const FormatNumber = (value, previousValue) => {
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;

    if (cvLength < 7)
      return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;

    if (cvLength < 11) {
      return `${currentValue.slice(0, 3)} ${currentValue.slice(
        3,
        6
      )} ${currentValue.slice(6, 10)}`;
    }
    if (cvLength < 12) {
      return `${currentValue.slice(0, 1)}${currentValue.slice(
        1,
        4
      )} ${currentValue.slice(4, 7)} ${currentValue.slice(7, 11)}`;
    }

    return `${currentValue.slice(1, 2)}${currentValue.slice(
      2,
      5
    )} ${currentValue.slice(5, 8)} ${currentValue.slice(8, 12)}`;
  }
};
export default FormatNumber;
