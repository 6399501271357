import React, { useContext, useState } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
//Mui
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { GreenSwitch } from "../../../../../components/switch/greenSwitch";

function WorkingHours({ resDetailInfo, id }) {
  //variables
  const [hours, setHours] = useState({ start: "00:00", end: "23:50" });
  const { resChangeDetailFunc } = useContext(RestaurantContext);
  const [workingHours, setWorkingHours] = useState(
    resDetailInfo?.working_hours
  );
  //functions
  const handleAllChange = (startEnd, e) => {
    if (!e) return "";

    if (startEnd === "start") {
      setHours({ ...hours, start: e.target.value });
    } else {
      if (e.target.value == "00:00") {
        setHours({ ...hours, end: "23:59" });
      } else {
        setHours({ ...hours, end: e.target.value });
      }
    }
  };
  const applyAllDayOpenOrClose = () => {
    let tempworkingHoursList = [...workingHours];
    tempworkingHoursList = tempworkingHoursList.map((item) => {
      if (hours.start == "00:00" && hours.end == "23:59") {
        setHours({ start: "00:00", end: "23:50" });
        item.hours.start = "00:00";
        item.hours.end = "23:50";
        return item;
      } else {
        setHours({ start: "00:00", end: "23:59" });
        item.hours.start = "00:00";
        item.hours.end = "23:59";
        return item;
      }
    });
    setWorkingHours(tempworkingHoursList);
  };

  const applyAllDay = (index) => {
    let tempworkingHoursList = [...workingHours];
    tempworkingHoursList = tempworkingHoursList.map((item, index, array) => {
      item.hours.start = hours.start;
      item.hours.end = hours.end;
      item.is_active = true;
      return item;
    });
    setWorkingHours(tempworkingHoursList);
  };
  const handleDateChange = (name, index) => (event) => {
    const tempworkingHoursList = [...workingHours];
    if (name == "end" && event.target.value == "00:00") {
      tempworkingHoursList[index].hours[name] = "23:59";
    } else {
      tempworkingHoursList[index].hours[name] = event.target.value;
    }

    setWorkingHours(tempworkingHoursList);
  };
  const closeDay = (row, index) => {
    let tempworkingHoursList = [...workingHours];
    if (!tempworkingHoursList[index]?.is_active) {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "23:59";
    } else {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "00:00";
    }
    tempworkingHoursList[index].is_active =
      !tempworkingHoursList[index].is_active;
    setWorkingHours(tempworkingHoursList);
  };
  const allDayOpenOrClose = (row, index) => {
    let tempworkingHoursList = [...workingHours];
    if (
      tempworkingHoursList[index]?.hours.start == "00:00" &&
      tempworkingHoursList[index]?.hours.end == "23:59"
    ) {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "23:00";
    } else {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "23:59";
    }

    setWorkingHours(tempworkingHoursList);
  };

  return (
    <Card
      style={{
        overflow: "auto",
        backgroundColor: "#f4f4f4",
        marginTop: 4,
      }}
    >
      <CardContent style={{ textAlign: "left", padding: "0 15px" }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 14,
                  }}
                >
                  Günler
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 14,
                  }}
                >
                  Açılış Saati
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 14,
                  }}
                >
                  Kapanış Saati
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 14,
                  }}
                >
                  24 saat Açık / Kapalı
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 14,
                  }}
                >
                  Durum
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      fontSize: 14,
                    }}
                  >
                    Tümü
                  </span>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    variant="filled"
                    id="time"
                    label={
                      <span
                        style={{
                          fontFamily: "segoeuiRg",
                          fontSize: 14,
                        }}
                      >
                        Açılış Saati
                      </span>
                    }
                    type="time"
                    value={hours.start}
                    onChange={(e) => {
                      handleAllChange("start", e);
                    }}
                    style={{
                      width: 250,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    variant="filled"
                    id="time"
                    label={
                      <span
                        style={{
                          fontFamily: "segoeuiRg",
                          fontSize: 14,
                        }}
                      >
                        Kapanış Saati
                      </span>
                    }
                    type="time"
                    value={hours.end}
                    onChange={(e) => {
                      handleAllChange("end", e);
                    }}
                    style={{
                      width: 250,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={
                          hours?.start == "00:00" && hours?.end == "23:59"
                            ? true
                            : false
                        }
                        onChange={() => {
                          applyAllDayOpenOrClose();
                        }}
                        name="checkedG"
                      />
                    }
                    label={
                      <span
                        style={{
                          fontFamily: "segoeuiRg",
                          fontSize: 14,
                        }}
                      >
                        Tüm Hafta Açık
                      </span>
                    }
                    labelPlacement="top"
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "#f1b62a",
                      borderRadius: 4,
                      width: "auto",
                      alignSelf: "flex-end",
                      marginBottom: 15,
                      boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                    }}
                    variant="contained"
                    onClick={() => applyAllDay()}
                  >
                    <span
                      style={{
                        fontFamily: "segoeuiRg",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      Tüm Günler
                    </span>
                  </Button>
                </TableCell>
              </TableRow>

              {resDetailInfo?.working_hours?.map((row, index) => (
                <TableRow key={`workingHoursList${row.day}`}>
                  <TableCell align="center">
                    <span
                      style={{
                        fontFamily: "segoeuiRg",
                        fontSize: 14,
                      }}
                    >
                      {row.day == "Monday"
                        ? "Pazartesi"
                        : row.day == "Tuesday"
                        ? "Salı"
                        : row.day == "Wednesday"
                        ? "Çarşamba"
                        : row.day == "Thursday"
                        ? "Perşembe"
                        : row.day == "Friday"
                        ? "Cuma"
                        : row.day == "Saturday"
                        ? "Cumartesi"
                        : "Pazar"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      variant="filled"
                      id="time"
                      label={
                        <span
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Açılış Saati
                        </span>
                      }
                      type="time"
                      value={row?.hours?.start}
                      onChange={handleDateChange("start", index)}
                      disabled={row?.is_active}
                      style={{
                        width: 250,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      variant="filled"
                      id="time"
                      label={
                        <span
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Kapanış Saati
                        </span>
                      }
                      type="time"
                      value={row?.hours?.end}
                      onChange={handleDateChange("end", index)}
                      style={{
                        width: 250, //xxx
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={
                            row?.hours?.start == "00:00" &&
                            row?.hours?.end == "23:59"
                              ? true
                              : false
                          }
                          onChange={() => {
                            allDayOpenOrClose(row, index);
                          }}
                          name="checkedG"
                        />
                      }
                      label={
                        <span
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          24 saat Açık
                        </span>
                      }
                      labelPlacement="top"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={row.is_active == true ? true : false}
                          onChange={() => {
                            closeDay(row, index);
                          }}
                          name="checkedG"
                        />
                      }
                      label={
                        row.is_active ? (
                          <span
                            style={{
                              fontFamily: "segoeuiRg",
                              fontSize: 14,
                            }}
                          >
                            Bugün Açık
                          </span>
                        ) : (
                          <span
                            style={{
                              fontFamily: "segoeuiRg",
                              fontSize: 14,
                            }}
                          >
                            Bugün Kapalı
                          </span>
                        )
                      }
                      labelPlacement="top"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions
        style={{
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            backgroundColor: "#f1b62a",
            borderRadius: 4,
            width: "20%",
            alignSelf: "flex-end",
            marginBottom: 15,
            boxShadow: "2px 3px 2.5px 1px #C5C5BB",
            fontWeight: 700,
            fontSize: 14,
          }}
          variant="contained"
          onClick={() => {
            let data = {
              restofficial_id: id,
              working_hours: workingHours,
            };
            resChangeDetailFunc(data);
          }}
        >
          Güncelle
        </Button>
      </CardActions>
    </Card>
  );
}

export default WorkingHours;
