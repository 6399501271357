import React, { useContext, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  Divider,
  IconButton,
  InputBase,
  Modal,
  Paper,
} from "@mui/material";
import { Audiotrack, Done, MusicOff } from "@mui/icons-material";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 90%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const ReminderModal = ({
  reminderModal,
  setReminderModal,
  reminderType,
  reminderRow,
  setReminderRow,
  fetchData,
  dashboardType,
}) => {
  const classes = useStyles();
  const [note, setNote] = useState("");
  const { createRestofficialReminder, patchRestofficialReminder } =
    useContext(RestaurantContext);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const [audioUrl, setAudioUrl] = useState("");
  const streamRef = useRef(null);
  const [buttonDisable, setButtonDisable] = useState(true);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    streamRef.current = stream;
    const options = {
      audioBitsPerSecond: 32000, // 32 kbps
      videoBitsPerSecond: 2500000, // 2.5 Mbps
    };
    mediaRecorder.current = new MediaRecorder(stream, options);
    mediaRecorder.current.ondataavailable = (event) => {
      audioChunks.current.push(event.data);
    };
    mediaRecorder.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, {
        type: "audio/webm;codecs=opus",
      });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      // audioChunks.current = [];

      // Stop all tracks on the stream to release the microphone
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
    };
    setIsRecording(false);
  };

  const deleteRecording = () => {
    setAudioUrl("");
    audioChunks.current = [];
  };

  const handlePlay = () => {
    setButtonDisable(false);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={reminderModal}
      className={classes.modal}
      onClose={() => {
        if (reminderType !== "show") {
          setReminderModal(false);
        }
      }}
    >
      <Card
        style={{
          width: "50%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 600,
          overflow: "auto",
        }}
      >
        <div
          style={{
            fontSize: 24,
            color: "red",
            fontFamily: "SgBd",
          }}
        >
          HATIRLATMA
        </div>
        <Divider style={{ margin: "15px 0px" }} />
        {reminderType === "show" ? (
          <div
            style={{
              width: "100%",
              fontSize: 20,
              fontFamily: "math",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div>{reminderRow?.note}</div>
            {reminderRow?.voice_note ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  placeContent: "flex-end",
                }}
              >
                <audio
                  onPlay={handlePlay}
                  controls
                  src={reminderRow?.voice_note}
                ></audio>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <>
            {reminderRow?.reminders?.map((row, ind) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    style={{
                      display: "flex",
                      backgroundColor: "#f4f4f4",
                      alignSelf: "center",
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    {row?.is_read ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 5,
                        }}
                      >
                        <div style={{ fontFamily: "segoeuiB", fontSize: 13 }}>
                          [
                          {row?.sender_role === "quality_control"
                            ? "Kalite Kontrol"
                            : "Ofis Kullanıcısı"}
                          ] - {row?.officeuser?.full_name}
                          <span
                            style={{
                              color: "gray",
                              fontSize: 12,
                              marginLeft: 3,
                            }}
                          >
                            (
                            {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                            )
                          </span>
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            fontSize: 13,
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {row?.note}
                          {row?.voice_note ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                placeContent: "flex-end",
                              }}
                            >
                              <audio controls src={row?.voice_note}></audio>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : row?.sender_role === "officeuser" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 5,
                        }}
                      >
                        <div style={{ fontFamily: "segoeuiB", fontSize: 13 }}>
                          [
                          {row?.sender_role === "quality_control"
                            ? "Kalite Kontrol"
                            : "Ofis Kullanıcısı"}
                          ] - {row?.officeuser?.full_name}{" "}
                          <span
                            style={{
                              color: "gray",
                              fontSize: 12,
                              marginLeft: 3,
                            }}
                          >
                            (
                            {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                            )
                          </span>
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            fontSize: 13,
                            marginTop: 5,
                          }}
                        >
                          {row?.note}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <InputBase
                          multiline
                          value={row?.note}
                          spellCheck={false}
                          style={{
                            width: "100%",
                            fontFamily: "math",
                            paddingLeft: 8,
                          }}
                          rows={6}
                          placeholder={"Not ekle"}
                          className={classes.input}
                          onChange={(e) => {
                            const reminderRowObj = Object.assign(
                              [],
                              JSON.parse(
                                JSON.stringify({
                                  ...reminderRow,
                                })
                              )
                            );
                            reminderRowObj.reminders[ind].note = e.target.value;
                            setReminderRow(reminderRowObj);
                          }}
                        />
                        {row?.voice_note ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              placeContent: "flex-end",
                            }}
                          >
                            <audio controls src={row?.voice_note}></audio>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </Paper>
                  {row?.sender_role === "officeuser" ? (
                    <Done style={{ width: "15%" }} />
                  ) : row?.is_read ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "18%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Done style={{ width: "100%" }} />
                      {row?.voice_note && (
                        <Button
                          style={{
                            backgroundColor: "#f53b62",
                            color: "#fff",
                            fontFamily: "segoeuiB",
                            width: "80%",
                            display: "flex",
                            justifyContent: "center",
                            height: 20,
                            marginTop: 10,
                          }}
                          size="small"
                          onClick={() => {
                            setAudioUrl(row?.voice_note);
                          }}
                        >
                          Sesi Tekrarla
                        </Button>
                      )}
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        fontFamily: "segoeuiB",
                        fontWeight: 700,
                        backgroundColor: "#0FBD20",
                        width: "15%",
                        marginLeft: 10,
                        height: "50%",
                      }}
                      onClick={() => {
                        let data = {
                          id: row?.id,
                          sendObj: {
                            note: row?.note,
                          },
                          fetchData,
                          reminderType,
                          dashboardType,
                        };
                        patchRestofficialReminder(data);
                        setReminderModal(false);
                      }}
                    >
                      Güncelle
                    </Button>
                  )}
                </div>
              );
            })}
            {((reminderRow?.reminders?.length !== 0 &&
              reminderRow?.reminders[0]?.sender_role === "officeuser") ||
              reminderRow?.reminders?.length === 0) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 140,
                  flexDirection: "column",
                }}
              >
                <Paper
                  style={{
                    display: "flex",
                    backgroundColor: "#f4f4f4",
                    alignSelf: "center",
                    width: "100%",
                    marginTop: 50,
                  }}
                >
                  <InputBase
                    multiline
                    value={note}
                    spellCheck={false}
                    style={{
                      width: "100%",
                      fontFamily: "math",
                      paddingLeft: 8,
                    }}
                    rows={5}
                    placeholder={"Not ekle"}
                    className={classes.input}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  />
                </Paper>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "10%",
                    }}
                  >
                    <IconButton
                      onClick={isRecording ? stopRecording : startRecording}
                      style={{
                        color: isRecording ? "green" : "black",
                        backgroundColor: isRecording ? "black" : "#f4f4f4",
                      }}
                      disabled={audioUrl ? true : false}
                    >
                      <Audiotrack />
                    </IconButton>
                    <IconButton
                      onClick={deleteRecording}
                      style={{
                        color: audioUrl ? "red" : "black",
                        backgroundColor: audioUrl ? "black" : "#f4f4f4",
                        marginLeft: 10,
                      }}
                    >
                      <MusicOff />
                    </IconButton>
                    {/* <a href={audioUrl} download="recording.webm">
                      Download
                    </a> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {audioUrl ? (
                      <div>
                        <audio controls src={audioUrl}></audio>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      fontFamily: "segoeuiB",
                      fontWeight: 700,
                      backgroundColor: "#0FBD20",
                      width: 120,
                      height: 40,
                    }}
                    onClick={() => {
                      let data = {
                        sendObj: {
                          restofficial: reminderRow?.restofficial?.id,
                          note,
                          task_type: dashboardType === "summary" ? 14 : 7,
                          audioChunks:
                            audioChunks?.current?.length !== 0
                              ? audioChunks
                              : audioUrl,
                          audioType:
                            audioChunks?.current?.length !== 0
                              ? "chunk"
                              : "url",
                        },
                        setNote,
                        fetchData,
                        reminderType,
                        dashboardType,
                      };
                      createRestofficialReminder(data);
                      setReminderModal(false);
                    }}
                  >
                    Oluştur
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        <Divider style={{ margin: "50px 0px" }} />
        {reminderType === "show" && (
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: reminderRow?.voice_note
                ? buttonDisable
                  ? "gray"
                  : "#0FBD20"
                : "#0FBD20",
              width: "100%",
            }}
            disabled={reminderRow?.voice_note ? buttonDisable : false}
            onClick={() => {
              let data = {
                id: reminderRow?.id,
                sendObj: {
                  is_read: true,
                },
                fetchData,
                reminderType,
                dashboardType,
              };
              patchRestofficialReminder(data);

              setReminderModal(false);
              setButtonDisable(true);
            }}
          >
            Tamam
          </Button>
        )}
      </Card>
    </Modal>
  );
};

export default ReminderModal;
