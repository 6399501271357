import React, { useContext, useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import Containner from "../components/containner";
import Header from "../components/header";
import Sidebar from "../components/sidebar";

import {
  operationSidbarData,
  operationSidbarDataCollector,
  operationSidbarDataOutbound,
  operationSidbarDataSetup,
  callCenterSidbarData,
  technicalServiceSidBarData,
} from "../utils/constants";
import Operation from "../utils/assets/operasyon_logo.png";
import { RestaurantContext } from "../contexts/restaurantContext";
import CustomerAppBar from "../components/appBar";
import MobilMainSidebar from "../components/sidebar/components/mobilMainSidebar";
import { LoginContext } from "../contexts/loginContext";
import SidebarIcon from "../components/sidebar/components/sidebarIcon";

const OperationLayout = ({ children }) => {
  const { operationSidebarPage, setOperationSidebarPage } =
    useContext(RestaurantContext);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const [isMobil, setMobil] = useState(false);
  const [sidebarData, setSidebarData] = useState(
    JSON.parse(localStorage.getItem("st-officeuser-info")).role === "collector"
      ? operationSidbarDataCollector
      : operationSidbarData
  );
  const { getUserRole, userRole } = useContext(LoginContext);

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    if (userRole === "outbound") {
      setSidebarData(operationSidbarDataOutbound);
    } else if (userRole === "collector") {
      setSidebarData(operationSidbarDataCollector);
    } else if (userRole === "setup") {
      setSidebarData(operationSidbarDataSetup);
    } else if (userRole === "call_center") {
      setSidebarData(callCenterSidbarData);
    } else if (userRole === "tech") {
      setSidebarData(technicalServiceSidBarData);
    } else {
      setSidebarData(operationSidbarData);
    }
  }, [userRole]);

  return (
    <div style={{ overflow: "hidden" }}>
      {isMatch ? (
        <Header image={Operation} title="Operasyon" />
      ) : (
        <CustomerAppBar isMobil={isMobil} setMobil={setMobil} />
      )}

      <Containner>
        <div
          style={{
            height: "98%",
            width: "98%",
            borderRadius: 4,
            display: "flex",
            contain: "content",
            justifyContent: "space-between",
          }}
        >
          {isMatch ? (
            window.location.pathname === "/operation/tasks" ? (
              <SidebarIcon
                currentIndex={operationSidebarPage}
                setCurrentIndex={setOperationSidebarPage}
                sidebarData={sidebarData}
              />
            ) : (
              <SidebarIcon
                currentIndex={operationSidebarPage}
                setCurrentIndex={setOperationSidebarPage}
                sidebarData={sidebarData}
              />
            )
          ) : (
            <MobilMainSidebar
              openDrawer={isMobil}
              setOpenDrawer={setMobil}
              currentIndex={operationSidebarPage}
              setCurrentIndex={setOperationSidebarPage}
              sidebarData={sidebarData}
            />
          )}
          <Box
            component="main"
            sx={{
              width: isMatch
                ? window.location.pathname === "/operation/tasks"
                  ? "96%"
                  : "96%"
                : "100%",
              backgroundColor: "#f4f4f4",
              borderRadius: 2,
              color: "#000",
              overflowY: "auto",
            }}
          >
            <main style={{ height: "100%" }}>{children}</main>
          </Box>
        </div>
      </Containner>
    </div>
  );
};

export default OperationLayout;
