import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import "../../../components/tableCss/table.css";
import { platformImageConverterBig } from "../../../components/converter/platformImage";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { GreenSwitch } from "../../../components/switch/greenSwitch";

const PaymentTypes = () => {
  let { id } = useParams();
  const {
    platformList,
    getPlatformList,
    getRestaurantInfo,
    restOfficialData,
    getRestaurantPaymentTypesData,
    restaurantPaymentTypes,
    setRestaurantPaymentTypes,
    postRestaurantPaymentTypesData,
  } = useContext(RestaurantContext);

  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [platformRes, setPlatformRes] = useState([]);
  const [selectedPlatformRes, setSelectedPlatformRes] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    getRestaurantInfo(id);
    getPlatformList();
  }, []);

  useEffect(() => {
    if (selectedPlatform != null) {
      let res_test = [];

      restOfficialData?.platforms?.length != 0 &&
        restOfficialData?.platforms
          ?.filter((info) => info.platform?.id == selectedPlatform?.id)
          ?.map((row, ind) => {
            res_test.push(row);
          });
      setPlatformRes(res_test);
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (platformRes?.length != 0) {
      setSelectedPlatformRes(platformRes[0]);
    }
  }, [platformRes]);

  useEffect(() => {
    if (selectedPlatformRes != null) {
      getRestaurantPaymentTypesData(id, selectedPlatformRes?.id);
    }
  }, [selectedPlatformRes]);

  const Action = (item) => {
    return (
      <FormControlLabel
        control={
          <GreenSwitch
            checked={item?.is_active}
            onChange={(e) => {
              postRestaurantPaymentTypesData(id, selectedPlatformRes?.id, {
                secret_key: selectedPlatformRes?.id,
                payment_type: item.id,
                is_active: !item.is_active,
                setButtonDisable,
              });
            }}
            disabled={buttonDisable}
          />
        }
        label={
          item?.is_active ? (
            <span style={{ fontFamily: "segoeuiRg", fontSize: 14 }}>
              {" "}
              Aktif
            </span>
          ) : (
            <span style={{ fontFamily: "segoeuiRg", fontSize: 14 }}>
              Pasif{" "}
            </span>
          )
        }
        labelPlacement="start"
      />
    );
  };

  return (
    <Box
      component="main"
      sx={{
        width: "83%",
        backgroundColor: "#f4f4f4",
        borderRadius: 2,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
        marginLeft: 5,
      }}
    >
      <div
        style={{
          height: "98%",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <div
            style={{
              width: "100%",
              height: 80,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              minHeight: 80,
            }}
          >
            {platformList?.map((row, index) => {
              let disabledObj = [];
              disabledObj = restOfficialData?.platforms?.map((info) => {
                if (info.platform.id == row?.id) {
                  return true;
                } else {
                  return false;
                }
              });

              const disabledChecked = disabledObj?.findIndex(
                (item) => item == true
              );

              return (
                <div
                  key={index}
                  style={{
                    cursor: disabledChecked == -1 ? "default" : "pointer",
                  }}
                  onClick={() => {
                    if (disabledChecked != -1) {
                      setSelectedPlatform(row);
                      setRestaurantPaymentTypes(null);
                      setSelectedPlatformRes(null);
                    }
                  }}
                  disabled={disabledChecked == -1 ? true : false}
                >
                  {row?.name === "Yemeksepeti" && (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 14,
                            fontFamily: "segoeuiRg",
                            padding: 1,
                            bgcolor: "#082657",
                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                      title={
                        "Yemeksepeti ödeme yöntemlerinin entegrasyon firmaları üzerinden değiştirilmesine izin vermemektedir.Değişiklik işlemini MyRestoran üzerinden yapınız."
                      }
                      arrow
                      placement="bottom"
                    >
                      <img
                        alt="yemeksepeti"
                        style={{ width: 120, height: 70 }}
                        src={platformImageConverterBig(
                          row?.name + (disabledChecked == -1 ? "md" : "")
                        )}
                      />
                    </Tooltip>
                  )}
                  {row?.name === "Trendyol" && (
                    <img
                      alt="Trendyol"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Gofody" && (
                    <img
                      alt="Gofody"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Getir" && (
                    <img
                      alt="Getir"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Migros" && (
                    <img
                      alt="Migros"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Fuudy" && (
                    <img
                      alt="Fuudy"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: "98%",
              height: 65,
              display: "flex",
              flexDirection: "row",
              minHeight: 65,
              alignItems: "center",
              alignSelf: "center",
              overflow: "auto",
            }}
          >
            {platformRes?.map((row, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor:
                      selectedPlatformRes?.id == row?.id ? "green" : "#f53b62",
                    padding: "1px 6px 1px 6px",
                    fontSize: 13,
                    color: "white",
                    borderRadius: 4,
                    fontFamily: "segoeuiRg",
                    minWidth: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: 100,
                    height: "80%",
                    flexDirection: "column",
                    marginLeft: 5,
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    setSelectedPlatformRes(row);
                  }}
                >
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      cursor: "pointer",
                    }}
                  >
                    Tabela
                  </span>
                  {(row?.restaurant_name).length > 16 ? (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 14,
                            fontFamily: "segoeuiRg",
                            padding: 1,
                            bgcolor: "#082657",
                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                      title={row?.restaurant_name}
                      arrow
                      placement="top"
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 100,
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "segoeuiRg",
                            cursor: "pointer",
                          }}
                        >
                          {row?.restaurant_name}
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <>{row?.restaurant_name}</>
                  )}
                </div>
              );
            })}
          </div>
          {selectedPlatformRes != null && (
            <Card
              style={{
                overflow: "auto",
                backgroundColor: "#fafafa",
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <h2
                style={{
                  display: "flex",
                  color: "#000",
                  marginLeft: 16,
                  backgroundColor: "#fafafa",
                }}
              >
                Ödeme Yöntemleri
              </h2>
              <CardContent>
                <TableContainer>
                  <Table style={{ border: 0, width: "98%" }}>
                    <TableBody>
                      {restaurantPaymentTypes?.map(
                        (item, index) =>
                          item && (
                            <TableRow key={index}>
                              <TableCell
                                style={{
                                  borderTop: 0,
                                  borderRight: 0,
                                  borderLeft: 0,
                                }}
                              >
                                <span style={{ fontFamily: "segoeuiRg" }}>
                                  {" "}
                                  {item.name}
                                </span>
                              </TableCell>

                              <TableCell
                                style={{
                                  borderTop: 0,
                                  borderRight: 0,
                                  borderLeft: 0,
                                }}
                                align="right"
                              >
                                {restaurantPaymentTypes.length > 0 &&
                                  Action(item, index)}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </Box>
  );
};

export default PaymentTypes;
