import {
  AddBoxOutlined,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import React from "react";
import { optionDetailFunc } from "./opsiyonDetailFunc";

export const opsiyonFunc = (
  productObj,
  forceUpdate,
  classes,
  optProductList,
  menuProductList
) => {
  const numMaxFunc = (row) => {
    if (
      row?.num_min == 0 &&
      row?.num_max == 0 &&
      row?.select_limit == 0 &&
      !row?.force_select_limit &&
      !row?.select_all
    ) {
      return "Dilediğiniz kadar seçiniz";
    } else if (row?.select_limit > 0) {
      return row?.select_limit + " adet seçebilirsiniz.";
    } else if (row?.select_all) {
      return "Çıkarmak istediğiniz malzemelerin seçimini kaldırınız.";
    } else if (row?.num_min == 0 && row?.num_max > 0) {
      return row?.num_max + " taneye kadar seçebilirsiniz.";
    } else if (row?.num_min == 1 && row?.num_max == 1) {
      return "Bir tane seçiniz";
    } else if (row?.num_min > 0 && row?.num_max > 0) {
      return (
        "En az " +
        row?.num_min +
        ", " +
        "en çok " +
        row?.num_max +
        " tane seçebilirsiniz"
      );
    } else {
      return "Hatalı Seçim";
    }
  };
  return productObj?.options?.map((row, ind) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            height: 80,
            backgroundColor: "#5a5757",
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 8,
            alignItems: "center",
          }}
        >
          {row?.show == true ? (
            <IconButton
              onClick={() => {
                row.show = false;
                forceUpdate();
              }}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                row.show = true;
                forceUpdate();
              }}
            >
              <ExpandMore />
            </IconButton>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
            }}
          >
            <Paper
              component="form"
              style={{
                width: "98%",
                display: "flex",
                justifyContent: "center",
                margin: 2,
              }}
            >
              <InputBase
                spellCheck="false"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                value={row?.name}
                className={classes.input}
                style={{
                  fontSize: 13,
                  width: "95%",
                }}
                placeholder={"Opsiyon"}
                onChange={(e) => {
                  row.name = e.target.value;
                  forceUpdate();
                }}
              />
            </Paper>
            <Paper
              component="form"
              style={{
                width: "98%",
                display: "flex",
                justifyContent: "center",
                margin: 2,
              }}
            >
              <div
                style={{
                  fontSize: 13,
                  width: "95%",
                }}
              >
                {numMaxFunc(row)}
              </div>
            </Paper>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Paper
                  component="form"
                  style={{
                    width: 75,
                    display: "flex",
                    justifyContent: "center",
                    margin: 2,
                  }}
                >
                  <InputBase
                    type="number"
                    spellCheck="false"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    value={row?.num_min}
                    className={classes.input}
                    style={{
                      fontSize: 13,
                      width: "95%",
                    }}
                    placeholder={"min"}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        row.num_min = 0;
                      } else {
                        row.num_min = e.target.value;
                        row.select_all = false;
                        row.force_select_limit = false;
                        row.select_limit = 0;
                      }
                      forceUpdate();
                    }}
                  />
                </Paper>
                <Paper
                  component="form"
                  style={{
                    width: 75,
                    display: "flex",
                    justifyContent: "center",
                    margin: 2,
                  }}
                >
                  <InputBase
                    type="number"
                    spellCheck="false"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    value={row?.num_max}
                    className={classes.input}
                    style={{
                      fontSize: 13,
                      width: "95%",
                    }}
                    placeholder={"max"}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        row.num_max = 0;
                      } else {
                        row.num_max = e.target.value;
                        row.select_all = false;
                        row.force_select_limit = false;
                        row.select_limit = 0;
                      }
                      forceUpdate();
                    }}
                  />
                </Paper>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Paper
                component="form"
                style={{
                  width: 75,
                  display: "flex",
                  justifyContent: "center",
                  margin: 2,
                  height: "50%",
                }}
              >
                <InputBase
                  type="number"
                  spellCheck="false"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  value={row?.select_limit}
                  className={classes.input}
                  style={{ fontSize: 13 }}
                  placeholder={"Limit"}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      row.select_limit = 0;
                      row.force_select_limit = false;
                    } else {
                      if (e.target.value == 0) {
                        row.force_select_limit = false;
                      }
                      row.select_limit = e.target.value;
                      row.select_all = false;
                      row.num_min = 0;
                      row.num_max = 0;
                    }
                    forceUpdate();
                  }}
                />
              </Paper>
              <FormControlLabel
                value="order"
                control={
                  <Checkbox
                    color="secondary"
                    onChange={() => {
                      row.force_select_limit = !row.force_select_limit;
                      row.select_all = false;
                      row.num_min = 0;
                      row.num_max = 0;
                      forceUpdate();
                    }}
                    checked={row?.force_select_limit}
                  />
                }
                label={<span style={{ fontSize: 13 }}>Limitli Seçim</span>}
                labelPlacement="start"
              />
            </div>
            <FormControlLabel
              value="order"
              control={
                <Checkbox
                  color="secondary"
                  onChange={() => {
                    row.select_all = !row.select_all;
                    row.force_select_limit = false;
                    row.select_limit = 0;
                    row.num_min = 0;
                    row.num_max = 0;
                    forceUpdate();
                  }}
                  checked={row?.select_all}
                />
              }
              label={
                <span style={{ fontSize: 13 }}>Çıkarılacak Malzemeler</span>
              }
              labelPlacement="start"
            />
          </div>

          <div
            style={{
              width: "10%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "100%",
            }}
          >
            <DeleteForever
              style={{
                width: 24,
                height: 24,
                cursor: "pointer",
                color: "#fff",
              }}
              onClick={() => {
                productObj?.options.splice(ind, 1);
                forceUpdate();
              }}
            />

            <AddBoxOutlined
              style={{
                width: 32,
                height: 32,
                cursor: "pointer",
                color: "#fff",
              }}
              onClick={() => {
                row.show = true;
                row.details.push({
                  name: "",
                  price: 0,
                  product: null,
                  options: [],
                  optiondetail_type: "optionalproduct",
                });
                forceUpdate();
              }}
            />
          </div>
        </div>

        <div style={{ marginLeft: 20 }}>
          {row?.show &&
            optionDetailFunc(
              row,
              forceUpdate,
              classes,
              optProductList,
              menuProductList
            )}
        </div>
      </div>
    );
  });
};
