import React, { useContext, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { LoginContext } from "../../contexts/loginContext";

let resInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));

const CustomerAppBar = ({ setMobil }) => {
  const { logoutFunc, logoutSellerFunc } = useContext(LoginContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    console("event.currentTarget", event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={"primary-search-account-menu"}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profil</MenuItem>
      <MenuItem onClick={handleMenuClose}>Ayarlar</MenuItem>
      <MenuItem
        onClick={() => {
          if (resInfo?.type == "officeuser") {
            logoutFunc();
          } else {
            logoutSellerFunc();
          }
          setAnchorEl(null);
        }}
      >
        Çıkış
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#f53f62" }}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#f53f62",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            onClick={() => setMobil(true)}
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div">
            Sepet Takip
          </Typography>
        </Toolbar>
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            style={{
              display: "flex",
              alignSelf: "end",
              justifyContent: "end",
            }}
          >
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={"primary-search-account-menu"}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Typography>
          {renderMenu}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default CustomerAppBar;
