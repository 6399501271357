import { makeStyles } from "@mui/styles";

import { Avatar, Button, InputBase, Modal, Paper } from "@mui/material";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useContext, useEffect, useRef, useState } from "react";
import { CallContext } from "../../../contexts/callContext";
import { GreenSwitch } from "../../switch/greenSwitch";
import moment from "moment";
import { ManagementContext } from "../../../contexts/managementContext";
import { useToast } from "../../snackbar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 30%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

export default function PuantajVoiceModal({
  puantajVoiceModal,
  setPuantajVoiceModal,
  currentRowId,
}) {
  const classes = useStyles();
  const { alert } = useToast();
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const audioRef = useRef(null);
  const { puantajVoice } = useContext(CallContext);
  const {
    ratingRateTahsilatFunc,
    tahsilatRatingList,
    setTahsilatRatingList,
    tahsilatRatingSkipFunc,
  } = useContext(ManagementContext);

  const changePlaybackRate = (rate) => {
    if (audioRef.current) {
      audioRef.current.audio.current.playbackRate = rate;
      setPlaybackRate(rate);
    }
  };

  const preparePayload = (array) => {
    return array
      .map((item) => {
        if (item.rating_type === "numeric") {
          return { id: item.id, value: item.numeric_rating };
        } else if (item.rating_type === "text") {
          return { id: item.id, value: item.text_rating };
        }
        return null;
      })
      .filter(Boolean);
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={puantajVoiceModal}
      className={classes.modal}
      onClose={() => {
        if (currentRowId === 0 && tahsilatRatingList?.state !== "completed") {
          alert("Puanlama yapılmalı");
        } else {
          setPuantajVoiceModal(false);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          width: "40%",
          maxHeight: 600,
          overflowY: "auto",
          backgroundColor: "#fff",
          padding: 30,
          flexDirection: "column",
        }}
      >
        {puantajVoice && (
          <div>
            <AudioPlayer
              ref={audioRef}
              src={puantajVoice}
              showJumpControls={false}
              autoPlay={false}
              customAdditionalControls={[]}
              style={{
                padding: "2px 6px",
                width: "100%",
                backgroundColor: "#474545",
                boxShadow: "none",
                color: "#fff",
                marginTop: 2,
              }}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundColor: playbackRate === 1.0 ? "green" : "gray",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 35,
                  display: "flex",
                  justifyContent: "center",
                  height: 20,
                  marginTop: 1,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => changePlaybackRate(1.0)}
              >
                1x
              </div>
              <div
                style={{
                  backgroundColor: playbackRate === 1.25 ? "green" : "gray",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 35,
                  display: "flex",
                  justifyContent: "center",
                  height: 20,
                  marginTop: 1,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => changePlaybackRate(1.25)}
              >
                1.25x
              </div>
              <div
                style={{
                  backgroundColor: playbackRate === 1.5 ? "green" : "gray",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 35,
                  display: "flex",
                  justifyContent: "center",
                  height: 20,
                  marginTop: 1,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => changePlaybackRate(1.5)}
              >
                1.5x
              </div>
              <div
                style={{
                  backgroundColor: playbackRate === 1.75 ? "green" : "gray",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 35,
                  display: "flex",
                  justifyContent: "center",
                  height: 20,
                  marginTop: 1,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => changePlaybackRate(1.75)}
              >
                1.75x
              </div>
            </div>
          </div>
        )}
        <div>
          <Paper
            // key={ind}
            elevation={3}
            style={{
              width: "92%",
              display: "flex",
              padding: 5,
              margin: "10px 10px",
              backgroundColor: "#393737",
              boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
              height: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "32%",
                alignItems: "center",
                justifyContent: "space-around",
                backgroundColor: "#474545",
              }}
            >
              <Paper
                style={{
                  backgroundColor: "#736868",
                  width: "-webkit-fill-available",
                  color: "#fff",
                  padding: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "monospace",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {tahsilatRatingList?.task_type?.name}
                </div>
              </Paper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ width: "40%", textAlign: "-webkit-center" }}>
                  <Avatar
                    alt="backoffice_user"
                    src={tahsilatRatingList?.officeuser?.picture}
                    sx={{ width: 64, height: 64, cursor: "pointer" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "monospace",
                      fontSize: 14,
                      fontWeight: 550,
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {tahsilatRatingList?.officeuser?.full_name}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      fontFamily: "monospace",
                      fontSize: 11,
                      color: "#fff",
                    }}
                  >
                    {moment(tahsilatRatingList?.created_at).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "68%" }}>
              <div style={{ height: "70%", overflow: "auto" }}>
                {tahsilatRatingList?.features?.map((rowF, indF) => {
                  return (
                    <Paper
                      style={{
                        display: "flex",
                        color: "#fff",
                        padding: 2,
                        backgroundColor: "#403836",
                        width: "95%",
                        marginTop: 10,
                        fontFamily: "monospace",
                      }}
                    >
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {rowF?.title}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "80%",
                          justifyContent:
                            rowF.rating_type === "numeric"
                              ? "space-between"
                              : "space-around",
                        }}
                      >
                        {rowF.rating_type === "numeric"
                          ? Array.from({ length: 10 }, (_, i) => i + 1).map(
                              (rowArray) => {
                                return (
                                  <div
                                    onClick={() => {
                                      const tahsilatRatingListObj =
                                        Object.assign(
                                          {},
                                          JSON.parse(
                                            JSON.stringify({
                                              ...tahsilatRatingList,
                                            })
                                          )
                                        );
                                      tahsilatRatingListObj.features[
                                        indF
                                      ].numeric_rating = rowArray;
                                      setTahsilatRatingList(
                                        tahsilatRatingListObj
                                      );
                                    }}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor:
                                        rowArray === rowF?.numeric_rating
                                          ? "green"
                                          : "#f4f4f4",
                                      borderRadius: 2,
                                      color:
                                        rowArray === rowF?.numeric_rating
                                          ? "#f4f4f4"
                                          : "#000",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {rowArray}
                                  </div>
                                );
                              }
                            )
                          : rowF.text_choices?.map((rowtc, indt) => {
                              return (
                                <div
                                  onClick={() => {
                                    const tahsilatRatingListObj = Object.assign(
                                      {},
                                      JSON.parse(
                                        JSON.stringify({
                                          ...tahsilatRatingList,
                                        })
                                      )
                                    );
                                    tahsilatRatingListObj.features[
                                      indF
                                    ].text_rating = rowtc?.choice;
                                    setTahsilatRatingList(
                                      tahsilatRatingListObj
                                    );
                                  }}
                                  style={{
                                    width: 40,
                                    height: 20,
                                    backgroundColor:
                                      rowtc?.choice === rowF?.text_rating
                                        ? "green"
                                        : "#f4f4f4",
                                    borderRadius: 2,
                                    color:
                                      rowtc?.choice === rowF?.text_rating
                                        ? "#f4f4f4"
                                        : "#000",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  {rowtc?.choice}
                                </div>
                              );
                            })}
                      </div>
                    </Paper>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  height: "30%",
                  alignItems: "center",
                }}
              >
                <Paper
                  style={{
                    width: "75%",
                    display: "flex",
                    justifyContent: "space-between",
                    minHeight: 42,
                    backgroundColor: "#F0F0F0",
                    alignSelf: "center",
                    marginTop: 10,
                  }}
                >
                  <InputBase
                    multiline
                    spellCheck={false}
                    style={{
                      width: "100%",
                      fontFamily: "segoeuiRg",
                      paddingLeft: 8,
                      fontSize: 14,
                    }}
                    rows={2}
                    placeholder={"Not ekle"}
                    className={classes.input}
                    value={
                      tahsilatRatingList?.note ? tahsilatRatingList?.note : ""
                    }
                    onChange={(e) => {
                      const tahsilatRatingListObj = Object.assign(
                        {},
                        JSON.parse(JSON.stringify({ ...tahsilatRatingList }))
                      );
                      tahsilatRatingListObj.note = e.target.value;
                      setTahsilatRatingList(tahsilatRatingListObj);
                    }}
                  />
                </Paper>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "20%",
                  }}
                >
                  <div
                    style={{
                      height: "50%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 3,
                    }}
                  >
                    <GreenSwitch
                      onClick={() => {
                        const tahsilatRatingListObj = Object.assign(
                          {},
                          JSON.parse(JSON.stringify({ ...tahsilatRatingList }))
                        );
                        if (tahsilatRatingListObj.education) {
                          tahsilatRatingListObj.education =
                            !tahsilatRatingList?.education;
                        } else {
                          tahsilatRatingListObj.education = true;
                        }
                        setTahsilatRatingList(tahsilatRatingListObj);
                      }}
                      checked={true}
                    />
                  </div>
                  <div
                    style={{
                      height: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#f53b62",
                        color: "#fff",
                        fontFamily: "segoeuiB",
                        width: "20%",
                        display: "flex",
                        justifyContent: "center",
                        height: 20,
                      }}
                      size="small"
                      onClick={() => {
                        let data = {
                          rating_id: tahsilatRatingList?.id,
                          sendObj: {
                            education: tahsilatRatingList?.education
                              ? true
                              : false,
                            note: tahsilatRatingList?.note
                              ? tahsilatRatingList?.note
                              : "",
                            features: preparePayload(
                              tahsilatRatingList?.features
                            ),
                          },
                        };
                        ratingRateTahsilatFunc(data);
                      }}
                    >
                      Kaydet
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <Button
              style={{
                backgroundColor: "red",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "30%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                let data = {
                  rating_id: tahsilatRatingList?.id,
                };
                tahsilatRatingSkipFunc(data);
              }}
            >
              Puanlama yapılamadı
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
