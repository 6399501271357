import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import { useContext, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";

export default function TaskRepeatModal({
  openTaskRepeatModal,
  setOpenTaskRepeatModal,
  officeUserDetailTaskRepeats,
  startDate,
  endDate,
  userId,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { dashboardOfficeuserDetailTaskRepeatsDates, taskRepeatDates } =
    useContext(ManagementContext);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);

  const handleShowDate = (row, index) => {
    let data = {
      user_id: userId,
      startDate: startDate,
      endDate: endDate,
      task_type: row.task_type.id,
    };
    if (row.restofficial_id) {
      data = { ...data, restofficial_id: row.restofficial_id };
      dashboardOfficeuserDetailTaskRepeatsDates(data);
    } else {
      data = { ...data, application_id: row.application_id };
      dashboardOfficeuserDetailTaskRepeatsDates(data);
    }

    setClickedRowIndex(index);
  };

  function convertToHourAndMinutes(seconds) {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.round((seconds % 3600) / 60);

    const result = (hour !== 0 ? hour + " saat " : "") + minute + " dakika";

    return result;
  }

  return (
    <Modal
      open={openTaskRepeatModal}
      onClose={() => {
        setOpenTaskRepeatModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Tekrar Görev Tipleri
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "450px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Görev Tipi</TableCell>
                  <TableCell align="center">Randevu Tarihi</TableCell>
                  <TableCell align="center">Uzatma Adet</TableCell>
                  <TableCell align="center">Süre Dk</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {officeUserDetailTaskRepeats?.map((item, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          if (item.restofficial_id) {
                            window.open(
                              `restaurants/${item.restofficial_id}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {item.restofficial_id
                          ? item.restofficial_id
                          : item.application_id}
                      </TableCell>
                      <TableCell align="center">
                        {item.task_type.name}
                      </TableCell>
                      <TableCell align="center">
                        {clickedRowIndex === ind ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setClickedRowIndex(null);
                            }}
                          >
                            {taskRepeatDates?.map((date, index) => {
                              return (
                                <div key={index}>
                                  {moment(date.start_at).format(
                                    "DD.MM.YYYY HH:mm"
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <InfoIcon
                            onClick={() => handleShowDate(item, ind)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">{item.count}</TableCell>
                      <TableCell align="center">
                        {convertToHourAndMinutes(Number(item.total_time_spent))}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
