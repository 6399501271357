import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerInput } from "../../../../components/CustomerInput";
import { makeStyles } from "@mui/styles";
import YsNewKey from "./components/ysNewKey";
import YsRemoteList from "./components/ysRemoteList";
import Ysv1 from "./components/ysv1";
import Ysv2 from "./components/ysv2";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

const Yemeksepeti = ({
  secretKeyPlatformInfo,
  id,
  selectedSecretKeyPlatform,
  postSecretKey,
  scrollRefs,
  deleteRestofficialPlatforms,
  ysRemoteIdListFunc,
  ysRemoteIdList,
  ysRemoteIdCreateFunc,
  ysRemoteIdDeleteFunc,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          className={classes.tabs}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Yeni Başvuru" {...a11yProps(0)} />
          <Tab label="Bekleyen Listesi" {...a11yProps(1)} />
          <Tab label="Yemeksepeti v1" {...a11yProps(2)} />
          <Tab label="Yemeksepeti v2" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <Box style={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <YsNewKey
              selectedSecretKeyPlatform={selectedSecretKeyPlatform}
              id={id}
              ysRemoteIdCreateFunc={ysRemoteIdCreateFunc}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <YsRemoteList
            ysRemoteIdListFunc={ysRemoteIdListFunc}
            ysRemoteIdList={ysRemoteIdList}
            id={id}
            scrollRefs={scrollRefs}
            ysRemoteIdDeleteFunc={ysRemoteIdDeleteFunc}
            postSecretKey={postSecretKey}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Ysv1
            secretKeyPlatformInfo={secretKeyPlatformInfo}
            scrollRefs={scrollRefs}
            deleteRestofficialPlatforms={deleteRestofficialPlatforms}
            id={id}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Ysv2
            secretKeyPlatformInfo={secretKeyPlatformInfo}
            scrollRefs={scrollRefs}
            deleteRestofficialPlatforms={deleteRestofficialPlatforms}
            id={id}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Yemeksepeti;
