import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";
import TablePaginationActions from "../../../../components/pagination/mainPagination";
import AcceptReferencePaymentModal from "../../../../components/modals/acceptReferencePayment";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

const ReferencePayed = () => {
  const { referencePaymentsFunc, referencePayments } =
    useContext(ManagementContext);
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [currentRow, setCurrentRow] = useState(null);
  const [acceptReferencePaymentModal, setAcceptReferencePaymentModal] =
    useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let data = {
      payed: 1,
    };
    referencePaymentsFunc(data);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Oluşturulma tarihi</TableCell>
                  <TableCell align="center">Ref Rest Id</TableCell>
                  <TableCell align="center">Ref Rest</TableCell>
                  <TableCell align="center">Ref Rest IBAN</TableCell>
                  <TableCell align="center">Ref Rest ŞirketTürü</TableCell>
                  <TableCell align="center">Ref Rest ŞirketAdı</TableCell>
                  <TableCell align="center">Ref Rest HesapAdı</TableCell>
                  <TableCell align="center">Ref Rest BankaAdı</TableCell>
                  <TableCell align="center">Yeni Rest Id</TableCell>
                  <TableCell align="center">Yeni Rest</TableCell>
                  <TableCell align="center">Tüm Tutar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referencePayments?.results?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {row?.restofficial?.user_id}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {row?.restofficial?.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {row?.restofficial?.iban}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {row?.restofficial?.company_type}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {row?.restofficial?.company_title}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {row?.restofficial?.account_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {row?.restofficial?.bank_name}
                      </TableCell>
                      <TableCell align="center">
                        {row?.refered_restofficial?.user_id}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {row?.refered_restofficial?.name}
                      </TableCell>
                      <TableCell align="center">₺ {row?.amount}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  {referencePayments != null && (
                    <TablePagination
                      rowsPerPageOptions={[5]}
                      labelRowsPerPage={"Sayfa Başına Kayıt"}
                      count={referencePayments?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      classes={{ root: classes.root }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <AcceptReferencePaymentModal
        acceptReferencePaymentModal={acceptReferencePaymentModal}
        setAcceptReferencePaymentModal={setAcceptReferencePaymentModal}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
    </>
  );
};

export default ReferencePayed;
