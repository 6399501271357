import React, { useContext } from "react";

import { Button, Card, Modal, TextField } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

import { Save } from "@mui/icons-material";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const LoginTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#f53b62",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#f53b62",
      },
    },
  },
})(TextField);

const SecretKeyUpdateModal = ({
  secretKeyUpdateModal,
  setSecretKeyUpdateModal,
  currentSecretKeyRow,
  setCurrentSecretKeyRow,
}) => {
  const classes = useStyles();
  const { changeSecretKey } = useContext(RestaurantContext);

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={secretKeyUpdateModal}
        className={classes.modal}
        onClose={() => {
          setSecretKeyUpdateModal(false);
        }}
      >
        <Card
          className={classes.paper}
          style={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            padding: 25,
            flexDirection: "column",
          }}
        >
          <Card
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoginTextField
                variant="filled"
                required
                fullWidth
                spellCheck={false}
                name="secretkey_res_name"
                value={currentSecretKeyRow?.restaurant_name}
                label="SecretKey Restoran İsmi"
                type={"text"}
                id="secretkey_res_name"
                inputProps={{
                  style: {
                    fontFamily: "segoeuiB",
                    fontSize: 14,
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: "segoeuiB",
                    fontSize: 14,
                  },
                }}
                onChange={(e) => {
                  const currentSecretKeyRowObj = Object.assign(
                    [],
                    JSON.parse(JSON.stringify({ ...currentSecretKeyRow }))
                  );
                  currentSecretKeyRowObj.restaurant_name = e.target.value;
                  setCurrentSecretKeyRow(currentSecretKeyRowObj);
                }}
              />

              <div
                style={{
                  marginTop: 15,
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    padding: "5px 10px 5px 10px",
                    textTransform: "capitalize",
                    fontFamily: "SgSemi",
                    fontSize: 14,
                  }}
                  startIcon={<Save />}
                  onClick={async () => {
                    let data = {
                      id: currentSecretKeyRow.id,
                      sendObj: {
                        restaurant_name: currentSecretKeyRow?.restaurant_name,
                      },
                      r_id: currentSecretKeyRow?.restofficial,
                      platform: currentSecretKeyRow?.platform?.id,
                    };
                    changeSecretKey(data);
                    setSecretKeyUpdateModal(false);
                  }}
                >
                  Güncelle
                </Button>
              </div>
            </div>
          </Card>
        </Card>
      </Modal>
    </>
  );
};

export default SecretKeyUpdateModal;
