import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SinglePrice from "./SinglePrice";

export default function OptionelPrices({ productData, setProductData }) {
  const handleAddPriceOption = () => {
    const option = { min: "", max: "", price: "" };
    const optional_price_value = [...productData.optional_price_value, option];
    setProductData({ ...productData, optional_price_value });
  };

  const handleDeleteOption = (index) => {
    const optional_price_value = productData.optional_price_value;
    optional_price_value.splice(index, 1);
    setProductData({ ...productData, optional_price_value });
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Min Adet</TableCell>
              <TableCell align="center">Max Adet</TableCell>
              <TableCell align="center">Fiyat</TableCell>
              <TableCell align="center">Aksiyon</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productData?.optional_price_value?.map((item, i) => (
              <SinglePrice
                key={i}
                item={item}
                index={i}
                handleDeleteOption={handleDeleteOption}
                productData={productData}
              />
            ))}
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell
                align="center"
                onClick={handleAddPriceOption}
                style={{ cursor: "pointer" }}
              >
                <AddBoxIcon style={{ color: "red" }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
