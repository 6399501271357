import React, { useContext } from "react";
import { Box } from "@mui/material";
import Containner from "../components/containner";
import Header from "../components/header";
import OperationManagement from "../utils/assets/yonetim_logo.png";
import { operationManagementSidbarData } from "../utils/constants";
import { RestaurantContext } from "../contexts/restaurantContext";
import ManagementSidebarIcon from "../components/sidebar/components/managementSidebarIcon";
import Sidebar from "../components/sidebar";

const OperationManagmentLayout = ({ children }) => {
  const { managmentSidebarPage, setManagementSidebarPage } =
    useContext(RestaurantContext);
  return (
    <div style={{ overflow: "hidden" }}>
      <Header image={OperationManagement} title="Operasyon Yönetimi" />

      <Containner>
        <div
          style={{
            height: "98%",
            width: "98%",
            borderRadius: 4,
            display: "flex",
            contain: "content",
            justifyContent: "space-between",
          }}
        >
          <ManagementSidebarIcon
            currentIndex={managmentSidebarPage}
            setCurrentIndex={setManagementSidebarPage}
            sidebarData={operationManagementSidbarData}
          />
          <Box
            component="main"
            sx={{
              width: "96%",
              backgroundColor: "#f4f4f4",
              borderRadius: 2,
              color: "#000",
              overflowY:
                // children?.props?.location?.pathname !== "/user-dashboard" &&
                "auto",
            }}
          >
            {children}
          </Box>
        </div>
      </Containner>
    </div>
  );
};

export default OperationManagmentLayout;
