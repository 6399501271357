import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useEffect, useState } from "react";

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
    backgroundColor: "#3e90c9",
  },
});

const OverflowTip = (props) => {
  const classes = useStyles();
  const textElementRef = useRef();
  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={props.note}
      disableHoverListener={!hoverStatus}
      classes={{ tooltip: classes.tooltip }}
      style={{ fontSize: 28, fontFamily: "segoeuiRg" }}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <span style={{ fontFamily: "segoeuiRg", cursor: "pointer" }}>
          {props.note}
        </span>
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
