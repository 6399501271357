import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  InputBase,
  Modal,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { developmentService } from "../../../../services/developmentService";
import { useToast } from "../../../../components/snackbar";
import { DevelopmentContext } from "../../../../contexts/developmentContext";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 20%)",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    marginBottom: 80,
  },
}));

const CreateGroup = ({ createGroupModal, setCreateGroupModal }) => {
  const classes = useStyles();
  const { alert, success } = useToast();
  const { getGroupTask, taskGroups, deleteGroupTask, editGroupDetail } =
    useContext(DevelopmentContext);
  const [addHeader, setAddHeader] = useState("");
  const [color, setColor] = useColor("#561ecb");
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleDeleteGroup = (group) => {
    deleteGroupTask(group.id);
  };

  const handleEditGroup = (group) => {
    setAddHeader(group.header);
    setSelectedGroup(group);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={createGroupModal}
      className={classes.modal}
      onClose={() => {
        setCreateGroupModal(false);
        setAddHeader("");
        setSelectedGroup(null);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#f4f4f4",
          padding: 5,
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {taskGroups?.map((group) => {
            if (!group.id) return null;
            return (
              <div
                key={group.id}
                style={{
                  margin: 5,
                  background: group.color,
                  padding: 5,
                  borderRadius: 4,
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditGroup(group)}
                >
                  {group.header}
                </span>
                <DeleteIcon
                  style={{ marginLeft: 10, cursor: "pointer" }}
                  onClick={() => handleDeleteGroup(group)}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 15,
            marginTop: 5,
          }}
        >
          <span
            style={{
              width: "auto",
              color: "#293c51",
              fontFamily: "segoeuiRg",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {selectedGroup ? "Grup Düzenle" : "Grup Oluştur"}
          </span>
        </div>
        <CardContent>
          <InputBase
            spellCheck="false"
            size="medium"
            value={addHeader}
            className={classes.input}
            style={{
              fontSize: 14,
              width: "100%",
              fontFamily: "segoeuiB",
              backgroundColor: "#fff",
              padding: 5,
              marginBottom: 10,
              marginLeft: 0,
            }}
            onChange={(e) => {
              setAddHeader(e.target.value);
            }}
          />

          <div style={{ width: 200 }}>
            <span
              style={{
                display: "block",
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: color.hex,
              }}
            >
              Renk Gir
            </span>
            <ColorPicker
              color={color}
              onChange={setColor}
              hideInput={["rgb", "hsv"]}
              hideAlpha
              height={100}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {selectedGroup && (
              <Button
                style={{
                  backgroundColor: "#f53b62",
                  marginRight: 10,
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 100,
                  display: "flex",
                  justifyContent: "center",
                  height: 32,
                  textTransform: "capitalize",
                  boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                }}
                size="small"
                onClick={async () => {
                  setAddHeader("");
                  setSelectedGroup(null);
                }}
              >
                Vazgeç
              </Button>
            )}

            <Button
              style={{
                backgroundColor: "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: 100,
                display: "flex",
                justifyContent: "center",
                height: 32,
                textTransform: "capitalize",
                boxShadow: "2px 3px 2.5px 1px #C5C5BB",
              }}
              size="small"
              onClick={async () => {
                let data = {
                  header: addHeader,
                  color: color.hex,
                };

                if (selectedGroup?.id) {
                  editGroupDetail(selectedGroup.id, data);
                  setAddHeader("");
                  setSelectedGroup(null);
                  setCreateGroupModal(false);
                } else {
                  await developmentService
                    .add_group_task(data)
                    .then((response) => {
                      setAddHeader("");
                      success("Grup oluşturuldu");
                      setCreateGroupModal(false);
                      getGroupTask();
                    })
                    .catch((err) => {
                      alert("Bir hata oluştu");
                    });
                }
              }}
            >
              {selectedGroup ? "Düzenle" : "Oluştur"}
            </Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default CreateGroup;
