import { Box, Grid, Paper } from "@mui/material";

const GridItem = ({
  per,
  title,
  count,
  percent,
  center,
  countTwo,
  boxStyle,
  onClick,
  total,
  darkMode,
}) => {
  return (
    <Grid item xs={per}>
      <Paper
        elevation={3}
        sx={{ padding: 2 }}
        style={{ ...boxStyle }}
        onClick={onClick}
      >
        <h3
          style={{
            margin: 0,
            marginBottom: darkMode ? 0 : 10,
            fontFamily: darkMode ? "monospace" : "segoeuiL",
            color: darkMode ? "#fff" : "darkslategray",
            fontSize: darkMode ? 12 : 18,
            textAlign: center ? "center" : "left",
          }}
        >
          {title}
        </h3>
        <Box
          sx={{
            display: "flex",
            justifyContent: center ? "center" : "flex-end",
            color: darkMode ? "#fff" : "darkslategray",
          }}
        >
          {percent !== undefined && percent !== Infinity && (
            <span
              style={{
                fontSize: darkMode ? 12 : 16,
                fontFamily: darkMode ? "monospace" : "segoeuiL",
              }}
            >
              %{isNaN(percent) ? 0 : percent.toFixed(2).replace(/[.,]00$/, "")}{" "}
              /
            </span>
          )}
          {count !== undefined && count !== Infinity && (
            <span
              style={{
                fontSize: darkMode ? 12 : 16,
                marginLeft: 5,
                fontFamily: darkMode ? "monospace" : "segoeuiL",
              }}
            >
              {isNaN(count)
                ? 0
                : new Intl.NumberFormat("tr-TR").format(
                    count.toFixed(2).replace(/[.,]00$/, "")
                  )}
            </span>
          )}

          {total > 0 && (
            <span
              style={{
                fontSize: darkMode ? 12 : 16,
                marginLeft: 5,
                fontFamily: darkMode ? "monospace" : "segoeuiL",
              }}
            >
              / {total} TL
            </span>
          )}

          {countTwo !== undefined && countTwo !== Infinity && (
            <span
              style={{
                fontSize: darkMode ? 12 : 16,
                marginLeft: 5,
                fontFamily: "segoeuiL",
              }}
            >
              /{" "}
              {isNaN(countTwo)
                ? 0
                : new Intl.NumberFormat("tr-TR").format(
                    countTwo.toFixed(2).replace(/[.,]00$/, "")
                  )}
            </span>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default GridItem;
