import React, { useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Card, InputBase, Modal } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const UserNotesModal = ({
  userNotesModal,
  setUserNotesModal,
  personalTemplateNoteList,
}) => {
  const classes = useStyles();
  const {
    personalTemplateNoteCreateFunc,
    personalTemplateNoteDeleteFunc,
    personalTemplateNoteSortFunc,
  } = useContext(RestaurantContext);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);

  const sortNotes = useCallback(async (updatedItems) => {
    let data = {
      sorted: updatedItems.map((item) => item.id),
    };
    personalTemplateNoteSortFunc(data);
  }, []);

  useEffect(() => {
    if (personalTemplateNoteList !== null) {
      setNotes(personalTemplateNoteList);
    }
  }, [personalTemplateNoteList]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={userNotesModal}
      className={classes.modal}
      onClose={() => {
        setUserNotesModal(false);
      }}
    >
      <Card
        style={{
          width: "40%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        {notes?.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#fff",
              marginBottom: 1,
              padding: 1,
            }}
          >
            <Box sx={{ display: "flex", width: "90%" }}>
              <Box style={{ color: "red", fontWeight: 600, marginRight: 10 }}>
                {index + 1} -{" "}
              </Box>
              <Box>{item.text}</Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowUpwardIcon
                style={{ color: "#f53b62", cursor: "pointer", marginRight: 10 }}
                onClick={() => {
                  if (index > 0) {
                    const updatedItems = [...notes];
                    const itemToMove = updatedItems.splice(index, 1)[0];
                    updatedItems.splice(index - 1, 0, itemToMove);
                    setNotes(updatedItems);
                    sortNotes(updatedItems);
                  }
                }}
              />

              <ArrowDownwardIcon
                style={{ color: "#f53b62", cursor: "pointer", marginRight: 10 }}
                onClick={() => {
                  if (index < notes.length - 1) {
                    const updatedItems = [...notes];
                    const itemToMove = updatedItems.splice(index, 1)[0];
                    updatedItems.splice(index + 1, 0, itemToMove);
                    setNotes(updatedItems);
                    sortNotes(updatedItems);
                  }
                }}
              />

              <DeleteIcon
                style={{ color: "#f53b62", cursor: "pointer" }}
                onClick={() => {
                  let data = {
                    note_id: item?.id,
                  };
                  personalTemplateNoteDeleteFunc(data);
                }}
              />
            </Box>
          </Box>
        ))}
        <Box>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Not
          </span>
          <Box sx={{ display: "flex" }}>
            <InputBase
              style={{
                background: "#f4f4f4",
                padding: 6,
                fontSize: 16,
                fontWeight: "400",
                fontFamily: "SgSemi",
                width: "100%",
                marginRight: 20,
              }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />

            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "SgBd",
                width: 160,
                fontWeight: 700,
                backgroundColor: "#0FBD20",
              }}
              onClick={async () => {
                let data = {
                  text: note,
                };
                personalTemplateNoteCreateFunc(data);
                setNote("");
              }}
            >
              Ekle
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default UserNotesModal;
