import { Box } from "@mui/system";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ background: "#fff", padding: 4, zIndex: 500, outline: "none" }}
      >
        <p className="label" style={{ marginBottom: 5 }}>
          {label}
        </p>

        {payload[0].payload.toplam && (
          <p className="label" style={{ marginTop: 0 }}>
            Toplam: {payload[0].payload.toplam}
          </p>
        )}

        {payload.map((pay, i) => {
          return (
            <p
              className="desc"
              key={i}
              style={{ color: pay.fill, fontSize: "13px" }}
            >
              {payload[i].name.slice(-1) === " " ? (
                <div style={{ textTransform: "capitalize" }}>
                  {" "}
                  {payload[i].name} /{" "}
                  {
                    pay.payload[
                      `restoran_${payload[i].name.slice(
                        0,
                        payload[i].name.length - 1
                      )}`
                    ]
                  }{" "}
                  -{" "}
                  {
                    pay.payload[
                      `tabela_${payload[i].name.slice(
                        0,
                        payload[i].name.length - 1
                      )}`
                    ]
                  }{" "}
                  - %
                  {
                    pay.payload[
                      `tabela_${payload[i].name.slice(
                        0,
                        payload[i].name.length - 1
                      )}_oran`
                    ]
                  }
                </div>
              ) : (
                <>
                  {pay.name} {new Intl.NumberFormat("tr-TR").format(pay.value)}
                  {pay.payload[`${pay.dataKey}_oran`] !== undefined
                    ? " - %" + pay.payload[`${pay.dataKey}_oran`]
                    : ""}{" "}
                </>
              )}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};

export default function StackedBar({ graph, data, title }) {
  return (
    <Box>
      <h3 style={{ fontFamily: "segoeuiL", color: "#293c51" }}>{title}</h3>
      <ResponsiveContainer height={300} width={"100%"}>
        <BarChart
          data={graph}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="0 12" />
          <XAxis
            dataKey="ay"
            style={{
              fontSize: "12px",
            }}
          />
          <YAxis
            style={{
              fontSize: "12px",
            }}
          />
          <Legend
            formatter={(value, entry, index) => {
              return (
                <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
                  {value}
                </span>
              );
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{ zIndex: 1000 }}
          />
          {data?.map((row, i) => {
            return (
              <Bar
                barSize={40}
                key={i}
                dataKey={row.dataKey}
                name={row.name}
                stackId="a"
                fill={row.color}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
