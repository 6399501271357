import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import managementService from "../../../../services/managementService";
import InputField from "../../../../components/input";
import Product from "./components/Product";
import PageHeader from "./components/PageHeader";
import Questions from "./components/questions";
import DeletePackage from "../../../../components/modals/deletePackage";

export default function ProductPrices() {
  const [packages, setPackages] = useState([]);
  const [savedPackage, setSavedPackage] = useState({});
  const [selectedPackageProducts, setSelectedPackageProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [packageId, setPackageId] = useState(null);
  const [openProduct, setOpenProduct] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [selectDeletedItem, setSelectDeletedItem] = useState(null);

  const [packageDetail, setPackageDetail] = useState({
    name: "",
    description: "",
  });

  const handleSavePackage = async () => {
    let data = {
      name: packageDetail.name,
      description: packageDetail.description,
    };
    const response = await managementService.post_package(data);
    setSavedPackage(response);
  };

  const handleSelectPackage = async (pack) => {
    const res = await managementService.get_package_products(pack.id);
    setPackageId(pack.id);
    setSelectedPackageProducts(res);
    setOpenProduct(false);
    setSelectedProduct({});
    getPackages();
  };

  useEffect(() => {
    packages.map((pack) => {
      if (pack.id === packageId) {
        setQuestions(pack.question_answers);
      }
    });
  }, [packageId]);

  const getPackages = async () => {
    const response = await managementService.get_packages();
    setPackages(response);
  };

  const handleSelectProduct = async (product) => {
    const res = await managementService.get_product_detail(
      product.package,
      product.id
    );
    setOpenProduct(true);
    setSelectedProduct(res);
  };

  const handleAddProduct = () => {
    setOpenProduct(true);
    setSelectedProduct({
      id: null,
      payment_type: "",
      kdv: "",
      name: "",
      price: "",
      description: "",
      ready_for_sale: false,
      package: null,
      discount_value: 0,
      price_description: "",
      modules: [],
      discount_permission_array: [],
      discount_expires_at: null,
      link: "",
      is_active: false,
      price_action: "",
      optional_price: false,
      optional_price_value: [],
      kdv_office: "",
      header_summary: "",
      summaries: [],
    });
  };

  useEffect(() => {
    getPackages();
  }, [savedPackage]);

  const handleAddQuestionTable = () => {
    let data = {
      question: "",
      answer: "",
    };
    setQuestions([...questions, data]);
    getPackages();
  };

  const handleDeletePackage = (pack) => {
    setShowModal(true);
    setTitle("Paketi silmek istediğinize emin misiniz?");
    setSelectDeletedItem(pack);
  };

  const handleDeleteProductModal = (product) => {
    setShowModal(true);
    setTitle("Ürünü silmek istediğinize emin misiniz?");
    setSelectDeletedItem(product);
  };

  return (
    <div>
      <PageHeader />

      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Box sx={{ marginRight: 2 }}>
          <InputField
            label="Paket Başlık"
            fieldName="name"
            formData={packageDetail}
            setFormData={setPackageDetail}
            fieldValue={packageDetail?.name}
          />
        </Box>

        <Box sx={{ marginRight: 2 }}>
          <InputField
            label="Paket Açıklama"
            fieldName="description"
            formData={packageDetail}
            setFormData={setPackageDetail}
            fieldValue={packageDetail?.description}
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SaveIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={handleSavePackage}
          />
        </Box>
      </Box>

      {packages.length > 0 && (
        <Box sx={{ marginBottom: 2 }}>
          <p
            style={{
              color: "#585858",
              fontFamily: "segoeuiRg",
              fontSize: 22,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 4,
            }}
          >
            Oluşturulmuş Paketler
          </p>

          <Box sx={{ display: "flex" }}>
            {packages.map((pack, i) => (
              <Box
                key={i}
                sx={{
                  marginRight: 2,
                  background: pack.id === packageId ? "#e3e3e3" : "#fff",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: 2,
                }}
              >
                <span
                  style={{
                    color: "#585858",
                    fontFamily: "segoeuiRg",
                    fontSize: 18,
                    fontWeight: 700,
                    marginRight: 10,
                    paddingRight: 10,
                    display: "block",
                    borderRight: "1px solid #585858",
                  }}
                  onClick={() => handleSelectPackage(pack)}
                >
                  {pack.name}
                </span>
                <DeleteForeverIcon
                  style={{ color: "red" }}
                  onClick={() => handleDeletePackage(pack)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {selectedPackageProducts.length > 0 && (
        <Box>
          <p
            style={{
              color: "#585858",
              fontFamily: "segoeuiRg",
              fontSize: 22,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 4,
            }}
          >
            Ürünler
          </p>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {selectedPackageProducts.map((product, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                  marginRight: 2,
                  background:
                    selectedProduct.id === product.id ? "#e3e3e3" : "#fff",
                  padding: 2,
                  cursor: "pointer",
                  fontFamily: "segoeuiRg",
                  fontWeight: 600,
                }}
              >
                <span
                  style={{
                    color: "#585858",
                    fontFamily: "segoeuiRg",
                    fontSize: 18,
                    fontWeight: 700,
                    marginRight: 10,
                    paddingRight: 10,
                    display: "block",
                    borderRight: "1px solid #585858",
                  }}
                  onClick={() => handleSelectProduct(product)}
                >
                  {product.name}
                </span>
                <DeleteForeverIcon
                  style={{ color: "red" }}
                  onClick={() => handleDeleteProductModal(product)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {openProduct && (
        <Product
          product={selectedProduct}
          packageId={packageId}
          setSelectedPackageProducts={setSelectedPackageProducts}
          setOpenProduct={setOpenProduct}
          setSelectedProduct={setSelectedProduct}
        />
      )}

      <Box sx={{ display: "flex" }}>
        {packageId && !openProduct && (
          <Box sx={{ display: "flex", marginBottom: 2, marginRight: 2 }}>
            <Box
              sx={{
                display: "flex",
                background: "#fff",
                padding: 2,
                cursor: "pointer",
              }}
              onClick={handleAddProduct}
            >
              <span
                style={{
                  marginRight: 20,
                  display: "block",
                  color: "#585858",
                  fontFamily: "segoeuiRg",
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                Ürün Ekle
              </span>
              <AddBoxIcon style={{ color: "red" }} />
            </Box>
          </Box>
        )}

        {questions.length === 0 && packageId && (
          <Box sx={{ display: "flex", marginBottom: 2 }}>
            <Box
              sx={{
                display: "flex",
                background: "#fff",
                padding: 2,
                cursor: "pointer",
              }}
              onClick={handleAddQuestionTable}
            >
              <span
                style={{
                  marginRight: 20,
                  display: "block",
                  color: "#585858",
                  fontFamily: "segoeuiRg",
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                Soru Cevap Alanı Ekle
              </span>
              <AddBoxIcon style={{ color: "red" }} />
            </Box>
          </Box>
        )}
      </Box>

      {questions.length > 0 && (
        <Box sx={{ paddingTop: 1, marginTop: 1, borderTop: "1px solid #ddd" }}>
          <h3 style={{ textAlign: "center" }}>Paket Soru Cevap Alanı</h3>

          <Questions
            questions={questions}
            setQuestions={setQuestions}
            packageId={packageId}
          />
        </Box>
      )}

      <DeletePackage
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        setTitle={setTitle}
        selectDeletedItem={selectDeletedItem}
        setSelectedPackageProducts={setSelectedPackageProducts}
      />
    </div>
  );
}
