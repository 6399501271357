import { Button, Paper } from "@mui/material";
import React, { useState } from "react";
import { CustomerInput } from "../../../../../components/CustomerInput";

const YsNewKey = ({ id, ysRemoteIdCreateFunc }) => {
  const [newSecretKeyObj, setNewSecretKeyObj] = useState({
    restaurant_name: "",
    yemeksepeti_restaurant_id: "",
    remote_id: "",
  });

  const newRegistrationKey = () => {
    let data = {
      restofficial: id,
      restaurant_name: newSecretKeyObj?.restaurant_name,
      yemeksepeti_restaurant_id: newSecretKeyObj?.yemeksepeti_restaurant_id,
      remote_id: newSecretKeyObj?.remote_id,
    };
    ysRemoteIdCreateFunc({
      sendObj: data,
      res_id: id,
    });
  };

  return (
    <Paper elevation={2} style={{ width: "50%", height: "auto", padding: 10 }}>
      <div
        style={{
          color: "#293c51",
          fontFamily: "segoeuiRg",
          fontSize: 20,
          fontWeight: 700,
          marginBottom: 5,
        }}
      >
        Yeni secret key ekle
      </div>

      <div>
        <div style={{ marginTop: 5 }}>
          <CustomerInput
            fieldHeader="Restoran İsmi"
            fieldName="restaurant_name"
            formData={newSecretKeyObj}
            setFormData={setNewSecretKeyObj}
            fieldValue={newSecretKeyObj?.restaurant_name}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomerInput
            fieldHeader="Yemeksepeti Vendor Id"
            fieldName="yemeksepeti_restaurant_id"
            formData={newSecretKeyObj}
            setFormData={setNewSecretKeyObj}
            fieldValue={newSecretKeyObj?.yemeksepeti_restaurant_id}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomerInput
            fieldHeader="Remote ID"
            fieldName="remote_id"
            formData={newSecretKeyObj}
            setFormData={setNewSecretKeyObj}
            fieldValue={newSecretKeyObj?.remote_id}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "98%",
          alignSelf: "center",
          height: 2,
          backgroundColor: "#f53b62",
          margin: "15px 0px",
        }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: 14,
            fontWeight: 600,
            backgroundColor: "#f53b62",
            color: "#fff",
            margin: "0px 15px",
          }}
          onClick={() => {
            newRegistrationKey();
          }}
        >
          Başvur
        </Button>
      </div>
    </Paper>
  );
};

export default YsNewKey;
