import React, { useContext, useEffect, useReducer, useRef } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
//mui
import { Button, IconButton, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
//mui icons
import { ArrowBack } from "@mui/icons-material";
//functions
import { productFunc } from "../productFunctions/productFunc";

const useStyles = makeStyles((theme) => ({
  select: {
    width: 250,
    height: 30,
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
    },
  },
  input: {
    marginLeft: 10,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
}));
function MenuDetail({ currentHeader, setIsFlipped, id }) {
  const {
    menuDetailObj,
    setMenuDetailObj,
    menuDetailFunc,
    optProductListFunc,
    optProductList,
    menuProductList,
    getProductList,
    menuProductUpdateFunc,
    productList,
  } = useContext(RestaurantContext);
  const scrollRefs = useRef(null);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const classes = useStyles();
  useEffect(() => {
    if (currentHeader != null) {
      setTimeout(() => {
        scrollRefs?.current?.scrollIntoView({
          behavior: "instant",
        });
      }, 100);
      let data = {
        product_id: currentHeader?.id,
      };
      menuDetailFunc(data);
      optProductListFunc(id);
      getProductList(id);
    }
  }, [currentHeader]);
  return (
    <>
      <div
        ref={scrollRefs}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => {
            setIsFlipped(false);
          }}
          style={{ color: "#fff", backgroundColor: "#f53b62" }}
        >
          <ArrowBack />
        </IconButton>
        <Paper
          elevation={2}
          style={{
            width: "auto",
            minWidth: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            padding: 5,
            margin: 12,
          }}
        >
          <span>{currentHeader?.name}</span>
        </Paper>
      </div>
      {menuDetailObj != null && (
        <div style={{ display: "flex" }}>
          {productFunc(
            menuDetailObj,
            setMenuDetailObj,
            forceUpdate,
            classes,
            optProductList,
            productList
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 30,
        }}
      >
        <Button
          style={{
            backgroundColor: "#f53b62",
            textTransform: "capitalize",
            boxShadow: "2px 3px 2.5px 1px #C5C5BB",
          }}
          onClick={() => {
            let data = {
              menuObj: { product: menuDetailObj, restofficial_id: id },
              setIsFlipped,
            };
            menuProductUpdateFunc(data);
          }}
          variant="contained"
          color="primary"
        >
          Güncelle
        </Button>
      </div>
    </>
  );
}

export default MenuDetail;
