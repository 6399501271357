import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import InputField from "../../../../../../components/input";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import managementService from "../../../../../../services/managementService";

export default function ProductSummaryDesc({
  summary,
  productData,
  setProductData,
  featureIndex,
  summaries,
}) {
  const [featuresData, setFeaturesData] = useState({
    description: "",
  });

  const handleDeleteDescription = async () => {
    if (productData.id && summary.id) {
      const res = await managementService.delete_summary(
        productData.id,
        summaries[featureIndex].id
      );
    }
    summaries.splice(featureIndex, 1);
    setProductData({ ...productData, summaries });
  };

  useEffect(() => {
    summaries[featureIndex] = featuresData;
  }, [featuresData, featureIndex, summaries]);

  useEffect(() => {
    setFeaturesData(summary);
  }, [summary]);

  return (
    <Box
      sx={{
        marginRight: 2,
        marginBottom: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <InputField
        label="Ürün Özet Açıklama"
        fieldName="description"
        formData={featuresData}
        setFormData={setFeaturesData}
        fieldValue={featuresData.description}
      />

      <DeleteForeverIcon
        style={{ color: "red", cursor: "pointer" }}
        onClick={handleDeleteDescription}
      />
    </Box>
  );
}
