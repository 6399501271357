import React, { useState } from "react";
//Components
import TabItem from "./tabItem";
import TabHeader from "./tabHeader";
function PanelActionNavbar({ actionType, setActionType }) {
  const [headerMenu, setHeaderMenu] = useState(true);
  const [headerApp, setHeaderApp] = useState(false);
  const [headerMasa, setHeaderMasa] = useState(false);
  return (
    <div
      style={{
        width: "99%",
        backgroundColor: "#fff",
        borderRadius: 6,
        display: "flex",
        alignItems: "center",
        minHeight: 50,
        marginBottom: 5,
        padding: 10,
      }}
    >
      <div
        style={{
          width: "auto",
          display: "flex",
          alignItems: "center",
          marginTop: 5,
          marginBottom: 3,
        }}
      >
        <TabHeader
          title={"Menü"}
          headerType={headerMenu}
          setHeaderMenu={setHeaderMenu}
          setHeaderApp={setHeaderApp}
          setHeaderMasa={setHeaderMasa}
          menuStatus={true}
          appStatus={false}
          masaStatus={false}
          setActionType={setActionType}
        />

        <TabHeader
          title={"App"}
          headerType={headerApp}
          setHeaderMenu={setHeaderMenu}
          setHeaderApp={setHeaderApp}
          setHeaderMasa={setHeaderMasa}
          menuStatus={false}
          appStatus={true}
          masaStatus={false}
          setActionType={setActionType}
        />

        <TabHeader
          title={"Masa"}
          headerType={headerMasa}
          setHeaderMenu={setHeaderMenu}
          setHeaderApp={setHeaderApp}
          setHeaderMasa={setHeaderMasa}
          menuStatus={false}
          appStatus={false}
          masaStatus={true}
          setActionType={setActionType}
        />
      </div>
      <div style={{ marginLeft: 20 }}>
        <div
          style={{
            display: headerMasa ? "flex" : "none",
            backgroundColor: headerMasa ? "snow" : "transparent",
          }}
        >
          <TabItem
            itemTitle={"Reklam"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"advert"}
          />
          <TabItem
            itemTitle={"Yanında Ne Gider"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"sidedish"}
          />
          <TabItem
            itemTitle={"İndirim"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"discount"}
          />
        </div>
        <div
          style={{
            width: "auto",
            display: headerApp ? "flex" : "none",
            justifyContent: "space-around",
            backgroundColor: headerApp ? "snow" : "transparent",
          }}
        >
          <TabItem
            itemTitle={"Bölge"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"area"}
          />
          <TabItem
            itemTitle={"Bildirim"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"push"}
          />
          <TabItem
            itemTitle={"Puan-Yorum"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"rating"}
          />
          <TabItem
            itemTitle={"Reklam"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"advert"}
          />
          <TabItem
            itemTitle={"Yanında Ne Gider"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"sidedish"}
          />
          <TabItem
            itemTitle={"İndirim"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"discount"}
          />
          <TabItem
            itemTitle={"Ödeme Y."}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"payment_type"}
          />
          <TabItem
            itemTitle={"Çalışma S."}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"working_hours"}
          />
          <TabItem
            itemTitle={"Müşteriler"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"customers"}
          />
        </div>
        <div
          style={{
            display: headerMenu ? "flex" : "none",
            backgroundColor: headerMenu ? "snow" : "transparent",
          }}
        >
          <TabItem
            itemTitle={"Sil"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"delete"}
          />
          <TabItem
            itemTitle={"Aç - Kapat"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"openOrClose"}
          />
          <TabItem
            itemTitle={"Fiyat"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"price"}
          />
          <TabItem
            itemTitle={"Fotoğraf"}
            actionType={actionType}
            setActionType={setActionType}
            itemAction={"photoUpload"}
          />
        </div>
      </div>
    </div>
  );
}

export default PanelActionNavbar;
