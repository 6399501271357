import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";

export const NotistackProvider = ({ children }) => {
  return (
    <SnackbarProvider
      children={children}
      maxSnack={10}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NotistackProvider>
    <App />
  </NotistackProvider>
);

reportWebVitals();
