import React from "react";
import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Report } from "@mui/icons-material";
import restaurantService from "../../../../services/restaurantService";
import { useToast } from "../../../../components/snackbar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const TechSupport = ({ techSupport, setTechSupport, id }) => {
  const { alert, success } = useToast();
  const classes = useStyles();

  const sendTechSupport = async () => {
    try {
      await restaurantService.send_tech_support(id);
      success("Teknik Destek kaydı oluşturuldu.");
    } catch (error) {
      alert(error.response.data.message);
    }

    setTechSupport(false);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={techSupport}
      className={classes.modal}
      onClose={() => {
        setTechSupport(false);
      }}
    >
      <Card
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: 25,
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
        }}
      >
        <div>
          <Report style={{ width: 100, height: 100, color: "red" }} />
        </div>
        <div
          style={{
            fontFamily: "segoeuiB",
            fontSize: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Teknik Destek kaydı oluşturmak istediğinize emin misiniz?
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <Button
            style={{
              backgroundColor: "red",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 150,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              height: 40,
              textTransform: "capitalize",
              fontSize: 14,
            }}
            size="small"
            onClick={() => {
              setTechSupport(false);
            }}
          >
            Hayır
          </Button>
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 150,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              height: 40,
              textTransform: "capitalize",
              fontSize: 14,
            }}
            size="small"
            onClick={sendTechSupport}
          >
            Evet
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default TechSupport;
