import { Button } from "@mui/material";
import React from "react";
import { endCall } from "./endCall";
import { Close } from "@mui/icons-material";

const calling = (
  enqueueSnackbar,
  closeSnackbar,
  phoneNumberv2,
  callkey1,
  cdrHangupFunc
) => {
  closeSnackbar(callkey1);
  callkey1 = enqueueSnackbar(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: 20, fontFamily: "SgBd", color: "#fff" }}>
        {phoneNumberv2} aranıyor ...
      </div>
      <Button
        style={{
          color: "white",
          backgroundColor: "#f53b62",
          textTransform: "capitalize",
          fontFamily: "SgBd",
          fontSize: 14,
          marginLeft: 15,
        }}
        onClick={() => {
          endCall(cdrHangupFunc);
          closeSnackbar(callkey1);
        }}
      >
        <Close />
      </Button>
    </div>,
    {
      preventDuplicate: true,
      persist: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      sx: {
        "& .SnackbarContent-root": {
          backgroundColor: "darkgray",
        },
      },
    }
  );
};

export default calling;
