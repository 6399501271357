import React, { useState } from "react";
import { Button, Card, Modal, ClickAwayListener, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const OtherAddressModal = ({
  enduserDetail,
  setOpenOtherAddressModal,
  openOtherAddressModal,
}) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  const classes = useStyles();
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openOtherAddressModal}
      className={classes.modal}
      onClose={() => {
        setOpenOtherAddressModal(false);
      }}
    >
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          height: "80%",
          width: "90%",
          flexWrap: "wrap",
          overflow: "auto",
        }}
      >
        {enduserDetail?.length > 0 &&
          enduserDetail?.map((addres, ind) => (
            <Card
              sx={{
                padding: 1,
                margin: 3,
                width: "30%",
                border: "1px solid #f53b62",
                borderRadius: 2,
              }}
              key={ind}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontSize: 18,
                    }}
                  >
                    Adres :
                  </label>
                  <label
                    style={{
                      width: 150,
                      marginBottom: 2,
                      fontSize: 18,
                    }}
                  >
                    Adres Açıklama :
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontSize: 18,
                    }}
                  >
                    Bina No :
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontSize: 18,
                    }}
                  >
                    Kat :
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontSize: 18,
                    }}
                  >
                    Daire No :
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontSize: 18,
                    }}
                  >
                    İlçe :
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontSize: 18,
                    }}
                  >
                    Şehir :
                  </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {addres?.address.length > 10 ? (
                    <ClickAwayListener
                      onClickAway={() => setOpenToolTip(false)}
                    >
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 18,
                              fontFamily: "segoeuiRg",
                              padding: 1,
                              bgcolor: "#082657",
                              "& .MuiTooltip-arrow": {
                                color: "#082657",
                              },
                            },
                          },
                        }}
                        title={addres?.address}
                        arrow
                        placement="top"
                        open={
                          openToolTip == `open_${ind}_address` ? true : false
                        }
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: "segoeuiB",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenToolTip(true);
                          }}
                        >
                          {addres?.address}
                        </div>
                      </Tooltip>
                    </ClickAwayListener>
                  ) : (
                    <label
                      style={{
                        width: 150,
                        marginBottom: 2,
                        fontFamily: "segoeuiB",
                        fontSize: 18,
                      }}
                    >
                      {addres?.address}
                    </label>
                  )}
                  {addres?.description.length > 12 ? (
                    <ClickAwayListener
                      onClickAway={() => setOpenToolTip(false)}
                    >
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 18,
                              fontFamily: "segoeuiRg",
                              padding: 1,

                              bgcolor: "#082657",
                              "& .MuiTooltip-arrow": {
                                color: "#082657",
                              },
                            },
                          },
                        }}
                        title={addres?.description}
                        arrow
                        placement="top"
                        open={
                          openToolTip == `open_${ind}_addresDes` ? true : false
                        }
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: "segoeuiRg",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenToolTip(true);
                          }}
                        >
                          {addres?.description}
                        </div>
                      </Tooltip>
                    </ClickAwayListener>
                  ) : (
                    <label
                      style={{
                        width: 125,
                        marginBottom: 2,
                        fontFamily: "segoeuiB",
                        fontSize: 18,
                      }}
                    >
                      {addres?.description !== "" ? addres?.description : "-"}
                    </label>
                  )}
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontFamily: "segoeuiB",
                      fontSize: 18,
                    }}
                  >
                    {addres?.building_no !== "" ? addres?.building_no : "-"}
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontFamily: "segoeuiB",
                      fontSize: 18,
                      height: 27,
                    }}
                  >
                    {addres?.floor !== "" ? addres?.floor : "-"}
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontFamily: "segoeuiB",
                      fontSize: 18,
                      height: 27,
                    }}
                  >
                    {addres?.door_number !== "" ? addres?.door_number : "-"}
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontFamily: "segoeuiB",
                      fontSize: 18,
                    }}
                  >
                    {addres?.town__name !== "" ? addres?.town__name : "-"}
                  </label>
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontFamily: "segoeuiB",
                      fontSize: 18,
                    }}
                  >
                    {addres?.city__name !== "" ? addres?.city__name : "-"}
                  </label>
                </div>
              </div>
            </Card>
          ))}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            marginBottom: 25,
            marginRight: 30,
          }}
        >
          <Button
            variant="contained"
            size="medium"
            color="error"
            style={{
              textTransform: "capitalize",
            }}
            onClick={() => setOpenOtherAddressModal(false)}
          >
            Kapat
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default OtherAddressModal;
