import { useContext, useState } from "react";
import { Box, Modal, Button } from "@mui/material";
import { selectDefaultStyles } from "../../selectStyles/stylev3";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import restaurantService from "../../../services/restaurantService";
import { useToast } from "../../snackbar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { RestaurantContext } from "../../../contexts/restaurantContext";

export default function ChangeCollectorModal({
  showChangeCollectorModal,
  setShowChangeCollectorModal,
  userId,
  backofficeCollectorList,
  fromUser,
}) {
  const [selectedUser, setSelectedUser] = useState(null);
  const { id } = useParams();
  const { alert, success } = useToast();
  const { getOfficeuserDetail } = useContext(RestaurantContext);

  const sendToCollector = () => {
    let data = {
      from_officeuser: fromUser.user_id,
      to_officeuser: selectedUser.user_id,
    };
    restaurantService
      .change_collector(data, userId)
      .then(() => {
        getOfficeuserDetail(id);
        setShowChangeCollectorModal(false);
        success("Tahsilatçı değiştirildi");
        setSelectedUser(null);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  return (
    <Modal
      open={showChangeCollectorModal}
      onClose={() => setShowChangeCollectorModal(false)}
    >
      <Box
        sx={{
          ...styles.modal,
          minHeight: 150,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Aktarmak istediğiniz Tahsilatçıyı Seçiniz
            </span>

            <Select
              options={backofficeCollectorList}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              placeholder={"Seçiniz"}
              value={selectedUser}
              getOptionValue={(option) => option.user_id}
              onChange={(e) => {
                setSelectedUser(e);
              }}
              getOptionLabel={(option) => option.full_name}
            />

            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#f53b62",
                marginTop: "20px",
                width: "100%",
              }}
              onClick={sendToCollector}
            >
              Aktar
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
