import {
  Badge,
  Box,
  createTheme,
  Modal,
  TablePagination,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";

import StarIcon from "@mui/icons-material/Star";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../contexts/managementContext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "segoeuiB",
    },
  },
}));

export default function RatingList({
  result = [],
  openModal,
  setOpenModal,
  resultCount = 0,
  restId,
}) {
  const classes = useStyles();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { getServicesReportsRating } = useContext(ManagementContext);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (openModal && restId) {
      let data = {
        restId,
        page: page + 1,
      };
      getServicesReportsRating(data.restId, data.page);
    }
  }, [page]);

  useEffect(() => {
    if (!openModal) {
      setPage(0);
    }
  }, [openModal]);

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "40%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Yorum Listesi
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "500px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <Box>
            {result?.map((row, i) => {
              return (
                <div
                  key={i}
                  style={{
                    border: "1px solid #c3c3c3",
                    padding: 10,
                    marginBottom: 10,
                    borderRadius: 8,
                  }}
                >
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: 18,
                        marginRight: 10,
                        fontFamily: "segoeuiRg",
                      }}
                    >
                      {row.portfoy_manager.name}
                    </span>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 11,
                        fontFamily: "segoeuiL",
                      }}
                    >
                      {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                    </span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: 11,
                          fontFamily: "segoeuiL",
                        }}
                      >
                        Program Puanı
                      </p>
                      <Badge color="primary">
                        <StarIcon fontSize="11" style={{ color: "#f53b62" }} />
                        <span style={{ fontSize: 11 }}>{row.app_rating}</span>
                      </Badge>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: 11,
                          fontFamily: "segoeuiL",
                        }}
                      >
                        Hizmet Puanı
                      </p>
                      <Badge color="primary">
                        <StarIcon fontSize="11" style={{ color: "#f53b62" }} />
                        <span style={{ fontSize: 11 }}>
                          {row.portfoy_manager_rating}
                        </span>
                      </Badge>
                    </div>
                  </div>
                  {row.note && (
                    <div
                      style={{
                        border: "1px solid #f4f4f4",
                        padding: 5,
                        marginTop: 10,
                      }}
                    >
                      <h5
                        style={{
                          margin: 0,
                          fontFamily: "segoeuiRg",
                          marginBottom: 5,
                        }}
                      >
                        Yorum:
                      </h5>
                      <div style={{ fontSize: 14, wordBreak: "break-word" }}>
                        {row.note}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Box>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#fff",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10]}
            labelRowsPerPage={"Sayfa Başına Kayıt"}
            count={resultCount}
            rowsPerPage={rowsPerPage}
            page={page}
            classes={{ root: classes.root }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
