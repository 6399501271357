import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import InfoIcon from "@mui/icons-material/Info";
import StackedBar from "../../../components/charts/StackedBar";
import CustomDatePickerInput from "../../../components/datePickerInput";
import useWindowDimensions from "../../../components/windowSize";

import { RestaurantContext } from "../../../contexts/restaurantContext";

import managementService from "../../../services/managementService";

import moment from "moment";
import { DescriptionTwoTone } from "@mui/icons-material";
import RatingList from "../../../components/modals/ratingList";
import { ManagementContext } from "../../../contexts/managementContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Paths } from "../../../utils/constants";
import ApplicationActions from "../../../components/modals/applicationActions";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

export default function ServiceReports() {
  const classes = useStyles();
  const [workerSelect, setWorkerSelect] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const { width } = useWindowDimensions();
  const [result, setResult] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [restId, setRestId] = useState(null);
  let history = useHistory();
  const [applicationActionsModal, setApplicationActionsModal] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [pageStatus, setPageStatus] = useState("home");

  const {
    getApplicationTransferList,
    backofficeList,
    getTrackerNotes,
    trackerNotes,
    postTrackerNotes,
  } = useContext(RestaurantContext);

  const { getServicesReportsRating, ratingsInfo } =
    useContext(ManagementContext);

  const getServicesReports = async (data) => {
    const response = await managementService.get_services_reports(data);
    setResult(response);
  };

  const handleChangeWorker = (e) => {
    setWorkerSelect(e);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleShowComments = async (rest) => {
    setRestId(rest.restofficial.user_id);
    getServicesReportsRating(rest.restofficial.user_id, 1);
    setOpenModal(true);

    let data = {
      id: rest.restofficial.user_id,
      obj: {
        rating_processed: true,
      },
    };

    let data2 = {
      start: moment(startDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD"),
      page: page + 1,
      userId:
        workerSelect && workerSelect.user_id !== 0 ? workerSelect.user_id : "",
    };
    managementService.update_services_reports(data).then((res) => {
      getServicesReports(data2);
    });
  };

  useEffect(() => {
    backofficeList.unshift({
      user_id: 0,
      full_name: "Tümü",
    });
  }, [backofficeList]);

  useEffect(() => {
    let data = {
      start: moment(startDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD"),
      page: page + 1,
      userId:
        workerSelect && workerSelect.user_id !== 0 ? workerSelect.user_id : "",
    };
    if (startDate && endDate) {
      getServicesReports(data);
    }
  }, [workerSelect, startDate, endDate, page]);

  useEffect(() => {
    setPage(0);
  }, [startDate, endDate]);

  useEffect(() => {
    getApplicationTransferList();
  }, []);

  return (
    <Container>
      <h3
        style={{
          fontFamily: "segoeuiB",
          fontSize: 24,
          textAlign: "center",
        }}
      >
        HİZMET RAPORLARI
      </h3>
      <Box sx={{ display: "flex" }}>
        <Box>
          <div style={{ marginRight: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Tarih
            </span>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              fixedHeight="45px"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              onChange={(update) => {
                setDateRange(update);
              }}
              customInput={
                <CustomDatePickerInput selectWidth={width > 1200 ? 230 : 140} />
              }
              locale="tr"
            />
          </div>
        </Box>
        <div>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Kişiler
          </span>
          <Select
            options={backofficeList}
            styles={{ ...selectDefaultStyles, height: "555px" }}
            isSearchable={false}
            hoverColor={"black"}
            menuColor={"#f53b62"}
            selectWidth={width > 1200 ? 230 : 140}
            placeholder={"Tümü"}
            value={workerSelect}
            getOptionValue={(option) => option.user_id}
            onChange={(e) => {
              handleChangeWorker(e);
            }}
            getOptionLabel={(option) => option.full_name}
          />
        </div>
      </Box>
      <Box>
        <Grid
          container
          columns={12}
          spacing={2}
          sx={{ marginTop: 2, marginBottom: 4 }}
        >
          <Grid item xs={4}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <h3
                style={{
                  margin: 0,
                  marginBottom: 10,
                  fontFamily: "segoeuiL",
                  color: "darkslategray",
                  fontSize: 18,
                }}
              >
                HİZMET
              </h3>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <span
                  style={{
                    fontSize: 16,
                    marginLeft: 5,
                    fontFamily: "segoeuiL",
                  }}
                >
                  {result.servis_oran}
                </span>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <h3
                style={{
                  margin: 0,
                  marginBottom: 10,
                  fontFamily: "segoeuiL",
                  color: "darkslategray",
                  fontSize: 18,
                }}
              >
                PROGRAM
              </h3>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <span
                  style={{
                    fontSize: 16,
                    marginLeft: 5,
                    fontFamily: "segoeuiL",
                  }}
                >
                  {result.program_oran}
                </span>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <h3
                style={{
                  margin: 0,
                  marginBottom: 10,
                  fontFamily: "segoeuiL",
                  color: "darkslategray",
                  fontSize: 18,
                }}
              >
                ORTALAMA
              </h3>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <span
                  style={{
                    fontSize: 16,
                    marginLeft: 5,
                    fontFamily: "segoeuiL",
                  }}
                >
                  {((result.servis_oran + result.program_oran) / 2).toFixed(1)}
                </span>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Yorum tarihi</TableCell>
                <TableCell align="center">Restoran</TableCell>
                <TableCell align="center">Yorum</TableCell>
                <TableCell align="center">Yorum Adet</TableCell>
                <TableCell align="center">Notlar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result?.results?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">
                      {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => {
                        history.push(
                          `${Paths.kunye}/${row?.restofficial.user_id}`
                        );
                      }}
                      style={{
                        color: "#f53b62",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          window.open(
                            `${window.location.origin}/restaurants/${row?.restofficial.user_id}`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      {row?.restofficial?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleShowComments(row)}
                    >
                      <Tooltip title="Yorum detay">
                        <DescriptionTwoTone style={{ color: "#f53b62" }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{row.total}</TableCell>
                    <TableCell align="center">
                      <InfoIcon
                        onClick={async () => {
                          setApplicationActionsModal(true);
                          setApplicationId(row.restofficial);
                        }}
                        style={{
                          color: row.rating_processed ? "green" : "#f53b62",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                {result.results != null && (
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    labelRowsPerPage={"Sayfa Başına Kayıt"}
                    count={result?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    classes={{ root: classes.root }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {result?.grafik?.length > 0 && (
          <Box sx={{ width: "50%" }}>
            <StackedBar
              title={"Adet / Hizmet Puan / Program Puan"}
              data={[
                {
                  dataKey: "hizmet",
                  name: "Hizmet Puan",
                  color: "#03bf00",
                },
                {
                  dataKey: "program",
                  name: "Program Puan",
                  color: "blue",
                },
                {
                  dataKey: "adet",
                  name: "Adet",
                  color: "red",
                },
              ]}
              graph={result?.grafik}
            />
          </Box>
        )}

        <RatingList
          openModal={openModal}
          setOpenModal={setOpenModal}
          result={ratingsInfo?.results}
          resultCount={ratingsInfo?.count}
          restId={restId}
        />

        <ApplicationActions
          applicationActionsModal={applicationActionsModal}
          setApplicationActionsModal={setApplicationActionsModal}
          applicationId={applicationId}
          pageStatus={pageStatus}
          setApplicationId={setApplicationId}
          getNotes={getTrackerNotes}
          notes={trackerNotes}
          data_type="restofficial"
          createNewNote={postTrackerNotes}
        />
      </Box>
    </Container>
  );
}
