import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Card, InputBase, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EditorState, convertToRaw } from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";

import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import { offerCount } from "../../../../utils/constants";
import draftToHtml from "draftjs-to-html";
import { ManagementContext } from "../../../../contexts/managementContext";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const CommunicationModal = ({
  communicationModal,
  setCommunicationModal,
  currentRow,
  setCurrentRow,
}) => {
  const classes = useStyles();
  const [editorStateUpdate, setEditorStateUpdate] = useState(() =>
    EditorState.createEmpty()
  );
  const [image1, setImage1] = useState(null);
  const [sendingBannerImage, setSendingBannerImage] = useState(null);
  const imageRef1 = useRef();
  const [image2, setImage2] = useState(null);
  const [sendingBannerImage2, setSendingBannerImage2] = useState(null);
  const imageRef2 = useRef();
  const [bannerHeader, setBannerHeader] = useState(null);
  const [selectedShowTime, setSelectedShowTime] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [blacklistOnClick, setBlackListOnClick] = useState(false);
  const { bannerTemplateChangeFunc } = useContext(ManagementContext);

  useEffect(() => {
    if (currentRow !== null) {
      const html = currentRow?.content;
      const contentState = stateFromHTML(html);
      setEditorStateUpdate(EditorState.createWithContent(contentState));

      setImage1(currentRow?.image);
      setImage2(currentRow?.image2);
      setBannerHeader(currentRow?.name);
      setSelectedShowTime({
        value: currentRow?.show_time,
        label: currentRow?.show_time,
      });
      setSelectedPriority({
        value: currentRow?.priority,
        label: currentRow?.priority,
      });
      setBlackListOnClick(currentRow?.blacklist_on_click);
    }
  }, [currentRow]);

  const onEditorStateChange = (editorState) => {
    setEditorStateUpdate(editorState);
  };

  const onImageChange1 = (e) => {
    let valueData = e.target ? e.target.files[0] : e;
    setSendingBannerImage(valueData);
    setImage1(URL.createObjectURL(e.target.files[0]));
  };

  const onImageChange2 = (e) => {
    let valueData = e.target ? e.target.files[0] : e;
    setSendingBannerImage2(valueData);
    setImage2(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={communicationModal}
        className={classes.modal}
        onClose={() => {
          setCommunicationModal(false);
        }}
      >
        <Card
          className={classes.paper}
          style={{
            width: "60%",
            display: "flex",
            //justifyContent: "center",
            alignItems: "center",
            padding: 25,
            flexDirection: "column",
            maxHeight: 600,
            overflow: "auto",
          }}
        >
          <div>
            <div style={{ width: "auto", height: 200 }}>
              <input
                type="file"
                multiple
                accept="image/*"
                style={{ display: "none" }}
                onChange={onImageChange1}
                ref={imageRef1}
              />

              <img
                src={image1}
                style={{
                  cursor: "pointer",
                  width: "auto",
                  height: 200,
                }}
                onClick={() => imageRef1.current.click()}
              />
            </div>
            <div style={{ width: "auto", height: 200 }}>
              <input
                type="file"
                multiple
                accept="image/*"
                style={{ display: "none" }}
                onChange={onImageChange2}
                ref={imageRef2}
              />

              <img
                src={image2}
                style={{
                  cursor: "pointer",
                  width: "auto",
                  height: 200,
                }}
                onClick={() => imageRef2.current.click()}
              />
            </div>
            <Card
              style={{
                width: "95%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                height: "100%",
                backgroundColor: "#f4f4f4",
              }}
            >
              <div style={{ alignSelf: "start" }}>
                <InputBase
                  multiline
                  spellCheck={false}
                  style={{
                    width: "100%",
                    fontFamily: "segoeuiRg",
                    paddingLeft: 8,
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    marginTop: 20,
                  }}
                  rows={1}
                  placeholder={"Banner İsmi"}
                  value={bannerHeader}
                  onChange={(e) => {
                    setBannerHeader(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 300,
                  padding: 20,
                  marginTop: 20,
                }}
              >
                <Editor
                  editorState={editorStateUpdate}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "fontFamily",
                      "fontSize",
                      "colorPicker",
                      "emoji",
                      "list",
                      "inline",
                      "link",
                    ],
                    inline: {
                      options: [
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "monospace",
                      ],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                    link: {
                      options: ["link"],
                    },
                  }}
                />
              </div>
            </Card>

            <div
              style={{
                width: "100%",
                height: 50,
                boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                borderRadius: 4,
                backgroundColor: "whitesmoke",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                textAlign: "center",
                padding: "20px 10px",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Select
                  responsiveHeight={30}
                  selectFontSize={16}
                  options={offerCount}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={90}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  selectWidth={150}
                  placeholder={"Sıralama"}
                  menuPlacement="top"
                  value={selectedPriority}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedPriority(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.label}
                    </div>
                  )}
                />
                <Select
                  responsiveHeight={30}
                  selectFontSize={16}
                  options={offerCount}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={90}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  selectWidth={150}
                  placeholder={"Gösterim (sn)"}
                  menuPlacement="top"
                  value={selectedShowTime}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedShowTime(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.label}
                    </div>
                  )}
                />
                <div>
                  <div
                    style={{
                      fontFamily: "segoeuiRg",
                      fontSize: 14,
                    }}
                  >
                    Tıklandığında kapansın
                  </div>
                  <div>
                    <GreenSwitch
                      onClick={() => {
                        setBlackListOnClick(!blacklistOnClick);
                      }}
                      checked={blacklistOnClick}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              style={{
                color: "white",
                backgroundColor: "red",
                padding: "5px 10px 5px 10px",
                textTransform: "capitalize",
                fontFamily: "SgSemi",
                fontSize: 14,
                width: "40%",
              }}
              // startIcon={<Save />}
              onClick={async () => {
                let data = {
                  temp_id: currentRow?.id,
                  sendObj: {
                    name: bannerHeader,
                    content: draftToHtml(
                      convertToRaw(editorStateUpdate.getCurrentContent())
                    ),
                    forward_to: "",
                    image: sendingBannerImage,
                    image2: sendingBannerImage2,
                    priority: selectedPriority,
                    show_time: selectedShowTime,
                    blacklist_on_click: blacklistOnClick,
                  },
                };
                bannerTemplateChangeFunc(data);
              }}
            >
              Gönder
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default CommunicationModal;
