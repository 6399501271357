import { Modal, Slider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { createRef, useContext, useState } from "react";

import Cropper from "react-easy-crop";

import getCroppedImg from "./cropImage";
import { RestaurantContext } from "../../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxHeight: "100vh",
  },
}));

const ProductPhotoModal = ({
  productPhotoModal,
  setProductPhotoModal,
  menuPhotoUploadFunc,
  row,
  currentProductType,
  menuHeaderPhotoUploadFunc,
  id,
  setOpenProductPoolModal,
  taskId,
}) => {
  const classes = useStyles();
  const fileInputRef = createRef();
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(1);
  const [rotation, setRotation] = useState(0);
  const { createPictureFromTask } = useContext(RestaurantContext);
  const [imageFile, setImageFile] = useState(null);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onFileInputClick = () => {
    fileInputRef.current.value = "";
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  const onFileInputChange = async (event) => {
    const res = await readFile(event.target.files[0]);
    setImage(res);
    setImageFile(event.target.files[0]);
  };

  const sendCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
      rotation
    );
    if (currentProductType === "header") {
      menuHeaderPhotoUploadFunc({
        name: row?.name,
        product_id: row?.id,
        croppedImage,
        restofficial_id: id,
      });
    } else if (currentProductType === "task") {
      const formData = new FormData();
      formData.append("picture", imageFile);
      createPictureFromTask(taskId, formData);
    } else {
      menuPhotoUploadFunc({
        product_id: row?.id,
        croppedImage,
        restofficial_id: id,
      });
    }
    setImage(null);
    setZoom(1);
    setRotation(0);
    setCrop({ x: 0, y: 0 });
    setProductPhotoModal(false);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      onClose={() => {
        setProductPhotoModal(false);
        setZoom(1);
        setCroppedAreaPixels(1);
        setRotation(0);
      }}
      open={productPhotoModal}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "absolute",
            textAlign: "-webkit-center",
            width: "98%",
            color: "#fff",
            fontSize: 40,
            fontFamily: "SgBd",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Resim Boyutu: 400x400</span>
          <span>Resim Formatları: JPG / JPEG / PNG /WEBP</span>
        </div>
        <div>
          <Cropper
            image={
              image != null
                ? image
                : "https://www.makliftsan.com.tr/mp-include/uploads/images/no-foto.jpg"
            }
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={aspect}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            cropSize={{ width: 400, height: 400 }}
            minZoom={0.1}
            onRotationChange={setRotation}
            zoomSpeed={0.1}
          />
        </div>

        <div
          style={{
            boxSizing: "border-box",
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "100%",
            padding: "0 16px",
            height: 50,
            display: "flex",
            alignItems: "center",
            backgroundColor: "antiquewhite",
            borderRadius: 8,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "40%",
            }}
          >
            <Typography variant="overline">Rotation {rotation}</Typography>
            <div
              style={{
                display: "flex",
                width: "10%",
              }}
            >
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "60%",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: 30,
            }}
          >
            {currentProductType !== "task" && (
              <div
                style={{
                  backgroundColor: "green",
                  padding: 3,
                  cursor: "pointer",
                  borderRadius: 4,
                  color: "#fff",
                  fontSize: 15,
                  boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                  minWidth: 80,
                  textAlign: "center",
                }}
                onClick={() => {
                  setOpenProductPoolModal(true);
                }}
              >
                Havuzdan Çek
              </div>
            )}

            <input
              ref={fileInputRef}
              type="file"
              onClick={onFileInputClick}
              onChange={onFileInputChange}
            />
            <div
              style={{
                backgroundColor: "green",
                padding: 3,
                cursor: "pointer",
                borderRadius: 4,
                color: "#fff",
                fontSize: 15,
                boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                minWidth: 80,
                textAlign: "center",
              }}
              onClick={() => {
                sendCroppedImage();
              }}
            >
              Yükle
            </div>
            <div
              style={{
                backgroundColor: "red",
                padding: 3,
                cursor: "pointer",
                borderRadius: 4,
                color: "#fff",
                fontSize: 15,
                boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                minWidth: 80,
                textAlign: "center",
              }}
              onClick={() => {
                setProductPhotoModal(false);
                setZoom(1);
                setCroppedAreaPixels(1);
                setRotation(0);
              }}
            >
              Kapat
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductPhotoModal;
