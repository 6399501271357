import React, { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import SearchWithIcon from "../../../../components/searchWithIcon";
import { Button, Divider, InputBase, Paper, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
}));

const OfferChange = () => {
  const classes = useStyles();
  const { financeOfferFunc, financeOffer, financeOfferChangeFunc } =
    useContext(ManagementContext);
  const [searchTerm, setSearchTerm] = useState(null);
  const [financeOfferObj, setFinanceOfferObj] = useState(null);

  useEffect(() => {
    if (financeOffer !== null) {
      setFinanceOfferObj(financeOffer);
    }
  }, [financeOffer]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SearchWithIcon
          setSearchTerm={setSearchTerm}
          placeTitle={"Restoran Id"}
          customWidth={"20%"}
          searchTerm={searchTerm}
        />
        <Button
          style={{
            backgroundColor: "#f53b62",
            color: "#fff",
            fontFamily: "segoeuiB",
            width: 100,
            display: "flex",
            justifyContent: "center",
            height: 40,
            marginLeft: 10,
          }}
          size="small"
          onClick={() => {
            financeOfferFunc({
              rest_id: searchTerm,
            });
          }}
        >
          Ara
        </Button>
      </div>
      {financeOfferObj !== null && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: "#fff",
              boxShadow: "#bebebebf 0px 0px 6px 2px",
              color: "black",
              marginTop: 30,
              width: "30%",
              height: 100,
            }}
          >
            <div
              style={{
                backgroundColor: "#4D4949",
                marginBottom: 5,
                fontSize: 15,
                fontWeight: 600,
                textTransform: "capitalize",
                borderRadius: "3px",
                color: "#fff",
              }}
            >
              {financeOffer?.restofficial_name}
            </div>

            <div
              style={{
                fontSize: 14,
                marginBottom: 3,
                fontFamily: "segoeuiRg",
                display: "flex",
              }}
            >
              <div style={{ width: 80 }}>Toplam Fiyat</div> : ₺{" "}
              {financeOffer?.total_amount}
            </div>
            <div
              style={{
                fontSize: 14,
                marginBottom: 3,
                display: "flex",
                fontFamily: "segoeuiRg",
              }}
            >
              <div style={{ width: 80, textAlign: "start" }}>Toplam Eft</div> :{" "}
              ₺ {financeOffer?.eft_amount}
            </div>
          </Paper>
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: "#fff",
              boxShadow: "#bebebebf 0px 0px 6px 2px",
              color: "black",
              marginTop: 30,
              width: "50%",
            }}
          >
            <div
              style={{
                backgroundColor: "#4D4949",
                textAlign: "center",
                marginBottom: 5,
                fontSize: 15,
                fontWeight: 600,
                textTransform: "capitalize",
                borderRadius: "3px",
                color: "#fff",
              }}
            >
              Ürünler
            </div>

            {financeOfferObj?.products?.map((row, ind) => {
              return (
                <div>
                  <Divider
                    orientation="horizontal"
                    sx={{
                      backgroundColor: "#f4f4f4",
                      width: "100%",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: 14,
                      marginBottom: 3,
                      fontFamily: "segoeuiRg",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: 120 }}>{row?.name} </div>
                    <div>₺ {financeOffer?.products[ind]?.amount} </div>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "85%",
                        }}
                      >
                        <InputBase
                          spellCheck="false"
                          style={{
                            width: "50%",
                            fontFamily: "SgBd",
                            fontSize: 12,
                            marginLeft: 8,
                            fontWeight: "bold",
                            backgroundColor: "#f4f4f4",
                          }}
                          value={row?.amount}
                          placeholder={"Yeni Fiyat"}
                          className={classes.input}
                          onChange={(e) => {
                            const financeOfferObjCopy = Object.assign(
                              [],
                              JSON.parse(JSON.stringify({ ...financeOfferObj }))
                            );
                            financeOfferObjCopy.products[ind].amount =
                              e.target.value;
                            setFinanceOfferObj(financeOfferObjCopy);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <Divider
              orientation="horizontal"
              sx={{
                backgroundColor: "#f4f4f4",
                width: "100%",
                height: "auto",
                margin: "10px 0px",
              }}
            />
            <div style={{ display: "flex", justifyContent: "end", margin: 20 }}>
              <Button
                style={{
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 100,
                  display: "flex",
                  justifyContent: "center",
                  height: 40,
                  marginLeft: 10,
                }}
                size="small"
                onClick={() => {
                  financeOfferChangeFunc({
                    rest_id: financeOfferObj?.restofficial_id,
                    sendObj: {
                      id: financeOfferObj?.id,
                      products: financeOfferObj?.products,
                    },
                  });
                }}
              >
                Onayla
              </Button>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default OfferChange;
