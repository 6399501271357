import { InputBase } from "@mui/material";
import React from "react";

export const CustomerInput = ({
  fieldHeader,
  fieldValue,
  fieldName,
  formData,
  setFormData,
  disabledStatus,
}) => {
  const handleChange = (name) => (event) => {
    let valueData = event.target ? event.target.value : event;
    let newDataRest = { ...formData };

    newDataRest = { ...formData, [name]: valueData };

    setFormData(newDataRest);
  };
  return (
    <div style={{ flex: 1 }}>
      <span
        style={{
          color: "gray",
          fontFamily: "segoeuiRg",
          fontSize: 15,
          fontWeight: 700,
          marginLeft: 8,
        }}
      >
        {fieldHeader}
      </span>

      <div
        style={{
          flex: 1,
          backgroundColor: "#f4f4f4",
          height: 45,
          marginTop: 4,
          borderRadius: 6,
          marginRight: 14,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderRadius: 8,
          }}
        >
          <InputBase
            disabled={disabledStatus ? disabledStatus : false}
            variant="filled"
            name={fieldName}
            style={{
              width: "100%",
              color: "black",
              marginLeft: 14,
              fontSize: 16,
              fontWeight: "400",
              fontFamily: "segoeuiRg",
              height: 45,
            }}
            value={fieldValue}
            onChange={handleChange(fieldName)}
          />
        </div>
      </div>
    </div>
  );
};
