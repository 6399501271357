import React from "react";
import { platformIconImageConverter } from "../../../components/converter/platformImage";
import { makeStyles } from "@mui/styles";
//css
const useStyles = makeStyles(() => ({
  platformSingleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 5,
  },
  greenRedInfo: {
    marginBottom: 3,
    width: 10,
    height: 10,
    borderRadius: 2,
  },
}));
const Platforms = ({ restaurantInfo, checkPlatform }) => {
  const classes = useStyles();
  return (
    <div className={classes.platformSingleBox}>
      {!restaurantInfo?.is_active ? (
        <>
          <div
            className={classes.greenRedInfo}
            style={{
              backgroundColor: "gray",
            }}
          />
          <img
            src={platformIconImageConverter(checkPlatform + "p")}
            width={30}
            alt="platform"
          />
        </>
      ) : (
        <>
          <div
            className={classes.greenRedInfo}
            style={{
              backgroundColor:
                restaurantInfo?.state_working === "open" ? "#18cd18" : "red",
            }}
          />
          <img
            src={platformIconImageConverter(checkPlatform)}
            width={30}
            alt="platform"
          />
        </>
      )}
    </div>
  );
};

export default Platforms;
