import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  InputBase,
  Paper,
} from "@mui/material";

import { ManagementContext } from "../../../../contexts/managementContext";
import SendSettingsModal from "../modals/sendSettings";
import {
  AddPhotoAlternate,
  DescriptionTwoTone,
  ImageSearch,
} from "@mui/icons-material";
import { forwardList } from "../../../../utils/constants/arrays";
import ContentNoteModal from "../modals/contentNote";
import { EditorState, convertToRaw } from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";

const PopupSettings = () => {
  const {
    createPopupTemplateFunc,
    popupTemplateListFunc,
    popupTemplateList,
    platformAndProductsFunc,
    platformAndProducts,
    buttonActionsListFunc,
    buttonActionsList,
  } = useContext(ManagementContext);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [popupHeader, setPopupHeader] = useState("");
  const [popupContent, setPopupContent] = useState("");
  const [popupType, setPopupType] = useState(null);
  const [selectedAppProduct, setSelectedAppProduct] = useState(null);
  const [selectedAppProductName, setSelectedAppProductName] = useState(null);
  const [selectedForwardPage, setSelectedForwardPage] = useState(null);
  const [sendSettingsModal, setSendSettingsModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [image1, setImage1] = useState(null);
  const [sendingBannerImage, setSendingBannerImage] = useState(null);
  const imageRef1 = useRef();
  const [image2, setImage2] = useState(null);
  const [sendingBannerImage2, setSendingBannerImage2] = useState(null);
  const imageRef2 = useRef();
  const [selectedButtonActions, setSelectedButtonActions] = useState(null);
  const [contentNoteModal, setContentNoteModal] = useState(false);

  useEffect(() => {
    popupTemplateListFunc();
    platformAndProductsFunc();
    buttonActionsListFunc();
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);

    setPopupContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const onImageChange1 = (e) => {
    let valueData = e.target ? e.target.files[0] : e;

    setSendingBannerImage(valueData);

    setImage1(URL.createObjectURL(e.target.files[0]));
  };

  const onImageChange2 = (e) => {
    let valueData = e.target ? e.target.files[0] : e;

    setSendingBannerImage2(valueData);

    setImage2(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "50%",
            height: "auto",
            display: "flex",
            alignItems: "center",
            minHeight: 700,
          }}
        >
          <Paper
            elevation={1}
            style={{
              backgroundColor: "#fff",
              width: "90%",
              height: 700,
              display: "flex",
              padding: 20,
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <div>
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  backgroundColor: "#f4f4f4",
                  borderRadius: 4,
                }}
                rows={1}
                placeholder={"Popup İsmi"}
                value={popupHeader}
                onChange={(e) => {
                  setPopupHeader(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="file"
                multiple
                accept="image/*"
                style={{ display: "none" }}
                onChange={onImageChange1}
                ref={imageRef1}
              />
              {image1 != null ? (
                <img
                  src={image1}
                  style={{
                    marginTop: 15,
                    cursor: "pointer",
                    width: "100%",
                    height: 200,
                  }}
                  onClick={() => imageRef1.current.click()}
                />
              ) : (
                <div
                  style={{
                    marginTop: 15,
                    width: 120,
                    height: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddPhotoAlternate
                    style={{
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                    }}
                    onClick={() => imageRef1.current.click()}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="file"
                multiple
                accept="image/*"
                style={{ display: "none" }}
                onChange={onImageChange2}
                ref={imageRef2}
              />
              {image2 != null ? (
                <img
                  src={image2}
                  style={{
                    marginTop: 15,
                    cursor: "pointer",
                    width: "100%",
                    height: 200,
                  }}
                  onClick={() => imageRef2.current.click()}
                />
              ) : (
                <div
                  style={{
                    marginTop: 15,
                    width: 120,
                    height: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddPhotoAlternate
                    style={{
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                    }}
                    onClick={() => imageRef2.current.click()}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: 20 }}>
              <div style={{ backgroundColor: "#f4f4f4", minHeight: 200 }}>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "fontFamily",
                      "fontSize",
                      "colorPicker",
                      "emoji",
                      "list",
                      "inline",
                      "link",
                    ],
                    inline: {
                      options: [
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "monospace",
                      ],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                    link: {
                      options: ["link"],
                    },
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <Paper
                elevation={1}
                style={{
                  padding: 5,
                  width: "25%",
                }}
              >
                <div
                  style={{
                    margin: 5,
                    fontFamily: "segoeuiRg",
                    color: "gray",
                    fontSize: 14,
                  }}
                >
                  Popup türü
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 100,
                      boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                      borderRadius: 4,
                      backgroundColor: "whitesmoke",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      textAlign: "center",
                      padding: "20px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Normal
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setPopupType("normal");
                            }}
                            checked={popupType === "normal" ? true : false}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Başvuru
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setPopupType("application");
                            }}
                            checked={popupType === "application" ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Yönlendirme
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setPopupType("forward");
                            }}
                            checked={popupType === "forward" ? true : false}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Referans
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setPopupType("ref");
                            }}
                            checked={popupType === "ref" ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>

              {popupType === "application" ? (
                <Paper elevation={1} style={{ padding: 5, width: "75%" }}>
                  <div
                    style={{
                      margin: 5,
                      fontFamily: "segoeuiRg",
                      color: "gray",
                      fontSize: 14,
                    }}
                  >
                    Popup ürün veya platform
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 100,
                        boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                        borderRadius: 4,
                        backgroundColor: "whitesmoke",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {platformAndProducts?.package_products?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedAppProduct?.id === row?.id) {
                                      setSelectedAppProduct(null);
                                      setSelectedAppProductName(null);
                                    } else {
                                      setSelectedAppProductName(row?.name);
                                      setSelectedAppProduct({
                                        id: row?.id,
                                        type: "product",
                                      });
                                    }
                                  }}
                                  checked={
                                    selectedAppProductName === row?.name
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {platformAndProducts?.platforms?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedAppProduct?.id === row?.id) {
                                      setSelectedAppProduct(null);
                                      setSelectedAppProductName(null);
                                    } else {
                                      setSelectedAppProductName(row?.name);
                                      setSelectedAppProduct({
                                        id: row?.id,
                                        type: "platform",
                                      });
                                    }
                                  }}
                                  checked={
                                    selectedAppProductName === row?.name
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Paper>
              ) : popupType === "forward" ? (
                <Paper elevation={1} style={{ padding: 5, width: "75%" }}>
                  <div
                    style={{
                      margin: 5,
                      fontFamily: "segoeuiRg",
                      color: "gray",
                      fontSize: 14,
                    }}
                  >
                    Yönlendirelecek sayfa
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 100,
                        boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                        borderRadius: 4,
                        backgroundColor: "whitesmoke",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {forwardList?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedForwardPage === row?.id) {
                                      setSelectedForwardPage(null);
                                    } else {
                                      setSelectedForwardPage(row?.id);
                                    }
                                  }}
                                  checked={
                                    selectedForwardPage === row?.id
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Paper>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Paper elevation={1} style={{ padding: 5, width: "100%" }}>
                <div
                  style={{
                    margin: 5,
                    fontFamily: "segoeuiRg",
                    color: "gray",
                    fontSize: 14,
                  }}
                >
                  Button seçimi
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 100,
                      boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                      borderRadius: 4,
                      backgroundColor: "whitesmoke",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      textAlign: "center",
                      padding: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      {buttonActionsList?.map((row) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 14,
                              }}
                            >
                              {row?.name}
                            </div>
                            <div>
                              <GreenSwitch
                                onClick={() => {
                                  const selectedButtonActionsObj =
                                    Object.assign(
                                      [],
                                      JSON.parse(
                                        JSON.stringify({
                                          ...selectedButtonActions,
                                        })
                                      )
                                    );
                                  let buttonActionsId =
                                    selectedButtonActionsObj.findIndex(
                                      (item) => item === row?.id
                                    );
                                  if (buttonActionsId == -1) {
                                    selectedButtonActionsObj.push(row?.id);
                                  } else {
                                    selectedButtonActionsObj.splice(
                                      buttonActionsId,
                                      1
                                    );
                                  }
                                  setSelectedButtonActions(
                                    selectedButtonActionsObj
                                  );
                                }}
                                checked={selectedButtonActions?.includes(
                                  row?.id
                                )}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 10,
              }}
            >
              <Button
                style={{
                  backgroundColor: "green",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  height: 40,
                }}
                size="small"
                onClick={() => {
                  let data = {
                    sendObj: {
                      name: popupHeader,
                      content: popupContent,
                      popup_type: popupType,
                      package_products:
                        selectedAppProduct?.type === "product"
                          ? [selectedAppProduct?.id]
                          : [],
                      popup_actions: selectedButtonActions
                        ?.map((row) => row)
                        .join(","),
                      platforms:
                        selectedAppProduct?.type === "platform"
                          ? [selectedAppProduct?.id]
                          : [],
                      custom_application:
                        selectedAppProduct === null ? popupHeader : "",
                      forward_to: selectedForwardPage,
                      image: sendingBannerImage,
                      image2: sendingBannerImage2,
                    },
                    setPopupHeader,
                    setPopupType,
                    setPopupContent,
                    setSelectedAppProduct,
                    setSelectedButtonActions,
                    setSelectedForwardPage,
                    setImage1,
                    setImage2,
                    setSendingBannerImage,
                    setSendingBannerImage2,
                  };
                  createPopupTemplateFunc(data);
                }}
              >
                Oluştur
              </Button>
            </div>
          </Paper>
        </div>
        <div
          style={{
            width: "50%",
            height: 700,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Paper
            elevation={1}
            style={{
              backgroundColor: "#fff",
              width: "90%",
              height: "90%",
              display: "flex",
              padding: 20,
              flexDirection: "column",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Popup İsmi</TableCell>
                    <TableCell align="center">Görsel</TableCell>
                    <TableCell align="center">Buton ismi</TableCell>
                    <TableCell align="center">İçerik</TableCell>
                    <TableCell align="center">Popup Tipi</TableCell>
                    <TableCell align="center">Etki Alanı</TableCell>
                    <TableCell align="center">Aksiyon</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {popupTemplateList?.map((row, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">{row?.name}</TableCell>
                        <TableCell align="center">
                          {row?.image != null ? (
                            <ImageSearch
                              onClick={() =>
                                window.open(`${row?.image}`, "_blank")
                              }
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <span>-</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row?.popup_actions?.map((row) => {
                            return (
                              <>
                                {row?.name}
                                <br />
                              </>
                            );
                          })}
                        </TableCell>
                        <TableCell align="center">
                          <DescriptionTwoTone
                            style={{
                              cursor: "pointer",
                              width: 24,
                              height: 24,
                            }}
                            onClick={() => {
                              setCurrentRow(row);
                              setContentNoteModal(true);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {row?.popup_type === "forward"
                            ? "Yönlendir"
                            : row?.popup_type === "application"
                            ? "Başvuru"
                            : row?.popup_type === "normal"
                            ? "Normal"
                            : "Referans"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.popup_type === "forward"
                            ? row?.forward_to
                            : row?.popup_type === "application"
                            ? row?.package_products?.length !== 0
                              ? row?.package_products[0]?.name
                              : row?.platforms[0]?.name
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            style={{
                              backgroundColor: "green",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              display: "flex",
                              justifyContent: "center",
                              height: 40,
                            }}
                            size="small"
                            onClick={() => {
                              setSendSettingsModal(true);
                              setCurrentRow(row);
                            }}
                          >
                            Yolla
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <SendSettingsModal
        sendSettingsModal={sendSettingsModal}
        setSendSettingsModal={setSendSettingsModal}
        platformAndProducts={platformAndProducts}
        currentRow={currentRow}
        type="popup"
      />
      <ContentNoteModal
        contentNoteModal={contentNoteModal}
        setContentNoteModal={setContentNoteModal}
        currentRow={currentRow}
      />
    </>
  );
};

export default PopupSettings;
