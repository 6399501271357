import React, { useEffect } from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
    input: {
        "& .MuiInputBase-input": {
            width: "100%",
            fontSize: 14,
        },
        "& input::placeholder": {
            fontSize: 14,
        },
    },
}));

const SearchV2 = ({
    searchTerm,
    placeholder,
    setSearchTerm,
    sendSearchTermFunc,
}) => {
    const classes = useStyles();

    return (
        <Paper
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                height: 42,
                backgroundColor: "#c9d1cc",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "85%",
                }}
            >
                <IconButton>{/* <img src={searchLogo} /> */}</IconButton>
                <InputBase
                    spellCheck="false"
                    style={{ width: "100%", fontFamily: "segoeuiRg" }}
                    placeholder={placeholder}
                    className={classes.input}
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
            </div>
            <IconButton
                onClick={() => {
                    sendSearchTermFunc();
                }}
                type="submit"
                aria-label="search"
                style={{ width: "15%" }}
            >
                <Search />
            </IconButton>
        </Paper>
    );
};

export default SearchV2;
