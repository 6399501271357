import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, Modal } from "@mui/material";
import Dictionary from "../../../pages/operation/dictionary";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 100%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const UserStatusModal = ({ statusModal, userStatus }) => {
  const classes = useStyles();

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={statusModal}
      className={classes.modal}
      componentsProps={{
        backdrop: { style: { top: 60 } },
        root: { style: { top: 60 } },
      }}
    >
      {userStatus === "dictionary" ? (
        <Dictionary />
      ) : (
        <Card
          style={{
            width: "30%",
            display: "flex",
            padding: 25,
            flexDirection: "column",
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              fontSize: 28,
              fontWeight: 600,
              fontFamily: "monospace",
            }}
          >
            {userStatus === "break"
              ? "Mola"
              : userStatus === "training"
              ? "Eğitim"
              : userStatus === "meeting"
              ? "Toplantı"
              : userStatus === "wc"
              ? "Wc"
              : userStatus === "passive"
              ? "İş Bitiş"
              : userStatus === "setup"
              ? "Kurulum"
              : ""}
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default UserStatusModal;
