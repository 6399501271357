import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  InputBase,
  Modal,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RestaurantContext } from "../../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: 10,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 20%)",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    marginBottom: 80,
  },
}));

const CreateHeader = ({
  createHeaderModal,
  setCreateHeaderModal,
  id,
  menuHeaderCreateFunc,
}) => {
  const classes = useStyles();
  const [addHeader, setAddHeader] = useState("");
  const inputRef = useRef(null);

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      createHeader();
    }
  };

  useEffect(() => {
    if (createHeaderModal) {
      inputRef.current.focus();
    }
  }, [createHeaderModal]);

  const createHeader = () => {
    let data = {
      name: addHeader,
      sepetapp: true,
      store: true,
      restofficial_id: id,
    };
    menuHeaderCreateFunc(data);
    setAddHeader("");
  };
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={createHeaderModal}
      className={classes.modal}
      onClose={() => {
        setCreateHeaderModal(false);
        setAddHeader("");
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#f4f4f4",
          padding: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 15,
            marginTop: 5,
          }}
        >
          <span
            style={{
              width: "auto",
              color: "#293c51",
              fontFamily: "segoeuiRg",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Başlık Oluştur
          </span>
        </div>
        <CardContent
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Paper
            component="form"
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "center",
              margin: 2,
            }}
          >
            <InputBase
              inputRef={inputRef}
              onKeyDown={keyPress}
              spellCheck="false"
              size="medium"
              value={addHeader}
              className={classes.input}
              style={{
                fontSize: 14,
                width: "95%",
                fontFamily: "segoeuiB",
              }}
              onChange={(e) => {
                setAddHeader(e.target.value);
              }}
            />
          </Paper>

          <Button
            style={{
              backgroundColor: "#f53b62",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 100,
              marginLeft: 15,
              display: "flex",
              justifyContent: "center",
              height: 32,
              textTransform: "capitalize",
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
            }}
            size="small"
            onClick={() => {
              createHeader();
              setCreateHeaderModal(false);
            }}
          >
            Oluştur
          </Button>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default CreateHeader;
