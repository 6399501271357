import React, { useState, useContext, useEffect } from "react";

import { createTheme, useMediaQuery, Button, InputBase } from "@mui/material";

import { RestaurantContext } from "../../../contexts/restaurantContext";
import { CustomerInput } from "../../../components/CustomerInput";
import Colors from "../../../utils/colors";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Save } from "@mui/icons-material";

const Platforms = () => {
  const {
    restofficialPlatforms,
    getRestofficialPlatforms,
    deleteRestofficialPlatforms,
    setDealarSidebarPage,
    postSecretKey,
    platformList,
    getPlatformList,
    restOfficialData,
    ysRemoteIdListFunc,
    ysRemoteIdList,
    ysRemoteIdCreateFunc,
    ysRemoteIdDeleteFunc,
  } = useContext(RestaurantContext);
  const [trendyolFormData, setTrendyolFormData] = useState();
  const [yemeksepetiFormData, setYemeksepetiFormData] = useState();
  const [yemeksepetiFormDatav2, setYemeksepetiFormDatav2] = useState({});
  const [getirFormData, setGetirFormData] = useState();
  const [gofodyFormData, setgofodyFormData] = useState();
  const [migrosFormData, setMigrosFormData] = useState();
  const [fuudyFormData, setFuudyFormData] = useState();

  const [trendyolData, setTrendyolData] = useState([]);
  const [yemeksepetiData, setYemeksepetiData] = useState([]);
  const [yemeksepetiDatav2, setYemeksepetiDatav2] = useState([]);
  const [getirData, setGetirData] = useState([]);
  const [gofodyData, setGofodyData] = useState([]);
  const [migrosData, setMigrosData] = useState([]);
  const [fuudyData, setFuudyData] = useState([]);

  const [isTrendyolNewItem, setisTrendyolNewItem] = useState(false);
  const [isGetirNewItem, setisGetirNewItem] = useState(false);
  const [isGofodyNewItem, setisGofodyNewItem] = useState(false);
  const [isYemeksepetiNewItem, setisYemeksepetiNewItem] = useState(false);
  const [isYemeksepetiNewItemv2, setisYemeksepetiNewItemv2] = useState(false);
  const [isMigrosNewItem, setisMigrosNewItem] = useState(false);
  const [isFuudyNewItem, setisFuudyNewItem] = useState(false);

  let { id } = useParams();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    setDealarSidebarPage(1);
    setTrendyolFormData({});
    setGetirFormData({});
    setgofodyFormData({});
    setGetirData([]);
    setGofodyData([]);
    setTrendyolData([]);
    setYemeksepetiData([]);
    setMigrosData([]);
    setFuudyData([]);

    setData();
    ysRemoteIdListFunc({ rest_id: id });
  }, [restofficialPlatforms]);

  useEffect(() => {
    if (ysRemoteIdList !== null) {
      setYemeksepetiDatav2(ysRemoteIdList);
    }
  }, [ysRemoteIdList]);

  useEffect(() => {
    getRestofficialPlatforms(id);
    getPlatformList();
  }, [id]);
  //09b8d94b7f063fab3e08bda72e929f7812be9b0f
  const setData = () => {
    restofficialPlatforms.length > 0 &&
      restofficialPlatforms?.map((item) => {
        if (item.platform.name === "Gofody") {
          const newData = restofficialPlatforms?.filter(
            (itm) => itm.platform.name === "Gofody"
          );
          const filteredNewData = newData.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.secret_key === item.secret_key)
          );
          setGofodyData(filteredNewData);
        } else if (item.platform.name === "Yemeksepeti") {
          const newData = restofficialPlatforms?.filter(
            (itm) =>
              itm.platform.name === "Yemeksepeti" && itm.yemeksepetiv2 === false
          );
          const newDatav2 = restofficialPlatforms?.filter(
            (itm) =>
              itm.platform.name === "Yemeksepeti" && itm.yemeksepetiv2 === true
          );
          setYemeksepetiData(newData);
          setYemeksepetiDatav2(newDatav2);
        } else if (item.platform.name === "Getir") {
          const newData = restofficialPlatforms?.filter(
            (itm) => itm.platform.name === "Getir"
          );
          setGetirData(newData);
        } else if (item.platform.name === "Trendyol") {
          const newData = restofficialPlatforms?.filter(
            (itm) => itm.platform.name === "Trendyol"
          );

          setTrendyolData(newData);
        } else if (item.platform.name === "Migros") {
          const newData = restofficialPlatforms?.filter(
            (itm) => itm.platform.name === "Migros"
          );
          setMigrosData(newData);
        } else if (item.platform.name === "Fuudy") {
          const newData = restofficialPlatforms?.filter(
            (itm) => itm.platform.name === "Fuudy"
          );
          setFuudyData(newData);
        }
      });
  };

  const approvall = async (platform, data) => {
    await postSecretKey(data, id);

    if (platform === "Yemeksepeti") {
      setisYemeksepetiNewItem(false);
    } else if (platform === "Trendyol") {
      setisTrendyolNewItem(false);
    } else if (platform === "Getir") {
      setisGetirNewItem(false);
    } else if (platform === "Migros") {
      setisMigrosNewItem(false);
    } else if (platform === "Fuudy") {
      setisFuudyNewItem(false);
    } else if (platform === "Gofody") {
      setisGofodyNewItem(false);
    }
  };

  const approvallv2 = async (data) => {
    await postSecretKey(data, id);

    setisYemeksepetiNewItemv2(false);
  };

  const ApplyButton = ({ onClick, label, contentStyle, style, props }) => {
    return (
      <div
        style={{
          ...styles.applyBtnContent,
          ...contentStyle,
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{ ...styles.applyBtn, ...style }}
          onClick={onClick}
        >
          {label ? label : "Başvur"}
        </Button>
      </div>
    );
  };

  const yemeksepetiNewCard = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ ...styles.item }}>
          <span
            style={{
              ...styles.boxText,
              fontWeight: 900,
            }}
          >
            {yemeksepetiData.length != 0
              ? "Yeni Secret Key Ekle"
              : "Yemek Sepeti"}
          </span>
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Kategori ID"
            fieldName="platform_user_id"
            formData={yemeksepetiFormData}
            setFormData={setYemeksepetiFormData}
            fieldValue={yemeksepetiFormData?.platform_user_id}
          />
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Kullanıcı Adı"
            fieldName="secret_key"
            formData={yemeksepetiFormData}
            setFormData={setYemeksepetiFormData}
            fieldValue={yemeksepetiFormData?.secret_key}
          />
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Şifre"
            fieldName="token"
            formData={yemeksepetiFormData}
            setFormData={setYemeksepetiFormData}
            fieldValue={yemeksepetiFormData?.token}
          />

          <Button
            color="success"
            variant="contained"
            size="small"
            style={{
              alignSelf: "end",
            }}
            onClick={() => approvall("Yemeksepeti", yemeksepetiFormData)}
          >
            Onayla
          </Button>
        </div>
      </div>
    );
  };
  const yemeksepetiNewCardv2 = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ ...styles.item }}>
          <span
            style={{
              ...styles.boxText,
              fontWeight: 900,
            }}
          >
            {yemeksepetiData.length != 0
              ? "Yeni Secret Key Ekle"
              : "Yemek Sepeti v2"}
          </span>
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Restoran İsmi"
            fieldName="restaurant_name"
            formData={yemeksepetiFormDatav2}
            setFormData={setYemeksepetiFormDatav2}
            fieldValue={yemeksepetiFormDatav2?.restaurant_name}
          />
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Yemeksepeti Vendor Id"
            fieldName="yemeksepeti_restaurant_id"
            formData={yemeksepetiFormDatav2}
            setFormData={setYemeksepetiFormDatav2}
            fieldValue={yemeksepetiFormDatav2?.yemeksepeti_restaurant_id}
          />
        </div>
        <div style={styles.item}>
          <Button
            color="success"
            variant="contained"
            size="small"
            style={{
              alignSelf: "end",
            }}
            onClick={() => {
              ysRemoteIdCreateFunc({
                sendObj: yemeksepetiFormDatav2,
                res_id: id,
              });
            }}
          >
            Onayla
          </Button>
        </div>
      </div>
    );
  };
  const trendyolNewCard = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ ...styles.item }}>
          <span style={{ ...styles.boxText, fontWeight: 900 }}>
            {trendyolData?.length != 0 ? "Yeni Secret Key Ekle" : "Trendyol"}
          </span>
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Store ID"
            fieldName="trendyol_restaurant_id"
            formData={trendyolFormData}
            setFormData={setTrendyolFormData}
            fieldValue={trendyolFormData?.trendyol_restaurant_id}
          />
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Satıcı ID"
            fieldName="platform_user_id"
            formData={trendyolFormData}
            setFormData={setTrendyolFormData}
            fieldValue={trendyolFormData?.platform_user_id}
          />
        </div>

        <div style={styles.item}>
          <CustomerInput
            fieldHeader="API Key"
            fieldName="secret_key"
            formData={trendyolFormData}
            setFormData={setTrendyolFormData}
            fieldValue={trendyolFormData?.secret_key}
          />
        </div>
        <div style={styles.item}>
          <CustomerInput
            fieldHeader="API Secret Key"
            fieldName="token"
            formData={trendyolFormData}
            setFormData={setTrendyolFormData}
            fieldValue={trendyolFormData?.token}
          />

          <Button
            color="success"
            variant="contained"
            size="small"
            style={{
              alignSelf: "end",
            }}
            onClick={() => approvall("Trendyol", trendyolFormData)}
          >
            Onayla
          </Button>
        </div>
      </div>
    );
  };
  const getirNewCard = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ ...styles.item }}>
          <span style={{ ...styles.boxText, fontWeight: 900 }}>
            {getirData?.length != 0 ? "Yeni Secret Key Ekle" : "Getir"}
          </span>
        </div>

        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Restoran Secret Key"
            fieldName="secret_key"
            formData={getirFormData}
            setFormData={setGetirFormData}
            fieldValue={getirFormData?.secret_key}
          />

          <Button
            color="success"
            variant="contained"
            size="small"
            style={{
              alignSelf: "end",
            }}
            onClick={() => approvall("Getir", getirFormData)}
          >
            Onayla
          </Button>
        </div>
      </div>
    );
  };

  const gofodyNewCard = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ ...styles.item }}>
          <span style={{ ...styles.boxText, fontWeight: 900 }}>
            {gofodyData?.length != 0 ? "Yeni Secret Key Ekle" : "Gofody"}
          </span>
        </div>

        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Restoran Secret Key"
            fieldName="secret_key"
            formData={gofodyFormData}
            setFormData={setgofodyFormData}
            fieldValue={gofodyFormData?.secret_key}
          />

          <Button
            color="success"
            variant="contained"
            size="small"
            style={{
              alignSelf: "end",
            }}
            onClick={() => approvall("Gofody", gofodyFormData)}
          >
            Onayla
          </Button>
        </div>
      </div>
    );
  };

  const migrosNewCard = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ ...styles.item }}>
          <span style={{ ...styles.boxText, fontWeight: 900 }}>
            {migrosData?.length != 0 ? "Yeni Secret Key Ekle" : "Migros"}
          </span>
        </div>

        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Zincir Id"
            fieldName="platform_user_id"
            formData={migrosFormData}
            setFormData={setMigrosFormData}
            fieldValue={migrosFormData?.platform_user_id}
          />
        </div>

        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Restoran Id"
            fieldName="migros_restaurant_id"
            formData={migrosFormData}
            setFormData={setMigrosFormData}
            fieldValue={migrosFormData?.migros_restaurant_id}
          />
        </div>

        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Api Key"
            fieldName="secret_key"
            formData={migrosFormData}
            setFormData={setMigrosFormData}
            fieldValue={migrosFormData?.secret_key}
          />

          <Button
            color="success"
            variant="contained"
            size="small"
            style={{
              alignSelf: "end",
            }}
            onClick={() => approvall("Migros", migrosFormData)}
          >
            Onayla
          </Button>
        </div>
      </div>
    );
  };

  const fuudyNewCard = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ ...styles.item }}>
          <span style={{ ...styles.boxText, fontWeight: 900 }}>
            {fuudyData?.length != 0 ? "Yeni Secret Key Ekle" : "Fuudy"}
          </span>
        </div>

        <div style={styles.item}>
          <CustomerInput
            fieldHeader="Restoran Secret Key"
            fieldName="secret_key"
            formData={fuudyFormData}
            setFormData={setFuudyFormData}
            fieldValue={fuudyFormData?.secret_key}
          />

          <Button
            color="success"
            variant="contained"
            size="small"
            style={{
              alignSelf: "end",
            }}
            onClick={() => approvall("Fuudy", fuudyFormData)}
          >
            Onayla
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={{ fontWeight: 600, marginBottom: 10 }}>
        Kalan Toplam Tabela Hakkınız: {restOfficialData?.coklu_tabela_kredi}
      </div>
      <div
        style={{
          ...styles.itemContent,

          width: matches ? "45%" : "90%",
        }}
      >
        {getirData?.length != 0 ? (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Getir"}
              </span>
            </div>
            {getirData?.map((itm, idx) => {
              return (
                <div key={idx}>
                  <div style={styles.statuRow}>
                    <span style={{ fontWeight: 500 }}>
                      {"Secret Key Durumu"}
                    </span>
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Restoran Secret Key"
                      fieldName="secret_key"
                      fieldValue={itm?.secret_key}
                    />

                    <DeleteIcon
                      variant="Filled"
                      size="large"
                      style={styles.deleteIcon}
                      onClick={() =>
                        deleteRestofficialPlatforms(
                          id,
                          itm.id,
                          itm?.platform?.id
                        )
                      }
                    />
                  </div>
                  <div style={styles.lineStyle} />
                </div>
              );
            })}

            {isGetirNewItem ? (
              getirNewCard()
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 12,
                  marginBottom: 10,
                }}
              >
                <ApplyButton
                  label="Yeni secret Key Ekle"
                  onClick={() => {
                    const platfrm = platformList?.find(
                      (item) => item.name === "Getir"
                    );
                    setisGetirNewItem(true);
                    setGetirFormData({
                      secret_key: "",
                      restofficial: +id,
                      platform: platfrm.id,
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : isGetirNewItem ? (
          getirNewCard()
        ) : (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Getir"}
              </span>
            </div>
            <ApplyButton
              label="Başvur"
              onClick={() => {
                const platfrm = platformList?.find(
                  (item) => item.name === "Getir"
                );
                setisGetirNewItem(true);
                setGetirFormData({
                  secret_key: "",
                  restofficial: +id,
                  platform: platfrm.id,
                });
              }}
            />
          </>
        )}
      </div>
      <div
        style={{
          ...styles.itemContent,

          width: matches ? "45%" : "90%",
        }}
      >
        {gofodyData?.length != 0 ? (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Gofody"}
              </span>
            </div>
            {gofodyData?.map((itm, idx) => {
              return (
                <div key={idx}>
                  <div style={styles.statuRow}>
                    <span style={{ fontWeight: 500 }}>
                      {"Secret Key Durumu"}
                    </span>
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Restoran Secret Key"
                      fieldName="secret_key"
                      fieldValue={itm?.secret_key}
                    />

                    <DeleteIcon
                      variant="Filled"
                      size="large"
                      style={styles.deleteIcon}
                      onClick={() =>
                        deleteRestofficialPlatforms(
                          id,
                          itm.id,
                          itm?.platform?.id
                        )
                      }
                    />
                  </div>
                  <div style={styles.lineStyle} />
                </div>
              );
            })}

            {isGofodyNewItem ? (
              gofodyNewCard()
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 12,
                  marginBottom: 10,
                }}
              >
                <ApplyButton
                  label="Yeni secret Key Ekle"
                  onClick={() => {
                    const platfrm = platformList?.find(
                      (item) => item.name === "Gofody"
                    );
                    setisGofodyNewItem(true);
                    setgofodyFormData({
                      secret_key: "",
                      restofficial: +id,
                      platform: platfrm.id,
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : isGofodyNewItem ? (
          gofodyNewCard()
        ) : (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Gofody"}
              </span>
            </div>
            <ApplyButton
              label="Başvur"
              onClick={() => {
                const platfrm = platformList?.find(
                  (item) => item.name === "Gofody"
                );
                setisGofodyNewItem(true);
                setgofodyFormData({
                  secret_key: "",
                  restofficial: +id,
                  platform: platfrm.id,
                });
              }}
            />
          </>
        )}
      </div>
      <div style={styles.rowLine} />
      <div style={{ ...styles.itemContent, width: matches ? "45%" : "90%" }}>
        {trendyolData?.length != 0 ? (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Trendyol"}
              </span>
            </div>
            {trendyolData?.map((itm, idx) => {
              return (
                <div key={idx}>
                  <div style={styles.statuRow}>
                    <span style={{ fontWeight: 500 }}>
                      {"Secret Key Durumu"}
                    </span>
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Store ID"
                      fieldName="trendyol_restaurant_id"
                      formData={""}
                      setFormData={""}
                      fieldValue={itm.trendyol_restaurant_id}
                    />
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Satıcı ID"
                      fieldName="platform_user_id"
                      fieldValue={itm.platform_user_id}
                    />
                  </div>

                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="API Key"
                      fieldName="secret_key"
                      fieldValue={itm.secret_key}
                    />
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="API Secret Key"
                      fieldName="token"
                      fieldValue={itm.token}
                    />

                    <DeleteIcon
                      variant="Filled"
                      size="large"
                      style={styles.deleteIcon}
                      onClick={() =>
                        deleteRestofficialPlatforms(
                          id,
                          itm.id,
                          itm?.platform?.id
                        )
                      }
                    />
                  </div>
                  <div style={styles.lineStyle} />
                </div>
              );
            })}
            {isTrendyolNewItem ? (
              trendyolNewCard()
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 12,
                  marginBottom: 10,
                }}
              >
                <ApplyButton
                  label="Yeni secret Key Ekle"
                  onClick={() => {
                    const platfrm = platformList?.find(
                      (item) => item.name === "Trendyol"
                    );
                    setisTrendyolNewItem(true);
                    setTrendyolFormData({
                      restofficial: +id,
                      platform: platfrm.id,
                      secret_key: "",
                      token: "",
                      platform_user_id: "",
                      trendyol_restaurant_id: "",
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : isTrendyolNewItem ? (
          trendyolNewCard()
        ) : (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Trendyol"}
              </span>
            </div>
            <ApplyButton
              label="Başvur"
              onClick={() => {
                const platfrm = platformList?.find(
                  (item) => item.name === "Trendyol"
                );
                setisTrendyolNewItem(true);
                setTrendyolFormData({
                  restofficial: +id,
                  platform: platfrm.id,
                  secret_key: "",
                  token: "",
                  platform_user_id: "",
                  trendyol_restaurant_id: "",
                });
              }}
            />
          </>
        )}
      </div>
      <div style={{ ...styles.itemContent, width: matches ? "45%" : "90%" }}>
        {yemeksepetiData?.length != 0 ? (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Yemek Sepeti"}
              </span>
            </div>
            {yemeksepetiData?.map((itm, idx) => {
              return (
                <div key={idx}>
                  <div style={styles.statuRow}>
                    <span style={{ fontWeight: 500 }}>
                      {"Secret Key Durumu"}
                    </span>
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Kategori ID"
                      fieldName="platform_user_id"
                      fieldValue={itm?.platform_user_id}
                    />
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Kullanıcı Adı"
                      fieldName="secret_key"
                      fieldValue={itm?.secret_key}
                    />
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Şifre"
                      fieldName="token"
                      fieldValue={itm?.token}
                    />
                    <DeleteIcon
                      variant="Filled"
                      size="large"
                      style={styles.deleteIcon}
                      onClick={() =>
                        deleteRestofficialPlatforms(
                          id,
                          itm.id,
                          itm?.platform?.id
                        )
                      }
                    />
                  </div>
                  <div style={styles.lineStyle} />
                </div>
              );
            })}

            {isYemeksepetiNewItem ? (
              yemeksepetiNewCard()
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 12,
                  marginBottom: 10,
                }}
              >
                <ApplyButton
                  label="Yeni secret Key Ekle"
                  onClick={() => {
                    const platfrm = platformList?.find(
                      (item) => item.name === "Yemeksepeti"
                    );
                    setisYemeksepetiNewItem(true);
                    setYemeksepetiFormData({
                      secret_key: "",
                      restofficial: +id,
                      platform: platfrm.id,
                      token: "",
                      platform_user_id: "",
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : isYemeksepetiNewItem ? (
          yemeksepetiNewCard()
        ) : (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Yemek Sepeti"}
              </span>
            </div>
            <ApplyButton
              label="Başvur"
              onClick={() => {
                const platfrm = platformList?.find(
                  (item) => item.name === "Yemeksepeti"
                );
                setisYemeksepetiNewItem(true);
                setYemeksepetiFormData({
                  secret_key: "",
                  restofficial: +id,
                  platform: platfrm.id,
                  token: "",
                  platform_user_id: "",
                });
              }}
            />
          </>
        )}
      </div>
      <div style={{ ...styles.itemContent, width: matches ? "45%" : "90%" }}>
        {yemeksepetiDatav2?.length != 0 ? (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Yemek Sepeti V2"}
              </span>
            </div>
            {yemeksepetiDatav2?.map((itm, idx) => {
              return (
                <div key={idx}>
                  <div style={styles.statuRow}>
                    <span style={{ fontWeight: 500 }}>
                      {"Secret Key Durumu"}
                    </span>
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Restoran İsmi"
                      fieldName="restaurant_name"
                      fieldValue={itm?.restaurant_name}
                    />
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Yemeksepeti Vendor Id"
                      fieldName="yemeksepeti_restaurant_id"
                      fieldValue={itm?.yemeksepeti_restaurant_id}
                    />
                  </div>
                  <div style={styles.item}>
                    <div style={{ flex: 1 }}>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        Remote Id
                      </span>

                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "#f4f4f4",
                          height: 45,
                          marginTop: 4,
                          borderRadius: 6,
                          marginRight: 14,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: 8,
                          }}
                        >
                          <InputBase
                            disabled={true}
                            variant="filled"
                            style={{
                              width: "100%",
                              color: "black",
                              marginLeft: 14,
                              fontSize: 16,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 45,
                            }}
                            value={itm?.remote_id}
                          />
                        </div>
                      </div>
                    </div>
                    <DeleteIcon
                      variant="Filled"
                      size="large"
                      style={{
                        alignSelf: "end",
                        marginBottom: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        ysRemoteIdDeleteFunc({ id: itm?.id, res_id: id });
                      }}
                    />
                  </div>
                  <div style={styles.item}>
                    <div style={{ flex: 1 }}>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        Chain Id
                      </span>

                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "#f4f4f4",
                          height: 45,
                          marginTop: 4,
                          borderRadius: 6,
                          marginRight: 14,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: 8,
                          }}
                        >
                          <InputBase
                            variant="filled"
                            style={{
                              width: "100%",
                              color: "black",
                              marginLeft: 14,
                              fontSize: 16,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 45,
                            }}
                            name={"platform_user_id"}
                            value={itm?.platform_user_id}
                            onChange={(e) => {
                              let newDataRest = { ...yemeksepetiDatav2 };
                              newDataRest[idx].platform_user_id =
                                e.target.value;
                              setYemeksepetiFormDatav2(newDataRest);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <Save
                      variant="Filled"
                      size="large"
                      style={{
                        alignSelf: "end",
                        marginBottom: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const platfrm = platformList?.find(
                          (item) => item.name === "Yemeksepeti"
                        );
                        let data = {
                          restofficial: id,
                          platform: platfrm?.id,
                          platform_user_id: itm?.platform_user_id,
                          yemeksepeti_remote_id: itm?.id,
                          yemeksepetiv2: true,
                        };
                        approvallv2(data);
                      }}
                    />
                  </div>
                  <div style={styles.lineStyle} />
                </div>
              );
            })}

            {isYemeksepetiNewItemv2 ? (
              yemeksepetiNewCardv2()
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 12,
                  marginBottom: 10,
                }}
              >
                <ApplyButton
                  label="Yeni secret Key Ekle"
                  onClick={() => {
                    setisYemeksepetiNewItemv2(true);
                    setYemeksepetiFormDatav2({
                      restofficial: +id,
                      restaurant_name: "",
                      yemeksepeti_restaurant_id: "",
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : isYemeksepetiNewItemv2 ? (
          yemeksepetiNewCardv2()
        ) : (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Yemek Sepeti v2"}
              </span>
            </div>
            <ApplyButton
              label="Başvur"
              onClick={() => {
                setisYemeksepetiNewItemv2(true);
                setYemeksepetiFormDatav2({
                  restofficial: +id,
                  restaurant_name: "",
                  yemeksepeti_restaurant_id: "",
                });
              }}
            />
          </>
        )}
      </div>
      <div style={{ ...styles.itemContent, width: matches ? "45%" : "90%" }}>
        {migrosData?.length != 0 ? (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Migros"}
              </span>
            </div>
            {migrosData?.map((itm, idx) => {
              return (
                <div key={idx}>
                  <div style={styles.statuRow}>
                    <span style={{ fontWeight: 500 }}>
                      {"Secret Key Durumu"}
                    </span>
                  </div>

                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Zincir Id"
                      fieldName="platform_user_id"
                      formData={migrosFormData}
                      setFormData={setMigrosFormData}
                      fieldValue={itm?.platform_user_id}
                    />
                  </div>

                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Restoran Id"
                      fieldName="migros_restaurant_id"
                      formData={migrosFormData}
                      setFormData={setMigrosFormData}
                      fieldValue={itm?.migros_restaurant_id}
                    />
                  </div>

                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Api Key"
                      fieldName="secret_key"
                      formData={migrosFormData}
                      setFormData={setMigrosFormData}
                      fieldValue={itm?.secret_key}
                    />
                    <DeleteIcon
                      variant="Filled"
                      size="large"
                      style={styles.deleteIcon}
                      onClick={() =>
                        deleteRestofficialPlatforms(
                          id,
                          itm.id,
                          itm?.platform?.id
                        )
                      }
                    />
                  </div>
                  <div style={styles.lineStyle} />
                </div>
              );
            })}

            {isMigrosNewItem ? (
              migrosNewCard()
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 12,
                  marginBottom: 10,
                }}
              >
                <ApplyButton
                  label="Yeni secret Key Ekle"
                  onClick={() => {
                    const platfrm = platformList?.find(
                      (item) => item.name === "Migros"
                    );
                    setisMigrosNewItem(true);
                    setMigrosFormData({
                      secret_key: "",
                      restofficial: +id,
                      platform: platfrm.id,
                      platform_user_id: "",
                      migros_restaurant_id: "",
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : isMigrosNewItem ? (
          migrosNewCard()
        ) : (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Migros"}
              </span>
            </div>
            <ApplyButton
              label="Başvur"
              onClick={() => {
                const platfrm = platformList?.find(
                  (item) => item.name === "Migros"
                );
                setisMigrosNewItem(true);
                setMigrosFormData({
                  secret_key: "",
                  restofficial: +id,
                  platform: platfrm.id,
                  platform_user_id: "",
                  migros_restaurant_id: "",
                });
              }}
            />
          </>
        )}
      </div>
      <div
        style={{
          ...styles.itemContent,

          width: matches ? "45%" : "90%",
        }}
      >
        {fuudyData?.length != 0 ? (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Fuudy"}
              </span>
            </div>
            {fuudyData?.map((itm, idx) => {
              return (
                <div key={idx}>
                  <div style={styles.statuRow}>
                    <span style={{ fontWeight: 500 }}>
                      {"Secret Key Durumu"}
                    </span>
                  </div>
                  <div style={styles.item}>
                    <CustomerInput
                      fieldHeader="Restoran Secret Key"
                      fieldName="secret_key"
                      fieldValue={itm?.secret_key}
                    />

                    <DeleteIcon
                      variant="Filled"
                      size="large"
                      style={styles.deleteIcon}
                      onClick={() =>
                        deleteRestofficialPlatforms(
                          id,
                          itm.id,
                          itm?.platform?.id
                        )
                      }
                    />
                  </div>
                  <div style={styles.lineStyle} />
                </div>
              );
            })}

            {isFuudyNewItem ? (
              fuudyNewCard()
            ) : (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 12,
                  marginBottom: 10,
                }}
              >
                <ApplyButton
                  label="Yeni secret Key Ekle"
                  onClick={() => {
                    const platfrm = platformList?.find(
                      (item) => item.name === "Fuudy"
                    );
                    setisFuudyNewItem(true);
                    setFuudyFormData({
                      secret_key: "",
                      restofficial: +id,
                      platform: platfrm.id,
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : isFuudyNewItem ? (
          fuudyNewCard()
        ) : (
          <>
            <div style={styles.item}>
              <span style={{ ...styles.boxText, fontWeight: 900 }}>
                {"Fuudy"}
              </span>
            </div>
            <ApplyButton
              label="Başvur"
              onClick={() => {
                const platfrm = platformList?.find(
                  (item) => item.name === "Fuudy"
                );
                setisFuudyNewItem(true);
                setFuudyFormData({
                  secret_key: "",
                  restofficial: +id,
                  platform: platfrm.id,
                });
              }}
            />
          </>
        )}
      </div>
      <div style={styles.rowLine} />
    </div>
  );
};

const styles = {
  container: {
    display: "inline-block",
    margin: "32px",
    paddingBottom: "16px",
    width: "96%",
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemContent: {
    minHeight: "160px",
    float: "left",
    backgroundColor: "#fff",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
    marginRight: 16,
    padding: "6px 10px",

    boxShadow: "5px 9px 8px 0 rgba(71, 10, 22, 0.07)",
  },
  rowLine: {
    display: "flex",
    width: "90%",
    height: 2,
    margin: 10,
    background: "transparent",
  },

  item: {
    backgroundColor: "#fff",
    width: "96%",
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 12,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteIcon: {
    alignSelf: "end",
    marginBottom: 10,
  },

  applyBtnContent: {
    height: 32,
    minHeight: 32,
    alignItems: "end",
    alignSelf: "flex-end",
    justifyContent: "flex-end",
    marginRight: 30,
  },
  switch: {
    color: "#f53f62",
    width: "60px",
    height: "26px",
  },
  boxText: {
    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 400,
    fontStyle: "normal",
    textAlign: "left",
  },
  lineStyle: {
    display: "flex",
    width: "98%",
    alignSelf: "center",
    height: 2,
    backgroundColor: Colors.mainColor,
    marginTop: 30,
  },
  statuRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    paddingLeft: 16,
    paddingRight: 12,
    width: "96%",
  },

  applyBtn: {
    fontSize: 14,
    fontWeight: 600,
    alignItems: "end",
    alignSelf: "flex-end",
    justifyContent: "flex-end",
    backgroundColor: Colors.mainColor,
    color: "#fff",
  },
};
export default Platforms;
