import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  InputBase,
  Modal,
  Paper,
  TablePagination,
  createTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { RestaurantContext } from "../../../contexts/restaurantContext";

import tr from "date-fns/locale/tr";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import moment from "moment";
import TablePaginationActions from "../../pagination/mainPagination";
import { useToast } from "../../snackbar";
import Select from "react-select";
import { selectDefaultStyles } from "../../selectStyles/stylev4";
import CustomDatePickerInput from "../../datePickerInput";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

registerLocale("tr", tr);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    //   alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },

  root: {
    backgroundColor: "#F0F0F0",
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "segoeuiB",
    },
  },
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
  overflowStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
    scrollbarWidth: "thin",
    cursor: "pointer",
    scrollbarColor: "#B7B7B7 gray",
    "&::-webkit-scrollbar": {
      height: 8,
      backgroundColor: "#f4f4f4",
      borderRadius: 4,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: `linear-gradient(to right,  ${"royalblue"} 0%,${"#f1b62a"} 100%)`,
      minHeight: 12,
      minWidth: 12,
      width: 12,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: `linear-gradient(to right,  ${"royalblue"} 0%,${"#f1b62a"} 100%)`,
    },
  },
}));

let resInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));

const ActionModal = ({
  actionModal,
  setActionModal,
  actionModalResId,
  actionVisiblity,
}) => {
  const classes = useStyles();
  const theme = createTheme();
  const [rowsPerPage, setRowsPerPage] = useState(actionVisiblity ? 5 : 100);
  const [page, setPage] = useState(0);
  const scrollRefs = useRef([]);
  const {
    getTrackerNotes,
    trackerNotes,
    restOfficialTaskActionListFunc,
    restofficialTaskActionList,
    calendarAllListFunc,
    calendarAllList,
    restOfficialTaskActionFunc,
    blockedReasonsv2,
    blockedStatusReasonsFuncv2,
    getTicketTypes,
    ticketTypes,
    trackerNotesRecordFunc,
  } = useContext(RestaurantContext);
  const [note, setNote] = useState("");
  const [selectedHeader, setSelectedHeader] = useState(null);
  const { alert } = useToast();
  const [selectedDeleteReason, setSelectedDeleteReason] = useState(null);
  const [selectedTicketType, setSelectedTicketType] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [startDate, setStartDate] = useState(
    setHours(
      setMinutes(new Date(), moment().format("mm")),
      moment().format("HH")
    )
  );
  const [selectedOfficeUser, setSelectedOfficeUser] = useState("");

  const [playbackRate, setPlaybackRate] = useState(1.0);
  const audioRef = useRef(null);

  const changePlaybackRate = (rate) => {
    if (audioRef.current) {
      audioRef.current.audio.current.playbackRate = rate;
      setPlaybackRate(rate);
    }
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
    }
    if (e.key === "Escape") {
      setActionModal(false);
    }
  };

  useEffect(() => {
    if (actionModalResId != null) {
      let data = {
        id: page + 1,
        app_id: actionModalResId?.user_id,
        page_size: actionVisiblity ? 5 : 100,
        selectedOfficeUser:
          selectedOfficeUser === null ? "system" : selectedOfficeUser,
      };
      getTrackerNotes(data);
    }
  }, [actionModalResId, page, selectedOfficeUser]);

  useEffect(() => {
    if (actionModalResId != null) {
      calendarAllListFunc();
      restOfficialTaskActionListFunc();
      blockedStatusReasonsFuncv2({ res_id: actionModalResId?.user_id });
    }
  }, [actionModalResId]);

  useEffect(() => {
    if (selectedHeader !== null && selectedHeader?.tag === "tech") {
      getTicketTypes("tech");
    }
  }, [selectedHeader]);

  scrollRefs.current = [...Array(trackerNotes?.creators?.length).keys()].map(
    (_, i) => scrollRefs.current[i] ?? createRef()
  );

  const scrollSmoothHandler = (index, user_id) => () => {
    setSelectedOfficeUser(user_id);
    scrollRefs.current[index]?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const calcNoteCount = (obj) => {
    let totalnoteCount = 0;
    obj.map((row) => {
      if (row?.note_count !== "-") {
        totalnoteCount = totalnoteCount + row?.note_count;
      }
    });
    return totalnoteCount;
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={actionModal}
      className={classes.modal}
      onKeyDown={keyPress}
      onClose={() => {
        setActionModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#393737",
          padding: 20,
          maxHeight: "95%",
          overflow: "auto",
          minHeight: "95%",
        }}
      >
        <div
          style={{
            height: actionVisiblity ? "70%" : "100%",
          }}
        >
          <div
            style={{
              width: "96%",
              backgroundColor: "#F0F0F0",
              margin: 15,
              borderRadius: 8,
            }}
          >
            <div
              style={{
                fontFamily: "segoeuiB",
                fontSize: 16,
                textDecoration: "underline",
                width: "100%",
                marginLeft: 4,
                padding: 10,
              }}
            >
              Notlar
            </div>
            <div
              className={classes.overflowStyle}
              style={{ width: "99%", overflow: "auto" }}
            >
              {trackerNotes?.creators &&
                trackerNotes?.creators?.map((item, index) => {
                  return (
                    <Paper
                      key={index}
                      elevation={2}
                      onClick={scrollSmoothHandler(index, item?.user_id)}
                      style={{
                        minWidth: 150,
                        maxWidth: 150,
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        padding: 5,
                        margin: 15,
                        fontSize: 12,
                        color:
                          selectedOfficeUser == item?.user_id
                            ? "royalblue"
                            : "black",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {item?.full_name === null ? "Sistem" : item?.full_name}{" "}
                        ({" "}
                        {item?.full_name === "Tümü"
                          ? calcNoteCount(trackerNotes?.creators)
                          : item?.note_count}{" "}
                        )
                      </span>
                    </Paper>
                  );
                })}
            </div>
            <div>
              <div>
                {trackerNotes?.results?.map((row, i) => {
                  return (
                    <ul
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        paddingRight: 10,
                        borderStyle: "solid",
                        borderWidth: 1,
                        margin: 10,
                        borderColor: "gray",
                      }}
                    >
                      <li
                        style={{
                          marginTop: 4,
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          color: "black",
                        }}
                      >
                        [{row.status}] [
                        {row.created_by === null
                          ? "-"
                          : row?.created_by?.role === "setup"
                          ? "Kurulum"
                          : row?.created_by?.role === "collector"
                          ? "Tahsilat"
                          : row?.created_by?.role}
                        ] [
                        {row.created_by === null
                          ? "-"
                          : row?.created_by?.full_name}
                        ] : {row?.note}
                      </li>
                      <span
                        style={{
                          fontFamily: "segoeuiRg",
                          fontSize: 13,
                        }}
                      >
                        {moment(row?.created_at).format("DD-MM-YYYY HH:mm:ss")}{" "}
                        - {moment(row?.created_at).fromNow()}
                        <br />
                        {row?.task?.recordings?.map((row1, index) => {
                          return (
                            <div
                              style={{
                                backgroundColor:
                                  selectedVoice?.ind === i &&
                                  selectedVoice?.record_id === row1?.id
                                    ? "#f53b62"
                                    : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 20,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                cursor: "pointer",
                                borderRadius: 2,
                              }}
                              onClick={() => {
                                setSelectedVoice({
                                  record_id: row1?.id,
                                  ind: i,
                                  index,
                                });
                                trackerNotesRecordFunc({
                                  record_id: row1?.id,
                                  i,
                                  index,
                                });
                              }}
                            >
                              {index + 1}
                            </div>
                          );
                        })}
                        {selectedVoice !== null &&
                        row?.task?.recordings[selectedVoice?.index].voice ? (
                          <div>
                            <AudioPlayer
                              ref={audioRef}
                              src={
                                row?.task?.recordings[selectedVoice?.index]
                                  .voice
                              }
                              showJumpControls={false}
                              customAdditionalControls={[]}
                              style={{
                                padding: "2px 6px",
                                width: 240,
                                backgroundColor: "#474545",
                                boxShadow: "none",
                                color: "#fff",
                                marginTop: 2,
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.0 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.0)}
                              >
                                1x
                              </div>
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.25 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.25)}
                              >
                                1.25x
                              </div>
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.5 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.5)}
                              >
                                1.5x
                              </div>
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.75 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.75)}
                              >
                                1.75x
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </span>
                    </ul>
                  );
                })}
              </div>
              {trackerNotes?.count != 0 && (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    backgroundColor: "#F0F0F0",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    labelRowsPerPage={"Sayfa Başına Kayıt"}
                    count={trackerNotes?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    classes={{ root: classes.root }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {actionVisiblity && (
          <div style={{ height: "30%" }}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#393737",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {restofficialTaskActionList?.map((row) => {
                      return (
                        <FormControlLabel
                          labelPlacement="top"
                          control={
                            <Checkbox
                              checked={selectedHeader?.id === row?.id}
                              onChange={(e) => {
                                if (selectedHeader?.id === row?.id) {
                                  setSelectedHeader(null);
                                } else {
                                  setSelectedHeader(row);
                                }
                              }}
                              sx={{
                                color: "#fff",
                                backgroundColor: "#393737",
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                              }}
                            />
                          }
                          style={{ textAlign: "center" }}
                          label={
                            <span
                              style={{
                                // fontFamily: "Sego",
                                fontSize: 12,
                                color: "#fff",
                              }}
                            >
                              {row?.name === "Bloke Talebi"
                                ? resInfo.department === "Operasyon Yonetimi" ||
                                  resInfo?.role === "tech"
                                  ? "Bloke Et"
                                  : row?.name
                                : row?.name}
                            </span>
                          }
                        />
                      );
                    })}
                  </div>
                  {selectedHeader?.tag === "block" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width:
                          selectedDeleteReason?.id === "other" ? "96%" : "50%",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        responsiveHeight={30}
                        selectFontSize={16}
                        options={blockedReasonsv2}
                        styles={selectDefaultStyles}
                        isSearchable={false}
                        zIndex={90}
                        hoverColor={"#f53b62"}
                        menuColor={"#f53b62"}
                        selectWidth={250}
                        placeholder={"Tümü"}
                        menuPlacement="top"
                        value={selectedDeleteReason}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                          setSelectedDeleteReason(e);
                        }}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {e.name}
                          </div>
                        )}
                      />
                    </div>
                  )}
                  {selectedHeader?.tag === "tech" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width:
                          selectedDeleteReason?.id === "other" ? "96%" : "50%",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        responsiveHeight={30}
                        selectFontSize={16}
                        options={ticketTypes}
                        styles={selectDefaultStyles}
                        isSearchable={false}
                        zIndex={90}
                        hoverColor={"#f53b62"}
                        menuColor={"#f53b62"}
                        selectWidth={250}
                        menuPlacement="top"
                        placeholder={"Seçiniz"}
                        value={selectedTicketType}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                          setSelectedTicketType(e);
                        }}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {e.ticket_type}
                          </div>
                        )}
                      />
                    </div>
                  )}

                  {/* {selectedHeader?.tag === "block" && resInfo.department === "Operasyon Yonetimi" ? (
                                    <div style={{ minHeight: 42 }} />
                                ) : ( */}
                  <Paper
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                      minHeight:
                        selectedHeader?.tag === "tech" ||
                        selectedHeader?.tag === "block"
                          ? 30
                          : 42,
                      backgroundColor: "#F0F0F0",
                      alignSelf: "center",
                      marginTop:
                        selectedHeader?.tag === "tech" ||
                        selectedHeader?.tag === "block"
                          ? 10
                          : 0,
                    }}
                  >
                    <InputBase
                      disabled={selectedHeader?.note ? false : true}
                      multiline
                      spellCheck={false}
                      style={{
                        width: "100%",
                        fontFamily: "segoeuiRg",
                        paddingLeft: 8,
                      }}
                      rows={
                        selectedHeader?.tag === "tech" ||
                        selectedHeader?.tag === "block"
                          ? 3
                          : 5
                      }
                      placeholder={"Not ekle"}
                      className={classes.input}
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    />
                  </Paper>
                  {/* )} */}

                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "auto",
                      }}
                    >
                      <DatePicker
                        selected={startDate}
                        timeIntervals={5}
                        disabled={selectedHeader?.calendar ? false : true}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        // maxDate={addDays(
                        //     new Date(),
                        //     taskList?.max_allowed_days
                        // )}
                        excludeTimes={calendarAllList?.map((row) => {
                          if (
                            startDate.getDate() ==
                            parseInt(moment(row?.start_at).format("DD"))
                          ) {
                            return setHours(
                              setMinutes(
                                new Date(),
                                moment(row?.start_at).format("mm")
                              ),
                              moment(row?.start_at).format("HH")
                            );
                          }
                        })}
                        minDate={moment(new Date()).toDate()}
                        dateFormat="d MMMM, HH:mm"
                        locale="tr"
                        customInput={
                          <CustomDatePickerInput
                            selectWidth={350}
                            color={
                              selectedHeader?.calendar ? "#f53b62" : "gray"
                            }
                          />
                        }
                      />
                    </div>
                    <Button
                      style={{
                        backgroundColor:
                          selectedHeader !== null && selectedHeader?.calendar
                            ? "purple"
                            : "#AB1818",
                        color: "#fff",
                        fontFamily: "segoeuiB",
                        width: "20%",
                        display: "flex",
                        justifyContent: "center",
                        height: 40,
                      }}
                      size="small"
                      onClick={() => {
                        let data;
                        if (selectedHeader !== null) {
                          if (selectedHeader?.tag === "block") {
                            data = {
                              rest_id: actionModalResId?.user_id,
                              tag: selectedHeader?.tag,
                              sendObj: {
                                note: note,
                                action: selectedHeader?.id,
                                block_reason: selectedDeleteReason.id,
                              },
                            };
                          } else if (selectedHeader?.tag === "tech") {
                            data = {
                              rest_id: actionModalResId?.user_id,
                              tag: selectedHeader?.tag,
                              sendObj: {
                                ticket_type: selectedTicketType?.id,
                                action: selectedHeader?.id,
                                note,
                              },
                            };
                          } else if (
                            selectedHeader?.note &&
                            selectedHeader?.calendar
                          ) {
                            data = {
                              rest_id: actionModalResId?.user_id,
                              tag: selectedHeader?.tag,
                              sendObj: {
                                note,
                                start_at: moment(startDate).toISOString(true),
                                action: selectedHeader?.id,
                              },
                            };
                          } else if (
                            !selectedHeader?.note &&
                            selectedHeader?.calendar
                          ) {
                            data = {
                              rest_id: actionModalResId?.user_id,
                              tag: selectedHeader?.tag,
                              sendObj: {
                                start_at: moment(startDate).toISOString(true),
                                action: selectedHeader?.id,
                              },
                            };
                          } else if (
                            selectedHeader?.note &&
                            !selectedHeader?.calendar
                          ) {
                            data = {
                              rest_id: actionModalResId?.user_id,
                              tag: selectedHeader?.tag,
                              sendObj: {
                                note,
                                action: selectedHeader?.id,
                              },
                            };
                          } else {
                            data = {
                              rest_id: actionModalResId?.user_id,
                              tag: selectedHeader?.tag,
                              sendObj: {
                                action: selectedHeader?.id,
                              },
                            };
                          }
                        } else {
                          alert("Aksiyon seçilmedi.");
                        }

                        if (selectedHeader?.note) {
                          if (note.length > 3) {
                            restOfficialTaskActionFunc(data);
                            setNote("");
                            setSelectedHeader(null);
                            setStartDate(
                              setHours(
                                setMinutes(new Date(), moment().format("mm")),
                                moment().format("HH")
                              )
                            );
                            setSelectedTicketType(null);
                          } else {
                            alert("Not alanı en az 3 karakter olmalıdır.");
                          }
                        } else {
                          restOfficialTaskActionFunc(data);
                          setNote("");
                          setSelectedHeader(null);
                          setStartDate(
                            setHours(
                              setMinutes(new Date(), moment().format("mm")),
                              moment().format("HH")
                            )
                          );
                        }
                      }}
                    >
                      {selectedHeader !== null && selectedHeader?.calendar
                        ? "Takvime Gönder"
                        : "Tamamla"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Card>
    </Modal>
  );
};

export default ActionModal;
