import React from "react";
//mui
import { Tooltip } from "@mui/material";
function NewOrderTooltip(props) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: 14,
            fontFamily: "segoeuiRg",
            padding: 1,
            bgcolor: "#082657",
            "& .MuiTooltip-arrow": {
              color: "#082657",
            },
          },
        },
      }}
      open={props?.open}
      disableHoverListener
      title={props?.title}
      arrow
      placement={props?.placement}
    >
      {props?.children}
    </Tooltip>
  );
}

export default NewOrderTooltip;
