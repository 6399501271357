import React, { useContext } from "react";
import { Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DevelopmentContext } from "../../../../contexts/developmentContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 20%)",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    marginBottom: 80,
  },
}));

export default function Groups({
  openGroups,
  setOpenGroups,
  taskDetail,
  setOpenTaskInfo,
}) {
  const classes = useStyles();
  const { taskGroups, changeTaskGeneralStatus } =
    useContext(DevelopmentContext);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openGroups}
      className={classes.modal}
      onClose={() => {
        setOpenGroups(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#f4f4f4",
          padding: 5,
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {taskGroups.map((group) => {
            return (
              <div
                style={{
                  background: group.color,
                  margin: 10,
                  padding: 5,
                  borderRadius: 8,
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const data = {
                    task_group: group.id,
                  };
                  changeTaskGeneralStatus(taskDetail.id, data);
                  setOpenTaskInfo(false);
                  setOpenGroups(false);
                }}
              >
                <span>{group.header}</span>
              </div>
            );
          })}
        </div>
      </Card>
    </Modal>
  );
}
