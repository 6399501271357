import {
  Box,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import moment from "moment";
import TicketCompleteModal from "../../../components/modals/ticketCompleteModal";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
import FormatNumber from "../../../components/formatData/formatPhone";
import CustomerSearch from "../../../components/search";
import useDebounce from "../../../components/debounce";
import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../../../components/datePickerInput";
import ReplyIcon from "@mui/icons-material/Reply";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Paths } from "../../../utils/constants";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

export default function Tickets() {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const {
    getActiveTicketList,
    activeTicketList,
    changeTicketStatus,
    getSentTickets,
    sentTickets,
    getCompletedTickets,
    completedTickets,
  } = useContext(RestaurantContext);
  const [showModal, setShowModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [stayStatus, setStayStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  let history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleTicketStatus = (row) => {
    let data;
    if (row.status === "new") {
      data = {
        id: row.id,
        sendObj: {
          status: "processing",
        },
      };
      const start = moment(startDate).format("YYYY-MM-DD");
      const end = moment(endDate).format("YYYY-MM-DD");
      changeTicketStatus(data, stayStatus, debouncedSearchTerm, start, end);
    } else if (row.status === "processing" && stayStatus) {
      let currentDate = new Date();
      let stay = new Date(currentDate.getTime() - 1000);
      data = {
        id: row.id,
        sendObj: {
          stay_time: stay,
        },
      };
      const start = moment(startDate).format("YYYY-MM-DD");
      const end = moment(endDate).format("YYYY-MM-DD");
      changeTicketStatus(data, stayStatus, debouncedSearchTerm, start, end);
    } else {
      setShowModal(true);
      setCurrentRow(row);
    }
  };

  useEffect(() => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    if (endDate != null) {
      if (value === 0)
        getActiveTicketList(stayStatus, debouncedSearchTerm, start, end);
      else if (value === 1) {
        getSentTickets(debouncedSearchTerm, start, end, page + 1);
      } else if (value === 2) {
        getCompletedTickets(debouncedSearchTerm, start, end, page + 1);
      }
    }
  }, [debouncedSearchTerm, stayStatus, value, startDate, endDate, page]);

  return (
    <Box>
      <Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginTop: 1,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            centered
            className={classes.tabs}
          >
            <Tab label="Gelen Ticketlar" {...a11yProps(0)} />
            <Tab label="Yollanan Ticketlar" {...a11yProps(1)} />
            <Tab label="Tamamlanmış Ticketlar" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Box>
          <div style={{ display: "flex", padding: "24px 24px 0px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <span
                    style={{
                      fontSize: 20,
                      marginBottom: 10,
                      display: "block",
                      fontWeight: "600",
                    }}
                  >
                    Tarih
                  </span>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    fixedHeight="45px"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    customInput={<CustomDatePickerInput />}
                    locale="tr"
                  />
                </div>

                <div style={{ maxWidth: 300 }}>
                  <CustomerSearch
                    value={searchValue}
                    placeholder={"Ara"}
                    onchange={setSearchValue}
                  />
                </div>
              </div>

              {value == 0 && (
                <div style={{ marginLeft: "auto", width: "fit-content" }}>
                  {stayStatus ? (
                    <HomeIcon
                      style={{ width: 35, height: 35, cursor: "pointer" }}
                      onClick={() => {
                        setStayStatus(!stayStatus);
                      }}
                    />
                  ) : (
                    <AccessTimeIcon
                      style={{ width: 35, height: 35, cursor: "pointer" }}
                      onClick={() => {
                        setStayStatus(!stayStatus);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <TabPanel value={value} index={0}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Restoran Id</TableCell>
                    <TableCell align="center">Bildirim Tipi</TableCell>
                    <TableCell align="center">Oluşturan Rolü</TableCell>
                    <TableCell align="center">Oluşturan adı</TableCell>
                    <TableCell align="center">Bildirim Zamanı</TableCell>
                    <TableCell align="center">Restoran</TableCell>
                    <TableCell align="center">Yetkili</TableCell>
                    <TableCell align="center">Cep No</TableCell>
                    <TableCell align="center">Not</TableCell>
                    <TableCell align="center">Aksiyon</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeTicketList?.map((row, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          {row?.restofficial?.user_id
                            ? row?.restofficial?.user_id
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.ticket_type?.ticket_type}
                        </TableCell>
                        <TableCell align="center">
                          {row?.created_by?.role === "setup"
                            ? "Kurulum"
                            : row?.created_by?.role === "collector"
                            ? "Tahsilat"
                            : row?.created_by?.role === "tech"
                            ? "Teknik"
                            : row?.created_by?.role === "call_center"
                            ? "CallCenter"
                            : row?.created_by?.role}
                        </TableCell>
                        <TableCell align="center">
                          {row?.created_by?.full_name}
                        </TableCell>
                        <TableCell align="center">
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => {
                            if (row?.restofficial?.user_id) {
                              history.push(
                                `${Paths.kunye}/${row?.restofficial?.user_id}`
                              );
                            }
                          }}
                          onMouseDown={(event) => {
                            if (event.button === 1) {
                              window.open(
                                `${window.location.origin}/restaurants/${row?.restofficial?.user_id}`,
                                "_blank"
                              );
                            }
                          }}
                          style={{ cursor: "pointer", color: "#f53f62" }}
                        >
                          {row?.restofficial?.name
                            ? row?.restofficial?.name
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.restofficial?.full_name
                            ? row?.restofficial?.full_name
                            : "-"}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 200 }}>
                          {row?.restofficial?.phone
                            ? FormatNumber(row?.restofficial?.phone, "")
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={row?.creation_note}>
                            <InfoIcon style={{ color: "#f53f62" }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {!stayStatus ? (
                            <Button
                              style={{
                                backgroundColor:
                                  row?.status !== "new" ? "tomato" : "green",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                height: 40,
                              }}
                              size="small"
                              onClick={() => handleTicketStatus(row)}
                            >
                              {row?.status === "new" ? "İşleme Al" : "Kapat"}
                            </Button>
                          ) : (
                            <ReplyIcon
                              style={{ cursor: "pointer", color: "#f53f62" }}
                              onClick={() => handleTicketStatus(row)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Restoran Id</TableCell>
                    <TableCell align="center">Bildirim Tipi</TableCell>
                    <TableCell align="center">Atanan Kişi</TableCell>
                    <TableCell align="center">Bildirim Zamanı</TableCell>
                    <TableCell align="center">Restoran</TableCell>
                    <TableCell align="center">Yetkili</TableCell>
                    <TableCell align="center">Cep No</TableCell>
                    <TableCell align="center">Not</TableCell>
                    <TableCell align="center">İşlem Zamanı</TableCell>
                    <TableCell align="center">Sonuç Zamanı</TableCell>
                    <TableCell align="center">İşlem Durumu</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sentTickets?.results?.map((row, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          {row?.restofficial?.user_id
                            ? row?.restofficial?.user_id
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.ticket_type?.ticket_type}
                        </TableCell>
                        <TableCell align="center">
                          {row?.processed_by?.full_name}
                        </TableCell>
                        <TableCell align="center">
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => {
                            if (row?.restofficial?.user_id) {
                              history.push(
                                `${Paths.kunye}/${row?.restofficial?.user_id}`
                              );
                            }
                          }}
                          onMouseDown={(event) => {
                            if (event.button === 1) {
                              window.open(
                                `${window.location.origin}/restaurants/${row?.restofficial?.user_id}`,
                                "_blank"
                              );
                            }
                          }}
                          style={{ cursor: "pointer", color: "#f53f62" }}
                        >
                          {row?.restofficial?.name
                            ? row?.restofficial?.name
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.restofficial?.full_name
                            ? row?.restofficial?.full_name
                            : "-"}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 200 }}>
                          {row?.restofficial?.phone
                            ? FormatNumber(row?.restofficial?.phone, "")
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={row?.creation_note}>
                            <InfoIcon style={{ color: "#f53f62" }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {row?.processing_timestamp
                            ? moment(row?.processing_timestamp).format(
                                "DD-MM-YYYY HH:mm"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.completed_timestamp
                            ? moment(row?.completed_timestamp).format(
                                "DD-MM-YYYY HH:mm"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.status === "new" ? (
                            "Bekliyor"
                          ) : row?.status === "completed" ? (
                            "Başarılı"
                          ) : row?.status === "completed_negative" ? (
                            "Başarısız"
                          ) : row?.status === "processing" &&
                            moment(row?.stay_time).isAfter(new Date()) ? (
                            <Tooltip
                              title={
                                <>
                                  <span>
                                    {moment(row?.stay_time).format(
                                      "DD-MM-YYYY HH:mm "
                                    )}
                                  </span>
                                  <span>- {row?.stay_note}</span>
                                </>
                              }
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "default",
                                }}
                              >
                                Sayaçta{" "}
                                <InfoIcon style={{ color: "#f53f62" }} />
                              </span>
                            </Tooltip>
                          ) : (
                            "İşlemde"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    {sentTickets?.results && (
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        labelRowsPerPage={"Sayfa Başına Kayıt"}
                        count={sentTickets?.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        classes={{ root: classes.root }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Restoran Id</TableCell>
                    <TableCell align="center">Bildirim Tipi</TableCell>
                    <TableCell align="center">Bildirim Zamanı</TableCell>
                    <TableCell align="center">Oluşturan Rolü</TableCell>
                    <TableCell align="center">Oluşturan adı</TableCell>
                    <TableCell align="center">Restoran</TableCell>
                    <TableCell align="center">Yetkili</TableCell>
                    <TableCell align="center">Cep No</TableCell>
                    <TableCell align="center">Not</TableCell>
                    <TableCell align="center">İşlem Durumu</TableCell>
                    <TableCell align="center">Kapatma Notu</TableCell>
                    <TableCell align="center">Kapatma Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completedTickets?.results?.map((row, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          {row?.restofficial?.user_id
                            ? row?.restofficial?.user_id
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.ticket_type?.ticket_type}
                        </TableCell>

                        <TableCell align="center">
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </TableCell>
                        <TableCell align="center">
                          {row?.created_by?.role === "setup"
                            ? "Kurulum"
                            : row?.created_by?.role === "collector"
                            ? "Tahsilat"
                            : row?.created_by?.role === "tech"
                            ? "Teknik"
                            : row?.created_by?.role === "call_center"
                            ? "CallCenter"
                            : row?.created_by?.role}
                        </TableCell>
                        <TableCell align="center">
                          {row?.created_by?.full_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => {
                            if (row?.restofficial?.user_id) {
                              history.push(
                                `${Paths.kunye}/${row?.restofficial?.user_id}`
                              );
                            }
                          }}
                          onMouseDown={(event) => {
                            if (event.button === 1) {
                              window.open(
                                `${window.location.origin}/restaurants/${row?.restofficial?.user_id}`,
                                "_blank"
                              );
                            }
                          }}
                          style={{ cursor: "pointer", color: "#f53f62" }}
                        >
                          {row?.restofficial?.name
                            ? row?.restofficial?.name
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.restofficial?.full_name
                            ? row?.restofficial?.full_name
                            : "-"}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 200 }}>
                          {row?.restofficial?.phone
                            ? FormatNumber(row?.restofficial?.phone, "")
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={row?.creation_note}>
                            <InfoIcon style={{ color: "#f53f62" }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {row?.status === "new"
                            ? "İşlem Yapılmadı"
                            : row?.status === "processing"
                            ? "İşlemde"
                            : row?.status === "completed"
                            ? "Başarılı"
                            : row?.status === "completed_negative"
                            ? "Başarısız"
                            : "Sayaçta"}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={row?.closing_note ? row?.closing_note : "-"}
                          >
                            <InfoIcon style={{ color: "#f53f62" }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 200 }}>
                          {row?.completed_timestamp
                            ? moment(row?.completed_timestamp).format(
                                "DD-MM-YYYY HH:mm"
                              )
                            : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    {completedTickets?.results && (
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        labelRowsPerPage={"Sayfa Başına Kayıt"}
                        count={completedTickets?.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        classes={{ root: classes.root }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Box>
      </Box>

      <TicketCompleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentRow={currentRow}
        stayStatus={stayStatus}
        searchText={debouncedSearchTerm}
        start={moment(startDate).format("YYYY-MM-DD")}
        end={moment(endDate).format("YYYY-MM-DD")}
      />
    </Box>
  );
}
