import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AssessmentIcon from "@mui/icons-material/Assessment";
import WorkIcon from "@mui/icons-material/Work";
import SourceIcon from "@mui/icons-material/Source";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import BadgeIcon from "@mui/icons-material/Badge";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PrintIcon from "@mui/icons-material/Print";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Paths = {
  operation: "/operation/main",
  operationPrim: "/operation/prim",
  operationTickets: "/operation/tickets",
  myRestaurants: "/operation/restaurants",
  techMyRestaurants: "/operation/tech-restaurants",
  operationPaymend: "/operation/paymend",
  operationSetupPaymend: "/operation/setup-paymend",
  operationRegister: "/operation/register",
  operationOutboundReports: "/operation/outbound-reports",
  operationPrimTable: "/operation/prim-table",
  callCenter: "/call-center",
  technicalService: "/technical-service",
  kunye: "/restaurants",
  collectorRestaurants: "/operation/collector-restaurants",
  tasks: "/operation/tasks",

  //operationManagement
  operationFinance: "/finans",
  operationManagerSellReports: "/operation-management-sell-reports",
  operationManagerServiceReports: "/operation-management-service-reports",
  operationManagerRestaurantReports: "/operation-management-restaurant-reports",
  operationManagerKpiReports: "/operation-management-kpi-reports",
  operationManagerWorks: "/operation-management-manager-works",
  operationManagerPayment: "/operation-management-manager-payment",
  operationManagementOfferSettings: "/operation-management-offer",
  operationManagerData: "/data-management",
  operationOfferApprove: "/approve-offer",
  userDashboard: "/user-dashboard",
  notificationTools: "/notification-tools",
  printingPress: "/printing-press",

  //seller
  sellerRegister: "/seller/register",
  sellerMyPrim: "/seller/my-prim",
};

const operationManagementSidbarData = [
  {
    id: 0,
    label: "Finans",
    path: Paths.operationFinance,
    icon: <MonetizationOnIcon />,
  },
  {
    id: 1,
    label: "Dashboard",
    path: Paths.userDashboard,
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    label: "Satış Raporları",
    path: Paths.operationManagerSellReports,
    icon: <AssessmentIcon />,
  },
  {
    id: 3,
    label: "Hizmet Raporları",
    path: Paths.operationManagerServiceReports,
    icon: <SupportAgentIcon />,
  },
  {
    id: 4,
    label: "Restoran Raporları",
    path: Paths.operationManagerRestaurantReports,
    icon: <RestaurantIcon />,
  },
  {
    id: 5,
    label: "Kpi Raporları",
    path: Paths.operationManagerKpiReports,
    icon: <BadgeIcon />,
  },
  {
    id: 6,
    label: "İşlerim",
    path: Paths.operationManagerWorks,
    icon: <WorkIcon />,
  },
  {
    id: 7,
    label: "Ödeme Takip",
    path: Paths.operationManagerPayment,
    icon: <RequestQuoteIcon />,
  },
  {
    id: 8,
    label: "Data Yönetimi",
    path: Paths.operationManagerData,
    icon: <SourceIcon />,
  },
  // {
  //   id: 9,
  //   label: "Teklif Onay",
  //   path: Paths.operationOfferApprove,
  // },
  {
    id: 9,
    label: "Bildirimler",
    path: Paths.notificationTools,
    icon: <NotificationsIcon />,
  },
  {
    id: 10,
    label: "Matbaa ve Baskı",
    path: Paths.printingPress,
    icon: <PrintIcon />,
  },
];

const operationSidbarData = [
  { id: 0, label: "Başvurularım", path: Paths.operation },
  { id: 1, label: "Restoranlarım", path: Paths.myRestaurants },
  { id: 2, label: "Restoran Başvuru", path: Paths.operationRegister },
  { id: 3, label: "Ödeme Takip", path: Paths.operationPaymend },
  // { id: 4, label: "Prim Tablosu", path: Paths.operationPrim },
];

const operationSidbarDataCollector = [
  { id: 0, label: "Görevlerim", path: Paths.tasks },
  // { id: 1, label: "Ödeme Takip", path: Paths.operationPaymend },
  { id: 1, label: "Tüm Restoranlar", path: Paths.myRestaurants },
  // { id: 3, label: "Restoranlarım", path: Paths.collectorRestaurants },
  { id: 2, label: "Prim Tablosu", path: Paths.operationPrim },
  { id: 3, label: "Ticketlar", path: Paths.operationTickets },
  // { id: 6, label: "Başvurularım", path: Paths.operation },
  { id: 4, label: "Restoran Başvuru", path: Paths.operationRegister },
];

const operationSidbarDataOutbound = [
  { id: 0, label: "Başvurularım", path: Paths.operation },
  { id: 1, label: "Raporlarım", path: Paths.operationOutboundReports },
  { id: 2, label: "Restoran Başvuru", path: Paths.operationRegister },
  // { id: 3, label: "Prim Tablosu", path: Paths.operationPrim },
];

const operationSidbarDataSetup = [
  { id: 0, label: "Görevlerim", path: Paths.tasks },
  // { id: 1, label: "Başvurularım", path: Paths.operation },
  { id: 1, label: "Restoranlarım", path: Paths.myRestaurants },
  { id: 2, label: "Restoran Başvuru", path: Paths.operationRegister },
  // { id: 4, label: "MMA Takip", path: Paths.operationSetupPaymend },
  // { id: 3, label: "Prim Tablosu", path: Paths.operationPrim },
  { id: 3, label: "Ticketlar", path: Paths.operationTickets },
];

const sellerSidbarData = [
  { id: 0, label: "Başvuru", path: Paths.sellerRegister },
  { id: 1, label: "Primlerim", path: Paths.sellerMyPrim },
];

const callCenterSidbarData = [
  { id: 0, label: "Başvurularım", path: Paths.callCenter },
  { id: 1, label: "Ticketlar", path: Paths.operationTickets },
];

const technicalServiceSidBarData = [
  { id: 0, label: "Görevlerim", path: Paths.tasks },
  { id: 1, label: "Restoranlarım", path: Paths.techMyRestaurants },
  { id: 2, label: "Ticketlar", path: Paths.operationTickets },
  { id: 3, label: "Restoran Başvuru", path: Paths.operationRegister },
];

const data = [
  {
    value: 0,
    label: "0 Gün",
  },
  {
    value: 1,
    label: "1 Gün",
  },
  {
    value: 2,
    label: "2 Gün",
  },
  {
    value: 3,
    label: "3 Gün",
  },
  {
    value: 4,
    label: "4 Gün",
  },
  {
    value: 5,
    label: "5 Gün",
  },
  {
    value: 6,
    label: "6 Gün",
  },
  {
    value: 7,
    label: "7 Gün",
  },
  {
    value: 8,
    label: "8 Gün",
  },
  {
    value: 9,
    label: "9 Gün",
  },
  {
    value: 10,
    label: "10 Gün",
  },
  {
    value: 11,
    label: "11 Gün",
  },
  {
    value: 12,
    label: "12 Gün",
  },
  {
    value: 13,
    label: "13 Gün",
  },
  {
    value: 14,
    label: "14 Gün",
  },
  {
    value: 15,
    label: "15 Gün",
  },
  {
    value: 16,
    label: "16 Gün",
  },
  {
    value: 17,
    label: "17 Gün",
  },
  {
    value: 18,
    label: "18 Gün",
  },
  {
    value: 19,
    label: "19 Gün",
  },
  {
    value: 20,
    label: "20 Gün",
  },
  {
    value: 21,
    label: "21 Gün",
  },
  {
    value: 22,
    label: "22 Gün",
  },
  {
    value: 23,
    label: "23 Gün",
  },
  {
    value: 24,
    label: "24 Gün",
  },
  {
    value: 25,
    label: "25 Gün",
  },
  {
    value: 26,
    label: "26 Gün",
  },
  {
    value: 27,
    label: "27 Gün",
  },
  {
    value: 28,
    label: "28 Gün",
  },
  {
    value: 29,
    label: "29 Gün",
  },
  {
    value: 30,
    label: "30 Gün",
  },
  {
    value: 31,
    label: "31 Gün",
  },
  {
    value: 32,
    label: "32 Gün",
  },
  {
    value: 33,
    label: "33 Gün",
  },
  {
    value: 34,
    label: "34 Gün",
  },
  {
    value: 35,
    label: "35 Gün",
  },
  {
    value: 36,
    label: "36 Gün",
  },
  {
    value: 37,
    label: "37 Gün",
  },
  {
    value: 38,
    label: "38 Gün",
  },
  {
    value: 39,
    label: "39 Gün",
  },
  {
    value: 40,
    label: "40 Gün",
  },
  {
    value: 41,
    label: "41 Gün",
  },
  {
    value: 42,
    label: "42 Gün",
  },
  {
    value: 43,
    label: "43 Gün",
  },
  {
    value: 44,
    label: "44 Gün",
  },
  {
    value: 45,
    label: "45 Gün",
  },
  {
    value: 46,
    label: "46 Gün",
  },
  {
    value: 47,
    label: "47 Gün",
  },
  {
    value: 48,
    label: "48 Gün",
  },
  {
    value: 49,
    label: "49 Gün",
  },
  {
    value: 50,
    label: "50 Gün",
  },
  {
    value: 51,
    label: "51 Gün",
  },
  {
    value: 52,
    label: "52 Gün",
  },
  {
    value: 53,
    label: "53 Gün",
  },
  {
    value: 54,
    label: "54 Gün",
  },
  {
    value: 55,
    label: "55 Gün",
  },
  {
    value: 56,
    label: "56 Gün",
  },
  {
    value: 57,
    label: "57 Gün",
  },
  {
    value: 58,
    label: "58 Gün",
  },
  {
    value: 59,
    label: "59 Gün",
  },
  {
    value: 60,
    label: "60 Gün",
  },
  {
    value: 61,
    label: "61 Gün",
  },
  {
    value: 62,
    label: "62 Gün",
  },
  {
    value: 63,
    label: "63 Gün",
  },
  {
    value: 64,
    label: "64 Gün",
  },
  {
    value: 65,
    label: "65 Gün",
  },
  {
    value: 66,
    label: "66 Gün",
  },
  {
    value: 67,
    label: "67 Gün",
  },
  {
    value: 68,
    label: "68 Gün",
  },
  {
    value: 69,
    label: "69 Gün",
  },
  {
    value: 70,
    label: "70 Gün",
  },
  {
    value: 71,
    label: "71 Gün",
  },
  {
    value: 72,
    label: "72 Gün",
  },
  {
    value: 73,
    label: "73 Gün",
  },
  {
    value: 74,
    label: "74 Gün",
  },
  {
    value: 75,
    label: "75 Gün",
  },
  {
    value: 76,
    label: "76 Gün",
  },
  {
    value: 77,
    label: "77 Gün",
  },
  {
    value: 78,
    label: "78 Gün",
  },
  {
    value: 79,
    label: "79 Gün",
  },
  {
    value: 80,
    label: "80 Gün",
  },
  {
    value: 81,
    label: "81 Gün",
  },
  {
    value: 82,
    label: "82 Gün",
  },
  {
    value: 83,
    label: "83 Gün",
  },
  {
    value: 84,
    label: "84 Gün",
  },
  {
    value: 85,
    label: "85 Gün",
  },
  {
    value: 86,
    label: "86 Gün",
  },
  {
    value: 87,
    label: "87 Gün",
  },
  {
    value: 88,
    label: "88 Gün",
  },
  {
    value: 89,
    label: "89 Gün",
  },
  {
    value: 90,
    label: "90 Gün",
  },
  {
    value: 91,
    label: "91 Gün",
  },
  {
    value: 92,
    label: "92 Gün",
  },
  {
    value: 93,
    label: "93 Gün",
  },
  {
    value: 94,
    label: "94 Gün",
  },
  {
    value: 95,
    label: "95 Gün",
  },
  {
    value: 96,
    label: "96 Gün",
  },
  {
    value: 97,
    label: "97 Gün",
  },
  {
    value: 98,
    label: "98 Gün",
  },
  {
    value: 99,
    label: "99 Gün",
  },
  {
    value: 100,
    label: "100 Gün",
  },
  {
    value: 101,
    label: "101 Gün",
  },
  {
    value: 102,
    label: "102 Gün",
  },
  {
    value: 103,
    label: "103 Gün",
  },
  {
    value: 104,
    label: "104 Gün",
  },
  {
    value: 105,
    label: "105 Gün",
  },
  {
    value: 106,
    label: "106 Gün",
  },
  {
    value: 107,
    label: "107 Gün",
  },
  {
    value: 108,
    label: "108 Gün",
  },
  {
    value: 109,
    label: "109 Gün",
  },
  {
    value: 110,
    label: "110 Gün",
  },
  {
    value: 111,
    label: "111 Gün",
  },
  {
    value: 112,
    label: "112 Gün",
  },
  {
    value: 113,
    label: "113 Gün",
  },
  {
    value: 114,
    label: "114 Gün",
  },
  {
    value: 115,
    label: "115 Gün",
  },
  {
    value: 116,
    label: "116 Gün",
  },
  {
    value: 117,
    label: "117 Gün",
  },
  {
    value: 118,
    label: "118 Gün",
  },
  {
    value: 119,
    label: "119 Gün",
  },
  {
    value: 120,
    label: "120 Gün",
  },
];

const offerCount = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 11,
    label: 11,
  },
  {
    value: 12,
    label: 12,
  },
  {
    value: 13,
    label: 13,
  },
  {
    value: 14,
    label: 14,
  },
  {
    value: 15,
    label: 15,
  },
  {
    value: 16,
    label: 16,
  },
  {
    value: 17,
    label: 17,
  },
  {
    value: 18,
    label: 18,
  },
  {
    value: 19,
    label: 19,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 21,
    label: 21,
  },
  {
    value: 22,
    label: 22,
  },
  {
    value: 23,
    label: 23,
  },
  {
    value: 24,
    label: 24,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 26,
    label: 26,
  },
  {
    value: 27,
    label: 27,
  },
  {
    value: 28,
    label: 28,
  },
  {
    value: 29,
    label: 29,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 31,
    label: 31,
  },
  {
    value: 32,
    label: 32,
  },
  {
    value: 33,
    label: 33,
  },
  {
    value: 34,
    label: 34,
  },
  {
    value: 35,
    label: 35,
  },
  {
    value: 36,
    label: 36,
  },
  {
    value: 37,
    label: 37,
  },
  {
    value: 38,
    label: 38,
  },
  {
    value: 39,
    label: 39,
  },
  {
    value: 40,
    label: 40,
  },
  {
    value: 41,
    label: 41,
  },
  {
    value: 42,
    label: 42,
  },
  {
    value: 43,
    label: 43,
  },
  {
    value: 44,
    label: 44,
  },
  {
    value: 45,
    label: 45,
  },
  {
    value: 46,
    label: 46,
  },
  {
    value: 47,
    label: 47,
  },
  {
    value: 48,
    label: 48,
  },
  {
    value: 49,
    label: 49,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 51,
    label: 51,
  },
  {
    value: 52,
    label: 52,
  },
  {
    value: 53,
    label: 53,
  },
  {
    value: 54,
    label: 54,
  },
  {
    value: 55,
    label: 55,
  },
  {
    value: 56,
    label: 56,
  },
  {
    value: 57,
    label: 57,
  },
  {
    value: 58,
    label: 58,
  },
  {
    value: 59,
    label: 59,
  },
  {
    value: 60,
    label: 60,
  },
  {
    value: 61,
    label: 61,
  },
  {
    value: 62,
    label: 62,
  },
  {
    value: 63,
    label: 63,
  },
  {
    value: 64,
    label: 64,
  },
  {
    value: 65,
    label: 65,
  },
  {
    value: 66,
    label: 66,
  },
  {
    value: 67,
    label: 67,
  },
  {
    value: 68,
    label: 68,
  },
  {
    value: 69,
    label: 69,
  },
  {
    value: 70,
    label: 70,
  },
  {
    value: 71,
    label: 71,
  },
  {
    value: 72,
    label: 72,
  },
  {
    value: 73,
    label: 73,
  },
  {
    value: 74,
    label: 74,
  },
  {
    value: 75,
    label: 75,
  },
  {
    value: 76,
    label: 76,
  },
  {
    value: 77,
    label: 77,
  },
  {
    value: 78,
    label: 78,
  },
  {
    value: 79,
    label: 79,
  },
  {
    value: 80,
    label: 80,
  },
  {
    value: 81,
    label: 81,
  },
  {
    value: 82,
    label: 82,
  },
  {
    value: 83,
    label: 83,
  },
  {
    value: 84,
    label: 84,
  },
  {
    value: 85,
    label: 85,
  },
  {
    value: 86,
    label: 86,
  },
  {
    value: 87,
    label: 87,
  },
  {
    value: 88,
    label: 88,
  },
  {
    value: 89,
    label: 89,
  },
  {
    value: 90,
    label: 90,
  },
  {
    value: 91,
    label: 91,
  },
  {
    value: 92,
    label: 92,
  },
  {
    value: 93,
    label: 93,
  },
  {
    value: 94,
    label: 94,
  },
  {
    value: 95,
    label: 95,
  },
  {
    value: 96,
    label: 96,
  },
  {
    value: 97,
    label: 97,
  },
  {
    value: 98,
    label: 98,
  },
  {
    value: 99,
    label: 99,
  },
  {
    value: 100,
    label: 100,
  },
];

const freeDays = [
  { value: 0, label: "0 Gün" },
  { value: 7, label: "7 Gün" },
  { value: 15, label: "15 Gün" },
];

export {
  Paths,
  operationManagementSidbarData,
  operationSidbarData,
  operationSidbarDataCollector,
  operationSidbarDataOutbound,
  operationSidbarDataSetup,
  callCenterSidbarData,
  technicalServiceSidBarData,
  data,
  sellerSidbarData,
  offerCount,
  freeDays,
};
