import { useState, useEffect } from "react";

const useMouseInactivity = (timeoutDuration, userStatus) => {
  const [isMouseInactive, setisMouseInactive] = useState(false);
  const officeuserInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));

  useEffect(() => {
    if (
      userStatus === "active" &&
      officeuserInfo.department !== "Operasyon Yonetimi"
    ) {
      if (officeuserInfo.role === "tech") return;
      let idleTimer;

      const handleMouseMovement = () => {
        if (idleTimer) {
          clearTimeout(idleTimer);
        }

        idleTimer = setTimeout(() => {
          setisMouseInactive(true);
        }, timeoutDuration);
      };

      document.addEventListener("mousemove", handleMouseMovement);

      idleTimer = setTimeout(() => {
        setisMouseInactive(true);
      }, timeoutDuration);

      return () => {
        document.removeEventListener("mousemove", handleMouseMovement);
        clearTimeout(idleTimer);
      };
    }
  }, [userStatus, isMouseInactive]);

  return [isMouseInactive, setisMouseInactive];
};

export default useMouseInactivity;
