import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Box, Container, Paper } from "@mui/material";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { RestaurantContext } from "../../../contexts/restaurantContext";

import CustomDatePickerInput from "../../../components/datePickerInput";
import useWindowDimensions from "../../../components/windowSize";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";

export const Reports = ({ currentIndex }) => {
  const {
    getRestaurantInfo,
    restOfficialData,
    getReportsData,
    reportData,
    platformList,
    getPlatformList,
    getRestaurantPaymentTypeList,
    paymentTypeList,
    getLifetimeStats,
    lifetimeStats,
    setLifetimeStats,
  } = useContext(RestaurantContext);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const { width } = useWindowDimensions();
  const [dateRange, setDateRange] = useState([
    restOfficialData?.created_at != null
      ? new Date(restOfficialData?.created_at)
      : new Date(),
    new Date(),
  ]);
  const [startDate, endDate] = dateRange;

  let { id } = useParams();

  useEffect(() => {
    getRestaurantInfo(id);
    getRestaurantPaymentTypeList();
    getPlatformList();
    getLifetimeStats(id);
  }, []);

  useEffect(() => {
    if (reportData) {
      setLifetimeStats({
        ...lifetimeStats,
        order_count: reportData?.order_count,
        order_sum: reportData?.order_sum,
        order_avg: reportData?.order_avg,
        canceled_order_count: reportData?.canceled_order_count,
        canceled_order_sum: reportData?.canceled_order_sum,
        canceled_order_avg: reportData?.canceled_order_avg,
        order_daily_count: reportData?.order_daily_count,
        order_daily_avg: reportData?.order_daily_avg,
        canceled_order_daily_count: reportData?.canceled_order_daily_count,
        canceled_order_daily_avg: reportData?.canceled_order_daily_avg,
        diff: reportData?.diff,
      });
    }
  }, [reportData]);

  useEffect(() => {
    if (paymentTypeList != null) {
      paymentTypeList?.unshift({
        id: 0,
        name: "Tümü",
      });
    }
  }, [paymentTypeList]);

  useEffect(() => {
    if (platformList != null) {
      platformList?.unshift({
        id: 0,
        name: "Tümü",
      });
    }
  }, [platformList]);

  function currencyFormat(num) {
    return (
      "₺ " +
      num
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }

  return (
    <Container
      sx={{
        marginTop: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexDirection: width > 800 ? "row" : "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <span
              style={{
                marginBottom: 3,
                width: "auto",
                height: 27,
                color: "#293c51",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Tarih
            </span>
            <div>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);

                  if (update[1]) {
                    getReportsData(id, {
                      payment_type: selectedPaymentType,
                      startDate: moment(update[0]).format("YYYY-MM-DD"),
                      endDate: moment(update[1]).format("YYYY-MM-DD"),
                      platform: selectedPlatform,
                    });
                  }
                }}
                customInput={<CustomDatePickerInput selectedWidth={200} />}
                dateFormat="dd/MM/yyyy"
                locale="tr"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: width > 800 ? 0 : 20,
            }}
          >
            <span
              style={{
                marginBottom: 3,
                width: "auto",
                height: 27,
                color: "#293c51",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Ödeme Yöntemi
            </span>
            <Select
              options={paymentTypeList}
              styles={selectDefaultStyles}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={230}
              placeholder={"Tümü"}
              value={selectedPaymentType}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSelectedPaymentType(e);

                if (e) {
                  getReportsData(id, {
                    payment_type: e,
                    startDate: moment(startDate).format("YYYY-MM-DD"),
                    endDate: moment(endDate).format("YYYY-MM-DD"),
                    platform: selectedPlatform,
                  });
                }
              }}
              getOptionLabel={(e) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginLeft: 15 }}>{e.name}</span>
                </div>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                marginBottom: 3,
                width: "auto",
                height: 27,
                color: "#293c51",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 600,
                marginTop: width > 800 ? 0 : 20,
              }}
            >
              Platform
            </span>
            <Select
              options={platformList}
              styles={selectDefaultStyles}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={200}
              placeholder={"Tümü"}
              value={selectedPlatform}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSelectedPlatform(e);

                if (e) {
                  getReportsData(id, {
                    payment_type: selectedPaymentType,
                    startDate: moment(startDate).format("YYYY-MM-DD"),
                    endDate: moment(endDate).format("YYYY-MM-DD"),
                    platform: e,
                  });
                }
              }}
              getOptionLabel={(e) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginLeft: 15 }}>{e.name}</span>
                </div>
              )}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: width > 800 ? "column" : "row",
            justifyContent: "space-between",
            marginTop: 50,
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: width > 800 ? "row" : "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "30%" : "95%",
                height: 100,
                display: "flex",
                borderRadius: 8,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: width > 800 ? 0 : 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Toplam Başarılı Sipariş
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                  }}
                >
                  {lifetimeStats?.order_count}
                </div>
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "30%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: width > 800 ? 0 : 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Toplam Ciro
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                  }}
                >
                  {lifetimeStats?.order_sum
                    ? currencyFormat(lifetimeStats?.order_sum)
                    : 0}
                </div>
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "30%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: width > 800 ? 0 : 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Ortalama Sepet Tutarı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                  }}
                >
                  {lifetimeStats?.order_avg
                    ? currencyFormat(lifetimeStats?.order_avg)
                    : 0}
                </div>
              </div>
            </Paper>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: width > 800 ? "row" : "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "30%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Toplam İptal Sipariş
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.canceled_order_count}
                </div>
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "30%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "red",
                    color: "#293c51",
                  }}
                >
                  Toplam İptal Ciro
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.canceled_order_sum
                    ? currencyFormat(lifetimeStats?.canceled_order_sum)
                    : 0}
                </div>
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "30%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "red",
                    color: "#293c51",
                  }}
                >
                  Ortalama İptal Sepet Tutarı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.canceled_order_avg
                    ? currencyFormat(lifetimeStats?.canceled_order_avg)
                    : 0}
                </div>
              </div>
            </Paper>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: width > 800 ? "row" : "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "23%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Sipariş veren müşteri sayısı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.total_enduser_count}
                </div>
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "23%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Tekrar sipariş veren müşteri adeti
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.repeats_enduser_count}
                </div>
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "23%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Toplam tekrar sipariş adeti
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.repeats_order_count}
                </div>
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "23%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Tekrar sipariş oranı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {isNaN(
                    lifetimeStats?.repeats_order_count /
                      lifetimeStats?.repeats_enduser_count
                  )
                    ? 0
                    : (
                        lifetimeStats?.repeats_order_count /
                        lifetimeStats?.repeats_enduser_count
                      ).toFixed(2)}
                </div>
              </div>
            </Paper>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: width > 800 ? "row" : "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "47%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Ortalama sipariş sayısı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.order_daily_count.toFixed(2)}
                </div>
              </div>
            </Paper>

            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "47%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Ortalama sipariş tutarı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.order_daily_avg.toFixed(2)}
                </div>
              </div>
            </Paper>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: width > 800 ? "row" : "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "47%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Ortalama İptal sipariş sayısı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.canceled_order_daily_count.toFixed(2)}
                </div>
              </div>
            </Paper>

            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "47%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Ortalama iptal sipariş tutarı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.canceled_order_daily_avg.toFixed(2)}
                </div>
              </div>
            </Paper>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: width > 800 ? "row" : "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Paper
              elevation={2}
              style={{
                width: width > 800 ? "47%" : "95%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                marginTop: 20,
              }}
            >
              <div style={{ width: "90%", height: "80%" }}>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 20,
                    textAlign: "left",
                    color: "#293c51",
                  }}
                >
                  Seçilen Gün Sayısı
                </div>
                <div
                  style={{
                    fontFamily: "SgSemi",
                    fontSize: 24,
                    textAlign: "end",
                    margin: 10,
                    color: "red",
                  }}
                >
                  {lifetimeStats?.diff}
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </Container>
  );
};
