import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  InputBase,
  Paper,
} from "@mui/material";

import { ManagementContext } from "../../../../contexts/managementContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SendSettingsModal from "../modals/sendSettings";
import { DescriptionTwoTone } from "@mui/icons-material";
import ContentNoteModal from "../modals/contentNote";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";

const SmsSettings = () => {
  const {
    createSmsTemplateFunc,
    createSmsTemplateTestFunc,
    smsTemplateListFunc,
    smsTemplateList,
    platformAndProductsFunc,
    platformAndProducts,
  } = useContext(ManagementContext);
  const [smsHeader, setSmsHeader] = useState("");
  const [smsContent, setSmsContent] = useState("");
  const [smsLink, setSmsLink] = useState("");
  const [smsType, setSmsType] = useState(null);
  const [smsTest, setSmsTest] = useState(false);
  const [testPhone, setTestPhone] = useState("90");
  const [selectedAppProduct, setSelectedAppProduct] = useState(null);
  const [selectedAppProductName, setSelectedAppProductName] = useState(null);
  const [sendSettingsModal, setSendSettingsModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [contentNoteModal, setContentNoteModal] = useState(false);

  useEffect(() => {
    smsTemplateListFunc();
    platformAndProductsFunc();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "50%",
            height: "auto",
            display: "flex",
            alignItems: "center",
            minHeight: 700,
          }}
        >
          <Paper
            elevation={1}
            style={{
              backgroundColor: "#fff",
              width: "90%",
              height: 700,
              display: "flex",
              flexDirection: "column",
              padding: 20,
              overflow: "auto",
            }}
          >
            <div style={{ fontFamily: "segoeuiB" }}>Sms Oluştur</div>
            <div style={{ marginTop: 20 }}>
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  backgroundColor: "#f4f4f4",
                  borderRadius: 4,
                }}
                rows={1}
                placeholder={"Sms İsmi"}
                value={smsHeader}
                onChange={(e) => {
                  setSmsHeader(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  backgroundColor: "#f4f4f4",
                  borderRadius: 4,
                }}
                rows={3}
                placeholder={"Sms İçeriği"}
                value={smsContent}
                onChange={(e) => {
                  setSmsContent(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  backgroundColor: "#f4f4f4",
                  borderRadius: 4,
                }}
                rows={1}
                placeholder={"Sms Linki"}
                value={smsLink}
                onChange={(e) => {
                  setSmsLink(e.target.value);
                }}
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Paper
                elevation={1}
                style={{
                  marginTop: 10,
                  padding: 5,
                  width: "25%",
                }}
              >
                <div
                  style={{
                    margin: 5,
                    fontFamily: "segoeuiRg",
                    color: "gray",
                    fontSize: 14,
                  }}
                >
                  Sms türü
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 100,
                      boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                      borderRadius: 4,
                      backgroundColor: "whitesmoke",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      textAlign: "center",
                      padding: "20px 10px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontFamily: "segoeuiRg",
                          fontSize: 14,
                        }}
                      >
                        Normal Sms
                      </div>
                      <div>
                        <GreenSwitch
                          onClick={() => {
                            setSmsType("normal");
                          }}
                          checked={smsType === "normal" ? true : false}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: "segoeuiRg",
                          fontSize: 14,
                        }}
                      >
                        Başvuru Sms
                      </div>
                      <div>
                        <GreenSwitch
                          onClick={() => {
                            setSmsType("application");
                          }}
                          checked={smsType === "application" ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>

              {smsType === "application" ? (
                <Paper
                  elevation={1}
                  style={{ marginTop: 10, padding: 5, width: "75%" }}
                >
                  <div
                    style={{
                      margin: 5,
                      fontFamily: "segoeuiRg",
                      color: "gray",
                      fontSize: 14,
                    }}
                  >
                    Sms ürün veya platform
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 100,
                        boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                        borderRadius: 4,
                        backgroundColor: "whitesmoke",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {platformAndProducts?.package_products?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedAppProduct?.id === row?.id) {
                                      setSelectedAppProduct(null);
                                      setSelectedAppProductName(null);
                                    } else {
                                      setSelectedAppProductName(row?.name);
                                      setSelectedAppProduct({
                                        id: row?.id,
                                        type: "product",
                                      });
                                    }
                                  }}
                                  checked={
                                    selectedAppProductName === row?.name
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {platformAndProducts?.platforms?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedAppProduct?.id === row?.id) {
                                      setSelectedAppProduct(null);
                                      setSelectedAppProductName(null);
                                    } else {
                                      setSelectedAppProductName(row?.name);
                                      setSelectedAppProduct({
                                        id: row?.id,
                                        type: "platform",
                                      });
                                    }
                                  }}
                                  checked={
                                    selectedAppProductName === row?.name
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Paper>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Paper
                elevation={1}
                style={{
                  marginTop: 10,
                  padding: 5,
                  width: "40%",
                }}
              >
                <div
                  style={{
                    margin: 5,
                    fontFamily: "segoeuiRg",
                    color: "gray",
                    fontSize: 14,
                  }}
                >
                  Sms Test
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 60,
                      boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                      borderRadius: 4,
                      backgroundColor: "whitesmoke",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      textAlign: "center",
                      padding: 20,
                    }}
                  >
                    <div>
                      <div>
                        <GreenSwitch
                          onClick={() => {
                            setSmsTest(!smsTest);
                          }}
                          checked={smsTest}
                        />
                      </div>
                    </div>
                    {smsTest && (
                      <PhoneInput
                        countryCodeEditable={false}
                        country="tr"
                        onlyCountries={["tr"]}
                        localization={{ tr: "Türkiye" }}
                        value={testPhone}
                        containerStyle={{ height: 45 }}
                        inputStyle={{
                          height: 45,
                          width: "100%",
                          fontSize: 15,
                          fontWeight: "400",
                        }}
                        onChange={(phone) => {
                          setTestPhone(phone);
                        }}
                      />
                    )}
                  </div>
                </div>
              </Paper>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 20,
              }}
            >
              <Button
                style={{
                  backgroundColor: "green",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  height: 40,
                  marginLeft: 10,
                }}
                size="small"
                onClick={() => {
                  if (smsTest) {
                    let data = {
                      sendObj: {
                        name: smsHeader,
                        content: smsContent,
                        sms_type: smsType,
                        url: smsLink,
                        package_products:
                          selectedAppProduct?.type === "product"
                            ? [selectedAppProduct?.id]
                            : [],
                        platforms:
                          selectedAppProduct?.type === "platform"
                            ? [selectedAppProduct?.id]
                            : [],
                        custom_application:
                          selectedAppProduct === null ? smsHeader : "",
                        phone: testPhone,
                      },
                      setSmsHeader,
                      setSmsContent,
                      setSmsType,
                      setSmsLink,
                      setTestPhone,
                      setSelectedAppProduct,
                      setSmsTest,
                    };
                    createSmsTemplateTestFunc(data);
                  } else {
                    let data = {
                      sendObj: {
                        name: smsHeader,
                        content: smsContent,
                        sms_type: smsType,
                        url: smsLink,
                        package_products:
                          selectedAppProduct?.type === "product"
                            ? [selectedAppProduct?.id]
                            : [],
                        platforms:
                          selectedAppProduct?.type === "platform"
                            ? [selectedAppProduct?.id]
                            : [],
                        custom_application:
                          selectedAppProduct === null ? smsHeader : "",
                      },
                      setSmsHeader,
                      setSmsContent,
                      setSmsType,
                      setSmsLink,
                      setSelectedAppProduct,
                    };
                    createSmsTemplateFunc(data);
                  }
                }}
              >
                Oluştur
              </Button>
            </div>
          </Paper>
        </div>
        <div
          style={{
            width: "50%",
            height: 700,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Paper
            elevation={1}
            style={{
              backgroundColor: "#fff",
              width: "90%",
              height: "90%",
              display: "flex",
              padding: 20,
              flexDirection: "column",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sms İsmi</TableCell>
                    <TableCell align="center">Sms Tipi</TableCell>
                    <TableCell align="center">Sms İçeriği</TableCell>
                    <TableCell align="center">Sms Linki</TableCell>
                    <TableCell align="center">Aksiyon</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {smsTemplateList?.map((row, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">{row?.name}</TableCell>
                        <TableCell align="center">{row?.sms_type}</TableCell>
                        <TableCell align="center">
                          <DescriptionTwoTone
                            style={{
                              cursor: "pointer",
                              width: 24,
                              height: 24,
                            }}
                            onClick={() => {
                              setCurrentRow(row);
                              setContentNoteModal(true);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">{row?.url}</TableCell>
                        <TableCell align="center">
                          <Button
                            style={{
                              backgroundColor: "green",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              display: "flex",
                              justifyContent: "center",
                              height: 40,
                            }}
                            size="small"
                            onClick={() => {
                              setSendSettingsModal(true);
                              setCurrentRow(row);
                            }}
                          >
                            Yolla
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <SendSettingsModal
        sendSettingsModal={sendSettingsModal}
        setSendSettingsModal={setSendSettingsModal}
        platformAndProducts={platformAndProducts}
        currentRow={currentRow}
        type="sms"
      />
      <ContentNoteModal
        contentNoteModal={contentNoteModal}
        setContentNoteModal={setContentNoteModal}
        currentRow={currentRow}
      />
    </>
  );
};

export default SmsSettings;
