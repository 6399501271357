import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputBase,
  Modal,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import AddIcon from "@mui/icons-material/Add";
import { DevelopmentContext } from "../../../contexts/developmentContext";
import { useToast } from "../../../components/snackbar";
import CancelIcon from "@mui/icons-material/Cancel";
import { developmentService } from "../../../services/developmentService";
import FolderIcon from "@mui/icons-material/Folder";

let priorities = [
  {
    id: 1,
    name: 1,
  },
  {
    id: 2,
    name: 2,
  },
  {
    id: 3,
    name: 3,
  },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function ApprovementDetail({
  openApprovementDetail,
  setOpenApprovementDetail,
  approveDetail,
  developmentUserList,
  taskGroups,
}) {
  const { alert, success } = useToast();
  const classes = useStyles();
  const {
    editDevelopmentRequest,
    createDevelopmentRequest,
    rejectDevelopmentRequest,
    deletePdfFromApprovement,
  } = useContext(DevelopmentContext);
  const [formData, setFormData] = useState({
    header: "",
    note: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [color, setColor] = useColor("#561ecb");
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [managerNote, setManagerNote] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [fileObjects, setFileObjects] = useState([]);
  const [loaderFile, setLoaderFile] = useState(false);

  useEffect(() => {
    if (approveDetail) {
      setFormData({
        header: approveDetail.header,
        note: approveDetail.note,
      });
    }
  }, []);

  useEffect(() => {
    if (approveDetail.file) {
      setFiles(approveDetail.file);
    }

    if (approveDetail.links) {
      setLinks(approveDetail.links);
    }
  }, [approveDetail]);

  const handleChange = (e, index) => {
    setCheckedUsers((prev) => {
      let newArr = [...prev];
      newArr[index] = e.target.checked;
      return newArr;
    });
  };

  const onChange = async (e, i) => {
    setLoaderFile(true);
    const filesCopy = [...files];
    filesCopy[i] = e.target.files[0];

    const res = await fetch(fileUrl, {
      method: "PUT",
      headers: {
        "x-amz-acl": "public-read",
      },
      body: e.target.files[0],
    });

    if (res.status === 200) {
      setLoaderFile(false);
      setFileObjects([...fileObjects, objectName]);
    }
  };

  const handleAddFile = async () => {
    await developmentService.get_file_url().then((response) => {
      setFileUrl(response?.url);
      setObjectName(response?.object_name);
    });
    setFiles([...files, null]);
  };

  const handleAddLink = () => {
    setLinks([...links, ""]);
  };

  const handleSaveApprovementHeader = () => {
    let data;
    data = {
      header: formData.header,
      note: formData.note,
    };

    if (approveDetail?.restofficial?.user_id) {
      data = {
        ...data,
        restofficial: approveDetail?.restofficial?.user_id,
      };
    }

    editDevelopmentRequest(data, approveDetail.id);
    setIsDisabled(true);
  };

  const handleSaveApprovementManager = () => {
    const users = developmentUserList.filter(
      (user, index) => checkedUsers[index]
    );
    const usersArr = users.map((user) => user.user_id);

    if (usersArr.length === 0) {
      alert("Lütfen en az bir kişi seçiniz.");
      return;
    }

    if (!selectedPriority) {
      alert("Lütfen öncelik seçiniz.");
      return;
    }

    const formData = {};
    formData.users = usersArr;

    if (links.length > 0) {
      formData.links = links;
    }
    formData.priority = selectedPriority.id;
    formData.color = color.hex;
    formData.note = managerNote;
    if (fileObjects.length > 0) {
      formData.file_addresses = fileObjects;
    }

    if (selectedGroup) {
      formData.task_group = selectedGroup.id;
    }
    createDevelopmentRequest(formData, approveDetail.id);
    setOpenApprovementDetail(false);
  };

  const handleRejectApprovement = () => {
    const data = {
      note: managerNote,
    };

    if (managerNote === "") {
      alert("Lütfen not giriniz.");
      return;
    }

    rejectDevelopmentRequest(data, approveDetail.id);
    setOpenApprovementDetail(false);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openApprovementDetail}
      className={classes.modal}
      onClose={() => {
        setOpenApprovementDetail(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "70%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 10 }}>
            <img
              src={approveDetail.officeuser.picture}
              style={{
                borderRadius: "9999px",
                width: 50,
                height: 50,
              }}
            />
          </div>

          <div
            style={{
              fontWeight: "bold",
            }}
          >
            Talep No:{approveDetail?.id}
          </div>
        </div>

        <div
          style={{
            padding: 10,
            border: "1px solid rgba(191,191,191,0.4)",
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: 20,
            }}
          >
            <div style={{ marginRight: 20, width: "20%" }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Başlık
              </span>
              <InputBase
                disabled={isDisabled}
                style={{
                  background: "#f4f4f4",
                  padding: 6,
                  fontSize: 16,
                  fontWeight: "400",
                  height: 36,
                  fontFamily: "segoeuiRg",
                }}
                value={formData.header}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    header: e.target.value,
                  });
                }}
              />
            </div>

            <div style={{ width: "80%" }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Not
              </span>
              <InputBase
                disabled={isDisabled}
                style={{
                  background: "#f4f4f4",
                  padding: 6,
                  height: 36,
                  fontSize: 16,
                  fontWeight: "400",
                  fontFamily: "segoeuiRg",
                  width: "100%",
                }}
                value={formData.note}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    note: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {isDisabled ? (
              <Button
                variant="contained"
                size="small"
                style={{
                  fontFamily: "segoeuiB",
                  width: 160,
                  fontWeight: 700,
                  backgroundColor: "orange",
                }}
                onClick={() => {
                  setIsDisabled(false);
                }}
              >
                Düzenle
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: "segoeuiB",
                    width: 160,
                    fontWeight: 700,
                    backgroundColor: "red",
                    marginRight: 20,
                  }}
                  onClick={() => {
                    setIsDisabled(true);
                    setFormData({
                      header: approveDetail.header,
                      note: approveDetail.note,
                    });
                  }}
                >
                  Vazgeç
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: "segoeuiB",
                    width: 160,
                    fontWeight: 700,
                    backgroundColor: "#0FBD20",
                  }}
                  onClick={handleSaveApprovementHeader}
                >
                  Kaydet
                </Button>
              </>
            )}
          </div>
        </div>

        <div
          style={{
            padding: 10,
            border: "1px solid rgba(191,191,191,0.4)",
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                marginRight: 20,
                width: "35%",
                paddingRight: 20,
                borderRight: "1px solid rgba(191,191,191,0.4)",
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Kişilere Aktar
              </span>

              <div style={{ maxHeight: 300, overflow: "auto" }}>
                {developmentUserList.map((user, index) => {
                  return (
                    <div key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedUsers[index] || false}
                            onChange={(e) => handleChange(e, index)}
                          />
                        }
                        label={user.full_name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              style={{
                width: "35%",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                borderRadius: 4,
                marginRight: 20,
                paddingRight: 20,
                borderRight: "1px solid rgba(191,191,191,0.4)",
              }}
            >
              <Button
                disabled={loaderFile}
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  marginBottom: 5,
                }}
                onClick={handleAddFile}
              >
                {loaderFile ? (
                  <CircularProgress
                    style={{ color: "#fff", width: 20, height: 20 }}
                  />
                ) : (
                  <>
                    <AddIcon />
                    Dosya Ekle
                  </>
                )}
              </Button>

              <div
                style={{ maxHeight: 120, overflow: "auto", paddingRight: 10 }}
              >
                {files?.map((item, index) => {
                  if (item?.id) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 5,
                          paddingBottom: 5,
                          borderBottom: "1px solid #f4f4f4",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {index + 1}-
                          <FolderIcon
                            style={{
                              color: "red",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              window.open(item.pdf, "_blank");
                            }}
                          />
                        </div>

                        <CancelIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            deletePdfFromApprovement(approveDetail.id, item.id);
                            const filesCopy = [...files];
                            filesCopy.splice(index, 1);
                            setFiles(filesCopy);
                          }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <input
                          name="file"
                          type="file"
                          accept="image/jpeg,image/png,application/pdf,image/x-eps,video/mp4,video/x-m4v,video/*"
                          onChange={(e) => onChange(e, index)}
                        />

                        <CancelIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            const filesCopy = [...files];
                            filesCopy.splice(index, 1);
                            setFiles(filesCopy);
                          }}
                        />
                      </div>
                    );
                  }
                })}
              </div>

              <Button
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  marginTop: 10,
                }}
                onClick={handleAddLink}
              >
                <AddIcon />
                Link Ekle
              </Button>

              <div
                style={{ maxHeight: 150, overflow: "auto", paddingRight: 10 }}
              >
                {links.map((link, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        marginBottom: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: 16,
                            marginBottom: 5,
                            display: "block",
                            fontWeight: "600",
                          }}
                        >
                          Link
                        </span>
                        <InputBase
                          style={{
                            background: "#f4f4f4",
                            padding: 6,
                            fontSize: 16,
                            fontWeight: "400",
                            height: 30,
                            fontFamily: "segoeuiRg",
                            width: "100%",
                          }}
                          value={link}
                          onChange={(e) => {
                            const linksCopy = [...links];
                            linksCopy[i] = e.target.value;
                            setLinks(linksCopy);
                          }}
                        />
                      </div>

                      <CancelIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => {
                          const linksCopy = [...links];
                          linksCopy.splice(i, 1);
                          setLinks(linksCopy);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div style={{ width: "25%" }}>
              <div style={{ marginBottom: 20 }}>
                <span
                  style={{
                    fontSize: 16,
                    marginBottom: 5,
                    display: "block",
                    fontWeight: "600",
                  }}
                >
                  Öncelik Gir
                </span>
                <Select
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  responsiveHeight={44}
                  selectFontSize={16}
                  options={priorities}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={90}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  placeholder={"Öncelik"}
                  value={selectedPriority}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setSelectedPriority(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.name}
                    </div>
                  )}
                />
              </div>

              <div>
                <span
                  style={{
                    display: "block",
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: color.hex,
                  }}
                >
                  Renk Gir
                </span>
                <ColorPicker
                  color={color}
                  onChange={setColor}
                  hideInput={["rgb", "hsv"]}
                  hideAlpha
                  height={100}
                />
              </div>
            </div>
          </div>

          {taskGroups?.length > 0 && (
            <div style={{ marginTop: 20 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Gruplar
              </span>

              <div
                style={{
                  display: "flex",
                  marginTop: 5,
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  width: "100%",
                  paddingBottom: 5,
                }}
              >
                {taskGroups?.map((group, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-block",
                      background:
                        selectedGroup?.id === group.id ? "red" : "none",
                      marginRight: 10,
                      borderRadius: 4,
                      color: selectedGroup?.id === group.id ? "#fff" : "#000",
                      padding: "2px 5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedGroup(group);
                    }}
                  >
                    {group.header}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div style={{ marginTop: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Not
              </span>
              <TextField
                style={{ width: "100%" }}
                placeholder="Not"
                multiline
                rows={3}
                maxRows={5}
                value={managerNote}
                onChange={(e) => {
                  setManagerNote(e.target.value);
                }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  fontFamily: "segoeuiB",
                  width: "50%",
                  fontWeight: 700,
                  backgroundColor: "red",
                  marginRight: 20,
                }}
                onClick={handleRejectApprovement}
              >
                Reddet
              </Button>

              <Button
                disabled={loaderFile}
                variant="contained"
                size="small"
                style={{
                  fontFamily: "segoeuiB",
                  width: "50%",
                  fontWeight: 700,
                  backgroundColor: "#0FBD20",
                }}
                onClick={handleSaveApprovementManager}
              >
                {loaderFile ? (
                  <CircularProgress
                    style={{ color: "#fff", width: 20, height: 20 }}
                  />
                ) : (
                  "Onayla"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
