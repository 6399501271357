import { useContext, useEffect, useState } from "react";
import { Box, Button, createTheme, Modal, useMediaQuery } from "@mui/material";
import { selectDefaultStyles } from "../../selectStyles/stylev3";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { useToast } from "../../snackbar";

export default function SendSmsModal({
  showSmsModal,
  setShowSmsModal,
  smsSettingsList,
  selectedSmsRest,
  isRestaurant,
  isKunye,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedReason, setSelectedReason] = useState(null);
  const [formData, setFormData] = useState({
    phone: "",
  });
  const { sendSmsToCustomer } = useContext(RestaurantContext);
  const { alert } = useToast();

  useEffect(() => {
    if (selectedSmsRest) {
      setFormData({ phone: selectedSmsRest?.phone });
    }
  }, [selectedSmsRest]);

  return (
    <Modal
      open={showSmsModal}
      onClose={() => setShowSmsModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "40%" : "90%" }}>
        <Box>
          <div style={{ marginBottom: 20 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Sms Tipi Seçiniz
            </span>

            <Select
              options={smsSettingsList}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              placeholder={"Seçiniz"}
              value={selectedReason}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSelectedReason(e);
              }}
              getOptionLabel={(option) => option.header}
            />
          </div>

          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Telefon Numarası
            </span>
            <PhoneInput
              countryCodeEditable={false}
              country="tr"
              onlyCountries={["tr"]}
              localization={{ tr: "Türkiye" }}
              value={formData?.phone}
              containerStyle={{ height: 45 }}
              inputStyle={{
                height: 45,
                width: "100%",
                fontSize: 15,
                fontWeight: "400",
                fontFamily: "segoeuiRg",
              }}
              onChange={(phone) => {
                setFormData({ ...formData, phone });
              }}
            />
          </div>

          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              width: 160,
              fontWeight: 700,
              backgroundColor: "#0FBD20",
              marginTop: 20,
            }}
            onClick={() => {
              if (!selectedReason) return alert("Sms tipi seçiniz");
              let data;

              if (selectedSmsRest?.phone === formData.phone) {
                if (selectedSmsRest?.restofficial) {
                  data = {
                    header: selectedReason.id,
                    restofficial: selectedSmsRest?.restofficial,
                  };
                } else if (selectedSmsRest?.user_id) {
                  data = {
                    header: selectedReason.id,
                    restofficial: selectedSmsRest?.user_id,
                  };
                } else if (selectedSmsRest?.user?.id) {
                  data = {
                    header: selectedReason.id,
                    restofficial: selectedSmsRest?.user.id,
                  };
                } else {
                  data = {
                    header: selectedReason.id,
                    application: selectedSmsRest?.id,
                  };
                }
              } else {
                if (selectedSmsRest?.restofficial) {
                  data = {
                    header: selectedReason.id,
                    restofficial: selectedSmsRest?.restofficial,
                  };
                } else if (selectedSmsRest?.user_id) {
                  data = {
                    header: selectedReason.id,
                    restofficial: selectedSmsRest?.user_id,
                  };
                } else if (selectedSmsRest?.user?.id) {
                  data = {
                    header: selectedReason.id,
                    restofficial: selectedSmsRest?.user.id,
                  };
                } else {
                  data = {
                    header: selectedReason.id,
                    application: selectedSmsRest?.id,
                    phone: formData.phone,
                  };
                }
              }
              sendSmsToCustomer(data);
              setShowSmsModal(false);
              setSelectedReason(null);
              setFormData({ phone: selectedSmsRest?.phone });
            }}
          >
            Gönder
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
