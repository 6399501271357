import React from "react";
import {
  Button,
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    height: "auto",
  },
}));

export default function ProductExpiredModal({
  showProductModal,
  setShowProductModal,
  dates,
}) {
  const classes = useStyles();
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={showProductModal}
      className={classes.modal}
      onClose={() => {
        setShowProductModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#f4f4f4",
          padding: 20,
          maxHeight: "95%",
          overflow: "auto",
          minHeight: "95%",
        }}
      >
        <div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 200 }}>
                    Ürün
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 200 }}>
                    Ödeme Durumu
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 200 }}>
                    Sözleşme Başlangıç Tarihi
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 200 }}>
                    Sözleşme Bitiş Tarihi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dates?.map((date) => {
                  return date.product.map((product, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          {product.product__name}
                        </TableCell>
                        <TableCell align="center">
                          {date.payed ? "Ödendi" : "Ödenmedi"}
                        </TableCell>
                        <TableCell align="center">
                          {moment(date.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {moment(date.expires_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  });
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </Modal>
  );
}
