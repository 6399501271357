import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";
import {
  DeleteForever,
  DescriptionTwoTone,
  DoneOutlined,
} from "@mui/icons-material";
import DailyExelModal from "./modals";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});
export default function DailyPayment() {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const {
    dailyPaymentListFunc,
    dailyPaymentList,
    dailyPaymentPatchFunc,
    dailyPaymentDeleteFunc,
    dailyPaymentExelFunc,
  } = useContext(ManagementContext);
  const [page, setPage] = useState(0);
  const [dailyExelModal, setDailyExelModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    dailyPaymentListFunc();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          size="large"
          style={{
            fontFamily: "segoeuiB",
            fontWeight: 700,
            backgroundColor: "red",
          }}
          onClick={() => {
            dailyPaymentExelFunc();
          }}
        >
          Günlük Exel Oluştur
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Tarih</TableCell>
              <TableCell align="center">Exel</TableCell>
              <TableCell align="center">Ödeme Bilgisi</TableCell>
              <TableCell align="center">Ödendiği Tarih</TableCell>
              <TableCell align="center">Ödemeyi Sil</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dailyPaymentList?.results?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    {moment(row.created).format("DD-MM-YYYY HH:mm")}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <DescriptionTwoTone
                      style={{
                        cursor: "pointer",
                        width: 24,
                        height: 24,
                      }}
                      onClick={() => {
                        setCurrentRow(row);
                        setDailyExelModal(true);
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row?.payed ? (
                      <DoneOutlined
                        style={{
                          width: 26,
                          height: 26,
                          color: "green",
                        }}
                      />
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#f53b62",
                          borderRadius: 12,
                          width: "35%",
                        }}
                        variant="contained"
                        onClick={() => {
                          dailyPaymentPatchFunc({ id: row?.id });
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          Ödendi
                        </span>
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row?.payed_at !== null
                      ? moment(row?.payed_at).format("DD-MM-YYYY HH:mm")
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    <DeleteForever
                      onClick={() => {
                        if (!row?.payed) {
                          dailyPaymentDeleteFunc({ id: row?.id });
                        }
                      }}
                      style={{
                        width: 26,
                        height: 26,
                        color: row?.payed ? "gray" : "red",
                        cursor: "pointer",
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {dailyPaymentList != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={dailyPaymentList?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DailyExelModal
        dailyExelModal={dailyExelModal}
        setDailyExelModal={setDailyExelModal}
        currentRow={currentRow}
      />
    </Box>
  );
}
