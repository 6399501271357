import { createTheme, InputBase, useMediaQuery } from "@mui/material";
import React from "react";

const RegistrationField = ({
  fieldHeader,
  fieldValue,
  fieldName,
  inputDisabled,
  formData,
  setFormData,
}) => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const handleChange = (name) => (event) => {
    let valueData = event.target ? event.target.value : event;
    let newDataRest = { ...formData };

    newDataRest = { ...formData, [name]: valueData };

    setFormData(newDataRest);
  };

  return (
    <div style={{ flex: 1 }}>
      {fieldHeader && (
        <span
          style={{
            color: "#293c51",
            fontFamily: "segoeuiRg",
            fontSize: 15,
            fontWeight: 700,
            marginLeft: 8,
          }}
        >
          {fieldHeader}
        </span>
      )}

      <div
        style={{
          flex: 1,
          backgroundColor: inputDisabled
            ? inputDisabled
              ? "darkgrey"
              : "#fff"
            : "#fff",
          height: 45,
          marginTop: matches ? 10 : 5,
          marginBottom: matches ? 0 : 10,
          borderRadius: 6,
          marginRight: 14,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderRadius: 8,
          }}
        >
          <InputBase
            variant="filled"
            disabled={inputDisabled ? inputDisabled : false}
            style={{
              width: "100%",
              color: "black",
              marginLeft: 14,
              fontSize: 15,
              fontWeight: "400",
              fontFamily: "segoeuiRg",
              height: 45,
            }}
            value={fieldValue ?? ""}
            onChange={handleChange(fieldName)}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationField;
