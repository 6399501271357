import React from "react";
import moment from "moment";
import { Box, Modal, createTheme, useMediaQuery } from "@mui/material";

const WorksNotesModal = ({
  showNotesModal,
  setShowNotesModal,
  selectedNotes,
}) => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Modal
      open={showNotesModal}
      onClose={() => setShowNotesModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
          width: matches ? "50%" : "90%",
          maxHeight: 600,
          overflow: "auto",
        }}
      >
        {selectedNotes.map((note, i) => (
          <div
            key={i}
            style={{
              padding: "5px 0px",
              borderBottom: "1px solid rgba(88,88,88,0.1)",
            }}
          >
            <p>
              {moment(note?.created_at).format("DD-MM-YYYY HH:mm")} |{" "}
              {note?.created_by?.name} | {note?.note}
            </p>
          </div>
        ))}
      </Box>
    </Modal>
  );
};
const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};

export default WorksNotesModal;
