import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardContent, Divider, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ArrowRightAltOutlined } from "@mui/icons-material";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const OfferModalV2 = ({ offerModal, setOfferModal, offerCode }) => {
  const classes = useStyles();
  const { offerInfoFunc, offerDetail } = useContext(RestaurantContext);
  const [offerDetailObj, setOfferDetailObj] = useState(null);

  useEffect(() => {
    if (offerModal === true) {
      offerInfoFunc({
        offerCode,
      });
    }
  }, [offerModal]);

  useEffect(() => {
    if (offerDetail !== null) {
      const newOfferObj = offerDetail?.product_offers_new?.concat(
        offerDetail?.module_offers_new
      );
      setOfferDetailObj(newOfferObj);
    }
  }, [offerDetail]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={offerModal}
      className={classes.modal}
      onClose={() => {
        setOfferModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#fff",
          padding: 25,
          maxHeight: 700,
          overflow: "auto",
        }}
      >
        <CardContent style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "90%",
              height: "90%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                padding: 10,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "auto",
                }}
              >
                <span
                  style={{
                    fontSize: 32,
                    fontFamily: "segoeuiRg",
                    color: "#293c51",
                  }}
                >
                  Güncel Teklifiniz
                </span>
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "segoeuiRg",
                    color: "#293c51",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: 20,
                  }}
                >
                  Kullanım başlangıç:{" "}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        fontFamily: "segoeuiRg",
                        fontSize: 18,
                      }}
                    >
                      {moment(offerDetail?.starts_at).format("DD-MM-YYYY")}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "segoeuiRg",
                    color: "#293c51",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: 20,
                  }}
                >
                  Kullanım bitiş:{" "}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        fontFamily: "segoeuiRg",
                        fontSize: 18,
                      }}
                    >
                      {moment(offerDetail?.expires_at).format("DD-MM-YYYY")}
                    </span>
                  </div>
                </div>
                <Divider />
                {offerDetailObj?.map((item, index) => {
                  return (
                    <>
                      <div
                        style={{
                          width: "100%",
                          marginTop: 20,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            fontFamily: "segoeuiB",
                            color: "#293c51",
                          }}
                        >
                          {item?.product?.name || item?.module?.name} (
                          {item?.count}
                          x)
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {item?.base_amount !== item?.amount && (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  textDecorationColor: "red",
                                  fontFamily: "segoeuiB",
                                  fontSize: 18,
                                  color: "#293c51",
                                }}
                              >
                                {item?.base_amount}
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <ArrowRightAltOutlined />
                              </div>
                            </>
                          )}

                          <span
                            style={{
                              fontFamily: "segoeuiB",
                              fontSize: 18,
                              color: "#293c51",
                            }}
                          >
                            {item?.amount}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div>
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "segoeuiRg",
                    color: "#293c51",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "#293c51",
                    marginTop: 15,
                  }}
                >
                  Toplam İndirim
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        fontFamily: "segoeuiB",
                        color: "#293c51",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      -{" "}
                      {offerDetail?.total_base_amount -
                        offerDetail?.total_amount}{" "}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "segoeuiRg",
                    color: "#293c51",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "red",
                    marginTop: 15,
                  }}
                >
                  Toplam fiyat(KDV Dahil):
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        textDecoration: "line-through",
                        textDecorationColor: "black",
                        fontFamily: "segoeuiB",
                      }}
                    >
                      {offerDetail?.total_base_amount}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ArrowRightAltOutlined />
                    </div>
                    <span
                      style={{
                        fontFamily: "segoeuiB",
                        textDecoration: "underline",
                      }}
                    >
                      {offerDetail?.total_amount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OfferModalV2;
