import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { useToast } from "../../snackbar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const BlockPotetialModal = ({
  blockPotentialModal,
  setBlockPotentialModal,
  selectedPotentialType,
  setSelectedPotentialType,
}) => {
  const classes = useStyles();
  const [potential, setPotential] = useState(null);
  const { alert } = useToast();

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={blockPotentialModal}
        className={classes.modal}
        onClose={() => {
          setBlockPotentialModal(false);
        }}
      >
        <Card
          className={classes.paper}
          style={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            padding: 25,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  checked={potential === "potential"}
                  onChange={(e) => {
                    setPotential("potential");
                  }}
                  sx={{
                    marginTop: -1,
                    color: "#f53b62",
                    "&.Mui-checked": {
                      color: "#f53b62",
                    },
                  }}
                />
              }
              style={{ textAlign: "center" }}
              label={
                <span
                  style={{
                    fontSize: 14,
                  }}
                >
                  Potensiyel
                </span>
              }
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  checked={potential === "notpotential"}
                  onChange={(e) => {
                    setPotential("notpotential");
                  }}
                  sx={{
                    marginTop: -1,
                    color: "#f53b62",
                    "&.Mui-checked": {
                      color: "#f53b62",
                    },
                  }}
                />
              }
              style={{ textAlign: "center" }}
              label={
                <span
                  style={{
                    fontSize: 14,
                  }}
                >
                  Potensiyel Değil
                </span>
              }
            />
          </div>
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 100,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              marginRight: 30,
              height: 40,
              textTransform: "capitalize",
            }}
            size="small"
            onClick={() => {
              if (potential !== null) {
                setSelectedPotentialType(potential);
              } else {
                alert("Potensiyel durumu seçilmeli.");
              }
            }}
          >
            Tamamla
          </Button>
        </Card>
      </Modal>
    </>
  );
};

export default BlockPotetialModal;
