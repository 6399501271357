import { useContext, useEffect, useState } from "react";
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import CustomerSearch from "../../../../components/search";
import useDebounce from "../../../../components/debounce";
import { ManagementContext } from "../../../../contexts/managementContext";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import { makeStyles } from "@mui/styles";
import DirectionsIcon from "@mui/icons-material/Directions";
import OfficeUserList from "../../../../components/modals/officeUserList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

export default function AllData() {
  const [searchValue, setSearchValue] = useState("");
  const debouncedsearchValue = useDebounce(searchValue, 1000);
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [type, setType] = useState(null);

  const { getApplicationTransferList } = useContext(RestaurantContext);

  const { patchDataResult, getDataResult, dataResult } =
    useContext(ManagementContext);

  useEffect(() => {
    getDataResult(debouncedsearchValue);
  }, [debouncedsearchValue]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ textAlign: "center" }}>
        <span
          style={{
            fontSize: 20,
            marginBottom: 10,
            display: "block",
            fontWeight: "600",
          }}
        >
          Arama Yapın
        </span>
        <div style={{ width: "50%", margin: "auto" }}>
          <CustomerSearch
            value={searchValue}
            placeholder={"Data Ara"}
            onchange={setSearchValue}
          />
        </div>
      </Box>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginTop: 1,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          centered
          className={classes.tabs}
        >
          <Tab label="Data" {...a11yProps(0)} />
          <Tab label="Zero Data" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Restoran İsmi</TableCell>
                  <TableCell align="center">Yetkili</TableCell>
                  <TableCell align="center">Cep no</TableCell>
                  <TableCell align="center">Data Tipi</TableCell>
                  <TableCell align="center">Yönlendir</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataResult?.data?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {row.restaurant_name}
                      </TableCell>
                      <TableCell align="center">{row.full_name}</TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">
                        {row.data_type} / {row.processed_by.name} / {row.status}
                      </TableCell>
                      <TableCell align="center">
                        <DirectionsIcon
                          style={styles.icon}
                          onClick={async () => {
                            setSelectedItem(row);
                            await getApplicationTransferList();
                            setType("application");
                            setOpenModal(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Restoran İsmi</TableCell>
                  <TableCell align="center">Yetkili</TableCell>
                  <TableCell align="center">Cep no</TableCell>
                  <TableCell align="center">Yönlendir</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataResult?.zero_data?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.full_name}</TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">
                        <DirectionsIcon
                          style={styles.icon}
                          onClick={async () => {
                            setSelectedItem(row);
                            await getApplicationTransferList();
                            setType("zero");
                            setOpenModal(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Box>

      <OfficeUserList
        openModal={openModal}
        setOpenModal={setOpenModal}
        selectedItem={selectedItem}
        patchAction={patchDataResult}
        allData={true}
        type={type}
        debouncedsearchValue={debouncedsearchValue}
      />
    </Box>
  );
}

const styles = {
  icon: {
    width: 30,
    height: 30,
    color: "#319a77",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    cursor: "pointer",
  },

  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
