import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import {
  Drawer,
  ListItemText,
  ListItemButton,
  List,
  Collapse,
} from "@mui/material";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  drawerClose: {
    overflowX: "hidden",
    width: "14%",
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
}));

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 8,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 12,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const Sidebar = ({ sidebarData = [] }) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState({});
  const [open, setOpen] = useState(false);
  const [isFinanceUser, setIsFinanceUser] = useState(false);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));
    if (
      userInfo.department === "Operasyon Yonetimi" &&
      userInfo.sub_department === "Finans"
    ) {
      setIsFinanceUser(true);
    } else {
      setIsFinanceUser(false);
    }

    sidebarData.map((item, i) => {
      if (item.path === history.location.pathname) {
        setCurrentPage(item);
      }
      item.subMenu?.map((subItem) => {
        if (subItem.path === history.location.pathname) {
          setOpen(true);
          setCurrentPage(subItem);
        }
      });
    });
  }, []);

  const { id } = useParams();
  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiListItemButton: {
            defaultProps: {
              disableTouchRipple: true,
            },
          },
        },
        palette: {
          mode: "light",
          primary: { main: "#f4f4f4" },
          background: { paper: "#f4f4f4" },
        },
      })}
    >
      <Drawer
        variant="permanent"
        classes={{ paper: classes.drawerClose }}
        open={false}
      >
        <FireNav
          sx={{
            width: "100%",
            maxWidth: 360,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {sidebarData.map((item, idx) => (
            <div key={idx} style={{ width: "85%" }}>
              <ListItemButton
                disabled={item.path === "/finans" && !isFinanceUser}
                onClick={() => {
                  const res_id =
                    id != null ? `${item.path}/${id}` : `${item.path}`;
                  setCurrentPage(item);
                  history.push(res_id);
                  if (item.subMenu) {
                    setOpen(!open);
                  } else {
                    setOpen(false);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  minHeight: 32,
                  backgroundColor: "white",
                  borderRadius: 8,
                  color: currentPage.id == idx ? "#f53f62" : "black",
                }}
              >
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: "medium",
                    fontFamily: "segoeuiRg",
                  }}
                />
                {item.subMenu && (open ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {item.subMenu &&
                item.subMenu.map((sub, i) => (
                  <Collapse
                    key={i}
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      width: "90%",
                      marginLeft: "auto",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      alignSelf: "flex-end",
                    }}
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <ListItemButton
                        key={"5"}
                        onClick={() => {
                          history.push(sub.path);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "white",
                          },
                          py: 0,
                          minHeight: 32,
                          backgroundColor: "white",
                          width: "85%",
                          borderRadius: 8,
                        }}
                      >
                        <ListItemText
                          primary={sub.sublabel}
                          primaryTypographyProps={{
                            fontSize: 14,
                            fontWeight: "medium",
                            fontFamily: "segoeuiRg",
                            color:
                              sub.path === window.location.pathname
                                ? "#f53f62"
                                : "black",
                          }}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
            </div>
          ))}
        </FireNav>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
