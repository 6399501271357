import { useContext, useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Box } from "@mui/system";
import Select from "react-select";
import managementService from "../../../../services/managementService";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import useWindowDimensions from "../../../../components/windowSize";
import { RestaurantContext } from "../../../../contexts/restaurantContext";

export default function ApplicationTypeManagement() {
  const {
    getOfficeUserListWithRole,
    backofficeListWithRole,
    patchOfficeuserDetailFunc,
    getOfficeUserListWithRoleCollector,
    backofficeCollectorList,
    patchOfficeuserDetailFuncCollector,
  } = useContext(RestaurantContext);
  const [people, setPeople] = useState(null);
  const [types, setTypes] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTechUser, setSelectedTechUser] = useState(null);
  const [selectedTechStatus, setSelectedTechStatus] = useState(null);
  const [selectedCollectorStatus, setSelectedCollectorStatus] = useState(null);
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedSector, setIsCheckedSector] = useState([]);
  const [selectedCollectorUser, setSelectedCollectorUser] = useState(null);
  const { width } = useWindowDimensions();

  const getTypes = async () => {
    const response = await managementService.permission_application_types();
    setTypes(response);
  };

  const getPeople = async () => {
    const response = await managementService.permission_application_people();
    setPeople(response);
  };

  const handleSelectPerson = (person) => {
    setSelectedUser(person);
  };

  const handleChangeCheck = (index) => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  };

  const handleChangeCheckSector = (index) => {
    setIsCheckedSector(isCheckedSector.map((v, i) => (i === index ? !v : v)));
  };

  const handleSaveApplicationType = async () => {
    let selectedCheckboxes = [];
    let selectedSectorBoxes = [];
    isChecked.map((check, i) => {
      if (check) {
        selectedCheckboxes.push(types.application_types[i].key);
      }
    });

    isCheckedSector.map((check, i) => {
      if (check) {
        selectedSectorBoxes.push(types.sector_types[i].key);
      }
    });

    await managementService.patch_application_type(selectedUser.user_id, {
      application_type: selectedCheckboxes,
      application_sector: selectedSectorBoxes,
    });
    setSelectedUser(null);
  };

  useEffect(() => {
    if (!selectedUser) {
      getPeople();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      const checkedUserTypes = types.application_types.map((typ) => {
        return selectedUser?.application_type.includes(typ.key);
      });
      setIsChecked(checkedUserTypes);

      const checkedUserTypesSector = types.sector_types.map((typ) => {
        return selectedUser?.application_sector.includes(typ.key);
      });
      setIsCheckedSector(checkedUserTypesSector);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (types) {
      setIsChecked(types.application_types.slice().fill(false));
      setIsCheckedSector(types.sector_types.slice().fill(false));
    }
  }, [types]);

  useEffect(() => {
    getTypes();
    getOfficeUserListWithRole({
      role: "tech",
      task_types: "",
    });

    getOfficeUserListWithRoleCollector({
      role: "collector",
      task_types: "",
    });
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <Box sx={{ marginRight: 4 }}>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Kişiler
              </span>
              <Select
                options={people}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                isSearchable={false}
                hoverColor={"black"}
                menuColor={"#f53b62"}
                placeholder={"Kişi Seç"}
                selectWidth={width > 1200 ? 230 : 140}
                value={selectedUser}
                getOptionValue={(option) => option.user_id}
                onChange={(e) => {
                  handleSelectPerson(e);
                  setSelectedTechUser(null);
                }}
                getOptionLabel={(option) => option.full_name}
              />
            </div>
          </Box>
          <Box sx={{ marginRight: 4 }}>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Teknik
              </span>
              <Select
                options={backofficeListWithRole}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                isSearchable={false}
                hoverColor={"black"}
                menuColor={"#f53b62"}
                placeholder={"Kişi Seç"}
                selectWidth={width > 1200 ? 230 : 140}
                value={selectedTechUser}
                getOptionValue={(option) => option.user_id}
                onChange={(e) => {
                  setSelectedTechUser(e);
                  setSelectedCollectorUser(null);
                  setSelectedUser(null);
                  setSelectedTechStatus(e.accept_support);
                  backofficeListWithRole.map((list) => {
                    if (list.id === e.id) {
                      setSelectedTechStatus(e.accept_support);
                    }
                  });
                }}
                getOptionLabel={(option) => option.full_name}
              />
            </div>
          </Box>

          <Box sx={{ marginRight: 4 }}>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Tahsilat
              </span>
              <Select
                options={backofficeCollectorList}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                isSearchable={false}
                hoverColor={"black"}
                menuColor={"#f53b62"}
                placeholder={"Kişi Seç"}
                selectWidth={width > 1200 ? 230 : 140}
                value={selectedCollectorUser}
                getOptionValue={(option) => option.user_id}
                onChange={(e) => {
                  setSelectedCollectorUser(e);
                  setSelectedTechUser(null);
                  setSelectedUser(null);
                  backofficeCollectorList.map((list) => {
                    if (list.id === e.id) {
                      setSelectedCollectorStatus(e.accept_data);
                    }
                  });
                }}
                getOptionLabel={(option) => option.full_name}
              />
            </div>
          </Box>
        </div>
        {selectedUser && (
          <>
            <Box sx={{ marginRight: 4 }}>
              <h3
                style={{
                  fontFamily: "segoeuiRg",
                  fontSize: 20,
                }}
              >
                Başvuru Tipi Seç
              </h3>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {types?.application_types.map((type, i) => {
                  return (
                    <FormGroup key={i}>
                      <FormControlLabel
                        onChange={() => handleChangeCheck(i)}
                        control={<Checkbox checked={isChecked[i]} />}
                        label={type.value}
                      />
                    </FormGroup>
                  );
                })}
              </Box>
            </Box>

            <Box sx={{ marginRight: 4 }}>
              <h3
                style={{
                  fontFamily: "segoeuiRg",
                  fontSize: 20,
                }}
              >
                Sektör Tipi Seç
              </h3>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {types?.sector_types.map((type, i) => {
                  return (
                    <FormGroup key={i}>
                      <FormControlLabel
                        onChange={() => handleChangeCheckSector(i)}
                        control={<Checkbox checked={isCheckedSector[i]} />}
                        label={type.value}
                      />
                    </FormGroup>
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  color: "#000",
                  fontFamily: "segoeuiB",
                  width: 100,
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 20,
                  height: 40,
                  textTransform: "capitalize",
                  border: "1px solid #000",
                }}
                size="small"
                onClick={() => {
                  const checkedUserTypes = types.application_types.map(
                    (typ) => {
                      return selectedUser?.application_type.includes(typ.key);
                    }
                  );
                  setIsChecked(checkedUserTypes);

                  const checkedUserTypesSector = types.sector_types.map(
                    (typ) => {
                      return selectedUser?.application_sector.includes(typ.key);
                    }
                  );
                  setIsCheckedSector(checkedUserTypesSector);
                  setSelectedUser(null);
                }}
              >
                Vazgeç
              </Button>

              <Button
                onClick={handleSaveApplicationType}
                style={{
                  backgroundColor: "#f53f62",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 100,
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 30,
                  height: 40,
                  textTransform: "capitalize",
                }}
                size="small"
              >
                Kaydet
              </Button>
            </Box>
          </>
        )}
        {selectedTechUser && (
          <>
            <Box sx={{ marginRight: 4 }}>
              <h3
                style={{
                  fontFamily: "segoeuiRg",
                  fontSize: 20,
                }}
              >
                Teknik Destek Durumu
              </h3>

              <FormGroup>
                <FormControlLabel
                  onChange={() => {
                    patchOfficeuserDetailFunc({
                      user_id: selectedTechUser?.user_id,
                      sendObj: {
                        accept_support: !selectedTechUser?.accept_support,
                      },
                      setSelectedTechStatus,
                      selectedTechStatus,
                    });
                  }}
                  control={<Checkbox checked={selectedTechStatus} />}
                  label={selectedTechStatus ? "Açık" : "Kapalı"}
                />
              </FormGroup>
            </Box>
          </>
        )}

        {selectedCollectorUser && (
          <>
            <Box sx={{ marginRight: 4 }}>
              <h3
                style={{
                  fontFamily: "segoeuiRg",
                  fontSize: 20,
                }}
              >
                Tahsilat Destek Durumu
              </h3>

              <FormGroup>
                <FormControlLabel
                  onChange={() => {
                    patchOfficeuserDetailFuncCollector({
                      user_id: selectedCollectorUser?.user_id,
                      sendObj: {
                        accept_data: !selectedCollectorUser?.accept_data,
                      },
                      setSelectedCollectorStatus,
                      selectedCollectorStatus,
                    });
                  }}
                  control={<Checkbox checked={selectedCollectorStatus} />}
                  label={selectedCollectorStatus ? "Açık" : "Kapalı"}
                />
              </FormGroup>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
