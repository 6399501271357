import React, { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import TablePaginationActions from "../../../../components/pagination/mainPagination";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RequestStatusesModal from "../../../../components/modals/requestStatuses";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

export default function BaskiRequestsFinance() {
  const classes = useStyles();
  const {
    getBaskiRequestsFinance,
    printingRequestList,
    getBrochurePicture,
    actionPrintingRequestFinance,
  } = useContext(ManagementContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getBaskiRequestsFinance(page + 1);
  }, [page]);

  return (
    <div>
      <span
        style={{
          fontSize: 20,
          marginBottom: 10,
          display: "block",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Baskı İstekleri
      </span>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Teslimat Adresi</TableCell>
              <TableCell align="center">Oluşturulma Tarihi</TableCell>
              <TableCell align="center">Restoran Adı</TableCell>
              <TableCell align="center">Oluşturan Kişi</TableCell>
              <TableCell align="center">Matbaa</TableCell>
              <TableCell align="center">Baskı Tipi</TableCell>
              <TableCell align="center">Gramaj</TableCell>
              <TableCell align="center">Boyut</TableCell>
              <TableCell align="center">Adet</TableCell>
              <TableCell align="center">Baskı Yönü</TableCell>
              <TableCell align="center">Fiyat</TableCell>
              <TableCell align="center">Kdv Oranı</TableCell>
              <TableCell align="center">Kargo Ücreti</TableCell>
              <TableCell align="center">Durum</TableCell>
              <TableCell align="center">Aksiyon</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printingRequestList?.results?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    <Tooltip title={row.cargo_delivery_address}>
                      <InfoIcon style={{ color: "#f53b62" }} />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    {moment(row.created_at).format("DD.MM.YYYY HH:mm")}
                  </TableCell>
                  <TableCell align="center">{row.restofficial.name}</TableCell>
                  <TableCell align="center">
                    {row.officeuser.full_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.printingpress.company_title}
                  </TableCell>
                  <TableCell align="center">
                    {row.printing_type.printing_type_title}
                  </TableCell>
                  <TableCell align="center">{row.grammage}</TableCell>
                  <TableCell align="center">{row.size}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">{row.direction}</TableCell>
                  <TableCell align="center">{row.price}</TableCell>
                  <TableCell align="center">{row.vat_rate} %</TableCell>
                  <TableCell align="center">{row.cargo_price}</TableCell>
                  <TableCell align="center">
                    {row.request_status === "waiting_confirm"
                      ? "Matbaa Onay Bekliyor"
                      : row.request_status === "waiting_offer"
                      ? "Matbaa'ya yönlendirildi"
                      : row.request_status === "created"
                      ? "Baskı İsteği Oluşturuldu"
                      : row.request_status === "confirm" &&
                        row.is_document_downloaded
                      ? "PDF Baskıda"
                      : row.request_status === "confirm" &&
                        !row.is_document_downloaded
                      ? "Finans Onayladı"
                      : row.request_status === "shipped"
                      ? "Kargoya Verildi"
                      : row.request_status === "delivered"
                      ? "Teslim Edildi"
                      : row.request_status === "checking"
                      ? "Teslim Edildi"
                      : row.request_status === "complated"
                      ? "Teslim Edildi"
                      : row.request_status === "unapproved"
                      ? "Matbaa Onay Bekleniyor"
                      : ""}
                  </TableCell>
                  <TableCell align="center">
                    {row.request_status === "waiting_confirm" && (
                      <>
                        <CheckCircleIcon
                          style={{
                            color: "#f53b62",
                            cursor: "pointer",
                            marginRight: 10,
                          }}
                          onClick={() => {
                            const data = {
                              request_status: "confirm",
                            };
                            actionPrintingRequestFinance(
                              data,
                              row.id,
                              page + 1
                            );
                          }}
                        />
                        <RemoveCircleIcon
                          style={{ color: "#f53b62", cursor: "pointer" }}
                          onClick={() => {
                            const data = {
                              request_status: "unapproved",
                            };
                            actionPrintingRequestFinance(
                              data,
                              row.id,
                              page + 1
                            );
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {printingRequestList?.results != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={printingRequestList?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
