import { Box, Container } from "@mui/material";
import KpiOutbound from "./components/KpiOutbound";
import KpiCollector from "./components/KpiCollector";
import KpiSetup from "./components/KpiSetup";
import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../../../../components/datePickerInput";
import { useContext, useEffect, useState } from "react";
import useWindowDimensions from "../../../../components/windowSize";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";
import KpiTickets from "./components/KpiTickets";
import KpiStateHistories from "./components/KpiStateHistories";

export default function KpiReports() {
  const { width } = useWindowDimensions();
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [startDate, endDate] = dateRange;
  const { getApplicationTransferList, backofficeList } =
    useContext(RestaurantContext);
  const [workerSelect, setWorkerSelect] = useState(null);
  const [startHistoryDate, setStartHistoryDate] = useState(new Date());
  const {
    getKpiOutbound,
    kpiOutbound,
    getKpiSetup,
    kpiSetup,
    getKpiCollector,
    kpiCollector,
    getKpiTickets,
    kpiTickets,
    getKpiStateHistory,
    kpiStateHistory,
  } = useContext(ManagementContext);

  const handleChangeWorker = (e) => {
    setWorkerSelect(e);
  };

  useEffect(() => {
    backofficeList.unshift({
      user_id: 0,
      full_name: "Tümü",
    });
  }, [backofficeList]);

  useEffect(() => {
    let data = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      userId: workerSelect
        ? workerSelect?.user_id === 0
          ? ""
          : workerSelect?.user_id
        : "",
    };

    let dataState = {
      day: moment(startHistoryDate).format("YYYY-MM-DD"),
      userId: workerSelect
        ? workerSelect?.user_id === 0
          ? ""
          : workerSelect?.user_id
        : "",
    };

    if (startDate && endDate) {
      getKpiOutbound(data);
      getKpiSetup(data);
      getKpiCollector(data);
      getKpiTickets(data);
    }
  }, [startDate, endDate, workerSelect]);

  useEffect(() => {
    let dataState = {
      day: moment(startHistoryDate).format("YYYY-MM-DD"),
      userId: workerSelect
        ? workerSelect?.user_id === 0
          ? ""
          : workerSelect?.user_id
        : "",
    };
    getKpiStateHistory(dataState);
  }, [startHistoryDate]);

  useEffect(() => {
    getApplicationTransferList();
  }, []);

  return (
    <Container>
      <h3
        style={{
          fontFamily: "segoeuiB",
          fontSize: 24,
          textAlign: "center",
        }}
      >
        KPI RAPORLARI
      </h3>

      <Box sx={{ display: "flex", marginBottom: 4 }}>
        <div style={{ marginRight: 10 }}>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Tarih
          </span>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            fixedHeight="45px"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            onChange={(update) => {
              setDateRange(update);
            }}
            customInput={
              <CustomDatePickerInput selectWidth={width > 1200 ? 230 : 140} />
            }
            locale="tr"
          />
        </div>

        <div style={{ marginRight: 10 }}>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Kişiler
          </span>
          <Select
            options={backofficeList}
            styles={{ ...selectDefaultStyles, height: "555px" }}
            isSearchable={false}
            hoverColor={"black"}
            menuColor={"#f53b62"}
            selectWidth={width > 1200 ? 230 : 140}
            placeholder={"Tümü"}
            value={workerSelect}
            getOptionValue={(option) => option.user_id}
            onChange={(e) => {
              handleChangeWorker(e);
            }}
            getOptionLabel={(option) => option.full_name}
          />
        </div>

        <div style={{ marginRight: 10 }}>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Kişi Geçmişi Tarih Filtrele
          </span>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={startHistoryDate}
            onChange={(date) => setStartHistoryDate(date)}
            maxDate={moment(new Date()).toDate()}
            locale="tr"
            customInput={
              <CustomDatePickerInput selectWidth={width > 1200 ? 230 : 140} />
            }
          />
        </div>
      </Box>

      <Box>
        <KpiStateHistories kpiStateHistory={kpiStateHistory} />
      </Box>

      <Box>
        <KpiOutbound kpiOutbound={kpiOutbound} />
      </Box>
      <Box>
        <KpiSetup kpiSetup={kpiSetup} />
      </Box>
      <Box>
        <KpiCollector kpiCollector={kpiCollector} />
      </Box>
      <Box>
        <KpiTickets kpiTickets={kpiTickets} />
      </Box>
    </Container>
  );
}
