import React, { useContext, useEffect, useReducer, useState } from "react";
import { Grid, InputBase, Pagination, Tooltip } from "@mui/material";
import moment from "moment";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { RestaurantContext } from "../../contexts/restaurantContext";
import restaurantService from "../../services/restaurantService";
import { selectDefaultStyles } from "../../components/selectStyles/stylev3";
import NewDevelopmentRequest from "../../components/modals/developmentModal/newDevelopmentRequest";
import classes from "./index.module.css";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import emptyImg from "../../utils/assets/empty.png";
import ApprovementDetail from "./approvementDetail";
import { DevelopmentContext } from "../../contexts/developmentContext";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ProcessingDetail from "./processingDetail";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import DoneIcon from "@mui/icons-material/Done";
import { developmentService } from "../../services/developmentService";
import SearchIcon from "@mui/icons-material/Search";
import SearchWithIcon from "../../components/searchWithIcon";
import useDebounce from "../../components/debounce";
import { useToast } from "../../components/snackbar";
import { CheckCircle, FiberNew } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateGroup from "./processingDetail/group/CreateGroup";
import { GreenSwitch } from "../../components/switch/greenSwitch";

const animatedComponents = makeAnimated();

let options = [];

export default function Development() {
  const { alert } = useToast();
  const userDetail = JSON.parse(localStorage.getItem("st-officeuser-info"));
  const { getApplicationTransferList, backofficeList } =
    useContext(RestaurantContext);
  const {
    developmentRequestListFunc,
    developmentRequestList,
    getDevelopmentProcessList,
    developmentProcessList,
    taskDetail,
    getTaskStatuses,
    taskStatuses,
    setTaskDetail,
    getDevelopmentCompletedList,
    developmentCompletedList,
    getDevelopmentUserList,
    developmentUserList,
    getGroupTask,
    taskGroups,
    changeTaskGeneralStatus,
  } = useContext(DevelopmentContext);

  const [newDevelopmentRequest, setNewDevelopmentRequest] = useState(false);
  const [ownRequest, setOwnRequest] = useState(true);
  const [list, setList] = useState({
    name: null,
    id: null,
  });
  const [globalStatus, setGlobalStatus] = useState("all");
  const [openApprovementDetail, setOpenApprovementDetail] = useState(false);
  const [approveDetail, setApproveDetail] = useState(null);
  const [showApprovel, setShowApprovel] = useState(true);
  const [showCompleted, setShowCompleted] = useState(true);
  const [showProcessingList, setShowProcessingList] = useState(true);
  const [selectedProcessingTask, setSelectedProcessingTask] = useState(null);
  const [showProcessingDetail, setShowProcessingDetail] = useState(false);
  const [taskGeneralStatus, setTaskGeneralStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [showSearch, setShowSearch] = useState(false);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [selectedNo, setSelectedNo] = useState("");
  const [taskNoOptions, setTaskNoOptions] = useState([]);
  const debouncedNo = useDebounce(selectedNo, 1000);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [page, setPage] = useState(1);
  const [pageCompleted, setPageCompleted] = useState(1);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState("");

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeCompleted = (event, value) => {
    setPageCompleted(value);
  };

  useEffect(() => {
    getApplicationTransferList();
    getTaskStatuses("");
    getDevelopmentUserList();
    getGroupTask();

    (async () => {
      const res = await developmentService.get_task_statuses("task");
      setTaskGeneralStatus(res);
    })();
  }, []);

  useEffect(() => {
    if (
      developmentCompletedList?.results &&
      developmentProcessList?.results &&
      developmentRequestList &&
      taskNoOptions.length === 0
    ) {
      options.push({ id: "", name: "Tümü" });

      developmentRequestList?.map((item) => {
        options.push({ id: item.id, name: item.id });

        if (item.development_request) {
          options.push({
            id: item.development_request,
            name: item.development_request,
          });
        }
      });

      developmentProcessList?.results.map((item) => {
        options.push({ id: item.id, name: item.id });

        if (item.development_request) {
          options.push({
            id: item.development_request,
            name: item.development_request,
          });
        }
      });

      developmentCompletedList?.results.map((item) => {
        options.push({ id: item.id, name: item.id });

        if (item.development_request) {
          options.push({
            id: item.development_request,
            name: item.development_request,
          });
        }
      });

      setTaskNoOptions(options);
    }
  }, [
    developmentCompletedList,
    developmentProcessList,
    developmentRequestList,
  ]);

  useEffect(() => {
    getDevelopmentProcessList({
      request_type: ownRequest ? "all" : "own",
      res_id: list?.id === null || list?.id === undefined ? "" : list?.id,
      is_global:
        globalStatus === "all" ? "" : globalStatus === "global" ? true : false,
      search: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : "",
      taskGroup: selectedTaskGroup,
      id_search: debouncedNo ? debouncedNo : "",
      page,
    });
  }, [page, debouncedSearchTerm, ownRequest, list, globalStatus, debouncedNo]);

  useEffect(() => {
    getDevelopmentCompletedList({
      request_type: ownRequest ? "all" : "own",
      res_id: list?.id === null || list?.id === undefined ? "" : list?.id,
      is_global:
        globalStatus === "all" ? "" : globalStatus === "global" ? true : false,
      search: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : "",
      id_search: debouncedNo ? debouncedNo : "",
      page: pageCompleted,
    });
  }, [
    pageCompleted,
    debouncedSearchTerm,
    ownRequest,
    list,
    globalStatus,
    debouncedNo,
  ]);

  useEffect(() => {
    developmentRequestListFunc({
      request_type: ownRequest ? "all" : "own",
      res_id: list?.id === null || list?.id === undefined ? "" : list?.id,
      is_global:
        globalStatus === "all" ? "" : globalStatus === "global" ? true : false,
      search: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : "",
      id_search: debouncedNo ? debouncedNo : "",
    });
  }, [debouncedSearchTerm, ownRequest, list, globalStatus, debouncedNo]);

  const handleRestaurantChange = (searchQuery) => {
    return new Promise((resolve) => {
      restaurantService
        .restofficial_search({ search: searchQuery })
        .then((response) => {
          const value = response?.map((item) => ({
            value: item.user_id,
            label: `${item?.name || ""}`,
          }));
          resolve(value);
        });
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <div
            className={classes.newRequest}
            onClick={() => {
              setNewDevelopmentRequest(true);
            }}
          >
            <img
              src="/development-request.png"
              style={{ maxWidth: "20px", height: "auto", marginRight: 15 }}
            />
            <span>Yeni Talep</span>
          </div>

          <AsyncSelect
            responsiveHeight={35}
            selectFontSize={16}
            zIndex={999}
            hoverColor={"#f53b62"}
            menuColor={"#f53b62"}
            selectWidth={220}
            placeholder={"Restoran Seçimi"}
            styles={selectDefaultStyles}
            value={{
              label: (
                <span
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 15,
                    fontWeight: 600,
                  }}
                >
                  {list?.name || "Restoran Seçimi"}
                </span>
              ),
              value: list?.id || -1,
            }}
            components={animatedComponents}
            onChange={(e) => {
              setList(() => ({
                name: e?.label,
                id: e?.value,
              }));
            }}
            loadOptions={handleRestaurantChange}
            isClearable={list?.name != null ? true : false}
          />
        </div>

        <div>
          <InputBase
            style={{
              background: "#c9d1cc",
              color: "black",
              padding: 6,
              fontSize: 16,
              fontWeight: "400",
              height: 36,
              fontFamily: "segoeuiRg",
            }}
            placeholder="Talep / Task No Arama"
            value={selectedNo}
            onChange={(e) => {
              setSelectedNo(e.target.value);
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {showSearch ? (
            <div style={{ height: 38 }}>
              <SearchWithIcon
                setSearchTerm={setSearchTerm}
                placeTitle={"Ara"}
                searchTerm={searchTerm}
                customWidth={"100%"}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  height: "100%",
                  boxShadow: "5px 9px 8px 0px rgba(191,191,191, 0.7)",
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "0px 10px",
                  marginRight: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontFamily: "segoeuiRg", fontSize: 12 }}>
                      Tümü
                    </div>
                    <GreenSwitch
                      onClick={() => {
                        setOwnRequest(!ownRequest);
                      }}
                      checked={ownRequest}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontFamily: "segoeuiRg", fontSize: 12 }}>
                      Kendi
                    </div>
                    <GreenSwitch
                      onClick={() => {
                        setOwnRequest(!ownRequest);
                      }}
                      checked={!ownRequest}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  padding: "0px 10px",
                  height: "100%",
                  boxShadow: "5px 9px 8px 0px rgba(191,191,191, 0.7)",
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontFamily: "segoeuiRg", fontSize: 12 }}>
                      Tümü
                    </div>
                    <GreenSwitch
                      onClick={() => {
                        setGlobalStatus("all");
                      }}
                      checked={globalStatus === "all" ? true : false}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontFamily: "segoeuiRg", fontSize: 12 }}>
                      Global
                    </div>
                    <GreenSwitch
                      onClick={() => {
                        setGlobalStatus("global");
                      }}
                      checked={globalStatus === "global" ? true : false}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontFamily: "segoeuiRg", fontSize: 12 }}>
                      Restoran
                    </div>
                    <GreenSwitch
                      onClick={() => {
                        setGlobalStatus("rest");
                      }}
                      checked={globalStatus === "rest" ? true : false}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <SearchIcon
            style={{
              color: showSearch ? "#3ac100" : "gray",
              padding: 5,
              borderRadius: 9999,
              boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.7)",
              cursor: "pointer",
              marginLeft: 20,
            }}
            onClick={() => {
              if (showSearch) {
                setSearchTerm("");
                setSelectedNo(null);
              }
              setShowSearch(!showSearch);
            }}
          />
        </div>
      </div>

      <div style={{ margin: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={showApprovel ? 3 : 1}>
            <div
              style={{
                opacity: !showApprovel ? "0" : "1",
                transition: "all .2s",
                visibility: !showApprovel ? "hidden" : "visible",
                width: showApprovel ? "100%" : "0",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#ffb818",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",

                  position: "relative",
                }}
              >
                ONAY AŞAMASI
                <Tooltip title="Liste gizle">
                  <VisibilityOffIcon
                    style={{
                      color: "#fff",
                      position: "absolute",
                      right: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowApprovel(false);
                    }}
                  />
                </Tooltip>
              </div>

              <div
                style={{
                  height: "calc(100vh - 250px)",
                  overflow: "auto",
                  padding: "5px",
                  marginTop: 10,
                }}
              >
                {developmentRequestList?.map((row, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        padding: 10,
                        marginBottom: 15,
                        boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.7)",
                        borderRadius: 12,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (
                          userDetail?.development_task_authority ===
                          "confirm_process"
                        ) {
                          setOpenApprovementDetail(true);
                          setApproveDetail(row);
                        } else {
                          alert("Bu işlemi yapmaya yetkiniz yoktur.");
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              background: "#f53b62",
                              whiteSpace: "nowrap",
                              color: "white",
                              marginRight: 10,
                              boxShadow: "0px 0px 3px 1px #f53b62",
                            }}
                          >
                            Talep: {row.id}
                          </div>
                          <Tooltip title={row?.officeuser?.full_name}>
                            <img
                              src={row?.officeuser?.picture}
                              style={{
                                borderRadius: "9999px",
                                marginRight: 5,
                                width: 30,
                                height: 30,
                              }}
                            />
                          </Tooltip>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          {row?.restofficial && (
                            <>
                              <Tooltip title={row?.restofficial?.name}>
                                <RestaurantMenuIcon
                                  style={{ marginRight: 5, color: "black" }}
                                />
                              </Tooltip>
                              |
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#efefef",
                          borderRadius: 4,
                          padding: 10,
                          boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.4)",
                          marginTop: 5,
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "segoeuiB",
                            fontSize: 14,
                            textAlign: "center",
                            marginBottom: 5,
                          }}
                        >
                          {row?.header}
                        </div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          {row?.note}{" "}
                        </div>
                      </div>

                      <div
                        style={{
                          fontFamily: "segoeuiRg",
                          color: "gray",
                          fontSize: 13,
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 10,
                        }}
                      >
                        {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {!showApprovel && (
              <Tooltip title="Onay Aşaması Liste">
                <KeyboardDoubleArrowRightIcon
                  style={{
                    color: "#ffb818",
                    fontSize: 40,
                    cursor: "pointer",
                    top: "50%",
                    position: "absolute",
                    left: 30,
                  }}
                  onClick={() => {
                    setShowApprovel(true);
                  }}
                />
              </Tooltip>
            )}
          </Grid>

          <Grid
            item
            xs={
              showApprovel && showCompleted
                ? 6
                : showApprovel && !showCompleted
                ? 8
                : !showApprovel && showCompleted
                ? 8
                : 10
            }
          >
            <div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#6aa1ff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                }}
              >
                SÜREÇ
                <AddCircleOutlineIcon
                  style={{
                    color: "#fff",
                    cursor: "pointer",
                    position: "absolute",
                    right: 35,
                  }}
                  onClick={() => setCreateGroupModal(true)}
                />
                <Tooltip
                  title={showProcessingList ? "Liste gizle" : "Liste göster"}
                >
                  {!showProcessingList ? (
                    <VisibilityIcon
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        position: "absolute",
                        right: 5,
                      }}
                      onClick={() => {
                        setShowProcessingList(!showProcessingList);
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        position: "absolute",
                        right: 5,
                      }}
                      onClick={() => {
                        setShowProcessingList(!showProcessingList);
                      }}
                    />
                  )}
                </Tooltip>
              </div>

              {taskGroups?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    marginTop: 5,
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    width: "100%",
                    paddingBottom: 5,
                  }}
                >
                  {taskGroups?.map((group, index) => (
                    <div
                      key={index}
                      style={{
                        display: "inline-block",
                        background: group.color,
                        marginRight: 10,
                        borderRadius: 4,
                        color: "#fff",
                        padding: "2px 5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedTaskGroup(group.id);
                        getDevelopmentProcessList({
                          request_type: ownRequest ? "all" : "own",
                          res_id:
                            list?.id === null || list?.id === undefined
                              ? ""
                              : list?.id,
                          is_global:
                            globalStatus === "all"
                              ? ""
                              : globalStatus === "global"
                              ? true
                              : false,
                          search:
                            debouncedSearchTerm.length > 0
                              ? debouncedSearchTerm
                              : "",
                          taskGroup: group.id,
                          id_search: "",
                          page,
                        });
                      }}
                    >
                      {group.header}
                    </div>
                  ))}
                </div>
              )}

              <div
                style={{
                  height: "calc(100vh - 295px)",
                  overflow: "auto",
                  padding: "5px",
                  marginTop: 10,
                }}
              >
                {developmentProcessList?.results.map((row) => {
                  return (
                    <div
                      key={row.id}
                      style={{
                        padding: 10,
                        marginBottom: 15,
                        boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.7)",
                        borderRadius: 12,
                        cursor: "pointer",
                        background: row.color,
                        height:
                          selectedProcessingTask?.id === row.id
                            ? "auto"
                            : showProcessingList
                            ? "auto"
                            : "55px",
                        transition: "height all .2s",
                        overflow: "hidden",
                      }}
                      onClick={(e) => {
                        setSelectedProcessingTask(row);

                        if (showProcessingList) {
                          setShowProcessingDetail(true);
                        } else {
                          if (selectedProcessingTask?.id === row.id) {
                            setShowProcessingDetail(true);
                          }
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {showProcessingList && (
                            <div
                              style={{
                                background: "#f53b62",
                                color: "white",
                                marginRight: 10,
                                boxShadow: "0px 0px 3px 1px #f53b62",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Talep: {row.development_request}
                            </div>
                          )}

                          <div
                            style={{
                              background: "#f53b62",
                              color: "white",
                              marginRight: 10,
                              boxShadow: "0px 0px 3px 1px #f53b62",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Task: {row.id}
                          </div>

                          <div
                            style={{
                              background: "#f53b62",
                              width: 21,
                              height: 21,
                              color: "white",
                              boxShadow: "0px 0px 3px 1px #f53b62",
                              textAlign: "center",
                            }}
                          >
                            {row.priority}
                          </div>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{ width: "100px" }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Select
                              styles={selectDefaultStyles}
                              menuPosition="fixed"
                              menuPortalTarget={document.body}
                              responsiveHeight={35}
                              selectFontSize={16}
                              zIndex={999}
                              hoverColor={"#f53b62"}
                              menuColor={"#f53b62"}
                              selectWidth={100}
                              placeholder={"Grup"}
                              options={taskGroups}
                              arrowPadding={2}
                              isSearchable={false}
                              value={{
                                id: row.task_group?.id
                                  ? row.task_group?.id
                                  : "",
                                header: row.task_group?.name
                                  ? row.task_group?.name
                                  : "Tümü",
                              }}
                              getOptionValue={(option) => option.id}
                              onChange={(e) => {
                                row.task_group = {
                                  id: e.id,
                                  name: e.header,
                                };

                                const data = {
                                  task_group: e.id,
                                };
                                changeTaskGeneralStatus(row.id, data);

                                forceUpdate();
                              }}
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 13,
                                  }}
                                >
                                  {e.header}
                                </div>
                              )}
                            />
                          </div>

                          {row?.restofficial && (
                            <>
                              <Tooltip title={row?.restofficial?.name}>
                                <RestaurantMenuIcon
                                  style={{ marginRight: 5, color: "black" }}
                                />
                              </Tooltip>
                              |
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          fontWeight: "bold",
                          color: "#fff",
                          padding: "3px 5px",
                          borderRadius: "8px",
                          textAlign: "center",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "100%",
                        }}
                      >
                        {row.title}
                      </div>

                      <div
                        style={{
                          backgroundColor: "#efefef",
                          borderRadius: 4,
                          padding: 10,
                          boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.4)",
                          fontFamily: "segoeuiRg",
                          fontSize: 14,
                          marginTop: 5,
                        }}
                      >
                        {row?.note}{" "}
                      </div>

                      <Grid container spacing={2} style={{ marginTop: 15 }}>
                        {row.users.map((user, i) => {
                          return (
                            <Grid item xs={2} key={i}>
                              <div
                                style={{
                                  background: "#fff",
                                  borderRadius: 8,
                                  boxShadow:
                                    "1px 2px 2px 2px rgba(191,191,191,0.4)",
                                  padding: 10,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Tooltip title={user.officeuser.full_name}>
                                  <img
                                    src={
                                      user.officeuser.picture
                                        ? user.officeuser.picture
                                        : emptyImg
                                    }
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: 9999,
                                    }}
                                  />
                                </Tooltip>

                                <div
                                  style={{
                                    fontFamily: "segoeuiRg",
                                    fontSize: 12,
                                    textAlign: "center",
                                    marginLeft: 5,
                                  }}
                                >
                                  {user.status === "new" ? (
                                    <Tooltip title="Yeni Task">
                                      <FiberNew
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : user.status === "seen" ? (
                                    <Tooltip title="Görüldü">
                                      <VisibilityIcon
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : user.status === "processing" ? (
                                    <Tooltip
                                      title={
                                        user.task_duration +
                                        " " +
                                        user.task_duration_type
                                      }
                                    >
                                      <TimelapseIcon
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : user.status === "completed" ? (
                                    <Tooltip title="Tamamlandı">
                                      <CheckCircle
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>

                      <div
                        style={{
                          fontFamily: "segoeuiRg",
                          color: "gray",
                          fontSize: 13,
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 10,
                        }}
                      >
                        {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Pagination
                  count={
                    developmentProcessList?.count % 30 === 0
                      ? developmentProcessList?.count / 30
                      : Math.floor(developmentProcessList?.count / 30) + 1
                  }
                  page={page}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={showCompleted ? 3 : 1}>
            <div
              style={{
                opacity: !showCompleted ? "0" : "1",
                transition: "all .2s",
                visibility: !showCompleted ? "hidden" : "visible",
                width: showCompleted ? "100%" : "0",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#3ac100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                }}
              >
                TAMAMLANAN
                <Tooltip title="Liste gizle">
                  <VisibilityOffIcon
                    style={{
                      color: "#fff",
                      position: "absolute",
                      right: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowCompleted(false);
                    }}
                  />
                </Tooltip>
              </div>

              <div
                style={{
                  height: "calc(100vh - 260px)",
                  overflow: "auto",
                  padding: "5px",
                  marginTop: 10,
                }}
              >
                {developmentCompletedList?.results.map((row) => {
                  return (
                    <div
                      key={row.id}
                      style={{
                        padding: 10,
                        marginBottom: 15,
                        boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.7)",
                        borderRadius: 12,
                        cursor: "pointer",
                        background: row.color,
                        height:
                          selectedProcessingTask?.id === row.id
                            ? "auto"
                            : showCompleted
                            ? "auto"
                            : "25px",
                        transition: "height all .2s",
                        overflow: "hidden",
                      }}
                      onClick={() => {
                        setSelectedProcessingTask(
                          selectedProcessingTask?.id === row.id ? null : row
                        );

                        if (showCompleted) {
                          setShowProcessingDetail(true);
                        } else {
                          if (selectedProcessingTask?.id === row.id) {
                            setShowProcessingDetail(true);
                          }
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              background: "#f53b62",
                              color: "white",
                              marginRight: 10,
                              boxShadow: "0px 0px 3px 1px #f53b62",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Talep: {row.development_request}
                          </div>

                          <div
                            style={{
                              background: "#f53b62",
                              color: "white",
                              marginRight: 10,
                              boxShadow: "0px 0px 3px 1px #f53b62",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Task: {row.id}
                          </div>

                          {row.task_group && (
                            <div
                              style={{
                                background: "#f53b62",
                                color: "white",
                                marginRight: 10,
                                boxShadow: "0px 0px 3px 1px #f53b62",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.task_group?.name}
                            </div>
                          )}
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          {row?.restofficial && (
                            <>
                              <Tooltip title={row?.restofficial?.name}>
                                <RestaurantMenuIcon
                                  style={{ marginRight: 5, color: "black" }}
                                />
                              </Tooltip>
                              |
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          fontWeight: "bold",
                          color: "#fff",
                          padding: "3px 5px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {row.title}
                      </div>

                      <div
                        style={{
                          backgroundColor: "#efefef",
                          borderRadius: 4,
                          padding: 10,
                          boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.4)",
                          fontFamily: "segoeuiRg",
                          fontSize: 14,
                          marginTop: 5,
                        }}
                      >
                        {row?.note}{" "}
                      </div>

                      <Grid container spacing={2} style={{ marginTop: 15 }}>
                        {row.users.map((user, i) => {
                          return (
                            <Grid item xs={4} key={i}>
                              <div
                                style={{
                                  background: "#fff",
                                  borderRadius: 8,
                                  boxShadow:
                                    "1px 2px 2px 2px rgba(191,191,191,0.4)",
                                  padding: 10,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Tooltip title={user.officeuser.full_name}>
                                  <img
                                    src={
                                      user.officeuser.picture
                                        ? user.officeuser.picture
                                        : emptyImg
                                    }
                                    style={{
                                      width: 35,
                                      height: 35,
                                      borderRadius: 9999,
                                    }}
                                  />
                                </Tooltip>

                                <div
                                  style={{
                                    fontFamily: "segoeuiRg",
                                    fontSize: 12,
                                    textAlign: "center",
                                    marginLeft: 5,
                                  }}
                                >
                                  {user.status === "new" ? (
                                    <Tooltip title="Yeni Task">
                                      <FiberNew
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : user.status === "seen" ? (
                                    <Tooltip title="Görüldü">
                                      <VisibilityIcon
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : user.status === "processing" ? (
                                    <Tooltip
                                      title={
                                        user.task_duration +
                                        " " +
                                        user.task_duration_type
                                      }
                                    >
                                      <TimelapseIcon
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : user.status === "completed" ? (
                                    <Tooltip title="Tamamlandı">
                                      <DoneIcon
                                        style={{
                                          color: "#000",
                                          width: 32,
                                          height: 32,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>

                      <div
                        style={{
                          fontFamily: "segoeuiRg",
                          color: "gray",
                          fontSize: 13,
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 10,
                        }}
                      >
                        {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Pagination
                  count={
                    developmentCompletedList?.count % 30 === 0
                      ? developmentCompletedList?.count / 30
                      : Math.floor(developmentCompletedList?.count / 30) + 1
                  }
                  page={pageCompleted}
                  onChange={handleChangeCompleted}
                />
              </div>
            </div>

            {!showCompleted && (
              <Tooltip title="Tamamlanan Liste">
                <KeyboardDoubleArrowLeftIcon
                  style={{
                    color: "#3ac100",
                    fontSize: 40,
                    cursor: "pointer",
                    top: "50%",
                    position: "absolute",
                    right: 30,
                  }}
                  onClick={() => {
                    setShowCompleted(true);
                  }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </div>

      {newDevelopmentRequest && (
        <NewDevelopmentRequest
          newDevelopmentRequest={newDevelopmentRequest}
          setNewDevelopmentRequest={setNewDevelopmentRequest}
          request_type={ownRequest ? "all" : "own"}
          res_id={list?.id === null || list?.id === undefined ? "" : list?.id}
          is_global={
            globalStatus === "all"
              ? ""
              : globalStatus === "global"
              ? true
              : false
          }
        />
      )}

      {openApprovementDetail && (
        <ApprovementDetail
          openApprovementDetail={openApprovementDetail}
          setOpenApprovementDetail={setOpenApprovementDetail}
          approveDetail={approveDetail}
          developmentUserList={developmentUserList}
          setApproveDetail={setApproveDetail}
          taskGroups={taskGroups}
        />
      )}

      {showProcessingDetail && (
        <ProcessingDetail
          showProcessingDetail={showProcessingDetail}
          setShowProcessingDetail={setShowProcessingDetail}
          taskDetail={taskDetail}
          taskStatuses={taskStatuses}
          developmentUserList={developmentUserList}
          taskGeneralStatus={taskGeneralStatus}
          setTaskDetail={setTaskDetail}
          selectedProcessingTask={selectedProcessingTask}
          setSelectedProcessingTask={setSelectedProcessingTask}
          ownRequest={ownRequest}
        />
      )}

      <CreateGroup
        createGroupModal={createGroupModal}
        setCreateGroupModal={setCreateGroupModal}
      />
    </div>
  );
}
