import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, FormControlLabel, InputBase, Modal, Radio } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 30%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const TextChoicesModal = ({
  textChoicesModal,
  setTextChoicesModal,
  textChoicesModalInfo,
  createNewRatingSetting,
  setCreateNewRatingSetting,
}) => {
  const classes = useStyles();
  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={textChoicesModal}
      className={classes.modal}
      componentsProps={{
        backdrop: { style: { top: 60 } },
        root: { style: { top: 60 } },
      }}
      onClose={() => {
        setTextChoicesModal(false);
      }}
    >
      <Card
        style={{
          width: "30%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <AddCircleOutline
            onClick={() => {
              setCreateNewRatingSetting((prev) => ({
                ...prev,
                features: prev.features.map((feature) => ({
                  ...feature,
                  text_choices: Array.isArray(feature.text_choices)
                    ? [...feature.text_choices, { choice: "", sentiment: "" }]
                    : [{ choice: "", sentiment: "" }],
                })),
              }));
            }}
          />
        </div>
        {createNewRatingSetting?.features[
          textChoicesModalInfo?.currentInd
        ]?.text_choices?.map((row1, ind2) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
                spellCheck={false}
                style={{
                  width: "60%",
                  fontFamily: "segoeuiRg",
                  fontSize: 12,
                  backgroundColor: "#f4f4f4",
                  borderRadius: 4,
                  height: 30,
                }}
                rows={1}
                placeholder={"Not ekle"}
                className={classes.input}
                value={row1?.choice}
                onChange={(e) => {
                  const createNewRatingSettingObj = Object.assign(
                    {},
                    JSON.parse(
                      JSON.stringify({
                        ...createNewRatingSetting,
                      })
                    )
                  );

                  createNewRatingSettingObj.features[
                    textChoicesModalInfo?.currentInd
                  ].text_choices[ind2].choice = e.target.value;
                  setCreateNewRatingSetting(createNewRatingSettingObj);
                }}
              />
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormControlLabel
                  value={
                    createNewRatingSetting?.features[
                      textChoicesModalInfo?.currentInd
                    ]?.text_choices[ind2]?.sentiment === "positive"
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      checked={
                        createNewRatingSetting?.features[
                          textChoicesModalInfo?.currentInd
                        ]?.text_choices[ind2]?.sentiment === "positive"
                          ? true
                          : false
                      }
                      size="small"
                      sx={{
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#6f1111",
                        },
                      }}
                      onClick={() => {
                        const createNewRatingSettingObj = Object.assign(
                          {},
                          JSON.parse(
                            JSON.stringify({
                              ...createNewRatingSetting,
                            })
                          )
                        );
                        if (
                          createNewRatingSettingObj.features[
                            textChoicesModalInfo?.currentInd
                          ].text_choices[ind2].sentiment === "positive"
                        ) {
                          createNewRatingSettingObj.features[
                            textChoicesModalInfo?.currentInd
                          ].text_choices[ind2].sentiment = "";
                        } else {
                          createNewRatingSettingObj.features[
                            textChoicesModalInfo?.currentInd
                          ].text_choices[ind2].sentiment = "positive";
                        }
                        setCreateNewRatingSetting(createNewRatingSettingObj);
                      }}
                    />
                  }
                  label="Positif"
                  labelPlacement="start"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 12,
                    },
                  }}
                />
                <FormControlLabel
                  value={
                    createNewRatingSetting?.features[
                      textChoicesModalInfo?.currentInd
                    ]?.text_choices[ind2]?.sentiment === "negative"
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      checked={
                        createNewRatingSetting?.features[
                          textChoicesModalInfo?.currentInd
                        ]?.text_choices[ind2]?.sentiment === "negative"
                          ? true
                          : false
                      }
                      size="small"
                      sx={{
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#6f1111",
                        },
                      }}
                      onClick={() => {
                        const createNewRatingSettingObj = Object.assign(
                          {},
                          JSON.parse(
                            JSON.stringify({
                              ...createNewRatingSetting,
                            })
                          )
                        );
                        if (
                          createNewRatingSettingObj.features[
                            textChoicesModalInfo?.currentInd
                          ].text_choices[ind2].sentiment === "negative"
                        ) {
                          createNewRatingSettingObj.features[
                            textChoicesModalInfo?.currentInd
                          ].text_choices[ind2].sentiment = "";
                        } else {
                          createNewRatingSettingObj.features[
                            textChoicesModalInfo?.currentInd
                          ].text_choices[ind2].sentiment = "negative";
                        }
                        setCreateNewRatingSetting(createNewRatingSettingObj);
                      }}
                    />
                  }
                  label="Negatif"
                  labelPlacement="start"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 12,
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
      </Card>
    </Modal>
  );
};

export default TextChoicesModal;
