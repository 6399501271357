import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import InfoIcon from "@mui/icons-material/Info";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { ManagementContext } from "../../../contexts/managementContext";

import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import useWindowDimensions from "../../../components/windowSize";
import FormatNumber from "../../../components/formatData/formatPhone";
import CustomDatePickerInput from "../../../components/datePickerInput";
import WorksNotesModal from "../../../components/modals/worksNotes";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
  cart: {
    "&.active": {
      backgroundColor: "#f53f62",
    },
  },
});

let SETUP_STATUS = [
  {
    id: "",
    name: "Tümü",
  },
  {
    id: "basarili",
    name: "Başarılı",
  },
  {
    id: "basarisiz",
    name: "Başarısız",
  },
  {
    id: "bekliyor",
    name: "Bekliyor",
  },
];

let dateStatus = [
  {
    id: "date",
    name: "Tarih",
  },
  {
    id: "setupDate",
    name: "Kurulum Tarihi",
  },
  {
    id: "together",
    name: "İkisi Birlikte",
  },
];

const ManagerWorks = () => {
  const classes = useStyles();
  const [workerSelect, setWorkerSelect] = useState(null);
  const [typeSelect, setTypeSelect] = useState(null);
  const [typeList, setTypeList] = useState([]);
  const { width } = useWindowDimensions();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [reports, setReports] = useState([]);
  const [status, setStatus] = useState(null);
  const [selectedSellerUser, setSelectedSellerUser] = useState(null);
  const [selectedOutboundUser, setSelectedOutboundUser] = useState(null);
  const [selectedSetupStatus, setSelectedSetupStatus] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [setupDateRange, setSetupDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [setupStartDate, setupEndDate] = setupDateRange;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [selectedDateType, setSelectedDateType] = useState("date");

  const { getApplicationTransferList, backofficeList } =
    useContext(RestaurantContext);

  const {
    getManagerWorks,
    managerWorksList,
    getApplicationTypes,
    applicationTypes,
    sellerUserListFunc,
    sellerUserList,
  } = useContext(ManagementContext);

  useEffect(() => {
    backofficeList.unshift({
      user_id: "",
      full_name: "Tümü",
    });
  }, [backofficeList]);

  useEffect(() => {
    if (sellerUserList) {
      sellerUserList.unshift({
        user_id: "",
        full_name: "Tümü",
      });
    }
  }, [sellerUserList]);

  useEffect(() => {
    applicationTypes.unshift("Tümü");
    const data = applicationTypes.map((type, i) => {
      return { name: type, id: i };
    });
    setTypeList(data);
  }, [applicationTypes]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeWorker = (e) => {
    setPage(0);
    setWorkerSelect(e);
  };

  const handleChangeType = (e) => {
    setPage(0);
    setTypeSelect(e);
  };

  useEffect(() => {
    if (endDate) {
      let selectedTypes;
      if (typeSelect) {
        selectedTypes = typeSelect.map((item) => item.name).join(",");

        if (selectedTypes.includes("Tümü")) {
          setTypeSelect(null);
          selectedTypes = "";
        } else {
          selectedTypes = typeSelect.map((item) => item.name).join(",");
        }
      } else {
        selectedTypes = "";
      }

      let data = {
        page: page + 1,
        user_id: workerSelect === null ? "" : workerSelect.user_id,
        status:
          status === null ? "" : status === "total" ? "" : status.split("_")[1],
        type: selectedTypes,
        seller: selectedSellerUser ? selectedSellerUser?.user_id : "",
        from_by: selectedOutboundUser ? selectedOutboundUser?.user_id : "",
        setup_status: selectedSetupStatus ? selectedSetupStatus?.id : "",
        selectedDateType,
      };
      if (selectedDateType === "setupDate") {
        data = {
          ...data,
          setupStartDate: moment(setupStartDate).format("YYYY-MM-DD"),
          setupEndDate: moment(setupEndDate).format("YYYY-MM-DD"),
        };
      } else if (selectedDateType === "date") {
        data = {
          ...data,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
        };
      } else {
        data = {
          ...data,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          setupStartDate: moment(setupStartDate).format("YYYY-MM-DD"),
          setupEndDate: moment(setupEndDate).format("YYYY-MM-DD"),
        };
      }
      getManagerWorks(data);
    }
  }, [
    page,
    workerSelect,
    status,
    typeSelect,
    selectedSellerUser,
    endDate,
    selectedOutboundUser,
    selectedSetupStatus,
    setupEndDate,
    selectedDateType,
  ]);

  const handleSelectStatus = (report) => {
    if (status === report.name) {
      setStatus("");
    } else {
      setStatus(report.name);
    }
    setPage(0);
  };

  useEffect(() => {
    if (managerWorksList) {
      const reportsArray = Object.entries(managerWorksList.reports).map(
        ([key, value]) => ({ [key]: value, name: key, value: value })
      );
      setReports(reportsArray);
    }
  }, [managerWorksList]);

  useEffect(() => {
    getApplicationTypes();
    getApplicationTransferList();
    sellerUserListFunc();
  }, []);

  const handleShowNotes = (report) => {
    setShowNotesModal(true);
    setSelectedNotes(report.notes);
  };

  return (
    <Container style={{ width: "98%", maxWidth: "98%" }}>
      <Box>
        <h3
          style={{
            fontFamily: "segoeuiB",
            fontSize: 24,
            textAlign: "center",
          }}
        >
          FİNANS / YÖNETİCİ İŞLERİM
        </h3>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ marginRight: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Kişiler
            </span>
            <Select
              options={backofficeList}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={true}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={workerSelect}
              getOptionValue={(option) => option.user_id}
              onChange={(e) => {
                handleChangeWorker(e);
              }}
              getOptionLabel={(option) => option.full_name}
            />
          </div>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Başvuru Tipi
            </span>
            <Select
              options={typeList}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              isMulti
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={typeSelect}
              getOptionValue={(option) => option.name}
              onChange={(e) => {
                handleChangeType(e);
              }}
              getOptionLabel={(option) => option.name}
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Lead Gönderen Bayi
            </span>
            <Select
              options={sellerUserList}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={true}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={selectedSellerUser}
              getOptionValue={(option) => option.user_id}
              onChange={(e) => {
                setSelectedSellerUser(e);
                setPage(0);
              }}
              getOptionLabel={(option) => option.full_name}
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Lead Gönderen Outbound
            </span>
            <Select
              options={backofficeList}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={true}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={selectedOutboundUser}
              getOptionValue={(option) => option.user_id}
              onChange={(e) => {
                setSelectedOutboundUser(e);
                setPage(0);
              }}
              getOptionLabel={(option) => option.full_name}
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Kurulum Durumu
            </span>
            <Select
              options={SETUP_STATUS}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={selectedSetupStatus}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSelectedSetupStatus(e);
                setPage(0);
              }}
              getOptionLabel={(option) => option.name}
            />
          </div>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: 2 }}>
          <div style={{ background: "#fff", padding: 5, borderRadius: 4 }}>
            <FormGroup>
              {dateStatus.map((item, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        value={item.id}
                        checked={item.id === selectedDateType ? true : false}
                      />
                    }
                    label={item.name}
                    onChange={(e) => {
                      setSelectedDateType(e.target.value);
                    }}
                  />
                );
              })}
            </FormGroup>
          </div>

          {(selectedDateType === "date" || selectedDateType === "together") && (
            <div style={{ marginLeft: 10 }}>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Tarih
              </span>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                onChange={(update) => {
                  setDateRange(update);
                }}
                customInput={
                  <CustomDatePickerInput
                    selectWidth={width > 1200 ? 230 : 140}
                  />
                }
                dateFormat="dd/MM/yyyy"
                locale="tr"
              />
            </div>
          )}

          {(selectedDateType === "setupDate" ||
            selectedDateType === "together") && (
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Kurulum Tarih
              </span>
              <DatePicker
                selectsRange={true}
                startDate={setupStartDate}
                endDate={setupEndDate}
                maxDate={new Date()}
                onChange={(update) => {
                  setSetupDateRange(update);
                }}
                customInput={
                  <CustomDatePickerInput
                    selectWidth={width > 1200 ? 230 : 140}
                  />
                }
                dateFormat="dd/MM/yyyy"
                locale="tr"
              />
            </div>
          )}
        </Box>
      </Box>
      <Grid
        container
        columns={12}
        spacing={2}
        sx={{ marginTop: 2, marginBottom: 4 }}
      >
        {reports.map((report, i) => {
          return (
            <Grid item xs={3} key={i}>
              <Paper
                elevation={3}
                sx={{ padding: 2, cursor: "pointer" }}
                className={`${classes.cart} ${
                  status === report.name ? "active" : ""
                }`}
                onClick={() => handleSelectStatus(report)}
              >
                <h3
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    fontFamily: "segoeuiL",
                    color: status === report.name ? "#fff" : "darkslategray",
                    fontSize: 18,
                  }}
                >
                  {report.name === "total" && "TOPLAM"}
                  {report.name === "total_deleted" && "SİLİNEN"}
                  {report.name === "total_stayed" && "ZAMANLAYICI"}
                  {report.name === "total_processing" && "İŞLEMDE"}
                </h3>
                <span
                  style={{
                    display: "block",
                    textAlign: "right",
                    fontSize: 16,
                    marginLeft: 5,
                    fontFamily: "segoeuiL",
                    color: status === report.name ? "#fff" : "darkslategray",
                  }}
                >
                  {report.value}
                </span>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Çalışan</TableCell>
              <TableCell align="center">Başvuru Tipi</TableCell>
              <TableCell align="center">Başvuru tarihi</TableCell>
              <TableCell align="center">Lead Gönderilen Tarih</TableCell>
              <TableCell align="center">Gönderen</TableCell>
              <TableCell align="center">Restoran</TableCell>
              <TableCell align="center">Cep</TableCell>
              <TableCell align="center">Sabit Tel</TableCell>
              <TableCell align="center">Outbound</TableCell>
              <TableCell align="center">Yönlendiren</TableCell>
              <TableCell align="center">Kurulum Durumu</TableCell>
              <TableCell align="center">Kurulum Tarihi</TableCell>
              <TableCell align="center" style={{ minWidth: 100 }}>
                Tahsilat Yapan Kişi
              </TableCell>
              <TableCell align="center">Tahsilat Tutarı</TableCell>
              <TableCell align="center">Tahsilat Tarihi</TableCell>
              <TableCell align="center">Notlar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managerWorksList?.results?.map((res, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    {res?.processed_by?.name}
                  </TableCell>
                  <TableCell align="center">{res?.application.type}</TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    {moment(res?.application.created_at).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {res?.from_outbound_date
                      ? moment(res?.from_outbound_date).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {res?.application?.full_name}
                  </TableCell>
                  <TableCell align="center">
                    {res?.application?.restaurant_name}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    {FormatNumber(res?.application?.phone, "")}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    {FormatNumber(res?.application?.landline_phone, "")}
                  </TableCell>
                  <TableCell align="center">
                    {res?.from_outbound ? res?.from_outbound?.name : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {res?.from_by ? res?.from_by?.name : "-"}
                  </TableCell>
                  <TableCell align="center">{res?.setup_status}</TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    {res?.application?.setup_at
                      ? moment(res?.application?.setup_at).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {res?.tahsilat?.user ? res?.tahsilat?.user?.name : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {res?.tahsilat?.amount === 0 ? "-" : res?.tahsilat?.amount}
                  </TableCell>
                  <TableCell align="center">
                    {res?.tahsilat?.payed_at
                      ? moment(res?.tahsilat?.payed_at).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {res?.notes.length > 0 && (
                      <InfoIcon
                        style={{ cursor: "pointer", color: "#f53b62" }}
                        onClick={() => handleShowNotes(res)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {managerWorksList?.results && (
                <TablePagination
                  rowsPerPageOptions={[5]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={managerWorksList?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <WorksNotesModal
        showNotesModal={showNotesModal}
        setShowNotesModal={setShowNotesModal}
        selectedNotes={selectedNotes}
      />
    </Container>
  );
};

export default ManagerWorks;
