import { Delete, DescriptionTwoTone } from "@mui/icons-material";
import { Paper } from "@mui/material";
import React, { useState } from "react";
import { CustomerInput } from "../../../../../components/CustomerInput";
import SecretKeyUpdateModal from "../../../../../components/modals/secretKeyUpdate";

const Ysv2 = ({
  secretKeyPlatformInfo,
  scrollRefs,
  deleteRestofficialPlatforms,
  id,
}) => {
  const [secretKeyUpdateModal, setSecretKeyUpdateModal] = useState(false);
  const [currentSecretKeyRow, setCurrentSecretKeyRow] = useState(null);
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {secretKeyPlatformInfo?.map((row, index) => {
          if (!row?.yemeksepetiv2) return;
          return (
            <Paper
              key={index}
              ref={scrollRefs.current[index]}
              elevation={2}
              style={{
                width: "50%",
                height: "auto",
                padding: 10,
                marginTop: 20,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "#293c51",
                    fontFamily: "segoeuiRg",
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: 5,
                  }}
                >
                  {row?.restaurant_name}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DescriptionTwoTone
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCurrentSecretKeyRow(row);
                      setSecretKeyUpdateModal(true);
                    }}
                  />
                </div>
                <Delete
                  variant="Filled"
                  size="large"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    deleteRestofficialPlatforms(id, row.id, row?.platform?.id)
                  }
                />
              </div>
              <div style={{ marginTop: 5 }}>
                <CustomerInput
                  fieldHeader="Restoran İsmi"
                  fieldName="restaurant_name"
                  formData={""}
                  setFormData={""}
                  fieldValue={row?.restaurant_name}
                  disabledStatus={true}
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <CustomerInput
                  fieldHeader="Yemeksepeti Vendor Id"
                  fieldName="yemeksepeti_restaurant_id"
                  formData={""}
                  setFormData={""}
                  fieldValue={row?.yemeksepeti_restaurant_id}
                  disabledStatus={true}
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <CustomerInput
                  fieldHeader="Remote Id"
                  fieldName="key"
                  formData={""}
                  setFormData={""}
                  fieldValue={row.key}
                  disabledStatus={true}
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <CustomerInput
                  fieldHeader="Chain Id"
                  fieldName="platform_user_id"
                  formData={""}
                  setFormData={""}
                  fieldValue={row.platform_user_id}
                  disabledStatus={true}
                />
              </div>
            </Paper>
          );
        })}
      </div>
      <SecretKeyUpdateModal
        secretKeyUpdateModal={secretKeyUpdateModal}
        setSecretKeyUpdateModal={setSecretKeyUpdateModal}
        currentSecretKeyRow={currentSecretKeyRow}
        setCurrentSecretKeyRow={setCurrentSecretKeyRow}
      />
    </>
  );
};

export default Ysv2;
