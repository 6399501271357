import { Card, Modal, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Company from "../../../pages/restaurants/offers/components/company";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    height: "60%",
  },
}));

const PayedModal = ({
  payedModal,
  setPayedModal,
  r_id,
  showPayment,
  taskPage,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={payedModal || false}
      className={classes.modal}
      onClose={() => {
        setPayedModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: isMobile ? "80%" : "60%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Company
          showPayment={showPayment}
          payedModal={payedModal}
          setPayedModal={setPayedModal}
          r_id={r_id}
          taskPage={taskPage}
        />
      </Card>
    </Modal>
  );
};

export default PayedModal;
