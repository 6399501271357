import React from "react";

import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const ProductModal = ({
  productModal,
  setProductModal,
  currentRow,
  productStatusChange,
  productModalType,
  selectedPlatformRes,
}) => {
  const classes = useStyles();

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={productModal}
      className={classes.modal}
      onClose={() => {
        setProductModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "35%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          height: 80,
          backgroundColor: "#fff",
          padding: 25,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "50%",
            alignItems: "flex-start",
            color: "black",
            fontFamily: "segoeuiRg",
            marginBottom: 20,
          }}
        >
          Ürün kapama süresi seçiniz:
        </div>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            display: "flex",
            height: "100%",
          }}
        >
          <Button
            onClick={() => {
              if (productModalType == "normal") {
                let data;

                data = {
                  is_active: false,
                  products: [currentRow?.id],
                  secret_key: selectedPlatformRes?.id,
                };

                productStatusChange(data, currentRow);
              } else {
                let newArr = [];
                currentRow?.products?.map((row1) => {
                  newArr.push(row1?.id);
                });
                productStatusChange(
                  {
                    is_active: false,
                    products: newArr,
                    secret_key: selectedPlatformRes?.id,
                  },
                  currentRow
                );
              }
              setProductModal(false);
            }}
            style={{
              color: "white",
              backgroundColor: "#f53b62",
              padding: "5px 10px 5px 10px",
              margin: 10,
              textTransform: "capitalize",
              fontFamily: "segoeuiRg",
            }}
          >
            Süresiz Kapat
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#f53b62",
              padding: "5px 10px 5px 10px",
              margin: 10,
              textTransform: "capitalize",
              fontFamily: "segoeuiRg",
            }}
            onClick={() => {
              if (productModalType == "normal") {
                let data;

                data = {
                  is_active: false,
                  temp_inactive: true,
                  products: [currentRow?.id],
                  secret_key: selectedPlatformRes?.id,
                };

                productStatusChange(data, currentRow);
              } else {
                let newArr = [];
                currentRow?.products?.map((row1) => {
                  newArr.push(row1?.id);
                });
                productStatusChange(
                  {
                    products: newArr,
                    is_active: false,
                    temp_inactive: true,
                    secret_key: selectedPlatformRes?.id,
                  },
                  currentRow
                );
              }
              setProductModal(false);
            }}
          >
            Günlük Kapat
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default ProductModal;
