import { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import InputField from "../../../../../../components/input";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function SinglePrice({
  item,
  index,
  handleDeleteOption,
  productData,
}) {
  const [optionalPriceData, setOptionalPriceData] = useState({
    min: "",
    max: "",
    price: "",
  });

  useEffect(() => {
    const optional_price_value = productData.optional_price_value;
    optional_price_value[index] = optionalPriceData;
  }, [optionalPriceData, index, productData.optional_price_value]);

  useEffect(() => {
    setOptionalPriceData(item);
  }, [item]);

  return (
    <TableRow>
      <TableCell align="center">
        <InputField
          fieldName="min"
          formData={optionalPriceData}
          setFormData={setOptionalPriceData}
          fieldValue={optionalPriceData?.min}
        />
      </TableCell>
      <TableCell align="center">
        <InputField
          fieldName="max"
          formData={optionalPriceData}
          setFormData={setOptionalPriceData}
          fieldValue={optionalPriceData?.max}
        />
      </TableCell>
      <TableCell align="center">
        <InputField
          fieldName="price"
          formData={optionalPriceData}
          setFormData={setOptionalPriceData}
          fieldValue={optionalPriceData?.price}
        />
      </TableCell>
      <TableCell
        align="center"
        style={{ cursor: "pointer" }}
        onClick={() => handleDeleteOption(index)}
      >
        <DeleteForeverIcon style={{ color: "red" }} />
      </TableCell>
    </TableRow>
  );
}
