import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Modal } from "@mui/material";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import Colors from "../../../utils/colors";
import { GreenSwitch } from "../../../components/switch/greenSwitch";

export const Printer = ({ currentIndex }) => {
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [open, setOpen] = useState(false);
  let { id } = useParams();
  const {
    restaurantPrinter,
    getRestaurantPrinterData,
    patchRestaurantPrinterData,
    deleteRestaurantPrinterData,
  } = useContext(RestaurantContext);

  useEffect(() => {
    getRestaurantPrinterData(id);
  }, []);

  const handleOpen = (print) => {
    setOpen(true);
    setSelectedPrinter(print);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closePrinter = () => {
    let newData = { ...selectedPrinter };
    newData.selected = !selectedPrinter.selected;
    deleteRestaurantPrinterData(id, newData);
    handleClose();
  };
  return (
    <Box
      component="main"
      sx={{
        width: "90%",
        backgroundColor: "#f4f4f4",

        height: "100%",
        display: "flex",
        marginTop: 10,
        marginLeft: 5,
        justifyContent: "space-around",
        alignItems: "center",
        overflow: "auto",
        flexDirection: "row",
      }}
    >
      {restaurantPrinter?.length > 0 ? (
        restaurantPrinter?.map((print, idx) => {
          return (
            <div
              key={idx}
              style={{
                height: "240px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "320px",
                flexDirection: "column",
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: "12px 12px",
                marginTop: 20,
                marginLeft: 12,
              }}
            >
              <h4>Yazıcı</h4>
              <div
                style={{
                  display: "flex",
                  width: "90%",
                  height: 50,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#f1f1f1",
                  padding: "1px 10px",
                  borderRadius: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {print?.name}
                </div>
                <GreenSwitch
                  onClick={() => {
                    handleOpen(print);
                  }}
                  checked={print?.selected}
                />
              </div>

              <h4>Kopya sayısı</h4>
              <div
                style={{
                  display: "flex",
                  width: "300px",
                  height: "30px",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7].map((count, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        width: "24px",
                        height: "26px",
                        flexDirection: "row",
                        backgroundColor:
                          print?.copy_count === count
                            ? Colors.mainColor
                            : "#f1f1f1",
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "center",
                        borderRadius: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        let newData = { ...print };
                        newData.copy_count = count;
                        patchRestaurantPrinterData(id, newData);
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: print?.copy_count === count ? "#fff" : "#000",
                          alignSelf: "center",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {count}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <h2>Henüz cihazınızda yazıcı tespit edilemedi</h2>
      )}

      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: "4px",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            width: "18%",
          }}
        >
          <h2>Bilgilendirme</h2>
          <p>Bu işlem yazıcıyı kaldıracaktır. Yazıcı kaldırılsın mı ?</p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose}>Hayır</Button>
            <Button onClick={() => closePrinter()}>Evet</Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};
