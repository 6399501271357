import React from "react";
import { Button, Card, CardActions, CardContent, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentNoteModal = ({
  setContentNoteModal,
  contentNoteModal,
  currentRow,
}) => {
  const classes = useStyles();

  return (
    <Modal
      onClose={() => {
        setContentNoteModal(false);
      }}
      open={contentNoteModal}
      className={classes.modal}
    >
      <div
        style={{
          background: "white",
          marginBottom: 18,
          padding: 10,
          textAlign: "left",
          width: "70%",
        }}
      >
        <div>
          <Card style={{ boxShadow: "none" }}>
            <CardContent style={{ maxHeight: "80vh", overflow: "auto" }}>
              <div>
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: 20,
                    marginTop: 15,
                  }}
                >
                  <p
                    style={{
                      padding: 20,
                      backgroundColor: "#f0f0f0",
                      margin: 0,
                      padding: 0,
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    {currentRow?.content}
                  </p>
                </div>
              </div>
            </CardContent>

            <CardActions style={{ textAlign: "right", display: "block" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    flex: 1,
                    textAlign: "left",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    variant="success"
                    style={{
                      backgroundColor: "purple",
                      color: "white",
                      marginBottom: 20,
                      marginLeft: 25,
                      textTransform: "none",
                    }}
                    onClick={() => setContentNoteModal(false)}
                  >
                    <span style={{ fontFamily: "segoeuiRg" }}>Kapat</span>
                  </Button>
                </div>
              </div>
            </CardActions>
          </Card>
        </div>
      </div>
    </Modal>
  );
};

export default ContentNoteModal;
