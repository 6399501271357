import React, { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
import { Button, Paper } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import HeaderDeleteModal from "./HeaderDeleteModal";
import ProductDeleteModal from "./ProductDeleteModal";
import ProductHeader from "./ProductHeader";
import ProductModal from "./ProductModal";

export default function Pictures() {
  const {
    getProductHeaderPictureTemplate,
    productHeaderPictureTemplate,
    getProductPictureTemplate,
    productPictureTemplate,
    deleteProductTemplateHeader,
    openAddHeaderModal,
    setOpenAddHeaderModal,
    openProductModal,
    setOpenProductModal,
  } = useContext(RestaurantContext);
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openProductHeaderModal, setOpenProductHeaderModal] = useState(false);
  const [openProductDeleteModal, setOpenProductDeleteModal] = useState(false);

  useEffect(() => {
    getProductHeaderPictureTemplate();
  }, []);

  const handleDeleteHeader = (item) => {
    setOpenProductHeaderModal(true);
    getProductPictureTemplate(item.id);
    setSelectedProduct(null);
  };

  const handleDeleteProduct = () => {
    setOpenProductDeleteModal(true);
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={{ fontFamily: "segoeuiB", fontSize: 24, margin: 0 }}>
            Başlıklar
          </h3>

          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "#f53b62",
            }}
            onClick={() => {
              setSelectedHeader(null);
              setOpenAddHeaderModal(true);
            }}
          >
            Başlık Ekle
          </Button>
        </div>

        <div style={{ display: "flex", overflow: "auto" }}>
          {productHeaderPictureTemplate?.map((item, index) => {
            return (
              <div style={{ margin: 15 }} key={index}>
                <Paper
                  elevation={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    color: selectedHeader?.id === item.id ? "white" : "black",
                    fontFamily: "segoeuiRg",
                    height: 60,
                    background: selectedHeader?.id === item.id ? "#f53b62" : "",
                    padding: 5,
                  }}
                  onClick={() => {
                    setSelectedHeader(item);
                    getProductPictureTemplate(item.id);
                    setSelectedProduct(null);
                  }}
                >
                  <div
                    style={{
                      minWidth: 150,
                      maxWidth: 150,
                      marginRight: 10,
                      fontSize: 12,
                      paddingRight: 10,
                      borderRight: "1px solid rgba(0,0,0,0.2)",
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <span style={{ fontWeight: "bold", textAlign: "center" }}>
                      {item?.name}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ModeEditIcon
                      style={{ fontSize: 20, marginRight: 5 }}
                      onClick={() => {
                        setOpenAddHeaderModal(true);
                        setSelectedHeader(item);
                        setSelectedProduct(null);
                      }}
                    />
                    <DeleteIcon
                      style={{ fontSize: 20 }}
                      onClick={() => handleDeleteHeader(item)}
                    />
                  </div>
                </Paper>
              </div>
            );
          })}
        </div>
      </div>

      {productPictureTemplate?.length > 0 && selectedHeader && (
        <div style={{ marginTop: 50 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ fontFamily: "segoeuiB", fontSize: 24, margin: 0 }}>
              Ürünler
            </h3>
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#f53b62",
              }}
              onClick={() => {
                setOpenProductModal(true);
                setSelectedProduct(null);
              }}
            >
              Ürün Ekle
            </Button>
          </div>

          <div style={{ display: "flex", overflow: "auto" }}>
            {productPictureTemplate?.map((item, i) => {
              return (
                <Paper
                  key={i}
                  elevation={2}
                  style={{
                    minWidth: 250,
                    maxWidth: 250,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    padding: 5,
                    margin: 15,
                    fontSize: 12,
                    color: selectedProduct?.id === item.id ? "white" : "black",
                    fontFamily: "segoeuiRg",
                    background:
                      selectedProduct?.id === item.id ? "#f53b62" : "",
                  }}
                  onClick={() => {
                    setSelectedProduct(item);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: 10,
                      borderRight: "1px solid rgba(0,0,0,0.2)",
                      marginRight: 10,
                    }}
                  >
                    <img
                      style={{
                        height: 75,
                        width: 75,
                        borderRadius: 10,
                        border: "1px solid darkgray",
                      }}
                      src={item?.picture}
                      alt={item?.name}
                    />
                    <span style={{ fontWeight: "bold", marginLeft: 10 }}>
                      {item.name}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ModeEditIcon
                      style={{ fontSize: 20, marginRight: 5 }}
                      onClick={() => {
                        setOpenProductModal(true);
                        setSelectedProduct(item);
                      }}
                    />
                    <DeleteIcon
                      style={{ fontSize: 20 }}
                      onClick={() => handleDeleteProduct(item)}
                    />
                  </div>
                </Paper>
              );
            })}
          </div>
        </div>
      )}

      <HeaderDeleteModal
        openProductHeaderModal={openProductHeaderModal}
        setOpenProductHeaderModal={setOpenProductHeaderModal}
        selectedHeader={selectedHeader}
        productPictureTemplate={productPictureTemplate}
      />

      <ProductDeleteModal
        openProductDeleteModal={openProductDeleteModal}
        setOpenProductDeleteModal={setOpenProductDeleteModal}
        selectedProduct={selectedProduct}
        selectedHeader={selectedHeader}
      />

      <ProductHeader
        openAddHeaderModal={openAddHeaderModal}
        setOpenAddHeaderModal={setOpenAddHeaderModal}
        selectedHeader={selectedHeader}
        setSelectedHeader={setSelectedHeader}
      />

      <ProductModal
        openProductModal={openProductModal}
        setOpenProductModal={setOpenProductModal}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        headerId={selectedHeader?.id}
      />
    </div>
  );
}
