import React, { useContext, useState } from "react";

import { Button, Card, Modal, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import { DevelopmentContext } from "../../../contexts/developmentContext";
import { useToast } from "../../../components/snackbar";

const taskTypes = [
  {
    id: "test",
    value: "Test",
  },
  {
    id: "prod",
    value: "Canlıya al",
  },
  {
    id: "new_user",
    value: "Göreve Ata",
  },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function AddTaskModal({
  openAddTask,
  setOpenAddTask,
  taskDetail,
  selectedUser,
  developmentUserList,
}) {
  const { alert } = useToast();
  const classes = useStyles();
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [taskNote, setTaskNote] = useState("");
  const { addTaskToUser } = useContext(DevelopmentContext);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const handleSaveTask = () => {
    if (!selectedPerson) {
      alert("Lütfen bir kişi seçiniz.");
      return;
    }

    if (!selectedTaskType) {
      alert("Lütfen bir görev tipi seçiniz.");
      return;
    }

    const data = {
      type: selectedTaskType.id,
      note: taskNote,
      user: selectedPerson.user_id,
    };

    addTaskToUser(taskDetail.id, data);
    setOpenAddTask(false);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openAddTask}
      className={classes.modal}
      onClose={() => {
        setOpenAddTask(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "50%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            padding: 15,
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ marginRight: 10 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Görev Tipi Seçiniz
              </span>

              <div style={{ width: 200 }}>
                <Select
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  responsiveHeight={44}
                  selectFontSize={16}
                  options={taskTypes}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={90}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  placeholder={"Görev Tipi"}
                  value={selectedTaskType}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setSelectedTaskType(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.value}
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ marginBottom: 10 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Kişi Seçiniz
              </span>
              <div style={{ width: 200 }}>
                <Select
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  responsiveHeight={44}
                  selectFontSize={16}
                  options={developmentUserList}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={90}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  placeholder={"Kişi"}
                  value={selectedPerson}
                  getOptionValue={(option) => option.user_id}
                  onChange={(e) => {
                    setSelectedPerson(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.full_name}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Not
              </span>
              <TextField
                style={{ width: "100%" }}
                placeholder="Not"
                multiline
                rows={5}
                value={taskNote}
                onChange={(e) => {
                  setTaskNote(e.target.value);
                }}
              />
            </div>

            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  fontFamily: "segoeuiB",
                  width: "50%",
                  fontWeight: 700,
                  backgroundColor: "red",
                  marginRight: 20,
                }}
                onClick={() => {
                  setOpenAddTask(false);
                  setSelectedPerson(null);
                  setSelectedTaskType(null);
                  setTaskNote("");
                }}
              >
                Reddet
              </Button>

              <Button
                variant="contained"
                size="small"
                style={{
                  fontFamily: "segoeuiB",
                  width: "50%",
                  fontWeight: 700,
                  backgroundColor: "#0FBD20",
                }}
                onClick={handleSaveTask}
              >
                Onayla
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
