import React from "react";
import { Button } from "@mui/material";
function TabHeader({
  title,
  headerType,
  setHeaderMenu,
  setHeaderApp,
  setHeaderMasa,
  menuStatus,
  appStatus,
  masaStatus,
  setActionType,
}) {
  return (
    <Button
      style={{
        backgroundColor: headerType ? "#f53b62" : "#5a5757",
        cursor: "pointer",
        borderRadius: 4,
        color: "#fff",
        fontSize: 14,
        boxShadow: "2px 3px 2.5px 1px #C5C5BB",
        minWidth: 40,
        marginRight: 5,
        padding: 6,
      }}
      onClick={() => {
        if (title == "Menü") {
          setActionType("delete");
        } else if (title == "App") {
          setActionType("area");
        } else {
          setActionType("qr");
        }
        setHeaderMenu(menuStatus);
        setHeaderApp(appStatus);
        setHeaderMasa(masaStatus);
      }}
    >
      {title}
    </Button>
  );
}

export default TabHeader;
