import React, { useContext } from "react";
import { Box, Button, Modal, createTheme, useMediaQuery } from "@mui/material";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { ElectricBolt } from "@mui/icons-material";

const UrgentModal = ({
  urgentModal,
  setUrgentModal,
  currentUrgentId,
  type,
}) => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { acceptUrgentSetupFunc, acceptUrgentCollectorFunc } =
    useContext(RestaurantContext);

  return (
    <Modal
      open={urgentModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
          width: matches ? "30%" : "90%",
          maxHeight: 250,
          overflow: "auto",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          minHeight: 250,
          alignItems: "center",
        }}
      >
        <div style={{ fontFamily: "segoeuiB", fontSize: 48 }}>
          {type === "application" ? "Acil Kurulum" : "Acil Tahsilat"}
        </div>
        <ElectricBolt style={{ width: 120, height: 120, color: "goldenrod" }} />
        <div style={{ display: "flex", width: "100%" }}>
          <Button
            style={{
              backgroundColor: "red",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: "90%",
              display: "flex",
              justifyContent: "center",
              height: 40,
              marginLeft: 10,
            }}
            size="small"
            onClick={() => {
              setUrgentModal(false);
            }}
          >
            Müsait Değilim
          </Button>
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: "90%",
              display: "flex",
              justifyContent: "center",
              height: 40,
              marginLeft: 10,
            }}
            size="small"
            onClick={() => {
              if (type === "application") {
                acceptUrgentSetupFunc({ app_id: currentUrgentId });
              } else {
                acceptUrgentCollectorFunc({ id: currentUrgentId });
              }
              setUrgentModal(false);
            }}
          >
            Kabul Et
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};

export default UrgentModal;
