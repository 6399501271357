import React from "react";

import { TablePagination } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import moment from "moment";
import TablePaginationActions from "../../../pagination/mainPagination";

registerLocale("tr", tr);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },

  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "segoeuiB",
    },
  },
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
}));

const Notes = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  applicationNotes,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        width: "96%",
        backgroundColor: "#fff",
        margin: 15,
      }}
    >
      <div
        style={{
          fontFamily: "segoeuiB",
          fontSize: 16,
          textDecoration: "underline",
          width: "100%",
          marginLeft: 4,
          padding: 10,
        }}
      >
        Notlar
      </div>
      <div>
        <div>
          {applicationNotes?.results?.map((row, i) => {
            return (
              <ul
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                  borderBottomColor: "#f4f4f4",
                  paddingRight: 10,
                }}
              >
                <li
                  style={{
                    marginTop: 4,
                    fontFamily: "segoeuiRg",
                    fontSize: 15,
                    color:
                      row.status == "Zamanlayıcı"
                        ? "#e03192"
                        : row.status == "Normal"
                        ? "#293c51"
                        : "red",
                  }}
                >
                  [{row.status}] [
                  {row.created_by === null
                    ? "-"
                    : row?.created_by?.role === "setup"
                    ? "Kurulum"
                    : row?.created_by?.role === "collector"
                    ? "Tahsilat"
                    : row?.created_by?.role}
                  ] [
                  {row.created_by === null ? "-" : row?.created_by?.full_name}]
                  : {row?.note}
                </li>
                <span style={{ fontFamily: "segoeuiRg", fontSize: 15 }}>
                  {moment(row?.created_at).format("DD-MM-YYYY HH:mm:ss")} ({" "}
                  {moment(row?.created_at).fromNow()} )
                </span>
              </ul>
            );
          })}
        </div>
        {applicationNotes?.count != 0 && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "#fff",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5]}
              labelRowsPerPage={"Sayfa Başına Kayıt"}
              count={applicationNotes?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              classes={{ root: classes.root }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Notes;
