import {
  Backdrop,
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import SearchWithIcon from "../../../components/searchWithIcon";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import useDebounce from "../../../components/debounce";
import { Report } from "@mui/icons-material";
import { useToast } from "../../../components/snackbar";
import FormatNumber from "../../../components/formatData/formatPhone";

export default function CallCenter() {
  let history = useHistory();
  const { callCenterRestSearchFunc, callCenterRestList } =
    useContext(RestaurantContext);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedsearchValue = useDebounce(searchTerm, 1000);
  const { alert } = useToast();

  useEffect(() => {
    if (debouncedsearchValue !== "") {
      let data = {
        searchTerm: debouncedsearchValue,
      };
      callCenterRestSearchFunc(data);
    }
  }, [debouncedsearchValue]);

  useEffect(() => {
    if (callCenterRestList != null && callCenterRestList?.length === 0) {
      alert("Arama ile eşleşen restoran bulunamadı.");
    }
  }, [callCenterRestList]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          marginTop: 30,
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%" }}>
          <SearchWithIcon
            setSearchTerm={setSearchTerm}
            placeTitle={"Restoran Ara"}
            searchTerm={searchTerm}
          />
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Restoran Adı</TableCell>
                <TableCell align="center">Restoran Yetkilisi</TableCell>
                <TableCell align="center">Telefon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callCenterRestList?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">
                      <div
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push(`/restaurants/${row?.user_id}`);
                        }}
                      >
                        {row?.name}
                      </div>
                    </TableCell>
                    <TableCell align="center"> {row?.full_name}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {FormatNumber(row?.phone, "")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}
