import React, { useState, useContext } from "react";
import { Drawer, Collapse, ListItem } from "@mui/material";

import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    minWidth: 260,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 12,
    fontFamily: "segoeuiRg",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "#fafafa",
    color: "#f53f62",
  },
  mainItem: {
    display: "flex",
    color: "#000",
    fontWeight: "600px",
    fontStyle: "bold",
    fontFamily: "segoeuiB",
  },
}));

const MobilRestaurantSidebar = ({ openDrawer, setOpenDrawer }) => {
  const classes = useStyles();
  const [resOpen, setResOpen] = useState(true);
  const [reportsOpen, setReportsOpen] = useState(true);
  const [platformOpen, setPlatformOpen] = useState(true);
  const [managmentOpen, setManagmentOpen] = useState(true);
  const { currentIndex, setCurrentIndex } = useContext(RestaurantContext);

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List component="nav" className={classes.root}>
          <ListItem
            onClick={() => setPlatformOpen(!platformOpen)}
            className={classes.mainItem}
          >
            <ListItemText primary="Platformlar" />
            {platformOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={platformOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("tabela");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Tabelalar"
                  style={{
                    color: currentIndex === "tabela" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("offers");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Teklif"
                  style={{
                    color: currentIndex === "offers" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("secret_key");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Secret Key"
                  style={{
                    color: currentIndex === "secret_key" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("menu");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Menu"
                  style={{
                    color: currentIndex === "menu" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("working_hour");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Çalışma S"
                  style={{
                    color: currentIndex === "working_hour" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("payment_type");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Ödeme Y"
                  style={{
                    color: currentIndex === "payment_type" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("courier");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Kuryeler"
                  style={{
                    color: currentIndex === "courier" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem onClick={() => setResOpen(!resOpen)}>
            <ListItemText primary="Restoranlar" />
            {resOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={resOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("printer");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Yazıcı/Ses"
                  style={{
                    color: currentIndex === "printer" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            className={classes.nested}
            onClick={() => {
              setCurrentIndex("management_panel");
              setOpenDrawer(false);
            }}
          >
            <ListItemText
              primary="Yönetim Paneli"
              style={{
                color: currentIndex === "management_panel" ? "#f53f62" : "#000",
              }}
            />
          </ListItem>
          <ListItem
            onClick={() => setReportsOpen(!reportsOpen)}
            className={classes.mainItem}
          >
            <ListItemText primary="Raporlar" />
            {reportsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={classes.nested}
                onClick={() => {
                  setCurrentIndex("reports");
                  setOpenDrawer(false);
                }}
              >
                <ListItemText
                  primary="Sipariş/Ciro"
                  style={{
                    color: currentIndex === "reports" ? "#f53f62" : "#000",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className={classes.mainItem}
            onClick={() => {
              setCurrentIndex("module");
              setOpenDrawer(false);
            }}
          >
            <ListItemText
              primary="Modüller"
              style={{ color: currentIndex === "module" ? "#f53f62" : "#000" }}
            />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default MobilRestaurantSidebar;
