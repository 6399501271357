import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import Select from "react-select";
import { makeStyles } from "@mui/styles";
import restaurantService from "../../../../services/restaurantService";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import useWindowDimensions from "../../../../components/windowSize";
import managementService from "../../../../services/managementService";
import GridItem from "../../../../components/gridItem";
import moment from "moment";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useToast } from "../../../../components/snackbar";
import InfoIcon from "@mui/icons-material/Info";

const nextDay = moment().add(1, "days");
const nextWeek = moment().add(1, "weeks");
const nextTwoWeek = moment().add(2, "weeks");
const nextMonth = moment().add(1, "M");

const convertedNextDay = moment(nextDay).format("YYYY-MM-DD");
const convertedNextWeek = moment(nextWeek).format("YYYY-MM-DD");
const convertedNextTwoWeek = moment(nextTwoWeek).format("YYYY-MM-DD");
const convertedNextMonth = moment(nextMonth._d).format("YYYY-MM-DD");

const expireDates = [
  {
    label: "1 Gün",
    date: convertedNextDay,
  },
  {
    label: "1 Hafta",
    date: convertedNextWeek,
  },
  {
    label: "2 Hafta",
    date: convertedNextTwoWeek,
  },
  {
    label: "1 Ay",
    date: convertedNextMonth,
  },
];

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

export default function Notifications() {
  const classes = useStyles();
  const [response, setResponse] = useState(null);
  const { width } = useWindowDimensions();
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedError, setSelectedError] = useState(null);
  const [message, setMessage] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [expireDate, setExpireDate] = useState("");
  const [notifReports, setNotifReports] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { success, alert } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getNotificationTypes = async () => {
    const res = await restaurantService.get_notification_types();
    setResponse(res);
  };

  const getNotificationCount = async (type, error, product) => {
    const res = await managementService.get_notification_type(
      type,
      error,
      product
    );
    setTotalCount(res.count);
  };

  const getNotificationReports = async () => {
    const res = await managementService.get_notif_reports(page + 1);
    setNotifReports(res);
  };

  useEffect(() => {
    getNotificationReports();
  }, [page]);

  useEffect(() => {
    if (selectedNotificationType) {
      if (selectedNotificationType.key === "product") {
        if (selectedProduct) {
          getNotificationCount(
            selectedNotificationType.key,
            "",
            selectedProduct.key
          );
        }
      } else if (selectedNotificationType.key === "general") {
        getNotificationCount(selectedNotificationType.key);
      } else if (selectedNotificationType.key === "error") {
        if (selectedError) {
          getNotificationCount(selectedNotificationType.key, selectedError.key);
        }
      }
    }
  }, [selectedNotificationType, selectedError, selectedProduct]);

  const handleChangeNotification = (e) => {
    setSelectedNotificationType(e);
  };

  const handleChangeProduct = (e) => {
    setSelectedProduct(e);
  };

  const handleChangeError = (e) => {
    setSelectedError(e);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleChangeExpireDate = (e) => {
    setExpireDate(e);
  };

  const handleSubmitNotification = async () => {
    setIsSubmitting(true);
    let data;
    if (selectedNotificationType.key === "product") {
      data = {
        notif_type: selectedNotificationType.key,
        message,
        product: selectedProduct.key ? selectedProduct.key : "",
        expired_at: expireDate.date,
        amount,
      };
    } else if (selectedNotificationType.key === "general") {
      data = {
        notif_type: selectedNotificationType.key,
        message,
        expired_at: expireDate.date,
        amount,
      };
    } else {
      data = {
        notif_type: selectedNotificationType.key,
        error: selectedError.key,
        message,
        expired_at: expireDate.date,
        amount,
      };
    }
    await managementService
      .send_notification(data)
      .then((res) => {
        setIsSubmitting(false);
        success("Bildirim başarıyla gönderildi");
      })
      .catch((err) => {
        alert("Bildirim gönderilemedi");
        setIsSubmitting(false);
      });

    setSelectedNotificationType(null);
    setSelectedError(null);
    setSelectedProduct(null);
    setMessage("");
    setAmount(0);
    setExpireDate("");
    setTotalCount(0);
    getNotificationReports();
  };

  useEffect(() => {
    getNotificationTypes();
  }, []);

  return (
    <div>
      <Box sx={{ display: "flex", marginBottom: 4 }}>
        <Box sx={{ marginRight: 4 }}>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Bildirim Tipi
            </span>
            <Select
              options={response?.notification_type}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              zIndex={500}
              isSearchable={false}
              hoverColor={"black"}
              selectWidth={width > 1200 ? 230 : 140}
              menuColor={"#f53b62"}
              placeholder={"Bildirim Tipi Seç"}
              value={selectedNotificationType}
              getOptionValue={(option) => option.key}
              onChange={(e) => {
                handleChangeNotification(e);
              }}
              getOptionLabel={(option) => option.value}
            />
          </div>
        </Box>

        {selectedNotificationType?.key === "product" && (
          <Box sx={{ marginRight: 4 }}>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Ürün Seç
              </span>
              <Select
                options={response?.products}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                zIndex={500}
                isSearchable={false}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 230 : 140}
                menuColor={"#f53b62"}
                placeholder={"Ürün Seç"}
                value={selectedProduct}
                getOptionValue={(option) => option.key}
                onChange={(e) => {
                  handleChangeProduct(e);
                }}
                getOptionLabel={(option) => option.value}
              />
            </div>
          </Box>
        )}

        {selectedNotificationType?.key === "error" && (
          <Box sx={{ marginRight: 4 }}>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Hata Seç
              </span>
              <Select
                options={response?.errors}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                zIndex={500}
                isSearchable={false}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 230 : 140}
                menuColor={"#f53b62"}
                placeholder={"Hata Seç"}
                value={selectedError}
                getOptionValue={(option) => option.key}
                onChange={(e) => {
                  handleChangeError(e);
                }}
                getOptionLabel={(option) => option.value}
              />
            </div>
          </Box>
        )}
      </Box>

      <Box sx={{ marginBottom: 4 }}>
        <span
          style={{
            fontSize: 20,
            marginBottom: 10,
            display: "block",
            fontWeight: "600",
          }}
        >
          Bildirim İçerik Gir
        </span>
        <TextareaAutosize
          style={{
            width: "100%",
            minHeight: "50px",
            fontFamily: "segoeuiRg",
            padding: "10px",
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Box>

      <Box sx={{ display: "flex" }}>
        <GridItem title={"Toplam"} count={totalCount} center={true} />

        <Box sx={{ marginLeft: 4 }}>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Tutar Gir
          </span>
          <InputBase
            style={{
              background: "#fff",
              padding: 6,
              fontSize: 16,
              fontWeight: "400",
              fontFamily: "segoeuiRg",
            }}
            value={amount}
            onChange={handleChangeAmount}
          />
        </Box>

        <Box sx={{ marginLeft: 4 }}>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Geçerlilik Süresi
            </span>
            <Select
              options={expireDates}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              selectWidth={width > 1200 ? 230 : 140}
              menuColor={"#f53b62"}
              placeholder={"Süre Seç"}
              value={expireDate}
              getOptionValue={(option) => option.date}
              onChange={(e) => {
                handleChangeExpireDate(e);
              }}
              getOptionLabel={(option) => option.label}
            />
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            backgroundColor: !isSubmitting ? "#f53f62" : "#b70023",
            color: "#fff",
            fontFamily: "segoeuiB",
            width: 100,
            marginTop: 15,
            display: "flex",
            justifyContent: "center",
            height: 40,
            textTransform: "capitalize",
          }}
          size="small"
          onClick={handleSubmitNotification}
          disabled={isSubmitting}
        >
          Gönder
        </Button>
      </Box>

      <span
        style={{
          fontSize: 20,
          marginBottom: 10,
          display: "block",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Geçmiş Bildirim Raporları
      </span>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Geçerlilik Süresi</TableCell>
              <TableCell align="center">Tarih</TableCell>
              <TableCell align="center">Bildirim Tipi</TableCell>
              <TableCell align="center">Data Tipi</TableCell>
              <TableCell align="center">Bildirim İçerik</TableCell>
              <TableCell align="center">Toplam</TableCell>
              <TableCell align="center">Geri Dönen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifReports?.results.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    {row?.expired_at
                      ? moment(row?.expired_at).format("DD-MM-YYYY HH:mm:ss")
                      : "-"}
                  </TableCell>
                  <TableCell align="center">{row.day}</TableCell>
                  <TableCell align="center">{row.notify_type}</TableCell>
                  <TableCell align="center">{row.error_type}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      placement="top-start"
                      title={row.text}
                      sx={{ marginLeft: 1 }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 12,
                            width: 200,
                            padding: 1,
                            bgcolor: "#fff",
                            color: "#000",
                            boxShadow: "1px 1px 1px 1px #C5C5BB",
                            border: "1px solid #f4f4f4",

                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                    >
                      <IconButton>
                        <InfoIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">{row.count}</TableCell>
                  <TableCell align="center">{row.total_return}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {notifReports?.results != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={notifReports?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
