import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Colors from "../../../../utils/colors";
import AcceptPaymentModal from "../../../../components/modals/acceptPaymentModal";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";

const PrimControl = ({ primResult, handlePrimControl, togglePrim }) => {
  const [acceptPaymentOpen, setAcceptPaymentOpen] = useState(false);
  const [person, setPerson] = useState({});

  const calculateTotalPrim = () => {
    let total = 0;
    primResult.map((row) => {
      total = total + row?.total_prim;
    });
    return total.toFixed(2);
  };

  return (
    <div>
      <Box sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            backgroundColor: Colors.mainColor,
            marginLeft: "auto",
            width: "max-content",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            cursor: "pointer",
            textTransform: "capitalize",
            borderRadius: "8px",
            fontFamily: "segoeuiRg",
            color: "white",
            padding: 1,
          }}
          onClick={handlePrimControl}
        >
          <h3
            style={{
              fontFamily: "segoeuiL",
              fontSize: "16px",
              marginRight: 10,
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            {togglePrim ? "Onaylanmış Ödemeler" : "Bekleyen Ödemeler"}
          </h3>
          {togglePrim ? <CheckCircleRoundedIcon /> : <AccessTimeRoundedIcon />}
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Çalışan</TableCell>
              <TableCell align="center">Prim</TableCell>
              <TableCell align="center">Ödeme</TableCell>
              <TableCell align="center">Durum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {primResult?.map((person, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">{person.user.username}</TableCell>
                  <TableCell align="center">{person.total_prim} ₺</TableCell>
                  <TableCell align="center">
                    <GreenSwitch
                      onClick={() => {
                        setPerson(person);
                        setAcceptPaymentOpen(true);
                      }}
                      disabled={person.payed ? true : false}
                      checked={person.payed ? true : false}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {person.payed ? "ÖDENDİ" : "ÖDENMEDİ"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div
          style={{
            width: "90%",
            textAlign: "end",
            fontSize: 24,
            fontFamily: "segoeuiB",
          }}
        >
          Toplam : {calculateTotalPrim() + " ₺"}{" "}
        </div>
      </TableContainer>
      <AcceptPaymentModal
        person={person}
        acceptPaymentOpen={acceptPaymentOpen}
        setAcceptPaymentOpen={setAcceptPaymentOpen}
      />
    </div>
  );
};

export default PrimControl;
