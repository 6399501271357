import React from "react";

import { Paper, useTheme, useMediaQuery } from "@mui/material";

const Containner = ({ children }) => {
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
      }}
    >
      {/* <TopMenu /> */}
      <Paper
        elevation={5}
        style={{
          backgroundColor: "black",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          height: "calc(100vh - 60px)",
          marginTop: isMatch ? 60 : 10,
        }}
      >
        {children}
      </Paper>
    </div>
  );
};

export default Containner;
