import React, { useContext, useEffect, useState } from "react";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControlLabel,
  InputBase,
  Paper,
  Radio,
} from "@mui/material";
import { ManagementContext } from "../../../../contexts/managementContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev2";
import { offerCount } from "../../../../utils/constants";
import { makeStyles } from "@mui/styles";
import TextChoicesModal from "./textChoicesModal";
import TextChoicesModalv2 from "./textChoicesModalv2";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
});

const PuantajSettings = () => {
  const classes = useStyles();
  const [createNewRatingSetting, setCreateNewRatingSetting] = useState(null);
  const [ratingSettingListObj, setRatingSettingListObj] = useState([]);
  const [textChoicesModal, setTextChoicesModal] = useState(false);
  const [textChoicesModalv2, setTextChoicesModalv2] = useState(false);
  const [textChoicesModalInfo, setTextChoicesModalInfo] = useState(null);
  const {
    ratingSettingsListFunc,
    ratingSettingsList,
    createRatingSettingsFunc,
    dashboardCdrFiltersFunc,
    dashboardCdrFilterList,
    ratingSettingAddFunc,
    ratingSettingDeleteFunc,
  } = useContext(ManagementContext);

  useEffect(() => {
    ratingSettingsListFunc();
    dashboardCdrFiltersFunc();
  }, []);

  useEffect(() => {
    if (ratingSettingsList !== null) {
      setRatingSettingListObj(ratingSettingsList);
    }
  }, [ratingSettingsList]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {ratingSettingListObj?.map((row, index) => {
          return (
            <Paper
              elevation={3}
              style={{
                width: "48%",
                display: "flex",
                padding: 5,
                margin: "10px 10px",
                backgroundColor: "#f4f4f4",
                boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                height: 300,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "25%",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div style={{ width: "100%" }}>
                  <Paper
                    style={{
                      backgroundColor: "#736868",
                      width: "-webkit-fill-available",
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        fontFamily: "monospace",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      Task Tipi Seç
                    </div>
                  </Paper>
                  <div
                    style={{
                      textAlign: "--webkit-center",
                      marginTop: 10,
                      width: "100%",
                    }}
                  >
                    <Select
                      options={dashboardCdrFilterList?.task_types}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      selectWidth={"100%"}
                      hoverColor={"black"}
                      menuColor={"#f53b62"}
                      placeHolderColor={"#fff"}
                      placeholder={"Adet"}
                      dynamiczIndex={3}
                      value={{
                        id: row?.task_type?.id,
                        name: dashboardCdrFilterList?.task_types?.find(
                          (item) => item.id === row?.task_type?.id
                        )?.name,
                      }}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        const ratingSettingListObjv2 = Object.assign(
                          [],
                          JSON.parse(
                            JSON.stringify({
                              ...ratingSettingListObj,
                            })
                          )
                        );
                        ratingSettingListObjv2[index].task_type.id = e.id;
                        setRatingSettingListObj(ratingSettingListObjv2);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.name}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <Paper
                    style={{
                      backgroundColor: "#736868",
                      width: "-webkit-fill-available",
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        fontFamily: "monospace",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      Adet
                    </div>
                  </Paper>
                  <div style={{ textAlign: "--webkit-center", marginTop: 10 }}>
                    <Select
                      options={offerCount}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      selectWidth={"100%"}
                      hoverColor={"black"}
                      menuColor={"#f53b62"}
                      placeHolderColor={"#fff"}
                      placeholder={"Adet"}
                      dynamiczIndex={3}
                      value={{
                        value: row?.count,
                        label: row?.count,
                      }}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        const ratingSettingListObjv2 = Object.assign(
                          [],
                          JSON.parse(
                            JSON.stringify({
                              ...ratingSettingListObj,
                            })
                          )
                        );
                        ratingSettingListObjv2[index].count = e.value;
                        setRatingSettingListObj(ratingSettingListObjv2);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "75%" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    height: "90%",
                    justifyContent: "space-evenly",
                    overflow: "auto",
                  }}
                >
                  {row?.features?.map((rowF, ind) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "48%",
                        }}
                      >
                        <Paper
                          style={{
                            backgroundColor: "#736868",
                            width: "100%",
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            height: 80,
                            alignItems: "center",
                            marginTop: 10,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontFamily: "monospace",
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexDirection: "column",
                              padding: 2,
                              alignItems: "center",
                              width: "40%",
                            }}
                          >
                            Puanlama Türü
                            <div
                              style={{
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              <InputBase
                                spellCheck={false}
                                style={{
                                  width: "100%",
                                  fontFamily: "segoeuiRg",
                                  fontSize: 12,
                                  backgroundColor: "#f4f4f4",
                                  borderRadius: 4,
                                }}
                                rows={1}
                                placeholder={"Not ekle"}
                                className={classes.input}
                                value={rowF?.title}
                                onChange={(e) => {
                                  const ratingSettingListObjv2 = Object.assign(
                                    [],
                                    JSON.parse(
                                      JSON.stringify({
                                        ...ratingSettingListObj,
                                      })
                                    )
                                  );
                                  ratingSettingListObjv2[index].features[
                                    ind
                                  ].title = e.target.value;
                                  setRatingSettingListObj(
                                    ratingSettingListObjv2
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <Divider
                            orientation="vertical"
                            sx={{
                              backgroundColor: "#f4f4f4",
                              width: "1px",
                            }}
                          />
                          <div
                            style={{
                              fontSize: 10,
                              fontFamily: "monospace",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              padding: 2,
                              alignItems: "center",
                              width: "60%",
                            }}
                          >
                            Puan Tipi
                            <div
                              style={{
                                fontSize: 13,
                                textAlign: "center",
                                display: "flex",
                              }}
                            >
                              <FormControlLabel
                                value={
                                  rowF?.rating_type === "numeric" ? true : false
                                }
                                control={
                                  <Radio
                                    checked={
                                      rowF?.rating_type === "numeric"
                                        ? true
                                        : false
                                    }
                                    size="small"
                                    sx={{
                                      color: "#fff",
                                      "&.Mui-checked": {
                                        color: "#6f1111",
                                      },
                                    }}
                                    onClick={() => {
                                      const ratingSettingListObjv2 =
                                        Object.assign(
                                          [],
                                          JSON.parse(
                                            JSON.stringify({
                                              ...ratingSettingListObj,
                                            })
                                          )
                                        );
                                      if (
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].rating_type === "numeric"
                                      ) {
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].rating_type = "";
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].text_choices = [];
                                      } else {
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].rating_type = "numeric";
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].text_choices = [];
                                      }
                                      setRatingSettingListObj(
                                        ratingSettingListObjv2
                                      );
                                    }}
                                  />
                                }
                                label="Numeric"
                                labelPlacement="start"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: 12,
                                  },
                                }}
                              />
                              <FormControlLabel
                                value={
                                  rowF?.rating_type === "text" ? true : false
                                }
                                control={
                                  <Radio
                                    checked={
                                      rowF?.rating_type === "text"
                                        ? true
                                        : false
                                    }
                                    size="small"
                                    sx={{
                                      color: "#fff",
                                      "&.Mui-checked": {
                                        color: "#6f1111",
                                      },
                                    }}
                                    onClick={() => {
                                      const ratingSettingListObjv2 =
                                        Object.assign(
                                          [],
                                          JSON.parse(
                                            JSON.stringify({
                                              ...ratingSettingListObj,
                                            })
                                          )
                                        );
                                      if (
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].rating_type === "text"
                                      ) {
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].rating_type = "";
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].text_choices = [];
                                      } else {
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].rating_type = "text";
                                        ratingSettingListObjv2[index].features[
                                          ind
                                        ].text_choices[0] = {
                                          choice: "",
                                          sentiment: "",
                                        };
                                      }
                                      setRatingSettingListObj(
                                        ratingSettingListObjv2
                                      );
                                    }}
                                  />
                                }
                                label="Text"
                                labelPlacement="start"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: 12,
                                  },
                                }}
                              />
                            </div>
                            {rowF?.rating_type === "text" ? (
                              <div>
                                <div
                                  onClick={() => {
                                    setTextChoicesModalv2(true);
                                    setTextChoicesModalInfo({
                                      currentRow: row,
                                      currentInd: index,
                                      currentIndF: ind,
                                    });
                                  }}
                                >
                                  {" "}
                                  Ayarla{" "}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Paper>
                        <Paper
                          style={{
                            backgroundColor: "#736868",
                            width: "100%",
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            height: 20,
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "20%",
                              display: "flex",
                              justifyContent: "center",
                              height: 18,
                            }}
                            size="small"
                            onClick={() => {
                              let data = {
                                rating_setting_id: row?.id,
                                feature_setting_id: rowF?.id,
                              };
                              ratingSettingDeleteFunc(data);
                            }}
                          >
                            Sil
                          </Button>
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "20%",
                              display: "flex",
                              justifyContent: "center",
                              height: 18,
                            }}
                            size="small"
                            onClick={() => {
                              let sendingObj;
                              if (rowF?.rating_type === "numeric") {
                                sendingObj = {
                                  title: rowF?.title,
                                  rating_type: rowF?.rating_type,
                                };
                              } else {
                                sendingObj = {
                                  title: rowF?.title,
                                  rating_type: rowF?.rating_type,
                                  text_choices: rowF?.text_choices,
                                };
                              }
                              let data = {
                                rating_setting_id: row?.id,
                                sendObj: sendingObj,
                              };
                              ratingSettingAddFunc(data);
                            }}
                          >
                            Kaydet
                          </Button>
                        </Paper>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    marginBottom: 5,
                    alignItems: "flex-end",
                  }}
                >
                  <div>
                    <AddCircleOutline
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRatingSettingListObj((prev) =>
                          prev.map((item, ind2) =>
                            ind2 === index
                              ? {
                                  ...item,
                                  features: [
                                    ...item.features,
                                    {
                                      title: "",
                                      rating_type: "",
                                      text_choices: [],
                                    },
                                  ],
                                }
                              : item
                          )
                        );
                      }}
                    />
                  </div>
                  {/* <Button
                    style={{
                      backgroundColor: "#f53b62",
                      color: "#fff",
                      fontFamily: "segoeuiB",
                      width: "20%",
                      display: "flex",
                      justifyContent: "center",
                      height: 20,
                    }}
                    size="small"
                    onClick={() => {
                      let data = {
                        sendObj: ratingSettingListObj,
                      };
                      createRatingSettingsFunc(data);
                    }}
                  >
                    Gönder
                  </Button> */}
                </div>
              </div>
            </Paper>
          );
        })}

        <Paper
          elevation={3}
          style={{
            width: "48%",
            display: "flex",
            padding: 5,
            margin: "10px 10px",
            backgroundColor: "#f4f4f4",
            boxShadow: "green 0px 0px 9px 2px",
            height: 300,
          }}
        >
          {createNewRatingSetting !== null ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "25%",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div style={{ width: "100%" }}>
                  <Paper
                    style={{
                      backgroundColor: "#736868",
                      width: "-webkit-fill-available",
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        fontFamily: "monospace",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      Task Tipi Seç
                    </div>
                  </Paper>
                  <div style={{ textAlign: "--webkit-center", marginTop: 10 }}>
                    <Select
                      options={dashboardCdrFilterList?.task_types}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      selectWidth={"100%"}
                      hoverColor={"black"}
                      menuColor={"#f53b62"}
                      placeHolderColor={"#fff"}
                      placeholder={"Adet"}
                      dynamiczIndex={3}
                      value={{
                        id: createNewRatingSetting?.task_type,
                        name: dashboardCdrFilterList?.task_types?.find(
                          (item) =>
                            item.id === createNewRatingSetting?.task_type
                        )?.name,
                      }}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        //   setSelectedQuantity(e);
                        const createNewRatingSettingObj = Object.assign(
                          {},
                          JSON.parse(
                            JSON.stringify({
                              ...createNewRatingSetting,
                            })
                          )
                        );
                        createNewRatingSettingObj.task_type = e.id;
                        setCreateNewRatingSetting(createNewRatingSettingObj);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.name}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <Paper
                    style={{
                      backgroundColor: "#736868",
                      width: "-webkit-fill-available",
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        fontFamily: "monospace",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      Adet
                    </div>
                  </Paper>
                  <div style={{ textAlign: "--webkit-center", marginTop: 10 }}>
                    <Select
                      options={offerCount}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      selectWidth={100}
                      hoverColor={"black"}
                      menuColor={"#f53b62"}
                      placeHolderColor={"#fff"}
                      placeholder={"Adet"}
                      dynamiczIndex={3}
                      value={{
                        value: createNewRatingSetting?.count,
                        label: createNewRatingSetting?.count,
                      }}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        const createNewRatingSettingObj = Object.assign(
                          {},
                          JSON.parse(
                            JSON.stringify({
                              ...createNewRatingSetting,
                            })
                          )
                        );
                        createNewRatingSettingObj.count = e.value;
                        setCreateNewRatingSetting(createNewRatingSettingObj);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "75%" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    height: "90%",
                    justifyContent: "space-evenly",
                    overflow: "auto",
                  }}
                >
                  {createNewRatingSetting?.features?.map((row, ind) => {
                    return (
                      <Paper
                        style={{
                          backgroundColor: "#736868",
                          width: "48%",
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          height: 80,
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "monospace",
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                            padding: 2,
                            alignItems: "center",
                            width: "40%",
                          }}
                        >
                          Puanlama Türü
                          <div
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                            }}
                          >
                            <InputBase
                              spellCheck={false}
                              style={{
                                width: "100%",
                                fontFamily: "segoeuiRg",
                                fontSize: 12,
                                backgroundColor: "#f4f4f4",
                                borderRadius: 4,
                              }}
                              rows={1}
                              placeholder={"Not ekle"}
                              className={classes.input}
                              value={row?.title}
                              onChange={(e) => {
                                const createNewRatingSettingObj = Object.assign(
                                  {},
                                  JSON.parse(
                                    JSON.stringify({
                                      ...createNewRatingSetting,
                                    })
                                  )
                                );
                                createNewRatingSettingObj.features[ind].title =
                                  e.target.value;
                                setCreateNewRatingSetting(
                                  createNewRatingSettingObj
                                );
                              }}
                            />
                          </div>
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "monospace",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 2,
                            alignItems: "center",
                            width: "60%",
                          }}
                        >
                          Puan Tipi
                          <div
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                              display: "flex",
                            }}
                          >
                            <FormControlLabel
                              value={
                                row?.rating_type === "numeric" ? true : false
                              }
                              control={
                                <Radio
                                  checked={
                                    row?.rating_type === "numeric"
                                      ? true
                                      : false
                                  }
                                  size="small"
                                  sx={{
                                    color: "#fff",
                                    "&.Mui-checked": {
                                      color: "#6f1111",
                                    },
                                  }}
                                  onClick={() => {
                                    const createNewRatingSettingObj =
                                      Object.assign(
                                        {},
                                        JSON.parse(
                                          JSON.stringify({
                                            ...createNewRatingSetting,
                                          })
                                        )
                                      );
                                    if (
                                      createNewRatingSettingObj.features[ind]
                                        .rating_type === "numeric"
                                    ) {
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].rating_type = "";
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].text_choices = [];
                                    } else {
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].rating_type = "numeric";
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].text_choices = [];
                                    }
                                    setCreateNewRatingSetting(
                                      createNewRatingSettingObj
                                    );
                                  }}
                                />
                              }
                              label="Numeric"
                              labelPlacement="start"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: 12,
                                },
                              }}
                            />
                            <FormControlLabel
                              value={row?.rating_type === "text" ? true : false}
                              control={
                                <Radio
                                  checked={
                                    row?.rating_type === "text" ? true : false
                                  }
                                  size="small"
                                  sx={{
                                    color: "#fff",
                                    "&.Mui-checked": {
                                      color: "#6f1111",
                                    },
                                  }}
                                  onClick={() => {
                                    const createNewRatingSettingObj =
                                      Object.assign(
                                        {},
                                        JSON.parse(
                                          JSON.stringify({
                                            ...createNewRatingSetting,
                                          })
                                        )
                                      );
                                    if (
                                      createNewRatingSettingObj.features[ind]
                                        .rating_type === "text"
                                    ) {
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].rating_type = "";
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].text_choices = [];
                                    } else {
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].rating_type = "text";
                                      createNewRatingSettingObj.features[
                                        ind
                                      ].text_choices[0] = {
                                        choice: "",
                                        sentiment: "",
                                      };
                                    }
                                    setCreateNewRatingSetting(
                                      createNewRatingSettingObj
                                    );
                                  }}
                                />
                              }
                              label="Text"
                              labelPlacement="start"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: 12,
                                },
                              }}
                            />
                          </div>
                          {row?.rating_type === "text" ? (
                            <div>
                              <div
                                onClick={() => {
                                  setTextChoicesModal(true);
                                  setTextChoicesModalInfo({
                                    currentRow: row,
                                    currentInd: ind,
                                  });
                                }}
                              >
                                {" "}
                                Ayarla{" "}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Paper>
                    );
                  })}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    marginBottom: 5,
                  }}
                >
                  <div>
                    <AddCircleOutline
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCreateNewRatingSetting((prev) => ({
                          ...prev,
                          features: Array.isArray(prev.features)
                            ? [
                                ...prev.features,
                                {
                                  title: "",
                                  rating_type: "",
                                  text_choices: [],
                                },
                              ]
                            : [
                                {
                                  title: "",
                                  rating_type: "",
                                  text_choices: [],
                                },
                              ],
                        }));
                      }}
                    />
                  </div>
                  <Button
                    style={{
                      backgroundColor: "#f53b62",
                      color: "#fff",
                      fontFamily: "segoeuiB",
                      width: "20%",
                      display: "flex",
                      justifyContent: "center",
                      height: 20,
                    }}
                    size="small"
                    onClick={() => {
                      let data = {
                        sendObj: createNewRatingSetting,
                        setCreateNewRatingSetting,
                      };
                      createRatingSettingsFunc(data);
                    }}
                  >
                    Gönder
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <AddCircleOutline
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCreateNewRatingSetting({
                    task_type: "",
                    count: "",
                    features: [],
                  });
                }}
              />
            </div>
          )}
        </Paper>
      </div>
      <TextChoicesModal
        textChoicesModal={textChoicesModal}
        setTextChoicesModal={setTextChoicesModal}
        textChoicesModalInfo={textChoicesModalInfo}
        createNewRatingSetting={createNewRatingSetting}
        setCreateNewRatingSetting={setCreateNewRatingSetting}
      />
      <TextChoicesModalv2
        textChoicesModalv2={textChoicesModalv2}
        setTextChoicesModalv2={setTextChoicesModalv2}
        textChoicesModalInfo={textChoicesModalInfo}
        ratingSettingListObj={ratingSettingListObj}
        setRatingSettingListObj={setRatingSettingListObj}
      />
    </>
  );
};

export default PuantajSettings;
