import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BaskiModal from "./BaskiModal";
import { ManagementContext } from "../../../../../contexts/managementContext";
import InfoIcon from "@mui/icons-material/Info";

export default function Baski() {
  const [openBaskiModal, setOpenBaskiModal] = useState(false);
  const { getBaskiTypes, baskiTypes } = useContext(ManagementContext);
  const [formData, setFormData] = useState({
    printing_type_title: "",
    grammages: [],
    sizes: [],
    quantities: [],
    directions: [],
  });

  useEffect(() => {
    getBaskiTypes();
  }, []);

  const handleAddNewBaski = () => {
    setOpenBaskiModal(true);
  };

  const handleShowBaskiDetail = (res, i) => {
    setFormData(res);
    setOpenBaskiModal(true);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <h2 style={{ margin: 0 }}>Baskılar</h2>
        <Tooltip title="Baskı Tipi Ekle">
          <AddCircleIcon
            style={{ color: "#f53b62", cursor: "pointer", fontSize: 30 }}
            onClick={handleAddNewBaski}
          />
        </Tooltip>
      </div>

      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Baski Tipi Adı</TableCell>
                <TableCell align="center">Gramajlar</TableCell>
                <TableCell align="center">Boyutlar</TableCell>
                <TableCell align="center">Adetler</TableCell>
                <TableCell align="center">Baskı Yönü</TableCell>
                <TableCell align="center">Detay</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {baskiTypes?.map((res, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">
                      {res.printing_type_title}
                    </TableCell>
                    <TableCell align="center">
                      {res.grammages.map((gram, i) => {
                        if (res.grammages.length > 1) {
                          return gram + ", ";
                        } else {
                          return gram;
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {res.sizes.map((size, i) => {
                        if (res.sizes.length > 1) {
                          return size + ", ";
                        } else {
                          return size;
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {res.quantities.map((quantity, i) => {
                        if (res.quantities.length > 1) {
                          return quantity + ", ";
                        } else {
                          return quantity;
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {res.directions.map((direction, i) => {
                        if (res.directions.length > 1) {
                          return direction + ", ";
                        } else {
                          return direction;
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">
                      <InfoIcon
                        style={{ color: "#f53b62", cursor: "pointer" }}
                        onClick={() => handleShowBaskiDetail(res, ind)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <BaskiModal
        openBaskiModal={openBaskiModal}
        setOpenBaskiModal={setOpenBaskiModal}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
}
