import React, { useContext } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

import sepet_takip from "../../utils/assets/sepet_takip.png";
import logo2 from "../../utils/assets/logo_2.png";
import dealer from "../../utils/assets/bayi.png";
import operation from "../../utils/assets/operasyon.png";
import operationManagement from "../../utils/assets/yönetim.png";
import { useHistory } from "react-router-dom";
import { Paths } from "../../utils/constants";
import makeCall from "../../components/call/func/makeCall";

import { useSnackbar } from "notistack";
import { CallContext } from "../../contexts/callContext";

let resInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));

const Main = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { cdrOutboundFunc } = useContext(CallContext);

  const onClickImage = (selected) => {
    if (selected === "/finans") {
      if (
        resInfo.department === "Operasyon Yonetimi" &&
        resInfo.sub_department === "Finans"
      ) {
        history.push("/finans");
      } else {
        history.push("/user-dashboard");
      }
    } else {
      if (
        JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
        "collector"
      ) {
        history.push("/operation/tasks");
      } else if (
        JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
        "call_center"
      ) {
        history.push("/call-center");
      } else if (
        JSON.parse(localStorage.getItem("st-officeuser-info")).role === "tech"
      ) {
        history.push("/operation/tasks");
      } else {
        history.push(selected);
      }
    }
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fff",
      }}
    >
      {/* <div
        style={{ padding: 30 }}
        onClick={() => {
          let sendCallData = {
            restofficial: 4, // zorunlu değil
            application: null, // zorunlu değil
            task: 100, // zorunlu değil
            callee: "905374235738", // zorunlu
            trigger_source: "task", // zorunlu değil
          };
          makeCall(
            enqueueSnackbar,
            closeSnackbar,
            sendCallData,
            cdrOutboundFunc
          );
        }}
      >
        ewqew
      </div> */}
      {/* <div
        style={{ padding: 30 }}
        onClick={() => {
         let sendCallData = {
            restofficial: 4, // zorunlu değil
            application: null, // zorunlu değil
            task: 100, // zorunlu değil
            callee: "905526171376", // zorunlu
            trigger_source: "task", // zorunlu değil
          };
          makeCall(
            enqueueSnackbar,
            closeSnackbar,
            sendCallData,
            cdrOutboundFunc
          );
        }}
      >
        ewqew
      </div> */}
      <Grid
        style={{
          backgroundColor: "#fff",
          backgroundImage: isMatch ? `url(${logo2})` : "none",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundPositionY: "bottom",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh)",
        }}
      >
        <Grid item style={styles.menuContent}>
          <div
            style={{
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="sepettakip"
              src={sepet_takip}
              style={{
                height: 100,
                width: isMatch ? 600 : 350,
                alignItems: "center",
                alignSelf: "center",
              }}
            />
          </div>
          {resInfo?.type === "officeuser" ? (
            <div
              style={{
                ...styles.menuRow,
                marginTop: 45,
              }}
            >
              {resInfo?.department === "Operasyon Yonetimi" ? (
                <img
                  alt="operationyonetimi"
                  src={operationManagement}
                  style={{
                    ...styles.menuImage,
                  }}
                  onClick={() => onClickImage(Paths.operationFinance)}
                />
              ) : (
                <img
                  alt="management"
                  src={operationManagement}
                  style={{
                    ...styles.menuImage,
                    ...styles.disabled,
                  }}
                />
              )}

              <img
                alt="operation"
                src={operation}
                style={{ ...styles.menuImage, marginTop: 35 }}
                onClick={() => onClickImage(Paths.operation)}
              />
            </div>
          ) : (
            <div style={{ ...styles.menuRow, marginTop: 45 }}>
              <img
                alt="dealer"
                src={dealer}
                style={{ ...styles.menuImage }}
                onClick={() => onClickImage(Paths.sellerRegister)}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;

const styles = {
  menuImage: {
    backgroundColor: "transparent",
    height: "auto",
    width: "auto",
    alignItems: "center",
    alignSelf: "center",
    cursor: "pointer",
  },
  menuRow: {
    alignItems: "center",
    alignSelf: "center",
    justifyItems: "center",

    display: "flex",
  },
  menuContent: {
    display: "flex",
    background: "transparant",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 120,
    marginBottom: 240,
  },
  disabled: {
    color: "#666",
    opacity: 0.4,
  },
};
