import React, { useContext, useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputBase,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useToast } from "../../../components/snackbar";
import { DevelopmentContext } from "../../../contexts/developmentContext";
import AddIcon from "@mui/icons-material/Add";
import { developmentService } from "../../../services/developmentService";
import FolderIcon from "@mui/icons-material/Folder";
import CancelIcon from "@mui/icons-material/Cancel";

let priorities = [
  {
    id: 1,
    name: 1,
  },
  {
    id: 2,
    name: 2,
  },
  {
    id: 3,
    name: 3,
  },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function AddSubTaskModal({
  openAddSubTask,
  setOpenAddSubTask,
  taskDetail,
  developmentUserList,
}) {
  const { alert } = useToast();
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState(null);
  const { addSubTask } = useContext(DevelopmentContext);
  const [formData, setFormData] = useState({
    header: "",
    note: "",
  });
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [color, setColor] = useColor("#561ecb");
  const [loaderFile, setLoaderFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [fileObjects, setFileObjects] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [objectNames, setObjectNames] = useState([]);

  const handleSaveSubTask = () => {
    if (!selectedPriority) {
      alert("Lütfen bir öncelik seçiniz.");
      return;
    }

    if (formData.header === "" || formData.note === "") {
      alert("Lütfen tüm alanları doldurunuz.");
      return;
    }

    const userArr = [];
    if (selectedUser) {
      userArr.push(selectedUser);
    }

    const data = {
      users: userArr.length > 0 ? userArr : [],
      priority: selectedPriority.id,
      color: color.hex,
      title: formData.header,
      note: formData.note,
      file_addresses: fileObjects,
    };

    addSubTask(taskDetail.id, data);

    setOpenAddSubTask(false);
    setFormData({
      header: "",
      note: "",
    });
    setSelectedPriority(null);
    setSelectedUser(null);
  };
  const onChange = async (e, i) => {
    setLoaderFile(true);

    const filesCopy = [...files];
    filesCopy[i] = e.target.files[0];

    const currentFileUrl = fileUrls[i];
    const currentObjectName = objectNames[i];

    const res = await fetch(currentFileUrl, {
      method: "PUT",
      headers: {
        "x-amz-acl": "public-read",
      },
      body: e.target.files[0],
    });

    if (res.status === 200) {
      setLoaderFile(false);

      setFileObjects([...fileObjects, currentObjectName]);

      setFiles(filesCopy);
    }
  };

  const handleAddFile = async () => {
    const response = await developmentService.get_file_url();
    const newFileUrl = response?.url;
    const newObjectName = response?.object_name;

    setFiles([...files, null]);

    setFileUrls([...fileUrls, newFileUrl]);
    setObjectNames([...objectNames, newObjectName]);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openAddSubTask}
      className={classes.modal}
      onClose={() => {
        setOpenAddSubTask(false);
        setFormData({
          header: "",
          note: "",
        });
        setSelectedPriority(null);
        setSelectedUser(null);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "50%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            padding: 15,
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
            display: "flex",
          }}
        >
          <div style={{ marginRight: 20, width: "20%" }}>
            <span
              style={{
                fontSize: 16,
                marginBottom: 5,
                display: "block",
                fontWeight: "600",
              }}
            >
              Başlık
            </span>
            <InputBase
              style={{
                background: "#f4f4f4",
                padding: 6,
                fontSize: 16,
                fontWeight: "400",
                height: 36,
                fontFamily: "segoeuiRg",
              }}
              value={formData.header}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  header: e.target.value,
                });
              }}
            />
          </div>

          <div style={{ width: "80%", marginRight: 20 }}>
            <span
              style={{
                fontSize: 16,
                marginBottom: 5,
                display: "block",
                fontWeight: "600",
              }}
            >
              Not
            </span>
            <InputBase
              style={{
                background: "#f4f4f4",
                padding: 6,
                height: 36,
                fontSize: 16,
                fontWeight: "400",
                fontFamily: "segoeuiRg",
                width: "100%",
              }}
              value={formData.note}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  note: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div
          style={{
            padding: 15,
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginRight: 20,
              paddingRight: 20,
              borderRight: "1px solid rgba(191,191,191,0.4)",
            }}
          >
            <span
              style={{
                fontSize: 16,
                marginBottom: 5,
                display: "block",
                fontWeight: "600",
              }}
            >
              Kişi Ekle
            </span>

            <div style={{ maxHeight: 250, overflow: "auto" }}>
              {developmentUserList.map((user, index) => {
                return (
                  <div key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={user.user_id}
                          checked={
                            user.user_id === parseInt(selectedUser)
                              ? true
                              : false
                          }
                        />
                      }
                      onChange={(e) => {
                        if (user.user_id === parseInt(selectedUser)) {
                          setSelectedUser(null);
                        } else {
                          setSelectedUser(e.target.value);
                        }
                      }}
                      label={user.full_name}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div
            style={{
              width: 200,
              marginRight: 20,
              paddingRight: 20,
              borderRight: "1px solid rgba(191,191,191,0.4)",
            }}
          >
            <span
              style={{
                fontSize: 16,
                marginBottom: 5,
                display: "block",
                fontWeight: "600",
              }}
            >
              Öncelik Gir
            </span>
            <Select
              menuPosition="fixed"
              menuPortalTarget={document.body}
              responsiveHeight={44}
              selectFontSize={16}
              options={priorities}
              styles={selectDefaultStyles}
              isSearchable={false}
              zIndex={90}
              hoverColor={"#f53b62"}
              menuColor={"#f53b62"}
              placeholder={"Öncelik"}
              value={selectedPriority}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSelectedPriority(e);
              }}
              getOptionLabel={(e) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {e.name}
                </div>
              )}
            />

            <Button
              disabled={loaderFile}
              variant="outline"
              size="small"
              style={{
                fontFamily: "segoeuiRg",
                fontWeight: 500,
                backgroundColor: "#f53b62",
                color: "#fff",
                marginBottom: 5,
                width: "100%",
                marginTop: 10,
              }}
              onClick={handleAddFile}
            >
              {loaderFile ? (
                <CircularProgress
                  style={{ color: "#fff", width: 20, height: 20 }}
                />
              ) : (
                <>
                  <AddIcon />
                  Dosya Ekle
                </>
              )}
            </Button>

            <div style={{ maxHeight: 120, overflow: "auto", paddingRight: 10 }}>
              {files?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <input
                    name="file"
                    type="file"
                    accept="image/jpeg,image/png,application/pdf,image/x-eps,video/mp4,video/x-m4v,video/*"
                    onChange={(e) => onChange(e, index)}
                  />

                  <CancelIcon
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => {
                      const filesCopy = [...files];
                      filesCopy.splice(index, 1);
                      setFiles(filesCopy);

                      const fileUrlsCopy = [...fileUrls];
                      fileUrlsCopy.splice(index, 1);
                      setFileUrls(fileUrlsCopy);

                      const objectNamesCopy = [...objectNames];
                      objectNamesCopy.splice(index, 1);
                      setObjectNames(objectNamesCopy);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div>
            <span
              style={{
                display: "block",
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: color.hex,
              }}
            >
              Renk Gir
            </span>
            <ColorPicker
              color={color}
              onChange={setColor}
              hideInput={["rgb", "hsv"]}
              hideAlpha
              height={100}
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            size="small"
            style={{
              fontFamily: "segoeuiB",
              width: "50%",
              fontWeight: 700,
              backgroundColor: "red",
              marginRight: 20,
            }}
            onClick={() => {
              setOpenAddSubTask(false);
              setFormData({
                header: "",
                note: "",
              });
              setSelectedPriority(null);
              setSelectedUser(null);
            }}
          >
            Reddet
          </Button>

          <Button
            disabled={loaderFile}
            variant="contained"
            size="small"
            style={{
              fontFamily: "segoeuiB",
              width: "50%",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
            }}
            onClick={handleSaveSubTask}
          >
            Onayla
          </Button>
        </div>
      </Card>
    </Modal>
  );
}
