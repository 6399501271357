import React, { useState } from "react";
import { Box, Button, Modal, createTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import managementService from "../../../services/managementService";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

export default function DeletePackage({
  showModal,
  setShowModal,
  title,
  selectDeletedItem,
  setSelectedPackageProducts,
}) {
  const classes = useStyles();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDelete = async () => {
    if (selectDeletedItem.products) {
      await managementService.delete_package(selectDeletedItem.id);
      setShowModal(false);
    } else {
      await managementService.delete_product(
        selectDeletedItem.package,
        selectDeletedItem.id
      );
      setShowModal(false);
      const res = await managementService.get_package_products(
        selectDeletedItem.package
      );
      setSelectedPackageProducts(res);
    }
  };

  return (
    <Modal
      open={showModal}
      className={classes.modal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <Box
        sx={{
          background: "#fff",
          ...styles.modal,
          width: matches ? "40%" : "90%",
        }}
      >
        <h3 style={{ textAlign: "center" }}>{title}</h3>

        <Box sx={{ display: "flex" }}>
          <Button
            style={{
              backgroundColor: "#f53f62",
              color: "#fff",
              fontFamily: "segoeuiRg",
              width: 200,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              height: 40,
              textTransform: "capitalize",
              marginLeft: "auto",
            }}
            size="small"
            onClick={handleDelete}
          >
            Evet
          </Button>
          <Button
            style={{
              backgroundColor: "#f53f62",
              color: "#fff",
              fontFamily: "segoeuiRg",
              width: 200,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              height: 40,
              textTransform: "capitalize",
              marginLeft: "auto",
            }}
            size="small"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Hayır
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
