import { Box, Button, Modal } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import RegistrationField from "../../../../../components/registrationField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
import { selectDefaultStyles } from "../../../../../components/selectStyles/stylev2";
import { ManagementContext } from "../../../../../contexts/managementContext";
import { useToast } from "../../../../../components/snackbar";
import { set } from "date-fns";

const eInvoices = [
  {
    value: true,
    label: "Evet",
  },
  {
    value: false,
    label: "Hayır",
  },
];

export default function PrintUserModal({
  openPrintUserModal,
  setOpenPrintUserModal,
  formData,
  setFormData,
  selectedCity,
  setSelectedCity,
  selectedTown,
  setSelectedTown,
  selectedNeigh,
  setSelectedNeigh,
  isInvoices,
  setIsInvoices,
}) {
  const { getCity, cities, getTown, town, getNeighborhood, neigh } =
    useContext(RestaurantContext);
  const { createPrintingPressUser, editPrintingPressUser } =
    useContext(ManagementContext);
  const { alert } = useToast();

  useEffect(() => {
    getCity();
  }, []);

  useEffect(() => {
    if (selectedCity != null) {
      getTown(selectedCity.id);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedTown != null) {
      getNeighborhood(selectedCity.id, selectedTown?.id);
    }
  }, [selectedTown]);

  const customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const handleSavePrintUser = () => {
    if (formData.username === "" || formData.password === "") {
      alert("Kullanıcı adı ve şifre boş bırakılamaz.");
      return;
    }
    if (formData?.user?.id) {
      const newFormData = {
        ...formData,
        first_name: formData.first_name,
        last_name: formData.last_name,
        user: {
          ...formData.user,
          first_name: formData.first_name,
          last_name: formData.last_name,
        },
      };
      editPrintingPressUser(newFormData);
    } else {
      createPrintingPressUser(formData);
    }
  };

  return (
    <Modal
      open={openPrintUserModal}
      onClose={() => {
        setOpenPrintUserModal(false);
        setFormData({
          first_name: "",
          last_name: "",
          identity_number: "",
          company_title: "",
          tax_no: "",
          tax_authority: "",
          phone_number: "",
          constant_phone: "",
          billing_email: "",
          billing_city: null,
          billing_town: null,
          billing_neighborhood_id: null,
          billing_address: "",
          bank_name: "",
          account_name: "",
          iban: "",
          efatura_mukellef: null,
          username: "",
          password: "",
        });
        setSelectedCity(null);
        setSelectedTown(null);
        setSelectedNeigh(null);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, maxHeight: 550, overflow: "auto" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {formData?.user?.id ? "Matbaa Düzenle" : "Matbaa Ekle"}
        </h2>
        <div
          style={{
            width: "100%",
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Yetkili Adı"}
                fieldValue={formData?.first_name}
                fieldName={"first_name"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Yetkili Soyadı"}
                fieldValue={formData?.last_name}
                fieldName={"last_name"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"TC-Kimlik No"}
                fieldValue={formData?.identity_number}
                fieldName={"identity_number"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Ticari Ünvan (Fatura Ünvanı)"}
                fieldValue={formData?.company_title}
                fieldName={"company_title"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Vergi No"}
                fieldValue={formData?.tax_no}
                fieldName={"tax_no"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Vergi Dairesi"}
                fieldValue={formData?.tax_authority}
                fieldName={"tax_authority"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <div>
                <span
                  style={{
                    color: "#293c51",
                    fontFamily: "segoeuiRg",
                    fontSize: 15,
                    fontWeight: 700,
                    marginLeft: 8,
                  }}
                >
                  Cep No
                </span>
                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#f4f4f4",
                    height: 45,
                    marginTop: 10,
                    borderRadius: 6,
                    marginRight: 14,
                  }}
                >
                  <PhoneInput
                    countryCodeEditable={false}
                    country="tr"
                    onlyCountries={["tr"]}
                    localization={{ tr: "Türkiye" }}
                    value={formData?.phone_number}
                    containerStyle={{ height: 45 }}
                    inputStyle={{
                      height: 45,
                      width: "100%",
                      fontSize: 15,
                      fontWeight: "400",
                      fontFamily: "segoeuiRg",
                    }}
                    onChange={(phone) => {
                      setFormData({ ...formData, phone_number: phone });
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <div>
                <span
                  style={{
                    color: "#293c51",
                    fontFamily: "segoeuiRg",
                    fontSize: 15,
                    fontWeight: 700,
                    marginLeft: 8,
                  }}
                >
                  Sabit No
                </span>
                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#f4f4f4",
                    height: 45,
                    marginTop: 10,
                    borderRadius: 6,
                    marginRight: 14,
                  }}
                >
                  <PhoneInput
                    countryCodeEditable={false}
                    country="tr"
                    onlyCountries={["tr"]}
                    localization={{ tr: "Türkiye" }}
                    value={formData?.constant_phone}
                    containerStyle={{ height: 45 }}
                    inputStyle={{
                      height: 45,
                      width: "100%",
                      fontSize: 15,
                      fontWeight: "400",
                      fontFamily: "segoeuiRg",
                    }}
                    onChange={(phone) => {
                      setFormData({ ...formData, constant_phone: phone });
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"E-Posta"}
                fieldValue={formData?.billing_email}
                fieldName={"billing_email"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                İl Seçimi
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={cities}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3}
                  value={selectedCity || ""}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setSelectedCity(e);
                    setFormData({
                      ...formData,
                      billing_city: e.id,
                      billing_town: null,
                      billing_neighborhood_id: null,
                    });
                    setSelectedTown(null);
                    setSelectedNeigh(null);
                  }}
                  filterOption={customFilter}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.name}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                İlçe Seçimi
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={town}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  value={selectedTown || ""}
                  dynamiczIndex={2}
                  getOptionValue={(option) => option.id}
                  filterOption={customFilter}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      billing_town: e.id,
                      billing_neighborhood_id: null,
                    });
                    setSelectedTown(e);
                    setSelectedNeigh(null);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.name}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <span
                style={{
                  fontFamily: "segoeuiRg",
                  color: "#293c51",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                  marginBottom: 10,
                }}
              >
                Mahalle Seçimi
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={neigh}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  value={selectedNeigh || ""}
                  dynamiczIndex={1}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setFormData({ ...formData, billing_neighborhood_id: e.id });
                    setSelectedNeigh(e);
                  }}
                  filterOption={customFilter}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.name}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "100%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Adres"}
                fieldValue={formData?.billing_address}
                fieldName={"billing_address"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Banka Adı"}
                fieldValue={formData?.bank_name}
                fieldName={"bank_name"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Banka Hesap Adı"}
                fieldValue={formData?.account_name}
                fieldName={"account_name"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"İban No"}
                fieldValue={formData?.iban}
                fieldName={"iban"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <span
                style={{
                  fontFamily: "segoeuiRg",
                  color: "#293c51",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                  marginBottom: 10,
                }}
              >
                E-Fatura Mükellefi mi?
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={eInvoices}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  value={isInvoices}
                  dynamiczIndex={1}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setFormData({ ...formData, efatura_mukellef: e.value });
                    setIsInvoices(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.label}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Kullanıcı Adı"}
                fieldValue={formData?.username}
                fieldName={"username"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div style={{ width: "33%", marginBottom: 10 }}>
              <RegistrationField
                fieldHeader={"Şifre"}
                fieldValue={formData?.password}
                fieldName={"password"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 20,
            marginTop: 10,
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
              width: 200,
            }}
            onClick={handleSavePrintUser}
          >
            Kaydet
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    width: "70%",
  },
};
