import {
  Box,
  Button,
  createTheme,
  InputBase,
  Modal,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev2";
import { useContext, useEffect, useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";

export default function TaskLockModal({
  openTaskLockModal,
  setOpenTaskLockModal,
  dashboardOfficeuserDetail,
  startDate,
  endDate,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const modalRef = useRef();
  const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);
  const [phones, setPhones] = useState([]);
  const { dashboardOfficeuserDetailTaskLock } = useContext(ManagementContext);

  useEffect(() => {
    if (dashboardOfficeuserDetail && dashboardOfficeuserDetail.user_id) {
      setSelectedTaskTypes(
        dashboardOfficeuserDetail.locked_task_types.filter((item) => {
          if (item.locked) {
            return item;
          }
        })
      );

      setPhones(dashboardOfficeuserDetail.unlock_code_phones);
    }
  }, [dashboardOfficeuserDetail]);

  const handleChangeTaskType = (e, i) => {
    setSelectedTaskTypes(e);
  };

  const handleAddPhone = () => {
    setPhones([...phones, ""]);
  };

  const handleDeletePhone = (i) => {
    let newPhones = [...phones];
    newPhones.splice(i, 1);
    setPhones(newPhones);
  };

  const handleSendTaskLock = () => {
    let data = {
      user_id: dashboardOfficeuserDetail.user_id,
      obj: {
        locked_task_types: selectedTaskTypes.map((item) => item.id),
        unlock_code_phones: phones,
      },
    };

    let data2 = {
      user_id: dashboardOfficeuserDetail?.user_id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };

    dashboardOfficeuserDetailTaskLock(data, data2);
  };

  return (
    <Modal
      open={openTaskLockModal}
      onClose={() => {
        setOpenTaskLockModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}
        ref={modalRef}
      >
        <h2
          id="child-modal-title"
          style={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "monospace",
          }}
        >
          Görev Tipi Onay Ayarları
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "600px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Select
              menuPortalTarget={document.body}
              styles={{
                ...selectDefaultStyles,
                fontFamily: "monospace",
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              options={dashboardOfficeuserDetail?.locked_task_types}
              responsiveHeight={"auto"}
              selectFontSize={14}
              menuColor={"#f4f4f4"}
              hoverColor={"#f53b62"}
              darkMode={true}
              isSearchable={false}
              selectWidth={"100%"}
              inputColor={"#f4f4f4"}
              textColor={"black"}
              placeHolderColor={"#f53b62"}
              isMulti
              inputFontSize={14}
              dropdownColor={"#f53b62"}
              isClearable={false}
              closeMenuOnSelect={true}
              placeholder="Görev Tipi"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={selectedTaskTypes}
              onChange={(e) => handleChangeTaskType(e)}
            />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 10,
              }}
            >
              <Tooltip title="Telefon Ekle">
                <AddCircleOutlineIcon
                  onClick={handleAddPhone}
                  style={{ cursor: "pointer", color: "#f53f62", fontSize: 30 }}
                />
              </Tooltip>
            </div>

            {phones.map((phone, i) => {
              return (
                <div
                  key={i}
                  style={{
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Box style={{ marginRight: 10, width: "100%" }}>
                    <span
                      style={{
                        fontSize: 16,
                        marginBottom: 10,
                        display: "block",
                        fontWeight: "600",
                        fontFamily: "monospace",
                      }}
                    >
                      Telefon {i + 1}
                    </span>
                    <PhoneInput
                      countryCodeEditable={false}
                      country="tr"
                      onlyCountries={["tr"]}
                      localization={{ tr: "Türkiye" }}
                      value={phone}
                      containerStyle={{ height: 45 }}
                      inputStyle={{
                        height: 45,
                        width: "100%",
                        fontSize: 15,
                        fontWeight: "400",
                        fontFamily: "monospace",
                      }}
                      onChange={(e) => {
                        let newPhones = [...phones];
                        newPhones[i] = e;
                        setPhones(newPhones);
                      }}
                    />
                  </Box>

                  <Tooltip title="Telefonu Sil">
                    <DeleteIcon
                      style={{
                        cursor: "pointer",
                        color: "#f53f62",
                        fontSize: 30,
                        marginBottom: 10,
                      }}
                      onClick={() => handleDeletePhone(i)}
                    />
                  </Tooltip>
                </div>
              );
            })}
          </div>

          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              width: "100%",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
              marginTop: 20,
            }}
            onClick={handleSendTaskLock}
          >
            Kaydet
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
