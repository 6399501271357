import React, { useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import EftInfoModal from "../../../../components/modals/eftInfoModal";
import AcceptEftModal from "../../../../components/modals/acceptEftModal";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

const EftControl = ({ eftList, eftChangeFunc, page, setPage }) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [eftInfoModal, setEftInfoModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(false);
  const [acceptEftModal, setAcceptEftModal] = useState(false);
  const [isPre, setIsPre] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <Grid container columns={12} spacing={2} sx={{ marginBottom: 4 }}>
            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <h3
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    fontFamily: "segoeuiL",
                    fontSize: 18,
                  }}
                >
                  Toplam Ön Ödeme
                </h3>
                <span
                  style={{
                    display: "block",
                    textAlign: "right",
                    fontSize: 16,
                    marginLeft: 5,
                    fontFamily: "segoeuiL",
                  }}
                >
                  {new Intl.NumberFormat("tr-TR").format(
                    eftList?.total.toplam_on_odeme
                  )}
                </span>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <h3
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    fontFamily: "segoeuiL",
                    fontSize: 18,
                  }}
                >
                  Toplam Kalan Ödeme
                </h3>
                <span
                  style={{
                    display: "block",
                    textAlign: "right",
                    fontSize: 16,
                    marginLeft: 5,
                    fontFamily: "segoeuiL",
                  }}
                >
                  {new Intl.NumberFormat("tr-TR").format(
                    eftList?.total.toplam_kalan_odeme
                  )}
                </span>
              </Paper>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Oluşturulma tarihi</TableCell>
                  <TableCell align="center">Restoran Id</TableCell>
                  <TableCell align="center">Restoran</TableCell>
                  <TableCell align="center">Tüm Tutar</TableCell>
                  <TableCell align="center">Ön Ödeme</TableCell>
                  <TableCell align="center">Ön Ödeme Aksiyon</TableCell>
                  <TableCell align="center">Kalan Ödeme</TableCell>
                  <TableCell align="center">Kalan Gün</TableCell>
                  <TableCell align="center">Durum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eftList?.results?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {row?.offer?.restofficial?.user_id}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "blue",
                        }}
                        onClick={() => {
                          setEftInfoModal(true);
                          setCurrentRow(row);
                        }}
                      >
                        {row?.offer?.restofficial?.name}
                      </TableCell>
                      <TableCell align="center">
                        ₺ {row?.offer?.total_amount}
                      </TableCell>
                      <TableCell align="center">
                        ₺ {row?.down_payment} / ₺ {row?.check_payment}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <GreenSwitch
                          onClick={() => {
                            setIsPre(true);
                            setAcceptEftModal(true);
                            setCurrentRow(row);
                          }}
                          checked={row?.checked}
                        />
                      </TableCell>
                      <TableCell align="center">
                        ₺ {row?.dept?.toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.offer?.remaining_day != 0
                          ? row?.offer?.remaining_day + " gün"
                          : "Popup sürecinde"}
                      </TableCell>

                      <TableCell align="center">
                        {row?.offer?.payed ? (
                          <span style={{ color: "green" }}>Online Ödeme</span>
                        ) : (
                          <span style={{ color: "red" }}>Ödenmedi</span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  {eftList != null && (
                    <TablePagination
                      rowsPerPageOptions={[5]}
                      labelRowsPerPage={"Sayfa Başına Kayıt"}
                      count={eftList?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      classes={{ root: classes.root }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <EftInfoModal
        eftInfoModal={eftInfoModal}
        setEftInfoModal={setEftInfoModal}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
      <AcceptEftModal
        isPre={isPre}
        acceptEftModal={acceptEftModal}
        setAcceptEftModal={setAcceptEftModal}
        eftChangeFunc={eftChangeFunc}
        currentRow={currentRow}
      />
    </>
  );
};

export default EftControl;
