import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import EftControl from "./eftControl";
import PrimControl from "./primControl";
import ProductPrices from "./productPrices";

import { RestaurantContext } from "../../../contexts/restaurantContext";
import { ManagementContext } from "../../../contexts/managementContext";

import PropTypes from "prop-types";
import SmsSettings from "./smsSettings";
import CurrentList from "./currentList";
import OfferChange from "./offerChange";
import BaskiRequestsFinance from "./baskiRequestsFinance";
import DailyPayment from "./dailyPayment";
import PrimSettingsV2 from "./primSettingsv2";
import ReferencePayments from "./referencePayments";
import ReferencePayed from "./referencePayments/referencePayed";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

const EftPrimControl = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [innerValue, setInnerValue] = useState(0);
  const { eftListFunc, eftList, eftChangeFunc } = useContext(RestaurantContext);
  const { getPaidableUsers, primResult } = useContext(ManagementContext);
  const [page, setPage] = useState(0);
  const [togglePrim, setTogglePrim] = useState(false);

  const handlePrimControl = () => {
    setTogglePrim(!togglePrim);
  };

  useEffect(() => {
    getPaidableUsers(togglePrim);
  }, [togglePrim]);

  useEffect(() => {
    eftListFunc({ page: page + 1 });
  }, [page]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeInnerTab = (event, newValue) => {
    setInnerValue(newValue);
    eftListFunc({ page: page + 1 });
  };

  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          className={classes.tabs}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Prim Ayarları" {...a11yProps(0)} />
          <Tab label="Eft Kontrol" {...a11yProps(1)} />
          <Tab label="Prim Kontrol" {...a11yProps(2)} />
          <Tab label="Referans Ödemeleri" {...a11yProps(3)} />
          <Tab label="Ürün Fiyatları" {...a11yProps(4)} />
          <Tab label="Sms Ayarları" {...a11yProps(5)} />
          <Tab label="Cari Ayarları" {...a11yProps(6)} />
          <Tab label="Komisyon Ödemeleri" {...a11yProps(7)} />
          <Tab label="Baskı İstekleri" {...a11yProps(8)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <PrimSettingsV2 />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div style={{ textAlign: "center" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={innerValue}
              onChange={handleChangeInnerTab}
              centered
              className={classes.tabs}
            >
              <Tab label="Eft" {...a11yProps(0)} />
              <Tab label="Teklif" {...a11yProps(0)} />
            </Tabs>
            <TabPanel value={innerValue} index={0}>
              <EftControl
                eftList={eftList}
                eftChangeFunc={eftChangeFunc}
                page={page}
                setPage={setPage}
              />
            </TabPanel>
            <TabPanel value={innerValue} index={1}>
              <OfferChange />
            </TabPanel>
          </Box>
        </div>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <PrimControl
          primResult={primResult}
          handlePrimControl={handlePrimControl}
          togglePrim={togglePrim}
        />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <div style={{ textAlign: "center" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={innerValue}
              onChange={handleChangeInnerTab}
              centered
              className={classes.tabs}
            >
              <Tab label="Ödeme Beklenen" {...a11yProps(0)} />
              <Tab label="Ödenen" {...a11yProps(0)} />
            </Tabs>
            <TabPanel value={innerValue} index={0}>
              <ReferencePayments />
            </TabPanel>
            <TabPanel value={innerValue} index={1}>
              <ReferencePayed />
            </TabPanel>
          </Box>
        </div>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <ProductPrices />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <SmsSettings />
      </TabPanel>

      <TabPanel value={value} index={6}>
        <CurrentList />
      </TabPanel>

      <TabPanel value={value} index={7}>
        <DailyPayment />
      </TabPanel>

      <TabPanel value={value} index={8}>
        <BaskiRequestsFinance />
      </TabPanel>
    </div>
  );
};

export default EftPrimControl;
