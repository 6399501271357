import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import ProductsModalSetup from "./ProductsModalSetup";

let list = [];

const showInfo = (row, x) => {
  return (
    <Box>
      <p>Kişi: {row?.full_name}</p>
      <p>Gelen Teklif Müşteri / Adet: {row?.offer[x]?.total_count}</p>
      <p>Gelen Teklif Tutar: {row?.offer[x]?.total_amount} ₺</p>
      <p>Dokunulmayan Data: {row?.offer[x]?.not_touched_count}</p>
      <p>İşlemde: {row?.offer[x]?.processing_count}</p>
      <p>Sayaçta: {row?.offer[x]?.stayed_count}</p>
      <p>MMA1 ADET / DOKUNULAN: {row?.offer[x]?.mma1_count}</p>
      <p>MMA2 ADET / DOKUNULAN: {row?.offer[x]?.mma2_count}</p>
      <p>POP UP ADET / DOKUNULAN: {row?.offer[x]?.popup_count}</p>
      <p>
        PASİF ADET / DOKUNULAN: {row?.offer[x]?.passive_count} / %
        {row?.offer[x]?.passive_percent}
      </p>
      <p>
        Çapraz Satış: {row?.multi_sale[x]?.total_count} / %
        {row?.multi_sale[x]?.percent}
      </p>
      <p>
        Bekleyen Teklif Adet: {row?.payment[x]?.total_count} / Ort Gün:{" "}
        {row?.payment[x]?.average_remaining_days?.toFixed(2)} / Alınacak bakiye:{" "}
        {row?.payment[x]?.total_amount} ₺
      </p>
      <p>
        Ön Ödeme Adet: {row?.remaining_payment[x]?.total_count} / Ort Gün:{" "}
        {row?.remaining_payment[x]?.average_remaining_days?.toFixed(2)} / kalan
        bakiye: {row?.remaining_payment[x]?.total_amount} ₺
      </p>
      <p>Bloke: {row?.restoff[x]?.blocked}</p>
      <p>
        Pasif Ödemiş: {row?.restoff[x]?.passive_payed} / %
        {row?.restoff[x]?.passive_payed_percent}
      </p>
      <p>
        Aktif Ödemiş: {row?.restoff[x]?.active_payed} / %
        {row?.restoff[x]?.active_payed_percent}
      </p>
      <p>
        Pasif Ödememiş: {row?.restoff[x]?.passive_not_payed} / %
        {row?.restoff[x]?.passive_not_payed_percent}
      </p>
      <p>Toplam Tahsilat: {row?.restoff[x]?.total_amount} ₺</p>
    </Box>
  );
};

export default function KpiCollector({ kpiCollector }) {
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [kpiCollectorList, setKpiCollectorList] = useState(null);

  const handleShowProducts = (row) => {
    setOpenModal(true);
    setProducts(row.products);
  };

  useEffect(() => {
    if (kpiCollector) {
      list = [];
      kpiCollector.forEach((row, ind) => {
        list.push({
          user: row.full_name,
          multi_sale: row?.multi_sale?.map((r1) => {
            return {
              value: r1.application_type,
              label: r1.application_type,
            };
          }),
        });
      });
      setKpiCollectorList(kpiCollector);
    }
  }, [kpiCollector]);

  return (
    <Box>
      <h3 style={{ textAlign: "center" }}>KPI TAHSİLATÇI RAPORLARI</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Kişi
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Başvuru Tipi
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Gelen Teklif Müşteri / Adet
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Gelen Teklif Tutar
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Dokunulmayan Data
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Sayaçta
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                MMA1 Adet / Dokunulan
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                MMA2 Adet / Dokunulan
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                POP UP ADET / DOKUNULAN
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                PASİF ADET / DOKUNULAN
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ürünler
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Çapraz Satış %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Bekleyen Teklif Adet / Ort Gün / alınacak bakiye
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ön Ödeme adet / Ort Gün / kalan bakiye
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Bloke %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Pasif Ödememiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Aktif Ödemiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Pasif Ödemiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Toplam Tahsilat
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kpiCollectorList?.map((row, ind) => {
              return list[ind]?.multi_sale?.map((item, i) => {
                const x = row.multi_sale.findIndex(
                  (rowItem) => rowItem.application_type === item.value
                );
                return (
                  <TableRow key={i}>
                    <TableCell align="center">{row?.full_name}</TableCell>
                    <Tooltip title={showInfo(row, x)}>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.label} <InfoIcon style={{ color: "#293c51" }} />
                      </TableCell>
                    </Tooltip>
                    <TableCell align="center">
                      {row?.offer[x]?.total_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer[x]?.total_amount} ₺
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer[x]?.not_touched_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer[x]?.stayed_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer[x]?.mma1_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer[x]?.mma2_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer[x]?.popup_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer[x]?.passive_count} / %
                      {row?.offer[x]?.passive_percent}
                    </TableCell>
                    <TableCell align="center">
                      <InfoIcon
                        style={{ cursor: "pointer", color: "#f53b62" }}
                        onClick={() => handleShowProducts(row)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {row?.multi_sale[x]?.total_restofficial_count} /{" "}
                      {row?.multi_sale[x]?.total_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.payment[x]?.total_count} /{" "}
                      {row?.payment[x]?.average_remaining_days?.toFixed(2)} /{" "}
                      {row?.payment[x]?.total_amount} ₺
                    </TableCell>
                    <TableCell align="center">
                      {row?.remaining_payment[x]?.total_count} /{" "}
                      {row?.remaining_payment[
                        x
                      ]?.average_remaining_days?.toFixed(2)}{" "}
                      / {row?.remaining_payment[x]?.total_amount} ₺
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.blocked} /{" "}
                      {row?.restoff[x]?.blocked_percent} %
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.passive_not_payed} /{" "}
                      {row?.restoff[x]?.passive_not_payed_percent} %
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.active_payed} /{" "}
                      {row?.restoff[x]?.active_payed_percent} %
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.passive_payed} /{" "}
                      {row?.restoff[x]?.passive_payed_percent} %
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.total_amount} ₺
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ProductsModalSetup
        openModal={openModal}
        setOpenModal={setOpenModal}
        products={products}
      />
    </Box>
  );
}
