import React, { useState, useContext } from "react";
//Mui
import { Card, Tooltip, ClickAwayListener, Button } from "@mui/material";
//Modals
import OtherAddressModal from "../modals/otherAdressModal";
import { RestaurantContext } from "../../../../../../contexts/restaurantContext";

function CustomerInfo({ enduserDetail, id }) {
  const [openToolTip, setOpenToolTip] = useState(false);
  const [openToolTipAdresDes, setOpenToolTipAdresDes] = useState(false);
  const [openOtherAddressModal, setOpenOtherAddressModal] = useState(false);
  const {
    restaurantEnduserCashBackUptFunc,
    setSepetPayAmound,
    sepetPayAmound,
  } = useContext(RestaurantContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginRight: 15,
          }}
        >
          <Card
            sx={{
              width: 400,
              padding: 2,
              borderRadius: 2,
              border: "1px solid #e0e0e0",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 7,
                fontFamily: "segoeuiB",
                color: "#f53b62",
              }}
            >
              BİLGİLER
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    width: 100,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  İsim :
                </label>
                <label
                  style={{
                    width: 100,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  Telefon :
                </label>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    width: 120,
                    fontFamily: "segoeuiB",
                    fontSize: 16,
                  }}
                >
                  {enduserDetail?.full_name}
                </label>
                <label
                  style={{
                    width: 120,
                    fontFamily: "segoeuiB",
                    fontSize: 16,
                  }}
                >
                  {enduserDetail?.phone}
                </label>
              </div>
            </div>
          </Card>
          <Card
            sx={{
              width: 400,
              padding: 2,
              marginTop: 2,
              position: "relative",
              borderRadius: 2,
              border: "1px solid #e0e0e0",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 7,
                color: "#f53b62",
                fontFamily: "segoeuiB",
              }}
            >
              GEÇERLİ ADRES
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 36,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  Adres :
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  Adres Açıklama :
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  Bina No :
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  Kat :
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  Daire No :
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  İlçe :
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontSize: 16,
                  }}
                >
                  Şehir :
                </label>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {enduserDetail?.current_address?.address.length > 12 ? (
                  <ClickAwayListener onClickAway={() => setOpenToolTip(false)}>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 16,
                            fontFamily: "segoeuiB",
                            padding: 1,
                            bgcolor: "#082657",
                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                      title={enduserDetail?.current_address?.address}
                      arrow
                      placement="top"
                      open={openToolTip}
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontFamily: "segoeuiB",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenToolTip(true);
                        }}
                      >
                        {enduserDetail?.current_address?.address}
                      </div>
                    </Tooltip>
                  </ClickAwayListener>
                ) : (
                  <label
                    style={{
                      width: 150,
                      marginBottom: 2,
                      fontFamily: "segoeuiB",
                      fontSize: 16,
                    }}
                  >
                    {enduserDetail?.current_address?.address}
                  </label>
                )}
                {enduserDetail?.current_address?.description.length > 12 ? (
                  <ClickAwayListener
                    onClickAway={() => setOpenToolTipAdresDes(false)}
                  >
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 16,
                            fontFamily: "segoeuiB",
                            padding: 1,
                            bgcolor: "#082657",
                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                      title={enduserDetail?.current_address?.description}
                      arrow
                      placement="top"
                      open={openToolTipAdresDes}
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontFamily: "segoeuiB",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenToolTipAdresDes(true);
                        }}
                      >
                        {enduserDetail?.current_address?.description}
                      </div>
                    </Tooltip>
                  </ClickAwayListener>
                ) : (
                  <label
                    style={{
                      width: 125,
                      marginBottom: 2,
                      fontFamily: "segoeuiB",
                      fontSize: 16,
                    }}
                  >
                    {enduserDetail?.current_address?.description !== ""
                      ? enduserDetail?.current_address?.description
                      : "-"}
                  </label>
                )}
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontFamily: "segoeuiB",
                    fontSize: 16,
                  }}
                >
                  {enduserDetail?.current_address?.building_no !== ""
                    ? enduserDetail?.current_address?.building_no
                    : "-"}
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontFamily: "segoeuiB",
                    fontSize: 16,
                  }}
                >
                  {enduserDetail?.current_address?.floor !== ""
                    ? enduserDetail?.current_address?.floor
                    : "-"}
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontFamily: "segoeuiB",
                    fontSize: 16,
                  }}
                >
                  {enduserDetail?.current_address?.door_number !== ""
                    ? enduserDetail?.current_address?.door_number
                    : "-"}
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontFamily: "segoeuiB",
                    fontSize: 16,
                  }}
                >
                  {enduserDetail?.current_address?.town !== ""
                    ? enduserDetail?.current_address?.town
                    : "-"}
                </label>
                <label
                  style={{
                    width: 125,
                    marginBottom: 2,
                    fontFamily: "segoeuiB",
                    fontSize: 16,
                  }}
                >
                  {enduserDetail?.current_address?.city !== ""
                    ? enduserDetail?.current_address?.city
                    : "-"}
                </label>
              </div>
            </div>
            <Button
              variant="text"
              size="medium"
              color="error"
              style={{
                borderBottom: "1px solid #f53b62",
                position: "absolute",
                bottom: 10,
                right: 20,
                textTransform: "capitalize",
              }}
              onClick={() => setOpenOtherAddressModal(true)}
            >
              Diğer Adresler
            </Button>
          </Card>
        </div>
        <Card
          sx={{
            width: 355,
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 120,
            borderRadius: 2,
            border: "1px solid #e0e0e0",
          }}
        >
          <h2
            style={{
              color: "#f53b62",
              fontFamily: "segoeuiB",
              textAlign: "center",
              fontSize: 18,
              borderBottom: "1px solid #e0e0e0",
              marginBottom: 7,
              width: "100%",
            }}
          >
            SEPETPAY
          </h2>
          <div
            style={{
              fontSize: 16,
              fontFamily: "segoeuiB",
            }}
          >
            <span
              style={{
                color: "#f53b62",
                fontFamily: "segoeuiB",
                fontSize: 18,
              }}
            >
              {enduserDetail?.cashback} ₺{" "}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontFamily: "segoeuiB",
                marginRight: 5,
              }}
            >
              Tutar :
            </div>
            <input
              value={sepetPayAmound}
              style={{
                padding: 8,
                border: "1px solid #000",
                textAlign: "center",
                borderRadius: 4,
                fontSize: 14,
                width: "30%",
              }}
              onChange={(e) => {
                let re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  if (e.target.value === "") {
                    setSepetPayAmound(0);
                  } else {
                    setSepetPayAmound(parseFloat(e.target.value));
                  }
                }
              }}
            />
            <span
              style={{
                fontSize: 16,
                marginLeft: 5,
              }}
            >
              ₺
            </span>
            <Button
              variant="contained"
              size="medium"
              color="error"
              style={{
                alignSelf: "center",
                borderRadius: 8,
                fontFamily: "SgNr",
                fontSize: 16,
                textAlign: "center",
                textTransform: "capitalize",
                boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                marginLeft: 10,
              }}
              onClick={() => {
                if (sepetPayAmound !== 0) {
                  restaurantEnduserCashBackUptFunc({
                    enduser_id: enduserDetail?.user_id,
                    amount: sepetPayAmound,
                    restofficial_id: id,
                  });
                }
              }}
              disabled={sepetPayAmound === null || sepetPayAmound === 0}
            >
              Ekle
            </Button>
          </div>
        </Card>
      </div>
      <OtherAddressModal
        enduserDetail={enduserDetail?.addresses}
        setOpenOtherAddressModal={setOpenOtherAddressModal}
        openOtherAddressModal={openOtherAddressModal}
      />
    </>
  );
}

export default CustomerInfo;
