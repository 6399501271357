export const selectDefaultStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.selectProps.menuColor,
    borderRadius: 6,
    width: state.selectProps.selectWidth,
    color: state.selectProps.color ? state.selectProps.color : "#fff",
    border: 0,
    cursor: "pointer",
    boxShadow: "none",
    //height: 25,
    minHeight: 25,
  }),
  container: (styles, state) => ({
    ...styles,
    width: state.selectProps.selectWidth,
  }),
  placeholder: (styles, state) => {
    return {
      ...styles,
      color: state.selectProps.color,
      fontFamily: "segoeuiRg",
      fontSize: 15,
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.selectProps.color,
    padding: state.selectProps.arrowPadding
      ? state.selectProps.arrowPadding
      : 8,
  }),
  indicatorSeparator: (styles, { isFocused, isSelected }) => ({
    ...styles,
    display: "none",
  }),
  input: (styles) => ({
    ...styles,
    '[type="text"]': {
      color: "white !important",
    },
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: state.selectProps.color,
    fontFamily: "segoeuiRg",
    fontSize: 15,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isDisabled ? "#f4f4f4" : "baige",
      color: "black",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      ":active": {
        ...styles[":active"],
        backgroundColor: "red",
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: state.isDisabled
          ? "none"
          : state.selectProps.hoverColor,
        color: state.isDisabled ? "black" : "white",
      },
    };
  },
};
