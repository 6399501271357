import React, { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../../contexts/managementContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import TablePaginationActions from "../../../../../components/pagination/mainPagination";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import BaskiRequestModal from "../../../../../components/modals/baskiRequestModal";
import RequestStatusesModal from "../../../../../components/modals/requestStatuses";
import BrochureModal from "../../../../../components/modals/brochure";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

export default function BaskiRequest() {
  const classes = useStyles();
  const {
    getPrintingRequestList,
    printingRequestList,
    getPrintingRequestDetail,
    getBaskiTypes,
    baskiTypes,
    brochurePicture,
    setBrochurePicture,
    setBrochureResResult,
  } = useContext(ManagementContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openBaskiRequestModal, setOpenBaskiRequestModal] = useState(false);
  const [openRequestStatuses, setOpenRequestStatuses] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [brochureModal, setBrochureModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getBaskiTypes();
  }, []);

  useEffect(() => {
    getPrintingRequestList(page + 1, "");
  }, [page]);

  const handleShowRequestStatuses = (row) => {
    setOpenRequestStatuses(true);
    setSelectedRequest(row);
  };

  return (
    <div>
      <span
        style={{
          fontSize: 20,
          marginBottom: 10,
          display: "block",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Baskı İstekleri
      </span>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Teslimat Adresi</TableCell>
              <TableCell align="center">Oluşturulma Tarihi</TableCell>
              <TableCell align="center">Restoran Adı</TableCell>
              <TableCell align="center">Oluşturan Kişi</TableCell>
              <TableCell align="center">Matbaa</TableCell>
              <TableCell align="center">Baskı Tipi</TableCell>
              <TableCell align="center">Gramaj</TableCell>
              <TableCell align="center">Boyut</TableCell>
              <TableCell align="center">Adet</TableCell>
              <TableCell align="center">Baskı Yönü</TableCell>
              <TableCell align="center">Broşür</TableCell>
              <TableCell align="center">Detay</TableCell>
              <TableCell align="center">Durum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printingRequestList?.results?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    <Tooltip title={row.cargo_delivery_address}>
                      <InfoIcon style={{ color: "#f53b62" }} />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    {moment(row.created_at).format("DD.MM.YYYY HH:mm")}
                  </TableCell>
                  <TableCell align="center">{row.restofficial.name}</TableCell>
                  <TableCell align="center">
                    {row.officeuser.full_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.printingpress.company_title}
                  </TableCell>
                  <TableCell align="center">
                    {row.printing_type.printing_type_title}
                  </TableCell>
                  <TableCell align="center">{row.grammage}</TableCell>
                  <TableCell align="center">{row.size}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">{row.direction}</TableCell>
                  <TableCell align="center">
                    <PictureAsPdfIcon
                      onClick={() => {
                        setBrochureResResult(null);
                        setBrochureModal(true);
                        setCurrentRow(row);
                      }}
                      style={{ color: "#f53b62", cursor: "pointer" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <InfoIcon
                      style={{ color: "#f53b62", cursor: "pointer" }}
                      onClick={() => {
                        getPrintingRequestDetail(row.id);
                        setOpenBaskiRequestModal(true);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      cursor:
                        row.status_changes.length > 0 ? "pointer" : "auto",
                    }}
                    onClick={() => {
                      if (row.status_changes.length > 0) {
                        handleShowRequestStatuses(row);
                      }
                    }}
                  >
                    {row.request_status === "waiting_confirm"
                      ? "Matbaa Onay Bekliyor"
                      : row.request_status === "waiting_offer"
                      ? "Matbaa'ya yönlendirildi"
                      : row.request_status === "created"
                      ? "Baskı İsteği Oluşturuldu"
                      : row.request_status === "confirm" &&
                        row.is_document_downloaded
                      ? "PDF Baskıda"
                      : row.request_status === "confirm" &&
                        !row.is_document_downloaded
                      ? "Finans Onayladı"
                      : row.request_status === "shipped"
                      ? "Kargoya Verildi"
                      : row.request_status === "delivered"
                      ? "Teslim Edildi"
                      : row.request_status === "checking"
                      ? "Teslim Edildi"
                      : row.request_status === "complated"
                      ? "Teslim Edildi"
                      : row.request_status === "unapproved"
                      ? "Matbaa Onay Bekleniyor"
                      : ""}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {printingRequestList?.results != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={printingRequestList?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <BaskiRequestModal
        openBaskiRequestModal={openBaskiRequestModal}
        setOpenBaskiRequestModal={setOpenBaskiRequestModal}
        baskiTypes={baskiTypes}
        printingRequestList={printingRequestList}
        brochurePicture={brochurePicture}
        setBrochurePicture={setBrochurePicture}
        page={page}
      />

      <RequestStatusesModal
        openRequestStatuses={openRequestStatuses}
        setOpenRequestStatuses={setOpenRequestStatuses}
        selectedRequest={selectedRequest}
      />

      <BrochureModal
        brochureModal={brochureModal}
        setBrochureModal={setBrochureModal}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
    </div>
  );
}
