import {
  Box,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import InputField from "../../../../components/input";
import { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import EditIcon from "@mui/icons-material/Edit";
import { useToast } from "../../../../components/snackbar";
import DeleteIcon from "@mui/icons-material/Delete";

export default function SmsSettings() {
  const {
    sendSmsSetting,
    getSmsSettings,
    smsSettingsList,
    editSmsSetting,
    deleteSmsSetting,
  } = useContext(ManagementContext);
  const [formData, setFormData] = useState({
    header: "",
    content: "",
    video_link: "",
    id: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const { alert } = useToast();

  useEffect(() => {
    getSmsSettings();
  }, []);

  const handleChangeSmsInfo = (row) => {
    setFormData({
      header: row.header,
      content: row.content,
      video_link: row.video_link,
      id: row.id,
    });
    setIsEditing(true);
  };

  const handleDeleteSmsInfo = (row) => {
    deleteSmsSetting(row.id);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <FormControl variant="outlined" sx={{ marginBottom: 2 }}>
          <InputField
            label="Sms Başlık"
            fieldValue={formData?.header || ""}
            fieldName={"header"}
            formData={formData}
            setFormData={setFormData}
          />
        </FormControl>

        <FormControl variant="outlined" sx={{ marginBottom: 2 }}>
          <InputField
            label="Sms İçerik"
            fieldValue={formData?.content || ""}
            fieldName={"content"}
            formData={formData}
            setFormData={setFormData}
          />
        </FormControl>

        <FormControl variant="outlined" sx={{ marginBottom: 2 }}>
          <InputField
            label="Video Link"
            fieldValue={formData?.video_link || ""}
            fieldName={"video_link"}
            formData={formData}
            setFormData={setFormData}
          />
        </FormControl>

        {isEditing ? (
          <Box>
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                width: 160,
                fontWeight: 700,
                backgroundColor: "red",
                marginRight: 20,
              }}
              onClick={() => {
                setFormData({
                  header: "",
                  content: "",
                  video_link: "",
                });
                setIsEditing(false);
              }}
            >
              Vazgeç
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                width: 160,
                fontWeight: 700,
                backgroundColor: "#0FBD20",
              }}
              onClick={() => {
                editSmsSetting(formData);
                setFormData({
                  header: "",
                  content: "",
                  video_link: "",
                });
                setIsEditing(false);
              }}
            >
              Düzenle
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              width: 160,
              fontWeight: 700,
              backgroundColor: "#0FBD20",
            }}
            onClick={() => {
              if (!formData.header || !formData.content || !formData.video_link)
                return alert("Lütfen tüm alanları doldurunuz.");

              sendSmsSetting(formData);
              setFormData({
                header: "",
                content: "",
                video_link: "",
              });
            }}
          >
            Kaydet
          </Button>
        )}
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Sms Başlık</TableCell>
                <TableCell align="center">Sms İçerik</TableCell>
                <TableCell align="center">Video Link</TableCell>
                <TableCell align="center">Aksiyon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {smsSettingsList?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">{row.header}</TableCell>
                    <TableCell align="center">{row.content}</TableCell>
                    <TableCell align="center">{row.video_link}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Düzenle">
                        <EditIcon
                          style={{
                            color: "#f53f62",
                            cursor: "pointer",
                            marginRight: 20,
                          }}
                          onClick={() => handleChangeSmsInfo(row)}
                        />
                      </Tooltip>

                      <Tooltip title="Sil">
                        <DeleteIcon
                          style={{ color: "#f53f62", cursor: "pointer" }}
                          onClick={() => handleDeleteSmsInfo(row)}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
