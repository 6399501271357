import { Box, Button, InputBase, Modal, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import RegistrationField from "../../../../../components/registrationField";
import { ManagementContext } from "../../../../../contexts/managementContext";

export default function BaskiModal({
  openBaskiModal,
  setOpenBaskiModal,
  formData,
  setFormData,
}) {
  const { createNewBaski, editBaskiDetail } = useContext(ManagementContext);

  const handleAddNewGramaj = () => {
    setFormData({
      ...formData,
      grammages: [...formData.grammages, 0],
    });
  };

  const handleAddNewSize = () => {
    setFormData({
      ...formData,
      sizes: [...formData.sizes, 0],
    });
  };

  const handleAddNewQuantities = () => {
    setFormData({
      ...formData,
      quantities: [...formData.quantities, 0],
    });
  };

  const handleAddNewDirections = () => {
    setFormData({
      ...formData,
      directions: [...formData.directions, ""],
    });
  };

  const handleSaveBaskiType = () => {
    if (formData?.id) {
      editBaskiDetail(formData);
    } else {
      createNewBaski(formData);
    }
    setOpenBaskiModal(false);
  };

  return (
    <Modal
      open={openBaskiModal}
      onClose={() => {
        setOpenBaskiModal(false);
        setFormData({
          printing_type_title: "",
          grammages: [],
          sizes: [],
          quantities: [],
          directions: [],
        });
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, maxHeight: 550, overflow: "auto" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Baskı Tipi Ekle
        </h2>
        <div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <RegistrationField
              fieldHeader={"Baskı Tipi Adı"}
              fieldValue={formData?.printing_type_title}
              fieldName={"printing_type_title"}
              formData={formData}
              setFormData={setFormData}
            />
          </div>

          <div
            style={{
              padding: 10,
              background: "#e7e7e7",
              marginBottom: 10,
              borderRadius: 4,
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#f53b62",
                width: 150,
                textTransform: "Capitalize",
              }}
              onClick={handleAddNewGramaj}
            >
              Gramaj Ekle
            </Button>
            {formData.grammages.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                {formData.grammages.map((item, i) => {
                  return (
                    <div style={{ width: "30%", marginRight: 10 }} key={i}>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        Gramaj
                      </span>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "#fff",
                          height: 45,
                          marginTop: 10,
                          marginBottom: 0,
                          borderRadius: 6,
                          marginRight: 14,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: 8,
                          }}
                        >
                          <InputBase
                            variant="filled"
                            style={{
                              width: "100%",
                              color: "black",
                              marginLeft: 14,
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 45,
                            }}
                            value={item}
                            onChange={(e) => {
                              let newArr = [...formData.grammages];
                              newArr[i] = e.target.value;
                              setFormData({
                                ...formData,
                                grammages: newArr,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div
            style={{
              padding: 10,
              background: "#e7e7e7",
              marginBottom: 10,
              borderRadius: 4,
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#f53b62",
                width: 150,
                textTransform: "Capitalize",
              }}
              onClick={handleAddNewSize}
            >
              Boyut Ekle
            </Button>

            {formData.sizes.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                {formData.sizes.map((item, i) => {
                  return (
                    <div style={{ width: "30%", marginRight: 10 }} key={i}>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        Boyut
                      </span>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "#fff",
                          height: 45,
                          marginTop: 10,
                          marginBottom: 0,
                          borderRadius: 6,
                          marginRight: 14,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: 8,
                          }}
                        >
                          <InputBase
                            variant="filled"
                            style={{
                              width: "100%",
                              color: "black",
                              marginLeft: 14,
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 45,
                            }}
                            value={item}
                            onChange={(e) => {
                              let newArr = [...formData.sizes];
                              newArr[i] = e.target.value;
                              setFormData({
                                ...formData,
                                sizes: newArr,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div
            style={{
              padding: 10,
              background: "#e7e7e7",
              marginBottom: 10,
              borderRadius: 4,
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#f53b62",
                width: 150,
                textTransform: "Capitalize",
              }}
              onClick={handleAddNewQuantities}
            >
              Adet Ekle
            </Button>

            {formData.quantities.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                {formData.quantities.map((item, i) => {
                  return (
                    <div style={{ width: "30%", marginRight: 10 }} key={i}>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        Adet
                      </span>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "#fff",
                          height: 45,
                          marginTop: 10,
                          marginBottom: 0,
                          borderRadius: 6,
                          marginRight: 14,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: 8,
                          }}
                        >
                          <InputBase
                            variant="filled"
                            style={{
                              width: "100%",
                              color: "black",
                              marginLeft: 14,
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 45,
                            }}
                            value={item}
                            onChange={(e) => {
                              let newArr = [...formData.quantities];
                              newArr[i] = e.target.value;
                              setFormData({
                                ...formData,
                                quantities: newArr,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div
            style={{
              padding: 10,
              background: "#e7e7e7",
              marginBottom: 10,
              borderRadius: 4,
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#f53b62",
                width: 150,
                textTransform: "Capitalize",
              }}
              onClick={handleAddNewDirections}
            >
              Baskı Yönü Ekle
            </Button>
            {formData.directions.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                {formData.directions.map((item, i) => {
                  return (
                    <div style={{ width: "30%", marginRight: 10 }} key={i}>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        Baskı Yönü
                      </span>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "#fff",
                          height: 45,
                          marginTop: 10,
                          marginBottom: 0,
                          borderRadius: 6,
                          marginRight: 14,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: 8,
                          }}
                        >
                          <InputBase
                            variant="filled"
                            style={{
                              width: "100%",
                              color: "black",
                              marginLeft: 14,
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 45,
                            }}
                            value={item}
                            onChange={(e) => {
                              let newArr = [...formData.directions];
                              newArr[i] = e.target.value;
                              setFormData({
                                ...formData,
                                directions: newArr,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
              width: 200,
            }}
            onClick={handleSaveBaskiType}
          >
            Kaydet
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    width: "70%",
  },
};
