import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputBase,
  Modal,
  TextField,
} from "@mui/material";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { useToast } from "../../snackbar";
import { selectDefaultStyles } from "../../selectStyles/style";
import restaurantService from "../../../services/restaurantService";
import { DevelopmentContext } from "../../../contexts/developmentContext";
import AddIcon from "@mui/icons-material/Add";
import { developmentService } from "../../../services/developmentService";
import { GreenSwitch } from "../../switch/greenSwitch";

const animatedComponents = makeAnimated();

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const NewDevelopmentRequest = ({
  newDevelopmentRequest,
  setNewDevelopmentRequest,
  request_type,
  res_id,
  product,
  is_global,
  optionalProducts,
}) => {
  const classes = useStyles();
  const { developmentRequestFunc } = useContext(DevelopmentContext);

  const [list, setList] = useState({
    name: null,
    id: null,
  });
  const [note, setNote] = useState("");
  const [noteHeader, setNoteHeader] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [selectedDevelopmentProduct, setSelectedDevelopmentProduct] =
    useState(null);
  const { alert, success } = useToast();
  const [links, setLinks] = useState([]);
  const [files, setFiles] = useState([]);
  const [checked, setChecked] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [fileObjects, setFileObjects] = useState([]);
  const [loaderFile, setLoaderFile] = useState(false);

  const handleRestaurantChange = (searchQuery) => {
    return new Promise((resolve) => {
      restaurantService
        .restofficial_search({ search: searchQuery })
        .then((response) => {
          const value = response?.map((item) => ({
            value: item.user_id,
            label: `${item?.name || ""}`,
          }));
          resolve(value);
        });
    });
  };

  const onChange = async (e, i) => {
    setLoaderFile(true);
    const filesCopy = [...files];
    filesCopy[i] = e.target.files[0];

    const res = await fetch(fileUrl, {
      method: "PUT",
      headers: {
        "x-amz-acl": "public-read",
      },
      body: e.target.files[0],
    });

    if (res.status === 200) {
      setLoaderFile(false);
      setFileObjects([...fileObjects, objectName]);
    }
    // setFiles(filesCopy);
  };

  const handleAddFile = async () => {
    await developmentService.get_file_url().then((response) => {
      setFileUrl(response?.url);
      setObjectName(response?.object_name);
    });
    setFiles([...files, null]);
  };

  const handleAddLink = () => {
    setLinks([...links, ""]);
  };

  const isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={newDevelopmentRequest}
      className={classes.modal}
      componentsProps={{
        backdrop: { style: { top: 60 } },
        root: { style: { top: 60 } },
      }}
      onClose={() => {
        setNote("");
        setSelectedDevelopmentProduct(null);
        setIsGlobal(false);
        setNewDevelopmentRequest(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "60%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: "#f53b62",
            marginBottom: 15,
            fontSize: 18,
          }}
        >
          Geliştirme Kayıt
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginBottom: 15,
          }}
        >
          <div style={{ marginRight: 15 }}>
            Global
            <GreenSwitch
              onClick={() => {
                setIsGlobal(!isGlobal);
                setList({
                  name: null,
                  id: null,
                });
              }}
              checked={isGlobal ? true : false}
            />
          </div>

          <AsyncSelect
            isDisabled={isGlobal ? true : false}
            responsiveHeight={30}
            selectFontSize={16}
            zIndex={999}
            hoverColor={isGlobal ? "gray" : "#f53b62"}
            menuColor={isGlobal ? "gray" : "#f53b62"}
            selectWidth={200}
            placeholder={"Restoran Seçimi"}
            styles={selectDefaultStyles}
            value={{
              label: (
                <span
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 15,
                    fontWeight: 600,
                  }}
                >
                  {list?.name || "Restoran Seçimi"}
                </span>
              ),
              value: list?.id || -1,
            }}
            components={animatedComponents}
            onChange={(e) => {
              setList(() => ({
                name: e?.label,
                id: e?.value,
              }));
            }}
            loadOptions={handleRestaurantChange}
            isClearable={list?.name != null ? true : false}
          />
        </div>

        <div style={{ display: "flex", marginBottom: 15 }}>
          <div style={{ width: "65%" }}>
            <div style={{ marginBottom: 20 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Başlık
              </span>
              <TextField
                style={{ width: "100%" }}
                placeholder="Başlık"
                multiline
                maxRows={5}
                value={noteHeader}
                onChange={(e) => {
                  setNoteHeader(e.target.value);
                }}
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Not ekle
              </span>
              <TextField
                style={{ width: "100%" }}
                placeholder="Not ekle"
                multiline
                minRows={3}
                maxRows={5}
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  backgroundColor: "#f4f4f4",
                }}
                rows={2}
                placeholder={"Başlık ekle"}
                value={noteHeader}
                onChange={(e) => {
                  setNoteHeader(e.target.value);
                }}
              />
            </div> */}
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  backgroundColor: "#f4f4f4",
                }}
                rows={4}
                placeholder={"Not ekle"}
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div> */}
          </div>

          <div style={{ width: "35%", marginLeft: 20 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  />
                }
                label="Hata Talebi"
              />
            </FormGroup>

            <Button
              disabled={loaderFile}
              variant="outline"
              size="small"
              style={{
                fontFamily: "segoeuiRg",
                fontWeight: 500,
                color: "#f53b62",
                marginBottom: 5,
                width: "100%",
                height: 30,
                border: "1px solid #f53b62",
              }}
              onClick={handleAddFile}
            >
              {loaderFile ? (
                <CircularProgress
                  style={{ color: "#f53b62", width: 20, height: 20 }}
                />
              ) : (
                <>
                  <AddIcon />
                  Dosya Ekle
                </>
              )}
            </Button>

            <div
              style={{
                maxHeight: 120,
                overflow: "auto",
                paddingRight: 10,
                marginBottom: 10,
              }}
            >
              {files.map((file, i) => {
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        name="file"
                        type="file"
                        accept="image/jpeg,image/png,application/pdf,image/x-eps,video/mp4,video/x-m4v,video/*"
                        onChange={(e) => onChange(e, i)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <Button
              variant="outline"
              size="small"
              style={{
                fontFamily: "segoeuiRg",
                fontWeight: 500,
                color: "#f53b62",
                width: "100%",
                height: 30,
                border: "1px solid #f53b62",
              }}
              onClick={handleAddLink}
            >
              <AddIcon />
              Link Ekle
            </Button>

            <div style={{ maxHeight: 150, overflow: "auto", paddingRight: 10 }}>
              {links.map((link, i) => {
                return (
                  <div>
                    <span
                      style={{
                        fontSize: 16,
                        marginBottom: 5,
                        display: "block",
                        fontWeight: "600",
                      }}
                    >
                      Link
                    </span>
                    <InputBase
                      style={{
                        background: "#f4f4f4",
                        padding: 6,
                        fontSize: 16,
                        fontWeight: "400",
                        height: 30,
                        fontFamily: "segoeuiRg",
                        width: "100%",
                      }}
                      value={link}
                      onChange={(e) => {
                        const linksCopy = [...links];
                        linksCopy[i] = e.target.value;
                        setLinks(linksCopy);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            disabled={loaderFile}
            style={{
              backgroundColor: "#f53b62",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: 32,
              marginLeft: 10,
            }}
            size="small"
            onClick={() => {
              if (!isGlobal && list?.id === null) {
                alert("Restoran seçiniz.");
              } else if (isBlank(noteHeader)) {
                alert("Başlık giriniz.");
              } else if (isBlank(note)) {
                alert("Not giriniz.");
              } else {
                // const formData = new FormData();
                let formData = {};
                if (links.length > 0) {
                  formData.links = links;
                }
                if (fileObjects.length > 0) {
                  formData.file_addresses = fileObjects;
                }
                formData.restofficial = isGlobal ? "" : list?.id;
                if (selectedDevelopmentProduct) {
                  formData.product = selectedDevelopmentProduct?.id;
                }
                formData.note = note;
                formData.header = noteHeader;
                formData.is_global = isGlobal;
                formData.error_request = checked;

                let data = {
                  setIsGlobal,
                  setSelectedDevelopmentProduct,
                  setNote,
                  setNewDevelopmentRequest,
                  setList,
                  request_type,
                  res_id,
                  is_global,
                  product,
                  setNoteHeader,
                  search: "",
                };

                developmentRequestFunc(data, formData);
              }
            }}
          >
            {loaderFile ? (
              <CircularProgress
                style={{ color: "#fff", width: 20, height: 20 }}
              />
            ) : (
              "Gönder"
            )}
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default NewDevelopmentRequest;
