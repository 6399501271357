import React, { createContext, useEffect, useState } from "react";
import { useToast } from "../components/snackbar";
import callService from "../services/callService";
export const CallContext = createContext();

let phoneNumberv2 = "";
export let callkey;

const CallProvider = (props) => {
  const { alert, success } = useToast();

  const [cdrMissedCount, setCdrMissedCount] = useState(null);
  const [cdrSummaryList, setCdrSummaryList] = useState(null);

  const [cdrMissedListObj, setCdrMissedListObj] = useState([]);
  const [callLoader, setCallLoader] = useState(false);
  const [puantajVoice, setPuantajVoice] = useState(null);

  useEffect(() => {
    missedCallCountFunc();
  }, []);

  const missedCallCountFunc = () => {
    cdrMissedCountFunc();
    setInterval(() => cdrMissedCountFunc(), 60000);
  };

  const cdrOutboundFunc = (data) => {
    callService
      .cdr_outbound(data)
      .then((response) => {})
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrInboundFunc = (data) => {
    callService
      .cdr_inbound(data)
      .then((response) => {})
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrAnswerFunc = (data) => {
    callService
      .cdr_answer(data)
      .then((response) => {})
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrHangupFunc = (data) => {
    callService
      .cdr_hangup(data)
      .then((response) => {})
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrMissedListFunc = (data) => {
    setCallLoader(true);
    callService
      .cdr_missed_list(data)
      .then((response) => {
        setCallLoader(false);
        const grouped = {};

        response.forEach((item) => {
          if (grouped[item.caller]) {
            grouped[item.caller].times.push(item.start_at);
            if (item.restofficial !== null) {
              grouped[item.caller].restofficial = item.restofficial;
            }
          } else {
            grouped[item.caller] = { ...item, times: [item.start_at] };
            delete grouped[item.caller].start_at;
            if (item.restofficial !== null) {
              grouped[item.caller].restofficial = item.restofficial;
            }
          }
        });

        setCdrMissedListObj(Object.values(grouped));
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrMissedCountFunc = () => {
    callService
      .cdr_missed_count()
      .then((response) => {
        setCdrMissedCount(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrSummaryListFunc = (data) => {
    callService
      .cdr_summary_list(data)
      .then((response) => {
        setCdrSummaryList(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrRecordingListFunc = (data) => {
    callService
      .cdr_recording(data)
      .then((response) => {
        setPuantajVoice(response?.recording_url);
        const cdrSummaryListObj = Object.assign(
          [],
          JSON.parse(JSON.stringify({ ...cdrSummaryList }))
        );
        let voiceIndex = cdrSummaryListObj.findIndex(
          (item) => item.id === data.record_id
        );
        if (voiceIndex !== -1) {
          cdrSummaryListObj[voiceIndex].voice = response?.recording_url;
          setCdrSummaryList(cdrSummaryListObj);
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrInboundListFunc = (data) => {
    setCallLoader(true);
    callService
      .cdr_inbound_list(data)
      .then((response) => {
        setCallLoader(false);
        const grouped = {};

        response.forEach((item) => {
          if (grouped[item.callee]) {
            grouped[item.callee].times.push(item.start_at);
            if (item.restofficial !== null) {
              grouped[item.callee].restofficial = item.restofficial;
            }
          } else {
            grouped[item.callee] = { ...item, times: [item.start_at] };
            delete grouped[item.callee].start_at;
            if (item.restofficial !== null) {
              grouped[item.callee].restofficial = item.restofficial;
            }
          }
        });

        setCdrMissedListObj(Object.values(grouped));
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrOutboundListFunc = (data) => {
    setCallLoader(true);
    callService
      .cdr_outbound_list(data)
      .then((response) => {
        setCallLoader(false);
        const grouped = {};
        response.forEach((item) => {
          if (grouped[item.caller]) {
            grouped[item.caller].times.push(item.start_at);
            if (item.restofficial !== null) {
              grouped[item.caller].restofficial = item.restofficial;
            }
          } else {
            grouped[item.caller] = { ...item, times: [item.start_at] };
            delete grouped[item.caller].start_at;
            if (item.restofficial !== null) {
              grouped[item.caller].restofficial = item.restofficial;
            }
          }
        });
        setCdrMissedListObj(Object.values(grouped));
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const allUsersIqcellVerimorFunc = (data) => {
    callService
      .all_user_iqcell_verimor(data)
      .then((response) => {
        success("Tüm kullanıcıların durumu değiştirildi", 3500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  return (
    <CallContext.Provider
      value={{
        cdrOutboundFunc,
        cdrInboundFunc,
        cdrAnswerFunc,
        cdrHangupFunc,
        phoneNumberv2,
        cdrMissedListFunc,
        cdrMissedCountFunc,
        cdrMissedCount,
        cdrSummaryListFunc,
        cdrRecordingListFunc,
        cdrSummaryList,
        cdrOutboundListFunc,
        cdrInboundListFunc,
        allUsersIqcellVerimorFunc,
        cdrMissedListObj,
        setCdrMissedListObj,
        callLoader,
        puantajVoice,
      }}
    >
      {props.children}
    </CallContext.Provider>
  );
};

export default CallProvider;
