import React, { createContext, useState } from "react";
import { useSnackbar } from "notistack";
import loginService from "../services/loginService";
import { useToast } from "../components/snackbar";
export const LoginContext = createContext();

const LoginProvider = (props) => {
  const { alert, success } = useToast();
  const { enqueueSnackbar } = useSnackbar();
  const [userRole, setUserRole] = useState(null);

  const [loginLoading, setLoginLoading] = useState(false);

  const loginFunc = (data) => {
    setLoginLoading(true);
    loginService
      .login(data)
      .then((response) => {
        if (response.refresh) {
          setLoginLoading(false);
          localStorage.setItem("st-b-access", response.access);
          localStorage.setItem("st-b-refresh", response.refresh);
        }
        officeuserInfoFunc();
      })
      .catch((err) => {
        setLoginLoading(false);
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2500,
        });
      });
  };

  const loginSellerFunc = (data) => {
    setLoginLoading(true);
    loginService
      .login_seller(data)
      .then((response) => {
        if (response.refresh) {
          setLoginLoading(false);
          localStorage.setItem("st-b-access", response.access);
          localStorage.setItem("st-b-refresh", response.refresh);
        }
        officeuserSellerInfoFunc();
      })
      .catch((err) => {
        setLoginLoading(false);
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2500,
        });
      });
  };

  const officeuserInfoFunc = () => {
    loginService
      .officeuser_info()
      .then((response) => {
        success("Giriş Başarılı. Yönlendiriliyorsunuz.");
        localStorage.setItem("st-officeuser-info", JSON.stringify(response));
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const officeuserInfoFuncv2 = () => {
    loginService
      .officeuser_info()
      .then((response) => {
        localStorage.setItem("st-officeuser-info", JSON.stringify(response));
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const officeuserSellerInfoFunc = () => {
    loginService
      .officeuser_seller_info()
      .then((response) => {
        success("Giriş Başarılı. Yönlendiriliyorsunuz.");
        localStorage.setItem("st-officeuser-info", JSON.stringify(response));
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const changePasswordFunc = (data) => {
    loginService.change_password(data).then((response) => {
      if (response?.success) {
        data.setPasswordOld("");
        data.setPasswordNew("");
        data.setPasswordNew2("");
        enqueueSnackbar(response?.response?.message, {
          variant: "success",
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar(
          "Şifre değiştirilemedi.Şifrenin en az 8 karakterli olduğuna emin olunuz.",
          {
            variant: "error",
            preventDuplicate: true,
          }
        );
      }
    });
  };

  const logoutFunc = () => {
    loginService
      .logout()
      .then((response) => {
        localStorage.removeItem("st-b-access");
        localStorage.removeItem("st-b-refresh");

        enqueueSnackbar("Çıkış yaptınız.", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.response.data.message, {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 1500,
        });
      });
  };

  const logoutSellerFunc = () => {
    loginService
      .logout_seller()
      .then((response) => {
        localStorage.removeItem("st-b-access");
        localStorage.removeItem("st-b-refresh");

        enqueueSnackbar("Çıkış yaptınız.", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.response.data.message, {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 1500,
        });
      });
  };

  const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem("st-officeuser-info"));
    if (user) {
      setUserRole(user.role);
    }
  };

  return (
    <LoginContext.Provider
      value={{
        loginLoading,
        changePasswordFunc,
        loginFunc,
        logoutFunc,
        officeuserInfoFunc,
        loginSellerFunc,
        officeuserSellerInfoFunc,
        logoutSellerFunc,
        getUserRole,
        userRole,
        officeuserInfoFuncv2,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
