import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import ProductsModalSetup from "./ProductsModalSetup";

let list = [];

const showInfo = (row, x) => {
  return (
    <Box>
      <p>Kişi: {row?.full_name}</p>
      <p>
        Yönlendirilen / Kurulum Adet / %: {row?.application[x]?.total_count} /{" "}
        {row?.application[x]?.setup_completed_count} /{" "}
        {row?.application[x]?.setup_completed_percent}%
      </p>
      <p>Dokunulmayan Data: {row?.application[x]?.not_touched_count}</p>
      <p>İşlemde: {row?.application[x]?.setup_count}</p>
      <p>Sayaçta: {row?.application[x]?.stayed_count}</p>
      <p>Silinen: {row?.application[x]?.deleted_count}</p>
      <p>Toplam Dokunulan: {row?.application[x]?.setup_completed_count}</p>
      <p>
        Yönlendirilen: {row?.application[x]?.transfered_count} / %
        {row?.application[x]?.transfered_percent}
      </p>
      <p>
        Çapraz Satış: {row?.multi_sale[x]?.total_count} / %
        {row?.multi_sale[x]?.percent}
      </p>
      <p>
        Bekleyen Teklif Adet: {row?.payment[x]?.total_count} / Ort Gün:{" "}
        {row?.payment[x]?.average_remaining_days?.toFixed(2)} / Alınacak bakiye:{" "}
        {row?.payment[x]?.total_amount} ₺
      </p>
      <p>
        Ön Ödeme Adet: {row?.remaining_payment[x]?.total_count} / Ort Gün:{" "}
        {row?.remaining_payment[x]?.average_remaining_days?.toFixed(2)} / kalan
        bakiye: {row?.remaining_payment[x]?.total_amount} ₺
      </p>
      <p>Bloke: {row?.restoff[x]?.blocked}</p>
      <p>
        Pasif Ödemiş: {row?.restoff[x]?.passive_payed} / %
        {row?.restoff[x]?.passive_payed_percent}
      </p>
      <p>
        Aktif Ödemiş: {row?.restoff[x]?.active_payed} / %
        {row?.restoff[x]?.active_payed_percent}
      </p>
      <p>
        Pasif Ödememiş: {row?.restoff[x]?.passive_not_payed} / %
        {row?.restoff[x]?.passive_not_payed_percent}
      </p>
      <p>Toplam Tahsilat: {row?.restoff[x]?.total_amount} ₺</p>
    </Box>
  );
};

export default function KpiSetup({ kpiSetup }) {
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [kpiSetupList, setKpiSetupList] = useState(null);

  const handleShowProducts = (row) => {
    setOpenModal(true);
    setProducts(row.products);
  };

  useEffect(() => {
    if (kpiSetup) {
      list = [];
      kpiSetup.forEach((row, ind) => {
        list.push({
          user: row.full_name,
          application: row?.application?.map((r1) => {
            return {
              value: r1.application_type,
              label: r1.application_type,
            };
          }),
        });
      });
      setKpiSetupList(kpiSetup);
    }
  }, [kpiSetup]);

  return (
    <Box>
      <h3 style={{ textAlign: "center" }}>KPI KURULUM RAPORLARI</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Kişi
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Başvuru Tipi
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Yönlendirilen / Kurulum Adet / %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Dokunulmayan Data
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                İşlemde
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Sayaçta
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Silinen
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ürünler
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Çapraz Satış
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Bekleyen Teklif Adet / Ort Gün / Alınacak bakiye
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ön Ödeme Adet / Ort Gün / Kalan bakiye
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Bloke %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Pasif Ödemiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Aktif Ödemiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Pasif Ödememiş
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Aktif Ödememiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Toplam Tahsilat
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kpiSetupList?.map((row, ind) => {
              return list[ind]?.application?.map((item, i) => {
                const x = row.application.findIndex(
                  (rowItem) => rowItem.application_type === item.value
                );
                return (
                  <TableRow key={i}>
                    <TableCell align="center">{row?.full_name}</TableCell>
                    <Tooltip title={showInfo(row, x)}>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.label} <InfoIcon style={{ color: "#293c51" }} />
                      </TableCell>
                    </Tooltip>
                    <TableCell align="center">
                      {row?.application[x]?.total_count} /{" "}
                      {row?.application[x]?.setup_completed_count} /{" "}
                      {row?.application[x]?.setup_completed_percent}%
                    </TableCell>
                    <TableCell align="center">
                      {row?.application[x]?.not_touched_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.application[x]?.setup_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.application[x]?.stayed_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.application[x]?.deleted_count}
                    </TableCell>
                    <TableCell align="center">
                      <InfoIcon
                        style={{ cursor: "pointer", color: "#f53b62" }}
                        onClick={() => handleShowProducts(row)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {row?.multi_sale[x]?.total_restofficial_count} /{" "}
                      {row?.multi_sale[x]?.total_count}
                    </TableCell>
                    <TableCell align="center">
                      {row?.payment[x]?.total_count} /{" "}
                      {row?.payment[x]?.average_remaining_days?.toFixed(2)} /{" "}
                      {row?.payment[x]?.total_amount}
                    </TableCell>
                    <TableCell align="center">
                      {row?.remaining_payment[x]?.total_count} /{" "}
                      {row?.remaining_payment[
                        x
                      ]?.average_remaining_days?.toFixed(2)}{" "}
                      / {row?.remaining_payment[x]?.total_amount}
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.blocked} /
                      {row?.restoff[x]?.blocked_percent}%
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.passive_payed} /{" "}
                      {row?.restoff[x]?.passive_payed_percent}%
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.active_payed} /{" "}
                      {row?.restoff[x]?.active_payed_percent}%
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.passive_not_payed} /{" "}
                      {row?.restoff[x]?.passive_not_payed_percent}%
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.active_not_payed} /{" "}
                      {row?.restoff[x]?.active_not_payed_percent}%
                    </TableCell>
                    <TableCell align="center">
                      {row?.restoff[x]?.total_amount} ₺
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ProductsModalSetup
        openModal={openModal}
        setOpenModal={setOpenModal}
        products={products}
      />
    </Box>
  );
}
