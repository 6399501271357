import { http } from "./axios";

function edit_development_request(data, id) {
  return http.patch(`/officeuser/development/request/v2/${id}`, data);
}

function create_development_request(data, id) {
  return http.post(`/officeuser/development/request/${id}/action`, data);
}

function reject_development_request(data, id) {
  return http.delete(`/officeuser/development/request/${id}/action`, { data });
}

function development_request_list(data) {
  return http.get(
    `/officeuser/development/request/v2?request_type=${data.request_type}&restaurant=${data.res_id}&is_global=${data?.is_global}&search=${data.search}&id_search=${data.id_search}`
  );
}

function development_request_patch(data) {
  return http.patch(`/officeuser/development/request/${data.id}`, data.sendObj);
}

function development_request(data) {
  return http.post(`/officeuser/development/request/v2`, data);
}

function get_development_process_list(data) {
  return http.get(
    `/officeuser/development/task?request_type=${data.request_type}&restaurant=${data.res_id}&is_global=${data?.is_global}&search=${data.search}&task_group=${data.taskGroup}&id_search=${data.id_search}&page=${data.page}`
  );
}

function get_development_completed_list(data) {
  return http.get(
    `/officeuser/development/task?status=completed&request_type=${data.request_type}&restaurant=${data.res_id}&is_global=${data?.is_global}&search=${data.search}&id_search=${data.id_search}&page=${data.page}`
  );
}

function get_task_detail(id, noteType, requestType) {
  return http.get(
    `/officeuser/development/task/${id}?note_type=${noteType}&request_type=${requestType}`
  );
}

function get_task_statuses(type) {
  return http.get(`/officeuser/development/task/status?type=${type}`);
}

function add_note_to_main_task(id, data) {
  return http.post(`/officeuser/development/task/${id}/note`, data);
}

function add_note_to_sub_task(id, data) {
  return http.post(`/officeuser/development/task/${id}/note`, data);
}

function change_task_status(userId, data) {
  return http.patch(
    `/officeuser/development/task/${userId}/user/status/change`,
    data
  );
}

function add_task_to_user(taskId, data) {
  return http.post(`/officeuser/development/task/${taskId}/job`, data);
}

function change_task_general_status(taskId, data) {
  return http.patch(`officeuser/development/task/${taskId}`, data);
}

function add_sub_task(taskId, data) {
  return http.post(`/officeuser/development/task/${taskId}/subtask`, data);
}

function delete_task(id) {
  return http.delete(`/officeuser/development/task/${id}`);
}

function delete_user_from_task(userId) {
  return http.delete(`/officeuser/development/task/user/${userId}`);
}

function delete_pdf_from_task(taskId, pdfId) {
  return http.delete(`/officeuser/development/task/${taskId}/file/${pdfId}`);
}

function delete_pdf_from_approvement(approveId, pdfId) {
  return http.delete(
    `/officeuser/development/request/${approveId}/file/${pdfId}`
  );
}

function change_sub_task_feauture(id, data) {
  return http.patch(`/officeuser/development/task/${id}`, data);
}

function get_user_authority_list() {
  return http.get(`/officeuser/development/authority/list`);
}

function get_development_user_list() {
  return http.get(
    `/officeuser/development/user/list?authority=process,confirm_process`
  );
}

function add_group_task(data) {
  return http.post(`/officeuser/development/task/group`, data);
}

function get_group_task() {
  return http.get(`/officeuser/development/task/group`);
}

function delete_group_task(id) {
  return http.delete(`/officeuser/development/task/group/${id}`);
}

function edit_group_detail(id, data) {
  return http.patch(`/officeuser/development/task/group/${id}`, data);
}

function get_file_url() {
  return http.get(`/officeuser/s3/file/url`);
}

function delete_note(taskId, noteId) {
  return http.delete(`/officeuser/development/task/${taskId}/note/${noteId}`);
}

export const developmentService = {
  edit_development_request,
  create_development_request,
  development_request_list,
  development_request_patch,
  development_request,
  get_development_process_list,
  reject_development_request,
  get_task_detail,
  get_task_statuses,
  add_note_to_main_task,
  change_task_status,
  add_task_to_user,
  change_task_general_status,
  add_sub_task,
  delete_task,
  delete_user_from_task,
  delete_pdf_from_task,
  change_sub_task_feauture,
  get_development_completed_list,
  get_user_authority_list,
  delete_pdf_from_approvement,
  get_development_user_list,
  add_group_task,
  get_group_task,
  delete_group_task,
  edit_group_detail,
  get_file_url,
  delete_note,
  add_note_to_sub_task,
};
