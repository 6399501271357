import React from "react";

import { platformIconImageConverter } from "../../../../../components/converter/platformImage";

const RenewalPlatforms = ({ summaryRestDetailInfo }) => {
  return (
    <div style={{ width: "45%" }}>
      <div
        style={{
          width: "100%",
          height: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 4.5px 1px gray",
          fontFamily: "segoeuiB",
          fontSize: 13,
          fontVariant: "all-petite-caps",
        }}
      >
        Platformlar
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {summaryRestDetailInfo?.platforms?.length !== 0 ? (
          summaryRestDetailInfo?.platforms?.map((row1) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: 5,
                  backgroundColor: "#494343",
                  margin: 5,
                  borderRadius: 4,
                  flexDirection: "column",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                }}
              >
                <img
                  src={platformIconImageConverter(row1?.name)}
                  width={30}
                  alt="platform"
                />
                <div style={{ marginLeft: 2 }}>{row1?.count}</div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              padding: 5,
              backgroundColor: "#494343",
              margin: 5,
              borderRadius: 4,
              flexDirection: "column",
              width: "100%",
              fontFamily: "segoeuiB",
              fontSize: 13,
            }}
          >
            Platform Bulunmamaktadır.
          </div>
        )}
      </div>
    </div>
  );
};

export default RenewalPlatforms;
