import { useState } from "react";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ApplicationTypeManager from "./components/ApplicationTypeManagement";
import CampaignManagement from "./components/CampaignManagement";
import Notifications from "./components/Notifications";
import DataManagementTab from "./components/DataManagement";
import AllData from "./components/AllData";
import IVRdata from "./components/IVRdata";
import Notes from "./components/Notes";
import TaskDashboard from "../taskDashboard";
import Dictionary from "./components/Dictionary";
import Pictures from "./components/pictures";
import PuantajSettings from "./components/puantajSettings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

export default function DataManagement() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "90%" }}>
        <h3
          style={{
            fontFamily: "segoeuiB",
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Data Yönetimi
        </h3>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="0 Data" {...a11yProps(0)} />
            {/* <Tab label="IVR Data" {...a11yProps(1)} /> */}
            <Tab label="Başvuru Tipi" {...a11yProps(1)} />
            <Tab label="Bildirimler" {...a11yProps(2)} />
            <Tab label="Kampanya" {...a11yProps(3)} />
            <Tab label="Tüm Data" {...a11yProps(4)} />
            <Tab label="Notlar" {...a11yProps(5)} />
            <Tab label="Görev Ayarları" {...a11yProps(6)} />
            <Tab label="Sözlük" {...a11yProps(7)} />
            <Tab label="Görseller" {...a11yProps(8)} />
            <Tab label="Puantaj Ayarları" {...a11yProps(9)} />
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            <DataManagementTab />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <IVRdata />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <ApplicationTypeManager />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Notifications />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CampaignManagement />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AllData />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Notes />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <TaskDashboard />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Dictionary />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Pictures />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <PuantajSettings />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
