import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "react-color-palette/css";
import { useToast } from "../../../components/snackbar";
import { DevelopmentContext } from "../../../contexts/developmentContext";
import InfoIcon from "@mui/icons-material/Info";
import ChangeColorModal from "./ChangeColorModal";
import FolderIcon from "@mui/icons-material/Folder";
import SubTaskInfoModal from "./SubTaskInfoModal";
import { developmentService } from "../../../services/developmentService";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function SubTaskModal({
  openSubTask,
  setOpenSubTask,
  taskDetail,
  subTaskDetail,
  developmentUserList,
  taskGeneralStatus,
}) {
  const { alert } = useToast();
  const classes = useStyles();
  const { getSubTaskDetail } = useContext(DevelopmentContext);
  const [showNotePeople, setShowNotePeople] = useState(false);
  const [note, setNote] = useState("");
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [openColorModal, setOpenColorModal] = useState(false);
  const [openSubTaskInfo, setOpenSubTaskInfo] = useState(false);

  const handleChange = (e, index) => {
    setCheckedUsers((prev) => {
      let newArr = [...prev];
      newArr[index] = e.target.checked;
      return newArr;
    });
  };

  const handleSaveNote = async () => {
    const users = developmentUserList.filter(
      (user, index) => checkedUsers[index]
    );
    const usersArr = users.map((user) => user.user_id);

    if (note === "") {
      alert("Not alanı boş bırakılamaz");
      return;
    }

    const data = {
      users: usersArr,
      note: note,
    };

    await developmentService
      .add_note_to_sub_task(subTaskDetail.id, data)
      .then(() => {
        setNote("");
        setCheckedUsers([]);
        getSubTaskDetail(subTaskDetail.id);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });

    // changeSubTaskFeature(subTaskDetail.id, data, taskDetail.id);
  };

  if (!subTaskDetail) return;

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openSubTask}
      className={classes.modal}
      onClose={() => {
        setOpenSubTask(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "70%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            padding: 15,
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
          }}
        >
          <div
            style={{
              margin: "10px 0px",
              padding: 15,
              border: "1px solid rgba(191,191,191,0.4)",
              borderRadius: 8,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Task Renk Değiştir">
                  <span
                    style={{
                      display: "block",
                      textAlign: "center",
                      fontWeight: "bold",
                      backgroundColor: subTaskDetail.color,
                      borderRadius: 9999,
                      color: "#fff",
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenColorModal(true);
                    }}
                  ></span>
                </Tooltip>

                <div style={{ marginLeft: 5 }}>
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      fontWeight: "bold",
                      borderRadius: 9999,
                      padding: "5px 10px",
                      backgroundColor: subTaskDetail.color,
                      color: "#fff",
                    }}
                  >
                    Alt Task No:{subTaskDetail.id}
                  </span>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                {subTaskDetail?.file?.map((file, i) => {
                  return (
                    <div key={i} style={{ marginRight: 5 }}>
                      <Tooltip title="Dosya Görüntüle">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {i + 1}-
                          <FolderIcon
                            style={{
                              color: "red",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              window.open(file.url, "_blank");
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>

              <InfoIcon
                style={{
                  color: subTaskDetail.color,
                  cursor: "pointer",
                  fontSize: 32,
                }}
                onClick={() => {
                  setOpenSubTaskInfo(true);
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              padding: "15px",
              border: "1px solid rgba(191,191,191,0.4)",
              borderRadius: 8,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              position: "relative",
              margin: "10px 0px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              {subTaskDetail.notes.length === 0 ? (
                <div style={{ color: "red", fontWeight: "bold", fontSize: 14 }}>
                  Not bulunamadı
                </div>
              ) : (
                <div
                  style={{
                    paddingRight: 5,
                    maxHeight: 300,
                    overflow: "auto",
                  }}
                >
                  {subTaskDetail.notes.map((note, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          borderRadius: 12,
                          padding: 5,
                          margin: "5px 0px",
                          background: subTaskDetail.color,
                          fontSize: 13,
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={note.created_by.picture}
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 9999,
                          }}
                        />
                        <div style={{ marginLeft: 10 }}>{note.note}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              padding: 10,
              border: "1px solid rgba(191,191,191,0.4)",
              borderRadius: 8,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              margin: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  marginRight: 20,
                  width: showNotePeople ? "75%" : "100%",
                }}
              >
                <TextField
                  placeholder="Not"
                  multiline
                  rows={2}
                  style={{ width: "100%", height: "100%" }}
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </div>

              {showNotePeople && (
                <div
                  style={{
                    maxHeight: 80,
                    overflow: "auto",
                    width: showNotePeople ? "auto" : 0,
                  }}
                >
                  {developmentUserList.map((user, index) => {
                    return (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkedUsers[index] || false}
                              onChange={(e) => handleChange(e, index)}
                            />
                          }
                          label={user.full_name}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100px",
                  marginLeft: 20,
                }}
              >
                <Button
                  variant="outline"
                  size="small"
                  style={{
                    fontFamily: "segoeuiRg",
                    fontWeight: 500,
                    backgroundColor: "#f53b62",
                    marginBottom: 10,
                    color: "#fff",
                  }}
                  onClick={() => {
                    setCheckedUsers([]);
                    setShowNotePeople(!showNotePeople);
                  }}
                >
                  Kişi Seç
                </Button>
                <Button
                  variant="outline"
                  size="small"
                  style={{
                    fontFamily: "segoeuiRg",
                    fontWeight: 500,
                    backgroundColor: "green",
                    color: "#fff",
                  }}
                  onClick={handleSaveNote}
                >
                  Gönder
                </Button>
              </div>
            </div>
          </div>
        </div>

        <ChangeColorModal
          openColorModal={openColorModal}
          setOpenColorModal={setOpenColorModal}
          taskDetail={taskDetail}
          type={"subtask"}
          subTaskDetail={subTaskDetail}
        />

        <SubTaskInfoModal
          openSubTaskInfo={openSubTaskInfo}
          setOpenSubTaskInfo={setOpenSubTaskInfo}
          taskGeneralStatus={taskGeneralStatus}
          subTaskDetail={subTaskDetail}
          taskDetail={taskDetail}
          setOpenSubTask={setOpenSubTask}
        />
      </Card>
    </Modal>
  );
}
