import React, { useEffect, useState } from "react";
import { CustomerInput } from "../../../../../components/CustomerInput";
import { Delete } from "@mui/icons-material";
import { Button, InputBase, Paper } from "@mui/material";

const YsRemoteList = ({
  ysRemoteIdListFunc,
  ysRemoteIdList,
  id,
  scrollRefs,
  ysRemoteIdDeleteFunc,
  postSecretKey,
}) => {
  const [platformUserId, setPlatformUserId] = useState("");

  useEffect(() => {
    ysRemoteIdListFunc({ rest_id: id });
  }, []);

  const sendYsKey = (data) => {
    postSecretKey(data, id);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {ysRemoteIdList?.map((row, index) => {
        return (
          <Paper
            key={index}
            ref={scrollRefs.current[index]}
            elevation={2}
            style={{ width: "50%", height: "auto", padding: 10, marginTop: 20 }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 18,
                  fontWeight: 700,
                  marginBottom: 5,
                }}
              >
                {row?.restaurant_name}
              </div>
              <Delete
                variant="Filled"
                size="large"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ysRemoteIdDeleteFunc({ id: row?.id, res_id: id });
                }}
              />
            </div>
            <div style={{ marginTop: 5 }}>
              <CustomerInput
                fieldHeader="Restoran İsmi"
                fieldName="restaurant_name"
                formData={"row"}
                setFormData={""}
                fieldValue={row?.restaurant_name}
                disabledStatus={true}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <CustomerInput
                fieldHeader="Yemeksepeti Vendor Id"
                fieldName="yemeksepeti_restaurant_id"
                formData={"row"}
                setFormData={""}
                fieldValue={row?.yemeksepeti_restaurant_id}
                disabledStatus={true}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <CustomerInput
                fieldHeader="Remote Id"
                fieldName="remote_id"
                formData={"row"}
                setFormData={""}
                fieldValue={row.remote_id}
                disabledStatus={true}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <div style={{ flex: 1 }}>
                <span
                  style={{
                    color: "gray",
                    fontFamily: "segoeuiRg",
                    fontSize: 15,
                    fontWeight: 700,
                    marginLeft: 8,
                  }}
                >
                  Chain Id
                </span>

                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#f4f4f4",
                    height: 45,
                    marginTop: 4,
                    borderRadius: 6,
                    marginRight: 14,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 8,
                    }}
                  >
                    <InputBase
                      variant="filled"
                      style={{
                        width: "100%",
                        color: "black",
                        marginLeft: 14,
                        fontSize: 16,
                        fontWeight: "400",
                        fontFamily: "segoeuiRg",
                        height: 45,
                      }}
                      value={platformUserId}
                      onChange={(e) => {
                        setPlatformUserId(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  margin: "0px 15px",
                }}
                onClick={() => {
                  let data = {
                    restofficial: id,
                    platform: 2,
                    platform_user_id: platformUserId,
                    yemeksepeti_remote_id: row?.id,
                    yemeksepetiv2: true,
                  };
                  sendYsKey(data);
                }}
              >
                Başvur
              </Button>
            </div>
          </Paper>
        );
      })}
    </div>
  );
};

export default YsRemoteList;
