import React, { createContext, useState } from "react";
import { useSnackbar } from "notistack";
import { useToast } from "../components/snackbar";
import { developmentService } from "../services/developmentService";

export const DevelopmentContext = createContext();

const DevelopmentProvider = (props) => {
  const { alert, success, successV2 } = useToast();
  const { enqueueSnackbar } = useSnackbar();
  const [developmentRequestList, setDevelopmentRequestList] = useState(null);
  const [developmentProcessList, setDevelopmentProcessList] = useState(null);
  const [developmentCompletedList, setDevelopmentCompletedList] =
    useState(null);
  const [taskDetail, setTaskDetail] = useState(null);
  const [taskStatuses, setTaskStatuses] = useState(null);
  const [subTaskDetail, setSubTaskDetail] = useState(null);
  const [authorityList, setAuthorityList] = useState(null);
  const [developmentUserList, setDevelopmentUserList] = useState(null);
  const [taskGroups, setTaskGroups] = useState(null);

  const developmentRequestListFunc = (data) => {
    developmentService
      .development_request_list(data)
      .then((response) => {
        setDevelopmentRequestList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const developmentRequestPatchFunc = (data) => {
    developmentService
      .development_request_patch(data)
      .then((response) => {
        if (data.type === "modal") {
          data.setProcessingDetailModal(false);
        }
        developmentRequestListFunc({
          request_type: data.request_type,
          res_id: data.res_id,
          is_global: data.is_global,
          search: "",
          id_search: "",
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editDevelopmentRequest = (data, id) => {
    developmentService
      .edit_development_request(data, id)
      .then((response) => {
        developmentRequestListFunc({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          id_search: "",
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createDevelopmentRequest = (data, id) => {
    developmentService
      .create_development_request(data, id)
      .then((response) => {
        getDevelopmentProcessList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          taskGroup: "",
          id_search: "",
          page: 1,
        });

        developmentRequestListFunc({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          id_search: "",
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const rejectDevelopmentRequest = (data, id) => {
    developmentService
      .reject_development_request(data, id)
      .then((response) => {
        developmentRequestListFunc({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          id_search: "",
        });
        success("Geliştirme talebi reddedildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const developmentRequestFunc = (data, formData) => {
    developmentService
      .development_request(formData)
      .then((response) => {
        successV2("Geliştirme talebi onay bekleyen listesine alındı.");
        developmentRequestListFunc({
          request_type: data.request_type,
          res_id: data.res_id,
          is_global: data.is_global,
          search: "",
          id_search: "",
        });

        getDevelopmentProcessList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          taskGroup: "",
          id_search: "",
          page: 1,
        });

        data.setNote("");
        data.setNoteHeader("");
        data.setIsGlobal(false);
        data.setNewDevelopmentRequest(false);
        data.setList({
          name: null,
          id: null,
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getDevelopmentProcessList = (data) => {
    developmentService
      .get_development_process_list(data)
      .then((response) => {
        setDevelopmentProcessList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getDevelopmentCompletedList = (data) => {
    developmentService
      .get_development_completed_list(data)
      .then((response) => {
        setDevelopmentCompletedList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getTaskDetail = (id, noteType, requestType) => {
    developmentService
      .get_task_detail(id, noteType, requestType)
      .then((response) => {
        setTaskDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getSubTaskDetail = (id) => {
    developmentService
      .get_task_detail(id, "all", "all")
      .then((response) => {
        setSubTaskDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getTaskStatuses = (type) => {
    developmentService
      .get_task_statuses(type)
      .then((response) => {
        setTaskStatuses(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const addNoteToMainTask = (id, data, own, noteType) => {
    developmentService
      .add_note_to_main_task(id, data)
      .then((response) => {
        getTaskDetail(id, noteType, own);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeTaskStatus = (userId, data, taskId) => {
    developmentService
      .change_task_status(userId, data)
      .then((response) => {
        getTaskDetail(taskId, "all", "all");
        getDevelopmentProcessList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          taskGroup: "",
          id_search: "",
          page: 1,
        });
        success("Görev durumu değiştirildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const addTaskToUser = (taskId, data) => {
    developmentService
      .add_task_to_user(taskId, data)
      .then((response) => {
        getTaskDetail(taskId, "all", "all");
        getDevelopmentProcessList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          taskGroup: "",
          id_search: "",
          page: 1,
        });
        success("Kişi Taska eklendi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeTaskGeneralStatus = (taskId, data) => {
    developmentService
      .change_task_general_status(taskId, data)
      .then((response) => {
        getTaskDetail(taskId, "all", "all");
        getDevelopmentProcessList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          taskGroup: "",
          id_search: "",
          page: 1,
        });

        getDevelopmentCompletedList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          id_search: "",
          page: 1,
        });

        success("İşlem başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const addSubTask = (taskId, data) => {
    developmentService
      .add_sub_task(taskId, data)
      .then((response) => {
        getTaskDetail(taskId, "all", "all");
        success("Yeni Alt Task Eklendi");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteTask = (id, type, taskId) => {
    developmentService
      .delete_task(id)
      .then((response) => {
        if (type === "task") {
          getDevelopmentProcessList({
            request_type: "all",
            res_id: "",
            is_global: "",
            search: "",
            taskGroup: "",
            id_search: "",
            page: 1,
          });

          getDevelopmentCompletedList({
            request_type: "all",
            res_id: "",
            is_global: "",
            search: "",
            id_search: "",
            page: 1,
          });
        } else {
          getSubTaskDetail(id);
          getTaskDetail(taskId, "all", "all");
        }
        success("Task Silindi");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteUserFromTask = (userId, id) => {
    developmentService
      .delete_user_from_task(userId)
      .then((response) => {
        getTaskDetail(id, "all", "all");
        success("İşlem başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deletePdfFromTask = (taskId, pdfId) => {
    developmentService
      .delete_pdf_from_task(taskId, pdfId)
      .then((response) => {
        getTaskDetail(taskId, "all", "all");
        success("İşlem başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeSubTaskFeature = (id, data, taskId) => {
    developmentService
      .change_sub_task_feauture(id, data)
      .then((response) => {
        getSubTaskDetail(id);
        getTaskDetail(taskId, "all", "all");
        success("İşlem başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getUserAuthorityList = () => {
    developmentService
      .get_user_authority_list()
      .then((response) => {
        setAuthorityList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deletePdfFromApprovement = (approveId, pdfId) => {
    developmentService
      .delete_pdf_from_approvement(approveId, pdfId)
      .then((response) => {
        success("İşlem başarılı");
        developmentRequestListFunc({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          id_search: "",
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getDevelopmentUserList = () => {
    developmentService
      .get_development_user_list()
      .then((response) => {
        setDevelopmentUserList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getGroupTask = () => {
    developmentService
      .get_group_task()
      .then((response) => {
        if (response.length > 0) {
          response.unshift({ header: "Tümü", id: "", color: "black" });
        }
        setTaskGroups(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteGroupTask = (id) => {
    developmentService
      .delete_group_task(id)
      .then((response) => {
        getGroupTask();
        success("İşlem başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editGroupDetail = (id, data) => {
    developmentService
      .edit_group_detail(id, data)
      .then((response) => {
        getDevelopmentProcessList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          taskGroup: "",
          id_search: "",
          page: 1,
        });

        getDevelopmentCompletedList({
          request_type: "all",
          res_id: "",
          is_global: "",
          search: "",
          id_search: "",
          page: 1,
        });

        getGroupTask();
        success("İşlem başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteNote = (taskId, noteId) => {
    developmentService
      .delete_note(taskId, noteId)
      .then((response) => {
        getTaskDetail(taskId, "all", "all");
        success("İşlem başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  return (
    <DevelopmentContext.Provider
      value={{
        editDevelopmentRequest,
        createDevelopmentRequest,
        developmentRequestListFunc,
        developmentRequestPatchFunc,
        developmentRequestFunc,
        developmentRequestList,
        getDevelopmentProcessList,
        developmentProcessList,
        rejectDevelopmentRequest,
        getTaskDetail,
        taskDetail,
        setTaskDetail,
        getTaskStatuses,
        taskStatuses,
        addNoteToMainTask,
        changeTaskStatus,
        addTaskToUser,
        changeTaskGeneralStatus,
        addSubTask,
        deleteTask,
        deleteUserFromTask,
        deletePdfFromTask,
        changeSubTaskFeature,
        getSubTaskDetail,
        subTaskDetail,
        getDevelopmentCompletedList,
        developmentCompletedList,
        getUserAuthorityList,
        authorityList,
        deletePdfFromApprovement,
        getDevelopmentUserList,
        developmentUserList,
        getGroupTask,
        taskGroups,
        deleteGroupTask,
        editGroupDetail,
        deleteNote,
      }}
    >
      {props.children}
    </DevelopmentContext.Provider>
  );
};

export default DevelopmentProvider;
