import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Box, createTheme, useMediaQuery } from "@mui/material";

import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import useWindowDimensions from "../../../components/windowSize";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { GreenSwitch } from "../../../components/switch/greenSwitch";

const onroadDeliveredValues = [
  { value: 5, label: "5 dk" },
  { value: 10, label: "10 dk" },
  { value: 15, label: "15 dk" },
  { value: 20, label: "20 dk" },
  { value: 25, label: "25 dk" },
  { value: 30, label: "30 dk" },
  { value: 35, label: "35 dk" },
  { value: 40, label: "40 dk" },
  { value: 45, label: "45 dk" },
  { value: 50, label: "50 dk" },
  { value: 55, label: "55 dk" },
  { value: 60, label: "60 dk" },
  { value: 65, label: "65 dk" },
  { value: 70, label: "70 dk" },
  { value: 75, label: "75 dk" },
  { value: 80, label: "80 dk" },
  { value: 85, label: "85 dk" },
  { value: 90, label: "90 dk" },
  { value: 95, label: "95 dk" },
];

const Modules = () => {
  const { width } = useWindowDimensions();
  const {
    getRestaurantModulesData,
    patchRestaurantModulesData,
    restaurantModules,
  } = useContext(RestaurantContext);
  let { id } = useParams();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    getRestaurantModulesData(id);
  }, []);

  return (
    <Box component="main">
      <div
        style={{
          height: "95%",
          display: "flex",
          width: width > 1500 ? "50%" : width > 1200 ? "80%" : "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            margin: 20,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ fontFamily: "segoeuiRg" }}>Modül Aç Kapa</div>
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 30,
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div
                style={{
                  width: matches ? "45%" : "98%",
                  height: matches ? 210 : 100,
                  boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                  borderRadius: 4,
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: 12,
                }}
              >
                <div style={{ color: "black" }}>Otomatik Onay</div>
                <GreenSwitch
                  onClick={() => {
                    patchRestaurantModulesData(id, {
                      auto_accept: !restaurantModules?.auto_accept,
                    });
                  }}
                  checked={restaurantModules?.auto_accept ?? false}
                />
              </div>
              <div
                style={{
                  width: matches ? "50%" : "98%",
                  height: 180,
                  boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                  borderRadius: 4,
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingTop: 16,
                  paddingBottom: 14,
                }}
              >
                <div style={{ color: "black" }}>
                  Otomatik Yola Çıkar / Teslim Et
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <GreenSwitch
                    onClick={() => {
                      patchRestaurantModulesData(id, {
                        auto_accept_onroad:
                          !restaurantModules?.auto_accept_onroad,
                        auto_accept_deliver:
                          !restaurantModules?.auto_accept_deliver,
                      });
                    }}
                    checked={restaurantModules?.auto_accept_onroad ?? false}
                    // disabled={!restaurantModules?.auto_accept}
                  />

                  {restaurantModules?.auto_accept_onroad && (
                    <>
                      <div style={{ color: "black" }}>
                        Yola çıkarma / Teslim etme dakikası
                      </div>{" "}
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          width: "100%",
                        }}
                      >
                        <Select
                          name="auto_accept_onroad_minutes"
                          options={onroadDeliveredValues}
                          styles={selectDefaultStyles}
                          isSearchable={false}
                          hoverColor={"black"}
                          menuColor={"#319a77"}
                          selectWidth={100}
                          value={onroadDeliveredValues.filter(function (
                            option
                          ) {
                            return (
                              option.value ===
                              restaurantModules?.auto_accept_onroad_minutes
                            );
                          })}
                          onChange={(e) => {
                            if (
                              e.value !=
                              restaurantModules?.auto_accept_onroad_minutes
                            ) {
                              patchRestaurantModulesData(id, {
                                auto_accept_onroad_minutes: e.value,
                              });
                            }
                          }}
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>{e.label}</span>
                            </div>
                          )}
                        />
                        <Select
                          name="auto_accept_deliver_minutes"
                          options={onroadDeliveredValues}
                          styles={selectDefaultStyles}
                          isSearchable={false}
                          hoverColor={"black"}
                          menuColor={"#319a77"}
                          selectWidth={100}
                          value={onroadDeliveredValues.filter(function (
                            option
                          ) {
                            return (
                              option.value ===
                              restaurantModules?.auto_accept_deliver_minutes
                            );
                          })}
                          onChange={(e) => {
                            if (
                              e.value !=
                              restaurantModules?.auto_accept_deliver_minutes
                            ) {
                              patchRestaurantModulesData(id, {
                                auto_accept_deliver_minutes: e.value,
                              });
                            }
                          }}
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>{e.label}</span>
                            </div>
                          )}
                        />
                      </div>
                      <div style={{ color: "black", marginTop: 2 }}>
                        Sadece online siparişlerde
                      </div>
                      <GreenSwitch
                        onClick={() => {
                          patchRestaurantModulesData(id, {
                            auto_accept_onroad_deliver_only_online_payment:
                              !restaurantModules?.auto_accept_onroad_deliver_only_online_payment,
                          });
                        }}
                        checked={
                          restaurantModules?.auto_accept_onroad_deliver_only_online_payment ??
                          false
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Modules;
