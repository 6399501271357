import { useContext, useEffect, useState } from "react";
import { Container, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import CustomDatePickerInput from "../../../components/datePickerInput";
import useWindowDimensions from "../../../components/windowSize";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import StackedBar from "../../../components/charts/StackedBar";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { ManagementContext } from "../../../contexts/managementContext";
import { makeStyles } from "@mui/styles";
import GridItem from "../../../components/gridItem";
import SetupCountModal from "./SetupCountModal";
import managementService from "../../../services/managementService";
import MmaTotalModal from "../../../components/modals/mmaTotalModal";

const today = new Date();
const lastWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7
);
const lastMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 30
);
const lastThreeMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 90
);
const dates = [
  {
    label: "Bugün",
    date: [today, today],
  },
  {
    label: "Son 1 Hafta",
    date: [lastWeek, today],
  },
  {
    label: "Son 1 Ay",
    date: [lastMonth, today],
  },
  {
    label: "Son 3 Ay",
    date: [lastThreeMonth, today],
  },
];

const years = [
  {
    label: "2024",
    value: 2024,
  },
  {
    label: "2023",
    value: 2023,
  },
  {
    label: "2022",
    value: 2022,
  },
  {
    label: "2021",
    value: 2021,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

const ManagerReports = () => {
  const classes = useStyles();
  const [workerSelect, setWorkerSelect] = useState(null);
  const { width } = useWindowDimensions();
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [selectDate, setSelectDate] = useState([]);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabValueSec, setTabValueSec] = useState(0);
  const [tabValueThird, setTabValueThird] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showCollectorModal, setShowCollectorModal] = useState(false);
  const [showCollectorRenewModal, setShowCollectorRenewModal] = useState(false);
  const [showCollectorTransactionModal, setShowCollectorTransactionModal] =
    useState(false);
  const { getApplicationTransferList, backofficeList } =
    useContext(RestaurantContext);
  const [mmaTotal, setMmaTotal] = useState([]);
  const [showMmaModal, setShowMmaModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState({
    label: "2024",
    value: 2024,
  });

  const {
    fetchFilteredReports,
    filteredReports,
    getGraphs,
    graphs,
    getSetupCount,
    setupCount,
    getCollectorData,
    collectorData,
    getCollectorDataRenew,
    collectorDataRenew,
    getCollectorTreansactionData,
    collectorTransactionData,
  } = useContext(ManagementContext);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeTabSec = (event, newValue) => {
    setTabValueSec(newValue);
  };

  const handleChangeTabThird = (event, newValue) => {
    setTabValueThird(newValue);
  };

  const handleChangeWorker = (e) => {
    setWorkerSelect(e);
  };

  const handleFilterDate = (e) => {
    setDateRange(e.date);
    setIsDateSelected(false);
    setSelectDate(e);
  };

  useEffect(() => {
    getGraphs(selectedYear.value, workerSelect?.user_id);
  }, [workerSelect, selectedYear]);

  useEffect(() => {
    const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    if (startDate && endDate) {
      fetchFilteredReports(selectedStartDate, selectedEndDate, workerSelect);
    }
  }, [endDate, startDate, workerSelect, dateRange]);

  useEffect(() => {
    backofficeList.unshift({
      user_id: 0,
      full_name: "Tümü",
    });
  }, [backofficeList]);

  useEffect(() => {
    getApplicationTransferList();
  }, []);

  const handleShowSetupModal = () => {
    setShowModal(true);
    const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    let data = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };
    if (startDate && endDate) {
      getSetupCount(data);
    }
  };

  const handleShowCollectorData = () => {
    setShowCollectorModal(true);

    const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    let data = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };
    if (startDate && endDate) {
      getCollectorData(data);
    }
  };

  const handleShowCollectorDataRenew = () => {
    setShowCollectorRenewModal(true);

    const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    let data = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };
    if (startDate && endDate) {
      getCollectorDataRenew(data);
    }
  };

  const handleShowCollectorTransactionData = (type) => {
    setShowCollectorTransactionModal(true);

    const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    let data = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      type,
    };
    if (startDate && endDate) {
      getCollectorTreansactionData(data);
    }
  };

  const handleShowMmaTotal = async () => {
    const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    let data = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };
    const res = await managementService.get_mma_total(data);
    setShowMmaModal(true);
    setMmaTotal(res.data);
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "90%" }}>
        <Box>
          <h3
            style={{
              fontFamily: "segoeuiB",
              fontSize: 24,
              textAlign: "center",
            }}
          >
            SATIŞ RAPORLARI
          </h3>

          <Box sx={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Yıl Filtrele
              </span>
              <Select
                options={years}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                isSearchable={false}
                hoverColor={"black"}
                menuColor={"#f53b62"}
                selectWidth={width > 1200 ? 230 : 140}
                placeholder={"Yıl Seçiniz"}
                value={selectedYear}
                getOptionValue={(option) => option.value}
                onChange={(e) => {
                  setSelectedYear(e);
                }}
                getOptionLabel={(option) => option.label}
              />
            </div>

            <div style={{ marginRight: 10 }}>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Tarih Filtrele
              </span>
              <Select
                options={dates}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                isSearchable={false}
                hoverColor={"black"}
                menuColor={"#f53b62"}
                selectWidth={width > 1200 ? 230 : 140}
                placeholder={
                  isDateSelected
                    ? "-"
                    : selectDate.label
                    ? selectDate.label
                    : "Bugün"
                }
                value={isDateSelected ? "-" : selectDate}
                getOptionValue={(option) => option.date}
                onChange={(e) => {
                  handleFilterDate(e);
                }}
                getOptionLabel={(option) => option.label}
              />
            </div>
            <Box>
              <div style={{ marginRight: 10 }}>
                <span
                  style={{
                    fontSize: 20,
                    marginBottom: 10,
                    display: "block",
                    fontWeight: "600",
                  }}
                >
                  Tarih
                </span>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  fixedHeight="45px"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  onChange={(update) => {
                    setIsDateSelected(true);
                    setDateRange(update);
                  }}
                  customInput={
                    <CustomDatePickerInput
                      selectWidth={width > 1200 ? 230 : 140}
                    />
                  }
                  locale="tr"
                />
              </div>
            </Box>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Kişiler
              </span>
              <Select
                options={backofficeList}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                isSearchable={false}
                hoverColor={"black"}
                menuColor={"#f53b62"}
                selectWidth={width > 1200 ? 230 : 140}
                placeholder={"Tümü"}
                value={workerSelect}
                getOptionValue={(option) => option.user_id}
                onChange={(e) => {
                  handleChangeWorker(e);
                }}
                getOptionLabel={(option) => option.full_name}
              />
            </div>
          </Box>
        </Box>

        <Grid container columns={12} spacing={2} sx={{ marginTop: 1 }}>
          <GridItem
            per={2}
            title="Toplam Kurulum"
            count={filteredReports?.total}
          />
          <GridItem
            per={2}
            title="Aktif Ödemiş"
            count={filteredReports?.active_payed}
            percent={
              (filteredReports?.active_payed * 100) / filteredReports?.total
            }
          />
          <GridItem
            per={2}
            title="Pasif Ödemiş"
            count={filteredReports?.passive_payed}
            percent={
              (filteredReports?.passive_payed * 100) / filteredReports?.total
            }
          />
          <GridItem
            per={2}
            title="Aktif Ödememiş"
            count={filteredReports?.active_not_payed}
            percent={
              (filteredReports?.active_not_payed * 100) / filteredReports?.total
            }
          />
          <GridItem
            per={2}
            title="Pasif Ödememiş"
            count={filteredReports?.passive_not_payed}
            percent={
              (filteredReports?.passive_not_payed * 100) /
              filteredReports?.total
            }
          />
          <GridItem
            per={2}
            title="Bloke"
            count={filteredReports?.bloked}
            percent={(filteredReports?.bloked * 100) / filteredReports?.total}
          />
        </Grid>

        <Grid container columns={14} spacing={2} sx={{ marginTop: 1 }}>
          <GridItem
            per={2}
            title="Toplam Başvuru"
            count={filteredReports?.application_total}
          />
          <GridItem
            per={2}
            title="Kurulum Adeti"
            count={filteredReports?.application_total_setup}
            onClick={() => handleShowSetupModal()}
            boxStyle={{ cursor: "pointer" }}
          />
          <GridItem
            per={2}
            title="Silinen Adeti"
            count={filteredReports?.application_total_deleted}
          />
          <GridItem
            per={2}
            title="İşlemde Adeti"
            count={filteredReports?.application_total_processing}
          />
          <GridItem
            per={2}
            title="Toplam Maliyet"
            count={filteredReports?.total_cost}
          />
          <GridItem
            per={2}
            title="Toplam Prim"
            count={filteredReports?.total_prim}
          />
          <GridItem
            per={2}
            title="Aktif"
            count={
              filteredReports?.active_payed + filteredReports?.active_not_payed
            }
            percent={
              ((filteredReports?.active_payed +
                filteredReports?.active_not_payed) *
                100) /
              (filteredReports?.total - filteredReports?.bloked)
            }
          />
        </Grid>
        <Grid container columns={8} spacing={2} sx={{ marginTop: 1 }}>
          <GridItem
            per={2}
            title="Yeni Tahsilat TL"
            count={filteredReports?.total_payed_amount}
            onClick={handleShowCollectorData}
            boxStyle={{ cursor: "pointer" }}
          />
          <GridItem
            per={2}
            title="Yeni Ort Tahsilat"
            count={
              filteredReports?.total_payed_amount /
              filteredReports?.total_payed_count
            }
          />
          <GridItem
            per={2}
            title="Yeni Gelen Ödeme"
            count={filteredReports?.total_incoming_amount}
            onClick={() => handleShowCollectorTransactionData("new")}
          />
          <GridItem
            per={2}
            title="Yeni Tahsilat Sayısı"
            count={filteredReports?.total_payed_count}
          />
        </Grid>
        <Grid container columns={8} spacing={2} sx={{ marginTop: 1 }}>
          <GridItem
            per={2}
            title="Yenileme Tahsilat TL"
            count={filteredReports?.total_payed_renew_amount}
            onClick={handleShowCollectorDataRenew}
            boxStyle={{ cursor: "pointer" }}
          />
          <GridItem
            per={2}
            title="Yenileme Ort Tahsilat"
            count={
              filteredReports?.total_payed_renew_amount /
              filteredReports?.total_payed_renew_count
            }
          />

          <GridItem
            per={2}
            title="Yenileme Gelen Ödeme"
            count={filteredReports?.total_incoming_renew_amount}
            onClick={() => handleShowCollectorTransactionData("renew")}
          />
          <GridItem
            per={2}
            title="Yenileme Tahsilat Sayısı"
            count={filteredReports?.total_payed_renew_count}
          />
        </Grid>
        <Grid
          container
          columns={14}
          spacing={2}
          sx={{ marginTop: 1, marginBottom: 2 }}
        >
          {filteredReports?.product_reports?.map((item, index) => {
            return (
              <GridItem
                key={index}
                per={2}
                title={
                  item.product__name === "RESTORAN APP"
                    ? "SEPETAPP"
                    : item.product__name
                }
                count={item.count}
                total={item.total}
              />
            );
          })}
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            {graphs?.application?.length > 0 && (
              <StackedBar
                title={"Toplam Başvuru / Silinen / Kurulum"}
                data={[
                  {
                    dataKey: "toplam_basvuru",
                    name: "Toplam Başvuru",
                    color: "blue",
                  },
                  {
                    dataKey: "toplam_silinen",
                    name: "Toplam Silinen",
                    color: "red",
                  },
                  {
                    dataKey: "toplam_kurulum",
                    name: "Toplam Kurulum",
                    color: "#03bf00",
                  },
                ]}
                graph={graphs?.application}
                year={graphs?.year}
              />
            )}
            <Box>
              <Tabs
                variant="scrollable"
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                className={classes.tabs}
              >
                {graphs?.application &&
                  graphs?.application.map((inf, i) => (
                    <Tab key={i} label={inf.ay} {...a11yProps(i)} />
                  ))}
              </Tabs>
              {graphs?.application &&
                graphs?.application.map((inf, i) => {
                  return (
                    <TabPanel value={tabValue} index={i} key={i}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "33%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Başvuru Tipi
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_basvuru_ivr} - %
                                {inf?.toplam_basvuru_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_basvuru_zerodata} - %
                                {inf?.toplam_basvuru_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_basvuru_rehber} - %
                                {inf?.toplam_basvuru_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_basvuru_dijital} - %
                                {inf?.toplam_basvuru_dijital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                        <div style={{ width: "33%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Silinen Tipi
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_silinen_ivr} - %
                                {inf?.toplam_silinen_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_silinen_zerodata} - %
                                {inf?.toplam_silinen_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_silinen_rehber} - %
                                {inf?.toplam_silinen_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_silinen_dijital} - %
                                {inf?.toplam_silinen_dijital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                        <div style={{ width: "33%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Kurulum Tipi
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_kurulum_ivr} - %
                                {inf?.toplam_kurulum_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_kurulum_zerodata} - %
                                {inf?.toplam_kurulum_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_kurulum_rehber} - %
                                {inf?.toplam_kurulum_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_kurulum_dijital} - %
                                {inf?.toplam_kurulum_dijital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </TabPanel>
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={6}>
            {graphs?.deleted_applications?.length > 0 && (
              <StackedBar
                title={"Silinen Başvuru Sebebleri"}
                data={[
                  {
                    dataKey: "aylık_ödeme",
                    name: "Aylık Ödeme",
                    color: "#8884d8",
                  },
                  {
                    dataKey: "az_sipariş",
                    name: "Az sipariş",
                    color: "#82ca9d",
                  },
                  {
                    dataKey: "callerid",
                    name: "Caller id",
                    color: "#609EA2",
                  },
                  {
                    dataKey: "devir",
                    name: "Devir",
                    color: "#4e7a56",
                  },
                  {
                    dataKey: "diğer",
                    name: "Diğer",
                    color: "blue",
                  },
                  {
                    dataKey: "entegrasyonu_var",
                    name: "Entegrasyonu var",
                    color: "black",
                  },
                  {
                    dataKey: "fiyat",
                    name: "Fiyat",
                    color: "#C93D1B",
                  },
                  {
                    dataKey: "hatalı_basvuru",
                    name: "Hatalı Başvuru",
                    color: "gray",
                  },
                  {
                    dataKey: "i̇flas",
                    name: "İflas",
                    color: "#9DF1DF",
                  },
                  {
                    dataKey: "ios_i̇şletim_sistemi",
                    name: "İos işletim sistemi",
                    color: "#778177",
                  },
                  {
                    dataKey: "kurye_takip",
                    name: "Kurye takip",
                    color: "#A31ACB",
                  },
                  {
                    dataKey: "kuryesi_yok",
                    name: "Kuryesi yok",
                    color: "orange",
                  },
                  {
                    dataKey: "masa_modulu",
                    name: "Masa Modulu",
                    color: "#49FF00",
                  },
                  {
                    dataKey: "mükerrer",
                    name: "Mükerrer",
                    color: "#FDB827",
                  },
                  {
                    dataKey: "paket_servisi_yok",
                    name: "Paket servisi yok",
                    color: "#2192FF",
                  },
                  {
                    dataKey: "patron_modülü",
                    name: "Patron modülü",
                    color: "#FF1E1E",
                  },
                  {
                    dataKey: "stok_yönetimi",
                    name: "Stok Yönetimi",
                    color: "#a2a5b7",
                  },
                  {
                    dataKey: "tek_platform",
                    name: "Tek Platform",
                    color: "#70603b",
                  },
                  {
                    dataKey: "teknik_problem",
                    name: "Teknik problem",
                    color: "#C70A80",
                  },
                  {
                    dataKey: "ulaşılamadı",
                    name: "Ulaşılamadı",
                    color: "#FF85B3",
                  },
                  {
                    dataKey: "yanlış_numara",
                    name: "Yanlış numara",
                    color: "purple",
                  },
                  {
                    dataKey: "zincir_marka",
                    name: "Zincir Marka",
                    color: "#397864",
                  },
                ]}
                graph={graphs?.deleted_applications}
                year={graphs?.year}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {graphs?.restofficial?.length > 0 && (
              <StackedBar
                title={"Toplam Kurulum / Tahsilat Adet / Bloke"}
                data={[
                  {
                    dataKey: "toplam_kurulum",
                    name: "Toplam Kurulum",
                    color: "blue",
                  },
                  {
                    dataKey: "toplam_tahsilat",
                    name: "Toplam Tahsilat Adet",
                    color: "#03bf00",
                  },
                  {
                    dataKey: "toplam_bloke",
                    name: "Toplam Bloke Adet",
                    color: "red",
                  },
                ]}
                graph={graphs?.restofficial}
                year={graphs?.year}
              />
            )}
            <Box>
              <Tabs
                variant="scrollable"
                value={tabValueSec}
                onChange={handleChangeTabSec}
                aria-label="basic tabs example"
                className={classes.tabs}
              >
                {graphs?.restofficial &&
                  graphs?.restofficial.map((inf, i) => (
                    <Tab key={i} label={inf.ay} {...a11yProps(i)} />
                  ))}
              </Tabs>
              {graphs?.restofficial &&
                graphs?.restofficial.map((inf, i) => {
                  return (
                    <TabPanel value={tabValueSec} index={i} key={i}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "33%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Başvuru Tipi Kurulum
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_kurulum_ivr} - %
                                {inf?.toplam_kurulum_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_kurulum_zerodata} - %
                                {inf?.toplam_kurulum_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_kurulum_rehber} - %
                                {inf?.toplam_kurulum_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_kurulum_digital} - %
                                {inf?.toplam_kurulum_digital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                        <div style={{ width: "33%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Tahsilat Adet
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_tahsilat_ivr} - %
                                {inf?.toplam_tahsilat_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_tahsilat_zerodata} - %
                                {inf?.toplam_tahsilat_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_tahsilat_rehber} - %
                                {inf?.toplam_tahsilat_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_tahsilat_digital} - %
                                {inf?.toplam_tahsilat_digital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                        <div style={{ width: "33%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Bloke Adet
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_bloke_ivr} - %
                                {inf?.toplam_bloke_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_bloke_zerodata} - %
                                {inf?.toplam_bloke_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_bloke_rehber} - %
                                {inf?.toplam_bloke_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_bloke_digital} - %
                                {inf?.toplam_bloke_digital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </TabPanel>
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={6}>
            {graphs?.delete_restofficials?.length > 0 && (
              <StackedBar
                title={"Bloke Sebebleri"}
                data={[
                  {
                    dataKey: "aylık_ödeme",
                    name: "Aylık Ödeme",
                    color: "#8884d8",
                  },
                  {
                    dataKey: "az_sipariş",
                    name: "Az sipariş",
                    color: "#82ca9d",
                  },
                  {
                    dataKey: "callerid",
                    name: "Caller id",
                    color: "#609EA2",
                  },
                  {
                    dataKey: "devir",
                    name: "Devir",
                    color: "#4e7a56",
                  },
                  {
                    dataKey: "diğer",
                    name: "Diğer",
                    color: "blue",
                  },
                  {
                    dataKey: "entegrasyonu_var",
                    name: "Entegrasyonu var",
                    color: "black",
                  },
                  {
                    dataKey: "fiyat",
                    name: "Fiyat",
                    color: "#C93D1B",
                  },
                  {
                    dataKey: "hatalı_basvuru",
                    name: "Hatalı Başvuru",
                    color: "gray",
                  },
                  {
                    dataKey: "ios_i̇şletim_sistemi",
                    name: "İos işletim sistemi",
                    color: "#778177",
                  },
                  {
                    dataKey: "i̇flas",
                    name: "İflas",
                    color: "#9DF1DF",
                  },
                  {
                    dataKey: "kurye_takip",
                    name: "Kurye takip",
                    color: "#A31ACB",
                  },
                  {
                    dataKey: "kuryesi_yok",
                    name: "Kuryesi yok",
                    color: "orange",
                  },
                  {
                    dataKey: "masa_modulu",
                    name: "Masa Modulu",
                    color: "#49FF00",
                  },
                  {
                    dataKey: "mükerrer",
                    name: "Mükerrer",
                    color: "#FDB827",
                  },
                  {
                    dataKey: "paket_servisi_yok",
                    name: "Paket servisi yok",
                    color: "#2192FF",
                  },
                  {
                    dataKey: "patron_modülü",
                    name: "Patron modülü",
                    color: "#FF1E1E",
                  },
                  {
                    dataKey: "stok_yönetimi",
                    name: "Stok Yönetimi",
                    color: "#2a5872",
                  },
                  {
                    dataKey: "tek_platform",
                    name: "Tek Platform",
                    color: "#70603b",
                  },
                  {
                    dataKey: "teknik_problem",
                    name: "Teknik problem",
                    color: "#C70A80",
                  },
                  {
                    dataKey: "ulaşılamadı",
                    name: "Ulaşılamadı",
                    color: "#FF85B3",
                  },
                  {
                    dataKey: "yanlış_numara",
                    name: "Yanlış numara",
                    color: "purple",
                  },
                  {
                    dataKey: "zincir_marka",
                    name: "Zincir Marka",
                    color: "#397864",
                  },
                ]}
                graph={graphs?.delete_restofficials}
                year={graphs?.year}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {graphs?.amount_cost?.length > 0 && (
              <StackedBar
                title={"Toplam Tahsilat / Maliyet / Adet"}
                data={[
                  {
                    dataKey: "toplam_tahsilat",
                    name: "Toplam Tahsilat",
                    color: "#03bf00",
                  },
                  {
                    dataKey: "toplam_maliyet",
                    name: "Toplam Maliyet",
                    color: "blue",
                  },
                  {
                    dataKey: "toplam_tahsilat_sayi",
                    name: "Toplam Adet",
                    color: "red",
                  },
                ]}
                graph={graphs?.amount_cost}
                year={graphs?.year}
              />
            )}
            <Box>
              <Tabs
                variant="scrollable"
                value={tabValueThird}
                onChange={handleChangeTabThird}
                aria-label="basic tabs example"
                className={classes.tabs}
              >
                {graphs?.amount_cost &&
                  graphs?.amount_cost.map((inf, i) => (
                    <Tab key={i} label={inf.ay} {...a11yProps(i)} />
                  ))}
              </Tabs>
              {graphs?.amount_cost &&
                graphs?.amount_cost.map((inf, i) => {
                  return (
                    <TabPanel value={tabValueThird} index={i} key={i}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "50%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Başvuru Tipi Tahsilat
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_tahsilat_ivr} - %
                                {inf?.toplam_tahsilat_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_tahsilat_zerodata} - %
                                {inf?.toplam_tahsilat_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_tahsilat_rehber} - %
                                {inf?.toplam_tahsilat_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_tahsilat_digital} - %
                                {inf?.toplam_tahsilat_digital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                        <div style={{ width: "50%" }}>
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 600,
                              color: "#293c51",
                              fontSize: "14px",
                            }}
                          >
                            Başvuru Tipi Adet
                          </div>
                          <ul
                            style={{
                              paddingLeft: 20,
                              marginBottom: 0,
                              marginTop: 5,
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              IVR:{" "}
                              <b>
                                {inf?.toplam_tahsilat_sayi_ivr} - %
                                {inf?.toplam_tahsilat_sayi_ivr_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Zero Data:{" "}
                              <b>
                                {inf?.toplam_tahsilat_sayi_zerodata} - %
                                {inf?.toplam_tahsilat_sayi_zerodata_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Rehberimden:{" "}
                              <b>
                                {inf?.toplam_tahsilat_sayi_rehber} - %
                                {inf?.toplam_tahsilat_sayi_rehber_oran}
                              </b>
                            </li>
                            <li
                              style={{
                                fontFamily: "segoeuiRg",
                                color: "#293c51",
                                fontSize: "12px",
                              }}
                            >
                              Dijital:{" "}
                              <b>
                                {inf?.toplam_tahsilat_sayi_digital} - %
                                {inf?.toplam_tahsilat_sayi_digital_oran}
                              </b>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </TabPanel>
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={6}>
            {graphs?.renewals_task?.length > 0 && (
              <StackedBar
                title={"Sözleşme Yenilemesi Gelen Teklifler"}
                data={[
                  {
                    dataKey: "total",
                    name: "Toplam",
                    color: "#03bf00",
                  },
                  {
                    dataKey: "tahsilat",
                    name: "Tahsilatı alınan",
                    color: "blue",
                  },
                  {
                    dataKey: "processing",
                    name: "İşlemde Olan",
                    color: "red",
                  },
                  {
                    dataKey: "blocked",
                    name: "Bloke Olan",
                    color: "black",
                  },
                ]}
                graph={graphs?.renewals_task}
                year={graphs?.year}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {graphs?.renewals_task_blocked?.length > 0 && (
              <StackedBar
                title={"Sözleşme Yenileme Bloke Sebebleri"}
                data={[
                  {
                    dataKey: "aylık_ödeme",
                    name: "Aylık Ödeme",
                    color: "#8884d8",
                  },
                  {
                    dataKey: "az_sipariş",
                    name: "Az sipariş",
                    color: "#82ca9d",
                  },
                  {
                    dataKey: "callerid",
                    name: "Caller id",
                    color: "#609EA2",
                  },
                  {
                    dataKey: "devir",
                    name: "Devir",
                    color: "#4e7a56",
                  },
                  {
                    dataKey: "diğer",
                    name: "Diğer",
                    color: "blue",
                  },
                  {
                    dataKey: "entegrasyonu_var",
                    name: "Entegrasyonu var",
                    color: "black",
                  },
                  {
                    dataKey: "fiyat",
                    name: "Fiyat",
                    color: "#C93D1B",
                  },
                  {
                    dataKey: "hatalı_basvuru",
                    name: "Hatalı Başvuru",
                    color: "gray",
                  },
                  {
                    dataKey: "ios_i̇şletim_sistemi",
                    name: "İos işletim sistemi",
                    color: "#778177",
                  },
                  {
                    dataKey: "i̇flas",
                    name: "İflas",
                    color: "#9DF1DF",
                  },
                  {
                    dataKey: "kurye_takip",
                    name: "Kurye takip",
                    color: "#A31ACB",
                  },
                  {
                    dataKey: "kuryesi_yok",
                    name: "Kuryesi yok",
                    color: "orange",
                  },
                  {
                    dataKey: "masa_modulu",
                    name: "Masa Modulu",
                    color: "#49FF00",
                  },
                  {
                    dataKey: "mükerrer",
                    name: "Mükerrer",
                    color: "#FDB827",
                  },
                  {
                    dataKey: "paket_servisi_yok",
                    name: "Paket servisi yok",
                    color: "#2192FF",
                  },
                  {
                    dataKey: "patron_modülü",
                    name: "Patron modülü",
                    color: "#FF1E1E",
                  },
                  {
                    dataKey: "stok_yönetimi",
                    name: "Stok Yönetimi",
                    color: "#2a5872",
                  },
                  {
                    dataKey: "tek_platform",
                    name: "Tek Platform",
                    color: "#70603b",
                  },
                  {
                    dataKey: "teknik_problem",
                    name: "Teknik problem",
                    color: "#C70A80",
                  },
                  {
                    dataKey: "ulaşılamadı",
                    name: "Ulaşılamadı",
                    color: "#FF85B3",
                  },
                  {
                    dataKey: "yanlış_numara",
                    name: "Yanlış numara",
                    color: "purple",
                  },
                  {
                    dataKey: "zincir_marka",
                    name: "Zincir Marka",
                    color: "#397864",
                  },
                ]}
                graph={graphs?.renewals_task_blocked}
                year={graphs?.year}
              />
            )}
          </Grid>
        </Grid>

        <SetupCountModal
          showModal={showModal}
          setShowModal={setShowModal}
          result={setupCount}
          title={"KURULUM ADETLERİ"}
          subTitle={"Adet"}
        />

        <SetupCountModal
          showModal={showCollectorModal}
          setShowModal={setShowCollectorModal}
          result={collectorData?.data}
          title={"TAHSİLAT MİKTARLARI"}
          subTitle={"Miktar"}
        />

        <SetupCountModal
          showModal={showCollectorRenewModal}
          setShowModal={setShowCollectorRenewModal}
          result={collectorDataRenew?.data}
          title={"YENİLEME TAHSİLAT MİKTARLARI"}
          subTitle={"Miktar"}
        />

        <SetupCountModal
          showModal={showCollectorTransactionModal}
          setShowModal={setShowCollectorTransactionModal}
          result={collectorTransactionData?.data}
          title={"YENİ GELEN ÖDEME"}
          subTitle={"Miktar"}
        />

        <SetupCountModal
          showModal={showCollectorTransactionModal}
          setShowModal={setShowCollectorTransactionModal}
          result={collectorTransactionData?.data}
          title={"YENİLEME GELEN ÖDEME"}
          subTitle={"Miktar"}
        />

        <MmaTotalModal
          showMmaModal={showMmaModal}
          setShowMmaModal={setShowMmaModal}
          mmaTotal={mmaTotal}
        />
      </div>
    </div>
  );
};

export default ManagerReports;
