import React, { useState } from "react";
import { Button, Paper } from "@mui/material";
import PushNotificationModal from "../../modals/pushNotificationModal";

function PushNotification({ sendPushNotificationFunc, id }) {
  const [messageHeader, setMessageHeader] = useState(null);
  const [message, setMessage] = useState(null);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Paper
          elevation={3}
          style={{
            width: "50%",
            margin: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: 10,
          }}
        >
          <div style={{ width: "90%" }}>
            <span style={{ fontWeight: 600 }}>Başlık</span>

            <input
              className="input"
              type="text"
              name="name"
              value={messageHeader}
              spellCheck="false"
              style={{
                width: "100%",
                borderWidth: 0,
                borderColor: "#d4d4d4",
                fontSize: 14,
                resize: "none",
                height: 40,
                backgroundColor: "#d4d4d4",
                borderRadius: 4,
                padding: 10,
                marginBottom: 5,
                marginTop: 5,
                fontFamily: "SgNr",
              }}
              autoComplete="off"
              onChange={(e) => {
                setMessageHeader(e.target.value);
              }}
            />
          </div>
          <div style={{ width: "90%" }}>
            <span style={{ fontWeight: 600 }}>İçerik</span>

            <textarea
              className="input"
              type="text"
              name="name"
              rows="2"
              value={message}
              spellCheck="false"
              style={{
                width: "100%",
                borderWidth: 0,
                borderColor: "#d4d4d4",
                fontSize: 14,
                fontWeight: "400",
                resize: "none",
                height: 120,
                backgroundColor: "#d4d4d4",
                borderRadius: 4,
                padding: 10,
                fontFamily: "SgNr",
                marginBottom: 10,
                marginTop: 10,
              }}
              autoComplete="off"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              multiline="true"
            />
          </div>
          <Button
            style={{
              alignSelf: "flex-end",
              backgroundColor: "#5a5757",
              borderRadius: 4,
              width: "30%",
              height: 30,
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
              marginBottom: 20,
              marginRight: 12,
            }}
            variant="contained"
            onClick={() => {
              setPushNotificationModal(true);
            }}
          >
            <span
              style={{
                fontFamily: "CoRg",
                fontWeight: 700,
              }}
            >
              Gönder
            </span>
          </Button>
        </Paper>
      </div>
      <PushNotificationModal
        pushNotificationModal={pushNotificationModal}
        setPushNotificationModal={setPushNotificationModal}
        messageHeader={messageHeader}
        message={message}
        setMessageHeader={setMessageHeader}
        setMessage={setMessage}
        sendPushNotificationFunc={sendPushNotificationFunc}
        id={id}
      />
    </>
  );
}

export default PushNotification;
