import {
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import restaurantService from "../../../services/restaurantService";
import { useToast } from "../../../components/snackbar";

export default function AddProductModal({
  openProductModal,
  setopenProductModal,
  selectedRest,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { success, alert } = useToast();
  const [checkedItems, setCheckedItems] = useState([]);

  const handleChangeRest = (e) => {
    const checkedItem = e.target.value;
    if (e.target.checked) {
      setCheckedItems([...checkedItems, parseInt(checkedItem)]);
    } else {
      setCheckedItems(
        checkedItems.filter((item) => item !== parseInt(checkedItem))
      );
    }
  };

  useEffect(() => {
    if (selectedRest) {
      let arr = [];
      selectedRest.products.map((item) => {
        if (item.status) {
          arr.push(item.id);
        }
      });
      setCheckedItems(arr);
    }
  }, [selectedRest]);

  const handleSaveProducts = async () => {
    let data = {
      restoff_id: selectedRest.user_id,
      products: checkedItems,
    };
    try {
      await restaurantService.post_restaurant_new_products(data);
      success("Yeni Ürün Başvurusu Oluşturuld");
    } catch (error) {
      alert("Hata oluştu");
    }
  };

  return (
    <Modal
      open={openProductModal}
      onClose={() => {
        setopenProductModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Yeni Ürün Başvurusu
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "600px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              maxHeight: 250,
              overflow: "auto",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {selectedRest?.products?.map((rest, i) => {
              return (
                <FormGroup key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={rest.id}
                        onChange={handleChangeRest}
                        checked={checkedItems.includes(rest.id)}
                      />
                    }
                    label={<Typography variant="span">{rest.name}</Typography>}
                  />
                </FormGroup>
              );
            })}
          </div>

          <Button
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "5px 10px",
              fontSize: "14px",
              margin: "4px 2px",
              cursor: "pointer",
            }}
            onClick={handleSaveProducts}
          >
            Kaydet
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
