import React, { useContext, useReducer, useEffect, useRef } from "react";
//mui
import { Button, IconButton, Paper } from "@mui/material";
//mui icons
import { ArrowBack } from "@mui/icons-material";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
import { productFunc } from "../productFunctions/productFunc";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  select: {
    width: 250,
    height: 30,
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
    },
  },
  input: {
    marginLeft: 10,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
}));
function MenuCreateProduct({
  currentHeader,
  setIsFlipped,
  productObj,
  setProductObj,
  id,
}) {
  const classes = useStyles();
  const scrollRefs = useRef(null);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const {
    optProductListFunc,
    optProductList,
    getProductList,
    productList,
    menuProductCreateFunc,
  } = useContext(RestaurantContext);

  useEffect(() => {
    if (currentHeader != null) {
      setTimeout(() => {
        scrollRefs?.current?.scrollIntoView({
          behavior: "instant",
        });
      }, 100);

      setProductObj({
        product_header: currentHeader?.id,
        name: "",
        description: "",
        price: "",
        options: [],
      });

      optProductListFunc(id);
      getProductList(id);
    }
  }, [currentHeader]);
  return (
    <>
      <div
        ref={scrollRefs}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => {
            setIsFlipped(false);
          }}
          style={{ color: "#fff", backgroundColor: "#f53b62" }}
        >
          <ArrowBack />
        </IconButton>
        <Paper
          elevation={2}
          style={{
            width: "auto",
            minWidth: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            padding: 5,
            margin: 12,
          }}
        >
          <span>{currentHeader?.name}</span>
        </Paper>
      </div>
      {productObj != null && (
        <div style={{ display: "flex" }}>
          {productFunc(
            productObj,
            setProductObj,
            forceUpdate,
            classes,
            optProductList,
            productList
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 30,
        }}
      >
        <Button
          style={{
            backgroundColor: "#f53b62",
            textTransform: "capitalize",
            boxShadow: "2px 3px 2.5px 1px #C5C5BB",
          }}
          onClick={() => {
            let data = {
              sendingObj: { product: productObj, restofficial_id: id },
              setIsFlipped,
            };
            menuProductCreateFunc(data);
          }}
          variant="contained"
          color="primary"
        >
          Oluştur
        </Button>
      </div>
    </>
  );
}

export default MenuCreateProduct;
