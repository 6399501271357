import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Select from "react-select";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import { selectDefaultStyles } from "../../../selectStyles/style";
import CustomDatePickerInput from "../../../datePickerInput";
import { LoginContext } from "../../../../contexts/loginContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useToast } from "../../../snackbar";
import managementService from "../../../../services/managementService";

registerLocale("tr", tr);

const userRoles = [
  {
    id: "collector",
    label: "Tahsilatçı",
  },
  {
    id: "setup",
    label: "Kurulumcu",
  },
  {
    id: "tech",
    label: "Teknik",
  },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },

  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "segoeuiRg",
    },
  },
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
}));

const Actions = ({
  selectedActionType,
  setSelectedActionType,
  selectedDeleteReason,
  setSelectedDeleteReason,
  setApplicationActionsModal,
  applicationId,
  pageStatus,
  setApplicationId,
  note,
  setNote,
  page,
  isWeb,
  startDate,
  setStartDate,
  data_type,
  createNewNote,
  setPageStatus,
}) => {
  const classes = useStyles();

  const {
    postRestaurantBlock,
    blockedReasons,
    blockedReasonFunc,
    stayTimeFunc,
    patchApplicationStatu,
    getTicketTypes,
    ticketTypes,
    sendTicket,
  } = useContext(RestaurantContext);

  const { getUserRole, userRole } = useContext(LoginContext);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [selfTicket, setSelfTicket] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState(null);
  const { alert } = useToast();
  const [notes, setNotes] = useState([]);

  const getNotes = async () => {
    await managementService.get_note_templates().then((res) => {
      setNotes(res);
    });
  };
  useEffect(() => {
    getUserRole();
    getNotes();
  }, []);

  useEffect(() => {
    if (selectedUserRole) {
      getTicketTypes(selectedUserRole.id);
    }
  }, [selectedUserRole]);

  return (
    <div
      style={{
        width: "96%",
        backgroundColor: "#fff",
        margin: 15,
        height: "88%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontFamily: "segoeuiB",
            fontSize: 16,
            textDecoration: "underline",
            width: "100%",
            marginLeft: 4,
          }}
        >
          Aksiyonlar
        </div>
        {/* {userRole === "collector" && data_type !== "application" && (
          <ShoppingCartIcon
            style={{
              color: "#fff",
              background: "#f53b62",
              padding: "5px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onMouseDown={(event) => {
              if (event.button === 1) {
                localStorage.setItem("offers", "offers");
                window.open(
                  `${window.location.origin}/restaurants/${applicationId.user_id}`,
                  "_blank"
                );
              }
            }}
          />
        )} */}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
        }}
      >
        {pageStatus == "home" ? (
          <div
            style={{
              width: isWeb ? "60%" : "90%",
              marginLeft: isWeb ? "20%" : "5%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl
              style={{
                width: "100%",
              }}
            >
              <RadioGroup
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: isWeb ? "row" : "column",
                  userSelect: "none",
                }}
                value={selectedActionType}
                onChange={(e) => {
                  setSelectedActionType(e.target.value);
                  setNote("");
                }}
              >
                <FormControlLabel
                  value="normal"
                  control={<Radio color="primary" />}
                  label={
                    <span style={{ fontFamily: "segoeuiRg" }}>Not Ekle</span>
                  }
                />
                {/* {data_type != "restofficial" && (
                  <FormControlLabel
                    value="stayTimer"
                    control={<Radio color="primary" />}
                    label={
                      <span style={{ fontFamily: "segoeuiRg" }}>
                        Zamanlayıcı
                      </span>
                    }
                  />
                )} */}

                {userRole !== "setup" && (
                  <FormControlLabel
                    value="delete"
                    control={<Radio color="primary" />}
                    label={
                      <span style={{ fontFamily: "segoeuiRg" }}>
                        {data_type == "application" ? "Sil" : "Bloke Et"}
                      </span>
                    }
                  />
                )}

                {userRole === "setup" && data_type === "application" ? (
                  <FormControlLabel
                    value="delete"
                    control={<Radio color="primary" />}
                    label={<span style={{ fontFamily: "segoeuiRg" }}>Sil</span>}
                  />
                ) : (
                  ""
                )}

                {(applicationId.restofficial || applicationId.user_id) && (
                  <FormControlLabel
                    value="ticket"
                    control={<Radio color="primary" />}
                    label={
                      <span style={{ fontFamily: "segoeuiRg" }}>
                        Ticket Gönder
                      </span>
                    }
                  />
                )}
              </RadioGroup>
            </FormControl>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          {pageStatus == "home" ? (
            selectedActionType == "normal" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h4 style={{ margin: 0, marginBottom: 10 }}>Not Şablonları</h4>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: 20,
                  }}
                >
                  {notes?.map((note, i) => {
                    return (
                      <>
                        {note?.text?.length > 30 ? (
                          <Tooltip
                            key={i}
                            style={{
                              maxWidth: 200,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              marginRight: 20,
                              background: "#f4f4f4",
                              padding: 5,
                              cursor: "pointer",
                              borderRadius: 5,
                            }}
                            title={note?.text}
                            onClick={() => {
                              setNote((prev) => {
                                return `${prev} ${note?.text}`;
                              });
                            }}
                          >
                            <div
                              style={{
                                width: "150px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                marginBottom: 20,
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                            >
                              {note.text}
                            </div>
                          </Tooltip>
                        ) : (
                          <div
                            onClick={() => {
                              setNote((prev) => {
                                return `${prev} ${note?.text}`;
                              });
                            }}
                            style={{
                              width: "150px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              marginBottom: 20,
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                          >
                            {note.text}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                <Paper
                  style={{
                    display: "flex",
                    width: isWeb ? "80%" : "96%",
                    backgroundColor: "#c9d1cc",
                    alignSelf: "center",
                    marginBottom: isWeb ? 0 : 10,
                  }}
                >
                  <InputBase
                    multili
                    value={note}
                    spellCheck={false}
                    style={{
                      width: "100%",
                      fontFamily: "segoeuiRg",
                      paddingLeft: 8,
                    }}
                    rows={3}
                    placeholder={"Not ekle"}
                    className={classes.input}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  />
                </Paper>
                <div
                  style={{
                    width: isWeb ? "80%" : "96%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#f53b62",
                      color: "#fff",
                      fontFamily: "segoeuiB",
                      width: isWeb ? 100 : "90%",
                      display: "flex",
                      justifyContent: "center",
                      height: 40,
                      marginLeft: 10,
                    }}
                    size="small"
                    onClick={() => {
                      let data = {
                        app_id:
                          data_type == "application"
                            ? applicationId?.id
                            : applicationId?.user_id,
                        note,
                        id: page + 1,
                        setNote,
                        setPageStatus,
                      };
                      if (note.length > 5) {
                        createNewNote(data);
                      } else {
                        alert("Not alanı en az 5 karakter olmalıdır.");
                      }
                    }}
                  >
                    Gönder
                  </Button>
                </div>
              </div>
            ) : selectedActionType == "stayTimer" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: isWeb ? "row" : "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: isWeb ? 0 : 12,
                    width: isWeb ? "50%" : "96%",
                  }}
                >
                  <DatePicker
                    inline
                    selected={startDate}
                    timeIntervals={5}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    minDate={moment(new Date()).toDate()}
                    dateFormat="d MMMM, HH:mm"
                    locale="tr"
                    customInput={<CustomDatePickerInput selectWidth={350} />}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    style={{
                      width: isWeb ? "80%" : "90%",
                      display: "flex",
                      justifyContent: "space-between",
                      minHeight: 42,
                      backgroundColor: "#c9d1cc",
                      alignSelf: "center",
                      margin: isWeb ? "0" : "10px 0",
                    }}
                  >
                    <InputBase
                      multiline
                      spellCheck={false}
                      style={{
                        width: "100%",
                        fontFamily: "segoeuiRg",
                        paddingLeft: 8,
                      }}
                      rows={5}
                      placeholder={"Not ekle"}
                      className={classes.input}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    />
                  </Paper>
                  <div
                    style={{
                      width: isWeb ? "80%" : "96%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#f53b62",
                        color: "#fff",
                        fontFamily: "segoeuiB",
                        width: isWeb ? 100 : "90%",
                        display: "flex",
                        justifyContent: "center",
                        height: 40,
                        marginLeft: 10,
                      }}
                      size="small"
                      onClick={() => {
                        let data = {
                          stay_id: applicationId?.id
                            ? applicationId?.id
                            : applicationId?.user_id,
                          table_type: data_type,
                          note,
                          stay_time: moment(startDate).toISOString(true),
                          setApplicationActionsModal,
                          pageStatus,
                          setSelectedActionType,
                          setNote,
                          setSelectedDeleteReason,
                          setStartDate,
                          setHours,
                          setMinutes,
                          setApplicationId,
                        };
                        if (note.length > 5) {
                          stayTimeFunc(data);
                        } else {
                          alert("Not alanı en az 5 karakter olmalıdır.");
                        }
                      }}
                    >
                      Gönder
                    </Button>
                  </div>
                </div>
              </div>
            ) : selectedActionType == "ticket" ? (
              <>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: isWeb ? "row" : "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: 20 }}>
                      <span
                        style={{
                          fontSize: 20,
                          marginBottom: 10,
                          display: "block",
                          fontWeight: "600",
                        }}
                      >
                        Kişi Rolü Seçiniz
                      </span>
                      <Select
                        responsiveHeight={30}
                        selectFontSize={16}
                        options={userRoles}
                        styles={selectDefaultStyles}
                        isSearchable={false}
                        zIndex={90}
                        hoverColor={"#f53b62"}
                        menuColor={"#f53b62"}
                        selectWidth={250}
                        placeholder={"Seçiniz"}
                        value={selectedUserRole}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                          setSelectedUserRole(e);
                          setSelectedTicketType(null);
                        }}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {e.label}
                          </div>
                        )}
                      />
                    </div>

                    <div>
                      <span
                        style={{
                          fontSize: 20,
                          marginBottom: 10,
                          display: "block",
                          fontWeight: "600",
                        }}
                      >
                        Ticket Tipi Seçiniz
                      </span>
                      <Select
                        responsiveHeight={30}
                        selectFontSize={16}
                        options={ticketTypes}
                        styles={selectDefaultStyles}
                        isSearchable={false}
                        zIndex={90}
                        hoverColor={"#f53b62"}
                        menuColor={"#f53b62"}
                        selectWidth={250}
                        placeholder={"Seçiniz"}
                        value={selectedTicketType}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                          setSelectedTicketType(e);
                        }}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {e.ticket_type}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <Paper
                    style={{
                      display: "flex",
                      backgroundColor: "#c9d1cc",
                      alignSelf: "center",
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <InputBase
                      multiline
                      value={note}
                      spellCheck={false}
                      style={{
                        width: "100%",
                        fontFamily: "segoeuiRg",
                        paddingLeft: 8,
                      }}
                      rows={3}
                      placeholder={"Not ekle"}
                      className={classes.input}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    />
                  </Paper>

                  <div style={{ display: "flex" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setSelfTicket(!selfTicket);
                            }}
                            checked={selfTicket}
                          />
                        }
                        label={"Self Ticket"}
                      />
                    </FormGroup>
                    <Button
                      style={{
                        backgroundColor: "#f53b62",
                        color: "#fff",
                        fontFamily: "segoeuiB",
                        width: isWeb ? 100 : "90%",
                        display: "flex",
                        justifyContent: "center",
                        height: 40,
                        marginLeft: "auto",
                      }}
                      size="small"
                      onClick={() => {
                        let data;
                        if (!selectedTicketType || !selectedUserRole)
                          return alert("Lütfen alanları doldurunuz");
                        if (selfTicket) {
                          data = {
                            ticket_type: selectedTicketType.id,
                            creation_note: note,
                            restofficial: applicationId.hasOwnProperty(
                              "restofficial"
                            )
                              ? applicationId?.restofficial
                              : applicationId?.user_id,
                            processed_by: JSON.parse(
                              localStorage.getItem("st-officeuser-info")
                            ).user_id,
                          };
                        } else {
                          data = {
                            ticket_type: selectedTicketType.id,
                            creation_note: note,
                            restofficial: applicationId.hasOwnProperty(
                              "restofficial"
                            )
                              ? applicationId?.restofficial
                              : applicationId?.user_id,
                          };
                        }

                        let data2 = {
                          app_id:
                            data_type == "application"
                              ? applicationId?.id
                              : applicationId?.user_id,
                          note,
                          id: page + 1,
                          setNote,
                          setPageStatus,
                        };
                        setSelfTicket(false);
                        sendTicket(data, data2);
                        setSelectedTicketType(null);
                        setSelectedUserRole(null);
                        setNote("");
                      }}
                    >
                      Gönder
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: isWeb ? "row" : "column",
                  width: selectedDeleteReason?.id === "other" ? "96%" : "50%",
                  alignItems: "center",
                }}
              >
                <Select
                  responsiveHeight={30}
                  selectFontSize={16}
                  options={blockedReasons}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={90}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  selectWidth={250}
                  placeholder={"Tümü"}
                  value={selectedDeleteReason}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setSelectedDeleteReason(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.label}
                    </div>
                  )}
                />
                {selectedDeleteReason && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: isWeb ? "row" : "column",
                      justifyContent: "center",
                    }}
                  >
                    {selectedDeleteReason?.id === "other" && (
                      <Paper
                        style={{
                          width: isWeb ? "80%" : "90%",
                          display: "flex",
                          justifyContent: "space-between",
                          minHeight: 42,
                          backgroundColor: "#c9d1cc",
                          alignSelf: "center",
                          margin: isWeb ? "0" : "10px 0",
                        }}
                      >
                        <InputBase
                          spellCheck="false"
                          style={{
                            width: "100%",
                            fontFamily: "segoeuiRg",
                            paddingLeft: 8,
                          }}
                          rows={isWeb ? 1 : 3}
                          placeholder={"Not ekle"}
                          className={classes.input}
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </Paper>
                    )}
                    <Button
                      style={{
                        backgroundColor: "#f53b62",
                        color: "#fff",
                        fontFamily: "segoeuiB",
                        width: isWeb ? 100 : "90%",
                        display: "flex",
                        justifyContent: "center",
                        height: 32,
                        marginLeft: 10,
                      }}
                      size="small"
                      onClick={() => {
                        if (
                          data_type == "tracker" ||
                          data_type == "restofficial"
                        ) {
                          postRestaurantBlock(
                            data_type,
                            applicationId?.user_id,
                            {
                              blocked: true,
                              deleted_reason: selectedDeleteReason.id,
                              deleted_reason_note:
                                selectedDeleteReason?.id === "other"
                                  ? note
                                  : "",
                            }
                          );
                          setApplicationActionsModal(false);
                        } else {
                          let data = {
                            app_id: applicationId.id,
                            status: "deleted",
                            deleted_reason: selectedDeleteReason,
                            setApplicationActionsModal,
                            deleted_reason_note:
                              selectedDeleteReason?.id === "other" ? note : "",
                          };
                          blockedReasonFunc(data);
                        }
                      }}
                    >
                      Gönder
                    </Button>
                  </div>
                )}
              </div>
            )
          ) : (
            <Button
              style={{
                backgroundColor: "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: 100,
                display: "flex",
                justifyContent: "center",
                height: 40,
                marginLeft: 30,
                marginTop: 30,
              }}
              size="small"
              onClick={() => {
                if (pageStatus == "waiting") {
                  let data = {
                    stay_id: applicationId?.id,
                    table_type: data_type,
                    note: "",
                    stay_time: "",
                    setApplicationActionsModal,
                    pageStatus,
                    setSelectedActionType,
                    setNote,
                    setSelectedDeleteReason,
                    setStartDate,
                    setHours,
                    setMinutes,
                    setApplicationId,
                  };
                  stayTimeFunc(data);
                } else {
                  patchApplicationStatu(applicationId?.id, "revoke_deleted");
                  setPageStatus("home");
                  setApplicationActionsModal(false);
                  setApplicationId(null);
                }
              }}
            >
              Geri Al
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Actions;
