import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import "../../../components/tableCss/table.css";
import { platformImageConverterBig } from "../../../components/converter/platformImage";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { GreenSwitch } from "../../../components/switch/greenSwitch";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      fontFamily: "segoeuiRg",
      backgroundColor: "#f4f4f4",
      color: "#000",
      borderRadius: 4,
    },
  },
});

const WorkingHour = () => {
  const classes = useStyles();
  let { id } = useParams();
  const {
    getRestaurantWorkingHours,
    postRestaurantWorkingHours,
    restaurantWorkingHours,
    setRestaurantWorkingHours,

    getRestaurantInfo,
    restOfficialData,

    platformList,
    getPlatformList,
  } = useContext(RestaurantContext);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [platformRes, setPlatformRes] = useState([]);
  const [selectedPlatformRes, setSelectedPlatformRes] = useState(null);
  const [hours, setHours] = useState({ start: "00:00", end: "23:50" });

  useEffect(() => {
    getRestaurantInfo(id);
    getPlatformList();
  }, []);

  useEffect(() => {
    if (selectedPlatform != null) {
      let res_test = [];

      restOfficialData?.platforms?.length != 0 &&
        restOfficialData?.platforms
          ?.filter((info) => info.platform?.id == selectedPlatform?.id)
          ?.map((row, ind) => {
            res_test.push(row);
          });
      setPlatformRes(res_test);
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (platformRes?.length != 0) {
      setSelectedPlatformRes(platformRes[0]);
    }
  }, [platformRes]);

  useEffect(() => {
    if (selectedPlatformRes != null) {
      getRestaurantWorkingHours(id, selectedPlatformRes?.id);
    }
  }, [selectedPlatformRes]);

  const handleDateChange = (name, index) => (event) => {
    const tempworkingHoursList = [...restaurantWorkingHours];
    if (name == "end" && event.target.value == "00:00") {
      tempworkingHoursList[index].hours[name] = "23:59";
    } else {
      tempworkingHoursList[index].hours[name] = event.target.value;
    }

    setRestaurantWorkingHours(tempworkingHoursList);
  };

  const closeDay = (row, index) => {
    let tempworkingHoursList = [...restaurantWorkingHours];
    if (!tempworkingHoursList[index]?.is_active) {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "23:59";
    } else {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "00:00";
    }
    tempworkingHoursList[index].is_active =
      !tempworkingHoursList[index].is_active;
    setRestaurantWorkingHours(tempworkingHoursList);
  };

  const handleAllChange = (startEnd, e) => {
    if (!e) return "";

    if (startEnd === "start") {
      setHours({ ...hours, start: e.target.value });
    } else {
      if (e.target.value == "00:00") {
        setHours({ ...hours, end: "23:59" });
      } else {
        setHours({ ...hours, end: e.target.value });
      }
    }
  };

  const applyAllDay = (index) => {
    let tempworkingHoursList = [...restaurantWorkingHours];
    tempworkingHoursList = tempworkingHoursList?.map((item, index, array) => {
      item.hours.start = hours.start;
      item.hours.end = hours.end;
      item.is_active = true;
      return item;
    });
    setRestaurantWorkingHours(tempworkingHoursList);
  };

  const allDayOpenOrClose = (row, index) => {
    let tempworkingHoursList = [...restaurantWorkingHours];
    if (
      tempworkingHoursList[index]?.hours.start == "00:00" &&
      tempworkingHoursList[index]?.hours.end == "23:59"
    ) {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "23:00";
    } else {
      tempworkingHoursList[index].hours.start = "00:00";
      tempworkingHoursList[index].hours.end = "23:59";
    }

    setRestaurantWorkingHours(tempworkingHoursList);
  };

  const applyAllDayOpenOrClose = () => {
    let tempworkingHoursList = [...restaurantWorkingHours];
    tempworkingHoursList = tempworkingHoursList?.map((item, index, array) => {
      if (hours.start == "00:00" && hours.end == "23:59") {
        setHours({ start: "00:00", end: "23:50" });
        item.hours.start = "00:00";
        item.hours.end = "23:50";
        return item;
      } else {
        setHours({ start: "00:00", end: "23:59" });
        item.hours.start = "00:00";
        item.hours.end = "23:59";
        return item;
      }
    });
    setRestaurantWorkingHours(tempworkingHoursList);
  };
  return (
    <Box component="main" sx={styles.box}>
      <div
        style={{
          height: "98%",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <div
            style={{
              width: "100%",
              height: 80,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              minHeight: 80,
            }}
          >
            {platformList?.map((row, index) => {
              let disabledObj = [];

              disabledObj = restOfficialData?.platforms?.map((info) => {
                if (info.platform.id == row?.id) {
                  return true;
                } else {
                  return false;
                }
              });

              const disabledChecked = disabledObj?.findIndex(
                (item) => item == true
              );

              return (
                <div
                  key={index}
                  style={{
                    cursor: disabledChecked == -1 ? "default" : "pointer",
                  }}
                  onClick={() => {
                    if (disabledChecked != -1) {
                      setSelectedPlatform(row);
                      setRestaurantWorkingHours([]);
                      setSelectedPlatformRes(null);
                    }
                  }}
                  disabled={
                    disabledChecked == -1 || row?.name == "Yemeksepeti"
                      ? true
                      : false
                  }
                >
                  {row?.name === "Yemeksepeti" && (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 14,
                            fontFamily: "segoeuiRg",
                            padding: 1,
                            bgcolor: "#082657",
                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                      title={
                        "Yemeksepeti çalışma saatlerinin entegrasyon firmaları üzerinden değiştirilmesine izin vermemektedir.Değişiklik işlemini MyRestoran üzerinden yapınız."
                      }
                      arrow
                      placement="bottom"
                    >
                      <img
                        alt="yemeksepeti"
                        style={{ width: 120, height: 70 }}
                        src={platformImageConverterBig(
                          row?.name + (disabledChecked == -1 ? "md" : "")
                        )}
                      />
                    </Tooltip>
                  )}
                  {row?.name === "Trendyol" && (
                    <img
                      alt="Trendyol"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Gofody" && (
                    <img
                      alt="Gofody"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Getir" && (
                    <img
                      alt="Getir"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Migros" && (
                    <img
                      alt="Migros"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                  {row?.name === "Fuudy" && (
                    <img
                      alt="Fuudy"
                      style={{ width: 120, height: 70 }}
                      src={platformImageConverterBig(
                        row?.name + (disabledChecked == -1 ? "md" : "")
                      )}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: "98%",
              height: 65,
              display: "flex",
              flexDirection: "row",
              minHeight: 65,
              alignItems: "center",
              alignSelf: "center",
              overflow: "auto",
              marginTop: 20,
            }}
          >
            {platformRes?.map((row, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor:
                      selectedPlatformRes?.id == row?.id ? "green" : "#f53b62",
                    padding: "1px 6px 1px 6px",
                    fontSize: 13,
                    color: "white",
                    borderRadius: 4,
                    fontFamily: "segoeuiRg",
                    minWidth: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: 100,
                    height: "80%",
                    flexDirection: "column",
                    marginLeft: 5,
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    setSelectedPlatformRes(row);
                  }}
                >
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      cursor: "pointer",
                    }}
                  >
                    Tabela
                  </span>
                  {(row?.restaurant_name).length > 16 ? (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 14,
                            fontFamily: "segoeuiRg",
                            padding: 1,
                            bgcolor: "#082657",
                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                      title={row?.restaurant_name}
                      arrow
                      placement="top"
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 100,
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "segoeuiRg",
                            cursor: "pointer",
                          }}
                        >
                          {row?.restaurant_name}
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <>{row?.restaurant_name}</>
                  )}
                </div>
              );
            })}
          </div>
          {selectedPlatformRes != null && (
            <div style={{ marginTop: 16 }}>
              <TableContainer>
                <Table aria-label="simple table" style={{ color: "#000" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <span style={{ fontFamily: "segoeuiRg" }}>Günler</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ fontFamily: "segoeuiRg" }}>
                          Açılış Saati
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ fontFamily: "segoeuiRg" }}>
                          Kapanış Saati
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ fontFamily: "segoeuiRg" }}>
                          24 saat Açık / Kapalı
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ fontFamily: "segoeuiRg" }}>Durum</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <span style={{ fontFamily: "segoeuiRg" }}> Tümü </span>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          className={classes.root}
                          variant="filled"
                          id="time"
                          label={
                            <span
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 15,
                                backgroundColor: "#F4F4F4",
                                color: "#000",
                              }}
                            >
                              Açılış Saati
                            </span>
                          }
                          type="time"
                          value={hours.start}
                          onChange={(e) => {
                            handleAllChange("start", e);
                          }}
                          style={{
                            width: 260,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          className={classes.root}
                          variant="filled"
                          id="time"
                          label={
                            <span
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 15,
                                backgroundColor: "#F4F4F4",
                                color: "#000",
                              }}
                            >
                              Kapanış Saati
                            </span>
                          }
                          type="time"
                          value={hours.end}
                          onChange={(e) => {
                            handleAllChange("end", e);
                          }}
                          style={{
                            width: 260,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormControlLabel
                          control={
                            <GreenSwitch
                              checked={
                                hours?.start == "00:00" && hours?.end == "23:59"
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                applyAllDayOpenOrClose();
                              }}
                              name="checkedG"
                            />
                          }
                          label={
                            <span style={{ fontFamily: "segoeuiRg" }}>
                              Tüm Hafta Açık
                            </span>
                          }
                          labelPlacement="top"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          style={{
                            backgroundColor: "#f1b62a",
                            borderRadius: 12,
                            width: "auto",
                            alignSelf: "flex-end",
                            marginBottom: 15,
                          }}
                          variant="contained"
                          onClick={() => applyAllDay()}
                        >
                          <span
                            style={{
                              fontFamily: "segoeuiRg",
                              fontWeight: 700,
                              //color: "white",
                            }}
                          >
                            Tüm Günler
                          </span>
                        </Button>
                      </TableCell>
                    </TableRow>

                    {restaurantWorkingHours &&
                      restaurantWorkingHours?.map((row, index) => (
                        <TableRow key={`workingHoursList${row.day}`}>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 15,
                              }}
                            >
                              {row.day == "Monday"
                                ? "Pazartesi"
                                : row.day == "Tuesday"
                                ? "Salı"
                                : row.day == "Wednesday"
                                ? "Çarşamba"
                                : row.day == "Thursday"
                                ? "Perşembe"
                                : row.day == "Friday"
                                ? "Cuma"
                                : row.day == "Saturday"
                                ? "Cumartesi"
                                : "Pazar"}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              className={classes.root}
                              variant="filled"
                              id="time"
                              label={
                                <span
                                  style={{
                                    fontFamily: "segoeuiRg",
                                    fontSize: 15,
                                    backgroundColor: "#F4F4F4",
                                    color: "#000",
                                  }}
                                >
                                  Açılış Saati
                                </span>
                              }
                              type="time"
                              value={row?.hours?.start}
                              onChange={handleDateChange("start", index)}
                              disabled={!row.is_active ? true : false}
                              style={{
                                width: 260,
                                backgroundColor: "#F4F4F4",
                                color: "#000",
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300,
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              className={classes.root}
                              variant="filled"
                              id="time"
                              label={
                                <span
                                  style={{
                                    fontFamily: "segoeuiRg",
                                    fontSize: 15,
                                    color: "#000",
                                  }}
                                >
                                  Kapanış Saati
                                </span>
                              }
                              type="time"
                              value={row?.hours?.end}
                              onChange={handleDateChange("end", index)}
                              disabled={!row.is_active ? true : false}
                              style={{
                                width: 260,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300,
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FormControlLabel
                              control={
                                <GreenSwitch
                                  checked={
                                    row?.hours?.start == "00:00" &&
                                    row?.hours?.end == "23:59"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    allDayOpenOrClose(row, index);
                                  }}
                                  name="checkedG"
                                />
                              }
                              label={
                                <span style={{ fontFamily: "segoeuiRg" }}>
                                  24 saat Açık
                                </span>
                              }
                              labelPlacement="top"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FormControlLabel
                              control={
                                <GreenSwitch
                                  checked={row.is_active == true ? true : false}
                                  onChange={() => {
                                    closeDay(row, index);
                                  }}
                                  name="checkedG"
                                />
                              }
                              label={
                                row.is_active ? (
                                  <span style={{ fontFamily: "segoeuiRg" }}>
                                    Bugün Açık
                                  </span>
                                ) : (
                                  <span style={{ fontFamily: "segoeuiRg" }}>
                                    Bugün Kapalı
                                  </span>
                                )
                              }
                              labelPlacement="top"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 8,
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#f1b62a",
                    borderRadius: 12,
                    width: "20%",
                    alignSelf: "end",
                  }}
                  variant="contained"
                  onClick={() => {
                    let data = {
                      secret_key: selectedPlatformRes?.id,
                      working_hours: restaurantWorkingHours,
                    };
                    postRestaurantWorkingHours(
                      id,
                      selectedPlatformRes?.id,
                      data
                    );
                  }}
                >
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      fontWeight: 700,
                    }}
                  >
                    Güncelle
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};
const styles = {
  box: {
    width: "83%",
    backgroundColor: "#f4f4f4",
    borderRadius: 2,
    height: "100%",
    marginBottom: 5,
    marginLeft: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
};

export default WorkingHour;
