import React, { useContext } from "react";

import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useToast } from "../../../components/snackbar";
import { DevelopmentContext } from "../../../contexts/developmentContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function ChangeColorModal({
  openColorModal,
  setOpenColorModal,
  taskDetail,
  type,
  subTaskDetail,
}) {
  const { alert } = useToast();
  const classes = useStyles();
  const [color, setColor] = useColor("#561ecb");
  const { changeTaskGeneralStatus, changeSubTaskFeature } =
    useContext(DevelopmentContext);

  const handleSaveColor = () => {
    if (type === "task") {
      changeTaskGeneralStatus(taskDetail.id, { color: color.hex });
    } else {
      changeSubTaskFeature(
        subTaskDetail.id,
        {
          color: color.hex,
        },
        taskDetail.id
      );
    }

    setOpenColorModal(false);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openColorModal}
      className={classes.modal}
      onClose={() => {
        setOpenColorModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "25%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            padding: 15,
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
          }}
        >
          <ColorPicker
            color={color}
            onChange={setColor}
            hideInput={["rgb", "hsv"]}
            hideAlpha
            height={200}
          />
        </div>

        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            size="small"
            style={{
              fontFamily: "segoeuiB",
              width: "50%",
              fontWeight: 700,
              backgroundColor: "red",
              marginRight: 20,
            }}
            onClick={() => {
              setOpenColorModal(false);
            }}
          >
            Reddet
          </Button>

          <Button
            variant="contained"
            size="small"
            style={{
              fontFamily: "segoeuiB",
              width: "50%",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
            }}
            onClick={handleSaveColor}
          >
            Onayla
          </Button>
        </div>
      </Card>
    </Modal>
  );
}
