import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

// * platforms
import Getir from "./platforms/getir";
import { useHorizontalScroll } from "../menu/scroll/horizontalScroll";
import OverflowTip from "../../../components/overflowtip";
import Gofody from "./platforms/gofody";
import Fuudy from "./platforms/fuudy";
import Trendyol from "./platforms/trendyol";
import Migros from "./platforms/migros";
import Yemeksepeti from "./platforms/yemeksepeti";

const useStyles = makeStyles(() => ({
  overflowStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",

    overflowX: "auto",
    alignItems: "center",
    justifyContent: "space-between",
    overflowY: "hidden",
    marginBottom: 15,
    scrollbarWidth: "thin",
    cursor: "pointer",
    scrollbarColor: "#B7B7B7 gray",
    "&::-webkit-scrollbar": {
      height: 8,
      backgroundColor: "#f4f4f4",
      borderRadius: 4,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "darkgray",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: `linear-gradient(to right,  ${"royalblue"} 0%,${"#f1b62a"} 100%)`,
      minHeight: 12,
      minWidth: 12,
      width: 12,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: `linear-gradient(to right,  ${"royalblue"} 0%,${"#f1b62a"} 100%)`,
    },
  },
  input: {
    marginLeft: 10,
    "& input::placeholder": {
      fontSize: 12,
    },
  },
}));

const SecretKeys = () => {
  const classes = useStyles();
  const scrollRefs = useRef([]);
  const scrollRef = useHorizontalScroll();
  let { id } = useParams();
  const {
    secretKeyPlatformSummaryFunc,
    secretKeyPlatformSummary,
    secretKeyPlatformInfoFunc,
    secretKeyPlatformInfo,
    postSecretKey,
    deleteRestofficialPlatforms,
    ysRemoteIdListFunc,
    ysRemoteIdList,
    ysRemoteIdCreateFunc,
    ysRemoteIdDeleteFunc,
    changeSecretKey,
  } = useContext(RestaurantContext);
  const [selectedSecretKeyPlatform, setSelectedSecretKeyPlatform] =
    useState(null);
  const [platformRestaurants, setPlatformRestaurants] = useState(null);
  const [selectedRestaurants, setSelectedRestaurant] = useState(null);
  const [selectedSecretKeyPlatformName, setSelectedSecretKeyPlatformName] =
    useState(null);
  const officeUser = JSON.parse(localStorage.getItem("st-officeuser-info"));

  useEffect(() => {
    secretKeyPlatformSummaryFunc({ rest_id: id });
  }, []);

  scrollRefs.current = [...Array(platformRestaurants?.length).keys()].map(
    (_, i) => scrollRefs.current[i] ?? createRef()
  );

  const scrollSmoothHandler = (index) => () => {
    setSelectedRestaurant(`header_${index}`);
    scrollRefs.current[index].current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (secretKeyPlatformInfo !== null) {
      setPlatformRestaurants(secretKeyPlatformInfo);
    }
  }, [secretKeyPlatformInfo]);

  return (
    <div
      style={{
        margin: "10px 24px",
        width: "96%",
      }}
    >
      {officeUser?.role === "tech" ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {secretKeyPlatformSummary?.map((row) => {
              return (
                <Paper
                  elevation={2}
                  sx={{
                    height: "auto",
                    padding: 2,
                    minWidth: 150,
                    maxWidth: 150,
                    marginTop: 2,
                    backgroundColor:
                      row?.id === selectedSecretKeyPlatform ? "green" : "#fff",
                    color:
                      row?.id === selectedSecretKeyPlatform ? "#fff" : "#000",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    secretKeyPlatformInfoFunc({ id: id, platform_id: row?.id });
                    setSelectedSecretKeyPlatform(row?.id);
                    setPlatformRestaurants(row?.secret_keys);
                    setSelectedSecretKeyPlatformName(row?.name);
                    setSelectedRestaurant(`header_${0}`);
                    scrollRefs.current[0].current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  <div>
                    {row?.name} ({row?.secret_key_count})
                  </div>
                </Paper>
              );
            })}
          </div>

          {selectedSecretKeyPlatform !== null &&
            secretKeyPlatformInfo !== null && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 15,
                  }}
                >
                  {selectedSecretKeyPlatform !== 2 && (
                    <div ref={scrollRef} className={classes.overflowStyle}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          minWidth: 100,
                          height: 50,
                        }}
                      >
                        {platformRestaurants?.map((row, i) => {
                          return (
                            <Paper
                              key={i}
                              elevation={2}
                              onClick={scrollSmoothHandler(i)}
                              style={{
                                textTransform: "capitalize",
                                boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                                width: "auto",
                                minWidth: 150,
                                maxWidth: 150,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                padding: 2,
                                marginTop: 3,
                                marginRight: 15,
                                marginBottom: 10,
                                fontSize: 12,
                                color:
                                  selectedRestaurants == `header_${i}`
                                    ? "royalblue"
                                    : "black",
                              }}
                            >
                              {row?.restaurant_name?.length > 10 ? (
                                <OverflowTip
                                  note={row?.restaurant_name}
                                  style={{
                                    fontSize: 12,
                                  }}
                                />
                              ) : (
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                    fontSize: 12,
                                  }}
                                >
                                  {row?.restaurant_name}
                                </span>
                              )}
                            </Paper>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {selectedSecretKeyPlatformName === "Getir" ? (
                    <Getir
                      secretKeyPlatformInfo={secretKeyPlatformInfo}
                      id={id}
                      selectedSecretKeyPlatform={selectedSecretKeyPlatform}
                      postSecretKey={postSecretKey}
                      scrollRefs={scrollRefs}
                      deleteRestofficialPlatforms={deleteRestofficialPlatforms}
                      changeSecretKey={changeSecretKey}
                    />
                  ) : selectedSecretKeyPlatformName === "Gofody" ? (
                    <Gofody
                      secretKeyPlatformInfo={secretKeyPlatformInfo}
                      id={id}
                      selectedSecretKeyPlatform={selectedSecretKeyPlatform}
                      postSecretKey={postSecretKey}
                      scrollRefs={scrollRefs}
                      deleteRestofficialPlatforms={deleteRestofficialPlatforms}
                    />
                  ) : selectedSecretKeyPlatformName === "Fuudy" ? (
                    <Fuudy
                      secretKeyPlatformInfo={secretKeyPlatformInfo}
                      id={id}
                      selectedSecretKeyPlatform={selectedSecretKeyPlatform}
                      postSecretKey={postSecretKey}
                      scrollRefs={scrollRefs}
                      deleteRestofficialPlatforms={deleteRestofficialPlatforms}
                    />
                  ) : selectedSecretKeyPlatformName === "Trendyol" ? (
                    <Trendyol
                      secretKeyPlatformInfo={secretKeyPlatformInfo}
                      id={id}
                      selectedSecretKeyPlatform={selectedSecretKeyPlatform}
                      postSecretKey={postSecretKey}
                      scrollRefs={scrollRefs}
                      deleteRestofficialPlatforms={deleteRestofficialPlatforms}
                    />
                  ) : selectedSecretKeyPlatformName === "Migros" ? (
                    <Migros
                      secretKeyPlatformInfo={secretKeyPlatformInfo}
                      id={id}
                      selectedSecretKeyPlatform={selectedSecretKeyPlatform}
                      postSecretKey={postSecretKey}
                      scrollRefs={scrollRefs}
                      deleteRestofficialPlatforms={deleteRestofficialPlatforms}
                    />
                  ) : selectedSecretKeyPlatformName === "Yemeksepeti" ? (
                    <Yemeksepeti
                      secretKeyPlatformInfo={secretKeyPlatformInfo}
                      id={id}
                      selectedSecretKeyPlatform={selectedSecretKeyPlatform}
                      postSecretKey={postSecretKey}
                      scrollRefs={scrollRefs}
                      deleteRestofficialPlatforms={deleteRestofficialPlatforms}
                      ysRemoteIdListFunc={ysRemoteIdListFunc}
                      ysRemoteIdList={ysRemoteIdList}
                      ysRemoteIdCreateFunc={ysRemoteIdCreateFunc}
                      ysRemoteIdDeleteFunc={ysRemoteIdDeleteFunc}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
        </>
      ) : (
        "Teknik Bölüm"
      )}
    </div>
  );
};

export default SecretKeys;
