import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Question from "./Question";
import AddBoxIcon from "@mui/icons-material/AddBox";
import managementService from "../../../../../../services/managementService";

export default function Questions({ questions, setQuestions, packageId }) {
  const handleAddQuestion = () => {
    let newQuestion = {
      question: "",
      answer: "",
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleSaveQuestions = async () => {
    const addedQuestions = questions.filter((question) => {
      if (!question.id) {
        return question;
      }
    });
    let data = {
      question_answers: addedQuestions,
    };
    await managementService.post_package_questions(packageId, data);

    const packs = await managementService.get_packages();
    packs.map((pack) => {
      if (pack.id === packageId) {
        setQuestions(pack.question_answers);
      }
    });
  };

  return (
    <Box>
      <Box sx={{ background: "#f1f1f1" }}>
        {questions.map((question, i) => {
          return (
            <Question
              packageId={packageId}
              question={question}
              index={i}
              key={i}
              questions={questions}
              setQuestions={setQuestions}
            />
          );
        })}

        <Box
          sx={{
            background: "#fff",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: 1,
            width: "fit-content",
            marginLeft: "auto",
          }}
          onClick={handleAddQuestion}
        >
          <span
            style={{
              marginRight: 10,
              display: "block",
              color: "#585858",
              fontFamily: "segoeuiRg",
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            Ekle
          </span>
          <AddBoxIcon style={{ color: "red" }} />
        </Box>
      </Box>

      <Box>
        <Button
          style={{
            backgroundColor: "#f53f62",
            color: "#fff",
            fontFamily: "segoeuiRg",
            width: 200,
            marginTop: 15,
            display: "flex",
            justifyContent: "center",
            height: 40,
            textTransform: "capitalize",
            marginLeft: "auto",
          }}
          size="small"
          onClick={handleSaveQuestions}
        >
          Soru Cevapları Kaydet
        </Button>
      </Box>
    </Box>
  );
}
