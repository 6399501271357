import { Box, Button, createTheme, Modal, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";

export default function UserDeleteModal({
  openDeleteUserModal,
  setOpenDeleteUserModal,
  dashboardOfficeuserDetail,
  setCallerFlipped,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { dashboardOfficeuserDetailUserDelete } = useContext(ManagementContext);

  const handleUserDelete = async () => {
    dashboardOfficeuserDetailUserDelete(dashboardOfficeuserDetail.user_id);
    setOpenDeleteUserModal(false);
    setCallerFlipped(false);
  };

  return (
    <Modal
      open={openDeleteUserModal}
      onClose={() => {
        setOpenDeleteUserModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "monospace",
          }}
        >
          Kullanıcı Silme
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "600px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontFamily: "monospace",
              fontSize: 16,
            }}
          >
            {dashboardOfficeuserDetail?.full_name} isimli kullanıcıyı silmek
            istediğinize emin misiniz?
          </div>

          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "monospace",
                width: "100%",
                fontWeight: 700,
                backgroundColor: "red",
                marginTop: 20,
                marginRight: 20,
              }}
              onClick={() => {
                setOpenDeleteUserModal(false);
              }}
            >
              Hayır
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "monospace",
                width: "100%",
                fontWeight: 700,
                backgroundColor: "#0FBD20",
                marginTop: 20,
              }}
              onClick={handleUserDelete}
            >
              Evet
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
