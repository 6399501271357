import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import useWindowDimensions from "../../../components/windowSize";
import { ManagementContext } from "../../../contexts/managementContext";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev2";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { useParams } from "react-router-dom";
import RegistrationField from "../../../components/registrationField";
import { useToast } from "../../../components/snackbar";
import moment from "moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TablePaginationActions from "../../../components/pagination/mainPagination";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import BaskiRequestModal from "../../../components/modals/baskiRequestModal";
import BrochureModal from "../../../components/modals/brochure";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

const discountArr = [];
for (let i = 0; i <= 100; i += 5) {
  const percent = {
    value: i,
    label: `${i} %`,
  };
  discountArr.push(percent);
}

const cashbackArr = [];
for (let i = 5; i <= 100; i++) {
  const percent = {
    value: i,
    label: `${i} %`,
  };
  cashbackArr.push(percent);
}

export default function CpKurulum() {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { alert } = useToast();
  const { id } = useParams();
  const {
    baskiTypes,
    getBaskiTypes,
    getPrintingPressList,
    printingPressList,
    getPrintingRequestList,
    printingRequestList,
    brochurePicture,
    setBrochurePicture,
    getPrintingRequestDetail,
    createPrintingRequest,
    setBrochureResResult,
  } = useContext(ManagementContext);
  const [selectedBaskiType, setSelectedBaskiType] = useState(null);
  const [selectedGramaj, setSelectedGramaj] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [selectedPrinterUser, setSelectedPrinterUser] = useState(null);
  const [selectedCashBack, setSelectedCashkBack] = useState(null);
  const [selectedBaskiData, setSelectedBaskiData] = useState({
    grammages: [],
    sizes: [],
    quantities: [],
    directions: [],
  });
  const [firstOrderForm, setFirstOrderForm] = useState({
    first_order_discount_min_price: 0,
    first_order_discount_amount: 0,
  });
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openBaskiRequestModal, setOpenBaskiRequestModal] = useState(false);
  const [cargoDeliveryForm, setCargoDeliveryForm] = useState({
    cargo_delivery_address: "",
  });
  const [brochureModal, setBrochureModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getPrintingPressList();
    getBaskiTypes();
  }, []);

  useEffect(() => {
    if (printingPressList.length > 0 && baskiTypes.length > 0) {
      setSelectedPrinterUser({
        value: printingPressList[0].user.username,
        label: printingPressList[0].user.username,
        id: printingPressList[0].user.id,
      });
      setSelectedBaskiType({
        value: baskiTypes[0].printing_type_title,
        label: baskiTypes[0].printing_type_title,
        id: baskiTypes[0].id,
      });
      setSelectedGramaj({
        value: baskiTypes[0].grammages[0],
        label: baskiTypes[0].grammages[0],
      });
      setSelectedSize({
        value: baskiTypes[0].sizes[0],
        label: baskiTypes[0].sizes[0],
      });
      setSelectedQuantity({
        value: baskiTypes[0].quantities[0],
        label: baskiTypes[0].quantities[0],
      });
      setSelectedDirection({
        value: baskiTypes[0].directions[0],
        label: baskiTypes[0].directions[0],
      });

      setSelectedDiscount(discountArr[2]);
      setSelectedCashkBack(cashbackArr[0]);
      setFirstOrderForm({
        first_order_discount_min_price: 250,
        first_order_discount_amount: 100,
      });
    }
  }, [printingPressList, baskiTypes]);

  useEffect(() => {
    getPrintingRequestList(page + 1, id);
  }, [page]);

  useEffect(() => {
    if (selectedBaskiType) {
      baskiTypes.forEach((type) => {
        if (type.printing_type_title === selectedBaskiType.value) {
          setSelectedBaskiData({
            grammages: type.grammages,
            sizes: type.sizes,
            quantities: type.quantities,
            directions: type.directions,
          });
        }
      });
    }
  }, [selectedBaskiType]);

  const handleSavePrintRequest = () => {
    if (
      !selectedPrinterUser ||
      !selectedBaskiType ||
      !selectedGramaj ||
      !selectedSize ||
      !selectedQuantity ||
      !selectedDirection ||
      !selectedCashBack ||
      !firstOrderForm.first_order_discount_min_price ||
      !firstOrderForm.first_order_discount_amount
    )
      return alert("Lütfen tüm alanları doldurunuz");

    const data = {
      printingpress_id: selectedPrinterUser.id,
      restofficial_id: id,
      printing_type_id: selectedBaskiType.id,
      grammage: selectedGramaj.value,
      quantity: selectedQuantity.value,
      size: selectedSize.value,
      direction: selectedDirection.value,
      first_order_discount_min_price:
        firstOrderForm.first_order_discount_min_price,
      first_order_discount_amount: firstOrderForm.first_order_discount_amount,
      order_discount_percent: selectedDiscount ? selectedDiscount.value : 0,
      cashback_percent: selectedCashBack.value,
      cargo_delivery_address: cargoDeliveryForm.cargo_delivery_address
        ? cargoDeliveryForm.cargo_delivery_address
        : "",
    };
    createPrintingRequest(data, page);
  };

  return (
    <Box component="main">
      <div
        style={{
          height: "95%",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: 20,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <h2 style={{ textAlign: "center" }}>Baskı İsteği Oluştur</h2>

          <div style={{ display: "flex" }}>
            <div style={{ width: "20%", marginBottom: 10 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Matbaa Seçiniz
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={printingPressList.map((type) => {
                    return {
                      value: type.user.username,
                      label: type.user.username,
                      id: type.user.id,
                    };
                  })}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3}
                  value={selectedPrinterUser}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedPrinterUser(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.label}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "20%", marginBottom: 10 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Baskı Tipi Seçiniz
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={baskiTypes.map((type) => {
                    return {
                      value: type.printing_type_title,
                      label: type.printing_type_title,
                      id: type.id,
                    };
                  })}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3}
                  value={selectedBaskiType}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedBaskiType(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.label}</span>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            {selectedBaskiType && (
              <>
                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Gramaj Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.grammages.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedGramaj}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedGramaj(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Boyut Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.sizes.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedSize}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedSize(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Adet Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.quantities.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedQuantity}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedQuantity(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Baskı Yönü Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.directions.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedDirection}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedDirection(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div>
            <RegistrationField
              fieldHeader={"Teslimat Adresi"}
              fieldValue={cargoDeliveryForm?.cargo_delivery_address}
              fieldName={"cargo_delivery_address"}
              formData={cargoDeliveryForm}
              setFormData={setCargoDeliveryForm}
            />
          </div>

          <div style={{ display: "flex", marginTop: 20 }}>
            <div style={{ width: "33%", marginBottom: 10 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                İndirim
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={discountArr}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3}
                  value={selectedDiscount}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedDiscount(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.label}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "33%", marginBottom: 10, marginRight: 10 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Yedikçe Kazan
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={cashbackArr}
                  styles={selectDefaultStyles}
                  isSearchable={true}
                  hoverColor={"black"}
                  menuColor={"#f4f4f4"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3}
                  value={selectedCashBack}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedCashkBack(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.label}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "antiquewhite",
                borderRadius: 20,
                padding: 10,
                width: "33%",
              }}
            >
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                İlk Sipariş Kupon
              </span>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <RegistrationField
                    fieldHeader={""}
                    fieldValue={firstOrderForm?.first_order_discount_min_price}
                    fieldName={"first_order_discount_min_price"}
                    formData={firstOrderForm}
                    setFormData={setFirstOrderForm}
                  />
                </div>

                <span>ye</span>

                <div style={{ marginLeft: 14 }}>
                  <RegistrationField
                    fieldHeader={""}
                    fieldValue={firstOrderForm?.first_order_discount_amount}
                    fieldName={"first_order_discount_amount"}
                    formData={firstOrderForm}
                    setFormData={setFirstOrderForm}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: 20,
              marginTop: 10,
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#0FBD20",
                width: 200,
              }}
              onClick={handleSavePrintRequest}
            >
              Kaydet
            </Button>
          </div>

          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Baskı İstekleri
          </span>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Teslimat Adresi</TableCell>
                  <TableCell align="center">Oluşturulma Tarihi</TableCell>
                  <TableCell align="center">Restoran Adı</TableCell>
                  <TableCell align="center">Oluşturan Kişi</TableCell>
                  <TableCell align="center">Matbaa</TableCell>
                  <TableCell align="center">Baskı Tipi</TableCell>
                  <TableCell align="center">Gramaj</TableCell>
                  <TableCell align="center">Boyut</TableCell>
                  <TableCell align="center">Adet</TableCell>
                  <TableCell align="center">Baskı Yönü</TableCell>
                  <TableCell align="center">Broşür</TableCell>
                  <TableCell align="center">Detay</TableCell>
                  <TableCell align="center">Durum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {printingRequestList?.results?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        <Tooltip title={row.cargo_delivery_address}>
                          <InfoIcon style={{ color: "#f53b62" }} />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.created_at).format("DD.MM.YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {row.restofficial.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.officeuser.full_name}
                      </TableCell>
                      <TableCell align="center">
                        {row.printingpress.company_title}
                      </TableCell>
                      <TableCell align="center">
                        {row.printing_type.printing_type_title}
                      </TableCell>
                      <TableCell align="center">{row.grammage}</TableCell>
                      <TableCell align="center">{row.size}</TableCell>
                      <TableCell align="center">{row.quantity}</TableCell>
                      <TableCell align="center">{row.direction}</TableCell>
                      <TableCell align="center">
                        <PictureAsPdfIcon
                          onClick={() => {
                            setBrochureResResult(null);
                            setBrochureModal(true);
                            setCurrentRow(row);
                          }}
                          style={{ color: "#f53b62", cursor: "pointer" }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InfoIcon
                          style={{ color: "#f53b62", cursor: "pointer" }}
                          onClick={() => {
                            getPrintingRequestDetail(row.id);
                            setOpenBaskiRequestModal(true);
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {row.request_status === "waiting_confirm"
                          ? "Matbaa Onay Bekliyor"
                          : row.request_status === "waiting_offer"
                          ? "Matbaa'ya yönlendirildi"
                          : row.request_status === "created"
                          ? "Baskı İsteği Oluşturuldu"
                          : row.request_status === "confirm" &&
                            row.is_document_downloaded
                          ? "PDF Baskıda"
                          : row.request_status === "confirm" &&
                            !row.is_document_downloaded
                          ? "Finans Onayladı"
                          : row.request_status === "shipped"
                          ? "Kargoya Verildi"
                          : row.request_status === "delivered"
                          ? "Teslim Edildi"
                          : row.request_status === "checking"
                          ? "Teslim Edildi"
                          : row.request_status === "complated"
                          ? "Teslim Edildi"
                          : row.request_status === "unapproved"
                          ? "Matbaa Onay Bekleniyor"
                          : row.request_status === "ro_accepted"
                          ? "Restoran onayladı"
                          : row.request_status === "ro_rejected"
                          ? "Restoran reddetti"
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  {printingRequestList?.results != null && (
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      labelRowsPerPage={"Sayfa Başına Kayıt"}
                      count={printingRequestList?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      classes={{ root: classes.root }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <BaskiRequestModal
        openBaskiRequestModal={openBaskiRequestModal}
        setOpenBaskiRequestModal={setOpenBaskiRequestModal}
        baskiTypes={baskiTypes}
        printingRequestList={printingRequestList}
        brochurePicture={brochurePicture}
        setBrochurePicture={setBrochurePicture}
        page={page}
        restId={id}
      />

      <BrochureModal
        brochureModal={brochureModal}
        setBrochureModal={setBrochureModal}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
    </Box>
  );
}
