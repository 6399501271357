import sepettakipLogo from "../../utils/assets/sepettakipLogos/sepettakip-logo.webp";
import sepetappLogo from "../../utils/assets/sepettakipLogos/sepetapp-logo.webp";
import sepetfastLogo from "../../utils/assets/sepettakipLogos/sepetfast-logo.webp";
import calleridLogo from "../../utils/assets/sepettakipLogos/callerid-logo.webp";
import courierLogo from "../../utils/assets/sepettakipLogos/courier-logo.webp";
import stoktakipLogo from "../../utils/assets/sepettakipLogos/stoktakip-logo.webp";
import masatakipLogo from "../../utils/assets/sepettakipLogos/masatakip-logo.webp";
import coklutabelaLogo from "../../utils/assets/sepettakipLogos/cokluTabela-logo.png";
import callerPlusLogo from "../../utils/assets/sepettakipLogos/callerplus-logo.webp";

const orderProductsImage = (product_order) => {
  if (product_order === "sepettakip") return sepettakipLogo;
  if (product_order === "sepetapp") return sepetappLogo;
  if (product_order === "restoranapp") return sepetappLogo;
  if (product_order === "sepetfast") return sepetfastLogo;
  if (product_order === "callerid") return calleridLogo;
  if (product_order === "kuryetakip") return courierLogo;
  if (product_order === "stoktakip") return stoktakipLogo;
  if (product_order === "masatakip") return masatakipLogo;
  if (product_order === "coklutabela") return coklutabelaLogo;
  if (product_order === "callerplus") return callerPlusLogo;

  return product_order;
};

export { orderProductsImage };
