import {
  DescriptionTwoTone,
  KeyboardVoice,
  Notifications,
  Reply,
} from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import moment from "moment";
import React from "react";

const RenewalActions = ({
  setCurrentRestRow,
  setVoiceModal,
  summaryRestDetailInfo,
  setReminderRow,
  setReminderModal,
  setActionModal,
  setActionModalResId,
  row,
  appointmentTaskBackFunc,
  summaryRestDetailFunc,
}) => {
  return (
    <div style={{ marginTop: 10 }}>
      <div
        style={{
          width: "100%",
          height: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 4.5px 1px gray",
          fontFamily: "segoeuiB",
          fontSize: 13,
          fontVariant: "all-petite-caps",
        }}
      >
        Aksiyonlar
      </div>
      <div
        style={{
          width: "100%",
          height: 40,
          backgroundColor: "#494343",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => {
            setVoiceModal(true);
            setCurrentRestRow(row);
          }}
        >
          <KeyboardVoice
            style={{
              color: "green",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            setReminderRow(summaryRestDetailInfo);
            setReminderModal(true);
          }}
        >
          <Notifications
            style={{
              cursor: "pointer",
              color:
                summaryRestDetailInfo?.reminders?.length !== 0
                  ? summaryRestDetailInfo?.reminders[0]?.sender_role ===
                    "officeuser"
                    ? "green"
                    : "red"
                  : "gray",
            }}
          />
        </IconButton>

        <IconButton
          onClick={() => {
            setActionModal(true);
            setActionModalResId({
              user_id: row?.restofficial?.id,
            });
          }}
        >
          <DescriptionTwoTone
            style={{
              color: "green",
            }}
          />
        </IconButton>
        {summaryRestDetailInfo &&
          summaryRestDetailInfo.hasOwnProperty("calendar") &&
          summaryRestDetailInfo?.calendar !== null && (
            <div
              style={{
                boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                borderRadius: 4,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "row",
                backgroundColor: "#393737",
                padding: "0px 10px",
                height: 36,
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  color: "#f4f4f4",
                  fontFamily: "monospace",
                  textAlign: "center",
                }}
              >
                {moment(summaryRestDetailInfo?.calendar?.start_at).format(
                  "DD-MM-YYYY HH:mm"
                )}
                <br />
                {summaryRestDetailInfo?.calendar?.title}
              </div>
              <Divider
                orientation="vertical"
                sx={{
                  backgroundColor: "#f4f4f4",
                  width: "0.1px",
                  height: 30,
                  margin: "0px 10px",
                }}
              />
              <Reply
                onClick={() => {
                  appointmentTaskBackFunc({
                    id: summaryRestDetailInfo?.calendar?.id,
                  });
                  setTimeout(() => {
                    summaryRestDetailFunc({
                      rest_id: row?.restofficial?.id,
                      sendObj: {
                        state: "in_progress",
                      },
                    });
                  }, 1000);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default RenewalActions;
