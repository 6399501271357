import { FileDownload, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useContext } from "react";

import sepetTakip from "../../utils/assets/sepet_takip.png";
import spet from "../../utils/assets/spet.png";
import useWindowDimensions from "../../components/windowSize";

import { LoginContext } from "../../contexts/loginContext";

import { withStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

const LoginTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#f53b62",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#f53b62",
      },
    },
  },
})(TextField);

const Login = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const { loginFunc, loginLoading, loginSellerFunc } = useContext(LoginContext);
  const { enqueueSnackbar } = useSnackbar();
  const { height } = useWindowDimensions();
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVis, setPasswordVis] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("operation");

  const isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  const checkLogin = () => {
    if (isBlank(mail)) {
      enqueueSnackbar("E-posta alanı boş olamaz.", {
        variant: "info",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
    } else if (isBlank(password)) {
      enqueueSnackbar("Şifre alanını kontrol ediniz.", {
        variant: "info",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
    } else {
      if (selectedUserType == "operation") {
        loginFunc({
          username: mail,
          password,
        });
      } else {
        loginSellerFunc({
          username: mail,
          password,
        });
      }
    }
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      checkLogin();
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        style={{
          backgroundColor: "#f4f4f4",
          height: isMatch ? height - 5 : "30%",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img
          alt="sepet"
          src={spet}
          width={isMatch ? "100%" : 400}
          height={isMatch ? "100%" : 400}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        style={{
          height: isMatch ? height - 5 : "70%",
          marginTop: isMatch ? 0 : 20,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Button
            style={{
              textTransform: "capitalize",
              backgroundColor: "#f53b62",
              fontFamily: "segoeuiRg",
              marginBottom: 10,
              position: "absolute",
              top: "20px",
              right: "20px",
            }}
            onClick={() => {
              window.open(`https://api.sepettakip.com/download`, "_blank");
            }}
            variant="contained"
            startIcon={<FileDownload />}
          >
            Sepettakip İndir
          </Button>
          <div>
            <img
              alt="sepettakip"
              src={sepetTakip}
              width={isMatch ? 498 : 380}
              height={80}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: isMatch ? 450 : 320,
              height: 45,
              marginTop: 15,
            }}
          >
            <Paper
              elevation={1}
              onClick={() => {
                setSelectedUserType("operation");
              }}
              style={{
                width: isMatch ? "90%" : "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  selectedUserType == "operation" ? "#f53b62" : "#f4f4f4",
                cursor: "pointer",
                color: selectedUserType == "operation" ? "#fff" : "#000",
                fontFamily: "segoeuiRg",
              }}
            >
              Operasyon Giriş
            </Paper>
            <Divider
              orientation="vertical"
              style={{ marginRight: 10, marginLeft: 10 }}
            />
            <Paper
              elevation={1}
              onClick={() => {
                setSelectedUserType("seller");
              }}
              style={{
                width: isMatch ? "90%" : "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  selectedUserType == "seller" ? "#f53b62" : "#f4f4f4",
                cursor: "pointer",
                color: selectedUserType == "seller" ? "#fff" : "#000",
                fontFamily: "segoeuiRg",
              }}
            >
              Bayi Giriş
            </Paper>
          </div>

          <div style={{ marginTop: 10, textAlign: "center" }}>
            <LoginTextField
              style={{ width: isMatch ? "100%" : "90%" }}
              variant="filled"
              margin="normal"
              required
              spellCheck={false}
              id="email"
              label="E-posta"
              name="email"
              autoComplete="email"
              autoFocus
              inputProps={{ style: { fontFamily: "segoeuiRg", color: "gray" } }}
              InputLabelProps={{
                style: { fontFamily: "segoeuiRg", color: "gray" },
              }}
              onChange={(e) => setMail(e.target.value)}
            />
            <LoginTextField
              style={{ width: isMatch ? "100%" : "90%" }}
              variant="filled"
              margin="normal"
              required
              onKeyDown={keyPress}
              spellCheck={false}
              name="password"
              label="Şifre"
              type={passwordVis ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              inputProps={{ style: { fontFamily: "segoeuiRg", color: "gray" } }}
              InputLabelProps={{
                style: { fontFamily: "segoeuiRg", color: "gray" },
              }}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setPasswordVis(!passwordVis);
                      }}
                      onMouseDown={() => {
                        setPasswordVis(!passwordVis);
                      }}
                    >
                      {passwordVis && <Visibility />}
                      {!passwordVis && <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{
              marginTop: 25,
              width: "90%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingButton
              loading={loginLoading}
              loadingIndicator=""
              style={{
                backgroundColor: loginLoading ? "gray" : "#f53f62",
                width: 485,
                height: 50,
                color: "#ffffff",
                fontFamily: "segoeuiRg",
                textTransform: "capitalize",
                fontSize: 18,
                marginBottom: isMatch ? 0 : 30,
              }}
              type="submit"
              onClick={() => {
                checkLogin();
              }}
            >
              {loginLoading ? "Lütfen bekleyin" : "Giriş Yap"}
            </LoadingButton>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
