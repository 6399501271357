import { GreenSwitch } from "../../../../../components/switch/greenSwitch";

function ProductOpenOrClose({ title, cond, onClick }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#fff",
      }}
    >
      {title}
      <GreenSwitch checked={cond} onClick={onClick} />
    </div>
  );
}

export default ProductOpenOrClose;
