import { Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputField from "../../../../../components/input";
import { useState } from "react";
import managementService from "../../../../../services/managementService";
import { useEffect } from "react";
import { useToast } from "../../../../../components/snackbar";

export default function PageHeader() {
  const { success } = useToast();
  const [pageTitleData, setPageTitleData] = useState({
    header: "",
    header_description: "",
    discount_header: "",
    sub_header: "",
    euro_tl: null,
  });

  const handleSavePageHeader = async () => {
    let data = {
      header: pageTitleData.header,
      header_description: pageTitleData.header_description,
      discount_header: pageTitleData.discount_header,
      sub_header: pageTitleData.sub_header,
      euro_tl: pageTitleData.euro_tl,
    };
    await managementService.post_offer_page_header(data);
    success("Sayfa başlığı güncellendi");
  };

  const getPageHeader = async () => {
    const res = await managementService.get_offer_page_header();
    setPageTitleData(res);
  };

  useEffect(() => {
    getPageHeader();
  }, []);

  return (
    <Box sx={{ display: "flex", marginBottom: 2 }}>
      <Box sx={{ marginRight: 2 }}>
        <InputField
          label="Sayfa Başlık"
          fieldName="header"
          formData={pageTitleData}
          setFormData={setPageTitleData}
          fieldValue={pageTitleData?.header}
        />
      </Box>

      <Box sx={{ marginRight: 2 }}>
        <InputField
          label="Alt Başlık"
          fieldName="header_description"
          formData={pageTitleData}
          setFormData={setPageTitleData}
          fieldValue={pageTitleData?.header_description}
        />
      </Box>

      <Box sx={{ marginRight: 2 }}>
        <InputField
          label="İndirim Başlık"
          fieldName="discount_header"
          formData={pageTitleData}
          setFormData={setPageTitleData}
          fieldValue={pageTitleData?.discount_header}
        />
      </Box>

      <Box sx={{ marginRight: 2 }}>
        <InputField
          label="Alt Başlık"
          fieldName="sub_header"
          formData={pageTitleData}
          setFormData={setPageTitleData}
          fieldValue={pageTitleData?.sub_header}
        />
      </Box>

      <Box sx={{ marginRight: 2 }}>
        <InputField
          label="Kur Gir"
          fieldName="euro_tl"
          formData={pageTitleData}
          setFormData={setPageTitleData}
          fieldValue={pageTitleData?.euro_tl}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SaveIcon
          style={{ color: "red", cursor: "pointer" }}
          onClick={handleSavePageHeader}
        />
      </Box>
    </Box>
  );
}
