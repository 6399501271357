import { Button } from "@mui/material";
import { forwardRef } from "react";
import dateP from "../../utils/assets/date-picker.png";

const CustomDatePickerInput = forwardRef(
  ({ value, onClick, selectedWidth, color }, ref) => (
    <Button
      style={{
        backgroundColor: color ? color : "#f53b62",
        color: "#fff",
        fontFamily: "segoeuiRg",
        width: selectedWidth,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 36,
      }}
      size="small"
      onClick={onClick}
      ref={ref}
    >
      <img style={{ marginRight: 14 }} src={dateP} width={18} height={18} />
      {value}
    </Button>
  )
);

export default CustomDatePickerInput;
