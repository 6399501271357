import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { useEffect, useState } from "react";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import useWindowDimensions from "../../../../components/windowSize";
import managementService from "../../../../services/managementService";
import GridItem from "../../../../components/gridItem";
import moment from "moment";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import restaurantService from "../../../../services/restaurantService";
import { useToast } from "../../../../components/snackbar";

const nextDay = moment().add(1, "days");
const nextWeek = moment().add(1, "weeks");
const nextTwoWeek = moment().add(2, "weeks");
const nextMonth = moment().add(1, "M");

const convertedNextDay = moment(nextDay).format("YYYY-MM-DD");
const convertedNextWeek = moment(nextWeek).format("YYYY-MM-DD");
const convertedNextTwoWeek = moment(nextTwoWeek).format("YYYY-MM-DD");
const convertedNextMonth = moment(nextMonth._d).format("YYYY-MM-DD");

const expireDates = [
  {
    label: "1 Gün",
    date: convertedNextDay,
  },
  {
    label: "1 Hafta",
    date: convertedNextWeek,
  },
  {
    label: "2 Hafta",
    date: convertedNextTwoWeek,
  },
  {
    label: "1 Ay",
    date: convertedNextMonth,
  },
];

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

export default function CampaignManagement() {
  const classes = useStyles();
  const { success, alert } = useToast();

  const { width } = useWindowDimensions();

  const [smsTypes, setSmsTypes] = useState(null);
  const [blockedReasons, setBlockedReasons] = useState(null);
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [selectedDeleted, setSelectedDeleted] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [amount, setAmount] = useState("");
  const [showAmount, setShowAmount] = useState(false);
  const [expireDate, setExpireDate] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [smsReports, setSmsReports] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [response, setResponse] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCampaignSmsTypes = async () => {
    const res = await managementService.campaign_sms_types();
    setSmsTypes(res);
  };

  const getBlockedReasons = async () => {
    const res = await managementService.blocked_reasons();
    setBlockedReasons(res);
  };

  const getTotalCount = async (type = "", reason = "") => {
    const res = await managementService.getSms(type, reason);
    setTotalCount(res);
  };

  const getSmsReports = async () => {
    const res = await managementService.get_sms_reports(page + 1);
    setSmsReports(res);
  };

  useEffect(() => {
    getSmsReports();
  }, [page]);

  const handleChangeNotification = (e) => {
    setSelectedNotificationType(e);
  };

  const handleChangeType = (e) => {
    setSelectedDataType(e);
    if (e.key === "deleted" || e.key === "blocked") {
      setSelectedDeleted(true);
    } else {
      setSelectedDeleted(false);
    }
  };

  const handleChangeReasons = (e) => {
    const checkedItem = e.target.value;
    if (e.target.checked) {
      setCheckedItems([...checkedItems, checkedItem]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== checkedItem));
    }
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleChangeExpireDate = (e) => {
    setExpireDate(e);
  };

  const handleSubmitCampaign = async () => {
    setIsSubmitting(true);
    let data;
    if (phone.length > 0) {
      data = {
        test: true,
        phone,
        sms_type: selectedNotificationType.key,
        message,
        data_type: selectedDataType.key,
        product: selectedProduct ? selectedProduct?.key : "",
      };
    } else if (selectedNotificationType.key === "product") {
      data = {
        data_type: selectedDataType.key,
        reasons: checkedItems,
        amount,
        expired_at: expireDate.date,
        message,
        sms_type: selectedNotificationType.key,
        product: selectedProduct ? selectedProduct?.key : "",
      };
    } else {
      data = {
        data_type: selectedDataType.key,
        reasons: checkedItems,
        amount,
        expired_at: expireDate.date,
        message,
        sms_type: selectedNotificationType.key,
      };
    }

    await managementService
      .postSmsCampaign(data)
      .then((res) => {
        success("Başarılı");
        setIsSubmitting(false);
      })
      .catch((err) => {
        alert("Sms Gönderilemedi");
        setIsSubmitting(false);
      });

    setMessage("");
    setExpireDate("");
    setAmount("");
    setSelectedDataType(false);
    setSelectedDeleted(false);
    setSelectedNotificationType(null);
    const ids = blockedReasons.map((reason) => {
      return reason.id;
    });
    setCheckedItems(ids);
    getSmsReports();
  };

  useEffect(() => {
    if (selectedNotificationType) {
      if (
        selectedNotificationType.key === "gift" ||
        selectedNotificationType.key === "product"
      ) {
        setShowAmount(true);
      } else {
        setShowAmount(false);
      }
    }
  }, [selectedNotificationType]);

  useEffect(() => {
    if (selectedDataType) {
      getTotalCount(selectedDataType.key, checkedItems.toString());
    }
  }, [checkedItems]);

  useEffect(() => {
    if (selectedDataType) {
      getTotalCount(selectedDataType.key);
    }
  }, [selectedDataType]);

  useEffect(() => {
    if (blockedReasons) {
      const ids = blockedReasons.map((reason) => {
        return reason.id;
      });
      setCheckedItems(ids);
    }
  }, [blockedReasons]);

  const getNotificationTypes = async () => {
    const res = await restaurantService.get_notification_types();
    setResponse(res);
  };

  const handleChangeProduct = (e) => {
    setSelectedProduct(e);
  };

  useEffect(() => {
    getCampaignSmsTypes();
    getBlockedReasons();
    getNotificationTypes();
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", marginBottom: 4 }}>
        <Box sx={{ marginRight: 4 }}>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Bildirim Tipi
            </span>
            <Select
              options={smsTypes?.sms_types}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              zIndex={500}
              isSearchable={false}
              hoverColor={"black"}
              selectWidth={width > 1200 ? 230 : 140}
              menuColor={"#f53b62"}
              placeholder={"Bildirim Tipi Seç"}
              value={selectedNotificationType}
              getOptionValue={(option) => option.key}
              onChange={(e) => {
                handleChangeNotification(e);
              }}
              getOptionLabel={(option) => option.value}
            />
          </div>
        </Box>

        {selectedNotificationType?.key === "product" && (
          <Box sx={{ marginRight: 4 }}>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Ürün Seç
              </span>
              <Select
                options={response?.products}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                zIndex={500}
                isSearchable={false}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 230 : 140}
                menuColor={"#f53b62"}
                placeholder={"Ürün Seç"}
                value={selectedProduct}
                getOptionValue={(option) => option.key}
                onChange={(e) => {
                  handleChangeProduct(e);
                }}
                getOptionLabel={(option) => option.value}
              />
            </div>
          </Box>
        )}

        <Box sx={{ marginRight: 4 }}>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Data Tipi
            </span>
            <Select
              options={smsTypes?.data_types}
              styles={{
                ...selectDefaultStyles,
                height: "555px",
              }}
              isSearchable={false}
              hoverColor={"black"}
              selectWidth={width > 1200 ? 230 : 140}
              zIndex={500}
              menuColor={"#f53b62"}
              placeholder={"Data Tipi Seç"}
              value={selectedDataType}
              getOptionValue={(option) => option.key}
              onChange={(e) => {
                handleChangeType(e);
              }}
              getOptionLabel={(option) => option.value}
            />
          </div>
        </Box>

        <Box>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Test Telefon No
          </span>
          <InputBase
            style={{
              height: 36,
              background: "#fff",
              padding: 6,
              fontSize: 16,
              fontWeight: "400",
              fontFamily: "segoeuiRg",
            }}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Box>
      </Box>

      {selectedDeleted && (
        <Box sx={{ marginBottom: 4 }}>
          <span
            style={{
              fontSize: 20,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Silinme Bloke Sebepleri
          </span>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {blockedReasons?.map((reason, i) => {
              return (
                <FormGroup key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={reason.id}
                        onChange={handleChangeReasons}
                        checked={checkedItems.includes(reason.id)}
                      />
                    }
                    label={reason.label}
                  />
                </FormGroup>
              );
            })}
          </Box>
        </Box>
      )}

      {selectedDataType && (
        <Box sx={{ display: "flex", marginBottom: 4 }}>
          <GridItem title={"Toplam"} count={totalCount.count} center={true} />

          {showAmount && (
            <Box sx={{ marginLeft: 4 }}>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Tutar Gir
              </span>
              <InputBase
                style={{
                  background: "#fff",
                  padding: 6,
                  fontSize: 16,
                  fontWeight: "400",
                  fontFamily: "segoeuiRg",
                }}
                value={amount}
                onChange={handleChangeAmount}
              />
            </Box>
          )}

          <Box sx={{ marginLeft: 4 }}>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Geçerlilik Süresi
              </span>
              <Select
                options={expireDates}
                styles={{ ...selectDefaultStyles, height: "555px" }}
                isSearchable={false}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 230 : 140}
                menuColor={"#f53b62"}
                placeholder={"Süre Seç"}
                value={expireDate}
                getOptionValue={(option) => option.date}
                onChange={(e) => {
                  handleChangeExpireDate(e);
                }}
                getOptionLabel={(option) => option.label}
              />
            </div>
          </Box>
        </Box>
      )}

      <Box sx={{ marginBottom: 4 }}>
        <span
          style={{
            fontSize: 20,
            marginBottom: 10,
            display: "block",
            fontWeight: "600",
          }}
        >
          Sms İçerik Gir
        </span>
        <TextareaAutosize
          style={{
            width: "100%",
            minHeight: "50px",
            fontFamily: "segoeuiRg",
            padding: "10px",
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            backgroundColor: !isSubmitting ? "#f53f62" : "#b70023",
            color: "#fff",
            fontFamily: "segoeuiB",
            width: 100,
            marginTop: 15,
            display: "flex",
            justifyContent: "center",
            height: 40,
            textTransform: "capitalize",
          }}
          size="small"
          onClick={handleSubmitCampaign}
          disabled={isSubmitting}
        >
          Gönder
        </Button>
      </Box>

      <span
        style={{
          fontSize: 20,
          marginBottom: 10,
          display: "block",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Geçmiş Sms Raporları
      </span>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Geçerlilik Süresi</TableCell>
              <TableCell align="center">Tarih</TableCell>
              <TableCell align="center">Bildirim Tipi</TableCell>
              <TableCell align="center">Data Tipi</TableCell>
              <TableCell align="center">Sms İçerik</TableCell>
              <TableCell align="center">Toplam</TableCell>
              <TableCell align="center">İndirim Tutarı</TableCell>
              <TableCell align="center">Toplam Tutar</TableCell>
              <TableCell align="center">Geri Dönen</TableCell>
              <TableCell align="center">Toplam Kurulan</TableCell>
              <TableCell align="center">Toplam Kullanılan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smsReports?.results.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    {moment(row?.expired_at).format("DD-MM-YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell align="center">{row.day}</TableCell>
                  <TableCell align="center">{row.giftcard_type}</TableCell>
                  <TableCell align="center">{row.data_type}</TableCell>
                  <TableCell align="center">{row.text}</TableCell>
                  <TableCell align="center">{row.count}</TableCell>
                  <TableCell align="center">{row.discount_amount}</TableCell>
                  <TableCell align="center">{row.total_amount}</TableCell>
                  <TableCell align="center">{row.total_return}</TableCell>
                  <TableCell align="center">{row.total_setup}</TableCell>
                  <TableCell align="center">{row.total_usage}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {smsReports?.results != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={smsReports?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
