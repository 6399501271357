import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { RestaurantContext } from "../../../contexts/restaurantContext";

export default function UserLoggedModal({ showModal, setShowModal }) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    getStateHistory,
    stateHistory,
    getStateHistoryDaily,
    stateHistoryDaily,
  } = useContext(RestaurantContext);

  useEffect(() => {
    getStateHistoryDaily();
  }, []);

  useEffect(() => {
    let data = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      userId: JSON.parse(localStorage.getItem("st-officeuser-info")).user_id,
    };
    getStateHistory(data);
  }, []);

  const calculateTimeLeft = (startTime, endTime) => {
    const remainingTime = moment.duration(
      moment(moment(startTime).diff(endTime))
    );

    const diffDays = remainingTime.days();
    const diffHours = remainingTime.hours();
    const diffMinutes = remainingTime.minutes();
    const diffSeconds = remainingTime.seconds();

    return ` ${diffDays !== 0 ? `${diffDays} g` : ""} ${
      diffHours !== 0 ? `${diffHours} s` : ""
    }  ${diffMinutes !== 0 ? `${diffMinutes} dk` : ""}  ${
      diffSeconds !== 0 ? `${diffSeconds} sn` : ""
    } `;
  };

  const calculateActiveTime = () => {
    if (stateHistoryDaily) {
      const totalActiveSecond =
        stateHistoryDaily.state_stats.active +
        stateHistoryDaily.state_stats.setup +
        stateHistoryDaily.state_stats.dictionary;

      const duration = moment.duration(totalActiveSecond, "seconds");
      const diffDays = duration.days();
      const diffHours = duration.hours();
      const diffMinutes = duration.minutes();
      const diffSeconds = duration.seconds();
      return ` ${diffDays !== 0 ? `${diffDays} g` : ""} ${
        diffHours !== 0 ? `${diffHours} s` : ""
      }  ${diffMinutes !== 0 ? `${diffMinutes} dk` : ""}  ${
        diffSeconds !== 0 ? `${diffSeconds} sn` : ""
      } `;
    }
  };

  const calculatePassiveTime = () => {
    if (stateHistoryDaily) {
      const totalPassiveSecond =
        stateHistoryDaily.state_stats.break +
        stateHistoryDaily.state_stats.health +
        stateHistoryDaily.state_stats.meeting +
        stateHistoryDaily.state_stats.training +
        stateHistoryDaily.state_stats.wc;

      const duration = moment.duration(totalPassiveSecond, "seconds");
      const diffDays = duration.days();
      const diffHours = duration.hours();
      const diffMinutes = duration.minutes();
      const diffSeconds = duration.seconds();
      return ` ${diffDays !== 0 ? `${diffDays} g` : ""} ${
        diffHours !== 0 ? `${diffHours} s` : ""
      }  ${diffMinutes !== 0 ? `${diffMinutes} dk` : ""}  ${
        diffSeconds !== 0 ? `${diffSeconds} sn` : ""
      } `;
    }
  };

  const calculateStartTime = () => {
    if (stateHistoryDaily?.histories?.length > 0) {
      let hasActiveItem = stateHistoryDaily?.histories?.find(
        (item) => item.state === "active"
      );

      if (hasActiveItem) {
        return moment(hasActiveItem.created_at).format("HH:mm:ss");
      } else {
        return "-";
      }
    }
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: 15,
              marginBottom: 15,
              borderBottom: "1px solid #f4f4f4",
            }}
          >
            <span style={{ fontWeight: 600, textAlign: "center" }}>
              <span style={{ color: "gray" }}>Başlangıç saati: </span>
              {calculateStartTime()}
            </span>
            <span style={{ fontWeight: 600, textAlign: "center" }}>
              <span style={{ color: "gray" }}> Aktif Süre: </span>

              {calculateActiveTime()}
            </span>
            <span style={{ fontWeight: 600, textAlign: "center" }}>
              <span style={{ color: "gray" }}>Pasif Süre: </span>
              {calculatePassiveTime()}
            </span>
          </div>

          {stateHistory?.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: 600,
                  overflow: "auto",
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Mola Türü</TableCell>
                        <TableCell align="center">Mola Başlangıcı</TableCell>
                        <TableCell align="center">Mola Bitişi</TableCell>
                        <TableCell align="center">Mola Süresi</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stateHistory.map((item, i) => {
                        if (item.state !== "active") {
                          return (
                            <TableRow key={i}>
                              <TableCell align="center">
                                {item.state === "meeting"
                                  ? "Toplantı"
                                  : item.state === "wc"
                                  ? "WC"
                                  : item.state === "break"
                                  ? "Mola"
                                  : item.state === "health"
                                  ? "Sağlık"
                                  : item.state === "training"
                                  ? "Eğitim"
                                  : item.state === "passive"
                                  ? "İş bitiş"
                                  : item.state === "out_call"
                                  ? "Dış arama"
                                  : item.state === "dictionary"
                                  ? "Sözlük"
                                  : item.state === "setup"
                                  ? "Kurulum"
                                  : item.state}
                              </TableCell>
                              <TableCell align="center">
                                {moment(item.created_at).format("HH:mm:ss")}
                              </TableCell>
                              <TableCell align="center">
                                {i === 0
                                  ? "-"
                                  : moment(
                                      stateHistory[i - 1].created_at
                                    ).format("HH:mm:ss")}
                              </TableCell>
                              <TableCell align="center">
                                {i === 0
                                  ? "-"
                                  : calculateTimeLeft(
                                      stateHistory[i - 1].created_at,
                                      item.created_at
                                    )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
