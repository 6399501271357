import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";

export default function UserStateHistory({
  showModal,
  setShowModal,
  userInfo,
  calculateStartTime,
  calculatePassiveTime,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const calculateTimeLeft = (startTime, endTime) => {
    const remainingTime = moment.duration(
      moment(moment(startTime).diff(endTime))
    );

    const diffDays = remainingTime.days();
    const diffHours = remainingTime.hours();
    const diffMinutes = remainingTime.minutes();
    const diffSeconds = remainingTime.seconds();

    return `${diffDays} gün ${diffHours} saat ${diffMinutes} dakika ${diffSeconds} saniye`;
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 15,
            marginBottom: 15,
            borderBottom: "1px solid #f4f4f4",
          }}
        >
          <span style={{ fontWeight: 600, textAlign: "center" }}>
            İşe Başlama Tarihi: {calculateStartTime(userInfo)}
          </span>
          <span style={{ fontWeight: 600, textAlign: "center" }}>
            Toplam Aktif Süre:{" "}
            {moment
              .utc(userInfo?.state_stats?.active * 1000)
              .format("HH:mm:ss")}
          </span>
          <span style={{ fontWeight: 600, textAlign: "center" }}>
            Toplam Pasif Süre: {calculatePassiveTime(userInfo)}
          </span>
        </div>

        {userInfo?.histories?.length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <h3 style={{ textAlign: "center", marginRight: "10px" }}>
                Geçmiş durumlar
              </h3>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                maxHeight: 250,
                overflow: "auto",
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Mola Türü</TableCell>
                      <TableCell align="center">
                        Mola Başlangıç Tarihi
                      </TableCell>
                      <TableCell align="center">Mola Bitiş Tarihi</TableCell>
                      <TableCell align="center">Toplam Süre</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userInfo.histories.reverse().map((item, i) => {
                      if (item.state !== "active") {
                        return (
                          <TableRow key={i}>
                            <TableCell align="center">
                              {item.state === "meeting"
                                ? "Toplantı"
                                : item.state === "wc"
                                ? "WC"
                                : item.state === "break"
                                ? "Mola"
                                : item.state === "health"
                                ? "Sağlık"
                                : item.state === "training"
                                ? "Eğitim"
                                : item.state === "passive"
                                ? "İş bitiş"
                                : item.state === "out_call"
                                ? "Dış arama"
                                : "Diğer"}
                            </TableCell>
                            <TableCell align="center">
                              {moment(item.created_at).format(
                                "DD.MM.YYYY HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {i === 0
                                ? "-"
                                : moment(
                                    userInfo.histories[i - 1].created_at
                                  ).format("DD.MM.YYYY HH:mm:ss")}
                            </TableCell>
                            <TableCell align="center">
                              {i === 0
                                ? "-"
                                : calculateTimeLeft(
                                    userInfo.histories[i - 1].created_at,
                                    item.created_at
                                  )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
