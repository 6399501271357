import {
  Avatar,
  Button,
  Card,
  Checkbox,
  createTheme,
  FormControlLabel,
  Modal,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { ManagementContext } from "../../../../contexts/managementContext";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import RegistrationField from "../../../../components/registrationField";
import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { useToast } from "../../../../components/snackbar";
import LockResetIcon from "@mui/icons-material/LockReset";
import { DevelopmentContext } from "../../../../contexts/developmentContext";
import { AddCircleOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  backgroundColor: "#f53b62",
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function AddOfficeUserModal({
  showAddUserModal,
  setShowAddUserModal,
  dashboardOfficeuserDetail,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const {
    getUserRoles,
    getUserSubdepartments,
    getUserDepartments,
    userRoles,
    userDepartments,
    userSubdepartments,
    createOfficeUser,
    getOfficeUser,
    officeUserDetail,
    editOfficeUser,
    setOfficeUserDetail,
  } = useContext(ManagementContext);
  const { getUserAuthorityList, authorityList } =
    useContext(DevelopmentContext);
  const { alert } = useToast();
  const [selectedImage, setSelectedImage] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [formData, setFormData] = useState({
    full_name: "",
    phone: "",
    email: "",
    password: "",
  });
  const getUserInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));
  const [selectedAuth, setSelectedAuth] = useState(null);
  const [allowSms, setAllowSms] = useState(false);
  const [personalPhones, setPersonalPhones] = useState([]);

  useEffect(() => {
    getUserSubdepartments();
    getUserRoles();
    getUserDepartments();
    getUserAuthorityList();

    if (dashboardOfficeuserDetail?.user_id) {
      getOfficeUser(dashboardOfficeuserDetail?.user_id);
      setUserImage(dashboardOfficeuserDetail?.picture);
    }
  }, []);

  useEffect(() => {
    if (officeUserDetail) {
      setSelectedDepartment(
        userDepartments.find((dep) => dep.key === officeUserDetail.department)
      );
      setSelectedSubDepartment(
        userSubdepartments.find(
          (subDep) => subDep.key === officeUserDetail.sub_department
        )
      );
      setSelectedRole(
        userRoles.find((role) => role.key === officeUserDetail.role)
      );
      setFormData({
        full_name: officeUserDetail.full_name,
        phone: officeUserDetail.phone ? officeUserDetail.phone : "",
        email: officeUserDetail.email,
      });

      if (authorityList) {
        setSelectedAuth(
          authorityList?.find(
            (auth) => auth.key === officeUserDetail.development_task_authority
          )
        );
      }

      if (officeUserDetail.sms_notify) {
        setAllowSms(true);
      }

      if (officeUserDetail.personal_phones) {
        setPersonalPhones(officeUserDetail.personal_phones);
      }
    }
  }, [officeUserDetail]);

  const onFileInputChange = async (event) => {
    setSelectedImage(event.target.files[0]);
    setUserImage(null);
  };

  const generatePassword = () => {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.-_!@#$%&*()+=?/{}[]",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    setFormData({ ...formData, password: retVal });
  };

  const handleSaveUser = () => {
    if (
      !selectedDepartment ||
      !selectedSubDepartment ||
      !selectedRole ||
      !formData.full_name
    ) {
      alert("Lütfen tüm alanları doldurunuz.");
      return;
    }

    const data = new FormData();
    data.append("full_name", formData.full_name);
    data.append("role", selectedRole.key);
    data.append("department", selectedDepartment.key);
    data.append("sub_department", selectedSubDepartment.key);

    if (allowSms) {
      data.append("sms_notify", true);
    }

    if (selectedAuth) {
      data.append("development_task_authority", selectedAuth.key);
    }

    if (selectedImage) {
      data.append("picture", selectedImage);
    }

    if (formData.phone) {
      data.append("phone", formData.phone);
    }

    if (personalPhones.length > 0) {
      data.append("personal_phones", JSON.stringify(personalPhones));
    }

    if (dashboardOfficeuserDetail?.user_id) {
      if (formData.password && formData.password.length > 0) {
        data.append("password", formData.password);
      }

      const dashboardData = {
        user_id: dashboardOfficeuserDetail?.user_id,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };

      editOfficeUser(data, dashboardOfficeuserDetail?.user_id, dashboardData);
    } else {
      data.append("email", formData.email);
      data.append("password", formData.password);

      createOfficeUser(data);
    }
  };

  const handleAddPersonalPhone = () => {
    const newPhone = {
      phone: "",
      checked: false,
    };
    const newPersonalPhones = [...personalPhones, newPhone];
    setPersonalPhones(newPersonalPhones);
  };

  return (
    <Modal
      className={classes.modal}
      open={showAddUserModal}
      onClose={() => {
        setShowAddUserModal(false);
        setFormData({
          full_name: "",
          phone: "",
          email: "",
        });
        setSelectedDepartment(null);
        setSelectedSubDepartment(null);
        setSelectedRole(null);
        setSelectedImage(null);
        setUserImage(null);
        setOfficeUserDetail(null);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Card
        className={classes.paper}
        style={{
          width: "60%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          maxHeight: 600,
        }}
      >
        <h2
          id="child-modal-title"
          style={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "monospace",
          }}
        >
          {dashboardOfficeuserDetail?.user_id
            ? "Kullanıcı Düzenle"
            : "Yeni Kullanıcı Ekle"}
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "600px",
            overflowY: "auto",
            backgroundColor: "#f4f4f4",
            padding: 20,
            width: "85%",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {userImage ? (
                <img
                  src={userImage}
                  // src={URL.createObjectURL(selectedImage)}
                  alt="selectedImage"
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 50,
                    marginRight: 20,
                  }}
                />
              ) : selectedImage ? (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="selectedImage"
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 50,
                    marginRight: 20,
                  }}
                />
              ) : (
                <Avatar
                  alt="backoffice_user"
                  src={null}
                  style={{
                    width: 100,
                    height: 100,
                    cursor: "pointer",
                    marginRight: 20,
                  }}
                />
              )}

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Kullanıcı Görseli
                <VisuallyHiddenInput type="file" onChange={onFileInputChange} />
              </Button>
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowSms}
                    onChange={() => {
                      setAllowSms(!allowSms);
                    }}
                  />
                }
                label="Sms Bildirim"
              />
            </div>
          </div>

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <div
              style={{
                width: dashboardOfficeuserDetail?.user_id ? "33%" : "25%",
              }}
            >
              <RegistrationField
                fieldHeader={"İsim Soyisim"}
                fieldValue={formData?.full_name}
                fieldName={"full_name"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <div
              style={{
                width: dashboardOfficeuserDetail?.user_id ? "33%" : "25%",
              }}
            >
              <div>
                <span
                  style={{
                    color: "#293c51",
                    fontFamily: "segoeuiRg",
                    fontSize: 15,
                    fontWeight: 700,
                    marginLeft: 8,
                  }}
                >
                  Telefon
                </span>
                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#fff",
                    height: 45,
                    marginTop: matches ? 10 : 5,
                    marginBottom: matches ? 0 : 10,
                    borderRadius: 6,
                    marginRight: 14,
                  }}
                >
                  <PhoneInput
                    countryCodeEditable={false}
                    country="tr"
                    onlyCountries={["tr"]}
                    localization={{ tr: "Türkiye" }}
                    value={formData?.phone}
                    containerStyle={{ height: 45 }}
                    inputStyle={{
                      height: 45,
                      width: "100%",
                    }}
                    onChange={(phone) => {
                      setFormData({ ...formData, phone });
                    }}
                  />
                </div>
              </div>
            </div>

            {!dashboardOfficeuserDetail?.user_id && (
              <>
                <div style={{ width: "25%" }}>
                  <RegistrationField
                    fieldHeader={"E-posta Adresi"}
                    fieldValue={formData?.email}
                    fieldName={"email"}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
              </>
            )}

            {(getUserInfo?.password_change_authority ||
              !dashboardOfficeuserDetail?.user_id) && (
              <div style={{ width: "25%", position: "relative" }}>
                <Tooltip title="Şifre oluşturucu">
                  <LockResetIcon
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 10,
                      color: "#f53b62",
                      cursor: "pointer",
                    }}
                    onClick={generatePassword}
                  />
                </Tooltip>
                <RegistrationField
                  fieldHeader={"Şifre"}
                  fieldValue={formData?.password}
                  fieldName={"password"}
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            )}
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "33%" }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Departman Seçiniz
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  styles={{
                    ...selectDefaultStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPosition="fixed"
                  options={userDepartments}
                  isSearchable={false}
                  hoverColor={"black"}
                  menuColor={"#fff"}
                  color={"#000"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3}
                  value={selectedDepartment}
                  getOptionValue={(option) => option.key}
                  onChange={(e) => {
                    setSelectedDepartment(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.value}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "33%" }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Alt Departman Seçiniz
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  styles={{
                    ...selectDefaultStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPosition="fixed"
                  options={userSubdepartments}
                  isSearchable={false}
                  hoverColor={"black"}
                  menuColor={"#fff"}
                  color={"#000"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3}
                  value={selectedSubDepartment}
                  getOptionValue={(option) => option.key}
                  onChange={(e) => {
                    setSelectedSubDepartment(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.value}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "33%" }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Rol Seçiniz
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={userRoles}
                  styles={{
                    ...selectDefaultStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPosition="fixed"
                  isSearchable={false}
                  hoverColor={"black"}
                  menuColor={"#fff"}
                  color={"#000"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3000}
                  value={selectedRole}
                  getOptionValue={(option) => option.key}
                  onChange={(e) => {
                    setSelectedRole(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.value}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div style={{ width: "25%" }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Yetki Seçiniz
              </span>
              <div style={{ marginRight: 14, marginTop: 10 }}>
                <Select
                  options={authorityList}
                  styles={{
                    ...selectDefaultStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPosition="fixed"
                  isSearchable={false}
                  hoverColor={"black"}
                  menuColor={"#fff"}
                  color={"#000"}
                  placeholder={"Seçiniz"}
                  dynamiczIndex={3000}
                  value={selectedAuth}
                  getOptionValue={(option) => option.key}
                  onChange={(e) => {
                    setSelectedAuth(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{e.value}</span>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                  marginRight: 8,
                }}
              >
                Kişisel Telefonlar
              </div>
              <AddCircleOutlined
                style={{ color: "#f53b62", cursor: "pointer" }}
                onClick={handleAddPersonalPhone}
              />
            </div>

            {personalPhones.map((personalPhone, index) => {
              return (
                <div
                  style={{
                    height: 45,
                    marginTop: matches ? 10 : 5,
                    marginBottom: matches ? 0 : 10,
                    borderRadius: 6,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PhoneInput
                    countryCodeEditable={false}
                    country="tr"
                    onlyCountries={["tr"]}
                    localization={{ tr: "Türkiye" }}
                    value={personalPhone?.phone}
                    containerStyle={{ height: 45, width: 200, marginRight: 10 }}
                    inputStyle={{
                      height: 45,
                      width: "200px",
                    }}
                    onChange={(phone) => {
                      const newPersonalPhones = [...personalPhones];
                      newPersonalPhones[index].phone = phone;
                      setPersonalPhones(newPersonalPhones);
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={personalPhone.checked}
                        checked={personalPhone.checked}
                      />
                    }
                    onChange={(e) => {
                      const newPersonalPhones = [...personalPhones];
                      newPersonalPhones[index].checked = e.target.checked;
                      setPersonalPhones(newPersonalPhones);
                    }}
                  />

                  <ClearIcon
                    style={{ color: "#f53b62", cursor: "pointer" }}
                    onClick={() => {
                      const newPersonalPhones = [...personalPhones];
                      newPersonalPhones.splice(index, 1);
                      setPersonalPhones(newPersonalPhones);
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div
            style={{ display: "flex", justifyContent: "end", marginTop: 20 }}
          >
            <Button
              style={{
                backgroundColor: "green",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: 150,
                display: "flex",
                justifyContent: "center",
                height: 40,
              }}
              onClick={handleSaveUser}
            >
              Kaydet
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
