import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import { CallContext } from "../../../contexts/callContext";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { KeyboardVoice } from "@mui/icons-material";
import PuantajVoiceModal from "./puantajVoiceModal";
import { ManagementContext } from "../../../contexts/managementContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 30%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

export default function VoiceModal({
  voiceModal,
  setVoiceModal,
  currentRestRow,
  type,
}) {
  const classes = useStyles();
  const [puantajVoiceModal, setPuantajVoiceModal] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);

  const { cdrSummaryListFunc, cdrRecordingListFunc, cdrSummaryList } =
    useContext(CallContext);

  const { tahsilatRatingFunc, tahsilatRatingList, setTahsilatRatingList } =
    useContext(ManagementContext);

  useEffect(() => {
    if (voiceModal) {
      if (type === "summary") {
        cdrSummaryListFunc({
          rest_id: currentRestRow?.restofficial?.id,
        });
      } else {
        cdrSummaryListFunc({
          rest_id: currentRestRow?.user?.id,
        });
      }
    }
  }, [voiceModal]);

  const convertTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSecondsAfterHours = totalSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSecondsAfterMinutes = remainingSecondsAfterHours % 60;

    let result = "";
    if (hours > 0) {
      result += `${hours} s `;
    }
    if (minutes > 0) {
      result += `${minutes} dk `;
    }
    result += `${remainingSecondsAfterMinutes.toFixed(0)} sn`;

    return result;
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={voiceModal}
        className={classes.modal}
        onClose={() => setVoiceModal(false)}
      >
        <div
          style={{
            display: "flex",
            width: "90%",
            maxHeight: 600,
            overflowY: "auto",
            backgroundColor: "#fff",
            padding: 30,
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Arayan</TableCell>
                  <TableCell align="center">Aranan</TableCell>
                  <TableCell align="center">Direction</TableCell>
                  <TableCell align="center">Yer</TableCell>
                  <TableCell align="center">Arama Tarih</TableCell>
                  <TableCell align="center">Cavaplama Tarih</TableCell>
                  <TableCell align="center">Bitiş Tarih</TableCell>
                  <TableCell align="center">Cevapsız</TableCell>
                  <TableCell align="center">Cevapsız Geri Dön</TableCell>
                  <TableCell align="center">Cevapsız Geri Dön Tarih</TableCell>
                  <TableCell align="center">Bekleme süresi</TableCell>
                  <TableCell align="center">Konuşma süresi</TableCell>
                  <TableCell align="center">Toplam süresi</TableCell>
                  <TableCell align="center">Ses Dosyası</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cdrSummaryList?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {row?.direction === "outbound"
                          ? row?.officeuser?.full_name
                          : row?.caller}
                      </TableCell>
                      <TableCell align="center">
                        {row?.direction === "inbound"
                          ? row?.officeuser?.full_name
                          : row?.callee}
                      </TableCell>
                      <TableCell align="center">{row?.direction}</TableCell>
                      <TableCell align="center">
                        {row?.trigger_source === "task" ? (
                          <span>
                            {row?.trigger_source} <br />{" "}
                            {row?.task?.task_type?.name}
                          </span>
                        ) : (
                          row?.trigger_source
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row?.start_at).format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row?.answer_at).format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row?.end_at).format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {row?.missed ? "Evet" : "Hayır"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.miss_returned ? "Evet" : "Hayır"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.miss_returned ? (
                          <>
                            {moment(row?.miss_returned_at).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                            <div>
                              {convertTime(
                                moment(row?.miss_returned_at).diff(
                                  moment(row?.start_at),
                                  "seconds"
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {convertTime(row?.wait_duration)}
                      </TableCell>
                      <TableCell align="center">
                        {convertTime(row?.talk_duration)}
                      </TableCell>
                      <TableCell align="center">
                        {convertTime(row?.total_duration)}
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: 300 }}>
                        {row?.rating !== null ? (
                          <KeyboardVoice
                            style={{
                              cursor: "pointer",
                              color: "gray",
                            }}
                            onClick={() => {
                              setPuantajVoiceModal(true);
                              cdrRecordingListFunc({
                                record_id: row?.id,
                              });
                              tahsilatRatingFunc({
                                rating_id: row?.rating?.id,
                              });
                              setCurrentRowId(ind);
                            }}
                          />
                        ) : row?.voice ? (
                          <audio controls src={row?.voice}></audio>
                        ) : (
                          <KeyboardVoice
                            style={{
                              cursor: "pointer",
                              color: row?.recording_played ? "green" : "gray",
                            }}
                            onClick={() => {
                              cdrRecordingListFunc({
                                record_id: row?.id,
                              });
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
      <PuantajVoiceModal
        puantajVoiceModal={puantajVoiceModal}
        setPuantajVoiceModal={setPuantajVoiceModal}
        currentRowId={currentRowId}
      />
    </>
  );
}
