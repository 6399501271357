import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Modal } from "@mui/material";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import CustomDatePickerInput from "../../datePickerInput";

const TechCompleteModal = ({
  techCompleteModal,
  setTechCompleteModal,
  techSupportDetail,
  currentRow,
  stayed,
  searchTerm,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState("home");
  const [startDate, setStartDate] = useState(
    setHours(
      setMinutes(new Date(), moment().format("mm")),
      moment().format("HH")
    )
  );

  const isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  return (
    <Modal
      open={techCompleteModal}
      style={{ border: "none", zCancelOrder: "9999", textAlign: "center" }}
      onClose={() => {
        setTechCompleteModal(!techCompleteModal);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          border: "none",
          position: "absolute",
          backgroundColor: "white",
          width: "50%",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
        }}
      >
        <h2>İşlem</h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <textarea
              className="input"
              type="text"
              name="name"
              rows="2"
              value={message}
              spellCheck="false"
              style={{
                width: "85%",
                borderWidth: 0,
                borderColor: "#f4f4f4",
                fontSize: 14,
                fontWeight: 400,
                height: 150,
                backgroundColor: "#f4f4f4",
                borderRadius: 12,
                padding: 20,
              }}
              autoComplete="off"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              multiline="true"
            />
          </div>
        </div>

        {modalStatus === "stay" ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "96%",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 12,
                width: "96%",
              }}
            >
              <DatePicker
                selected={startDate}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                minDate={moment(new Date()).toDate()}
                dateFormat="d MMMM, HH:mm"
                locale="tr"
                // inline
                customInput={<CustomDatePickerInput selectWidth={350} />}
              />
              <Button
                style={{
                  backgroundColor: "gray",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  height: 40,
                  marginLeft: 10,
                }}
                size="small"
                onClick={() => {
                  setModalStatus("home");
                }}
              >
                Geri Al
              </Button>
              <Button
                style={{
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  height: 40,
                  marginLeft: 10,
                }}
                size="small"
                onClick={() => {
                  if (isBlank(message)) {
                    enqueueSnackbar("Not boş olamaz.", {
                      variant: "error",
                      preventDuplicate: true,
                      autoHideDuration: 2000,
                    });
                  } else {
                    let data = {
                      support_id: currentRow?.id,
                      searchTerm,
                      stayed,
                      sendObj: {
                        stay_time: moment(startDate).toISOString(true),
                        stay_note: message,
                      },
                    };
                    techSupportDetail(data);
                    setTechCompleteModal(false);
                    setMessage("");
                    setModalStatus("home");
                  }
                }}
              >
                Gönder
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              display: "flex",
              marginTop: 30,
            }}
          >
            <div>
              <Button
                variant="success"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  marginBottom: 25,
                  marginLeft: 25,
                  textTransform: "none",
                }}
                onClick={() => {
                  setTechCompleteModal(false);
                }}
              >
                <span>Kapat</span>
              </Button>
            </div>

            <div
              style={{
                width: 180,
                height: 40,
                backgroundColor: "gray",
                borderRadius: 19,
                marginRight: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalStatus("stay");
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                }}
              >
                Sayaç
              </span>
            </div>
            <div
              style={{
                width: 180,
                height: 40,
                backgroundColor: "red",
                borderRadius: 19,
                marginRight: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                if (isBlank(message)) {
                  enqueueSnackbar("Not boş olamaz.", {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                  });
                } else {
                  let data = {
                    support_id: currentRow?.id,
                    searchTerm,
                    stayed,
                    sendObj: {
                      status: "completed_negative",
                      note: message,
                    },
                  };
                  techSupportDetail(data);
                  setTechCompleteModal(false);
                  setMessage("");
                }
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                }}
              >
                Başarısız
              </span>
            </div>
            <div
              style={{
                width: 180,
                height: 40,
                backgroundColor: "green",
                borderRadius: 19,
                marginRight: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                if (isBlank(message)) {
                  enqueueSnackbar("Not boş olamaz.", {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                  });
                } else {
                  let data = {
                    support_id: currentRow?.id,
                    searchTerm,
                    stayed,
                    sendObj: {
                      status: "completed",
                      note: message,
                    },
                  };
                  techSupportDetail(data);
                  setTechCompleteModal(false);
                  setMessage("");
                }
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                }}
              >
                Başarılı
              </span>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TechCompleteModal;
