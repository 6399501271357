import React from "react";
import { Box } from "@mui/material";
import Containner from "../components/containner";
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import { sellerSidbarData } from "../utils/constants";

const SellerLayout = ({ children }) => {
  const isDevelopmentPage = window.location.pathname === "/development";
  return (
    <div style={{ overflow: "hidden" }}>
      <Header title={isDevelopmentPage ? "Geliştirme" : "Bayi Sayfası"} />

      <Containner>
        <div
          style={{
            height: "98%",
            width: "98%",
            borderRadius: 4,
            display: "flex",
            contain: "content",
            justifyContent: "space-between",
          }}
        >
          {!isDevelopmentPage && <Sidebar sidebarData={sellerSidbarData} />}

          <Box
            component="main"
            sx={{
              width: isDevelopmentPage ? "100%" : "85%",
              backgroundColor: "#f4f4f4",
              borderRadius: 2,
              color: "#000",
              overflowY: "auto",
            }}
          >
            <main>{children}</main>
          </Box>
        </div>
      </Containner>
    </div>
  );
};

export default SellerLayout;
