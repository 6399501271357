let audio;
let isSoundPlaying = false;

const soundPlayF = (sound_id) => {
  if (!isSoundPlaying) {
    if (sound_id == 4) {
      audio = new Audio(
        "https://st-dj-static.s3.eu-central-1.amazonaws.com/notification2.mp3"
      );
    } else if (sound_id == 5) {
      audio = new Audio(
        "https://st-dj-static.s3.eu-central-1.amazonaws.com/notificationsound.mp3"
      );
    } else if (sound_id == 10) {
      audio = new Audio(
        "https://st-dj-static.s3.eu-central-1.amazonaws.com/qr_customer_notification.mp3"
      );
    } else {
      audio = new Audio(
        "https://st-dj-static.s3.eu-central-1.amazonaws.com/notification3.mp3"
      );
    }
    isSoundPlaying = true;
    audio.play();
    audio.addEventListener("ended", () => {
      isSoundPlaying = false;
      audio.pause();
    });
  }
};
export default soundPlayF;
