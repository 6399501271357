import { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../contexts/managementContext";
import CustomerSearch from "../../../components/search";
import useDebounce from "../../../components/debounce";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Divider, Paper, Tooltip } from "@mui/material";
import dictionaryImg from "../../../utils/assets/dictionary.webp";
import pdfIcon from "../../../utils/assets/pdf-icon.png";

export default function Dictionary() {
  const { getDictionaryHeaders, dictionaryHeaders } =
    useContext(ManagementContext);
  const [searchValue, setSearchValue] = useState("");
  const debouncedsearchValue = useDebounce(searchValue, 1000);
  const [selectedPdf, setSelectedPdf] = useState(null);

  useEffect(() => {
    getDictionaryHeaders(debouncedsearchValue);
  }, [debouncedsearchValue]);

  return (
    <div style={{ background: "#393737", height: "100%", minWidth: "100%" }}>
      <div
        style={{
          fontSize: 24,
          textAlign: "center",
          background: "#448d8c",
          color: "#fff",
          padding: "1px 0px",
          fontFamily: "segoeuiB",
        }}
      >
        Sözlük
      </div>

      <div
        style={{
          display: "flex",
          padding: "10px 10px 0px",
          height: "calc(100% - 100px)",
        }}
      >
        <div style={{ width: "20%", marginRight: 20 }}>
          <div>
            <CustomerSearch
              value={searchValue}
              placeholder={"Kelime Ara"}
              onchange={setSearchValue}
            />
          </div>

          <div
            style={{
              marginTop: 20,
              maxHeight: "90%",
              overflow: "auto",
            }}
          >
            {dictionaryHeaders.map((item, i) => {
              if (item?.features?.length === 0) return;
              return (
                <div
                  key={i}
                  style={{
                    padding: 5,
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#448d8c",
                      marginBottom: 5,
                      fontSize: 16,
                      fontFamily: "segoeuiB",
                      color: "#fff",
                      padding: "0px 4px",
                      borderRadius: 2,
                    }}
                  >
                    {item.name}
                  </div>
                  {item.features.map((feature, ind) => {
                    return (
                      <div key={ind} style={{ color: "#fff", padding: 10 }}>
                        <div
                          style={{
                            margin: 0,
                            marginBottom: 5,
                            fontSize: 14,
                            fontFamily: "segoeuiB",
                          }}
                        >
                          {feature.name}
                        </div>
                        <div
                          style={{
                            marginBottom: 5,
                            fontSize: 12,
                            fontFamily: "segoeuiB",
                            color: "darkgray",
                          }}
                        >
                          {feature.description}
                        </div>
                        <div style={{ display: "flex" }}>
                          {feature.files.map((file, i) => {
                            return (
                              <Tooltip
                                key={i}
                                title={file.file.substring(
                                  file.file.lastIndexOf("/") + 1
                                )}
                              >
                                <img
                                  onClick={() => {
                                    setSelectedPdf(file.file);
                                  }}
                                  src={pdfIcon}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    cursor: "pointer",
                                    marginLeft: 5,
                                  }}
                                />
                              </Tooltip>
                            );
                          })}
                        </div>
                        <Divider
                          sx={{
                            backgroundColor: "gray",
                            width: 1,
                            marginTop: 2,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <div style={{ width: "75%" }}>
          {selectedPdf ? (
            <embed
              src={selectedPdf}
              type="application/pdf"
              style={{ width: "100%", height: "100%" }}
            ></embed>
          ) : (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img src={dictionaryImg} alt="dictionary" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
