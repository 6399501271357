import gofodyPlatfomIconImageA from "../../utils/assets/fodyicona.png";
import trendyolPlatfomIconImageA from "../../utils/assets/trendicona.png";
import getirPlatfomIconImageA from "../../utils/assets/getiricona.png";
import yemeksepetiPlatfomIconImageA from "../../utils/assets/yemekicona.png";
import migrosPlatfomIconImageA from "../../utils/assets/migrosicona.png";
import fuudyPlatfomIconImageA from "../../utils/assets/fuudyicona.png";
import sepetappPlatfomIconImageA from "../../utils/assets/sepetappicona.png";

import gofodyPlatfomIconImageP from "../../utils/assets/fodyiconp.png";
import trendyolPlatfomIconImageP from "../../utils/assets/trendiconp.png";
import getirPlatfomIconImageP from "../../utils/assets/getiriconp.png";
import yemeksepetiPlatfomIconImageP from "../../utils/assets/yemekiconp.png";
import migrosPlatfomIconImageP from "../../utils/assets/migrosiconp.png";
import fuudyPlatfomIconImageP from "../../utils/assets/fuudyiconp.png";
import sepetappPlatfomIconImageP from "../../utils/assets/sepetappiconp.png";

import gofodyPlatfomImageB from "../../utils/assets/fodym.png";
import trendyolPlatfomImageB from "../../utils/assets/trendm.png";
import getirPlatfomImageB from "../../utils/assets/getirm.png";
import yemeksepetiPlatfomImageB from "../../utils/assets/yemekm.png";
import migrosPlatfomImageB from "../../utils/assets/migrosm.png";
import fuudyPlatfomImageB from "../../utils/assets/fuudym.png";

import gofodyPlatfomImageMd from "../../utils/assets/fodymd.png";
import trendyolPlatfomImageMd from "../../utils/assets/trendmd.png";
import getirPlatfomImageMd from "../../utils/assets/getirmd.png";
import yemeksepetiPlatfomImageMd from "../../utils/assets/yemekmd.png";
import migrosPlatfomImageMd from "../../utils/assets/migrosmd.png";
import fuudyPlatfomImageMd from "../../utils/assets/fuudymd.png";

const platformIconImageConverter = (platform_type) => {
  if (platform_type === "Gofody") return gofodyPlatfomIconImageA;
  if (platform_type === "Trendyol") return trendyolPlatfomIconImageA;
  if (platform_type === "Getir") return getirPlatfomIconImageA;
  if (platform_type === "Yemeksepeti") return yemeksepetiPlatfomIconImageA;
  if (platform_type === "Migros") return migrosPlatfomIconImageA;
  if (platform_type === "Fuudy") return fuudyPlatfomIconImageA;
  if (platform_type === "Sepetapp") return sepetappPlatfomIconImageA;

  if (platform_type === "Gofodyp") return gofodyPlatfomIconImageP;
  if (platform_type === "Trendyolp") return trendyolPlatfomIconImageP;
  if (platform_type === "Getirp") return getirPlatfomIconImageP;
  if (platform_type === "Yemeksepetip") return yemeksepetiPlatfomIconImageP;
  if (platform_type === "Migrosp") return migrosPlatfomIconImageP;
  if (platform_type === "Fuudyp") return fuudyPlatfomIconImageP;
  if (platform_type === "Sepetappp") return sepetappPlatfomIconImageP;

  return platform_type;
};

const platformImageConverterBig = (platform_type) => {
  if (platform_type == "Gofody") return gofodyPlatfomImageB;
  if (platform_type == "Trendyol") return trendyolPlatfomImageB;
  if (platform_type == "Getir") return getirPlatfomImageB;
  if (platform_type == "Yemeksepeti") return yemeksepetiPlatfomImageB;
  if (platform_type == "Migros") return migrosPlatfomImageB;
  if (platform_type == "Fuudy") return fuudyPlatfomImageB;

  if (platform_type == "Gofodymd") return gofodyPlatfomImageMd;
  if (platform_type == "Trendyolmd") return trendyolPlatfomImageMd;
  if (platform_type == "Getirmd") return getirPlatfomImageMd;
  if (platform_type == "Yemeksepetimd") return yemeksepetiPlatfomImageMd;
  if (platform_type == "Migrosmd") return migrosPlatfomImageMd;
  if (platform_type == "Fuudymd") return fuudyPlatfomImageMd;

  return platform_type;
};

export { platformImageConverterBig, platformIconImageConverter };
