import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Card, Modal } from "@mui/material";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 100%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const TechResTicket = ({ techResTicketModal, setTechResTicketModal }) => {
  const classes = useStyles();
  let history = useHistory();
  const { newTaskCreateFunc } = useContext(RestaurantContext);

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={techResTicketModal}
      className={classes.modal}
      componentsProps={{
        backdrop: { style: { top: 60 } },
        root: { style: { top: 60 } },
      }}
    >
      <Card
        style={{
          width: "30%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            fontSize: 28,
            fontWeight: 600,
            fontFamily: "monospace",
          }}
        >
          Restoran Teknik Destek
        </div>

        <Button
          style={{
            color: "white",
            backgroundColor: "#f53b62",
            padding: "5px 20px",
            textTransform: "capitalize",
            fontFamily: "SgBd",
            fontSize: 18,
            marginTop: 10,
          }}
          onClick={() => {
            history.push("/operation/tasks");
            newTaskCreateFunc();
            setTechResTicketModal(false);
          }}
        >
          Tamam
        </Button>
      </Card>
    </Modal>
  );
};

export default TechResTicket;
