import { Button } from "@mui/material";
import React from "react";
import { endCall } from "./endCall";
import { Close } from "@mui/icons-material";
let callkeyv2;

const errorCall = (
  enqueueSnackbar,
  closeSnackbar,
  reasonPhrase,
  statusCode
) => {
  closeSnackbar(callkeyv2);
  callkeyv2 = enqueueSnackbar(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: 280,
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: 20, fontFamily: "SgBd", color: "#fff" }}>
        {statusCode} |{" "}
        {statusCode === 486
          ? "Meşgul"
          : statusCode === 403
          ? "Arama Reddedildi"
          : statusCode === 408
          ? "Arama cevaplanmadı"
          : statusCode === 480
          ? "Aranan numara meşgul"
          : statusCode === 487
          ? "Çağrı sonlandı"
          : statusCode === 500
          ? "Kullanılmayan numara"
          : reasonPhrase}
      </div>
      <Button
        style={{
          color: "#000",
          backgroundColor: "#fff",
          textTransform: "capitalize",
          fontFamily: "SgBd",
          fontSize: 14,
          marginLeft: 25,
        }}
        onClick={() => {
          closeSnackbar(callkeyv2);
        }}
      >
        <Close />
      </Button>
    </div>,
    {
      preventDuplicate: true,
      persist: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      sx: {
        "& .SnackbarContent-root": {
          backgroundColor: "red",
        },
      },
    }
  );
};

export default errorCall;
