import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import GridItem from "../../../../components/gridItem";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePickerInput from "../../../../components/datePickerInput";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { offerCount } from "../../../../utils/constants";
import { ManagementContext } from "../../../../contexts/managementContext";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

let type_Array = [
  {
    value: "zero_data",
    label: "0-Data Sepettakip",
  },
  {
    value: "zero_data_callerplus",
    label: "0 Data CallerPlus",
  },
  {
    value: "ivr",
    label: "IVR",
  },
  {
    value: "dijital",
    label: "Dijital",
  },
  {
    value: "rehberimden",
    label: "Rehberimden",
  },
  {
    value: "customer_services",
    label: "Müşteri Hizmetleri",
  },
];

export default function DataManagement() {
  const classes = useStyles();
  const {
    applicationCountsFunc,
    applicationCounts,
    applicationDeletedCountFunc,
    applicationDeletedCount,
    applicationHistoryLogFunc,
    applicationHistoryLog,
    blockedStatusReasonsFunc,
    blockedReasons,
    ivrExelUploadFunc,
    applicationDeletedFunc,
    zeroDataExelUploadFunc,
  } = useContext(RestaurantContext);
  const { getOptionalProducts, optionalProducts } =
    useContext(ManagementContext);
  const [zeroDataReports, setZeroDataReports] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedDeletedCount, setSelectedDeletedCount] = useState([]);
  const [selectedDeletedReason, setSelectedDeletedReason] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [file, setFile] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({
    id: "",
    name: "",
  });
  const [selectedProductData, setSelectedProductData] = useState({
    id: "",
    name: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getOptionalProducts();
  }, []);

  useEffect(() => {
    if (blockedReasons !== null) {
      setSelectedDeletedReason(blockedReasons);
    }
  }, [blockedReasons]);

  useEffect(() => {
    blockedStatusReasonsFunc();
    setSelectedType(type_Array);
  }, []);

  useEffect(() => {
    applicationCountsFunc(selectedProductData?.id, selectedProduct?.id);
  }, [selectedProduct, selectedProductData]);

  useEffect(() => {
    applicationHistoryLogFunc(page + 1);
  }, [page]);

  useEffect(() => {
    if (selectedType !== null) {
      let reasonArray = [];
      let typeArray = [];
      selectedDeletedReason?.map((item) => {
        reasonArray?.push(item.id);
      });
      selectedType?.map((item) => {
        typeArray?.push(item.value);
      });

      let counts = selectedDeletedCount.map((item) => item.value);

      let data = {
        type: typeArray !== null ? typeArray : "",
        start_time: startDate ? moment(startDate)?.format("YYYY-MM-DD") : "",
        end_time: endDate ? moment(endDate)?.format("YYYY-MM-DD") : "",
        deleted_count: selectedDeletedCount ? counts.toString() : "",
        deleted_reason: reasonArray !== null ? reasonArray : [],
      };
      applicationDeletedCountFunc(data);
    }
  }, [
    selectedType,
    startDate,
    endDate,
    selectedDeletedCount,
    selectedDeletedReason,
  ]);

  const sendApplicationDeletedFunc = () => {
    let reasonArray = [];
    let typeArray = [];
    selectedDeletedReason?.map((item) => {
      reasonArray?.push(item.id);
    });
    selectedType?.map((item) => {
      typeArray?.push(item.value);
    });

    let counts = selectedDeletedCount.map((item) => item.value);

    let data = {
      sendObj: {
        type: typeArray !== null ? typeArray : "",
        start_time: startDate ? moment(startDate)?.format("YYYY-MM-DD") : "",
        end_time: endDate ? moment(endDate)?.format("YYYY-MM-DD") : "",
        deleted_count: selectedDeletedCount !== null ? counts : "",
        deleted_reason: reasonArray !== null ? reasonArray : [],
      },
    };
    applicationDeletedFunc(data, page + 1);
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadExel = (type) => {
    const formData = new FormData();
    formData.append("file", file);
    if (type === "ivr") {
      formData.append("product", selectedProduct?.id);
      ivrExelUploadFunc(formData);
    } else {
      formData.append("product", selectedProductData?.id);
      zeroDataExelUploadFunc(formData);
    }
  };

  const handleChangeWorkerData = (e) => {
    setSelectedProductData(e);
  };

  const handleChangeWorker = (e) => {
    setSelectedProduct(e);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          marginTop: 4,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#e7e7e7",
            padding: 10,
            marginBottom: 10,
            marginRight: 10,
          }}
        >
          <div style={{ fontFamily: "segoeuiB" }}>0-Data</div>
          <div
            style={{
              display: "flex",
              marginBottom: 10,
            }}
          >
            <GridItem
              count={applicationCounts?.zero_data?.not_deleted}
              title={"Dokunulmamış"}
            />
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.zero_data?.processed}
                title={"İşlemde"}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.zero_data?.deleted}
                title={"Silinmiş"}
              />
            </div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Ürün seç
            </span>
            <Select
              options={optionalProducts}
              styles={{ ...selectDefaultStyles }}
              isSearchable={true}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              placeholder={"Ürün Seç"}
              selectWidth={230}
              value={selectedProductData}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                handleChangeWorkerData(e);
              }}
              getOptionLabel={(option) => option.name}
            />
          </div>

          {selectedProductData && (
            <div style={{ textAlign: "end" }}>
              <input
                type="file"
                name="xlsFile"
                id="xlsFile"
                accept=".xls, .xlsx"
                onChange={onChange}
              />
              <button
                onClick={() => {
                  uploadExel("zero_data");
                }}
                className="btn btn-primary"
              >
                Yükle
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#e7e7e7",
            padding: 10,
            marginBottom: 10,
            marginRight: 10,
          }}
        >
          <div style={{ fontFamily: "segoeuiB" }}>IVR</div>
          <div
            style={{
              display: "flex",
              marginBottom: 10,
            }}
          >
            <GridItem
              count={applicationCounts?.ivr?.not_deleted}
              title={"Dokunulmamış"}
            />
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.ivr?.processed}
                title={"İşlemde"}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.ivr?.deleted}
                title={"Silinmiş"}
              />
            </div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Ürün seç
            </span>
            <Select
              options={optionalProducts}
              styles={{ ...selectDefaultStyles }}
              isSearchable={true}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              placeholder={"Ürün Seç"}
              selectWidth={230}
              value={selectedProduct}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                handleChangeWorker(e);
              }}
              getOptionLabel={(option) => option.name}
            />
          </div>

          {selectedProduct && (
            <div style={{ textAlign: "end" }}>
              <input
                type="file"
                name="xlsFile"
                id="xlsFile"
                accept=".xls, .xlsx"
                onChange={onChange}
              />
              <button
                onClick={() => {
                  uploadExel("ivr");
                }}
                className="btn btn-primary"
              >
                Yükle
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#e7e7e7",
            padding: 10,
            marginBottom: 10,
            marginRight: 10,
          }}
        >
          <div style={{ fontFamily: "segoeuiB" }}>Müşteri Hizmetleri</div>
          <div
            style={{
              display: "flex",
            }}
          >
            <GridItem
              count={applicationCounts?.customer_services?.not_deleted}
              title={"Dokunulmamış"}
            />
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.customer_services?.processed}
                title={"İşlemde"}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.customer_services?.deleted}
                title={"Silinmiş"}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#e7e7e7",
            padding: 10,
            marginBottom: 10,
            marginRight: 10,
          }}
        >
          <div style={{ fontFamily: "segoeuiB" }}>Dijital</div>
          <div
            style={{
              display: "flex",
            }}
          >
            <GridItem
              count={applicationCounts?.dijital?.not_deleted}
              title={"Dokunulmamış"}
            />
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.dijital?.processed}
                title={"İşlemde"}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.dijital?.deleted}
                title={"Silinmiş"}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#e7e7e7",
            padding: 10,
            marginBottom: 10,
            marginRight: 10,
          }}
        >
          <div style={{ fontFamily: "segoeuiB" }}>Rehberimden</div>
          <div
            style={{
              display: "flex",
            }}
          >
            <GridItem
              count={applicationCounts?.rehberimden?.not_deleted}
              title={"Dokunulmamış"}
            />
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.rehberimden?.processed}
                title={"İşlemde"}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <GridItem
                count={applicationCounts?.rehberimden?.deleted}
                title={"Silinmiş"}
              />
            </div>
          </div>
        </div>
      </Box>

      <div
        style={{
          background: "#e7e7e7",
          marginTop: 20,
          marginBottom: 20,
          padding: 10,
        }}
      >
        <Box sx={{ display: "flex", marginBottom: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "segoeuiB" }}>Silinen Adet</div>
            <Paper
              elevation={2}
              style={{
                width: 130,
                height: 45,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#00A79D",
                color: "#fff",
              }}
            >
              {applicationDeletedCount !== null
                ? applicationDeletedCount?.count
                : "-"}
            </Paper>
          </div>

          <div style={{ marginLeft: 20 }}>
            <span
              style={{
                fontSize: 16,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Başlangıç Zamanı
            </span>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              fixedHeight="45px"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              onChange={(update) => {
                setDateRange(update);
              }}
              customInput={<CustomDatePickerInput selectWidth={140} />}
              locale="tr"
            />
          </div>

          <div style={{ marginLeft: 20 }}>
            <span
              style={{
                fontSize: 16,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Silinme Sayısı
            </span>
            <Select
              responsiveHeight={30}
              selectFontSize={16}
              options={offerCount}
              styles={selectDefaultStyles}
              isSearchable={false}
              zIndex={90}
              isMulti
              hoverColor={"#f53b62"}
              menuColor={"#f53b62"}
              selectWidth={200}
              placeholder={"Seçiniz"}
              value={selectedDeletedCount}
              getOptionValue={(option) => option.value}
              onChange={(e) => {
                setSelectedDeletedCount(e);
              }}
              getOptionLabel={(e) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {e.label}
                </div>
              )}
            />
          </div>
        </Box>

        <Box style={{ display: "flex" }}>
          <div>
            <span
              style={{
                fontSize: 16,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Data Tipi
            </span>
            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={type_Array}
              isMulti
              isSearchable={false}
              value={selectedType}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={200}
              menuColor={"#fff"}
              color={"#000"}
              onChange={(e) => {
                setSelectedType(e);
              }}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
            />
          </div>

          <div style={{ marginLeft: 20 }}>
            <span
              style={{
                fontSize: 16,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Silinme Sebebi
            </span>

            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={blockedReasons}
              isMulti
              isSearchable={false}
              value={selectedDeletedReason}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={500}
              menuColor={"#fff"}
              color={"#000"}
              placeholder={"0%"}
              onChange={(e) => {
                setSelectedDeletedReason(e);
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) =>
                option.label +
                (applicationDeletedCount !== null
                  ? "(" + applicationDeletedCount[option.id] + ")"
                  : "")
              }
            />
          </div>

          <div
            style={{ marginLeft: 20, display: "flex", alignItems: "flex-end" }}
          >
            <Button
              style={{
                backgroundColor: "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "auto",
                display: "flex",
                justifyContent: "center",
                height: 40,
                marginLeft: 10,
              }}
              size="small"
              onClick={() => {
                sendApplicationDeletedFunc();
              }}
            >
              Tekrar Dokunulmamışa Yolla
            </Button>
          </div>
        </Box>
      </div>

      <span
        style={{
          fontSize: 20,
          marginBottom: 10,
          display: "block",
          fontWeight: "600",
          textAlign: "center",
          margin: 20,
        }}
      >
        Son İşlemler
      </span>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Tarih</TableCell>
              <TableCell align="center">Data Tipi</TableCell>
              <TableCell align="center">Silinen Tarih Aralığı</TableCell>
              <TableCell align="center">Silinme Sayısı</TableCell>
              <TableCell align="center">Silinme Nedenleri</TableCell>
              <TableCell align="center">Adet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicationHistoryLog?.results.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    {moment(row.created_at).format("DD-MM-YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell align="center">
                    {row.data_type.map((type) => type + ", ")}
                  </TableCell>
                  <TableCell align="center">
                    {moment(row.start_date).format("DD-MM-YYYY HH:mm:ss")}
                    <br />
                    {moment(row.end_date).format("DD-MM-YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell align="center">{row.deleted_count}</TableCell>
                  <TableCell align="center">
                    {row.deleted_reason.map((reason) => reason + ", ")}
                  </TableCell>
                  <TableCell align="center">{row.count}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {applicationHistoryLog?.results != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={applicationHistoryLog?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
