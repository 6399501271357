import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//Components
import PanelActionNavbar from "./components/PanelActionNavbar";
import RestaurantRating from "./components/restaurantRating";
import PushNotification from "./components/pushNotification";
import ServiceArea from "./components/serviceArea";
import MenuList from "./components/MenuList";
import MenuDetail from "./components/MenuDetail";
import MenuCreateProduct from "./components/MenuCreateProduct";
import Customers from "./components/customers";
//context
import { RestaurantContext } from "../../../contexts/restaurantContext";
//Material UI
import { Box } from "@mui/material";
//modal
import CreateHeader from "./modals/createHeader";
import ProductDeleteModal from "./modals/productDeleteModal";
import ProductPhotoModal from "./modals/productPhotoModal";
//REACT THIRD PART
import CardFlip from "react-card-flip";
import PaymentTypes from "./components/paymentTypes";
import WorkingHours from "./components/workingHours";
import SortList from "./modals/sortList";
import ProductPoolModal from "./modals/productPoolModal";
import Advert from "./components/advert";
function ManagementPanel() {
  let { id } = useParams();
  const [createHeaderModal, setCreateHeaderModal] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [actionType, setActionType] = useState("delete");
  const [currentHeader, setCurrentHeader] = useState(null);
  const [productObj, setProductObj] = useState(null);
  const [productAddOrUpdate, setProductAddOrUpdate] = useState(null);
  const [productDeleteModal, setProductDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const [currentProductType, setCurrentProductType] = useState(null);
  const [productPhotoModal, setProductPhotoModal] = useState(false);
  //sort
  const [sortListModal, setSortListModal] = useState("");
  const [sortList, setSortList] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [openProductPoolModal, setOpenProductPoolModal] = useState(false);

  const {
    getProductHeaderList,
    productHeaderList,
    getProductList,
    productList,
    menuDetailChangeFunc,
    menuHeaderDetailChangeFunc,
    restaurantEnduserAppListFunc,
    menuHeaderPhotoUploadFunc,
    sendPushNotificationFunc,
    menuPhotoUploadFunc,
    resDetailInfo,
    resDetailInfoFunc,
    resChangeDetailFunc,
    menuHeaderCreateFunc,
    menuHeaderSortFunc,
    menuProductSortFunc,
  } = useContext(RestaurantContext);

  useEffect(() => {
    restaurantEnduserAppListFunc(id);
    getProductHeaderList(id);
    getProductList(id);
    resDetailInfoFunc(id);
  }, []);

  const sortListFunc = (list) => {
    let sortObj = list?.map((item) => {
      return item.id;
    });

    if (sortType == "header") {
      menuHeaderSortFunc({
        sortObj,
        id,
      });
    } else {
      menuProductSortFunc({
        sortObj,
        id,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "99%",
          backgroundColor: "#f4f4f4",
          borderRadius: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 1,
        }}
      >
        <PanelActionNavbar
          actionType={actionType}
          setActionType={setActionType}
        />
        {actionType == "area" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <ServiceArea
              resDetailInfo={resDetailInfo}
              resChangeDetailFunc={resChangeDetailFunc}
              id={id}
            />
          </div>
        ) : actionType == "push" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <PushNotification
              sendPushNotificationFunc={sendPushNotificationFunc}
              id={id}
            />
          </div>
        ) : actionType == "rating" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <RestaurantRating resDetailInfo={resDetailInfo} id={id} />
          </div>
        ) : actionType == "advert" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <Advert resDetailInfo={resDetailInfo} />
          </div>
        ) : actionType == "payment_type" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <PaymentTypes resDetailInfo={resDetailInfo} id={id} />
          </div>
        ) : actionType == "working_hours" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <WorkingHours resDetailInfo={resDetailInfo} id={id} />
          </div>
        ) : actionType == "customers" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <Customers id={id} />
          </div>
        ) : (
          <CardFlip
            isFlipped={isFlipped}
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
          >
            <MenuList
              productHeaderList={productHeaderList}
              /* productList={productList} */
              setCreateHeaderModal={setCreateHeaderModal}
              setCurrentHeader={setCurrentHeader}
              setIsFlipped={setIsFlipped}
              setProductObj={setProductObj}
              setProductAddOrUpdate={setProductAddOrUpdate}
              id={id}
              setDeleteType={setDeleteType}
              setProductDeleteModal={setProductDeleteModal}
              actionType={actionType}
              setCurrentProductType={setCurrentProductType}
              setProductPhotoModal={setProductPhotoModal}
              resChangeDetailFunc={resChangeDetailFunc}
              resDetailInfo={resDetailInfo}
              setSortListModal={setSortListModal}
              setSortList={setSortList}
              setSortType={setSortType}
            />
            {productAddOrUpdate != null ? (
              productAddOrUpdate ? (
                <MenuCreateProduct
                  currentHeader={currentHeader}
                  setIsFlipped={setIsFlipped}
                  productObj={productObj}
                  setProductObj={setProductObj}
                  id={id}
                />
              ) : (
                <MenuDetail
                  currentHeader={currentHeader}
                  setIsFlipped={setIsFlipped}
                  id={id}
                />
              )
            ) : (
              ""
            )}
          </CardFlip>
        )}
      </Box>
      <CreateHeader
        createHeaderModal={createHeaderModal}
        setCreateHeaderModal={setCreateHeaderModal}
        menuHeaderCreateFunc={menuHeaderCreateFunc}
        id={id}
      />
      <ProductPhotoModal
        productPhotoModal={productPhotoModal}
        setProductPhotoModal={setProductPhotoModal}
        menuPhotoUploadFunc={menuPhotoUploadFunc}
        row={currentHeader}
        currentProductType={currentProductType}
        menuHeaderPhotoUploadFunc={menuHeaderPhotoUploadFunc}
        id={id}
        setOpenProductPoolModal={setOpenProductPoolModal}
      />

      <ProductPoolModal
        openProductPoolModal={openProductPoolModal}
        setOpenProductPoolModal={setOpenProductPoolModal}
        currentHeader={currentHeader}
        currentProductType={currentProductType}
        setProductPhotoModal={setProductPhotoModal}
      />

      <ProductDeleteModal
        setProductDeleteModal={setProductDeleteModal}
        productDeleteModal={productDeleteModal}
        setDeleteType={setDeleteType}
        deleteType={deleteType}
        setCurrentRow={setCurrentHeader}
        currentRow={currentHeader}
        menuDetailChangeFunc={menuDetailChangeFunc}
        menuHeaderDetailChangeFunc={menuHeaderDetailChangeFunc}
        id={id}
      />
      <SortList
        sortListModal={sortListModal}
        setSortListModal={setSortListModal}
        sortList={sortList}
        sortListFunc={sortListFunc}
      />
    </>
  );
}

export default ManagementPanel;
