import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";

// TODO  error durumlarında neler yapılacak
export const API_URL = process.env.REACT_APP_BASE_URL;

async function refreshTokenFunc(config) {
  const refreshToken = localStorage.getItem("st-b-refresh");
  const accessToken = localStorage.getItem("st-b-access");
  const userInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));

  if (accessToken) {
    let decoded = jwt_decode(accessToken);
    let accessDecoded = moment.unix(decoded.exp).format("DD-MM-YYYY HH:mm:ss");
    let nowAdd30Sec = moment().add(30, "seconds").format("DD-MM-YYYY HH:mm:ss");
    if (accessDecoded < nowAdd30Sec) {
      if (refreshToken) {
        await axios
          .post(`${API_URL}/${userInfo.type}/refresh`, {
            refresh: refreshToken,
          })
          .then((response) => {
            localStorage.setItem("st-b-access", response.data.access);
            config.headers.Authorization = "Bearer " + response.data.access;
          })
          .catch(() => {
            setTimeout(() => {
              localStorage.removeItem("st-b-access");
              localStorage.removeItem("st-b-refresh");
              window.location.reload();
            }, 3000);
          });
      }
    } else {
      config.headers.Authorization = "Bearer " + accessToken;
    }
  }
  return config;
}
function parseBody(response) {
  if (response?.status === 502) {
    return response?.data;
  }
  if (response?.status === 500) {
    return response?.data;
  }
  if (response?.status === 401) {
    return response?.data;
  }
  return response?.data;
}

let instance = axios.create({
  baseURL: API_URL,
});

// request header
instance.interceptors.request.use(
  (config) => {
    return refreshTokenFunc(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return parseBody(response);
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("st-b-access");
      localStorage.removeItem("st-b-refresh");
      window.location.reload();
      return parseBody(error.response);
    }

    return Promise.reject(error);
  }
);

export const http = instance;
