import { Button, Paper } from "@mui/material";
import React, { createRef, useContext, useState } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
import { useToast } from "../../../../../components/snackbar";

const Advert = ({ resDetailInfo }) => {
  const { alert } = useToast();
  const { changeRestaurantPictureFunc, changeRestaurantVideoFunc } =
    useContext(RestaurantContext);
  const fileInputRef = createRef();
  const fileInputRef2 = createRef();

  const onFileInputChange = async (event) => {
    const res = await readFile(event.target.files[0]);

    const img = new Image();
    img.onload = () => {
      let data = {
        id: resDetailInfo?.user,
        restaurant_picture: event.target.files[0],
      };
      changeRestaurantPictureFunc(data);
    };
    img.src = res;
  };
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onFileInputChangeVideo = (event) => {
    let data = {
      id: resDetailInfo?.user,
      restaurant_video: event.target.files[0],
    };
    changeRestaurantVideoFunc(data);
  };

  return (
    <div style={{ display: "flex" }}>
      <Paper
        style={{
          backgroundColor: "#474545",
          width: 300,
          color: "#fff",
          padding: 1,
          display: "flex",
          justifyContent: "space-around",
          height: 200,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "auto",
            backgroundColor: "#403836",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          Vitrin Resmi
        </div>
        <Button
          size="small"
          variant="contained"
          style={{
            fontWeight: 700,
            width: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            cursor: "pointer",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            fontSize: 16,
            backgroundColor: "#f53b62",
          }}
          onClick={() => {
            window.open(resDetailInfo?.picture, "_blank");
          }}
        >
          Mevcut Resmi göster
        </Button>
        <div>
          <div
            style={{
              background: "#f53b62",
              padding: "5px",
              color: "#fff",
              textAlign: "center",
              borderRadius: 5,
            }}
          >
            <label
              htmlFor="filePicker"
              style={{
                fontWeight: 700,
                width: 200,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              Yeni resim ekle
            </label>
          </div>
          <input
            id="filePicker"
            ref={fileInputRef}
            type="file"
            onChange={(e) => onFileInputChange(e)}
            style={{ visibility: "hidden" }}
          />
        </div>
      </Paper>
      <Paper
        style={{
          backgroundColor: "#474545",
          width: 300,
          color: "#fff",
          padding: 1,
          display: "flex",
          justifyContent: "space-around",
          height: 200,
          alignItems: "center",
          flexDirection: "column",
          marginLeft: 30,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "auto",
            backgroundColor: "#403836",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          Restoran Videosu
        </div>
        <Button
          size="small"
          variant="contained"
          style={{
            fontWeight: 700,
            width: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            cursor: "pointer",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            fontSize: 16,
            backgroundColor: "#f53b62",
          }}
          onClick={() => {
            window.open(resDetailInfo?.video, "_blank");
          }}
        >
          Mevcut video göster
        </Button>
        <div>
          <div
            style={{
              background: "#f53b62",
              padding: "5px",
              color: "#fff",
              textAlign: "center",
              borderRadius: 5,
            }}
          >
            <label
              htmlFor="videoPicker"
              style={{
                fontWeight: 700,
                width: 200,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              Yeni video ekle
            </label>
          </div>
          <input
            id="videoPicker"
            ref={fileInputRef2}
            type="file"
            accept="video/*"
            // onChange={(e) => onFileInputChangeVideo(e)}
            style={{ visibility: "hidden" }}
            onChange={onFileInputChangeVideo}
          />
        </div>
      </Paper>
    </div>
  );
};

export default Advert;
