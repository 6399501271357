import { Box, Modal } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
export default function RequestStatusesModal({
  openRequestStatuses,
  setOpenRequestStatuses,
  selectedRequest,
}) {
  const getRequestStatusTitle = (row) => {
    const currentStatus =
      row === "waiting_confirm"
        ? "Matbaa Onay Bekliyor"
        : row === "waiting_offer"
        ? "Matbaa'ya yönlendirildi"
        : row === "created"
        ? "Baskı İsteği Oluşturuldu"
        : row === "confirm" && selectedRequest.is_document_downloaded
        ? "PDF Baskıda"
        : row === "confirm" && !selectedRequest.is_document_downloaded
        ? "Finans Onayladı"
        : row === "shipped"
        ? "Kargoya Verildi"
        : row === "delivered"
        ? "Teslim Edildi"
        : row === "checking"
        ? "Matbaa Teslimat Kontrol"
        : row === "complated"
        ? "Tamamlandı"
        : row === "unapproved"
        ? "Matbaa Onay Bekleniyor"
        : "";
    return currentStatus;
  };
  return (
    <Modal
      open={openRequestStatuses}
      onClose={() => {
        setOpenRequestStatuses(false);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
          maxHeight: 550,
          overflow: "auto",
          minHeight: 400,
        }}
      >
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Baskı İsteği Aşamaları
        </h2>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {selectedRequest?.status_changes?.map((item, i) => {
            let durationHours, durationMinutes, durationSeconds;

            if (
              item.new_status ===
              selectedRequest?.status_changes[i + 1]?.old_status
            ) {
              durationHours = moment(
                selectedRequest?.status_changes[i + 1]?.changed_at
              ).diff(item.changed_at, "hours");
              durationMinutes =
                moment(selectedRequest?.status_changes[i + 1]?.changed_at).diff(
                  item.changed_at,
                  "minutes"
                ) % 60;
              durationSeconds =
                moment(selectedRequest?.status_changes[i + 1]?.changed_at).diff(
                  item.changed_at,
                  "seconds"
                ) % 60;

              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#fff",
                    borderRadius: 8,
                    margin: 10,
                    padding: 10,
                    width: "45%",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{ fontSize: 14, fontWeight: 500, marginRight: 20 }}
                  >
                    {getRequestStatusTitle(item.new_status)}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {durationHours +
                        " saat " +
                        durationMinutes +
                        " dakika " +
                        durationSeconds +
                        " saniye"}
                    </span>
                    <ArrowRightAltIcon style={{ transform: "scale(2.0)" }} />
                  </div>
                  <span
                    style={{ fontSize: 14, fontWeight: 500, marginLeft: 20 }}
                  >
                    {getRequestStatusTitle(
                      selectedRequest?.status_changes[i + 1]?.new_status
                    )}
                  </span>
                </div>
              );
            } else {
              return;
            }
          })}
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    width: "70%",
  },
};
