import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, createTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Paths } from "../../../utils/constants";
import Colors from "../../../utils/colors";

import { RestaurantContext } from "../../../contexts/restaurantContext";

import CustomerSearch from "../../../components/search";
import useDebounce from "../../../components/debounce";
import FormatNumber from "../../../components/formatData/formatPhone";
import SendSmsModal from "../../../components/modals/sendSmsModal";
import { ManagementContext } from "../../../contexts/managementContext";
import { LoginContext } from "../../../contexts/loginContext";
import AddProductModal from "./AddProductModal";
import CreateTicketModal from "./CreateTicketModal";

const headColumns = [
  { id: "name", label: "Marka", minWidth: 150, align: "center" },
  { id: "city", label: "İl", minWidth: 120, align: "center" },
  { id: "town", label: "İlçe", minWidth: 120, align: "center" },
  { id: "neighborhood", label: "Mahalle", minWidth: 130, align: "center" },
  { id: "constant_phone", label: "Sabit", align: "center" },
  { id: "phone", label: "Cep", align: "center" },
  { id: "eft_amount", label: "Ön Ödeme", minWidth: 120, align: "center" },
  { id: "online", label: "Durum", minWidth: 120, align: "center" },
  { id: "user_id", label: "Detay", align: "center" },
  { id: "sms", label: "Sms", align: "center" },
  { id: "products", label: "Kullanılan Ürünler", align: "center" },
  { id: "new_product", label: "Yeni Ürün Başvuru", align: "center" },
  { id: "ticket", label: "Teknik kayıt", align: "center" },
];

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
});

const TechMyRestaurants = () => {
  let history = useHistory();
  const classes = useStyles();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    setRestaurantID,
    myResmtaurantsData,
    getmyRestaurantTableDataList,
    myResmtaurantsTagReport,
    getmyRestaurantTagReport,
    setPreDealarSidebarPage,
    getCity,
    getTown,
    restofficialRefsFunc,
    getmyRestaurantTableDataListTech,
  } = useContext(RestaurantContext);

  // * city
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [selectedSmsRest, setSelectedSmsRest] = useState(null);

  const { smsSettingsList, getSmsSettings } = useContext(ManagementContext);
  const { getUserRole, userRole } = useContext(LoginContext);
  const [openProductModal, setopenProductModal] = useState(false);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [selectedRest, setSelectedRest] = useState(null);

  useEffect(() => {
    getSmsSettings();
    setPreDealarSidebarPage(1);
    getUserRole();
    getmyRestaurantTagReport();
    getCity();
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm !== "") {
      const data = {
        page: page + 1,
        search: searchValue,
      };
      getmyRestaurantTableDataListTech(data);
    }
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    if (selectedCity !== null || selectedTown !== null) {
      const data = {
        city: selectedCity !== null ? selectedCity?.id : "",
        town: selectedTown !== null ? selectedTown?.id : "",
        page: page2 + 1,
      };
      restofficialRefsFunc(data);
    }
  }, [selectedCity, selectedTown, page2]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };

  const handleOpenSmsModal = (row) => {
    setSelectedSmsRest(row);
    setShowSmsModal(true);
  };

  useEffect(() => {
    if (selectedCity != null) {
      getTown(selectedCity.id);
    }
  }, [selectedCity]);

  const handleAddProducts = (row) => {
    setopenProductModal(true);
    setSelectedRest(row);
  };

  const handleCreateTicket = (row) => {
    setSelectedRest(row);
    setOpenTicketModal(true);
  };

  const customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const Card = ({
    title,
    value,
    totalValue,
    labelStyle,
    boxStyle,
    onClick,
  }) => {
    return (
      <div
        style={{
          ...styles.boxContent,
          marginRight: 10,
          marginBottom: matches ? 0 : 12,
          ...boxStyle,
        }}
        onClick={onClick}
      >
        <span style={{ ...styles.boxTitle, ...labelStyle }}> {title}</span>
        <span style={{ ...styles.boxTitle, ...labelStyle }}>
          {" "}
          {totalValue && value !== undefined ? (
            <span style={{ display: "flex", flexDirection: "row" }}>
              {value}{" "}
              <span
                style={{
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 1,
                  marginLeft: 3,
                }}
              >
                (%{((value / totalValue) * 100).toFixed(1)})
              </span>
            </span>
          ) : (
            "-"
          )}
        </span>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      {JSON.parse(localStorage.getItem("st-officeuser-info")).role !==
        "tech" && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: 8,
            flexDirection: matches ? "row" : "column",
            justifyContent: matches ? "space-around" : "center",
            alignItems: "center",
          }}
        >
          <Card
            title={"Tümü"}
            value={myResmtaurantsTagReport?.total}
            totalValue={myResmtaurantsTagReport?.total}
            // boxStyle={{
            //   backgroundColor: active === "all" ? "#ffa091" : "#fff",
            // }}
            // onClick={() => setActive("all")}
          />
          <Card
            title={" Aktif Ödendi"}
            value={myResmtaurantsTagReport?.active_payed}
            totalValue={myResmtaurantsTagReport?.total}
            // boxStyle={{
            //   backgroundColor: active === "active_paymend" ? "#ffa091" : "#fff",
            // }}
            // onClick={() => setActive("active_paymend")}
          />
          <Card
            title={" Pasif Ödendi"}
            value={myResmtaurantsTagReport?.passive_payed}
            totalValue={myResmtaurantsTagReport?.total}
            // boxStyle={{
            //   backgroundColor: active === "pasive_paymend" ? "#ffa091" : "#fff",
            // }}
            // onClick={() => setActive("pasive_paymend")}
          />
          <Card
            title={" Aktif Ödenmedi"}
            value={myResmtaurantsTagReport?.active_not_payed}
            totalValue={myResmtaurantsTagReport?.total}
            // boxStyle={{
            //   backgroundColor:
            //     active === "active_not_paymend" ? "#ffa091" : "#fff",
            //  }}
            // onClick={() => setActive("active_not_paymend")}
          />
          <Card
            title={" Pasif Ödenmedi"}
            value={myResmtaurantsTagReport?.passive_not_payed}
            totalValue={myResmtaurantsTagReport?.total}
            // boxStyle={{
            //   backgroundColor:
            //     active === "pasive_not_paymend" ? "#ffa091" : "#fff",
            // }}
            // onClick={() => setActive("pasive_not_paymend")}
          />
          <Card
            title={"Blokeler"}
            value={myResmtaurantsTagReport?.bloked}
            totalValue={myResmtaurantsTagReport?.total}
            // boxStyle={{
            //   backgroundColor: active === "bloked" ? "#ffa091" : "#fff",
            // }}
            // onClick={() => setActive("bloked")}
          />
          <Card
            title={"Ön Ödeme"}
            value={myResmtaurantsTagReport?.deptor_count}
            totalValue={myResmtaurantsTagReport?.total}
            // boxStyle={{
            //   backgroundColor: active === "deptor" ? "#ffa091" : "#fff",
            // }}
            // onClick={() => setActive("deptor")}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Paper elevation={3} style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: matches ? "row" : "column-reverse",
              marginTop: 10,
              alignItems: "center",
              justifyContent: matches ? "space-between" : "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: matches ? "25%" : "96%",
                zIndex: 9,
                margin: 30,
              }}
            >
              <CustomerSearch
                value={searchValue}
                placeholder={"Restoran Ara"}
                onchange={setSearchValue}
              />
            </div>

            <Button
              variant="outlined"
              size="large"
              style={{
                letterSpacing: 1.5,
                fontWeight: 700,
                backgroundColor: "#0FBD20",
                color: "#fff",
                margin: 30,
              }}
              onClick={() => {
                history.push("/operation/register");
              }}
            >
              Yeni Başvuru Oluştur
            </Button>
          </div>

          <Paper sx={{ width: "98%", overflow: "hidden", marginBottom: 10 }}>
            <TableContainer style={{ maxHeight: 500 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headColumns.map((column, idx) => (
                      <TableCell
                        key={idx}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: Colors.tableHeadColor,
                          height: 32,
                          borderRightWidth: 0.2,
                          borderRightColor: "#fff",
                          border: "0.2px solid #fff",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myResmtaurantsData?.results?.map((row, idx) => {
                    return (
                      <TableRow hover tabIndex={-1} key={idx}>
                        {headColumns.map((column, idx) => {
                          const value = row[column.id];
                          if (
                            column.id === "city" ||
                            column.id === "town" ||
                            column.id === "neighborhood"
                          ) {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                {row[column.id]?.name}
                              </TableCell>
                            );
                          } else if (
                            column.id === "constant_phone" ||
                            column.id === "phone"
                          ) {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  fontSize: 12,
                                }}
                              >
                                {FormatNumber(value, "")}
                              </TableCell>
                            );
                          } else if (column.id === "online") {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  cursor: "pointer",
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                <div
                                  style={{
                                    width: "90%",
                                    height: "80%",
                                    backgroundColor: "black",
                                    borderRadius: 4,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "30%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: 12,
                                        height: 12,
                                        borderRadius: 6,
                                        backgroundColor: value
                                          ? "green"
                                          : "red",
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "70%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: 13,
                                        color: "white",
                                      }}
                                    >
                                      {value ? "Açık" : "Kapalı"}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                            );
                          } else if (column.id === "user_id") {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  cursor: "pointer",
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                <BorderColorIcon
                                  size="small"
                                  onClick={() => {
                                    history.push(`${Paths.kunye}/${value}`);
                                    setRestaurantID(value);
                                  }}
                                />
                              </TableCell>
                            );
                          } else if (column.id === "eft_amount") {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                {value ? value + " TL" : "0"}
                              </TableCell>
                            );
                          } else if (column.id === "sms") {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                <div
                                  style={{
                                    ...styles.iconContent,
                                    cursor: "pointer",
                                    background: "#f53b62",
                                    color: "#fff",
                                    borderRadius: "4px",
                                    padding: 3,
                                    justifyContent: "center",
                                  }}
                                  onClick={() => handleOpenSmsModal(row)}
                                >
                                  Sms
                                </div>
                              </TableCell>
                            );
                          } else if (column.id === "products") {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  minWidth: 200,
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                {row?.products?.map((item, idx) => {
                                  if (!item.status) return null;
                                  return (
                                    <span
                                      key={idx}
                                      style={{ fontSize: 12, color: "black" }}
                                    >
                                      {item.name},
                                    </span>
                                  );
                                })}
                              </TableCell>
                            );
                          } else if (column.id === "new_product") {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  minWidth: 120,
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                <AddCircleIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleAddProducts(row)}
                                />
                              </TableCell>
                            );
                          } else if (column.id === "ticket") {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  minWidth: 100,
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                <AddCircleIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleCreateTicket(row)}
                                />
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align}
                                style={{
                                  ...styles.tableCell,
                                  borderRightColor:
                                    idx === headColumns.length - 1
                                      ? "#fff"
                                      : "#f1f1f1",
                                }}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : column?.id == "name"
                                  ? value + " ( v" + row?.version + " )"
                                  : value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {myResmtaurantsData?.results && (
              <TablePagination
                style={{ backgroundColor: Colors.tableHeadColor }}
                classes={{ root: classes.root }}
                rowsPerPageOptions={[10]}
                labelRowsPerPage={"Sayfa Başına Kayıt"}
                component="div"
                count={myResmtaurantsData?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Paper>
      </div>

      <SendSmsModal
        showSmsModal={showSmsModal}
        setShowSmsModal={setShowSmsModal}
        smsSettingsList={smsSettingsList}
        selectedSmsRest={selectedSmsRest}
        isRestaurant={true}
      />

      <AddProductModal
        openProductModal={openProductModal}
        setopenProductModal={setopenProductModal}
        selectedRest={selectedRest}
      />

      <CreateTicketModal
        openTicketModal={openTicketModal}
        setOpenTicketModal={setOpenTicketModal}
        selectedRest={selectedRest}
      />
    </div>
  );
};

const styles = {
  container: { padding: 30, height: "80%" },

  boxContent: {
    display: "flex",
    backgroundColor: "white",
    padding: 4,
    width: "90%",
    flexDirection: "column",
    borderRadius: 6,
    fontSize: 20,
    cursor: "pointer",
  },
  boxTitle: {
    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 900,
    fontStyle: "normal",
    width: "80%",
    marginBottom: 4,
    textAlign: "center",
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCell: {
    color: Colors.tableHeadColor,
    borderLeftColor: "#f4f4f4",
    borderRightStyle: "solid",
    borderBottomColor: "#f2f3f9",
    backgroundColor: "#fff",
  },
};

export default TechMyRestaurants;
