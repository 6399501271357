import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MyPrim = () => {
  const {
    sellerMyPrimFunc,
    sellerMyPrim,
    sellerMyPrimHistoryFunc,
    sellerMyPrimHistory,
  } = useContext(RestaurantContext);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    sellerMyPrimFunc();
  }, []);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <div style={{ width: "80%" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Restoran ismi</TableCell>
                  <TableCell align="center">İl</TableCell>
                  <TableCell align="center">İlçe</TableCell>
                  <TableCell align="center">Kurulum</TableCell>
                  <TableCell align="center">Tahsilat</TableCell>
                  <TableCell align="center">Prim</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sellerMyPrim?.data?.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="center">{row?.name}</TableCell>
                      <TableCell align="center">{row?.city}</TableCell>
                      <TableCell align="center">{row?.town}</TableCell>
                      <TableCell align="center">
                        {row?.status == "setup"
                          ? "Başarılı"
                          : row?.status == "reject"
                          ? "Başarısız"
                          : "Bekliyor"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.status == "setup"
                          ? row?.payed
                          : row?.status == "reject"
                          ? "Başarısız"
                          : "-"}
                      </TableCell>
                      <TableCell align="center">{row?.prim} TL</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <div style={{ width: "65%" }}>
          {sellerMyPrim?.payment_history?.map((row, index) => {
            return (
              <Accordion
                onChange={handleChange(row?.id)}
                expanded={expanded === row?.id}
                onClick={() => {
                  let data = {
                    start_date: row?.start_at,
                    end_date: row?.end_at,
                  };
                  sellerMyPrimHistoryFunc(data);
                }}
                key={index}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    <Typography style={{ fontFamily: "segoeuiB" }}>
                      {row?.start_at} - {row?.end_at}
                    </Typography>

                    <Typography style={{ fontFamily: "segoeuiB" }}>
                      {row?.total_prim} TL
                    </Typography>
                    <Typography style={{ fontFamily: "segoeuiB" }}>
                      {row?.payed ? "Ödendi" : "Bekliyor"}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Restoran ismi</TableCell>
                            <TableCell align="center">İl</TableCell>
                            <TableCell align="center">İlçe</TableCell>
                            <TableCell align="center">Kurulum</TableCell>
                            <TableCell align="center">Tahsilat</TableCell>
                            <TableCell align="center">Prim</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sellerMyPrimHistory?.map((row, i) => {
                            return (
                              <TableRow key={i}>
                                <TableCell align="center">
                                  {row?.name}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.city}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.town}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.status == "setup"
                                    ? "Başarılı"
                                    : row?.status == "reject"
                                    ? "Başarısız"
                                    : "Bekliyor"}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.status == "setup"
                                    ? row?.payed
                                      ? "Başarılı"
                                      : "Bekliyor"
                                    : row?.status == "reject"
                                    ? "Başarısız"
                                    : "-"}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.prim} TL
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MyPrim;
