import React, { useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  Divider,
  InputBase,
  Modal,
  Paper,
} from "@mui/material";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 90%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const TaskReminder = ({ reminderModal, setReminderModal, reminderRow }) => {
  const classes = useStyles();
  const [note, setNote] = useState("");
  const { createTaskReminder } = useContext(RestaurantContext);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={reminderModal}
      className={classes.modal}
      onClose={() => {
        setReminderModal(false);
      }}
    >
      <Card
        style={{
          width: "50%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 600,
          overflow: "auto",
        }}
      >
        <div
          style={{
            fontSize: 24,
            color: "red",
            fontFamily: "SgBd",
          }}
        >
          HATIRLATMA
        </div>
        <Divider style={{ margin: "15px 0px" }} />

        {reminderRow?.reminders?.length !== 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              style={{
                display: "flex",
                backgroundColor: "#f4f4f4",
                alignSelf: "center",
                width: "100%",
                marginTop: 10,
              }}
            >
              <InputBase
                multiline
                value={note}
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "math",
                  paddingLeft: 8,
                }}
                rows={6}
                placeholder={"Not ekle"}
                className={classes.input}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </Paper>
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                fontWeight: 700,
                backgroundColor: "#0FBD20",
                width: "15%",
                marginLeft: 10,
                height: "50%",
              }}
              onClick={() => {
                let data = {
                  sendObj: {
                    restofficial: reminderRow?.restofficial?.id,
                    note,
                    officeuser_task_type: reminderRow?.task_type?.id,
                  },
                  setNote,
                };
                createTaskReminder(data);
                setReminderModal(false);
              }}
            >
              Oluştur
            </Button>
          </div>
        )}
      </Card>
    </Modal>
  );
};

export default TaskReminder;
