import React, { useEffect } from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 14,
    },
    "& input::placeholder": {
      fontSize: 14,
    },
  },
}));

const CustomerSearch = ({ value, placeholder, onchange }) => {
  const classes = useStyles();
  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, []);

  const keyPress = (e) => {
    if (e?.keyCode === 27) {
      //onclick get service data
    }
  };

  return (
    <Paper
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        height: 42,
        backgroundColor: "#c9d1cc",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "85%",
        }}
      >
        <IconButton>{/* <img src={searchLogo} /> */}</IconButton>
        <InputBase
          spellCheck="false"
          style={{ width: "100%", fontFamily: "segoeuiRg" }}
          placeholder={placeholder}
          className={classes.input}
          value={value}
          onChange={(e) => {
            onchange(e.target.value);
          }}
        />
      </div>
      <IconButton type="submit" aria-label="search" style={{ width: "15%" }}>
        <Search />
      </IconButton>
    </Paper>
  );
};

export default CustomerSearch;
