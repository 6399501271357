export const selectDefaultStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.selectProps.menuColor,
    borderRadius: 6,
    width: state.selectProps.selectWidth,
    color: "#fff",
    border: 0,
    cursor: "pointer",
    boxShadow: "none",
    //height: 25,
    minHeight: 25,
    zIndex: 1,
  }),
  container: (styles, state) => ({
    ...styles,
    width: state.selectProps.selectWidth,
    zIndex: state.selectProps.zIndex,
    fontSize: state.selectProps.fontSize,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: "#fff",
    fontFamily: "SgSemi",
    fontSize: state.selectProps.fontSize,
  }),
  dropdownIndicator: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: "white",
  }),
  indicatorSeparator: (styles, { isFocused, isSelected }) => ({
    ...styles,
    display: "none",
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: "black",
    fontFamily: "SgSemi",
    fontSize: state.selectProps.fontSize,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "#5a5757",
      color: "white",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      borderBottom: "1px solid gray",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#f53b62",
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: state.selectProps.hoverColor,
        color: "white",
      },
    };
  },
};
