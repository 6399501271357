import { useContext, useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import CustomDatePickerInput from "../../../components/datePickerInput";
import useWindowDimensions from "../../../components/windowSize";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { ManagementContext } from "../../../contexts/managementContext";
import GridItem from "../../../components/gridItem";
import { Divider, Grid } from "@mui/material";
import StackedBar from "../../../components/charts/StackedBar";

const today = new Date();
const lastWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7
);
const lastMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 30
);
const lastThreeMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 90
);
const dates = [
  {
    label: "Bugün",
    date: [today, today],
  },
  {
    label: "Son 1 Hafta",
    date: [lastWeek, today],
  },
  {
    label: "Son 1 Ay",
    date: [lastMonth, today],
  },
  {
    label: "Son 3 Ay",
    date: [lastThreeMonth, today],
  },
];

export default function RestaurantReports() {
  const { width } = useWindowDimensions();
  const [paymentType, setPaymentType] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [selectDate, setSelectDate] = useState([]);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const {
    getApplicationTransferList,
    getPaymentTypes,
    paymentTypes,
    platforms,
    getPlatforms,
  } = useContext(RestaurantContext);

  const {
    getRestaurantServices,
    restaurantServicesInfo,
    getRestaurantServicesGraphs,
    restaurantServicesGrahps,
  } = useContext(ManagementContext);

  useEffect(() => {
    if (platforms) {
      platforms.unshift({
        id: 0,
        name: "Tümü",
      });
    }
  }, [platforms]);

  useEffect(() => {
    if (paymentTypes) {
      paymentTypes.unshift({
        id: 0,
        name: "Tümü",
      });
    }
  }, [paymentTypes]);

  const handleFilterDate = (e) => {
    setDateRange(e.date);
    setIsDateSelected(false);
    setSelectDate(e);
  };

  const handleChangePaymentType = (e) => {
    setPaymentType(e);
  };

  const handleChangePlatform = (e) => {
    setPlatform(e);
  };

  useEffect(() => {
    const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
    const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
    let data = {
      start: selectedStartDate,
      end: selectedEndDate,
      platform: platform && platform.id !== 0 ? platform.id : "",
      paymentType: paymentType && paymentType.id !== 0 ? paymentType.id : "",
    };
    if (startDate && endDate) {
      getRestaurantServices(data);
    }
  }, [platform, paymentType, startDate, endDate]);

  useEffect(() => {
    getPaymentTypes();
    getPlatforms();
    getRestaurantServicesGraphs();
  }, []);

  return (
    <Container>
      <Box>
        <h3
          style={{
            fontFamily: "segoeuiB",
            fontSize: 24,
            textAlign: "center",
          }}
        >
          RESTORAN RAPORLARI
        </h3>

        <Box sx={{ display: "flex" }}>
          <div style={{ marginRight: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Tarih Filtrele
            </span>
            <Select
              options={dates}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={
                isDateSelected
                  ? "-"
                  : selectDate.label
                  ? selectDate.label
                  : "Bugün"
              }
              value={isDateSelected ? "-" : selectDate}
              getOptionValue={(option) => option.date}
              onChange={(e) => {
                handleFilterDate(e);
              }}
              getOptionLabel={(option) => option.label}
            />
          </div>
          <Box>
            <div style={{ marginRight: 10 }}>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Tarih
              </span>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                fixedHeight="45px"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                onChange={(update) => {
                  setIsDateSelected(true);
                  setDateRange(update);
                }}
                customInput={
                  <CustomDatePickerInput
                    selectWidth={width > 1200 ? 230 : 140}
                  />
                }
                locale="tr"
              />
            </div>
          </Box>
          <div style={{ marginRight: 10 }}>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Ödeme Yöntemleri
            </span>
            <Select
              options={paymentTypes}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={paymentType}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                handleChangePaymentType(e);
              }}
              getOptionLabel={(option) => option.name}
            />
          </div>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Platform
            </span>
            <Select
              options={platforms}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={platform}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                handleChangePlatform(e);
              }}
              getOptionLabel={(option) => option.name}
            />
          </div>
        </Box>
      </Box>
      <Grid
        container
        columns={12}
        spacing={2}
        sx={{ marginTop: 2, marginBottom: 4 }}
      >
        <GridItem
          title="Toplam Sipariş"
          per={4}
          count={restaurantServicesInfo?.toplam_siparis}
        />
        <GridItem
          title="Toplam Ciro"
          per={4}
          count={restaurantServicesInfo?.toplam_siparis_tutar}
        />
        <GridItem
          title="Ortalama Tutar"
          per={4}
          count={restaurantServicesInfo?.ortalama_tutar}
        />
        <GridItem
          title="Toplam İptal"
          per={4}
          count={restaurantServicesInfo?.toplam_iptal_siparis}
        />
        <GridItem
          title="Toplam Ciro"
          per={4}
          count={restaurantServicesInfo?.toplam_iptal_tutar}
        />
        <GridItem
          title="Ortalama Ciro"
          per={4}
          count={restaurantServicesInfo?.ortalama_iptal_tutar}
        />
        <GridItem
          title="Müşteri Adet"
          per={4}
          count={restaurantServicesInfo?.toplam_musteri}
        />
        <GridItem
          title="Tekrar Sipariş Veren Müşteri Adeti"
          per={4}
          count={restaurantServicesInfo?.toplam_tekrar_siparis_musteri}
        />
        <GridItem
          title="Tekrar Sipariş Oranı"
          per={4}
          count={
            restaurantServicesInfo?.toplam_tekrar_siparis /
            restaurantServicesInfo?.toplam_tekrar_siparis_musteri
          }
        />
      </Grid>
      <Divider />
      <Grid
        container
        justifyContent="center"
        columns={12}
        spacing={2}
        sx={{ marginTop: 2 }}
      >
        <GridItem
          title="TOPLAM AKTİF RESTORAN"
          center={true}
          per={4}
          count={restaurantServicesInfo?.restoranlar?.toplam_restoran}
        />
      </Grid>
      <Grid container columns={12} spacing={2} sx={{ marginTop: 2 }}>
        <GridItem
          title="Yemeksepeti Aktif Restoran / Tabela"
          per={4}
          count={restaurantServicesInfo?.restoranlar?.restoran_Yemeksepeti}
          countTwo={restaurantServicesInfo?.restoranlar?.tabela_Yemeksepeti}
        />
        <GridItem
          title="Getir Aktif Restoran / Tabela"
          per={4}
          count={restaurantServicesInfo?.restoranlar?.restoran_Getir}
          countTwo={restaurantServicesInfo?.restoranlar?.tabela_Getir}
        />
        <GridItem
          title="Trendyol Aktif Restoran / Tabela"
          per={4}
          count={restaurantServicesInfo?.restoranlar?.restoran_Trendyol}
          countTwo={restaurantServicesInfo?.restoranlar?.tabela_Trendyol}
        />
        <GridItem
          title="Migros Aktif Restoran / Tabela"
          per={4}
          count={restaurantServicesInfo?.restoranlar?.restoran_Migros}
          countTwo={restaurantServicesInfo?.restoranlar?.tabela_Migros}
        />
        <GridItem
          title="Fuudy Aktif Restoran / Tabela"
          per={4}
          count={restaurantServicesInfo?.restoranlar?.restoran_Fuddy}
          countTwo={restaurantServicesInfo?.restoranlar?.tabela_Fuddy}
        />
        <GridItem
          title="Gofody Aktif Restoran / Tabela"
          per={4}
          count={restaurantServicesInfo?.restoranlar?.restoran_Gofody}
          countTwo={restaurantServicesInfo?.restoranlar?.tabela_Gofody}
        />
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          {restaurantServicesGrahps?.siparisler && (
            <StackedBar
              title={"Toplam Sipariş / Toplam Ciro / Ort. Tutar"}
              data={[
                {
                  dataKey: "toplam_order",
                  name: "Toplam Sipariş",
                  color: "blue",
                },
                {
                  dataKey: "toplam_order_ciro",
                  name: "Toplam Ciro",
                  color: "red",
                },
                {
                  dataKey: "ortalama_tutar",
                  name: "Ort. Tutar",
                  color: "#03bf00",
                },
              ]}
              graph={restaurantServicesGrahps?.siparisler}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {restaurantServicesGrahps?.siparisler && (
            <StackedBar
              title={"Toplam İptal / Toplam Ciro / Ort. Ciro"}
              data={[
                {
                  dataKey: "toplam_iptal",
                  name: "Toplam İptal",
                  color: "blue",
                },
                {
                  dataKey: "toplam_iptal_ciro",
                  name: "Toplam Ciro",
                  color: "red",
                },
                {
                  dataKey: "ortalama_iptal_tutar",
                  name: "Ort. Ciro",
                  color: "#03bf00",
                },
              ]}
              graph={restaurantServicesGrahps?.siparisler}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {restaurantServicesGrahps?.tekrar_siparisler && (
            <StackedBar
              title={
                "Müşteri Adet / Tekrar Sipariş Veren Müşteri Adet / Tekrar Sipariş Oranı"
              }
              data={[
                {
                  dataKey: "toplam_musteri",
                  name: "Müşteri Adet",
                  color: "blue",
                },
                {
                  dataKey: "tekrar_siparis_musteri",
                  name: "Tekrar Sipariş Veren Müşteri Adet",
                  color: "red",
                },
                {
                  dataKey: "tekrar_siparis_oran",
                  name: "Sipariş Oranı",
                  color: "#03bf00",
                },
              ]}
              graph={restaurantServicesGrahps?.tekrar_siparisler}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {restaurantServicesGrahps?.odeme_yontemleri && (
            <StackedBar
              title={"Ödeme Yöntemi Sipariş Adetleri ve Yüzdeleri"}
              data={[
                {
                  dataKey: "bkm_online_ödeme",
                  name: "Bkm Online Ödeme",
                  color: "#8884d8",
                },
                {
                  dataKey: "cio_kart",
                  name: "Cio Kart",
                  color: "#82ca9d",
                },
                {
                  dataKey: "garantipay_online_ödeme",
                  name: "Garantipay Online Ödeme",
                  color: "#609EA2",
                },
                {
                  dataKey: "kredi_/_banka_kartı",
                  name: "Kredi / Banka Kartı",
                  color: "#4e7a56",
                },
                {
                  dataKey: "metropol_kartı",
                  name: "Metropol Kartı",
                  color: "blue",
                },
                {
                  dataKey: "moneypay_online_ödeme",
                  name: "Moneypay Online Ödeme",
                  color: "black",
                },
                {
                  dataKey: "multinet_kartı",
                  name: "Multinet Kartı",
                  color: "#C93D1B",
                },
                {
                  dataKey: "nakit",
                  name: "Nakit",
                  color: "gray",
                },
                {
                  dataKey: "online_ödeme",
                  name: "Online Ödeme",
                  color: "#9DF1DF",
                },
                {
                  dataKey: "paye_kart",
                  name: "Paye Kart",
                  color: "#778177",
                },
                {
                  dataKey: "setcard_kartı",
                  name: "Setcard",
                  color: "#A31ACB",
                },
                {
                  dataKey: "smarticket",
                  name: "Smarticket",
                  color: "orange",
                },
                {
                  dataKey: "sodexo_kartı",
                  name: "Sodexo kartı",
                  color: "#49FF00",
                },
                {
                  dataKey: "sodexo_mobil",
                  name: "Sodexo mobil",
                  color: "#FDB827",
                },
                {
                  dataKey: "sodexo_online_ödeme",
                  name: "Sodexo online ödeme",
                  color: "#2192FF",
                },
                {
                  dataKey: "ticket_kartı",
                  name: "Ticket kartı",
                  color: "#FF1E1E",
                },
                {
                  dataKey: "tokenflex",
                  name: "Tokenflex",
                  color: "#a2a5b7",
                },
                {
                  dataKey: "winwin_pos",
                  name: "Winwin pos",
                  color: "#70603b",
                },
                {
                  dataKey: "yemekmatik_kartı",
                  name: "Yemekmatik Kartı",
                  color: "#C70A80",
                },
              ]}
              graph={restaurantServicesGrahps?.odeme_yontemleri}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {restaurantServicesGrahps?.platformlar && (
            <StackedBar
              title={"Platform Sipariş Adetleri ve Yüzdeleri"}
              data={[
                {
                  dataKey: "fuddy",
                  name: "Fuddy",
                  color: "#8884d8",
                },
                {
                  dataKey: "getir",
                  name: "Getir",
                  color: "purple",
                },
                {
                  dataKey: "gofody",
                  name: "Gofody",
                  color: "#609EA2",
                },
                {
                  dataKey: "migros",
                  name: "Migros",
                  color: "red",
                },
                {
                  dataKey: "sepetapp",
                  name: "Sepetapp",
                  color: "blue",
                },
                {
                  dataKey: "trendyol",
                  name: "Trendyol",
                  color: "green",
                },
                {
                  dataKey: "multinet_kartı",
                  name: "Multinet Kartı",
                  color: "#C93D1B",
                },
                {
                  dataKey: "yemeksepeti",
                  name: "Yemeksepeti",
                  color: "#2192FF",
                },
              ]}
              graph={restaurantServicesGrahps?.platformlar}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {restaurantServicesGrahps?.restoran_tabela && (
            <StackedBar
              title={"Toplam Aktif Restoran / Toplam Aktif Tabela"}
              data={[
                {
                  dataKey: "toplam_restoran",
                  name: "Toplam Aktif Restoran",
                  color: "red",
                },
                {
                  dataKey: "toplam_aktif_tabela",
                  name: "Toplam Aktif Tabela",
                  color: "blue",
                },
              ]}
              graph={restaurantServicesGrahps?.restoran_tabela}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {restaurantServicesGrahps?.restoran_tabela && (
            <StackedBar
              title={"Aktif Restoran / Aktif Tabela ve Yüzdeleri"}
              data={[
                {
                  dataKey: "restoran_fuddy",
                  name: "fuddy ",
                  color: "#8884d8",
                },
                {
                  dataKey: "restoran_gofody",
                  name: "gofody ",
                  color: "blue",
                },
                {
                  dataKey: "restoran_migros",
                  name: "migros ",
                  color: "gray",
                },
                {
                  dataKey: "restoran_trendyol",
                  name: "trendyol ",
                  color: "tomato",
                },
                {
                  dataKey: "restoran_yemeksepeti",
                  name: "yemeksepeti ",
                  color: "red",
                },
                {
                  dataKey: "restoran_getir",
                  name: "getir ",
                  color: "purple",
                },
              ]}
              graph={restaurantServicesGrahps?.restoran_tabela}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
