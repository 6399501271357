import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import LockResetTwoToneIcon from "@mui/icons-material/LockResetTwoTone";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DirectionsIcon from "@mui/icons-material/Directions";
import ApplicationActions from "../../../../components/modals/applicationActions";
import {
  KeyboardVoice,
  LocalAtm,
  PhonelinkOff,
  Report,
  Store,
  StoreRounded,
  TouchApp,
} from "@mui/icons-material";
import SendSmsModal from "../../../../components/modals/sendSmsModal";
import ActionModal from "../../../../components/modals/actionsModal";
import PayedModal from "../../../../components/modals/payedModal";
import LimitedUseModal from "../../../../components/modals/limitedUseModal";
import { useToast } from "../../../../components/snackbar";
import ChangePasswordModal from "../../../../components/modals/changePassword";
import VoiceModal from "../../../../components/modals/voiceModal";
import ProductProfitModal from "../../../tasks/modals/productProfit";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import restaurantService from "../../../../services/restaurantService";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

export default function SpeedDialActions({
  resetPassword,
  restOfficialData,
  getTrackerNotes,
  trackerNotes,
  postTrackerNotes,
  handleUserList,
  smsSettingsList,
  testOrderFunc,
  restaurantLimitedUse,
  setNewPasswordFunc,
}) {
  let { alert, success } = useToast();
  const [openDial, setOpenDial] = useState(false);

  const [applicationActionsModal, setApplicationActionsModal] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [pageStatus, setPageStatus] = useState("home");
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [selectedSmsRest, setSelectedSmsRest] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [actionModalResId, setActionModalResId] = useState(null);
  const [payedModal, setPayedModal] = useState(false);
  const [limitedUseModal, setLimitedUseModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [voiceModal, setVoiceModal] = useState(false);
  const [currentRestRow, setCurrentRestRow] = useState(null);
  const [productProfitModal, setProductProfitModal] = useState(false);
  const [currentProfitRestId, setCurrentProfitRestId] = useState(null);

  const handleOpenDial = () => {
    setOpenDial(!openDial);
  };

  const handleCloseDial = (_, reason) => {
    if (
      reason === "toggle" ||
      reason === "blur" ||
      reason === "escapeKeyDown"
    ) {
      setOpenDial(false);
    }
  };

  const handleOpenSmsModal = () => {
    setSelectedSmsRest(restOfficialData);
    setShowSmsModal(true);
  };

  const actions = [
    {
      icon: (
        <LockResetTwoToneIcon
          onClick={() => {
            if (
              JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
              "tech"
            ) {
              setChangePasswordModal(true);
            } else {
              resetPassword();
            }
          }}
        />
      ),
      name: "Reset_password",
      title: "Şifre sıfırla",
    },
    {
      icon: (
        <DescriptionTwoToneIcon
          style={{}}
          onClick={() => {
            setActionModal(true);
            setActionModalResId({
              user_id: restOfficialData?.user?.id,
            });
          }}
        />
      ),
      name: "note",
      title: "Not Ekle",
    },
    {
      icon: <DirectionsIcon onClick={handleUserList} />,
      name: "Aktar",
      title: "Aktar",
    },
    {
      icon: (
        <TouchApp
          onClick={() => {
            testOrderFunc({
              user_id: restOfficialData?.user?.id,
              service_type: "order",
            });
          }}
        />
      ),
      name: "Test Siparişi Gönder",
      title: "Test Siparişi Gönder",
    },
    {
      icon: (
        <DeliveryDiningIcon
          onClick={async () => {
            try {
              await restaurantService.add_sepetfast_demo(
                restOfficialData?.user?.id
              );
              success("Sepetfast Demo Bayi Eklendi");
            } catch (error) {
              alert(error?.response?.data?.message);
            }
          }}
        />
      ),
      name: "Sepetfast Demo Bayi",
      title: "Sepetfast Demo Bayi",
    },
    {
      icon: (
        <TouchApp
          onClick={() => {
            testOrderFunc({
              user_id: restOfficialData?.user?.id,
              service_type: "courier",
            });
          }}
        />
      ),
      name: "Kurye Test Siparişi Gönder",
      title: "Kurye Test Siparişi Gönder",
    },
    {
      icon: (
        <div style={{ fontWeight: 600 }} onClick={() => handleOpenSmsModal()}>
          Sms
        </div>
      ),
      name: "sms",
      title: "Sms",
    },
    {
      icon: (
        <Store
          onClick={() => {
            setPayedModal(true);
          }}
        />
      ),
      name: "company",
      title: "Şirket",
    },
    {
      icon: (
        <PhonelinkOff
          style={{
            color:
              restOfficialData?.version >= "1.1.5"
                ? restOfficialData?.limited_use
                  ? "#f30000"
                  : "green"
                : "gray",
          }}
          onClick={() => {
            if (restOfficialData?.version >= "1.1.5") {
              setLimitedUseModal(true);
            } else {
              alert("Versiyon uyumsuz.", 5000);
            }
          }}
        />
      ),
      name: "limited_use",
      title: "Kısıtla",
    },
    {
      icon: (
        <KeyboardVoice
          onClick={() => {
            setVoiceModal(true);
            setCurrentRestRow(restOfficialData);
          }}
        />
      ),
      name: "cdr_voice",
      title: "CDR Kayıtları",
    },
    {
      icon: (
        <LocalAtm
          onClick={() => {
            setProductProfitModal(true);
            setCurrentProfitRestId(restOfficialData?.user?.id);
          }}
        />
      ),
      name: "product_profit",
      title: "Kazanç Göster",
    },
  ];

  return (
    <Box sx={{ position: "relative", mt: 3, height: 45 }}>
      <StyledSpeedDial
        ariaLabel="Şifre Yenileme ve Bloke"
        icon={<SpeedDialIcon onClick={() => setOpenDial(false)} />}
        direction={"left"}
        open={openDial}
        onClose={handleCloseDial}
        onClick={handleOpenDial}
        FabProps={{
          size: "medium",
          style: {
            backgroundColor: "#293c51",
            width: 45,
            height: 45,
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.title}
          />
        ))}
      </StyledSpeedDial>

      <ApplicationActions
        applicationActionsModal={applicationActionsModal}
        setApplicationActionsModal={setApplicationActionsModal}
        applicationId={applicationId}
        pageStatus={pageStatus}
        setApplicationId={setApplicationId}
        getNotes={getTrackerNotes}
        notes={trackerNotes}
        data_type="restofficial"
        createNewNote={postTrackerNotes}
      />

      <ActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        actionModalResId={actionModalResId}
        setActionModalResId={setActionModalResId}
        resofficial_id={restOfficialData?.user?.id}
        actionVisiblity={true}
      />

      <SendSmsModal
        showSmsModal={showSmsModal}
        setShowSmsModal={setShowSmsModal}
        smsSettingsList={smsSettingsList}
        selectedSmsRest={selectedSmsRest}
        isKunye={true}
      />

      <PayedModal
        showPayment={false}
        payedModal={payedModal}
        setPayedModal={setPayedModal}
        r_id={restOfficialData?.user?.id}
      />

      <LimitedUseModal
        limitedUseModal={limitedUseModal}
        setLimitedUseModal={setLimitedUseModal}
        restaurantLimitedUse={restaurantLimitedUse}
        restOfficialData={restOfficialData}
      />

      <ChangePasswordModal
        changePasswordModal={changePasswordModal}
        setChangePasswordModal={setChangePasswordModal}
        restOfficialData={restOfficialData}
        setNewPasswordFunc={setNewPasswordFunc}
        resetPassword={resetPassword}
      />

      <VoiceModal
        voiceModal={voiceModal}
        setVoiceModal={setVoiceModal}
        currentRestRow={currentRestRow}
        type="kunye"
      />

      <ProductProfitModal
        productProfitModal={productProfitModal}
        setProductProfitModal={setProductProfitModal}
        currentProfitRestId={currentProfitRestId}
        setCurrentProfitRestId={setCurrentProfitRestId}
      />
    </Box>
  );
}
