import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Modal,
  Button,
  Badge,
  Tooltip,
} from "@mui/material";
import {
  CheckCircleOutline,
  Close,
  DescriptionTwoTone,
  PhonelinkOff,
  ReplyAll,
} from "@mui/icons-material";
import ActionModal from "../../../components/modals/actionsModal";
import sepettakipLogo from "../../../utils/assets/sepettakipLogos/sepettakip-logo.webp";
import sepetappLogo from "../../../utils/assets/sepettakipLogos/sepetapp-logo.webp";
import sepetfastLogo from "../../../utils/assets/sepettakipLogos/sepetfast-logo.webp";
import calleridLogo from "../../../utils/assets/sepettakipLogos/callerid-logo.webp";
import courierLogo from "../../../utils/assets/sepettakipLogos/courier-logo.webp";
import stoktakipLogo from "../../../utils/assets/sepettakipLogos/stoktakip-logo.webp";
import masatakipLogo from "../../../utils/assets/sepettakipLogos/masatakip-logo.webp";
import DailyOrderModal from "./dailyOrder";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import moment from "moment";
import { platformIconImageConverter } from "../../../components/converter/platformImage";
import { styled } from "@mui/material/styles";
import LimitedUseModal from "../../../components/modals/limitedUseModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 12,
    height: 12,
    borderRadius: 8,
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const NotPayedModal = ({ notPayedModal, setNotPayedModal }) => {
  const classes = useStyles();
  const [actionModal, setActionModal] = useState(false);
  const [actionModalResId, setActionModalResId] = useState(null);
  const [dailyOrderModal, setDailyOrderModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const {
    appointmentListFunc,
    appointmentList,
    appointmentTaskBackFunc,
    createNewTaskFunc,
    patchLimitedUseTask,
  } = useContext(RestaurantContext);
  const [type, setType] = useState("active");
  const [limitedUseModal, setLimitedUseModal] = useState(false);
  const [currentLimitedUser, setCurrentLimitedUser] = useState(null);

  useEffect(() => {
    if (notPayedModal) {
      appointmentListFunc({ type });
    }
  }, [notPayedModal, type]);

  const calcDate = (date) => {
    const diff = moment(date).diff(moment(), "days");

    if (diff === 0) {
      return moment(date).diff(moment(), "hours") + "saat";
    } else {
      return moment(date).diff(moment(), "days") + "gün";
    }
  };

  const calcDateCreatedAt = (date) => {
    const diff = moment().diff(moment(date), "days");

    if (diff === 0) {
      return moment().diff(moment(date), "hours") + "saat";
    } else {
      return moment().diff(moment(date), "days") + "gün";
    }
  };

  const restaurantLimitedUse = () => {
    patchLimitedUseTask(currentLimitedUser?.restofficial?.id, {
      limited_use: !currentLimitedUser?.restofficial?.limited_use,
    });
    setTimeout(() => {
      appointmentListFunc({ type });
    }, 1000);
  };

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={notPayedModal}
        className={classes.modal}
        onClose={() => {
          setNotPayedModal(false);
        }}
      >
        <Card
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            overflow: "auto",
            minHeight: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 200,
                  display: "flex",
                  justifyContent: "center",
                  height: 30,
                  margin: 2,
                }}
                size="small"
                onClick={() => {
                  setType("active");
                }}
              >
                Aktif
              </Button>
              <Button
                style={{
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 200,
                  display: "flex",
                  justifyContent: "center",
                  height: 30,
                  margin: 2,
                }}
                size="small"
                onClick={() => {
                  setType("passive");
                }}
              >
                Pasif
              </Button>
              <Button
                style={{
                  backgroundColor: "#f53b62",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: 200,
                  display: "flex",
                  justifyContent: "center",
                  height: 30,
                  margin: 2,
                }}
                size="small"
                onClick={() => {
                  setType("one_platform");
                }}
              >
                Tek Platform
              </Button>
            </div>

            <TableContainer>
              <Table
                sx={{
                  ".MuiTableCell-root": {
                    height: 1,
                    lineHeight: 0,
                  },
                  overflow: "auto",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ minWidth: 200, width: "auto" }}
                    >
                      Restoran
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 150, width: "auto" }}
                    >
                      Ürünler
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 150, width: "auto" }}
                    >
                      Platformlar
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 100, width: "auto" }}
                    >
                      Kurulum
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, width: "auto" }}
                    >
                      Siparişler
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, width: "auto" }}
                    >
                      Ort Sipariş
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, width: "auto" }}
                    >
                      Teklif
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, width: "auto" }}
                    >
                      Ön Ödeme
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, width: "auto" }}
                    >
                      Task
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 10, width: "auto" }}
                    >
                      Ys
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 10, width: "auto" }}
                    >
                      Not
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 10, width: "auto" }}
                    >
                      Kısıtla
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{
                    overflow: "auto",
                  }}
                >
                  {appointmentList
                    ?.sort((a, b) => {
                      return moment(a.offer_date).diff(moment(b.offer_date));
                    })
                    .map((row, ind) => {
                      return (
                        <TableRow key={ind}>
                          <TableCell style={{ width: "auto" }}>
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: row?.restofficial?.online
                                    ? "#44b700"
                                    : "red",
                                  color: row?.restofficial?.online
                                    ? "#44b700"
                                    : "red",
                                },
                              }}
                              variant="dot"
                            ></StyledBadge>
                            <div
                              style={{
                                marginLeft: 10,
                                fontSize: 12,
                              }}
                            >
                              {row?.restofficial?.name?.length > 30 ? (
                                <Tooltip title={row?.restofficial?.name}>
                                  <div>
                                    {row?.restofficial?.name?.slice(0, 30)}...
                                  </div>
                                </Tooltip>
                              ) : (
                                row?.restofficial?.name
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="center" style={{ display: "flex" }}>
                            {row?.products?.map((row1) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <img
                                    src={
                                      row1 === "sepettakip"
                                        ? sepettakipLogo
                                        : row1 === "callerid"
                                        ? calleridLogo
                                        : row1 === "sepetfast"
                                        ? sepetfastLogo
                                        : row1 === "stoktakip"
                                        ? stoktakipLogo
                                        : row1 === "masatakip"
                                        ? masatakipLogo
                                        : row1 === "kuryetakip"
                                        ? courierLogo
                                        : sepetappLogo
                                    }
                                    alt={row1?.name}
                                    style={{
                                      width: 21,
                                      height: 21,
                                      marginLeft: 3,
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {row?.platforms?.map((row1, ind) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "#f4f4f4",
                                      padding: 4,
                                      borderRightColor: "red",
                                      borderRightStyle:
                                        row?.platforms?.length !== ind
                                          ? "solid"
                                          : "none",
                                    }}
                                  >
                                    <img
                                      src={platformIconImageConverter(
                                        row1?.name
                                      )}
                                      width={15}
                                      alt="platform"
                                    />
                                    <div style={{ marginLeft: 2 }}>
                                      {row1?.count}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {row?.offer?.product?.map((row1) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <img
                                    src={
                                      row1?.key === "sepettakip"
                                        ? sepettakipLogo
                                        : row1?.key === "callerid"
                                        ? calleridLogo
                                        : row1?.key === "sepetfast"
                                        ? sepetfastLogo
                                        : row1?.key === "stoktakip"
                                        ? stoktakipLogo
                                        : row1?.key === "masatakip"
                                        ? masatakipLogo
                                        : row1?.key === "kuryetakip"
                                        ? courierLogo
                                        : sepetappLogo
                                    }
                                    alt={row1?.key}
                                    style={{
                                      width: 21,
                                      height: 21,
                                      marginLeft: 3,
                                    }}
                                  />
                                </div>
                              );
                            })}
                            <div style={{ marginLeft: 2 }}>
                              {calcDateCreatedAt(row?.offer_date)}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {row?.order_summary?.total_order?.toFixed(0)} /{" "}
                            {row?.order_summary?.total_amount?.toFixed(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setDailyOrderModal(true);
                              setCurrentRow(row);
                            }}
                          >
                            {row?.order_summary?.average_order?.toFixed(0)} /{" "}
                            {row?.order_summary?.average_amount?.toFixed(0)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.total_amount?.toFixed(0)} ₺
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.down_payment?.toFixed(0)} ₺
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {row?.task?.in_calendar ? (
                                <>
                                  {calcDate(row?.task?.start_at)}
                                  <ReplyAll
                                    onClick={() => {
                                      appointmentTaskBackFunc({
                                        id: row?.task?.id,
                                        setNotPayedModal,
                                      });
                                    }}
                                    style={{
                                      marginLeft: 3,
                                      width: 18,
                                      height: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              ) : row?.task?.in_queue ? (
                                <Button
                                  style={{
                                    backgroundColor: "darkslategrey",
                                    color: "#fff",
                                    fontFamily: "segoeuiB",
                                    width: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: 20,
                                  }}
                                  size="small"
                                >
                                  Kuyrukta
                                </Button>
                              ) : (
                                <Button
                                  style={{
                                    backgroundColor: "#f53b62",
                                    color: "#fff",
                                    fontFamily: "segoeuiB",
                                    width: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: 20,
                                  }}
                                  size="small"
                                  onClick={() => {
                                    let data = {
                                      sendingObj: {
                                        task_type: 14,
                                        restofficial: row.restofficial?.id,
                                      },
                                    };
                                    createNewTaskFunc(data);
                                  }}
                                >
                                  Task Oluştur
                                </Button>
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {row?.yt_ticket ? (
                              <CheckCircleOutline style={{ color: "green" }} />
                            ) : (
                              <Close style={{ color: "red" }} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <DescriptionTwoTone
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setActionModal(true);
                                setActionModalResId({
                                  user_id: row?.restofficial?.id,
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <PhonelinkOff
                              disabled
                              style={{
                                cursor:
                                  row?.restofficial?.version >= "1.1.5"
                                    ? "pointer"
                                    : "default",
                                color:
                                  row?.restofficial?.version >= "1.1.5"
                                    ? row?.restofficial?.limited_use
                                      ? "#f30000"
                                      : "green"
                                    : "gray",
                              }}
                              onClick={() => {
                                if (row?.restofficial?.version >= "1.1.5") {
                                  setCurrentLimitedUser(row);
                                  setLimitedUseModal(true);
                                }
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Card>
      </Modal>
      <ActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        actionModalResId={actionModalResId}
        setActionModalResId={setActionModalResId}
        actionVisiblity={false}
      />

      <DailyOrderModal
        dailyOrderModal={dailyOrderModal}
        setDailyOrderModal={setDailyOrderModal}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
      <LimitedUseModal
        limitedUseModal={limitedUseModal}
        setLimitedUseModal={setLimitedUseModal}
        restaurantLimitedUse={restaurantLimitedUse}
        restOfficialData={currentLimitedUser?.restofficial}
      />
    </>
  );
};

export default NotPayedModal;
