import React, { useContext, useState } from "react";
import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  createTheme,
  useMediaQuery,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import InputField from "../../../../components/input";
import { useSnackbar } from "notistack";
import { CallContext, callkey } from "../../../../contexts/callContext";
import makeCall from "../../../../components/call/func/makeCall";
import { Call } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
});
export const RestaurantInfo = ({}) => {
  const classes = useStyles();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    cities,
    town,
    getTown,
    neigh,
    getNeighborhood,
    foodCategory,
    formData,
    setFormData,
  } = useContext(RestaurantContext);

  const { cdrOutboundFunc, cdrAnswerFunc, cdrHangupFunc, cdrMissedCountFunc } =
    useContext(CallContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [disabledCallIcon, setDisabledCallIcon] = useState(false);

  const handleChangeAdress = (name) => (event) => {
    if (name == "city") {
      getTown(event.target.value);
    } else if (name == "town") {
      getNeighborhood(formData.city, event.target.value);
    }

    let valueData = event.target ? event.target.value : event;
    let newData = { ...formData };
    newData = { ...formData, [name]: valueData };
    setFormData(newData);
  };

  if (Object.keys(formData).length > 0) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: matches ? "row" : "column",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          {" "}
          <div
            style={{
              width: matches ? "33%" : "100%",
              borderRadius: 6,
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <FormControl
              variant="outlined"
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: 6,
              }}
            >
              <InputField
                style={{
                  ...styles.officialInput,
                  width: "100%",
                  height: 62,
                }}
                label="Restoran İsmi"
                fieldValue={formData.name || ""}
                fieldName={"name"}
                formData={formData}
                setFormData={setFormData}
              />
            </FormControl>
          </div>
          <div
            style={{
              width: matches ? "33%" : "100%",
              marginLeft: matches ? "14px" : "4px",
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <FormControl
              variant="outlined"
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: 6,
              }}
            >
              <InputField
                style={{
                  ...styles.officialInput,
                  width: "100%",
                  height: 62,
                }}
                label=" Yetkili Kişi Ad"
                fieldValue={formData.first_name || ""}
                fieldName={"first_name"}
                formData={formData}
                setFormData={setFormData}
              />
            </FormControl>
          </div>
          <div
            style={{
              width: matches ? "33%" : "100%",
              marginLeft: matches ? "14px" : "4px",
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <FormControl
              variant="outlined"
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: 6,
              }}
            >
              <InputField
                style={{
                  ...styles.officialInput,
                  width: "100%",
                  height: 62,
                }}
                label=" Yetkili Kişi Soyad"
                fieldValue={formData.last_name || ""}
                fieldName={"last_name"}
                formData={formData}
                setFormData={setFormData}
              />
            </FormControl>
          </div>
        </div>
        <div
          style={{
            flexDirection: matches ? "row" : "column",
            width: "100%",
            display: "flex",
            marginTop: 12,
            justifyContent: "flex-start",
          }}
        >
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginRight: 5,
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <InputField
              style={{
                ...styles.officialInput,
              }}
              label=" E-mail"
              fieldValue={formData.email || ""}
              fieldName={"email"}
              formData={formData}
              setFormData={setFormData}
            />
          </FormControl>
          <div
            style={{
              width: "100%",
              borderRadius: 6,
              marginLeft: matches ? "8px" : "4px",
              marginBottom: matches ? "0px" : "12px",
              display: "flex",
              backgroundColor: "#fff",
            }}
          >
            <FormControl
              variant="outlined"
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: 6,
                marginLeft: matches ? "8px" : "4px",
                marginBottom: matches ? "0px" : "12px",
                display: "flex",
              }}
            >
              <InputField
                style={{
                  ...styles.officialInput,
                }}
                label="Cep"
                fieldValue={formData.phone || ""}
                fieldName={"phone"}
                formData={formData}
                setFormData={setFormData}
              />
            </FormControl>

            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                style={{
                  width: 38,
                  height: 38,
                  backgroundColor: disabledCallIcon ? "gray" : "#293c51",
                  color: "#fff",
                }}
                disabled={disabledCallIcon}
                onClick={() => {
                  setDisabledCallIcon(true);
                  setTimeout(() => {
                    setDisabledCallIcon(false);
                  }, 5000);
                  closeSnackbar(callkey);
                  let phoneNumberv2Obj = formData?.phone;

                  phoneNumberv2Obj = phoneNumberv2Obj?.replace(/\s+/g, "");
                  phoneNumberv2Obj = phoneNumberv2Obj.slice(-10);

                  phoneNumberv2Obj =
                    (JSON.parse(localStorage.getItem("st-officeuser-info"))
                      ?.iqcell_verimor
                      ? "0"
                      : "90") + phoneNumberv2Obj;

                  let sendCallData = {
                    callee: phoneNumberv2Obj,
                    trigger_source: "rest_kunye",
                    restofficial: window.location.pathname.slice(
                      13,
                      window.location.pathname.length
                    ),
                  };

                  makeCall({
                    enqueueSnackbar,
                    closeSnackbar,
                    sendCallData,
                    cdrOutboundFunc,
                    cdrAnswerFunc,
                    cdrHangupFunc,
                    callkey,
                    cdrMissedCountFunc,
                  });
                }}
              >
                <Call />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              borderRadius: 6,
              marginLeft: matches ? "8px" : "4px",
              marginBottom: matches ? "0px" : "12px",
              display: "flex",
              backgroundColor: "#fff",
            }}
          >
            <FormControl
              variant="outlined"
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: 6,
                marginLeft: matches ? "8px" : "4px",
                marginBottom: matches ? "0px" : "12px",
              }}
            >
              <InputField
                style={{
                  ...styles.officialInput,
                }}
                label="Sabit Tel"
                fieldValue={formData.constant_phone || ""}
                fieldName={"constant_phone"}
                formData={formData}
                setFormData={setFormData}
              />
            </FormControl>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                style={{
                  width: 38,
                  height: 38,
                  backgroundColor: disabledCallIcon ? "gray" : "#293c51",
                  color: "#fff",
                }}
                disabled={disabledCallIcon}
                onClick={() => {
                  setDisabledCallIcon(true);
                  setTimeout(() => {
                    setDisabledCallIcon(false);
                  }, 5000);
                  closeSnackbar(callkey);
                  let phoneNumberv2Obj = formData?.constant_phone;

                  phoneNumberv2Obj = phoneNumberv2Obj?.replace(/\s+/g, "");
                  phoneNumberv2Obj = phoneNumberv2Obj.slice(-10);

                  phoneNumberv2Obj =
                    (JSON.parse(localStorage.getItem("st-officeuser-info"))
                      ?.iqcell_verimor
                      ? "0"
                      : "90") + phoneNumberv2Obj;

                  let sendCallData = {
                    callee: phoneNumberv2Obj,
                    trigger_source: "rest_kunye",
                    restofficial: window.location.pathname.slice(
                      13,
                      window.location.pathname.length
                    ),
                  };

                  makeCall({
                    enqueueSnackbar,
                    closeSnackbar,
                    sendCallData,
                    cdrOutboundFunc,
                    cdrAnswerFunc,
                    cdrHangupFunc,
                    callkey,
                    cdrMissedCountFunc,
                  });
                }}
              >
                <Call />
              </IconButton>
            </div>
          </div>
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginLeft: matches ? "8px" : "4px",
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <InputLabel
              style={{
                marginTop: 10,
                fontSize: 16,
                color: "#585858",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 700,
                marginLeft: 8,
              }}
            >
              Mutfak Tipi
            </InputLabel>
            <Select
              className={classes.root}
              value={formData.category || ""}
              onChange={handleChangeAdress("category")}
            >
              {foodCategory?.map((catgry, i) => {
                return (
                  <MenuItem value={catgry?.id} key={i}>
                    {catgry?.category}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: matches ? "row" : "column",
            marginTop: 12,
            justifyContent: "flex-start",
          }}
        >
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <InputLabel
              style={{
                marginTop: 10,
                fontSize: 16,

                color: "#585858",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 700,
                marginLeft: 4,
              }}
            >
              İl Seçimi
            </InputLabel>
            <Select
              className={classes.root}
              value={formData.city || ""}
              onChange={handleChangeAdress("city")}
            >
              {cities?.map((city, i) => {
                return (
                  <MenuItem value={city?.id} key={i}>
                    {city?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginLeft: matches ? "8px" : "4px",
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            {" "}
            <InputLabel
              style={{
                marginTop: 10,
                fontSize: 16,
                color: "#585858",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              İlçe Seçimi
            </InputLabel>
            <Select
              className={classes.root}
              value={formData.town}
              onChange={handleChangeAdress("town")}
            >
              {town?.map((townn, i) => {
                return (
                  <MenuItem value={townn?.id} key={i}>
                    {townn?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginLeft: matches ? "8px" : "4px",
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <InputLabel
              style={{
                marginTop: 10,
                fontSize: 16,
                fontWeight: "400",

                color: "#585858",
              }}
            >
              Mahalle Seçimi
            </InputLabel>
            <Select
              className={classes.root}
              value={formData.neighborhood}
              onChange={handleChangeAdress("neighborhood")}
            >
              {neigh.length > 0 &&
                neigh?.map((item, i) => {
                  return (
                    <MenuItem value={item?.id} key={i}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginLeft: matches ? "8px" : "4px",
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <InputField
              style={{
                ...styles.officialInput,
              }}
              label=" Kapı No"
              fieldValue={formData.door_number}
              fieldName={"door_number"}
              formData={formData}
              setFormData={setFormData}
            />
          </FormControl>
        </div>
        <div
          style={{
            flexDirection: matches ? "row" : "column",
            display: "flex",
            marginTop: 12,
            justifyContent: "flex-start",
          }}
        >
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <InputField
              style={{
                ...styles.officialInput,
              }}
              label="Latitude"
              fieldValue={formData.latitude}
              fieldName={"latitude"}
              formData={formData}
              setFormData={setFormData}
            />
          </FormControl>

          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: 6,
              marginLeft: matches ? "14px" : "4px",
              marginBottom: matches ? "0px" : "12px",
            }}
          >
            <InputField
              style={{
                ...styles.officialInput,
              }}
              label="Longitude"
              fieldValue={formData.longitude}
              fieldName={"longitude"}
              formData={formData}
              setFormData={setFormData}
            />
          </FormControl>
        </div>
      </div>
    );
  }
};

const styles = {
  authorities: {
    width: "65%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 31,
    backgroundColor: "#ffffff",
    marginTop: 16,
  },
  official: {
    display: "flex",
    width: "100%",
    height: 62,
    marginLeft: 16,
    borderRadius: 8,
    backgroundColor: "#ffffff",

    alignItems: "center",
  },
  officialLabel: {
    width: "20%",
    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    paddingLeft: 10,
  },
  officialInput: {
    height: "52px",
    borderStyle: "none",
    borderWidth: 0,
    borderColor: "#f53f62",
    width: "90%",
    paddingLeft: 6,
    height: 45,
    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 400,

    textAlign: "left",
  },
};
