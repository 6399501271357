const orderStatusConverter = (order_status) => {
  if (order_status === "neworder") return "Yeni Sipariş";
  if (order_status === "scheduled") return "İleri Tarihli";
  if (order_status === "acceptedG") return "Ön Onay Alındı";
  if (order_status === "accepted") return "Kabul Edildi";
  if (order_status === "preparing") return "Hazırlanıyor";
  if (order_status === "onroad") return "Yolda";
  if (order_status === "rejected") return "Reddedildi";
  if (order_status === "delivered") return "Teslim";
  if (order_status === "canceled") return "İptal";
  if (order_status === "automaticreject") return "Otomatik İptal";
  if (order_status === "customercanceled") return "Kullanıcı İptali";
  if (order_status === "reached") return "Adreste";
  if (order_status === "undelivered") return "Teslim Edilemedi";
  if (order_status === "prepared") return "Hazırlandı";
  return order_status;
};

export default orderStatusConverter;
