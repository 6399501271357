import { Box, Button, Modal } from "@mui/material";
import { useContext } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";

export default function ProductDeleteModal({
  openProductDeleteModal,
  setOpenProductDeleteModal,
  selectedProduct,
  selectedHeader,
}) {
  const { deleteProductHeader } = useContext(RestaurantContext);

  return (
    <Modal
      open={openProductDeleteModal}
      onClose={() => {
        setOpenProductDeleteModal(false);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
        }}
      >
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {selectedProduct?.name} isimli ürünü silmek istediğinize emin misiniz?
        </h2>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            size="medium"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "#f53b62",
              width: 200,
            }}
            onClick={() => {
              setOpenProductDeleteModal(false);
            }}
          >
            Hayır
          </Button>
          <Button
            variant="contained"
            size="medium"
            style={{
              fontFamily: "segoeuiB",
              width: 200,
              fontWeight: 700,
              backgroundColor: "#0FBD20",
            }}
            onClick={() => {
              deleteProductHeader(selectedProduct.id, selectedHeader.id);
              setOpenProductDeleteModal(false);
            }}
          >
            Evet
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
