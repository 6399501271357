import React, { useContext, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import PrintUserModal from "./PrintUserModal";
import { ManagementContext } from "../../../../../contexts/managementContext";
import InfoIcon from "@mui/icons-material/Info";
import FormatNumber from "../../../../../components/formatData/formatPhone";

export default function Printing() {
  const {
    getPrintingPressList,
    printingPressList,
    openPrintUserModal,
    setOpenPrintUserModal,
  } = useContext(ManagementContext);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    identity_number: "",
    company_title: "",
    tax_no: "",
    tax_authority: "",
    phone_number: "",
    constant_phone: "",
    billing_email: "",
    billing_city: null,
    billing_town: null,
    billing_neighborhood_id: null,
    billing_address: "",
    bank_name: "",
    account_name: "",
    iban: "",
    efatura_mukellef: null,
    username: "",
    password: "",
  });
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);
  const [selectedNeigh, setSelectedNeigh] = useState(null);
  const [isInvoices, setIsInvoices] = useState(null);

  useEffect(() => {
    getPrintingPressList();
  }, []);

  const handleAddNewPrintUser = () => {
    setOpenPrintUserModal(true);
    setFormData({
      first_name: "",
      last_name: "",
      identity_number: "",
      company_title: "",
      tax_no: "",
      tax_authority: "",
      phone_number: "",
      constant_phone: "",
      billing_email: "",
      billing_city: null,
      billing_town: null,
      billing_neighborhood_id: null,
      billing_address: "",
      bank_name: "",
      account_name: "",
      iban: "",
      efatura_mukellef: null,
      username: "",
      password: "",
    });
    setSelectedCity(null);
    setSelectedTown(null);
    setSelectedNeigh(null);
  };

  const handleShowPrintUser = (res, ind) => {
    setOpenPrintUserModal(true);
    setFormData({
      ...res,
      billing_city: res.billing_city.id,
      billing_town: res.billing_town.id,
      billing_neighborhood_id: res.billing_neighborhood.id,
      efatura_mukellef: res.efatura_mukellef,
      first_name: res.user.first_name,
      last_name: res.user.last_name,
    });
    setSelectedCity(res.billing_city);
    setSelectedTown(res.billing_town);
    setSelectedNeigh(res.billing_neighborhood);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <h2 style={{ margin: 0 }}>Matbaalar</h2>
        <Tooltip title="Yeni Matbaa Ekle">
          <AddCircleIcon
            style={{ color: "#f53b62", cursor: "pointer", fontSize: 30 }}
            onClick={handleAddNewPrintUser}
          />
        </Tooltip>
      </div>

      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Ad</TableCell>
                <TableCell align="center">Soyad</TableCell>
                <TableCell align="center">Kullanıcı Adı</TableCell>
                <TableCell align="center">Cep No</TableCell>
                <TableCell align="center">E-Posta</TableCell>
                <TableCell align="center">Ticari Ünvan</TableCell>
                <TableCell align="center">TC No</TableCell>
                <TableCell align="center">Şehir</TableCell>
                <TableCell align="center">İlçe</TableCell>
                <TableCell align="center">Mahalle</TableCell>
                <TableCell align="center">Detay</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {printingPressList?.map((res, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">{res.user.first_name}</TableCell>
                    <TableCell align="center">{res.user.last_name}</TableCell>
                    <TableCell align="center">{res.user.username}</TableCell>
                    <TableCell align="center">
                      {FormatNumber(res?.phone_number)}
                    </TableCell>
                    <TableCell align="center">{res?.billing_email}</TableCell>
                    <TableCell align="center">{res?.company_title}</TableCell>
                    <TableCell align="center">{res?.identity_number}</TableCell>
                    <TableCell align="center">
                      {res?.billing_city.name}
                    </TableCell>
                    <TableCell align="center">
                      {res?.billing_town.name}
                    </TableCell>
                    <TableCell align="center">
                      {res?.billing_neighborhood.name}
                    </TableCell>
                    <TableCell align="center">
                      <InfoIcon
                        style={{ color: "#f53b62", cursor: "pointer" }}
                        onClick={() => handleShowPrintUser(res, ind)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {openPrintUserModal && (
        <PrintUserModal
          openPrintUserModal={openPrintUserModal}
          setOpenPrintUserModal={setOpenPrintUserModal}
          formData={formData}
          setFormData={setFormData}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          selectedTown={selectedTown}
          setSelectedTown={setSelectedTown}
          selectedNeigh={selectedNeigh}
          setSelectedNeigh={setSelectedNeigh}
          isInvoices={isInvoices}
          setIsInvoices={setIsInvoices}
        />
      )}
    </div>
  );
}
