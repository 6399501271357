import React, { useContext, useEffect, useState } from "react";
import RestaurantRatingModal from "../../modals/restaurantRatingModal";
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { makeStyles } from "@mui/styles";
//Components
import { GreenSwitch } from "../../../../../components/switch/greenSwitch";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 14,
      fontFamily: "SgBd",
    },
  },
});

const RestaurantRating = ({ resDetailInfo, id }) => {
  const classes = useStyles();
  const {
    orderRatingListFunc,
    restaurantRatingList,
    orderRatingChangeFunc,
    resChangeDetailFunc,
  } = useContext(RestaurantContext);
  const [restaurantRatingModal, setRestaurantRatingModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [pageType, setPageType] = useState("pending");

  /** This part is for pagination  */
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [page, setPage] = useState(0);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  };

  useEffect(() => {
    orderRatingListFunc({ page: page + 1, pageType, restofficial_id: id });
  }, [page, pageType, id]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        textAlign: "-webkit-center",
        overflow: "auto",
      }}
    >
      <Card
        style={{
          width: "98%",
          padding: 10,
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Paper
          elevation={2}
          style={{
            width: 200,
            height: 60,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontFamily: "SgBd",
              fontSize: 15,
            }}
          >
            Puanlama Sayısı
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontFamily: "SgNr",
              fontSize: 15,
            }}
          >
            {restaurantRatingList?.rating_count}
          </div>
        </Paper>
        <Paper
          elevation={2}
          style={{
            width: 200,
            height: 60,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontFamily: "SgBd",
              fontSize: 15,
            }}
          >
            Yorum Sayısı
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontFamily: "SgNr",
              fontSize: 15,
            }}
          >
            {restaurantRatingList?.comment_count}
          </div>
        </Paper>
        <Paper
          elevation={2}
          style={{
            width: 200,
            height: 60,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontFamily: "SgBd",
              fontSize: 15,
            }}
          >
            Puan Ortalaması
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontFamily: "SgNr",
              fontSize: 15,
            }}
          >
            {(
              (restaurantRatingList?.flavor_rating +
                restaurantRatingList?.serving_rating +
                restaurantRatingList?.speed_rating) /
              3
            ).toFixed(2)}
          </div>
        </Paper>
      </Card>
      <Card
        style={{
          width: "98%",
          height: "auto",
          overflow: "auto",
          marginTop: 15,
          marginBottom: 50,
        }}
      >
        <CardContent style={{ textAlign: "left" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontFamily: "SgBd",
                fontSize: 14,
              }}
            >
              Yorum ve Puanları Diğer Müşteriler Görsün
              <GreenSwitch
                checked={resDetailInfo?.show_rating}
                onClick={() => {
                  resChangeDetailFunc({
                    show_rating: !resDetailInfo?.show_rating,
                    restofficial_id: id,
                  });
                }}
              />
            </div>
            <Button
              style={{
                borderColor: "#f53b62",
                color: "#f53b62",
                textTransform: "capitalize",
                boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                fontSize: 15,
              }}
              onClick={() => {
                if (pageType === "pending") {
                  setPageType("verified,rejected");
                } else {
                  setPageType("pending");
                }
              }}
              variant="outlined"
            >
              {pageType !== "pending" ? "Aktif Yorumlar" : "Geçmiş Yorumlar"}
            </Button>
          </div>
          <span
            style={{
              fontWeight: "bold",
              fontFamily: "SgBd",
              fontSize: 15,
            }}
          >
            Yorum ve Puanlarınız
          </span>
          <TableContainer style={{ marginTop: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      color: "#fff",
                      fontFamily: "SgNr",
                      width: 30,
                      fontSize: 15,
                    }}
                  >
                    Lezzet
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#fff",
                      fontFamily: "SgNr",
                      width: 30,
                      fontSize: 15,
                    }}
                  >
                    Servis
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#fff",
                      fontFamily: "SgNr",
                      width: 30,
                      fontSize: 15,
                    }}
                  >
                    Hız
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#fff",
                      fontFamily: "SgNr",
                      minWidth: 200,
                      fontSize: 15,
                    }}
                  >
                    Yorum
                  </TableCell>
                  {pageType !== "pending" && (
                    <TableCell
                      align="center"
                      style={{
                        color: "#fff",
                        fontFamily: "SgNr",
                        minWidth: 200,
                        fontSize: 15,
                      }}
                    >
                      Cevap
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    style={{
                      color: "#fff",
                      fontFamily: "SgNr",
                      maxWidth: 70,
                      fontSize: 15,
                    }}
                  >
                    Aksiyon
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {restaurantRatingList?.results?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "SgNr",
                          fontSize: 14,
                        }}
                      >
                        {row?.flavor}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "SgNr",
                          fontSize: 14,
                        }}
                      >
                        {row?.serving}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "SgNr",
                          fontSize: 14,
                        }}
                      >
                        {row?.speed}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "SgNr",
                          fontSize: 14,
                        }}
                      >
                        {row?.comment ? row?.comment : "-"}
                      </TableCell>
                      {pageType !== "pending" && (
                        <TableCell
                          align="center"
                          style={{
                            fontFamily: "SgNr",
                            fontSize: 14,
                          }}
                        >
                          {row?.reply ? row?.reply : "-"}
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        style={{ maxWidth: 70, width: 70, minWidth: 70 }}
                      >
                        <Button
                          style={{
                            backgroundColor:
                              pageType !== "pending"
                                ? "transparent"
                                : "#f53b62",

                            textTransform: "capitalize",
                            borderColor:
                              row?.verification === "verified"
                                ? "green"
                                : "red",
                            boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                            color:
                              pageType === "pending"
                                ? "#fff"
                                : row?.verification === "verified"
                                ? "green"
                                : "red",
                            fontSize: 14,
                          }}
                          disabled={pageType !== "pending" ? true : false}
                          onClick={() => {
                            setRestaurantRatingModal(true);
                            setCurrentId(row?.order?.id);
                          }}
                          variant={
                            pageType === "pending" ? "contained" : "outlined"
                          }
                          color="primary"
                        >
                          {pageType !== "pending"
                            ? row?.verification === "verified"
                              ? "Onaylandı"
                              : "Reddedildi"
                            : "İşleme al"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  {restaurantRatingList && (
                    <TablePagination
                      rowsPerPageOptions={[7]}
                      labelRowsPerPage={"Sayfa Başına Kayıt"}
                      count={restaurantRatingList?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      classes={{ root: classes.root }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <RestaurantRatingModal
        restaurantRatingModal={restaurantRatingModal}
        setRestaurantRatingModal={setRestaurantRatingModal}
        currentId={currentId}
        setCurrentId={setCurrentId}
        orderRatingChangeFunc={orderRatingChangeFunc}
        page={page + 1}
        pageType={pageType}
        restofficial_id={id}
      />
    </div>
  );
};

export default RestaurantRating;
