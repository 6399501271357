import React, { useContext, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import FiberNew from "@mui/icons-material/FiberNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { makeStyles } from "@mui/styles";
import { useToast } from "../../../components/snackbar";
import { Button, Card, InputBase, Modal, Tooltip } from "@mui/material";
import { DevelopmentContext } from "../../../contexts/developmentContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function SubTaskInfoModal({
  openSubTaskInfo,
  setOpenSubTaskInfo,
  taskGeneralStatus,
  subTaskDetail,
  taskDetail,
  setOpenSubTask,
}) {
  const { alert } = useToast();
  const classes = useStyles();
  const { changeSubTaskFeature, deleteTask } = useContext(DevelopmentContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [taskHeader, setTaskHeader] = useState(subTaskDetail.title);
  const [taskNote, setTaskNote] = useState(subTaskDetail.note);

  const handleSaveHeader = () => {
    const data = {
      title: taskHeader,
      note: taskNote,
    };
    changeSubTaskFeature(subTaskDetail.id, data, taskDetail.id);

    setIsDisabled(true);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openSubTaskInfo}
      className={classes.modal}
      onClose={() => {
        setOpenSubTaskInfo(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "50%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            padding: 10,
          }}
        >
          <div
            style={{
              padding: 15,
              borderRadius: 8,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              margin: "10px 0px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 16,
                marginBottom: 10,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Task Status
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {taskGeneralStatus?.map((status, i) => {
                if (status.key === "test") return null;
                return (
                  <Tooltip title={status.value} key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const data = {
                          status: status.key,
                        };

                        if (subTaskDetail?.status === status.key)
                          return alert("Bu status zaten seçili");

                        changeSubTaskFeature(
                          subTaskDetail.id,
                          data,
                          taskDetail.id
                        );

                        setOpenSubTaskInfo(false);
                        setOpenSubTask(false);
                      }}
                    >
                      {status.key === "new" ? (
                        <FiberNew
                          style={{
                            color:
                              subTaskDetail?.status === status.key
                                ? subTaskDetail.color
                                : "gray",
                          }}
                        />
                      ) : status.key === "seen" ? (
                        <VisibilityIcon
                          style={{
                            color:
                              subTaskDetail?.status === status.key
                                ? subTaskDetail.color
                                : "gray",
                          }}
                        />
                      ) : status.key === "processing" ? (
                        <TimelapseIcon
                          style={{
                            color:
                              subTaskDetail?.status === status.key
                                ? subTaskDetail.color
                                : "gray",
                          }}
                        />
                      ) : status.key === "completed" ? (
                        <DoneIcon
                          style={{
                            color:
                              subTaskDetail?.status === status.key
                                ? subTaskDetail.color
                                : "gray",
                          }}
                        />
                      ) : status.key === "closed" ? (
                        <DoNotDisturbIcon
                          style={{
                            color:
                              subTaskDetail?.status === status.key
                                ? subTaskDetail.color
                                : "gray",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Tooltip>
                );
              })}

              <Tooltip title="Task Sil">
                <DeleteForeverIcon
                  style={{
                    color: "gray",
                    cursor: "pointer",
                    fontSize: 24,
                    margin: 10,
                  }}
                  onClick={() => {
                    deleteTask(subTaskDetail.id, "subtask", taskDetail.id);
                    setOpenSubTaskInfo(false);
                    setOpenSubTask(false);
                  }}
                />
              </Tooltip>
            </div>
          </div>

          <div
            style={{
              padding: 15,
              borderRadius: 8,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            }}
          >
            <div style={{ marginBottom: 20 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Başlık
              </span>
              <InputBase
                disabled={isDisabled}
                style={{
                  background: "#f4f4f4",
                  padding: 6,
                  fontSize: 16,
                  fontWeight: "400",
                  height: 36,
                  fontFamily: "segoeuiRg",
                  width: "100%",
                }}
                value={taskHeader}
                onChange={(e) => {
                  setTaskHeader(e.target.value);
                }}
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Not
              </span>
              <InputBase
                disabled={isDisabled}
                style={{
                  background: "#f4f4f4",
                  padding: 6,
                  fontSize: 16,
                  fontWeight: "400",
                  height: 36,
                  fontFamily: "segoeuiRg",
                  width: "100%",
                }}
                value={taskNote}
                onChange={(e) => {
                  setTaskNote(e.target.value);
                }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {isDisabled ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: "segoeuiB",
                    width: 160,
                    fontWeight: 700,
                    backgroundColor: "orange",
                  }}
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                >
                  Düzenle
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      fontFamily: "segoeuiB",
                      width: 160,
                      fontWeight: 700,
                      backgroundColor: "red",
                      marginRight: 20,
                    }}
                    onClick={() => {
                      setIsDisabled(true);
                      setTaskHeader(taskDetail.title);
                    }}
                  >
                    Vazgeç
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      fontFamily: "segoeuiB",
                      width: 160,
                      fontWeight: 700,
                      backgroundColor: "#0FBD20",
                    }}
                    onClick={handleSaveHeader}
                  >
                    Kaydet
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
