import { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import InputField from "../../../../../../components/input";
import ModuleDetail from "./ModuleDetail";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import managementService from "../../../../../../services/managementService";
import { ManagementContext } from "../../../../../../contexts/managementContext";

export default function Module({
  productData,
  setProductData,
  selectedPackageProducts,
  module,
  index,
  updatedModules,
  setUpdatedModules,
  productTypes,
}) {
  const [moduleHeaderData, setModuleHeaderData] = useState({
    name: "",
  });

  const { getOptionalProducts, optionalProducts } =
    useContext(ManagementContext);

  const handleAddModuleDetail = () => {
    let updatedModulesCopy = [...updatedModules];
    updatedModulesCopy[index].modules.push({
      name: "",
      description: "",
      option: false,
      optional_product: null,
      text: "",
    });
    setProductData({ ...productData, modules: updatedModulesCopy });
  };

  const handleDeleteModule = async () => {
    updatedModules.splice(index, 1);
    setProductData({ ...productData });
    setUpdatedModules(updatedModules);
    await managementService.delete_module_header(productData.id, module.id);
  };

  const handleDeleteModuleDetail = async (modules, i) => {
    if (modules[i].id) {
      await managementService.delete_module(productData.id, modules[i].id);
    }
    modules.splice(i, 1);
    setProductData({ ...productData });
    setUpdatedModules(updatedModules);
  };

  useEffect(() => {
    if (moduleHeaderData.name) {
      updatedModules[index].name = moduleHeaderData.name;
    }
  }, [moduleHeaderData, index, updatedModules]);

  useEffect(() => {
    setModuleHeaderData(module);
  }, [module]);

  useEffect(() => {
    getOptionalProducts();
  }, []);

  return (
    <Box>
      <Box>
        <Box
          sx={{
            background: "#fff",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: 1,
            width: "fit-content",
            marginLeft: "auto",
          }}
          onClick={handleDeleteModule}
        >
          <span
            style={{
              marginRight: 10,
              display: "block",
              color: "#585858",
              fontFamily: "segoeuiRg",
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            Sil
          </span>
          <DeleteForeverIcon style={{ color: "red", cursor: "pointer" }} />
        </Box>
        <Box sx={{ marginBottom: 2, padding: 1, background: "#efefef" }}>
          <Box sx={{ marginBottom: 2 }}>
            <InputField
              label="Modül Başlık"
              fieldName="name"
              formData={moduleHeaderData}
              setFormData={setModuleHeaderData}
              fieldValue={moduleHeaderData.name}
            />
          </Box>
          {module?.modules?.map((option, i) => {
            return (
              <ModuleDetail
                module={option}
                modules={module?.modules}
                index={i}
                key={i}
                handleDeleteModuleDetail={handleDeleteModuleDetail}
                productTypes={productTypes}
                optionalProducts={optionalProducts}
              />
            );
          })}
          <Box
            sx={{
              background: "#fff",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: 1,
              width: "fit-content",
            }}
            onClick={handleAddModuleDetail}
          >
            <span
              style={{
                marginRight: 10,
                display: "block",
                color: "#585858",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              Ekle
            </span>
            <AddBoxIcon style={{ color: "red" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
