import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function KpiTickets({ kpiTickets }) {
  return (
    <Box>
      <h3 style={{ textAlign: "center" }}>KPI TİCKET RAPORLARI</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Personel
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Gelen Ticket
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                İşleme Alınan Ticket
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Sayaçta
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Başarılı
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Başarısız
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ort. Müdahale Zamanı
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ort. Sonuçlandırma Zamanı
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kpiTickets?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    {row?.processed_by__full_name}
                  </TableCell>
                  <TableCell align="center">{row?.total_count}</TableCell>
                  <TableCell align="center">{row?.processing_count}</TableCell>
                  <TableCell align="center">{row?.stayed_count}</TableCell>
                  <TableCell align="center">{row?.completed_count}</TableCell>
                  <TableCell align="center">
                    {row?.completed_negative_count}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {row?.avg_processing_time
                        ? row?.avg_processing_time?.toFixed(2)
                        : "0"}{" "}
                      dk
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {row?.avg_completion_time
                        ? row?.avg_completion_time?.toFixed(2)
                        : "0"}{" "}
                      dk
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
