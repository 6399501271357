import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../../contexts/managementContext";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import restaurantService from "../../../../services/restaurantService";
import { useToast } from "../../../../components/snackbar";
import { Close } from "@mui/icons-material";

const animatedComponents = makeAnimated();

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const SendSettingsModal = ({
  sendSettingsModal,
  setSendSettingsModal,
  platformAndProducts,
  currentRow,
  type,
}) => {
  const classes = useStyles();
  const { alert } = useToast();
  const { publishSmsFunc, publishPopupFunc, publishBannerFunc } =
    useContext(ManagementContext);
  const [selectedAppProduct, setSelectedAppProduct] = useState([]);
  const [selectedAppPlatform, setSelectedAppPlatform] = useState([]);
  const [blockStatus, setBlockStatus] = useState(null);
  const [onlyOnline, setOnlyOnline] = useState(false);
  const [addRestList, setAddRestList] = useState([]);
  const [removeRestList, setRemoveRestList] = useState([]);

  // useEffect(() => {
  //   if (platformAndProducts !== null) {
  //     platformAndProducts?.package_products?.map((row) => {
  //       if (!selectedAppProduct?.includes(row?.id)) {
  //         selectedAppProduct.push(row?.id);
  //       }
  //     });
  //     platformAndProducts?.platforms?.map((row) => {
  //       if (!selectedAppPlatform?.includes(row?.id)) {
  //         selectedAppPlatform.push(row?.id);
  //       }
  //     });
  //   }
  // }, [platformAndProducts]);

  const handleRestaurantChange = (searchQuery) => {
    return new Promise((resolve) => {
      restaurantService
        .restofficial_search({ search: searchQuery })
        .then((response) => {
          const value = response?.map((item) => ({
            value: item.user_id,
            label: `${item?.name || ""}`,
          }));
          resolve(value);
        });
    });
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={sendSettingsModal}
      className={classes.modal}
      onClose={() => {
        setSendSettingsModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "70%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#fff",
          padding: 20,
          maxHeight: 700,
          overflow: "auto",
          height: 700,
          alignItems: "center",
        }}
      >
        {type !== "banner" && (
          <div
            style={{ height: "15%", backgroundColor: "#f4f4f4", padding: 20 }}
          >
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  checked={onlyOnline}
                  onChange={(e) => {
                    setOnlyOnline(!onlyOnline);
                  }}
                  sx={{
                    marginTop: -1,
                    color: "#f53b62",
                    "&.Mui-checked": {
                      color: "#f53b62",
                    },
                  }}
                />
              }
              style={{ textAlign: "center" }}
              label={"Sadece Online"}
            />

            {type === "sms" ? (
              <>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      checked={blockStatus === true ? true : false}
                      onChange={(e) => {
                        if (blockStatus !== true) {
                          setBlockStatus(true);
                        } else {
                          setBlockStatus(null);
                        }
                      }}
                      sx={{
                        marginTop: -1,
                        color: "#f53b62",
                        "&.Mui-checked": {
                          color: "#f53b62",
                        },
                      }}
                    />
                  }
                  style={{ textAlign: "center" }}
                  label={"Sadece Bloke"}
                />
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      checked={blockStatus === false ? true : false}
                      onChange={(e) => {
                        if (blockStatus !== false) {
                          setBlockStatus(false);
                        } else {
                          setBlockStatus(null);
                        }
                      }}
                      sx={{
                        marginTop: -1,
                        color: "#f53b62",
                        "&.Mui-checked": {
                          color: "#f53b62",
                        },
                      }}
                    />
                  }
                  style={{ textAlign: "center" }}
                  label={"Bloke Hariç"}
                />
              </>
            ) : (
              ""
            )}
          </div>
        )}
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div
            style={{
              width: "45%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 20,
            }}
          >
            <AsyncSelect
              responsiveHeight={35}
              selectFontSize={16}
              zIndex={999}
              hoverColor={"#f53b62"}
              menuColor={"#f53b62"}
              selectWidth={300}
              placeholder={"Restoran Ekle"}
              styles={selectDefaultStyles}
              value={{
                label: (
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    {"Restoran Ekle"}
                  </span>
                ),
                value: -1,
              }}
              components={animatedComponents}
              onChange={(e) => {
                const addRestListObj = Object.assign(
                  [],
                  JSON.parse(
                    JSON.stringify({
                      ...addRestList,
                    })
                  )
                );
                let selectedAppId = addRestListObj.findIndex(
                  (row) => row.id === e?.value
                );
                if (selectedAppId == -1) {
                  addRestListObj?.push({
                    name: e?.label,
                    id: e?.value,
                  });
                  setAddRestList(addRestListObj);
                } else {
                  alert("Listede bulunuyor");
                }
              }}
              loadOptions={handleRestaurantChange}
            />

            <div
              style={{
                height: 250,
                overflow: "auto",
                display: "flex",
                width: "100%",
                backgroundColor: "#cdfbcd",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {addRestList?.map((row, ind) => {
                  return (
                    <div
                      style={{
                        marginLeft: 20,
                        fontFamily: "segoeuiB",
                        color: "gray",
                        fontSize: 12,
                      }}
                    >
                      {ind + 1} - {row?.name}
                      <IconButton
                        onClick={() => {
                          const addRestListObj = Object.assign(
                            [],
                            JSON.parse(
                              JSON.stringify({
                                ...addRestList,
                              })
                            )
                          );
                          let selectedAppId = addRestListObj.findIndex(
                            (list) => list.id === row?.id
                          );
                          addRestListObj.splice(selectedAppId, 1);
                          setAddRestList(addRestListObj);
                        }}
                        style={{ marginLeft: 5, width: 14, height: 14 }}
                      >
                        <Close
                          style={{ width: 12, height: 12, color: "red" }}
                        />
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "45%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 20,
            }}
          >
            <AsyncSelect
              responsiveHeight={35}
              selectFontSize={16}
              zIndex={999}
              hoverColor={"#f53b62"}
              menuColor={"#f53b62"}
              selectWidth={300}
              placeholder={"Restoran Çıkar"}
              styles={selectDefaultStyles}
              value={{
                label: (
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    {"Restoran Çıkar"}
                  </span>
                ),
                value: -1,
              }}
              components={animatedComponents}
              onChange={(e) => {
                const removeRestListObj = Object.assign(
                  [],
                  JSON.parse(
                    JSON.stringify({
                      ...removeRestList,
                    })
                  )
                );
                let selectedAppId = removeRestListObj.findIndex(
                  (row) => row.id === e?.value
                );
                if (selectedAppId == -1) {
                  removeRestListObj?.push({
                    name: e?.label,
                    id: e?.value,
                  });
                  setRemoveRestList(removeRestListObj);
                } else {
                  alert("Listede bulunuyor");
                }
              }}
              loadOptions={handleRestaurantChange}
            />

            <div
              style={{
                height: 250,
                overflow: "auto",
                display: "flex",
                width: "100%",
                backgroundColor: "#ffcebf",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {removeRestList?.map((row, ind) => {
                  return (
                    <div
                      style={{
                        marginLeft: 20,
                        fontFamily: "segoeuiB",
                        color: "gray",
                        fontSize: 12,
                      }}
                    >
                      {ind + 1} - {row?.name}
                      <IconButton
                        onClick={() => {
                          const removeRestListObj = Object.assign(
                            [],
                            JSON.parse(
                              JSON.stringify({
                                ...removeRestList,
                              })
                            )
                          );
                          let selectedAppId = removeRestListObj.findIndex(
                            (list) => list.id === row?.id
                          );
                          removeRestListObj.splice(selectedAppId, 1);
                          setRemoveRestList(removeRestListObj);
                        }}
                        style={{ marginLeft: 5, width: 14, height: 14 }}
                      >
                        <Close
                          style={{ width: 12, height: 12, color: "red" }}
                        />
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f4f4f4",
            display: "flex",
            flexDirection: "column",
            width: "80%",
            padding: 20,
            marginTop: 30,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {platformAndProducts?.package_products?.map((row) => {
              return (
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      checked={selectedAppProduct?.includes(row?.id)}
                      onChange={(e) => {
                        const selectedAppProductObj = Object.assign(
                          [],
                          JSON.parse(
                            JSON.stringify({
                              ...selectedAppProduct,
                            })
                          )
                        );
                        let selectedAppId = selectedAppProductObj.findIndex(
                          (id) => id === row?.id
                        );
                        if (selectedAppId == -1) {
                          selectedAppProductObj?.push(row?.id);
                          setSelectedAppProduct(selectedAppProductObj);
                        } else {
                          selectedAppProductObj.splice(selectedAppId, 1);
                          setSelectedAppProduct(selectedAppProductObj);
                        }
                      }}
                      sx={{
                        marginTop: -1,
                        color: "#f53b62",
                        "&.Mui-checked": {
                          color: "#f53b62",
                        },
                      }}
                    />
                  }
                  style={{ textAlign: "center" }}
                  label={row?.name}
                />
              );
            })}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              marginTop: 20,
            }}
          >
            {platformAndProducts?.platforms?.map((row) => {
              return (
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      checked={selectedAppPlatform?.includes(row?.id)}
                      onChange={(e) => {
                        const selectedAppPlatformObj = Object.assign(
                          [],
                          JSON.parse(
                            JSON.stringify({
                              ...selectedAppPlatform,
                            })
                          )
                        );
                        let selectedAppId = selectedAppPlatformObj.findIndex(
                          (id) => id === row?.id
                        );
                        if (selectedAppId == -1) {
                          selectedAppPlatformObj?.push(row?.id);
                          setSelectedAppPlatform(selectedAppPlatformObj);
                        } else {
                          selectedAppPlatformObj.splice(selectedAppId, 1);
                          setSelectedAppPlatform(selectedAppPlatformObj);
                        }
                      }}
                      sx={{
                        marginTop: -1,
                        color: "#f53b62",
                        "&.Mui-checked": {
                          color: "#f53b62",
                        },
                      }}
                    />
                  }
                  style={{ textAlign: "center" }}
                  label={row?.name}
                />
              );
            })}
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: "20%",
              display: "flex",
              justifyContent: "center",
              height: 40,
              marginTop: 20,
            }}
            size="small"
            onClick={() => {
              if (type === "sms") {
                let data = {
                  sendObj: {
                    sms_template: currentRow?.id,
                    only_online: onlyOnline,
                    all_restofficials: false,
                    restofficials: addRestList.map((item) => item.id),
                    restofficials_exclude: removeRestList.map(
                      (item) => item.id
                    ),
                    package_products: selectedAppProduct,
                    platforms: selectedAppPlatform,
                    blocked: blockStatus,
                  },
                };
                publishSmsFunc(data);
              } else if (type === "popup") {
                let data = {
                  sendObj: {
                    popup_template: currentRow?.id,
                    only_online: onlyOnline,
                    all_restofficials: false,
                    restofficials: addRestList.map((item) => item.id),
                    restofficials_exclude: removeRestList.map(
                      (item) => item.id
                    ),
                    package_products: selectedAppProduct,
                    platforms: selectedAppPlatform,
                  },
                  setSendSettingsModal,
                };
                publishPopupFunc(data);
              } else if (type === "banner") {
                let data = {
                  sendObj: {
                    banner_template: currentRow?.id,
                    all_restofficials: false,
                    restofficials: addRestList.map((item) => item.id),
                    blacklist_restofficials: removeRestList.map(
                      (item) => item.id
                    ),
                    package_products: selectedAppProduct,
                    platforms: selectedAppPlatform,
                    end_at: "2024-12-27T18:00:00",
                  },
                  setSendSettingsModal,
                };
                publishBannerFunc(data);
              }
            }}
          >
            Oluştur
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default SendSettingsModal;
