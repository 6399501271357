import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";
import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../../../../components/datePickerInput";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import SearchV2 from "../../../../components/search/searchv2";
import useDebounce from "../../../../components/debounce";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

let directionObj = [
  { id: "", name: "Tümü" },
  { id: "inbound", name: "Gelen Arama" },
  { id: "outbound", name: "Giden Arama" },
];

let whohangedupObj = [
  { id: "", name: "Tümü" },
  { id: "agent", name: "Agent" },
  { id: "member", name: "Müşteri" },
];

let missedObj = [
  { id: "", name: "Tümü" },
  { id: "0", name: "Cevaplanan" },
  { id: "1", name: "Cevapsız" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

const SantralScreen = () => {
  const classes = useStyles();

  const {
    dashboardCdrListFunc,
    dashboardCdrList,
    dashboardCdrFiltersFunc,
    dashboardCdrFilterList,
    dashboardCdrStatsFunc,
    dashboardCdrStatsList,
    santralRecordFunc,
  } = useContext(ManagementContext);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const [selectedOfficeUsers, setSelectedOfficeUsers] = useState([]);

  const [selectedTask, setSelectedTask] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const [selectedDirection, setSelectedDirection] = useState(null);
  const [selectedWhohangedup, setSelectedWhohangedup] = useState("");
  const [selectedMissed, setSelectedMissed] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1500);

  const [value, setValue] = useState(0);

  const [playbackRate, setPlaybackRate] = useState(1.0);
  const audioRef = useRef(null);

  const changePlaybackRate = (rate) => {
    if (audioRef.current) {
      audioRef.current.audio.current.playbackRate = rate;
      setPlaybackRate(rate);
    }
  };

  useEffect(() => {
    dashboardCdrFiltersFunc();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (endDate) {
      let officeuserIds = [];
      let taskList = [];
      selectedOfficeUsers?.map((row) => {
        officeuserIds.push(row.user_id);
      });
      selectedTask?.map((row) => {
        taskList.push(row.id);
      });
      if (value === 0) {
        let data = {
          sendObj: {
            page: page + 1,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            officeuser:
              selectedOfficeUsers?.length !== 0 ? officeuserIds.join(",") : "",
            task_type: selectedTask?.length !== 0 ? taskList.join(",") : "",
            direction: selectedDirection !== null ? selectedDirection?.id : "",
            who_hanged_up:
              selectedWhohangedup !== null ? selectedWhohangedup?.id : "",
            missed: selectedMissed !== null ? selectedMissed?.id : "",
            result: "",
            phone: debouncedSearchTerm?.length !== 0 ? debouncedSearchTerm : "",
            page_size: 100,
          },
        };
        dashboardCdrListFunc(data);
      } else {
        let data = {
          sendObj: {
            page: page + 1,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            officeuser:
              selectedOfficeUsers?.length !== 0 ? officeuserIds.join(",") : "",
            task_type: selectedTask?.length !== 0 ? taskList.join(",") : "",

            page_size: 100,
          },
        };
        dashboardCdrStatsFunc(data);
      }
    }
  }, [
    selectedOfficeUsers,
    selectedTask,
    endDate,
    page,
    selectedDirection,
    selectedWhohangedup,
    selectedMissed,
    debouncedSearchTerm,
    value,
  ]);

  // useEffect(() => {
  //   if (dashboardCdrFilterList !== null) {
  //     dashboardCdrFilterList?.officeusers?.map((row) => {
  //       setSelectedOfficeUsers((resp) => [
  //         ...resp,
  //         { user_id: row?.user_id, full_name: row?.full_name },
  //       ]);
  //       setSelectedOfficeUsersId((resp) => [...resp, row?.user_id]);
  //     });
  //     dashboardCdrFilterList?.task_types?.map((row) => {
  //       setSelectedTask((resp) => [...resp, { id: row?.id, name: row?.name }]);
  //       setSelectedTaskId((resp) => [...resp, row?.id]);
  //     });
  //   }
  // }, [dashboardCdrFilterList]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const durationToLocalize = (totalDuration) => {
    const duration = moment.duration(totalDuration, "seconds");

    // Gün, saat ve dakikayı al
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${days}g ${hours}s ${minutes}dk`;
  };

  const durationToLocalizeSec = (totalDuration) => {
    const duration = moment.duration(totalDuration, "seconds");

    // Gün, saat ve dakikayı al
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}s ${minutes}dk ${seconds}sn`;
  };

  const daysToLocalize = (start_date, end_date) => {
    const startDate = moment(start_date);
    const endDate = moment(end_date);

    // İki tarih arasındaki farkı hesapla (büyükten küçüğe)
    const duration = moment.duration(endDate.diff(startDate));

    // Gün, saat ve dakikayı hesapla
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${days}g ${hours}s ${minutes}dk`;
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          margin: 20,
          display: "flex",
          width: "95%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            fixedHeight="35px"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            onChange={(update) => {
              setDateRange(update);
            }}
            customInput={
              <CustomDatePickerInput selectWidth={230} color={"#393737"} />
            }
            locale="tr"
          />
        </div>
        <div>
          {dashboardCdrFilterList && (
            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={dashboardCdrFilterList?.officeusers}
              isMulti
              isSearchable={false}
              value={selectedOfficeUsers}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={400}
              menuColor={"#fff"}
              color={"#000"}
              placeholder={"Tümü"}
              onChange={(e) => {
                setSelectedOfficeUsers(e);
              }}
              getOptionValue={(option) => option.user_id}
              getOptionLabel={(option) => option.full_name}
            />
          )}
        </div>
        <div>
          {dashboardCdrFilterList && (
            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={dashboardCdrFilterList?.task_types}
              isMulti
              isSearchable={false}
              value={selectedTask}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={400}
              menuColor={"#fff"}
              color={"#000"}
              placeholder={"Tümü"}
              onChange={(e) => {
                setSelectedTask(e);
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
            />
          )}
        </div>
        <div>
          {dashboardCdrFilterList && (
            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={directionObj}
              isSearchable={false}
              value={selectedDirection}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={180}
              isDisabled={value === 0 ? false : true}
              menuColor={value === 0 ? "#fff" : "gray"}
              color={"#000"}
              placeholder={"Tümü"}
              onChange={(e) => {
                setSelectedDirection(e);
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
            />
          )}
        </div>
        <div>
          {dashboardCdrFilterList && (
            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={whohangedupObj}
              isSearchable={false}
              value={selectedWhohangedup}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={180}
              isDisabled={value === 0 ? false : true}
              menuColor={value === 0 ? "#fff" : "gray"}
              color={"#000"}
              placeholder={"Tümü"}
              onChange={(e) => {
                setSelectedWhohangedup(e);
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
            />
          )}
        </div>
        <div>
          {dashboardCdrFilterList && (
            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={missedObj}
              isSearchable={false}
              value={selectedMissed}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={150}
              isDisabled={value === 0 ? false : true}
              menuColor={value === 0 ? "#fff" : "gray"}
              color={"#000"}
              placeholder={"Tümü"}
              onChange={(e) => {
                setSelectedMissed(e);
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
            />
          )}
        </div>
        <div>
          {dashboardCdrFilterList && (
            <Select
              styles={{
                ...selectDefaultStyles,
              }}
              options={missedObj}
              isSearchable={false}
              value={"-"}
              zIndex={200}
              hoverColor={"black"}
              selectWidth={150}
              isDisabled={value === 0 ? true : true}
              menuColor={value === 0 ? "#fff" : "gray"}
              color={"gray"}
              placeholder={"Tümü"}
              onChange={(e) => {
                setSelectedMissed(e);
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
            />
          )}
        </div>
      </div>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "10%" }}>
          {value === 0 && (
            <SearchV2
              searchTerm={searchTerm}
              placeTitle={"Kullanıcı / Numara Ara"}
              setSearchTerm={setSearchTerm}
              sendSearchTermFunc={[]}
            />
          )}
        </div>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          className={classes.tabs}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ backgroundColor: "#fff", borderRadius: 1, minWidth: 500 }}
        >
          <Tab
            label="CDR Tekli"
            {...a11yProps(0)}
            sx={{
              backgroundColor: "#f4f4f4",
              width: "50%",
              borderRightStyle: "solid",
              borderRightColor: "#f53b62",
              borderRightWidth: 1,
            }}
          />

          <Tab
            label="CDR Toplu"
            {...a11yProps(1)}
            sx={{
              backgroundColor: "#f4f4f4",
              width: "50%",
              borderLeftStyle: "solid",
              borderLeftColor: "#f53b62",
              borderLeftWidth: 1,
            }}
          />
        </Tabs>
        <div style={{ width: "10%" }}></div>
      </Box>
      <div>
        <TabPanel value={value} index={0}>
          <TableContainer>
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 20,
                  lineHeight: 1.2,
                },
                ".MuiTableCell-root td": {
                  color: "#fff",
                },
                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Kullanıcı
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Task
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 13,
                      fontFamily: "segoeuiRg",
                      width: 120,
                    }}
                  >
                    Arama Tarihi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Yön
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 13,
                      fontFamily: "segoeuiRg",
                      width: 120,
                    }}
                  >
                    Task tarihi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Task süresi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Konuşma süresi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Dokunuş süresi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Task - Konuşma
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Numara
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Sonuç
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Cevapsız Takip
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Çalma süresi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Kapatan
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 13,
                      fontFamily: "segoeuiRg",
                      width: 250,
                    }}
                  >
                    Ses Kaydı
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardCdrList?.results?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.officeuser?.full_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.trigger_source === "hand"
                          ? "Manuel"
                          : row?.trigger_source === "missed"
                          ? "Cevapsız"
                          : row?.trigger_source === "task"
                          ? "Task"
                          : row?.trigger_source === "rest_kunye"
                          ? "Künye"
                          : row?.trigger_source}
                        <div style={{ fontSize: 12, fontFamily: "segoeuiB" }}>
                          {row?.task ? row?.task?.task_type?.name : ""}
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {moment(row?.start_at).format("DD MMMM HH:mm:ss")}
                        <br />
                        {moment(row?.end_at).format("DD MMMM HH:mm:ss")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.direction === "outbound"
                          ? "Giden Arama"
                          : "Gelen Arama"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.task ? (
                          <div>
                            {moment(row?.task?.created_at).format(
                              "DD MMMM HH:mm:ss"
                            )}
                            <br />
                            {moment(row?.task?.completed_at).format(
                              "DD MMMM HH:mm:ss"
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.task && row?.task?.duration !== null
                          ? durationToLocalize(row?.task?.duration)
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.talk_duration !== null
                          ? durationToLocalize(row?.talk_duration)
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.task
                          ? daysToLocalize(row?.task?.created_at, row?.start_at)
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.task && row?.task?.duration !== null
                          ? durationToLocalize(
                              Math.abs(
                                parseFloat(row?.task?.duration) -
                                  parseFloat(row?.talk_duration)
                              )
                            )
                          : "-"}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.direction === "outbound"
                          ? row?.callee
                          : row?.caller}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        x
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.missed ? (
                          row?.miss_returned &&
                          row?.miss_returned_at !== null ? (
                            <>
                              <div>Dönüldü</div>
                              <div>
                                {daysToLocalize(
                                  row?.start_at,
                                  row?.miss_returned_at
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>Dönülmedi</div>
                              <div>
                                {daysToLocalize(row?.start_at, moment())}
                              </div>
                            </>
                          )
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.wait_duration !== null
                          ? durationToLocalizeSec(row?.wait_duration)
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.who_hanged_up === "agent" ? "Agent" : "Müşteri"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                      >
                        {row?.voice ? (
                          <div>
                            <AudioPlayer
                              ref={audioRef}
                              src={row?.voice}
                              showJumpControls={false}
                              customAdditionalControls={[]}
                              style={{
                                padding: "2px 6px",
                                width: 240,
                                backgroundColor: "#474545",
                                boxShadow: "none",
                                color: "#fff",
                                marginTop: 2,
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.0 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.0)}
                              >
                                1x
                              </div>
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.25 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.25)}
                              >
                                1.25x
                              </div>
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.5 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.5)}
                              >
                                1.5x
                              </div>
                              <div
                                style={{
                                  backgroundColor:
                                    playbackRate === 1.75 ? "green" : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: 35,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                  marginTop: 1,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => changePlaybackRate(1.75)}
                              >
                                1.75x
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {row?.voice === null ? (
                              "Ses kaydı yok"
                            ) : (
                              <Button
                                style={{
                                  backgroundColor: "#f53b62",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                }}
                                size="small"
                                onClick={() => {
                                  santralRecordFunc({
                                    record_id: row?.id,
                                  });
                                }}
                              >
                                Ses Dosyası
                              </Button>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  {dashboardCdrList?.results && (
                    <TablePagination
                      rowsPerPageOptions={[5]}
                      labelRowsPerPage={"Sayfa Başına Kayıt"}
                      count={dashboardCdrList?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer>
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 20,
                  lineHeight: 1.2,
                },
                ".MuiTableCell-root td": {
                  color: "#fff",
                },
                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Gelen Çağrı Adet
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Başarılı %
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Başarısız %
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Toplam Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Ort. Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Task Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Ort Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Kapatan Biz
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Kapatan Karşı
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Kaçan Adet
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Dönülen Adet
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Dönülmeyen Adet
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.inbound?.total_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.inbound?.successful_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.inbound?.total_count -
                      dashboardCdrStatsList?.inbound?.successful_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.inbound?.talk_duration_sum !== null
                      ? durationToLocalize(
                          dashboardCdrStatsList?.inbound?.talk_duration_sum
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {dashboardCdrStatsList?.inbound?.talk_duration_avg !== null
                      ? durationToLocalize(
                          dashboardCdrStatsList?.inbound?.talk_duration_avg
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.inbound?.agent_hanged_up_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.inbound?.total_count -
                      dashboardCdrStatsList?.inbound?.agent_hanged_up_count}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {dashboardCdrStatsList?.inbound?.missed_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.inbound?.miss_returned_count}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {dashboardCdrStatsList?.inbound?.missed_count -
                      dashboardCdrStatsList?.inbound?.miss_returned_count}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 20,
                  lineHeight: 1.2,
                },
                ".MuiTableCell-root td": {
                  color: "#fff",
                },
                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Giden Çağrı Adet
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Başarılı %
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Başarısız %
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Toplam Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Ort. Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Task Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Ort Dk
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Kapatan Biz
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    Kapatan Karşı
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    -
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    -
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: 13, fontFamily: "segoeuiRg" }}
                  >
                    -
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.outbound?.total_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.outbound?.successful_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.outbound?.total_count -
                      dashboardCdrStatsList?.outbound?.successful_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.outbound?.talk_duration_sum !== null
                      ? durationToLocalize(
                          dashboardCdrStatsList?.outbound?.talk_duration_sum
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {dashboardCdrStatsList?.outbound?.talk_duration_avg !== null
                      ? durationToLocalize(
                          dashboardCdrStatsList?.outbound?.talk_duration_avg
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.outbound?.agent_hanged_up_count}
                  </TableCell>
                  <TableCell align="center">
                    {dashboardCdrStatsList?.outbound?.total_count -
                      dashboardCdrStatsList?.outbound?.agent_hanged_up_count}
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </div>
    </div>
  );
};

export default SantralScreen;
