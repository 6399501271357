import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import LoginProvider from "./contexts/loginContext";
import RestaurantProvider from "./contexts/restaurantContext";
import ManagementProvider from "./contexts/managementContext";
import WsProvider from "./contexts/wsContext";
import CallProvider from "./contexts/callContext";

import AppRoute from "./utils/appRoute";
import { Paths } from "./utils/constants";

import OperationLayout from "./layouts/operationLayout";
import SellerLayout from "./layouts/sellerLayout";
import RestaurantLayout from "./layouts/restaurantLayout";
import OperationManagmentLayout from "./layouts/operationManagmentLayout";
import Main from "./pages/main/main";
import MyRestaurants from "./pages/operation/myRestaurants";
import Restaurant from "./pages/restaurants";
import RestaurantRegistration from "./pages/commons/Registration";
import SellerRegister from "./pages/seller/register";
import MyPrim from "./pages/seller/myPrim";
import Finance from "./pages/managment/finance";
import SellReports from "./pages/managment/reports/SellReports";
import ServiceReports from "./pages/managment/reports/ServiceReports";
import RestaurantReports from "./pages/managment/reports/RestaurantReports";
import ManagerWorks from "./pages/managment/managerWorks";
import ManagerPayment from "./pages/managment/paymentTrack";
import DataManagement from "./pages/managment/dataManagement";
import Login from "./pages/login";
import ApproveOffer from "./pages/managment/approveOffer";
import KpiReports from "./pages/managment/reports/kpiReports";
import CallCenter from "./pages/operation/callCenter";
import TechnicalService from "./pages/operation/technicalService";
import Tickets from "./pages/operation/tickets";

import Tasks from "./pages/tasks";
import UserDashboard from "./pages/managment/userDashboard";
import TechMyRestaurants from "./pages/operation/techMyRestaurants";
import OfficeuserPrim from "./pages/operation/prim";
import NotificationTools from "./pages/managment/notificationTools";
import PrintingPress from "./pages/managment/printingPress";
import PrimTable from "./pages/prim-table";
import MissedCall from "./components/call/components";
import Development from "./pages/development";
import DevelopmentProvider from "./contexts/developmentContext";

function App() {
  const refreshToken = localStorage.getItem("st-b-refresh");

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "segoeuiRg",
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {refreshToken ? (
          <LoginProvider>
            <ManagementProvider>
              <CallProvider>
                <RestaurantProvider>
                  <DevelopmentProvider>
                    <WsProvider>
                      <MissedCall />
                      <Switch>
                        <AppRoute exact path={"/"} component={Main} />

                        <AppRoute
                          exact
                          path={`${Paths.kunye}/:id`}
                          component={Restaurant}
                          layout={RestaurantLayout}
                        />

                        <AppRoute
                          exact
                          path={"/development"}
                          component={Development}
                          layout={SellerLayout}
                        />
                        {/* <AppRoute
                      exact
                      path={Paths.operation}
                      component={MyApplications}
                      layout={OperationLayout}
                    /> */}
                        {/* <AppRoute
                      exact
                      path={Paths.operationPaymend}
                      component={PaymendTracker}
                      layout={OperationLayout}
                    /> */}
                        {/* <AppRoute
                      exact
                      path={Paths.operationSetupPaymend}
                      component={PaymentSetupTracker}
                      layout={OperationLayout}
                    /> */}
                        <AppRoute
                          exact
                          path={Paths.operationRegister}
                          component={RestaurantRegistration}
                          layout={OperationLayout}
                        />
                        <AppRoute
                          exact
                          path={Paths.myRestaurants}
                          component={MyRestaurants}
                          layout={OperationLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.techMyRestaurants}
                          component={TechMyRestaurants}
                          layout={OperationLayout}
                        />
                        {/* <AppRoute
                      exact
                      path={Paths.collectorRestaurants}
                      component={CollectorRestaurants}
                      layout={OperationLayout}
                    /> */}

                        <AppRoute
                          exact
                          path={Paths.tasks}
                          component={Tasks}
                          layout={OperationLayout}
                        />
                        <AppRoute
                          exact
                          path={Paths.operationPrim}
                          component={OfficeuserPrim}
                          layout={OperationLayout}
                        />
                        {/* <AppRoute
                      exact
                      path={Paths.operationOutboundReports}
                      component={OutboundReports}
                      layout={OperationLayout}
                    /> */}

                        <AppRoute
                          exact
                          path={Paths.callCenter}
                          component={CallCenter}
                          layout={OperationLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.technicalService}
                          component={TechnicalService}
                          layout={OperationLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationTickets}
                          component={Tickets}
                          layout={OperationLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationPrimTable}
                          component={PrimTable}
                          layout={OperationLayout}
                        />

                        {/* operation-management */}
                        <AppRoute
                          exact
                          path={Paths.operationFinance}
                          component={Finance}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationManagerSellReports}
                          component={SellReports}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationManagerServiceReports}
                          component={ServiceReports}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationManagerRestaurantReports}
                          component={RestaurantReports}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationManagerKpiReports}
                          component={KpiReports}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationManagerWorks}
                          component={ManagerWorks}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationManagerPayment}
                          component={ManagerPayment}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationManagerData}
                          component={DataManagement}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.operationOfferApprove}
                          component={ApproveOffer}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.userDashboard}
                          component={UserDashboard}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.notificationTools}
                          component={NotificationTools}
                          layout={OperationManagmentLayout}
                        />

                        <AppRoute
                          exact
                          path={Paths.printingPress}
                          component={PrintingPress}
                          layout={OperationManagmentLayout}
                        />

                        {/* seller */}
                        <AppRoute
                          exact
                          path={Paths.sellerRegister}
                          component={SellerRegister}
                          layout={SellerLayout}
                        />
                        <AppRoute
                          exact
                          path={Paths.sellerMyPrim}
                          component={MyPrim}
                          layout={SellerLayout}
                        />

                        <Redirect push to="/" />
                      </Switch>
                    </WsProvider>
                  </DevelopmentProvider>
                </RestaurantProvider>
              </CallProvider>
            </ManagementProvider>
          </LoginProvider>
        ) : (
          <LoginProvider>
            <Switch>
              <Route path="/login" component={Login} />
              <Redirect push to="/login" />
            </Switch>
          </LoginProvider>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
