import React from "react";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createTheme,
  useMediaQuery,
} from "@mui/material";

const MmaTotalModal = ({ showMmaModal, setShowMmaModal, mmaTotal }) => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Modal
      open={showMmaModal}
      onClose={() => setShowMmaModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
          width: matches ? "50%" : "90%",
          maxHeight: 600,
          overflow: "auto",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Kişi</TableCell>
                <TableCell align="center">Toplam Adet</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mmaTotal?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center" style={{ background: "none" }}>
                      {row?.username}
                    </TableCell>
                    <TableCell align="center" style={{ background: "none" }}>
                      {row?.total}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};
const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};

export default MmaTotalModal;
