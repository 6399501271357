import { Button } from "@mui/material";
import React from "react";
import { Call, Close } from "@mui/icons-material";
import { SessionState } from "sip.js";

const inbound = ({
  enqueueSnackbar,
  closeSnackbar,
  callData,
  invitation,
  type,
  cdrAnswerFunc,
  X_UUID_INVITATION,
  history,
  callkey,
}) => {
  closeSnackbar(callkey);
  callkey = enqueueSnackbar(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          fontSize: 20,
          fontFamily: "SgBd",
          color: "#fff",
        }}
      >
        <span
          style={{
            textDecoration:
              callData?.restofficial_id !== null ? "underline" : "",
            cursor: callData?.restofficial_id !== null ? "pointer" : "default",
          }}
          onClick={() => {
            if (callData?.restofficial_id !== null) {
              history.push(`/restaurants/${callData?.restofficial_id}`);
            }
          }}
        >
          {callData?.restofficial_name !== null
            ? callData?.restofficial_name +
              " - (" +
              (callData?.restofficial_id !== null
                ? callData?.restofficial_id
                : "Bilinmeyen") +
              ")"
            : "Bilinmeyen"}
        </span>
        <br />
        {callData.phoneNumber}
        <br />
        {type === "accept" && `Bağlantı Kuruldu.`}
      </div>

      {type === "accept" ? (
        <Button
          style={{
            color: "white",
            backgroundColor: "#f53b62",
            textTransform: "capitalize",
            fontFamily: "SgBd",
            fontSize: 14,
            marginLeft: 30,
          }}
          onClick={() => {
            closeSnackbar(callkey);
            if (invitation?._state === SessionState.Established) {
              invitation.bye();
            }
          }}
        >
          <Close />
        </Button>
      ) : (
        <Button
          style={{
            color: "white",
            backgroundColor: "#f53b62",
            textTransform: "capitalize",
            fontFamily: "SgBd",
            fontSize: 14,
            marginLeft: 30,
          }}
          onClick={() => {
            const options = {
              sessionDescriptionHandlerOptions: {
                constraints: {
                  audio: true,
                  video: false,
                },
              },
            };

            invitation
              .accept(options)
              .then(() => {
                let data = {
                  x_uuid: X_UUID_INVITATION,
                };
                cdrAnswerFunc(data);
              })
              .catch((error) => {
                console.log("Failed to answer call", error);
              });
          }}
        >
          <Call />
        </Button>
      )}
    </div>,
    {
      preventDuplicate: true,
      persist: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      sx: {
        "& .SnackbarContent-root": {
          backgroundColor: "darkgray",
        },
      },
    }
  );
};

export default inbound;
