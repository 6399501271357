import React from "react";
import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    height: "auto",
  },
}));

const AcceptEftModal = ({
  acceptEftModal,
  setAcceptEftModal,
  currentRow,
  eftChangeFunc,
  isPre,
}) => {
  const classes = useStyles();
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={acceptEftModal}
      className={classes.modal}
      onClose={() => {
        setAcceptEftModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          padding: 30,
        }}
      >
        <div style={{ fontFamily: "segoeuiB", fontSize: 22 }}>
          {" "}
          Ödemeyi tamamlamak istediğinizden emin misiniz?
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 100,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              marginRight: 30,
              height: 40,
              textTransform: "capitalize",
            }}
            size="small"
            onClick={() => {
              setAcceptEftModal(false);
            }}
          >
            Kapat
          </Button>
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 100,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              marginRight: 30,
              height: 40,
              textTransform: "capitalize",
            }}
            size="small"
            onClick={() => {
              let data = {
                id: currentRow?.id,
                eftObj: {
                  check: isPre ? true : false,
                },
              };
              eftChangeFunc(data);
              setAcceptEftModal(false);
            }}
          >
            Evet
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default AcceptEftModal;
