import React from "react";
import { Box, createTheme, Modal, useMediaQuery } from "@mui/material";

export default function ReportModal({
  openReportModal,
  setOpenReportModal,
  title,
  data,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const renderCategory = (category, categoryName) => {
    let totalCount;
    let percentage;

    if (data[category]) {
      totalCount = data[category].reduce(
        (accumulator, item) => accumulator + item.count,
        0
      );

      if (categoryName === "TÜMÜ") {
        percentage = "100%";
      } else {
        percentage =
          (
            (totalCount /
              data.all.reduce(
                (accumulator, item) => accumulator + item.count,
                0
              )) *
            100
          ).toFixed(2) + "%";
      }
    }

    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <h3 style={{ textAlign: "center" }}>
          {categoryName} - {totalCount} - {percentage}
        </h3>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {data[category] &&
            data[category].map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 14,
                  marginBottom: 5,
                  paddingBottom: 5,
                  borderBottom: "1px solid rgba(0,0,0,0.2)",
                  fontWeight: 600,
                }}
              >
                <div style={{ marginRight: 10 }}>
                  {item.name} - {item.count}
                </div>
                <div style={{ color: "#97001d" }}>{item.percent}%</div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={openReportModal}
      onClose={() => setOpenReportModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {title}
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "450px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              overflow: "auto",
              justifyContent: "space-between",
            }}
          >
            {renderCategory("all", "TÜMÜ")}
            {renderCategory("dijital", "Dijital")}
            {renderCategory("ivr", "IVR")}
            {renderCategory("rehberimden", "Rehberimden")}
            {renderCategory("zero_data", "Zero data")}
          </div>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
