import React, { useState } from "react";
import { Button, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxHeight: "100vh",
  },
}));

const RestaurantRatingModal = ({
  restaurantRatingModal,
  setRestaurantRatingModal,
  currentId,
  setCurrentId,
  orderRatingChangeFunc,
  page,
  pageType,
  restofficial_id,
}) => {
  const classes = useStyles();
  const [message, setMessage] = useState(null);

  const sendOrderRatingRequest = (req) => {
    let data = {
      id: currentId,
      sendObj: {
        verification: req,
        reply: message != null ? message : "",
      },
      page,
      pageType,
      restofficial_id,
    };
    orderRatingChangeFunc(data);
    setCurrentId(null);
    setMessage(null);
    setRestaurantRatingModal(false);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      onClose={() => {
        setRestaurantRatingModal(false);
      }}
      open={restaurantRatingModal}
    >
      <Paper
        elevation={3}
        style={{
          width: "40%",
          padding: 20,
        }}
      >
        <div style={{ textAlign: "left", fontWeight: 600, marginBottom: 20 }}>
          Cevap Yaz
        </div>
        <input
          className="input"
          type="text"
          name="name"
          value={message}
          spellCheck="false"
          style={{
            width: "100%",
            borderWidth: 0,
            borderColor: "#d4d4d4",
            fontSize: 14,
            fontWeight: "400",
            resize: "none",
            height: 40,
            backgroundColor: "#d4d4d4",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            fontFamily: "SgNr",
          }}
          autoComplete="off"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="error"
            style={{
              borderRadius: 4,
              width: "30%",
              height: 30,
              marginTop: 20,
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
              fontFamily: "CoRg",
              fontWeight: 700,
            }}
            variant="contained"
            onClick={() => {
              sendOrderRatingRequest("rejected");
            }}
          >
            Reddet
          </Button>
          <Button
            style={{
              borderRadius: 4,
              width: "30%",
              height: 30,
              marginTop: 20,
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
              fontFamily: "CoRg",
              fontWeight: 700,
            }}
            variant="contained"
            color="success"
            onClick={() => {
              sendOrderRatingRequest("verified");
            }}
          >
            Kabul Et
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default RestaurantRatingModal;
