import { http } from "./axios";

function login(data) {
  return http.post("/officeuser/login", data);
}

function login_seller(data) {
  return http.post("/seller/login", data);
}

function officeuser_info() {
  return http.get("/officeuser/info");
}

function officeuser_seller_info() {
  return http.get("/seller/info");
}

function logout() {
  return http.post("/officeuser/logout");
}

function logout_seller() {
  return http.post("/seller/logout");
}

const loginService = {
  login,
  logout,
  officeuser_info,
  login_seller,
  officeuser_seller_info,
  logout_seller,
};

export default loginService;
