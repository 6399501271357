import React, { useReducer } from "react";
import { AddBoxOutlined } from "@mui/icons-material";
import { InputBase, Paper } from "@mui/material";
import { opsiyonFunc } from "./opsiyonFunc";

export const productFunc = (
  productObj,
  setProductObj,
  forceUpdate,
  classes,
  optProductList,
  menuProductList
) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          height: 110,
          backgroundColor: "#5a5757",
          marginTop: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderRadius: 8,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: 20,
          }}
        >
          <Paper
            component="form"
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              margin: 2,
            }}
          >
            <InputBase
              spellCheck="false"
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              size="medium"
              value={productObj?.name}
              className={classes.input}
              style={{
                fontSize: 13,
                width: "95%",
              }}
              placeholder={"Ürün"}
              onChange={(e) => {
                setProductObj((prevState) => {
                  return {
                    ...prevState,
                    name: e.target.value,
                  };
                });
              }}
            />
          </Paper>
          <Paper
            component="form"
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              margin: 2,
            }}
          >
            <InputBase
              spellCheck="false"
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              value={productObj?.description}
              className={classes.input}
              style={{
                fontSize: 13,
                width: "95%",
              }}
              placeholder={"Açıklaması"}
              onChange={(e) => {
                setProductObj((prevState) => {
                  return {
                    ...prevState,
                    description: e.target.value,
                  };
                });
              }}
            />
          </Paper>
          <Paper
            component="form"
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              margin: 2,
            }}
          >
            <InputBase
              size="small"
              spellCheck="false"
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              type="number"
              value={productObj?.price}
              className={classes.input}
              style={{
                fontSize: 13,
                width: "95%",
                marginTop: 3,
              }}
              placeholder={"Fiyat"}
              onChange={(e) => {
                setProductObj((prevState) => {
                  return {
                    ...prevState,
                    price: e.target.value,
                  };
                });
              }}
            />
          </Paper>
        </div>
        <AddBoxOutlined
          style={{
            width: 32,
            height: 32,
            cursor: "pointer",
            color: "#fff",
            marginRight: 20,
          }}
          onClick={() => {
            productObj.options.push({
              name: "",
              num_min: 0,
              num_max: 0,
              select_all: false,
              select_limit: 0,
              force_select_limit: false,
              details: [],
            });
            forceUpdate();
          }}
        />
      </div>

      <div style={{ marginLeft: 20 }}>
        {opsiyonFunc(
          productObj,
          forceUpdate,
          classes,
          optProductList,
          menuProductList
        )}
      </div>
    </div>
  );
};
