import { useState } from "react";
import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useHistory } from "react-router-dom/";

export default function CompletedTasksModal({
  openCompletedTasks,
  setOpenCompletedTasks,
  officeUserDetailCompletedTasks,
  page,
  setPage,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function convertToHourAndMinutes(seconds) {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.round((seconds % 3600) / 60);

    const result = (hour !== 0 ? hour + " saat " : "") + minute + " dakika";

    return result;
  }

  return (
    <Modal
      open={openCompletedTasks}
      onClose={() => {
        setOpenCompletedTasks(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Tamamlanan Görevler
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "450px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Restoran ID</TableCell>
                  <TableCell align="center">Görev Tipi</TableCell>
                  <TableCell align="center">Başlangıç Saati</TableCell>
                  <TableCell align="center">Bitiş Saati</TableCell>
                  <TableCell align="center">Süre Dk</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {officeUserDetailCompletedTasks?.results.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell
                        align="center"
                        style={{
                          cursor: row.restofficial_id ? "pointer" : "auto",
                          color: row.restofficial_id ? "blue" : "black",
                          textDecoration: row.restofficial_id
                            ? "underline"
                            : "none",
                        }}
                        onClick={() => {
                          if (row.restofficial_id) {
                            window.open(
                              `restaurants/${row.restofficial_id}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {row.restofficial_id}
                      </TableCell>
                      <TableCell align="center">{row.task_type.name}</TableCell>
                      <TableCell align="center">
                        {moment(row?.created_at).format("DD-MM-YYYY HH:mm:ss")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row?.completed_at).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {convertToHourAndMinutes(row.time_spent)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  {officeUserDetailCompletedTasks?.results != null && (
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      labelRowsPerPage={"Sayfa Başına Kayıt"}
                      count={officeUserDetailCompletedTasks?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
