import React, { useContext, useEffect } from "react";
import { Button, Card, Divider, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../contexts/managementContext";
import useWindowDimensions from "../../windowSize";
import LottieMain from "../../lottie";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    height: "auto",
  },
}));

const BrochureModal = ({
  brochureModal,
  setBrochureModal,
  currentRow,
  setCurrentRow,
}) => {
  const classes = useStyles();
  let { id } = useParams();
  const {
    getBrochurePicture,
    brochurePicture,
    brochureLoading,
    brochurePictureDownload,
    brochureDownloadLoading,
    brochureSendRestaurant,
    brochureResResult,
  } = useContext(ManagementContext);
  const { restOfficialData, getOfficeuserDetail } =
    useContext(RestaurantContext);
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (brochureModal) {
      getBrochurePicture(currentRow?.id);
      getOfficeuserDetail(id);
    }
  }, [brochureModal]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={brochureModal}
      className={classes.modal}
      onClose={() => {
        setBrochureModal(false);
        setCurrentRow(null);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "row",
          padding: 10,
          height: "95%",
        }}
      >
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            variant="outlined"
            style={{ marginTop: 10, width: "90%", textTransform: "capitalize" }}
            onClick={() => {
              let data = {
                id: currentRow?.id,
                return_format: "base64",
                file_type: "jpeg",
                type: "png",
              };
              brochurePictureDownload(data);
            }}
          >
            PNG İndir
          </Button>
          <Button
            variant="outlined"
            style={{ marginTop: 10, width: "90%", textTransform: "capitalize" }}
            onClick={() => {
              let data = {
                id: currentRow?.id,
                return_format: "pdf",
                file_type: "pdf",
                type: "pdf",
              };
              brochurePictureDownload(data);
            }}
          >
            PDF İndir
          </Button>
          <Button
            variant="outlined"
            style={{ marginTop: 10, width: "90%", textTransform: "capitalize" }}
            onClick={() => {
              let data = {
                id: currentRow?.id,
                return_format: "jpeg",
                file_type: "jpeg",
                type: "jpeg",
              };
              brochurePictureDownload(data);
            }}
          >
            JPG İndir
          </Button>
          {brochureDownloadLoading ? (
            <div
              style={{ marginTop: 30, color: "green", fontFamily: "segoeuiB" }}
            >
              İndirme İşlemi başladı.
            </div>
          ) : (
            ""
          )}

          <Button
            disabled={restOfficialData?.version >= "1.1.6" ? false : true}
            variant="outlined"
            style={{ marginTop: 30, width: "90%", textTransform: "capitalize" }}
            onClick={() => {
              brochureSendRestaurant(currentRow?.id);
            }}
          >
            Restorana Göster
          </Button>
          {restOfficialData?.version >= "1.1.6" ? (
            brochureResResult !== null ? (
              brochureResResult === "accepted" ? (
                <div
                  style={{
                    marginTop: 10,
                    color: "green",
                    fontFamily: "segoeuiB",
                  }}
                >
                  Onayladı
                </div>
              ) : (
                <div
                  style={{
                    marginTop: 10,
                    color: "green",
                    fontFamily: "segoeuiB",
                  }}
                >
                  Revize İsteği
                </div>
              )
            ) : (
              ""
            )
          ) : (
            <div
              style={{
                marginTop: 10,
                color: "red",
                fontFamily: "segoeuiB",
                fontSize: 12,
                textAlign: "center",
              }}
            >
              Restoran versiyonu güncellenmeli ({restOfficialData?.version})
            </div>
          )}
        </div>
        <Divider
          orientation="horizontal"
          sx={{
            backgroundColor: "gray",
            width: 1.1,
            height: "100%",
            margin: "5px 0px",
          }}
        />
        <div
          style={{
            width: "84%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {brochureLoading ? (
            <LottieMain />
          ) : (
            <img
              style={{
                width: (height - 123) * 0.70025402201,
                height: height - 123,
              }}
              src={`data:image/png;base64,${brochurePicture?.brochure_base64}`}
              alt="brochure_base64"
            />
          )}
        </div>
      </Card>
    </Modal>
  );
};

export default BrochureModal;
