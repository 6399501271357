import { useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { Settings } from "@mui/icons-material";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import TaskLockModal from "./TaskLockModal";
import UserDeleteModal from "./UserDeleteModal";
import DataTransferModal from "./DataTransferModal";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AddOfficeUserModal from "../addOfficeUserModal";

export default function BasicSpeedDial({
  dashboardOfficeuserDetail,
  setCallerFlipped,
  startDate,
  endDate,
  backofficeCollectorList,
}) {
  const [openDial, setOpenDial] = useState(false);
  const [openTaskLockModal, setOpenTaskLockModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openDataTransferModal, setOpenDataTransferModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleOpenDial = () => {
    setOpenDial(!openDial);
  };

  const handleCloseDial = (_, reason) => {
    if (
      reason === "toggle" ||
      reason === "blur" ||
      reason === "escapeKeyDown"
    ) {
      setOpenDial(false);
    }
  };

  const handleTaskLock = () => {
    setOpenTaskLockModal(true);
  };

  const handlePersonDelete = () => {
    setOpenDeleteUserModal(true);
  };

  const handleDataTransfer = () => {
    setOpenDataTransferModal(true);
  };

  const handleOfficeUser = () => {
    setShowAddUserModal(true);
  };

  const actions = [
    {
      icon: (
        <AppSettingsAltIcon
          onClick={() => {
            handleTaskLock();
          }}
        />
      ),
      name: "Görev Ayarı",
    },
    {
      icon: (
        <PersonRemoveIcon
          onClick={() => {
            handlePersonDelete();
          }}
        />
      ),
      name: "Kullanıcı Sil",
    },
    {
      icon: (
        <TransferWithinAStationIcon
          onClick={() => {
            handleDataTransfer();
          }}
        />
      ),
      name: "Data Transfer",
    },
    {
      icon: (
        <PersonSearchIcon
          onClick={() => {
            handleOfficeUser();
          }}
        />
      ),
      name: "Düzenle",
    },
  ];

  return (
    <Box style={{ paddingRight: 10 }}>
      <SpeedDial
        direction={"left"}
        open={openDial}
        onClose={handleCloseDial}
        onClick={handleOpenDial}
        FabProps={{
          size: "medium",
          style: { backgroundColor: "#293c51", width: 45, height: 45 },
        }}
        ariaLabel="Ayarlar"
        icon={<Settings style={{ width: 24, height: 24 }} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>

      <TaskLockModal
        openTaskLockModal={openTaskLockModal}
        setOpenTaskLockModal={setOpenTaskLockModal}
        dashboardOfficeuserDetail={dashboardOfficeuserDetail}
        startDate={startDate}
        endDate={endDate}
      />

      <UserDeleteModal
        openDeleteUserModal={openDeleteUserModal}
        setOpenDeleteUserModal={setOpenDeleteUserModal}
        dashboardOfficeuserDetail={dashboardOfficeuserDetail}
        setCallerFlipped={setCallerFlipped}
      />

      <DataTransferModal
        openDataTransferModal={openDataTransferModal}
        setOpenDataTransferModal={setOpenDataTransferModal}
        dashboardOfficeuserDetail={dashboardOfficeuserDetail}
        backofficeCollectorList={backofficeCollectorList}
      />

      {showAddUserModal && (
        <AddOfficeUserModal
          showAddUserModal={showAddUserModal}
          setShowAddUserModal={setShowAddUserModal}
          dashboardOfficeuserDetail={dashboardOfficeuserDetail}
        />
      )}
    </Box>
  );
}
