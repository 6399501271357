import { useContext } from "react";
import { Box, createTheme, Modal, useMediaQuery, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { Report, Send } from "@mui/icons-material";

const User = styled(Button)(({}) => ({
  display: "flex",
  backgroundColor: "#f53f62",
  cursor: "pointer",
  minHeight: 26,
  color: "#fff",
  width: "90%",
  marginBottom: 6,
  padding: 4,
  border: "solid 0.5px",
  borderRadius: 4,
  fontFamily: "segoeuiRg",
  fontWeight: 600,
  justifyContent: "flex-start",

  "&:hover": {
    backgroundColor: "#fafafa",
    color: "#000",
  },
}));

export default function OfficeUserList({
  openModal,
  setOpenModal,
  selectedItem,
  patchAction,
  allData,
  type,
  debouncedsearchValue,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { backofficeList, sendUrgentSetupFunc } = useContext(RestaurantContext);

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box
        sx={{
          ...styles.modal,
          width: matches ? "40%" : "90%",
          minHeight: 150,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Button
            style={{
              color: "white",
              backgroundColor: "darkslateblue",
              padding: "5px 10px 5px 10px",
              textTransform: "capitalize",
              fontFamily: "segoeuiB",
              fontSize: 15,
            }}
            startIcon={<Send />}
            onClick={() => {
              patchAction(selectedItem, {
                zero_data: true,
              });
              setOpenModal(false);
            }}
          >
            Havuza Gönder
          </Button>
          {JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
            "outbound" && (
            <Button
              style={{
                color: "white",
                backgroundColor: "#f53b62",
                padding: "5px 10px 5px 10px",
                textTransform: "capitalize",
                fontFamily: "segoeuiB",
                fontSize: 15,
              }}
              startIcon={<Report />}
              onClick={() => {
                sendUrgentSetupFunc({
                  app_id: selectedItem,
                });
                setOpenModal(false);
              }}
            >
              Acil Kurulum
            </Button>
          )}
        </div>

        {JSON.parse(localStorage.getItem("st-officeuser-info")).role !==
          "outbound" && (
          <>
            <h2
              id="child-modal-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Ofis Çalışanları Listesi
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxHeight: "780px",
                overflowY: "auto",
                backgroundColor: "#fff",
              }}
            >
              {backofficeList?.map((user, id) => {
                return (
                  <User
                    key={id + 1}
                    onClick={() => {
                      if (allData) {
                        let data = {
                          processed_by: user.user_id,
                          id:
                            type === "application"
                              ? selectedItem.application_id
                              : selectedItem.id,
                          type: type,
                        };
                        patchAction(data, debouncedsearchValue);
                      } else {
                        patchAction(selectedItem, {
                          processed_by: user.user_id,
                        });
                      }

                      setOpenModal(false);
                    }}
                  >
                    {user.full_name}
                  </User>
                );
              })}
            </div>
            <span style={{ fontSize: 14, fontWeight: 600 }}>
              {" "}
              Not: İstenilen kişinin üzerine tıklayarak atama işlemini
              yapabilirsiniz
            </span>
          </>
        )}
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
