import { Box, Button, Modal } from "@mui/material";
import { useContext } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";

export default function HeaderDeleteModal({
  openProductHeaderModal,
  setOpenProductHeaderModal,
  selectedHeader,
  productPictureTemplate,
}) {
  const { deleteProductTemplateHeader } = useContext(RestaurantContext);

  return (
    <Modal
      open={openProductHeaderModal}
      onClose={() => {
        setOpenProductHeaderModal(false);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
        }}
      >
        {productPictureTemplate?.length > 0 ? (
          <h3>Başlığı silmek için önce altındaki ürünleri silmelisiniz.</h3>
        ) : (
          <>
            <h2
              id="child-modal-title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {selectedHeader?.name} isimli başlığı silmek istediğinize emin
              misiniz?
            </h2>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                size="medium"
                style={{
                  fontFamily: "segoeuiB",
                  fontWeight: 700,
                  backgroundColor: "#f53b62",
                  width: 200,
                }}
                onClick={() => {
                  setOpenProductHeaderModal(false);
                }}
              >
                Hayır
              </Button>
              <Button
                variant="contained"
                size="medium"
                style={{
                  fontFamily: "segoeuiB",
                  width: 200,
                  fontWeight: 700,
                  backgroundColor: "#0FBD20",
                }}
                onClick={() => {
                  deleteProductTemplateHeader(selectedHeader.id);
                  setOpenProductHeaderModal(false);
                }}
              >
                Evet
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
