import React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import { green } from "@mui/material/colors";

const CustomerSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "red",
  },
}));

export const GreenSwitch = ({ style, checked, onChange, ...props }) => {
  return (
    <div>
      <CustomerSwitch
        {...props}
        style={{ ...style }}
        onChange={onChange}
        checked={checked}
      />
    </div>
  );
};
