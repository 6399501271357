import { Button, InputBase, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import { useToast } from "../../../../components/snackbar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import managementService from "../../../../services/managementService";

export default function Dictionary() {
  const { alert, success } = useToast();
  const [header, setHeader] = useState("");
  const [showHeader, setShowHeader] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const {
    getDictionaryHeaders,
    addDictionaryHeaders,
    dictionaryHeaders,
    editDictionaryHeaders,
    deleteDictionaryHeaders,
    addDictionaryFeature,
    getDictionaryFeatures,
    dictionaryFeatures,
    setDictionaryFeatures,
    deleteDictionaryFeature,
    editDictionaryFeature,
  } = useContext(ManagementContext);

  useEffect(() => {
    getDictionaryHeaders("");
  }, []);

  const handleSaveHeader = () => {
    if (selectedId) {
      let data = {
        id: selectedId,
        name: header,
      };
      editDictionaryHeaders(data);
    } else {
      let data = {
        name: header,
      };
      addDictionaryHeaders(data);
    }

    if (header === "") return alert("Başlık boş olamaz.");

    setHeader("");
    setShowHeader(false);
    setSelectedId(null);
  };

  const handleSelectHeader = (item) => {
    setShowHeader(true);
    setHeader(item.name);
    setSelectedId(item.id);
    getDictionaryFeatures(item.id);
  };

  const handleDeleteHeader = (item) => {
    deleteDictionaryHeaders(item.id);
    setHeader("");
    setSelectedId(null);
  };

  const handleAddFeature = () => {
    let data = {
      name: "",
      description: "",
      files: [],
    };
    setDictionaryFeatures([...dictionaryFeatures, data]);
  };

  const handleSaveFeature = (i, item) => {
    const formData = new FormData();
    formData.append("header", selectedId);
    formData.append("name", dictionaryFeatures[i].name);
    formData.append("description", dictionaryFeatures[i].description);
    dictionaryFeatures[i].files?.forEach((row, ind) => {
      formData.append(`files_${ind}`, row);
    });
    if (item.id) {
      editDictionaryFeature(selectedId, item.id, formData);
    } else {
      addDictionaryFeature(formData, selectedId);
    }
    success("İşlem Başarılı");
  };

  const onChange = (e, i, ind) => {
    const newDictionaryFeatures = [...dictionaryFeatures];
    newDictionaryFeatures[i].files[ind] = e.target.files[0];
    setDictionaryFeatures(newDictionaryFeatures);
  };

  const handleAddPdf = (i) => {
    const newDictionaryFeatures = [...dictionaryFeatures];
    newDictionaryFeatures[i].files.push({ file: "" });
    setDictionaryFeatures(newDictionaryFeatures);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="large"
        style={{
          fontFamily: "segoeuiB",
          fontWeight: 700,
          backgroundColor: "#0FBD20",
        }}
        onClick={() => {
          setShowHeader(true);
          setHeader("");
          setSelectedId(null);
          setDictionaryFeatures([]);
        }}
      >
        Başlık Oluştur
      </Button>

      <div style={{ display: "flex", marginTop: 20 }}>
        {dictionaryHeaders.length > 0 && (
          <div
            style={{
              display: "flex",
              width: "20%",
              background: "#e7e7e7",
              marginRight: 20,
              padding: 10,
            }}
          >
            <div style={{ width: "100%" }}>
              <h3 style={{ margin: "0px 0px 10px" }}>Başlıklar</h3>

              {dictionaryHeaders.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 5,
                      padding: 5,
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      fontWeight: 500,
                      cursor: "pointer",
                      background: selectedId === item.id ? "#fff" : "",
                    }}
                  >
                    <div
                      onClick={() => handleSelectHeader(item)}
                      style={{ width: "100%" }}
                    >
                      {i + 1} - {item.name}
                    </div>

                    <Tooltip title="Sil" placement="top">
                      <DeleteIcon
                        style={{ color: "red" }}
                        onClick={() => handleDeleteHeader(item)}
                      />
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {showHeader && (
          <div style={{ width: "80%", background: "#cbcbcb", padding: 10 }}>
            <div
              style={{
                background: "#e7e7e7",
                padding: 10,
                marginBottom: 20,
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Başlık
              </span>

              <InputBase
                style={{
                  background: "#fff",
                  padding: 6,
                  fontSize: 16,
                  fontWeight: "400",
                  fontFamily: "segoeuiRg",
                  width: "100%",
                  marginRight: 20,
                }}
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    fontFamily: "segoeuiB",
                    fontWeight: 700,
                    backgroundColor: "red",
                  }}
                  onClick={() => {
                    setShowHeader(false);
                    setHeader("");
                    setSelectedId(null);
                  }}
                >
                  İptal
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    fontFamily: "segoeuiB",
                    fontWeight: 700,
                    backgroundColor: "#0FBD20",
                    marginLeft: 20,
                  }}
                  onClick={handleSaveHeader}
                >
                  Kaydet
                </Button>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="Alt Başlık Ekle">
                <AddCircleOutlineIcon
                  style={{ color: "red", fontSize: 34, cursor: "pointer" }}
                  onClick={handleAddFeature}
                />
              </Tooltip>
            </div>

            {dictionaryFeatures.map((item, i) => {
              return (
                <div
                  style={{
                    background: "#e7e7e7",
                    padding: 10,
                    marginTop: 20,
                  }}
                  key={i}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {i !== 0 && (
                      <Tooltip title="Yukarı Taşı">
                        <ArrowUpwardIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={async () => {
                            let data = [...dictionaryFeatures];
                            let temp = data[i];
                            data[i] = data[i - 1];
                            data[i - 1] = temp;

                            const ids = data.map((item) => item.id);

                            const idsData = {
                              ids: ids,
                            };

                            await managementService.order_dictionary_features(
                              selectedId,
                              idsData
                            );

                            setDictionaryFeatures(data);
                          }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Sil" placement="top">
                      <DeleteIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => {
                          let data = [...dictionaryFeatures];
                          data.splice(i, 1);
                          setDictionaryFeatures(data);
                          if (item.id) {
                            deleteDictionaryFeature(selectedId, item.id);
                          }
                        }}
                      />
                    </Tooltip>
                  </div>
                  <span
                    style={{
                      fontSize: 18,
                      marginBottom: 10,
                      display: "block",
                      fontWeight: "600",
                    }}
                  >
                    Alt Başlık
                  </span>
                  <InputBase
                    style={{
                      background: "#fff",
                      padding: 6,
                      fontSize: 16,
                      fontWeight: "400",
                      fontFamily: "segoeuiRg",
                      width: "100%",
                      marginRight: 20,
                    }}
                    value={item.name}
                    onChange={(e) => {
                      let data = [...dictionaryFeatures];
                      data[i].name = e.target.value;
                      setDictionaryFeatures(data);
                    }}
                  />

                  <span
                    style={{
                      fontSize: 18,
                      marginBottom: 10,
                      display: "block",
                      fontWeight: "600",
                    }}
                  >
                    Açıklama
                  </span>
                  <InputBase
                    style={{
                      background: "#fff",
                      padding: 6,
                      fontSize: 16,
                      fontWeight: "400",
                      fontFamily: "segoeuiRg",
                      width: "100%",
                      marginRight: 20,
                    }}
                    value={item.description}
                    onChange={(e) => {
                      let data = [...dictionaryFeatures];
                      data[i].description = e.target.value;
                      setDictionaryFeatures(data);
                    }}
                  />

                  <div style={{ marginTop: 10 }}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip title="Pdf Ekle">
                        <AddCircleOutlineIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleAddPdf(i)}
                        />
                      </Tooltip>
                    </div>

                    {item.files.map((file, ind) => {
                      return (
                        <div
                          key={ind}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginRight: 10,
                            marginBottom: 10,
                          }}
                        >
                          <div>
                            {file?.file && (
                              <PictureAsPdfIcon
                                onClick={() => {
                                  window.open(file.file, "_blank");
                                }}
                                style={{ color: "red" }}
                              />
                            )}

                            <input
                              name="file"
                              type="file"
                              accept="application/pdf"
                              onChange={(e) => onChange(e, i, ind)}
                            />
                          </div>

                          <Tooltip title="Sil" placement="top">
                            <DeleteIcon
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={async () => {
                                let data = [...dictionaryFeatures];
                                data[i].files.splice(ind, 1);
                                setDictionaryFeatures(data);

                                if (file.id) {
                                  await managementService.delete_pdf_from_dictionary(
                                    file.id
                                  );
                                  success("İşlem Başarılı");
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        fontFamily: "segoeuiB",
                        fontWeight: 700,
                        backgroundColor: "#0FBD20",
                        marginLeft: 20,
                      }}
                      onClick={() => handleSaveFeature(i, item)}
                    >
                      Kaydet
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
