import React, { useContext, useEffect, useState } from "react";

import { Card, Modal, createTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { RestaurantContext } from "../../../contexts/restaurantContext";

import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Notes from "./components/notes";
import Actions from "./components/actions";
import moment from "moment";

registerLocale("tr", tr);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    //   alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },

  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "segoeuiB",
    },
  },
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
}));

const ApplicationActions = ({
  applicationActionsModal,
  setApplicationActionsModal,
  applicationId,
  pageStatus,
  setApplicationId,
  getNotes,
  notes,
  data_type,
  createNewNote,
  setPageStatus,
}) => {
  const classes = useStyles();
  const theme = createTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedActionType, setSelectedActionType] = useState("normal");
  const [selectedDeleteReason, setSelectedDeleteReason] = useState(null);
  const [note, setNote] = useState("");
  const [startDate, setStartDate] = useState(
    setHours(
      setMinutes(new Date(), moment().format("mm")),
      moment().format("HH")
    )
  );

  const { blockedStatusReasonsFunc } = useContext(RestaurantContext);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (applicationId != null) {
      let data = {
        id: page + 1,
        app_id: applicationId.hasOwnProperty("user_id")
          ? applicationId?.user_id
          : applicationId?.id,
      };

      getNotes(data);

      blockedStatusReasonsFunc();
    }
  }, [applicationId, page]);

  const keyPress = (e) => {
    if (e.keyCode == 13) {
    }
    if (e.key === "Escape") {
      setApplicationActionsModal(false);
    }
  };
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={applicationActionsModal}
      className={classes.modal}
      onKeyDown={keyPress}
      onClose={() => {
        setApplicationActionsModal(false);
        setSelectedActionType("normal");
        setNote("");
        setSelectedDeleteReason("null");
        setStartDate(
          setHours(
            setMinutes(new Date(), moment().format("mm")),
            moment().format("HH")
          )
        );
        setApplicationId(null);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#f4f4f4",
          padding: 20,
          maxHeight: "95%",
          overflow: "auto",
          minHeight: "95%",
        }}
      >
        <Notes
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          applicationNotes={notes}
          isWeb={isWeb}
        />

        <Actions
          selectedActionType={selectedActionType}
          setSelectedActionType={setSelectedActionType}
          selectedDeleteReason={selectedDeleteReason}
          setSelectedDeleteReason={setSelectedDeleteReason}
          startDate={startDate}
          setStartDate={setStartDate}
          setApplicationActionsModal={setApplicationActionsModal}
          applicationId={applicationId}
          pageStatus={pageStatus}
          setApplicationId={setApplicationId}
          note={note}
          setNote={setNote}
          page={page}
          isWeb={isWeb}
          data_type={data_type}
          createNewNote={createNewNote}
          setPageStatus={setPageStatus}
        />
      </Card>
    </Modal>
  );
};

export default ApplicationActions;
