import { Box, Button, Modal } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../contexts/managementContext";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import { selectDefaultStyles } from "../../selectStyles/stylev2";
import RegistrationField from "../../registrationField";
import { useToast } from "../../snackbar";

const discountArr = [];
for (let i = 0; i <= 100; i += 5) {
  const percent = {
    value: i,
    label: `${i} %`,
  };
  discountArr.push(percent);
}

const cashbackArr = [];
for (let i = 5; i <= 100; i++) {
  const percent = {
    value: i,
    label: `${i} %`,
  };
  cashbackArr.push(percent);
}

export default function BaskiRequestModal({
  openBaskiRequestModal,
  setOpenBaskiRequestModal,
  baskiTypes,
  printingRequestList,
  brochurePicture,
  setBrochurePicture,
  page,
  restId,
}) {
  const { alert } = useToast();
  const {
    printingRequestDetail,
    printingPressList,
    editPrintingRequestDetail,
  } = useContext(ManagementContext);
  const [selectedBaskiType, setSelectedBaskiType] = useState(null);
  const [selectedGramaj, setSelectedGramaj] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [selectedPrinterUser, setSelectedPrinterUser] = useState(null);
  const [selectedBaskiData, setSelectedBaskiData] = useState({
    grammages: [],
    sizes: [],
    quantities: [],
    directions: [],
  });
  const [selectedCashBack, setSelectedCashkBack] = useState(null);
  const [firstOrderForm, setFirstOrderForm] = useState({
    first_order_discount_min_price: 0,
    first_order_discount_amount: 0,
  });
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [cargoDeliveryForm, setCargoDeliveryForm] = useState({
    cargo_delivery_address: "",
  });

  const handleSaveBaskiRequest = () => {
    if (
      !selectedPrinterUser ||
      !selectedBaskiType ||
      !selectedGramaj ||
      !selectedSize ||
      !selectedQuantity ||
      !selectedDirection ||
      !selectedCashBack ||
      !firstOrderForm.first_order_discount_min_price ||
      !firstOrderForm.first_order_discount_amount ||
      !cargoDeliveryForm.cargo_delivery_address
    ) {
      alert("Lütfen tüm alanları doldurunuz.");
      return;
    }
    const id = printingRequestDetail.id;
    const data = {
      printingpress: selectedPrinterUser.id,
      printing_type_id: selectedBaskiType.id,
      grammage: selectedGramaj.value,
      size: selectedSize.value,
      quantity: selectedQuantity.value,
      direction: selectedDirection.value,
      first_order_discount_min_price:
        firstOrderForm.first_order_discount_min_price,
      first_order_discount_amount: firstOrderForm.first_order_discount_amount,
      order_discount_percent: selectedDiscount ? selectedDiscount.value : 0,
      cashback_percent: selectedCashBack.value,
      cargo_delivery_address: cargoDeliveryForm.cargo_delivery_address,
    };
    editPrintingRequestDetail(id, data, page + 1, restId ? restId : "");
    setOpenBaskiRequestModal(false);
  };

  useEffect(() => {
    if (printingRequestDetail) {
      setCargoDeliveryForm({
        cargo_delivery_address: printingRequestDetail.cargo_delivery_address,
      });

      setSelectedCashkBack({
        value: printingRequestDetail.restofficial.cashback_percent,
        label: `${printingRequestDetail.restofficial.cashback_percent} %`,
      });

      setFirstOrderForm({
        first_order_discount_min_price:
          printingRequestDetail.restofficial.first_order_discount_min_price,
        first_order_discount_amount:
          printingRequestDetail.restofficial.first_order_discount_amount,
      });

      setSelectedDiscount({
        value: printingRequestDetail.restofficial.order_discount_percent,
        label: `${printingRequestDetail.restofficial.order_discount_percent} %`,
      });

      if (baskiTypes.length > 0) {
        const selectedBaskiOptions = baskiTypes.find((item) => {
          if (
            item.printing_type_title ===
            printingRequestDetail.printing_type.printing_type_title
          ) {
            return item;
          }
        });

        setSelectedBaskiData({
          grammages: selectedBaskiOptions.grammages,
          sizes: selectedBaskiOptions.sizes,
          quantities: selectedBaskiOptions.quantities,
          directions: selectedBaskiOptions.directions,
        });
      }

      printingRequestList?.results?.map((item) => {
        if (
          item.printingpress.company_title ===
          printingRequestDetail.printingpress.company_title
        ) {
          setSelectedPrinterUser({
            value: item.printingpress.company_title,
            label: item.printingpress.company_title,
            id: item.printingpress.id,
          });
          setSelectedBaskiType({
            value: printingRequestDetail.printing_type.printing_type_title,
            label: printingRequestDetail.printing_type.printing_type_title,
            id: printingRequestDetail.printing_type.id,
          });
          setSelectedGramaj({
            value: printingRequestDetail.grammage,
            label: printingRequestDetail.grammage,
          });
          setSelectedSize({
            value: printingRequestDetail.size,
            label: printingRequestDetail.size,
          });
          setSelectedQuantity({
            value: printingRequestDetail.quantity,
            label: printingRequestDetail.quantity,
          });
          setSelectedDirection({
            value: printingRequestDetail.direction,
            label: printingRequestDetail.direction,
          });
        }
      });
    }
  }, [printingRequestDetail, baskiTypes]);

  useEffect(() => {
    if (selectedBaskiType) {
      const selectedBaskiOptions = baskiTypes.find((item) => {
        if (item.printing_type_title === selectedBaskiType.value) {
          return item;
        }
      });

      setSelectedBaskiData({
        grammages: selectedBaskiOptions.grammages,
        sizes: selectedBaskiOptions.sizes,
        quantities: selectedBaskiOptions.quantities,
        directions: selectedBaskiOptions.directions,
      });
    }
  }, [selectedBaskiType]);

  return (
    <Modal
      open={openBaskiRequestModal}
      onClose={() => {
        setOpenBaskiRequestModal(false);
        setBrochurePicture(null);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
          maxHeight: 550,
          overflow: "auto",
          minHeight: 400,
        }}
      >
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Baskı Detayı
        </h2>
        {brochurePicture?.brochure_base64 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={"data:image/png;base64," + brochurePicture?.brochure_base64}
              alt="brosur"
              style={{ maxWidth: 350 }}
            />
          </div>
        ) : (
          <>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "33%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Matbaa Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={printingPressList.map((type) => {
                        return {
                          value: type.user.username,
                          label: type.user.username,
                          id: type.user.id,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedPrinterUser}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedPrinterUser(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div style={{ width: "33%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Baskı Tipi Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={baskiTypes.map((type) => {
                        return {
                          value: type.printing_type_title,
                          label: type.printing_type_title,
                          id: type.id,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedBaskiType}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedBaskiType(e);
                        setSelectedGramaj(null);
                        setSelectedSize(null);
                        setSelectedQuantity(null);
                        setSelectedDirection(null);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Gramaj Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.grammages.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedGramaj}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedGramaj(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Boyut Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.sizes.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedSize}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedSize(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Adet Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.quantities.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedQuantity}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedQuantity(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div style={{ width: "25%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Baskı Yönü Seçiniz
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={selectedBaskiData.directions.map((type) => {
                        return {
                          value: type,
                          label: type,
                        };
                      })}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedDirection}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedDirection(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div>
                <RegistrationField
                  fieldHeader={"Teslimat Adresi"}
                  fieldValue={cargoDeliveryForm?.cargo_delivery_address}
                  fieldName={"cargo_delivery_address"}
                  formData={cargoDeliveryForm}
                  setFormData={setCargoDeliveryForm}
                />
              </div>

              <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ width: "33%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    Yedikçe Kazan
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={cashbackArr}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedCashBack}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedCashkBack(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "antiquewhite",
                    borderRadius: 20,
                    padding: 10,
                    marginRight: 10,
                  }}
                >
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    İlk Sipariş Kupon
                  </span>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <RegistrationField
                        fieldHeader={""}
                        fieldValue={
                          firstOrderForm?.first_order_discount_min_price
                        }
                        fieldName={"first_order_discount_min_price"}
                        formData={firstOrderForm}
                        setFormData={setFirstOrderForm}
                      />
                    </div>

                    <span>ye</span>

                    <div style={{ marginLeft: 14 }}>
                      <RegistrationField
                        fieldHeader={""}
                        fieldValue={firstOrderForm?.first_order_discount_amount}
                        fieldName={"first_order_discount_amount"}
                        formData={firstOrderForm}
                        setFormData={setFirstOrderForm}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ width: "33%", marginBottom: 10 }}>
                  <span
                    style={{
                      color: "#293c51",
                      fontFamily: "segoeuiRg",
                      fontSize: 15,
                      fontWeight: 700,
                      marginLeft: 8,
                    }}
                  >
                    İndirim
                  </span>
                  <div style={{ marginRight: 14, marginTop: 10 }}>
                    <Select
                      options={discountArr}
                      styles={selectDefaultStyles}
                      isSearchable={true}
                      hoverColor={"black"}
                      menuColor={"#f4f4f4"}
                      placeholder={"Seçiniz"}
                      dynamiczIndex={3}
                      value={selectedDiscount}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        setSelectedDiscount(e);
                      }}
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{e.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  fontFamily: "segoeuiB",
                  fontWeight: 700,
                  backgroundColor: "#0FBD20",
                  width: 200,
                }}
                onClick={handleSaveBaskiRequest}
              >
                Kaydet
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    width: "70%",
  },
};
