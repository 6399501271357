import { Box, Button, Modal } from "@mui/material";
import { useContext } from "react";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { useParams } from "react-router-dom";

export default function AllowPhotoModal({
  openAllowPhotoModal,
  setOpenAllowPhotoModal,
  setProductPhotoModal,
}) {
  const { resChangeDetailFunc } = useContext(RestaurantContext);
  const { id } = useParams();
  return (
    <Modal
      open={openAllowPhotoModal}
      onClose={() => {
        setOpenAllowPhotoModal(false);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
          maxHeight: 550,
          overflow: "auto",
        }}
      >
        <h2
          id="child-modal-title"
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          İŞLETMENİN KENDİ ÖZEL GÖRSELLERİ MEVCUTMU
        </h2>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "red",
              marginRight: 50,
              width: 200,
            }}
            onClick={() => {
              setOpenAllowPhotoModal(false);
              setProductPhotoModal(true);
              resChangeDetailFunc({
                restofficial_id: id,
                generic_product_pictures: false,
              });
            }}
          >
            Hayır
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
              width: 200,
            }}
            onClick={() => {
              setOpenAllowPhotoModal(false);
              setProductPhotoModal(true);

              resChangeDetailFunc({
                restofficial_id: id,
                generic_product_pictures: true,
              });
            }}
          >
            Evet
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
