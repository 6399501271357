import { SessionState } from "sip.js";
import { inviter } from "./makeCall";

function endCall() {
  switch (inviter.state) {
    case SessionState.Establishing:
      inviter.cancel().then(() => {
        // let data = {
        //   x_uuid: X_UUID,
        //   who_hanged_up: "agent",
        // };
        // cdrHangupFunc(data);
      });
      break;
    case SessionState.Initial:
      inviter.cancel().then(() => {
        // let data = {
        //   x_uuid: X_UUID,
        //   who_hanged_up: "agent",
        // };
        // cdrHangupFunc(data);
      });
      break;
    case SessionState.Established:
      inviter.bye().then(() => {
        // let data = {
        //   x_uuid: X_UUID,
        //   who_hanged_up: "agent",
        // };
        // cdrHangupFunc(data);
      });
      break;
    default:
      console.log("Arama sonlandırılamaz. Geçerli bir durumda değil.");
  }
}

export { endCall };
