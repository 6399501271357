import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  CircularProgress,
  InputBase,
  Modal,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import { useToast } from "../../../components/snackbar";
import { DevelopmentContext } from "../../../contexts/developmentContext";
import FiberNew from "@mui/icons-material/FiberNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import DoneIcon from "@mui/icons-material/Done";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import AddTaskModal from "./AddTaskModal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Groups from "./group/Groups";
import { developmentService } from "../../../services/developmentService";
import FolderIcon from "@mui/icons-material/Folder";

const durationTypes = [
  {
    key: "day",
    value: "gün",
  },
  {
    key: "hour",
    value: "saat",
  },

  {
    key: "minute",
    value: "dakika",
  },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function TaskInfoModal({
  openTaskInfo,
  setOpenTaskInfo,
  taskDetail,
  taskGeneralStatus,
  selectedUser,
  setSelectedUser,
  taskStatuses,
  setShowProcessingDetail,
  developmentUserList,
}) {
  const { alert, success } = useToast();
  const classes = useStyles();
  const {
    changeTaskGeneralStatus,
    deleteUserFromTask,
    changeTaskStatus,
    deleteTask,
    deletePdfFromTask,
  } = useContext(DevelopmentContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [taskHeader, setTaskHeader] = useState(taskDetail.title);
  const [selectedDurationType, setSelectedDurationType] = useState(null);
  const [taskDuration, setTaskDuration] = useState(0);
  const [showTimeDuration, setShowTimeDuration] = useState(false);
  const [files, setFiles] = useState([]);
  const [openAddTask, setOpenAddTask] = useState(false);
  const [links, setLinks] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));
  const [openGroups, setOpenGroups] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [fileObjects, setFileObjects] = useState([]);
  const [loaderFile, setLoaderFile] = useState(false);

  const handleSaveHeader = () => {
    const data = {
      title: taskHeader,
    };
    changeTaskGeneralStatus(taskDetail.id, data);

    setIsDisabled(true);
  };

  useEffect(() => {
    if (taskDetail.pdf_file) {
      setFiles(taskDetail.file);
    }

    if (taskDetail.links) {
      setLinks(taskDetail.links);
    }
  }, [taskDetail]);

  useEffect(() => {
    if (selectedUser) {
      if (selectedUser.status === "processing") {
        setShowTimeDuration(true);
        setTaskDuration(selectedUser.task_duration);
        setSelectedDurationType(() => {
          const x = durationTypes.find(
            (item) => item.value === selectedUser.task_duration_type
          );
          return x;
        });
      }
    }
  }, [selectedUser]);

  const handleSaveProcessingStatus = () => {
    if (!selectedDurationType || !taskDuration) {
      alert("Süre tipi seçiniz");
      return;
    }
    const data = {
      status: "processing",
      task_duration: taskDuration,
      task_duration_type: selectedDurationType.key,
    };

    changeTaskStatus(selectedUser.id, data, taskDetail.id);
  };

  const handleAddFile = async () => {
    await developmentService.get_file_url().then((response) => {
      setFileUrl(response?.url);
      setObjectName(response?.object_name);
    });
    setFiles([...files, { file: null }]);
  };

  const handleAddLink = () => {
    const linksCopy = [...links];
    linksCopy.push("");
    setLinks(linksCopy);
  };

  const onChange = async (e, i) => {
    setLoaderFile(true);
    const filesCopy = [...files];
    filesCopy[i] = e.target.files[0];

    const res = await fetch(fileUrl, {
      method: "PUT",
      headers: {
        "x-amz-acl": "public-read",
      },
      body: e.target.files[0],
    });

    if (res.status === 200) {
      setLoaderFile(false);
      setFileObjects([...fileObjects, objectName]);
    }
  };

  const handleSavePdfAndLinks = () => {
    const formData = {};

    const newFiles = fileObjects.filter((item) => !item.id);
    formData.file_addresses = newFiles;

    formData.links = links;

    changeTaskGeneralStatus(taskDetail.id, formData);
    setOpenTaskInfo(false);
    setFileObjects([]);
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openTaskInfo}
      className={classes.modal}
      onClose={() => {
        setOpenTaskInfo(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "50%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            padding: 15,
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
          }}
        >
          {userInfo?.development_task_authority === "confirm_process" && (
            <div style={{ display: "flex", marginBottom: 10 }}>
              <div
                style={{
                  display: "flex",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginBottom: 10,
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Task Status
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {taskGeneralStatus?.map((status, i) => {
                      if (
                        status.key === "completed" ||
                        status.key === "closed" ||
                        status.key === "processing"
                      ) {
                        return (
                          <Tooltip title={status.value} key={i}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const data = {
                                  status: status.key,
                                };

                                if (taskDetail?.status === status.key)
                                  return alert("Bu status zaten seçili");

                                changeTaskGeneralStatus(taskDetail.id, data);

                                setShowProcessingDetail(false);
                                setOpenTaskInfo(false);
                              }}
                            >
                              {status.key === "completed" ? (
                                <DoneIcon
                                  style={{
                                    color:
                                      taskDetail?.status === status.key
                                        ? taskDetail.color
                                        : "gray",
                                  }}
                                />
                              ) : status.key === "closed" ? (
                                <DoNotDisturbIcon
                                  style={{
                                    color:
                                      taskDetail?.status === status.key
                                        ? taskDetail.color
                                        : "gray",
                                  }}
                                />
                              ) : status.key === "processing" ? (
                                <TimelapseIcon
                                  style={{
                                    color:
                                      taskDetail?.status === status.key
                                        ? taskDetail.color
                                        : "gray",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </Tooltip>
                        );
                      }
                    })}
                  </div>

                  <Tooltip title="Task Sil">
                    <DeleteForeverIcon
                      style={{
                        color: "gray",
                        cursor: "pointer",
                        fontSize: 32,
                        marginTop: 10,
                      }}
                      onClick={() => {
                        deleteTask(taskDetail.id, "task");
                        setShowProcessingDetail(false);
                        setOpenTaskInfo(false);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>

              <div
                style={{
                  padding: 15,
                  borderRadius: 8,
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
                  width: "100%",
                  marginLeft: 20,
                }}
              >
                <div style={{ marginBottom: 20 }}>
                  <span
                    style={{
                      fontSize: 16,
                      marginBottom: 5,
                      display: "block",
                      fontWeight: "600",
                    }}
                  >
                    Başlık
                  </span>
                  <InputBase
                    disabled={isDisabled}
                    style={{
                      background: "#f4f4f4",
                      padding: 6,
                      fontSize: 16,
                      fontWeight: "400",
                      height: 36,
                      fontFamily: "segoeuiRg",
                      width: "100%",
                    }}
                    value={taskHeader}
                    onChange={(e) => {
                      setTaskHeader(e.target.value);
                    }}
                  />
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {isDisabled ? (
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        fontFamily: "segoeuiB",
                        width: 160,
                        fontWeight: 700,
                        backgroundColor: "orange",
                      }}
                      onClick={() => {
                        setIsDisabled(false);
                      }}
                    >
                      Düzenle
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          fontFamily: "segoeuiB",
                          width: 160,
                          fontWeight: 700,
                          backgroundColor: "red",
                          marginRight: 20,
                        }}
                        onClick={() => {
                          setIsDisabled(true);
                          setTaskHeader(taskDetail.title);
                        }}
                      >
                        Vazgeç
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          fontFamily: "segoeuiB",
                          width: 160,
                          fontWeight: 700,
                          backgroundColor: "#0FBD20",
                        }}
                        onClick={handleSaveHeader}
                      >
                        Kaydet
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div style={{ display: "flex" }}>
            {selectedUser && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
                  alignItems: "center",
                  position: "relative",
                  padding: 10,
                  marginRight: 20,
                  width: "50%",
                }}
              >
                <Tooltip title="Taskı bırak">
                  <RemoveCircleOutlineIcon
                    style={{
                      color: taskDetail.color,
                      cursor: "pointer",
                      position: "absolute",
                      left: 10,
                      top: 10,
                    }}
                    onClick={() => {
                      deleteUserFromTask(selectedUser.id, taskDetail.id);
                      setSelectedUser(null);
                    }}
                  />
                </Tooltip>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginBottom: 10,
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Güncel Status
                </div>

                <div>
                  <div
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      width: "fit-content",
                      margin: "auto",
                    }}
                  >
                    <img
                      style={{ width: 60, height: 60, borderRadius: 9999 }}
                      src={selectedUser?.officeuser.picture}
                    />

                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                      }}
                    >
                      {selectedUser?.status === "new" ? (
                        <FiberNew style={{ color: taskDetail.color }} />
                      ) : selectedUser?.status === "seen" ? (
                        <VisibilityIcon style={{ color: taskDetail.color }} />
                      ) : selectedUser?.status === "processing" ? (
                        <TimelapseIcon style={{ color: taskDetail.color }} />
                      ) : selectedUser?.status === "completed" ? (
                        <DoneIcon style={{ color: taskDetail.color }} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {taskStatuses.map((status, i) => {
                    return (
                      <Tooltip
                        title={
                          status.key === "new" ? "Yeni Task" : status.value
                        }
                        key={i}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const data = {
                              status: status.key,
                            };

                            if (selectedUser?.status === status.key)
                              return alert("Bu status zaten seçili");

                            if (status.key === "processing") {
                              setShowTimeDuration(true);
                              return;
                            }

                            setShowTimeDuration(false);
                            setSelectedDurationType(null);
                            setTaskDuration(0);

                            changeTaskStatus(
                              selectedUser.id,
                              data,
                              taskDetail.id
                            );
                          }}
                        >
                          {status.key === "new" ? (
                            <FiberNew
                              style={{
                                color:
                                  selectedUser?.status === status.key
                                    ? taskDetail.color
                                    : "gray",
                              }}
                            />
                          ) : status.key === "seen" ? (
                            <VisibilityIcon
                              style={{
                                color:
                                  selectedUser?.status === status.key
                                    ? taskDetail.color
                                    : "gray",
                              }}
                            />
                          ) : status.key === "processing" ? (
                            <TimelapseIcon
                              style={{
                                color:
                                  selectedUser?.status === status.key
                                    ? taskDetail.color
                                    : "gray",
                              }}
                            />
                          ) : status.key === "completed" ? (
                            <DoneIcon
                              style={{
                                color:
                                  selectedUser?.status === status.key
                                    ? taskDetail.color
                                    : "gray",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>

                {(showTimeDuration || selectedUser.status === "processing") && (
                  <div
                    style={{
                      position: "relative",
                      padding: 5,
                      background: "#ebebeb",
                      borderRadius: 4,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CancelIcon
                      style={{
                        color: "#f53b62",
                        position: "absolute",
                        left: 10,
                        top: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowTimeDuration(false);
                        setSelectedDurationType(null);
                        setTaskDuration(0);
                      }}
                    />
                    <span
                      style={{
                        fontSize: 16,
                        marginBottom: 5,
                        display: "block",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Süre Seç
                    </span>

                    <div
                      style={{
                        display: "flex",
                        marginBottom: 5,
                        alignItems: "center",
                      }}
                    >
                      <Select
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        responsiveHeight={44}
                        selectFontSize={16}
                        options={durationTypes}
                        styles={selectDefaultStyles}
                        isSearchable={false}
                        zIndex={90}
                        hoverColor={"#f53b62"}
                        menuColor={"#f53b62"}
                        placeholder={"Süre"}
                        value={selectedDurationType}
                        getOptionValue={(option) => option.key}
                        onChange={(e) => {
                          setSelectedDurationType(e);
                        }}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {e.value}
                          </div>
                        )}
                      />

                      <InputBase
                        style={{
                          background: "#f4f4f4",
                          padding: 6,
                          fontSize: 16,
                          fontWeight: "400",
                          height: 36,
                          fontFamily: "segoeuiRg",
                          width: 50,
                          marginLeft: 10,
                        }}
                        value={taskDuration}
                        onChange={(e) => {
                          setTaskDuration(e.target.value);
                        }}
                      />

                      <DoneIcon
                        onClick={handleSaveProcessingStatus}
                        style={{
                          fontFamily: "segoeuiRg",
                          fontWeight: 500,
                          border: "1px solid #f53b62",
                          textTransform: "none",
                          color: "#f53b62",
                          marginLeft: 10,
                          borderRadius: 9999,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <div
              style={{
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
                padding: 10,
                width: selectedUser ? "50%" : "100%",
              }}
            >
              <Button
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: taskDetail.color,
                  color: "#fff",
                  marginBottom: 20,
                  width: "100%",
                }}
                onClick={() => {
                  setOpenGroups(true);
                }}
              >
                <AddIcon />
                Gruba Ekle
              </Button>

              <Button
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: taskDetail.color,
                  color: "#fff",
                  marginBottom: 20,
                  width: "100%",
                }}
                onClick={() => {
                  setOpenAddTask(true);
                }}
              >
                <AddIcon />
                Kişiye Görev Ata
              </Button>
              <Button
                disabled={loaderFile}
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: taskDetail.color,
                  color: "#fff",
                  width: "100%",
                  marginBottom: 5,
                }}
                onClick={handleAddFile}
              >
                {loaderFile ? (
                  <CircularProgress
                    style={{ color: "#fff", width: 20, height: 20 }}
                  />
                ) : (
                  <>
                    <AddIcon />
                    Dosya Ekle
                  </>
                )}
              </Button>

              <div style={{ marginBottom: 20 }}>
                {files?.map((item, index) => {
                  if (item?.id) {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 5,
                          paddingBottom: 5,
                          borderBottom: "1px solid #f4f4f4",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {index + 1}-
                          <FolderIcon
                            style={{
                              color: "red",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              window.open(item.url, "_blank");
                            }}
                          />
                        </div>

                        <CancelIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            if (item?.id) {
                              deletePdfFromTask(taskDetail.id, item.id);
                            } else {
                              const pdfFilesCopy = [...files];
                              pdfFilesCopy.splice(index, 1);
                              setFiles(pdfFilesCopy);
                            }
                          }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: 10,
                        }}
                      >
                        <input
                          name="file"
                          type="file"
                          accept="image/jpeg,image/png,application/pdf,image/x-eps,video/mp4,video/x-m4v,video/*"
                          onChange={(e) => onChange(e, index)}
                        />

                        <CancelIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            const pdfFilesCopy = [...files];
                            pdfFilesCopy.splice(index, 1);
                            setFiles(pdfFilesCopy);
                          }}
                        />
                      </div>
                    );
                  }
                })}
              </div>

              <Button
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: taskDetail.color,
                  width: "100%",
                  color: "#fff",
                  marginBottom: 5,
                }}
                onClick={handleAddLink}
              >
                <AddIcon />
                Link Ekle
              </Button>

              <div style={{ marginBottom: 20 }}>
                {links?.map((link, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 5,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            marginBottom: 5,
                            display: "block",
                            fontWeight: "600",
                          }}
                        >
                          Link
                        </span>

                        <CancelIcon
                          style={{ color: taskDetail.color, cursor: "pointer" }}
                          onClick={() => {
                            const linksCopy = [...links];
                            linksCopy.splice(i, 1);
                            setLinks(linksCopy);
                          }}
                        />
                      </div>
                      <InputBase
                        style={{
                          background: "#f4f4f4",
                          padding: 6,
                          fontSize: 16,
                          fontWeight: "400",
                          height: 30,
                          fontFamily: "segoeuiRg",
                          width: "100%",
                        }}
                        value={link}
                        onChange={(e) => {
                          const linksCopy = [...links];
                          linksCopy[i] = e.target.value;
                          setLinks(linksCopy);
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: "segoeuiB",
                    width: "50%",
                    fontWeight: 700,
                    backgroundColor: "red",
                    marginRight: 20,
                  }}
                  onClick={() => {
                    setLinks([]);
                    setFiles([]);
                    setOpenTaskInfo(false);
                  }}
                >
                  Reddet
                </Button>

                <Button
                  disabled={loaderFile}
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: "segoeuiB",
                    width: "50%",
                    fontWeight: 700,
                    backgroundColor: "#0FBD20",
                  }}
                  onClick={handleSavePdfAndLinks}
                >
                  {loaderFile ? (
                    <CircularProgress
                      style={{ color: "#fff", width: 20, height: 20 }}
                    />
                  ) : (
                    "Onayla"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <AddTaskModal
          openAddTask={openAddTask}
          setOpenAddTask={setOpenAddTask}
          taskDetail={taskDetail}
          selectedUser={selectedUser}
          developmentUserList={developmentUserList}
        />

        <Groups
          openGroups={openGroups}
          setOpenGroups={setOpenGroups}
          taskDetail={taskDetail}
          setOpenTaskInfo={setOpenTaskInfo}
        />
      </Card>
    </Modal>
  );
}
