import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";

export default function CdrInboundModal({
  cdrInboundModal,
  setCdrInboundModal,
  startDate,
  endDate,
  userId,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { cdrInboundDurationListFunc, cdrInboundDurationsList } =
    useContext(ManagementContext);

  useEffect(() => {
    if (cdrInboundModal) {
      let data = {
        officeuser_id: userId,
        sendObj: {
          startDate: startDate,
          endDate: endDate,
        },
      };
      cdrInboundDurationListFunc(data);
    }
  }, [cdrInboundModal]);

  return (
    <Modal
      open={cdrInboundModal}
      onClose={() => {
        setCdrInboundModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Gelen Çağrı Uzunlukları
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "450px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Telefon No</TableCell>
                  <TableCell align="center">Toplam Süre</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cdrInboundDurationsList?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">{row?.phone}</TableCell>
                      <TableCell align="center">
                        {row?.total_duration_sum !== null
                          ? moment
                              .utc(row?.total_duration_sum * 1000)
                              .format("HH") +
                            "s " +
                            moment
                              .utc(row?.total_duration_sum * 1000)
                              .format("mm") +
                            "dk " +
                            moment
                              .utc(row?.total_duration_sum * 1000)
                              .format("ss") +
                            "sn"
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
