import {
  Box,
  Button,
  createTheme,
  Modal,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev2";

export default function CreateTicketModal({
  openTicketModal,
  setOpenTicketModal,
  selectedRest,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { getTicketTypes, ticketTypes, sendTicket } =
    useContext(RestaurantContext);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getTicketTypes("tech");
  }, []);

  const handleSaveTicket = (type) => {
    const officeuser = JSON.parse(localStorage.getItem("st-officeuser-info"));
    let data;
    if (type === "pool") {
      data = {
        restofficial: selectedRest.user_id,
        creation_note: message,
        ticket_type: selectedTicket.id,
      };
    } else {
      data = {
        processed_by: officeuser.user_id,
        restofficial: selectedRest.user_id,
        creation_note: message,
        ticket_type: selectedTicket.id,
      };
    }

    sendTicket(data, "modal");
    setOpenTicketModal(false);
    setMessage("");
    setSelectedTicket(null);
  };

  return (
    <Modal
      open={openTicketModal}
      onClose={() => {
        setOpenTicketModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Yeni Ticket Oluştur
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "600px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <Select
            menuPortalTarget={document.body}
            styles={{
              ...selectDefaultStyles,
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            options={ticketTypes}
            responsiveHeight={"auto"}
            selectFontSize={14}
            menuColor={"#f4f4f4"}
            hoverColor={"#f53b62"}
            isSearchable={false}
            selectWidth={"100%"}
            inputColor={"#f4f4f4"}
            textColor={"black"}
            placeHolderColor={"#f53b62"}
            inputFontSize={14}
            dropdownColor={"#f53b62"}
            isClearable={false}
            closeMenuOnSelect={true}
            placeholder="Ticket Türü"
            getOptionLabel={(option) => option.ticket_type}
            getOptionValue={(option) => option.id}
            value={selectedTicket}
            onChange={(e) => setSelectedTicket(e)}
          />

          <div style={{ marginTop: 20, fontWeight: 600 }}>Not</div>
          <textarea
            className="input"
            type="text"
            name="name"
            rows="2"
            value={message}
            spellCheck="false"
            style={{
              borderWidth: 0,
              borderColor: "#f4f4f4",
              fontSize: 14,
              fontWeight: 400,
              borderRadius: 12,
              padding: 20,
              margin: "5px 0 20px",
              background: "#dfdfdf",
            }}
            autoComplete="off"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            multiline="true"
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Button
              style={{
                backgroundColor: "#4CAF50",
                color: "white",
                padding: "5px 10px",
                fontSize: "14px",
                margin: "4px 2px",
                cursor: "pointer",
                width: "50%",
                marginRight: 50,
              }}
              onClick={() => handleSaveTicket("self")}
            >
              Self Ticket
            </Button>
            <Button
              style={{
                backgroundColor: "#4CAF50",
                color: "white",
                padding: "5px 10px",
                fontSize: "14px",
                margin: "4px 2px",
                width: "50%",
                cursor: "pointer",
              }}
              onClick={() => handleSaveTicket("pool")}
            >
              Teknik Havuza Gönder
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
