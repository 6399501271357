import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";

export default function CustomerOrders({
  openCustomerStatusModal,
  setOpenCustomerStatusModal,
  orders,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Modal
      open={openCustomerStatusModal}
      onClose={() => setOpenCustomerStatusModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "70%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Müşteri Siparişleri
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "500px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Tarih / Saat</TableCell>
                    <TableCell align="center">Restoran / Tabela İsmi</TableCell>
                    <TableCell align="center">Müşteri Adı</TableCell>
                    <TableCell align="center">Sipariş Tutarı</TableCell>
                    <TableCell align="center">Sipariş Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders?.map((res, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          {moment(res?.created_at).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {res?.restaurant_name}
                        </TableCell>
                        <TableCell align="center">
                          {res?.enduser_name}
                        </TableCell>
                        <TableCell align="center">{res?.amount}</TableCell>
                        <TableCell align="center">{res?.status}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
