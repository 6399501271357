import React, { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
//Components
/* import CustomerTabs from "./components/tabPanel"; */
import EnduserSearch from "./enduserSearch";
import CustomerTabs from "./tabPanel";

function Customers({ id }) {
  const {
    restaurantEnduserDetailFunc,
    enduserDetail,
    restaurantEnduserOrdersFunc,
    enduserOrders,
  } = useContext(RestaurantContext);
  const [userList, setUserList] = useState({
    full_name: null,
    user_id: null,
  });
  const [page, setPage] = useState(0);
  useEffect(() => {
    if (userList?.user_id != null) {
      restaurantEnduserDetailFunc(userList?.user_id, id);
      restaurantEnduserOrdersFunc({
        user_id: userList?.user_id,
        page: page + 1,
        restofficial_id: id,
      });
    }
  }, [userList, page]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        minHeight: 600,
        overflow: "auto",
      }}
    >
      <EnduserSearch setUserList={setUserList} userList={userList} id={id} />
      {userList?.user_id !== null && (
        <div style={{ width: "90%", marginTop: 10 }}>
          <CustomerTabs
            enduserDetail={enduserDetail}
            enduserOrders={enduserOrders}
            setPage={setPage}
            page={page}
            id={id}
          />
        </div>
      )}
    </div>
  );
}

export default Customers;
