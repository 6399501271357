import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../../contexts/managementContext";
import managementService from "../../../../services/managementService";
import { useToast } from "../../../../components/snackbar";
import SearchWithIcon from "../../../../components/searchWithIcon";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});
export default function CurrentList() {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { getCurrentList, currentList, accountingCurrentToKunyeFunc } =
    useContext(ManagementContext);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { success } = useToast();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getCurrentList(page + 1, rowsPerPage);
  }, [page]);

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          size="large"
          style={{
            fontFamily: "segoeuiB",
            fontWeight: 700,
            backgroundColor: "red",
          }}
          onClick={() => {
            managementService.create_current().then((res) => {
              success("İşlem başarılı");
              getCurrentList(page + 1);
            });
          }}
        >
          Toplu cari oluştur
        </Button>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SearchWithIcon
            setSearchTerm={setSearchTerm}
            placeTitle={"Cari Kodu"}
            customWidth={"40%"}
            searchTerm={searchTerm}
          />
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              accountingCurrentToKunyeFunc({ search: searchTerm });
            }}
          >
            Restorana Git
          </Button>
        </div>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">RestofficialId</TableCell>
              <TableCell align="center">Restoran Adı</TableCell>
              <TableCell align="center">Şirket Adı</TableCell>
              <TableCell align="center">Düzenle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentList?.results?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">{row.user_id}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.company_title}</TableCell>
                  <TableCell align="center">
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          window.open(
                            `${window.location.origin}/restaurants/${row?.user_id}`,
                            "_blank"
                          );
                        }
                      }}
                      onClick={() => {
                        window.open(
                          `${window.location.origin}/restaurants/${row?.user_id}`,
                          "_blank"
                        );
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {currentList != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={currentList?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
