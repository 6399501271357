import { Avatar, IconButton, Paper } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {
  Coffee,
  ConnectedTv,
  DescriptionTwoTone,
  Groups,
  Home,
  ImportContacts,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Opacity,
  PhoneForwarded,
  PowerOff,
  School,
  TagFaces,
} from "@mui/icons-material";
import moment from "moment";
import "moment/locale/tr";
import NewOrderTooltip from "../../../../components/tooltip";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 12,
    height: 12,
    borderRadius: 8,
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 2.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const MainTechScreen = ({
  setCallerFlipped,
  dashboardOfficeuserList,
  setDashboardUserInfo,
  setSelectedUser,
  stateHistoryPastListFunc,
  openToolTip,
  setOpenToolTip,
}) => {
  const calculatePassiveTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond =
        row?.state_info?.state_stats.break +
        row?.state_info?.state_stats.health +
        row?.state_info?.state_stats.meeting +
        row?.state_info?.state_stats.training +
        row?.state_info?.state_stats.wc;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };
  const calculatePassiveHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond =
        row?.state_info?.state_stats.break +
        row?.state_info?.state_stats.health +
        row?.state_info?.state_stats.meeting +
        row?.state_info?.state_stats.training +
        row?.state_info?.state_stats.wc;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const calculateOutCallTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.out_call;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };

  const calculateOutCallHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.out_call;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const calculateSetupTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.setup;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };

  const calculateSetupHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.setup;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const calculateDictionaryTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.dictionary;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };

  const calculateDictionaryHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.dictionary;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "99%", height: "98%" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {dashboardOfficeuserList &&
            dashboardOfficeuserList.map((row, i) => {
              return (
                <Paper
                  key={i}
                  elevation={3}
                  style={{
                    width: "22%",
                    display: "flex",
                    padding: 10,
                    margin: "10px 10px",
                    backgroundColor: "#393737",
                    boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "35%",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{ width: "auto" }}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        badgeContent={
                          row?.state === "active" ? (
                            <TagFaces
                              style={{
                                color: "green",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "out_call" ? (
                            <PhoneForwarded
                              style={{
                                color: "green",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "break" ? (
                            <Coffee
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "training" ? (
                            <School
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "wc" ? (
                            <Opacity
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "meeting" ? (
                            <Groups
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "passive" ? (
                            <PowerOff
                              style={{
                                color: "red",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "setup" ? (
                            <ConnectedTv
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "health" ? (
                            <Home
                              style={{
                                color: "red",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "dictionary" ? (
                            <ImportContacts
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : (
                            ""
                          )
                        }
                      >
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: row?.online ? "#44b700" : "red",
                              color: row?.online ? "#44b700" : "red",
                            },
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt="backoffice_user"
                            src={row?.picture}
                            sx={{ width: 90, height: 90, cursor: "pointer" }}
                            onClick={() => {
                              setDashboardUserInfo(row);
                              setCallerFlipped(true);
                              setSelectedUser(row);
                            }}
                          />
                        </StyledBadge>
                      </Badge>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 16,
                          fontWeight: 550,
                          color: "#fff",
                        }}
                      >
                        {row?.full_name}
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                            backgroundColor: "darkslategrey",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <IconButton
                            style={{
                              width: 13,
                              height: 13,
                              color:
                                moment().diff(row.current_date, "days") < 6
                                  ? "#fff"
                                  : "gray",
                            }}
                            disabled={
                              moment().diff(row.current_date, "days") < 6
                                ? false
                                : true
                            }
                            onClick={() => {
                              if (moment().diff(row.current_date, "days") < 6) {
                                row.current_date = moment(
                                  row.current_date
                                ).subtract(1, "days");
                                stateHistoryPastListFunc({
                                  sendingObj: {
                                    date: moment(row.current_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    officeuser: row?.user_id,
                                  },
                                });
                              }
                            }}
                          >
                            <KeyboardArrowLeft
                              style={{ width: 15, height: 15 }}
                            />
                          </IconButton>
                          <span>
                            {moment(row?.current_date).format("dddd")}
                          </span>
                          <IconButton
                            style={{
                              width: 13,
                              height: 13,
                              color:
                                moment().diff(row.current_date, "days") > 0
                                  ? "#fff"
                                  : "gray",
                            }}
                            disabled={
                              moment().diff(row.current_date, "days") > 0
                                ? false
                                : true
                            }
                            onClick={() => {
                              if (moment().diff(row.current_date, "days") > 0) {
                                row.current_date = moment(row.current_date).add(
                                  1,
                                  "days"
                                );

                                stateHistoryPastListFunc({
                                  sendingObj: {
                                    date: moment(row.current_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    officeuser: row?.user_id,
                                  },
                                });
                              }
                            }}
                          >
                            <KeyboardArrowRight
                              style={{ width: 15, height: 15 }}
                            />
                          </IconButton>
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        >
                          İş Baş.:{" "}
                          {row?.state_info?.active_date !== null
                            ? moment(row?.state_info?.active_date).format(
                                "HH:mm"
                              )
                            : "-"}
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        >
                          Aktif:{" "}
                          {moment
                            .utc(row?.state_info?.state_stats?.active * 1000)
                            .format("HH")}
                          s{" "}
                          {moment
                            .utc(row?.state_info?.state_stats?.active * 1000)
                            .format("mm")}
                          dk
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        >
                          Dış Ar.: {calculateOutCallHourTime(row)}s{" "}
                          {calculateOutCallTime(row)}dk
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        >
                          Kurulum: {calculateSetupHourTime(row)}s{" "}
                          {calculateSetupTime(row)}dk
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        >
                          Sözlük: {calculateDictionaryHourTime(row)}s{" "}
                          {calculateDictionaryTime(row)}dk
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                            display: "flex",
                          }}
                        >
                          <NewOrderTooltip
                            title={
                              <div
                                style={{
                                  overflow: "auto",
                                  padding: 2,
                                  fontSize: 12,
                                }}
                              >
                                <div>
                                  Mola :{" "}
                                  {moment
                                    .utc(
                                      row?.state_info?.state_stats.break * 1000
                                    )
                                    .format("HH")}
                                  s{" "}
                                  {moment
                                    .utc(
                                      row?.state_info?.state_stats.break * 1000
                                    )
                                    .format("mm")}
                                  dk{" "}
                                </div>
                                <div>
                                  Toplantı :{" "}
                                  {moment
                                    .utc(
                                      row?.state_info?.state_stats.meeting *
                                        1000
                                    )
                                    .format("HH")}
                                  s{" "}
                                  {moment
                                    .utc(
                                      row?.state_info?.state_stats.meeting *
                                        1000
                                    )
                                    .format("mm")}
                                  dk{" "}
                                </div>
                                <div>
                                  Eğitim :{" "}
                                  {moment
                                    .utc(
                                      row?.state_info?.state_stats.training *
                                        1000
                                    )
                                    .format("HH")}
                                  s{" "}
                                  {moment
                                    .utc(
                                      row?.state_info?.state_stats.training *
                                        1000
                                    )
                                    .format("mm")}
                                  dk{" "}
                                </div>
                                <div>
                                  Wc :{" "}
                                  {moment
                                    .utc(row?.state_info?.state_stats.wc * 1000)
                                    .format("HH")}
                                  s{" "}
                                  {moment
                                    .utc(row?.state_info?.state_stats.wc * 1000)
                                    .format("mm")}
                                  dk{" "}
                                </div>
                              </div>
                            }
                            placement="top"
                            open={
                              openToolTip == `open_${row?.user_id}`
                                ? true
                                : false
                            }
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontFamily: "SgNr",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                              onClick={(e) => {
                                if (openToolTip == `open_${row?.user_id}`) {
                                  setOpenToolTip(false);
                                } else {
                                  e.stopPropagation();
                                  setOpenToolTip(`open_${row?.user_id}`);
                                }
                              }}
                            >
                              <DescriptionTwoTone
                                style={{ width: 12, height: 12 }}
                              />
                            </div>
                          </NewOrderTooltip>
                          Pasif: {calculatePassiveHourTime(row)}s{" "}
                          {calculatePassiveTime(row)}dk
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        >
                          İş Bit.:{" "}
                          {row?.state_info?.inactive_date !== null
                            ? moment(row?.state_info?.inactive_date).format(
                                "HH:mm"
                              )
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "65%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <>
                      <div style={{ flex: 1 }}>
                        <div style={{ color: "#fff", fontFamily: "monospace" }}>
                          Aktif Task
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxHeight: 80,
                            overflow: "auto",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#f4f4f4",
                              padding: 3,
                              borderRadius: 4,
                              margin: 5,
                              fontSize: 13,
                              fontFamily: "monospace",
                              marginLeft: 10,
                              minWidth: 30,
                              textAlign: "center",
                            }}
                          >
                            {row?.active_tasks}
                          </div>
                        </div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div style={{ color: "#fff", fontFamily: "monospace" }}>
                          Takvimdeki Task
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxHeight: 80,
                            overflow: "auto",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#f4f4f4",
                              padding: 3,
                              borderRadius: 4,
                              margin: 5,
                              fontSize: 13,
                              fontFamily: "monospace",
                              marginLeft: 10,
                              minWidth: 30,
                              textAlign: "center",
                            }}
                          >
                            {row?.calendars}
                          </div>
                        </div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div style={{ color: "#fff", fontFamily: "monospace" }}>
                          Tamamlanan Task
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxHeight: 80,
                            overflow: "auto",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#f4f4f4",
                              padding: 3,
                              borderRadius: 4,
                              margin: 5,
                              fontSize: 13,
                              fontFamily: "monospace",
                              marginLeft: 10,
                              minWidth: 30,
                              textAlign: "center",
                            }}
                          >
                            {row?.completed_tasks}
                          </div>
                        </div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div style={{ color: "#fff", fontFamily: "monospace" }}>
                          Kaçan Restoran Teknik
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxHeight: 80,
                            overflow: "auto",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#f4f4f4",
                              padding: 3,
                              borderRadius: 4,
                              margin: 5,
                              fontSize: 13,
                              fontFamily: "monospace",
                              marginLeft: 10,
                              minWidth: 30,
                              textAlign: "center",
                            }}
                          >
                            {row?.ticket_bounce}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Paper>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MainTechScreen;
