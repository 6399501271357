import React, { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Modal,
} from "@mui/material";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    padding: 20,
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const DailyOrderModal = ({
  dailyOrderModal,
  setDailyOrderModal,
  currentRow,
  setCurrentRow,
}) => {
  const classes = useStyles();
  const { appointmentOrderListFunc, appointmentOrderList } =
    useContext(RestaurantContext);

  useEffect(() => {
    if (currentRow !== null) {
      appointmentOrderListFunc({ id: currentRow?.restofficial?.id });
    }
  }, [currentRow]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={dailyOrderModal}
      className={classes.modal}
      onClose={() => {
        setDailyOrderModal(false);
      }}
    >
      <Card
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          maxHeight: "50%",
          overflow: "auto",
          minHeight: "50%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TableContainer>
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 8,
                  lineHeight: 1,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tarih</TableCell>
                  <TableCell align="center">Sipariş Adeti</TableCell>
                  <TableCell align="center">Toplam Fiyat</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentOrderList?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {moment(row?.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row?.total_order}</TableCell>
                      <TableCell align="center">{row?.total_amount}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </Modal>
  );
};

export default DailyOrderModal;
