import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import UserStateHistory from "../../../../../components/modals/userStateHistory";
import moment from "moment";

export default function KpiStateHistories({ kpiStateHistory, workerSelect }) {
  const [showModal, setShowModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const handleShowUserHistory = (row) => {
    setUserInfo(row);
    setShowModal(true);
  };

  const calculateStartTime = (row) => {
    if (row?.histories?.length > 0) {
      let hasActiveItem = row?.histories?.find(
        (item) => item.state === "active"
      );

      if (hasActiveItem) {
        return moment(hasActiveItem.created_at).format("DD.MM.YYYY HH:mm:ss");
      } else {
        return "-";
      }
    }
  };

  const calculatePassiveTime = (row) => {
    if (row) {
      const totalPassiveSecond =
        row.state_stats.break +
        row.state_stats.health +
        row.state_stats.meeting +
        row.state_stats.training +
        row.state_stats.wc;

      return moment.utc(totalPassiveSecond * 1000).format("HH:mm:ss");
    }
  };

  return (
    <Box>
      <h3 style={{ textAlign: "center" }}>KPI KİŞİ GEÇMİŞ RAPORLARI</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Kişi
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Geçmiş Görüntüle
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                İşe Başlama Tarihi
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Toplam Aktif Süre
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Toplam Pasif Süre
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kpiStateHistory?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">{row?.full_name}</TableCell>
                  <TableCell align="center">
                    <InfoIcon
                      style={{ color: "#f35f56", cursor: "pointer" }}
                      onClick={() => handleShowUserHistory(row)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row?.histories?.length > 0 ? calculateStartTime(row) : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {Object.keys(row?.state_stats).length > 0
                      ? moment
                          .utc(row?.state_stats?.active * 1000)
                          .format("HH:mm:ss")
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {Object.keys(row?.state_stats).length > 0
                      ? calculatePassiveTime(row)
                      : "-"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <UserStateHistory
        showModal={showModal}
        setShowModal={setShowModal}
        userInfo={userInfo}
        calculateStartTime={calculateStartTime}
        calculatePassiveTime={calculatePassiveTime}
      />
    </Box>
  );
}
