import { Delete, DescriptionTwoTone } from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import React, { useState } from "react";
import { CustomerInput } from "../../../../components/CustomerInput";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";
import SecretKeyUpdateModal from "../../../../components/modals/secretKeyUpdate";

const Getir = ({
  secretKeyPlatformInfo,
  id,
  selectedSecretKeyPlatform,
  postSecretKey,
  scrollRefs,
  deleteRestofficialPlatforms,
  changeSecretKey,
}) => {
  const [newSecretKeyObj, setNewSecretKeyObj] = useState({
    secret_key: "",
  });

  const [secretKeyUpdateModal, setSecretKeyUpdateModal] = useState(false);
  const [currentSecretKeyRow, setCurrentSecretKeyRow] = useState(null);

  const newRegistrationKey = () => {
    let data = {
      platform: selectedSecretKeyPlatform,
      restofficial: id,
      secret_key: newSecretKeyObj?.secret_key,
    };
    postSecretKey(data, id);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Paper
          elevation={2}
          style={{ width: "50%", height: "auto", padding: 10 }}
        >
          <div
            style={{
              color: "#293c51",
              fontFamily: "segoeuiRg",
              fontSize: 20,
              fontWeight: 700,
              marginBottom: 5,
            }}
          >
            Yeni secret key ekle
          </div>

          <div>
            <div style={{ marginTop: 5 }}>
              <CustomerInput
                fieldHeader="Restoran Secret Key"
                fieldName="secret_key"
                formData={newSecretKeyObj}
                setFormData={setNewSecretKeyObj}
                fieldValue={newSecretKeyObj.secret_key}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "98%",
              alignSelf: "center",
              height: 2,
              backgroundColor: "#f53b62",
              margin: "15px 0px",
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: 14,
                fontWeight: 600,
                backgroundColor: "#f53b62",
                color: "#fff",
                margin: "0px 15px",
              }}
              onClick={() => {
                newRegistrationKey();
              }}
            >
              Başvur
            </Button>
          </div>
        </Paper>
        {secretKeyPlatformInfo?.map((row, index) => {
          return (
            <Paper
              key={index}
              ref={scrollRefs.current[index]}
              elevation={2}
              style={{
                width: "50%",
                height: "auto",
                padding: 10,
                marginTop: 20,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "#293c51",
                    fontFamily: "segoeuiRg",
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: 5,
                  }}
                >
                  {row?.restaurant_name}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DescriptionTwoTone
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCurrentSecretKeyRow(row);
                      setSecretKeyUpdateModal(true);
                    }}
                  />
                </div>
                <Delete
                  variant="Filled"
                  size="large"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    deleteRestofficialPlatforms(id, row.id, row?.platform?.id)
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomerInput
                  fieldHeader="Restoran Secret Key"
                  fieldName="secret_key"
                  formData={newSecretKeyObj}
                  setFormData={setNewSecretKeyObj}
                  fieldValue={row.secret_key}
                  disabledStatus={true}
                />
                <GreenSwitch
                  onClick={() => {
                    // setGlobalStatus("all");
                    if (!row?.is_active) {
                      let data = {
                        id: row.id,
                        sendObj: {
                          is_active: true,
                        },
                        r_id: row?.restofficial,
                        platform: row?.platform?.id,
                      };
                      changeSecretKey(data);
                    }
                  }}
                  checked={row?.is_active}
                />
              </div>
            </Paper>
          );
        })}
      </div>
      <SecretKeyUpdateModal
        secretKeyUpdateModal={secretKeyUpdateModal}
        setSecretKeyUpdateModal={setSecretKeyUpdateModal}
        currentSecretKeyRow={currentSecretKeyRow}
        setCurrentSecretKeyRow={setCurrentSecretKeyRow}
      />
    </>
  );
};

export default Getir;
