import { Button } from "@mui/material";
import React from "react";
import { Call, Close } from "@mui/icons-material";
import makeCall from "./makeCall";

let callkey;

const verimorCall = ({
  enqueueSnackbar,
  closeSnackbar,
  sendCallData,
  cdrOutboundFunc,
  cdrAnswerFunc,
  cdrHangupFunc,
  cdrMissedCountFunc,
  reasonPhrase,
  statusCode,
}) => {
  closeSnackbar(callkey);
  callkey = enqueueSnackbar(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: 20, fontFamily: "SgBd", color: "#fff" }}>
        {statusCode} |{" "}
        {statusCode === 486
          ? "Meşgul"
          : statusCode === 403
          ? "Arama Reddedildi"
          : statusCode === 408
          ? "Arama cevaplanmadı"
          : statusCode === 480
          ? "Aranan numara meşgul"
          : statusCode === 487
          ? "Çağrı sonlandı"
          : statusCode === 500
          ? "Kullanılmayan numara"
          : reasonPhrase}
        <br />
        {JSON.parse(localStorage.getItem("st-officeuser-info"))?.iqcell_verimor
          ? "Gsm"
          : "Verimor"}{" "}
        Üzerinden Ara?
      </div>
      <Button
        style={{
          color: "white",
          backgroundColor: "#f53b62",
          textTransform: "capitalize",
          fontFamily: "SgBd",
          fontSize: 14,
          marginLeft: 15,
        }}
        onClick={() => {
          sendCallData.callee = sendCallData.callee?.slice(-10);
          sendCallData.callee =
            (JSON.parse(localStorage.getItem("st-officeuser-info"))
              ?.iqcell_verimor
              ? "90"
              : "0") + sendCallData.callee;

          makeCall({
            enqueueSnackbar,
            closeSnackbar,
            sendCallData,
            cdrOutboundFunc,
            cdrAnswerFunc,
            cdrHangupFunc,
            cdrMissedCountFunc,
          });
        }}
      >
        <Call />
      </Button>
      <Button
        style={{
          color: "white",
          backgroundColor: "#f53b62",
          textTransform: "capitalize",
          fontFamily: "SgBd",
          fontSize: 14,
          marginLeft: 15,
        }}
        onClick={() => {
          closeSnackbar(callkey);
        }}
      >
        <Close />
      </Button>
    </div>,
    {
      preventDuplicate: true,
      persist: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      sx: {
        "& .SnackbarContent-root": {
          backgroundColor: "red",
        },
      },
    }
  );
};

export default verimorCall;
