import { useContext, useState } from "react";
import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useHistory } from "react-router-dom/";
import { Title } from "@mui/icons-material";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";

export default function InfoModal({
  openInfoModal,
  setOpenInfoModal,
  headers,
  rows,
  setRows,
  title,
  pageInfo,
  setPageInfo,
  userInfo,
  setUserInfo,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPageInfo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageInfo(0);
  };

  return (
    <Modal
      open={openInfoModal}
      onClose={() => {
        setUserInfo(null);
        setOpenInfoModal(false);
        setPageInfo(0);
        setRows([]);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {title}
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "450px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headers?.map((header, ind) => {
                    return (
                      <TableCell key={ind} align="center">
                        {header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {userInfo?.results?.map((item, ind) => {
                  return (
                    <TableRow key={ind}>
                      {rows.map((row, i) => {
                        let date;
                        if (
                          row === "last_payed_at" ||
                          row === "created_at" ||
                          row === "blocked_at"
                        ) {
                          date = moment(item[row]).format("DD-MM-YYYY HH:mm");
                        }

                        let totalAmount;
                        if (row === "total_amount") {
                          totalAmount = item[row].toLocaleString("tr-TR", {
                            style: "currency",
                            currency: "TRY",
                          });
                        }

                        let products;
                        if (row === "product_permissions") {
                          products = item[row]?.map((name, index) => (
                            <span key={index}>
                              {name}
                              {index < item[row].length - 1 ? ", " : ""}
                            </span>
                          ));
                        }

                        let totalDate;
                        if (row === "total_date") {
                          totalDate = moment().diff(
                            moment(item["restofficial"].created_at),
                            "days"
                          );
                        }

                        let restId;
                        if (row === "rest_id") {
                          restId = item["restofficial"].user_id;
                        }

                        let resCreated;
                        if (row === "rest_created") {
                          resCreated = moment(
                            item["restofficial"].created_at
                          ).format("DD-MM-YYYY HH:mm");
                        }

                        let productsArr;
                        if (row === "products") {
                          productsArr = item[row]?.map((name, index) => (
                            <span key={index}>
                              {name.name}
                              {index < item[row].length - 1 ? ", " : ""}
                            </span>
                          ));
                        }

                        let remainingAmount;
                        if (row === "remaining_amount") {
                          remainingAmount =
                            item["total_amount"] - item["eft_amount"];
                        }

                        let passiveDays;
                        if (row === "passive_days") {
                          passiveDays = moment().diff(
                            moment(item["restofficial"].online_date),
                            "days"
                          );
                        }

                        return (
                          <TableCell
                            key={i}
                            align="center"
                            style={{
                              color:
                                row === "user_id" || row === "rest_id"
                                  ? "blue"
                                  : "black",
                              textDecoration:
                                row === "user_id" || row === "rest_id"
                                  ? "underline"
                                  : "none",
                            }}
                            onClick={() => {
                              if (
                                row === "user_id" ||
                                row === "rest_id" ||
                                row === "restofficial_id"
                              ) {
                                let id =
                                  row === "user_id"
                                    ? item.user_id
                                    : row === "rest_id"
                                    ? item["restofficial"].user_id
                                    : item["restofficial_id"];

                                window.open(`restaurants/${id}`, "_blank");
                              }
                            }}
                          >
                            {date
                              ? date
                              : products
                              ? products
                              : restId
                              ? restId
                              : resCreated
                              ? resCreated
                              : totalDate
                              ? totalDate
                              : productsArr
                              ? productsArr
                              : totalAmount
                              ? totalAmount
                              : remainingAmount
                              ? remainingAmount
                              : passiveDays
                              ? passiveDays
                              : item[row]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}

                <TableRow>
                  {userInfo?.results != null && (
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      labelRowsPerPage={"Sayfa Başına Kayıt"}
                      count={userInfo?.count}
                      rowsPerPage={rowsPerPage}
                      page={pageInfo}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
