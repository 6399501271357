import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";

export default function SetupCountModal({
  showModal,
  setShowModal,
  result,
  title,
  subTitle,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  function currencyFormatWithoutFixed(num) {
    return (
      "₺ " +
      num
        .toFixed(0)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <Box sx={{ ...styles.modal, width: matches ? "40%" : "90%" }}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "segoeuiB",
            marginBottom: 10,
            fontSize: 20,
            fontVariant: "all-petite-caps",
            letterSpacing: 1,
          }}
        >
          {title}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "80vh",
            minHeight: "40vh",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer>
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 30,
                  lineHeight: 0,
                },
                ".MuiTableCell-root td": {
                  color: "#fff",
                },
                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Kişi</TableCell>
                  <TableCell align="center">{subTitle}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      {row?.processed_by__full_name && (
                        <TableCell
                          align="center"
                          style={{ background: "none" }}
                        >
                          {row?.processed_by__full_name}
                        </TableCell>
                      )}

                      {row?.total_count && (
                        <TableCell
                          align="center"
                          style={{ background: "none" }}
                        >
                          {row?.total_count}
                        </TableCell>
                      )}

                      {row?.name && (
                        <TableCell
                          align="center"
                          style={{ background: "none" }}
                        >
                          {row?.name}
                        </TableCell>
                      )}

                      {row?.total_amount && (
                        <TableCell
                          align="center"
                          style={{ background: "none" }}
                        >
                          {currencyFormatWithoutFixed(row?.total_amount)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
