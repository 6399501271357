import React, { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import {
  Button,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import managementService from "../../../../services/managementService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";

export default function PrimSettingsV2() {
  const {
    getProductPrimSettings,
    selectedProductPrimSettings,
    setSelectedProductPrimSettings,
    sendPrimSettings,
    getExtraPrimSettings,
    extraPrimSettings,
    setExtraPrimSettings,
    addExtraPrimSettings,
  } = useContext(ManagementContext);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [blokePrimLimit, setBlokePrimLimit] = useState(0);

  useEffect(() => {
    (async () => {
      const res = await managementService.get_product_list();
      setProductsList(res);
    })();

    getExtraPrimSettings();
  }, []);

  useEffect(() => {
    if (selectedProductPrimSettings?.length === 0) {
      setSelectedProductPrimSettings([
        {
          prim_percent_range: [],
          contract_renewal_percent_range: [],
        },
      ]);
    }
  }, [selectedProductPrimSettings]);

  useEffect(() => {
    if (extraPrimSettings) {
      setBlokePrimLimit(extraPrimSettings.bloke_prim_limit);
    }
  }, [extraPrimSettings]);

  const handleAddNewPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = [...selectedProductPrimSettings];
    newPrimSettings[0].prim_percent_range.push(newObj);
    setSelectedProductPrimSettings(newPrimSettings);
  };

  const handleAddNewRenewalPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = [...selectedProductPrimSettings];
    newPrimSettings[0].contract_renewal_percent_range.push(newObj);
    setSelectedProductPrimSettings(newPrimSettings);
  };

  const handleAddNewExtraPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = { ...extraPrimSettings };
    newPrimSettings.extra_prim.push(newObj);
    setExtraPrimSettings(newPrimSettings);
  };

  const handleAddNewSetupPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = { ...extraPrimSettings };
    newPrimSettings.setup_prim.push(newObj);
    setExtraPrimSettings(newPrimSettings);
  };

  const handleSavePrimSettings = () => {
    let data = {
      product: selectedProduct.id,
      prim_percent_range: selectedProductPrimSettings[0].prim_percent_range,
      contract_renewal_percent_range:
        selectedProductPrimSettings[0].contract_renewal_percent_range,
      officeusers: [],
    };
    if (selectedProductPrimSettings[0].id) {
      data = {
        ...data,
        id: selectedProductPrimSettings[0].id,
      };
    }
    sendPrimSettings(data);
  };

  const handleSaveExtraPrimSettings = () => {
    const data = {
      bloke_prim: extraPrimSettings.bloke_prim
        ? extraPrimSettings.bloke_prim
        : false,
      bloke_prim_limit: blokePrimLimit ? blokePrimLimit : 0,
      extra_prim: extraPrimSettings.extra_prim
        ? extraPrimSettings.extra_prim
        : [],
      setup_prim: extraPrimSettings.setup_prim
        ? extraPrimSettings.setup_prim
        : [],
    };
    addExtraPrimSettings(data);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          background: "#efefef",
          padding: 10,
          borderRadius: 4,
        }}
      >
        <div style={{ width: "50%", marginRight: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Ekstra Primler</h3>
            <Tooltip title="Ekstra Prim Ekle">
              <AddCircleOutlineIcon
                style={{ color: "#f53b62", fontSize: 34, cursor: "pointer" }}
                onClick={handleAddNewExtraPrim}
              />
            </Tooltip>
          </div>

          <div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Başlangıç</TableCell>
                    <TableCell align="center">Bitiş</TableCell>
                    <TableCell align="center">Prim %</TableCell>
                    <TableCell align="center">Aksiyon</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraPrimSettings?.extra_prim?.map((prim, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          <InputBase
                            variant="filled"
                            type="number"
                            style={{
                              width: 90,
                              color: "black",
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 40,
                              backgroundColor: "#f4f4f4",
                              textAlignLast: "center",
                            }}
                            value={prim.start}
                            onChange={(e) => {
                              const newPrimSettings = { ...extraPrimSettings };
                              newPrimSettings.extra_prim[ind].start =
                                e.target.value;
                              setExtraPrimSettings(newPrimSettings);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <InputBase
                            variant="filled"
                            type="number"
                            style={{
                              width: 90,
                              color: "black",
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 40,
                              backgroundColor: "#f4f4f4",
                              textAlignLast: "center",
                            }}
                            value={prim.end}
                            onChange={(e) => {
                              const newPrimSettings = { ...extraPrimSettings };
                              newPrimSettings.extra_prim[ind].end =
                                e.target.value;
                              setExtraPrimSettings(newPrimSettings);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <InputBase
                            variant="filled"
                            type="number"
                            style={{
                              width: 90,
                              color: "black",
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 40,
                              backgroundColor: "#f4f4f4",
                              textAlignLast: "center",
                            }}
                            value={prim.prim}
                            onChange={(e) => {
                              const newPrimSettings = { ...extraPrimSettings };
                              newPrimSettings.extra_prim[ind].prim =
                                e.target.value;
                              setExtraPrimSettings(newPrimSettings);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Sil">
                            <DeleteForeverIcon
                              style={{
                                color: "#f53b62",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const newPrimSettings = {
                                  ...extraPrimSettings,
                                };
                                newPrimSettings.extra_prim.splice(ind, 1);
                                setExtraPrimSettings(newPrimSettings);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div style={{ width: "50%", marginRight: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Kurulum Primler</h3>
            <Tooltip title="Kurulum Prim Ekle">
              <AddCircleOutlineIcon
                style={{ color: "#f53b62", fontSize: 34, cursor: "pointer" }}
                onClick={handleAddNewSetupPrim}
              />
            </Tooltip>
          </div>

          <div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Başlangıç</TableCell>
                    <TableCell align="center">Bitiş</TableCell>
                    <TableCell align="center">Prim %</TableCell>
                    <TableCell align="center">Aksiyon</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraPrimSettings?.setup_prim?.map((prim, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          <InputBase
                            variant="filled"
                            type="number"
                            style={{
                              width: 90,
                              color: "black",
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 40,
                              backgroundColor: "#f4f4f4",
                              textAlignLast: "center",
                            }}
                            value={prim.start}
                            onChange={(e) => {
                              const newPrimSettings = { ...extraPrimSettings };
                              newPrimSettings.setup_prim[ind].start =
                                e.target.value;
                              setExtraPrimSettings(newPrimSettings);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <InputBase
                            variant="filled"
                            type="number"
                            style={{
                              width: 90,
                              color: "black",
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 40,
                              backgroundColor: "#f4f4f4",
                              textAlignLast: "center",
                            }}
                            value={prim.end}
                            onChange={(e) => {
                              const newPrimSettings = { ...extraPrimSettings };
                              newPrimSettings.setup_prim[ind].end =
                                e.target.value;
                              setExtraPrimSettings(newPrimSettings);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <InputBase
                            variant="filled"
                            type="number"
                            style={{
                              width: 90,
                              color: "black",
                              fontSize: 15,
                              fontWeight: "400",
                              fontFamily: "segoeuiRg",
                              height: 40,
                              backgroundColor: "#f4f4f4",
                              textAlignLast: "center",
                            }}
                            value={prim.prim}
                            onChange={(e) => {
                              const newPrimSettings = { ...extraPrimSettings };
                              newPrimSettings.setup_prim[ind].prim =
                                e.target.value;
                              setExtraPrimSettings(newPrimSettings);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Sil">
                            <DeleteForeverIcon
                              style={{
                                color: "#f53b62",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const newPrimSettings = {
                                  ...extraPrimSettings,
                                };
                                newPrimSettings.setup_prim.splice(ind, 1);
                                setExtraPrimSettings(newPrimSettings);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3>Prim Bloklama</h3>
              <GreenSwitch
                onClick={() => {
                  const data = {
                    ...extraPrimSettings,
                    bloke_prim: !extraPrimSettings.bloke_prim,
                  };
                  addExtraPrimSettings(data);
                }}
                checked={extraPrimSettings?.bloke_prim}
              />
            </div>
            <div>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 5,
                  fontWeight: 600,
                }}
              >
                Prim Bloklama Limiti
              </span>
            </div>
            <InputBase
              disabled={!extraPrimSettings?.bloke_prim}
              variant="filled"
              type="number"
              style={{
                width: 150,
                color: "black",
                fontSize: 15,
                fontWeight: "400",
                fontFamily: "segoeuiRg",
                height: 40,
                backgroundColor: "#fff",
                padding: 5,
              }}
              value={blokePrimLimit}
              onChange={(e) => {
                setBlokePrimLimit(e.target.value);
              }}
            />
          </div>

          <Button
            color="success"
            variant="contained"
            size="large"
            style={{
              alignSelf: "end",
              width: "100%",
              marginTop: 20,
            }}
            onClick={handleSaveExtraPrimSettings}
          >
            Kaydet
          </Button>
        </div>
      </div>

      <h2
        style={{
          textAlign: "center",
          marginTop: 20,
          paddingTop: 20,
          borderTop: "1px solid rgba(233,233,233)",
        }}
      >
        Ürünler
      </h2>

      <div style={{ display: "flex", overflow: "auto", paddingBottom: 10 }}>
        {productsList?.map((product) => {
          return (
            <Tooltip title={product.name} key={product.id}>
              <Paper
                elevation={2}
                style={{
                  cursor: "pointer",
                  color: selectedProduct?.id === product.id ? "white" : "black",
                  fontFamily: "segoeuiRg",
                  background:
                    selectedProduct?.id === product.id ? "#f53b62" : "",
                  padding: 5,
                  marginRight: 20,
                  width: 200,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  minWidth: 100,
                  textAlign: "center",
                }}
                onClick={() => {
                  getProductPrimSettings(product.id);
                  setSelectedProduct(product);
                }}
              >
                {product.name}
              </Paper>
            </Tooltip>
          );
        })}
      </div>

      {selectedProductPrimSettings && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <div
            style={{
              width: "50%",
              paddingRight: 20,
              marginRight: 20,
              borderRight: "1px solid rgba(200,200,200,0.1)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>
                {selectedProduct?.name} ({selectedProduct?.price} ₺) Satış
                Primleri
              </h3>
              <Tooltip title="Yeni Prim Ekle">
                <AddCircleOutlineIcon
                  style={{ color: "#f53b62", fontSize: 34, cursor: "pointer" }}
                  onClick={handleAddNewPrim}
                />
              </Tooltip>
            </div>

            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Min İndirim Oranı</TableCell>
                      <TableCell align="center">Max İndirim Oranı</TableCell>
                      <TableCell align="center">Prim %</TableCell>
                      <TableCell align="center">Aksiyon</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedProductPrimSettings[0]?.prim_percent_range?.map(
                      (prim, ind) => {
                        return (
                          <TableRow key={ind}>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.start}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedProductPrimSettings,
                                  ];
                                  newPrimSettings[0].prim_percent_range[
                                    ind
                                  ].start = e.target.value;
                                  setSelectedProductPrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.end}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedProductPrimSettings,
                                  ];
                                  newPrimSettings[0].prim_percent_range[
                                    ind
                                  ].end = e.target.value;
                                  setSelectedProductPrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.prim}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedProductPrimSettings,
                                  ];
                                  newPrimSettings[0].prim_percent_range[
                                    ind
                                  ].prim = e.target.value;
                                  setSelectedProductPrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Sil">
                                <DeleteForeverIcon
                                  style={{
                                    color: "#f53b62",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].prim_percent_range.splice(
                                      ind,
                                      1
                                    );
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div style={{ width: "50%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Sözleşme Uzatma Primleri</h3>
              <Tooltip title="Yeni Prim Ekle">
                <AddCircleOutlineIcon
                  style={{ color: "#f53b62", fontSize: 34, cursor: "pointer" }}
                  onClick={handleAddNewRenewalPrim}
                />
              </Tooltip>
            </div>

            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Min Zam Oranı</TableCell>
                      <TableCell align="center">Max Zam Oranı</TableCell>
                      <TableCell align="center">Prim %</TableCell>
                      <TableCell align="center">Aksiyon</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedProductPrimSettings[0]?.contract_renewal_percent_range?.map(
                      (prim, ind) => {
                        return (
                          <TableRow key={ind}>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.start}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedProductPrimSettings,
                                  ];
                                  newPrimSettings[0].contract_renewal_percent_range[
                                    ind
                                  ].start = e.target.value;
                                  setSelectedProductPrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.end}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedProductPrimSettings,
                                  ];
                                  newPrimSettings[0].contract_renewal_percent_range[
                                    ind
                                  ].end = e.target.value;
                                  setSelectedProductPrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.prim}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedProductPrimSettings,
                                  ];
                                  newPrimSettings[0].contract_renewal_percent_range[
                                    ind
                                  ].prim = e.target.value;
                                  setSelectedProductPrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Sil">
                                <DeleteForeverIcon
                                  style={{
                                    color: "#f53b62",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].contract_renewal_percent_range.splice(
                                      ind,
                                      1
                                    );
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  color="success"
                  variant="contained"
                  size="large"
                  style={{
                    alignSelf: "end",
                  }}
                  onClick={handleSavePrimSettings}
                >
                  Kaydet
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
