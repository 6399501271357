import React, { useContext } from "react";
import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import managementService from "../../../services/managementService";
import { ManagementContext } from "../../../contexts/managementContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const AcceptPaymentModal = ({
  acceptPaymentOpen,
  setAcceptPaymentOpen,
  person,
}) => {
  const classes = useStyles();
  const { getPaidableUsers } = useContext(ManagementContext);

  const handleApprovePayment = async () => {
    setAcceptPaymentOpen(false);
    await managementService.paidable_prim_approve(person.id);
    getPaidableUsers();
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={acceptPaymentOpen}
      className={classes.modal}
      onClose={() => {
        setAcceptPaymentOpen(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          padding: 30,
        }}
      >
        <div style={{ fontFamily: "segoeuiB", fontSize: 22 }}>
          {" "}
          Ödemeyi tamamlamak istediğinizden emin misiniz?
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 100,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              marginRight: 30,
              height: 40,
              textTransform: "capitalize",
            }}
            size="small"
            onClick={() => {
              setAcceptPaymentOpen(false);
            }}
          >
            Kapat
          </Button>
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 100,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              marginRight: 30,
              height: 40,
              textTransform: "capitalize",
            }}
            size="small"
            onClick={handleApprovePayment}
          >
            Evet
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default AcceptPaymentModal;
