import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Drawer, ListItem } from "@mui/material";

import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    width: "50%",
    minWidth: 260,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 12,
    fontFamily: "segoeuiRg",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "#fafafa",
    color: "#f53f62",
  },
  mainItem: {
    display: "flex",
    color: "#000",
    fontWeight: "600px",
    fontStyle: "bold",
    fontFamily: "segoeuiB",
  },
}));

const MobilMainSidebar = ({
  openDrawer,
  setOpenDrawer,
  currentIndex,
  setCurrentIndex,
  sidebarData = [],
}) => {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List component="nav" className={classes.root}>
          {sidebarData.map((item, idx) => {
            return (
              <ListItem
                className={classes.mainItem}
                onClick={() => {
                  const res_id =
                    id != null ? `${item.path}/${id}` : `${item.path}`;
                  setCurrentIndex(item.id);
                  history.push(res_id);

                  setOpenDrawer(false);
                }}
                key={idx}
                sx={{
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  minHeight: 32,
                  backgroundColor: "white",
                  width: "85%",
                  borderRadius: 8,
                  color: currentIndex == idx ? "#f53f62" : "black",
                }}
              >
                <ListItemText
                  primary={item.label}
                  style={{
                    fontSize: 15,
                    fontWeight: "medium",
                    fontFamily: "segoeuiRg",
                    color: currentIndex == idx ? "#f53f62" : "black",
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default MobilMainSidebar;
