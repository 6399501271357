import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  createRef,
} from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Paper,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pink } from "@mui/material/colors";
import "../../../components/tableCss/table.css";
import { platformImageConverterBig } from "../../../components/converter/platformImage";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import CustomerSearch from "../../../components/search";
import ProductModal from "../../../components/modals/productModal";
import { GreenSwitch } from "../../../components/greenSwitch";
import OverflowTip from "../../../components/overflowtip";
import { useHorizontalScroll } from "./scroll/horizontalScroll";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      fontFamily: "segoeuiRg",
    },
  },
});

const Menu = () => {
  const classes = useStyles();
  let { id } = useParams();
  const {
    platformList,
    getPlatformList,

    getRestaurantInfo,
    restOfficialData,
    getRestaurantMenuList,
    restaurantMenuList,

    postRestaurantMenuProductStatu,

    setCurrentIndex,
  } = useContext(RestaurantContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [menuLoading, setMenuLoading] = useState(false);
  const [menuListObj, setMenuListObj] = useState([]);
  const scrollRefs = useRef([]);
  const scrollRef = useHorizontalScroll();
  const [selectedHeader, setSelectedHeader] = useState("header_0");
  const [bulkDisable, setBulkDisable] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [productModalType, setProductTypeModal] = useState(null);

  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [platformRes, setPlatformRes] = useState([]);
  const [selectedPlatformRes, setSelectedPlatformRes] = useState(null);

  scrollRefs.current = [...Array(menuListObj?.length).keys()].map(
    (_, i) => scrollRefs.current[i] ?? createRef()
  );

  const scrollSmoothHandler = (index) => () => {
    setSelectedHeader(`header_${index}`);
    scrollRefs.current[index].current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getRestaurantInfo(id);
    getPlatformList();
    setCurrentIndex("menu");
  }, []);

  useEffect(() => {
    if (selectedPlatform != null) {
      let res_test = [];

      restOfficialData?.platforms?.length != 0 &&
        restOfficialData?.platforms
          ?.filter((info) => info.platform?.id == selectedPlatform?.id)
          ?.map((row, ind) => {
            res_test.push(row);
          });
      setPlatformRes(res_test);
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (platformRes?.length != 0) {
      setSelectedPlatformRes(platformRes[0]);
    }
  }, [platformRes]);

  useEffect(() => {
    if (restaurantMenuList.length > 0) {
      if (searchTerm != "") {
        let newObj = [];
        restaurantMenuList?.map((row) => {
          return row?.products
            ?.filter((list) =>
              list.name
                .toLocaleLowerCase("tr")
                .includes(searchTerm.toLocaleLowerCase("tr"))
            )
            .map((item) => {
              const index = newObj.findIndex((item) => item.name == row?.name);

              if (index == -1) {
                return item.length != 0 && newObj.push(row);
              }
            });
        });

        setMenuListObj(newObj);
      } else {
        setMenuListObj(restaurantMenuList);
      }
    }
  }, [restaurantMenuList, searchTerm]);

  useEffect(() => {
    if (selectedPlatformRes != null) {
      getRestaurantMenuList(id, selectedPlatformRes?.id);
    }
  }, [selectedPlatformRes]);
  useEffect(() => {
    if (restaurantMenuList.length > 0) {
      setBulkDisable(false);
    }
  }, [restaurantMenuList]);

  const productStatusChange = (data, prod) => {
    postRestaurantMenuProductStatu(id, {
      prod_id: prod?.id,
      prod_name: prod?.name,
      platform: selectedPlatform?.name,
      statusData: data,
      selectedPlatformRes,
      setBulkDisable,
      setMenuLoading,
    });
  };

  return (
    <div>
      <Box
        component="main"
        sx={{
          width: "98%",
          backgroundColor: "#f4f4f4",
          borderRadius: 2,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
          marginLeft: 5,
        }}
      >
        <div
          style={{
            height: "98%",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                height: 80,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                overflowX: "auto",
                marginRight: 10,
              }}
            >
              {platformList?.map((row, index) => {
                let disabledObj = [];
                disabledObj = restOfficialData?.platforms?.map((info) => {
                  if (info.platform.id == row?.id) {
                    return true;
                  } else {
                    return false;
                  }
                });

                const disabledChecked = disabledObj?.findIndex(
                  (item) => item == true
                );

                return (
                  <div
                    key={index}
                    style={{
                      cursor: disabledChecked == -1 ? "default" : "pointer",
                    }}
                    onClick={() => {
                      if (disabledChecked != -1) {
                        setSelectedPlatform(row);
                        setMenuListObj([]);
                        setSelectedPlatformRes(null);
                      }
                    }}
                    disabled={disabledChecked == -1 ? true : false}
                  >
                    {row?.name === "Yemeksepeti" && (
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 14,
                              fontFamily: "segoeuiRg",
                              padding: 1,
                              bgcolor: "#082657",
                              "& .MuiTooltip-arrow": {
                                color: "#082657",
                              },
                            },
                          },
                        }}
                        title={
                          "Yemeksepeti menü değişikliklerini entegrasyon firmaları üzerinden değiştirilmesine izin vermemektedir.Değişiklik işlemini MyRestoran üzerinden yapınız."
                        }
                        arrow
                        placement="bottom"
                      >
                        <img
                          alt="yemeksepeti"
                          style={{ width: 120, height: 70 }}
                          src={platformImageConverterBig(
                            row?.name + (disabledChecked == -1 ? "md" : "")
                          )}
                        />
                      </Tooltip>
                    )}
                    {row?.name === "Trendyol" && (
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 14,
                              fontFamily: "segoeuiRg",
                              padding: 1,
                              bgcolor: "#082657",
                              "& .MuiTooltip-arrow": {
                                color: "#082657",
                              },
                            },
                          },
                        }}
                        title={
                          "Trendyol menü değişikliklerini entegrasyon firmaları üzerinden değiştirilmesine izin vermemektedir.Değişiklik işlemini MyRestoran üzerinden yapınız."
                        }
                        arrow
                        placement="bottom"
                      >
                        <img
                          alt="Trendyol"
                          style={{ width: 120, height: 70 }}
                          src={platformImageConverterBig(
                            row?.name + (disabledChecked == -1 ? "md" : "")
                          )}
                        />
                      </Tooltip>
                    )}
                    {row?.name === "Gofody" && (
                      <img
                        alt="Gofody"
                        style={{ width: 120, height: 70 }}
                        src={platformImageConverterBig(
                          row?.name + (disabledChecked == -1 ? "md" : "")
                        )}
                      />
                    )}
                    {row?.name === "Getir" && (
                      <img
                        alt="Getir"
                        style={{ width: 120, height: 70 }}
                        src={platformImageConverterBig(
                          row?.name + (disabledChecked == -1 ? "md" : "")
                        )}
                      />
                    )}
                    {row?.name === "Migros" && (
                      <img
                        alt="Migros"
                        style={{ width: 120, height: 70 }}
                        src={platformImageConverterBig(
                          row?.name + (disabledChecked == -1 ? "md" : "")
                        )}
                      />
                    )}
                    {row?.name === "Fuudy" && (
                      <img
                        alt="Fuudy"
                        style={{ width: 120, height: 70 }}
                        src={platformImageConverterBig(
                          row?.name + (disabledChecked == -1 ? "md" : "")
                        )}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                width: "98%",
                height: 65,
                display: "flex",
                flexDirection: "row",
                minHeight: 65,
                alignItems: "center",
                alignSelf: "center",
                overflowX: "auto",
              }}
            >
              {platformRes?.map((row, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor:
                        selectedPlatformRes?.id == row?.id
                          ? "green"
                          : "#f53b62",
                      padding: "1px 6px",
                      fontSize: 13,
                      color: "white",
                      borderRadius: 4,
                      fontFamily: "segoeuiRg",
                      width: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      height: "80%",
                      flexDirection: "column",
                      marginLeft: 5,
                      cursor: "pointer",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      setSelectedPlatformRes(row);
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "segoeuiRg",
                        cursor: "pointer",
                      }}
                    >
                      Tabela
                    </span>
                    {(row?.restaurant_name).length > 16 ? (
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 14,
                              fontFamily: "segoeuiRg",
                              padding: 1,
                              bgcolor: "#082657",
                              "& .MuiTooltip-arrow": {
                                color: "#082657",
                              },
                            },
                          },
                        }}
                        title={row?.restaurant_name}
                        arrow
                        placement="top"
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: 100,
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "segoeuiRg",
                              cursor: "pointer",
                            }}
                          >
                            {row?.restaurant_name}
                          </span>
                        </div>
                      </Tooltip>
                    ) : (
                      <>{row?.restaurant_name}</>
                    )}
                  </div>
                );
              })}
            </div>

            {selectedPlatformRes != null && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "98%",
                    overflowX: "auto",
                    marginBottom: 10,
                  }}
                >
                  <div ref={scrollRef} className={classes.overflowStyle}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 100,
                        height: "100%",
                      }}
                    >
                      {menuListObj?.map((row, i) => {
                        return (
                          <Paper
                            key={i}
                            elevation={2}
                            onClick={scrollSmoothHandler(i)}
                            style={{
                              width: "auto",
                              minWidth: 150,
                              maxWidth: 150,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              padding: 5,
                              margin: "10px 12px",

                              color:
                                selectedHeader == `header_${i}`
                                  ? "royalblue"
                                  : "black",
                            }}
                          >
                            {row?.name?.length > 10 ? (
                              <OverflowTip note={row?.name} />
                            ) : (
                              <span style={{ fontWeight: "bold" }}>
                                {row?.name}
                              </span>
                            )}
                          </Paper>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "35%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      height: "20%",
                    }}
                  >
                    <CustomerSearch
                      value={searchTerm}
                      placeholder={"Ürün Ara"}
                      onchange={setSearchTerm}
                    />
                  </div>
                </div>
                {selectedPlatform?.name == "Yemeksepeti" && (
                  <div
                    style={{
                      margin: 30,
                      fontSize: 22,
                      color: "red",
                      fontFamily: "segoeuiB",
                    }}
                  >
                    Yemeksepeti ürün aç kapa işlemlerinin entegrasyon şirketleri
                    üzerinden yapılmasına izin vermemektedir.Lütfen
                    restoranlarım sayfasından aç / kapa işlemlerini
                    gerçekleştiriniz.
                  </div>
                )}
                <div style={{ overflow: "auto" }}>
                  {menuListObj?.length != 0 ? (
                    menuListObj?.map((row, index) => {
                      let statusObj = row?.products.map((a) => {
                        if (a.is_active) {
                          return true;
                        } else {
                          return false;
                        }
                      });

                      const statusChecked = statusObj.findIndex(
                        (item) => item == true
                      );

                      return (
                        <div
                          key={index}
                          ref={scrollRefs.current[index]}
                          style={{ width: "98%" }}
                        >
                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              marginTop: 15,
                              backgroundColor: "#293c51",
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "0 8px",
                              borderRadius: 4,
                            }}
                          >
                            <span style={{ marginLeft: 10, color: "#fff" }}>
                              {row?.name}
                            </span>
                            <div>
                              <FormControlLabel
                                control={
                                  <GreenSwitch
                                    checked={statusChecked == -1 ? false : true}
                                    onChange={() => {
                                      if (statusChecked != -1) {
                                        setProductTypeModal("bulk");
                                        setProductModal(true);
                                        setCurrentRow(row);
                                      } else {
                                        let newArr = [];
                                        currentRow?.products?.map((row1) => {
                                          newArr.push(row1?.id);
                                        });
                                        productStatusChange(
                                          {
                                            products: newArr,
                                            is_active: true,
                                            secret_key: selectedPlatformRes?.id,
                                          },
                                          row
                                        );
                                      }
                                    }}
                                    disabled={
                                      bulkDisable ||
                                      selectedPlatform?.name == "Yemeksepeti"
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={
                                  <span
                                    style={{
                                      color: "#fff",
                                      fontFamily: "segoeuiB",
                                    }}
                                  >
                                    Başlığı{" "}
                                    {statusChecked != -1 ? "kapat" : "aç"}
                                  </span>
                                }
                                labelPlacement="start"
                              />
                            </div>
                          </div>

                          {row?.products
                            ?.filter((list) =>
                              list.name
                                .toLocaleLowerCase("tr")
                                .includes(searchTerm.toLocaleLowerCase("tr"))
                            )
                            .map((prod, ind) => {
                              return (
                                <div
                                  key={ind}
                                  style={{
                                    fontFamily: "segoeuiRg",
                                    marginTop: 15,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "0 8px",
                                    borderRadius: 4,
                                  }}
                                >
                                  <span
                                    style={{ marginLeft: 10, color: "black" }}
                                  >
                                    {prod?.name}
                                  </span>
                                  <div
                                    key={ind}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <RadioGroup row name="menu_item" key={ind}>
                                      <FormControlLabel
                                        control={
                                          <GreenSwitch
                                            checked={prod?.is_active}
                                            onChange={(e) => {
                                              if (prod?.is_active) {
                                                setProductTypeModal("normal");
                                                setProductModal(true);
                                                setCurrentRow(prod);
                                              } else {
                                                let data;

                                                data = {
                                                  is_active: !prod?.is_active,
                                                  products: [prod.id],
                                                  secret_key:
                                                    selectedPlatformRes?.id,
                                                };

                                                productStatusChange(data, prod);
                                              }
                                            }}
                                            sx={{
                                              color: pink[800],
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            disabled={
                                              bulkDisable ||
                                              selectedPlatform?.name ==
                                                "Yemeksepeti"
                                                ? true
                                                : false
                                            }
                                          />
                                        }
                                        labelPlacement="start"
                                        label={
                                          <span
                                            style={{ fontFamily: "segoeuiRg" }}
                                          >
                                            Aç / Kapa
                                          </span>
                                        }
                                      />
                                    </RadioGroup>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 50,
                        fontFamily: "segoeuiB",
                        fontSize: 20,
                      }}
                    >
                      Bu platforma ait ürün bulunamamıştır. Sayfayı yenileyerek
                      tekrar kontrol edebilirsiniz.
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
      <ProductModal
        productModal={productModal}
        setProductModal={setProductModal}
        productStatusChange={productStatusChange}
        currentRow={currentRow}
        productModalType={productModalType}
        selectedPlatformRes={selectedPlatformRes}
      />
    </div>
  );
};

export default Menu;
