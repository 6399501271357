import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Fab,
  Grow,
  IconButton,
  keyframes,
  Paper,
  Slide,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CallContext, callkey } from "../../../contexts/callContext";
import {
  ArrowBack,
  Call,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import moment from "moment";
import { useSnackbar } from "notistack";
import makeCall from "../func/makeCall";
import { Paths } from "../../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Draggable from "react-draggable";
import "./swing.css";
import { RestaurantContext } from "../../../contexts/restaurantContext";

import CardFlip from "react-card-flip";
import LottieMain from "../../lottie";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    bottom: 60,
    right: 70,
    width: 700,
    padding: 10,
    zIndex: 999,
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
}));

const MissedCall = () => {
  let history = useHistory();
  const classes = useStyles();
  const [openMissed, setOpenMissed] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    cdrMissedListFunc,
    cdrOutboundFunc,
    cdrAnswerFunc,
    cdrHangupFunc,
    cdrMissedCount,
    cdrMissedCountFunc,
    cdrOutboundListFunc,
    cdrInboundListFunc,
    cdrMissedListObj,
    callLoader,
  } = useContext(CallContext);
  const { swingAnim } = useContext(RestaurantContext);

  const [disabledCallIcon, setDisabledCallIcon] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [listType, setListType] = useState("missed");

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleToggle = () => {
    setOpenMissed((prev) => !prev);
  };

  useEffect(() => {
    if (openMissed) {
      if (listType === "missed") {
        cdrMissedListFunc();
      } else if (listType === "inbound") {
        cdrInboundListFunc();
      } else {
        cdrOutboundListFunc();
      }
      setPosition({ x: 0, y: 0 });
    }
  }, [openMissed]);

  const handleDrag = (e, data) => {
    setPosition({ x: data.x, y: data.y });
  };

  return (
    <>
      <Fab
        className={swingAnim ? "swing" : ""}
        style={{
          position: "absolute",
          bottom: 30,
          left: 30,
          width: 50,
          height: 30,
          backgroundColor: cdrMissedCount?.count === 0 ? "#448d8c" : "red",
          color: "#fff",
          borderRadius: 2,
          transformOrigin: "bottom center",
        }}
        onClick={handleToggle}
      >
        {cdrMissedCount !== null ? cdrMissedCount.count : "-"}
      </Fab>

      <Draggable handle=".drag-handle" onDrag={handleDrag} position={position}>
        <Slide direction="right" in={openMissed} mountOnEnter unmountOnExit>
          <div
            style={{
              left: 30,
              bottom: 75,
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 999,
              width: 300,
              height: 300,
              borderRadius: 2,
              backgroundColor: "#f4f4f4",
              boxShadow: "darkslategray 0px 0px 4px 3px",
            }}
          >
            <div
              style={{
                fontFamily: "segoeuiB",
                fontSize: 14,
                color: "#fff",
                backgroundColor: "darkslategray",
                display: "flex",
                cursor: "grabbing",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
              }}
              className="drag-handle"
            >
              {listType === "missed" ? (
                <span>Gelen Cevapsız Aramalar</span>
              ) : listType === "inbound" ? (
                "Giden Tüm Aramalar"
              ) : (
                "Gelen Cevaplanan Aramalar"
              )}
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                display: "flex",
                padding: 5,
                width: "95%",
                justifyContent: "space-between",
              }}
            >
              <Button
                style={{
                  backgroundColor: listType !== "missed" ? "#fff" : "#f53b62",
                  color: listType !== "missed" ? "#000" : "#fff",
                  fontFamily: "segoeuiB",
                  width: 80,
                  display: "flex",
                  justifyContent: "center",
                  height: 25,
                  textTransform: "capitalize",
                }}
                disabled={callLoader ? true : false}
                variant={listType !== "missed" ? "outlined" : "contained"}
                color={listType !== "missed" ? "error" : "inherit"}
                size="small"
                onClick={() => {
                  setListType("missed");
                  cdrMissedListFunc();
                }}
              >
                Gelen
              </Button>
              <Button
                style={{
                  backgroundColor: listType !== "inbound" ? "#fff" : "#f53b62",
                  color: listType !== "inbound" ? "#000" : "#fff",
                  fontFamily: "segoeuiB",
                  width: 80,
                  display: "flex",
                  justifyContent: "center",
                  height: 25,
                  textTransform: "capitalize",
                }}
                disabled={callLoader ? true : false}
                variant={listType !== "inbound" ? "outlined" : "contained"}
                color={listType !== "inbound" ? "error" : "inherit"}
                size="small"
                onClick={() => {
                  setListType("inbound");
                  cdrInboundListFunc();
                }}
              >
                Giden
              </Button>
              <Button
                style={{
                  backgroundColor: listType !== "outbound" ? "#fff" : "#f53b62",
                  color: listType !== "outbound" ? "#000" : "#fff",
                  fontFamily: "segoeuiB",
                  width: 80,
                  display: "flex",
                  justifyContent: "center",
                  height: 25,
                  textTransform: "capitalize",
                }}
                disabled={callLoader ? true : false}
                variant={listType !== "outbound" ? "outlined" : "contained"}
                color={listType !== "outbound" ? "error" : "inherit"}
                size="small"
                onClick={() => {
                  setListType("outbound");
                  cdrOutboundListFunc();
                }}
              >
                Cevaplanan
              </Button>
            </div>

            <div style={{ height: "77%", overflow: "auto" }}>
              {callLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80%",
                  }}
                >
                  <LottieMain />
                </div>
              ) : (
                cdrMissedListObj?.map((row, ind) => {
                  return (
                    <CardFlip
                      isFlipped={isFlipped === ind}
                      containerStyle={{
                        width: "100%",
                      }}
                    >
                      <Paper
                        key={ind}
                        style={{
                          backgroundColor: "#403836",
                          width: "95%",
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          height: 40,
                          alignItems: "center",
                          margin: 4,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            fontFamily: "monospace",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            padding: 2,
                            width: "90%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "60%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {listType === "inbound"
                                ? row?.callee
                                : listType === "missed"
                                ? row?.caller
                                : row?.caller}
                            </span>
                            <span
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                history.push(
                                  `/restaurants/${row?.restofficial?.user_id}`
                                );
                              }}
                            >
                              {row?.restofficial !== null
                                ? row?.restofficial?.name
                                : "-------"}
                            </span>
                          </div>
                          <div
                            style={{
                              width: "40%",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {moment(row?.times[0]).format("DD MMMM-HH:mm")}
                            </span>
                            <span
                              style={{
                                color:
                                  row?.times?.length > 5 ? "#e5a3a3" : "#fff",
                                cursor: "pointer",
                                fontSize: 16,
                              }}
                              onClick={() => {
                                setIsFlipped(ind);
                              }}
                            >
                              {row?.times?.length}
                            </span>
                          </div>
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "gray",
                            width: "0.1px",
                            height: "90%",
                            margin: "3px 0px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: 12,
                            fontFamily: "monospace",
                            display: "flex",
                            justifyContent: "center",
                            padding: 2,
                            width: "10%",
                            alignItems: "center",
                          }}
                        >
                          <Call
                            style={{
                              width: 25,
                              height: 25,
                              cursor: "pointer",
                              color: disabledCallIcon ? "gray" : "#fff",
                            }}
                            disabled={disabledCallIcon}
                            onClick={() => {
                              let sendCallData = null;
                              setDisabledCallIcon(true);
                              setTimeout(() => {
                                setDisabledCallIcon(false);
                              }, 5000);
                              closeSnackbar(callkey);
                              let phoneNumberv2Obj =
                                listType === "inbound"
                                  ? row?.callee
                                  : row?.caller;
                              phoneNumberv2Obj = phoneNumberv2Obj?.slice(-10);
                              phoneNumberv2Obj =
                                (JSON.parse(
                                  localStorage.getItem("st-officeuser-info")
                                )?.iqcell_verimor
                                  ? "0"
                                  : "90") + phoneNumberv2Obj;

                              if (row?.restofficial !== null) {
                                sendCallData = {
                                  callee: phoneNumberv2Obj,
                                  trigger_source: "missed",
                                  restofficial: row?.restofficial?.user_id,
                                };
                              } else {
                                sendCallData = {
                                  callee: phoneNumberv2Obj,
                                  trigger_source: "missed",
                                };
                              }
                              makeCall({
                                enqueueSnackbar,
                                closeSnackbar,
                                sendCallData,
                                cdrOutboundFunc,
                                cdrAnswerFunc,
                                cdrHangupFunc,
                                callkey,
                                cdrMissedCountFunc,
                              });
                              setOpenMissed(false);
                            }}
                          />
                        </div>
                      </Paper>
                      <Paper
                        key={ind}
                        style={{
                          backgroundColor: "#403836",
                          width: "95%",
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          height: 80,
                          margin: 4,
                          overflow: "auto",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            fontFamily: "monospace",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            padding: 2,
                            width: "90%",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          {row?.times?.map((row1) => {
                            return (
                              <div
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  {moment(row1).format("MMMM - HH:mm")}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "gray",
                            width: "0.1px",
                            height: "90%",
                            margin: "3px 0px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: 12,
                            fontFamily: "monospace",
                            display: "flex",
                            justifyContent: "center",
                            padding: 2,
                            width: "10%",
                            alignItems: "center",
                          }}
                        >
                          <ArrowBack
                            style={{
                              width: 25,
                              height: 25,
                              cursor: "pointer",
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setIsFlipped(false);
                            }}
                          />
                        </div>
                      </Paper>
                    </CardFlip>
                  );
                })
              )}
            </div>
          </div>
        </Slide>
      </Draggable>
    </>
  );
};

export default MissedCall;
