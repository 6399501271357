import {
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControlLabel,
  FormGroup,
  Modal,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev2";
import { ManagementContext } from "../../../../contexts/managementContext";
import SearchV2 from "../../../../components/search/searchv2";
import useDebounce from "../../../../components/debounce";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    fontFamily: "monospace",
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "monospace",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

export default function DataTransferModal({
  openDataTransferModal,
  setOpenDataTransferModal,
  dashboardOfficeuserDetail,
  backofficeCollectorList,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    dashboardOfficeuserTransfer,
    dashboardOfficeuserRestofficials,
    dashboardUserRestofficials,
    dashboardOfficeuserTransferRestofficials,
  } = useContext(ManagementContext);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedsearchValue = useDebounce(searchTerm, 1000);
  const [filteredRestOfficials, setFilteredRestOfficials] = useState([]);

  useEffect(() => {
    if (dashboardUserRestofficials) {
      setFilteredRestOfficials(dashboardUserRestofficials);
    }
  }, [dashboardUserRestofficials]);

  useEffect(() => {
    if (debouncedsearchValue && debouncedsearchValue.length > 0) {
      const filteredRestOfficialsArr = dashboardUserRestofficials.filter(
        (rest) => {
          return (
            rest.name
              .toLowerCase()
              .includes(debouncedsearchValue.toLowerCase()) ||
            rest.user_id === parseInt(debouncedsearchValue)
          );
        }
      );
      setFilteredRestOfficials(filteredRestOfficialsArr);
    } else {
      if (dashboardOfficeuserDetail) {
        if (dashboardOfficeuserDetail.user_id !== null) {
          dashboardOfficeuserRestofficials(dashboardOfficeuserDetail.user_id);
        }
      }
    }
  }, [debouncedsearchValue]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (dashboardOfficeuserDetail) {
      if (dashboardOfficeuserDetail.user_id !== null) {
        dashboardOfficeuserRestofficials(dashboardOfficeuserDetail.user_id);
      }
    }
  }, [dashboardOfficeuserDetail]);

  const handleSendUsers = () => {
    let data = {
      from_officeuser: dashboardOfficeuserDetail.user_id,
      to_officeusers: selectedUsers.map((user) => user.user_id),
    };
    dashboardOfficeuserTransfer(data);
  };

  const handleChangeRest = (e) => {
    const checkedItem = e.target.value;
    if (e.target.checked) {
      setCheckedItems([...checkedItems, checkedItem]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== checkedItem));
    }
  };

  const handleSendRestOfficials = () => {
    let data = {
      from_officeuser: dashboardOfficeuserDetail.user_id,
      to_officeuser: selectedUser.user_id,
      restofficials: checkedItems.map((item) => parseInt(item)),
    };
    dashboardOfficeuserTransferRestofficials(data);
  };

  return (
    <Modal
      open={openDataTransferModal}
      onClose={() => {
        setOpenDataTransferModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "monospace",
          }}
        >
          Kullanıcı Data Transfer
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "600px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              centered
              className={classes.tabs}
            >
              <Tab label="Kişiye Aktar" {...a11yProps(0)} />
              <Tab label="Restofficial Aktar" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <h4
              style={{
                fontFamily: "monospace",
                textAlign: "center",
                marginTop: 0,
              }}
            >
              Aktarmak istediğiniz kullanıcıları seçiniz
            </h4>
            <Select
              menuPortalTarget={document.body}
              styles={{
                ...selectDefaultStyles,
                fontFamily: "monospace",
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              options={backofficeCollectorList}
              responsiveHeight={"auto"}
              selectFontSize={14}
              menuColor={"#f4f4f4"}
              hoverColor={"#f53b62"}
              isSearchable={false}
              selectWidth={"100%"}
              darkMode={true}
              inputColor={"#f4f4f4"}
              textColor={"black"}
              placeHolderColor={"#f53b62"}
              isMulti
              inputFontSize={14}
              dropdownColor={"#f53b62"}
              isClearable={false}
              closeMenuOnSelect={true}
              placeholder="Kişi"
              getOptionLabel={(option) => option.full_name}
              getOptionValue={(option) => option.user_id}
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e)}
            />

            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                width: "100%",
                fontWeight: 700,
                backgroundColor: "#0FBD20",
                marginTop: 20,
              }}
              onClick={handleSendUsers}
            >
              Gönder
            </Button>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h4
              style={{
                fontFamily: "monospace",
                textAlign: "center",
                marginTop: 0,
              }}
            >
              Aktarmak istediğiniz kullanıcıyı seçiniz
            </h4>

            <Select
              menuPortalTarget={document.body}
              styles={{
                ...selectDefaultStyles,
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              options={backofficeCollectorList}
              darkMode={true}
              responsiveHeight={"auto"}
              selectFontSize={14}
              menuColor={"#f4f4f4"}
              hoverColor={"#f53b62"}
              isSearchable={false}
              selectWidth={"100%"}
              inputColor={"#f4f4f4"}
              textColor={"black"}
              placeHolderColor={"#f53b62"}
              inputFontSize={14}
              dropdownColor={"#f53b62"}
              isClearable={false}
              closeMenuOnSelect={true}
              placeholder="Kişi"
              getOptionLabel={(option) => option.full_name}
              getOptionValue={(option) => option.user_id}
              value={selectedUser}
              onChange={(e) => setSelectedUser(e)}
            />

            <h4
              style={{
                fontFamily: "monospace",
                textAlign: "center",
              }}
            >
              Aktarmak istediğiniz restofficialları seçiniz
            </h4>

            <div>
              <SearchV2 searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </div>

            <div style={{ maxHeight: 250, overflow: "auto" }}>
              {filteredRestOfficials?.map((rest, i) => {
                return (
                  <FormGroup key={i}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={rest.user_id}
                          onChange={handleChangeRest}
                          checked={checkedItems.includes(
                            rest.user_id.toString()
                          )}
                        />
                      }
                      label={
                        <Typography
                          variant="span"
                          style={{ fontFamily: "monospace" }}
                        >
                          {rest.name}
                        </Typography>
                      }
                    />
                  </FormGroup>
                );
              })}
            </div>

            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "monospace",
                letterSpacing: 1.5,
                width: "100%",
                fontWeight: 700,
                backgroundColor: "#0FBD20",
                marginTop: 20,
              }}
              onClick={handleSendRestOfficials}
            >
              Gönder
            </Button>
          </TabPanel>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
