import { Box, Button, InputBase, Modal } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";

export default function ProductModal({
  openProductModal,
  setOpenProductModal,
  selectedProduct,
  setSelectedProduct,
  headerId,
}) {
  const { createProductTemplate, editProductTemplate } =
    useContext(RestaurantContext);
  const [sendingBannerImage, setSendingBannerImage] = useState(null);
  const [productName, setProductName] = useState("");

  const onImageChange = (e) => {
    setSendingBannerImage(e.target.files[0]);
  };

  useEffect(() => {
    if (selectedProduct) {
      setProductName(selectedProduct.name);
    } else {
      setProductName("");
    }
  }, [selectedProduct]);

  return (
    <Modal
      open={openProductModal}
      onClose={() => {
        setOpenProductModal(false);
        setProductName("");
        setSendingBannerImage(null);
        setSelectedProduct(null);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...styles.modal,
        }}
      >
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {selectedProduct?.id ? "Ürün Düzenle" : "Yeni Ürün Ekle"}
        </h2>

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
        >
          <input type="file" accept="image/*" onChange={onImageChange} />

          {!selectedProduct?.id && (
            <div style={{ marginLeft: 10 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Ürün İsmi
              </span>
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#fff",
                  height: 45,
                  marginTop: 10,
                  marginBottom: 0,
                  borderRadius: 6,
                  marginRight: 14,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 8,
                  }}
                >
                  <InputBase
                    variant="filled"
                    style={{
                      width: "100%",
                      color: "black",
                      marginLeft: 14,
                      fontSize: 15,
                      fontWeight: "400",
                      fontFamily: "segoeuiRg",
                      height: 45,
                    }}
                    value={productName}
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            size="medium"
            style={{
              fontFamily: "segoeuiB",
              width: "200px",
              fontWeight: 700,
              backgroundColor: "red",
              marginRight: 20,
            }}
            onClick={() => {
              setOpenProductModal(false);
              setProductName("");
              setSendingBannerImage(null);
              setSelectedProduct(null);
            }}
          >
            Vazgeç
          </Button>

          <Button
            variant="contained"
            size="medium"
            style={{
              fontFamily: "segoeuiB",
              width: "200px",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
            }}
            onClick={() => {
              const formData = new FormData();

              formData.append("product_header_picture_template", headerId);
              formData.append("picture", sendingBannerImage);

              if (selectedProduct?.id) {
                editProductTemplate(formData, selectedProduct.id, headerId);
              } else {
                formData.append("name", productName);
                createProductTemplate(formData, headerId);
              }
            }}
          >
            Onayla
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
