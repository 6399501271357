import { Paper } from "@mui/material";
import React from "react";

const EftInfoCard = ({ title, eftInfo }) => {
  return (
    <Paper
      elevation={2}
      style={{
        width: "100%",
        padding: 10,
        margin: 5,
      }}
    >
      <div
        style={{
          color: "gray",
          textDecoration: "underline",
          fontFamily: "segoeuiB",
        }}
      >
        {title}
      </div>
      <div style={{ marginLeft: 30, fontFamily: "segoeuiRg", marginTop: 3 }}>
        {eftInfo}
      </div>
    </Paper>
  );
};

export default EftInfoCard;
