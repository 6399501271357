import React, { useContext, useEffect } from "react";
import Kunye from "./restaurantKunye";
import Platforms from "./platforms";
import { RestaurantContext } from "../../contexts/restaurantContext";
import Courier from "./courier/index.js";
import Modules from "./modules";
import { Printer } from "./printer";
import WorkingHour from "./WorkingHour";
import PaymentTypes from "./paymentTypes";
import { Offers } from "./offers";
import { Reports } from "./reports";
import Menu from "./menu";
import ManagementPanel from "./managementPanel";
import DeskFollowing from "./deskFollowing";
import SecretKeys from "./secret_keys/index.js";
import CpKurulum from "./cpKurulum/index.jsx";

const Restaurant = () => {
  const { currentIndex, setCurrentIndex } = useContext(RestaurantContext);

  useEffect(() => {
    if (localStorage.getItem("offers") === "offers") {
      setCurrentIndex("offers");
      localStorage.removeItem("offers");
    } else if (localStorage.getItem("offers") === "reports") {
      setCurrentIndex("reports");
      localStorage.removeItem("offers");
    } else if (localStorage.getItem("offers") === "secret_keys") {
      setCurrentIndex("secret_keys");
      localStorage.removeItem("offers");
    } else if (localStorage.getItem("offers") === "platforms") {
      setCurrentIndex("secret_key");
      localStorage.removeItem("offers");
    } else if (localStorage.getItem("offers") === "kunye") {
      setCurrentIndex("tabela");
      localStorage.removeItem("offers");
    }
  }, []);

  const getComponent = () => {
    switch (currentIndex) {
      case "tabela":
        return <Kunye />;
      case "offers":
        return <Offers />;
      case "secret_keys":
        return <SecretKeys />;
      case "secret_key":
        return <Platforms />;
      case "working_hour":
        return <WorkingHour />;
      case "menu":
        return <Menu />;
      case "payment_type":
        return <PaymentTypes />;
      case "courier":
        return <Courier />;
      case "printer":
        return <Printer currentIndex />;
      case "reports":
        return <Reports />;
      case "module":
        return <Modules />;
      case "management_panel":
        return <ManagementPanel />;
      case "tables":
        return <DeskFollowing />;
      case "cp-kurulum":
        return <CpKurulum />;
      default:
        <div>Tekrar deneyiniz</div>;
    }
  };

  return <div>{getComponent()}</div>;
};

export default Restaurant;
