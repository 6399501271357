import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import RegistrationField from "../../../components/registrationField";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev2";
import { useToast } from "../../../components/snackbar";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import validator from "validator";
import Colors from "../../../utils/colors";
import "react-phone-input-2/lib/style.css";

const RestaurantRegistration = () => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [formData, setFormData] = useState(null);
  const { alert } = useToast();
  const {
    sectorList,
    getSectorListFunc,
    postRestaurantRegister,
    setOperationSidebarPage,
    setPreDealarSidebarPage,
    postRestaurantRegisterTech,
  } = useContext(RestaurantContext);
  const officeUser = JSON.parse(localStorage.getItem("st-officeuser-info"));

  const [selectedSector, setSelectedSector] = useState(null);

  useEffect(() => {
    getSectorListFunc();
    setOperationSidebarPage(2);
    setPreDealarSidebarPage(2);
    setFormData({
      restaurant_name: "",
      full_name: "",
      phone: "",
      landline_phone: "",
      email: "",
      sector: "single_restaurant",
      discover_method: "",
      high_priority: false,
    });
  }, []);

  const isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  const sendRegistration = () => {
    if (isBlank(formData?.restaurant_name)) {
      alert("Restoran adı boş bırakılamaz.");
    } else if (isBlank(formData?.full_name)) {
      alert("Yetkili adı boş bırakılamaz.");
    } else if (!validator.isMobilePhone(formData?.phone, "tr-TR")) {
      alert("Uygun bir cep telefonu giriniz.");
    } else if (isBlank(formData?.landline_phone)) {
      alert("Sabit telefon boş bırakılamaz.");
    } else if (!validator.isEmail(formData?.email)) {
      alert("Uygun bir email giriniz.");
    } else {
      if (officeUser.role === "tech") {
        postRestaurantRegisterTech(formData);
      } else {
        postRestaurantRegister(formData);
      }
    }
  };

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f4f4f4",
      }}
    >
      <div
        style={{
          width: "80%",
          backgroundColor: "#9e9e9e",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 8,
          padding: 30,
          height: matches ? 500 : "auto",
          marginTop: 30,
        }}
      >
        <div
          style={{
            width: "90%",
            height: "80%",
            display: "flex",
            flexDirection: "column",
            marginBottom: matches ? 150 : 50,
            marginTop: 50,
          }}
        >
          <div
            style={{
              flexDirection: matches ? "row" : "column",
              display: "flex",
            }}
          >
            <RegistrationField
              fieldHeader={"Firma Adı"}
              fieldValue={formData?.restaurant_name}
              fieldName={"restaurant_name"}
              formData={formData}
              setFormData={setFormData}
            />
            <RegistrationField
              fieldHeader={"Yetkili Adı"}
              fieldValue={formData?.full_name}
              fieldName={"full_name"}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
          <div
            style={{
              flexDirection: matches ? "row" : "column",
              display: "flex",
              marginTop: 30,
            }}
          >
            <div style={{ flex: 1 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Cep No
              </span>
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f4f4f4",
                  height: 45,
                  marginTop: matches ? 10 : 5,
                  borderRadius: 6,
                  marginRight: 14,
                }}
              >
                <PhoneInput
                  countryCodeEditable={false}
                  country="tr"
                  onlyCountries={["tr"]}
                  localization={{ tr: "Türkiye" }}
                  value={formData?.phone}
                  containerStyle={{ height: 45 }}
                  inputStyle={{
                    height: 45,
                    width: "100%",
                    fontSize: 15,
                    fontWeight: "400",
                    fontFamily: "segoeuiRg",
                  }}
                  onChange={(phone) => {
                    setFormData({ ...formData, ["phone"]: phone });
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <span
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 15,
                  fontWeight: 700,
                  marginLeft: 8,
                }}
              >
                Sabit No
              </span>
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#f4f4f4",
                  height: 45,
                  marginTop: matches ? 10 : 5,
                  borderRadius: 6,
                  marginRight: 14,
                }}
              >
                <PhoneInput
                  countryCodeEditable={false}
                  country="tr"
                  onlyCountries={["tr"]}
                  localization={{ tr: "Türkiye" }}
                  value={formData?.landline_phone}
                  containerStyle={{ height: 45 }}
                  inputStyle={{
                    height: 45,
                    width: "100%",
                    fontSize: 16,
                    fontWeight: "400",
                    fontFamily: "segoeuiRg",
                  }}
                  onChange={(phone) => {
                    setFormData({ ...formData, ["landline_phone"]: phone });
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <RegistrationField
                fieldHeader={"Email"}
                fieldValue={formData?.email}
                fieldName={"email"}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 30,
              marginBottom: 20,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            {officeUser.role !== "tech" && (
              <>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 8,
                    }}
                  >
                    <FormControlLabel
                      labelPlacement="left"
                      control={
                        <Checkbox
                          checked={
                            formData?.discover_method === "ref" ? true : false
                          }
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              ["discover_method"]: "ref",
                            });
                          }}
                          sx={{
                            color: "#f53b62",
                            "&.Mui-checked": {
                              color: "#f53b62",
                            },
                          }}
                        />
                      }
                      style={{ textAlign: "center" }}
                      label={
                        <span
                          style={{
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          Referans
                        </span>
                      }
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 8,
                      marginLeft: 20,
                    }}
                  >
                    <FormControlLabel
                      labelPlacement="left"
                      control={
                        <Checkbox
                          checked={
                            formData?.discover_method === "self" ? true : false
                          }
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              ["discover_method"]: "self",
                            });
                          }}
                          sx={{
                            color: "#f53b62",
                            "&.Mui-checked": {
                              color: "#f53b62",
                            },
                          }}
                        />
                      }
                      style={{ textAlign: "center" }}
                      label={
                        <span
                          style={{
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          Kendim Buldum
                        </span>
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 8,
                    marginLeft: 20,
                  }}
                >
                  <FormControlLabel
                    labelPlacement="left"
                    control={
                      <Checkbox
                        checked={formData?.high_priority}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ["high_priority"]: !formData?.high_priority,
                          });
                        }}
                        sx={{
                          color: "#f53b62",
                          "&.Mui-checked": {
                            color: "#f53b62",
                          },
                        }}
                      />
                    }
                    style={{ textAlign: "center" }}
                    label={
                      <span
                        style={{
                          fontSize: 16,
                          color: "black",
                        }}
                      >
                        Yüksek Öncelik
                      </span>
                    }
                  />
                </div>
              </>
            )}

            <Button
              style={{
                backgroundColor: Colors.mainColor,
                textTransform: "capitalize",
                borderRadius: "8px",
                fontFamily: "segoeuiRg",
                width: 180,
                height: 40,
                marginTop: 15,
                color: "#fff",
                fontSize: 14,
                fontWeight: 600,
              }}
              size="small"
              variant="contained"
              onClick={() => sendRegistration()}
            >
              Başvuruyu Tamamla
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantRegistration;
