import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useToast } from "../components/snackbar";
import { RestaurantContext } from "./restaurantContext";
import UrgentModal from "../components/modals/urgentModal";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { ManagementContext } from "./managementContext";
import { LoginContext } from "./loginContext";

export const WsContext = createContext();

let orderInterval = null;
let endlessInterval = null;
let keyws = null;
let keytryws = null;
let wsTryConnectionCount = 0;
let WS_TRY_TIMER = 5000;

export const API_URL = process.env.REACT_APP_BASE_URL;

const accessT = localStorage.getItem("st-b-access");
const refreshToken = localStorage.getItem("st-b-refresh");

const WsProvider = (props) => {
  let history = useHistory();
  const { success, alertPersist, closeSnack, infoPersistWs, alertUrgent } =
    useToast();
  const {
    notificationListFunc,
    techSupportListFunc,
    getApplicationStatuList,
    getPaymentTracker,
    resTechPendingFunc,
    getTaskList,
  } = useContext(RestaurantContext);
  const { setBrochureResResult, getPrintingRequestList } =
    useContext(ManagementContext);
  const { officeuserInfoFuncv2 } = useContext(LoginContext);
  const [urgentModal, setUrgentModal] = useState(false);
  const [currentUrgentId, setCurrentUrgentId] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("st-b-access");
    if (accessToken) {
      accessCheck();
    }
  }, []);

  const accessCheck = async () => {
    const accessToken = localStorage.getItem("st-b-access");
    let decoded = jwt_decode(accessToken);
    let accessDecoded = moment.unix(decoded.exp).format("DD-MM-YYYY HH:mm:ss");
    let nowAdd30Sec = moment().add(30, "seconds").format("DD-MM-YYYY HH:mm:ss");
    if (accessDecoded < nowAdd30Sec) {
      if (refreshToken) {
        const a = await axios.post(`${API_URL}/officeuser/refresh`, {
          refresh: refreshToken,
        });

        localStorage.setItem("st-b-access", a.data.access);
        const socketUrl =
          process.env.REACT_APP_WS_BASE_URL + `?token=${a.data.access}`;
        let skt = new WebSocket(socketUrl);
        socketConnection(skt);
      }
    } else {
      const socketUrl =
        process.env.REACT_APP_WS_BASE_URL + `?token=${accessToken}`;
      let skt = new WebSocket(socketUrl);
      socketConnection(skt);
    }
  };

  const socketConnection = (skt) => {
    skt.onopen = () => {
      clearInterval(endlessInterval);
      clearInterval(orderInterval);
      closeSnack(keyws);
      closeSnack(keytryws);
      orderInterval = null;
      endlessInterval = null;
      keyws = null;
      keytryws = null;
      wsTryConnectionCount = 0;
      WS_TRY_TIMER = 5000;
      success("Baglantı kuruldu. (ws)");
    };

    //onopen
    skt.onmessage = function (wsData) {
      const newHeaders = JSON.parse(wsData.data);

      if (newHeaders.type === "new_support") {
        techSupportListFunc({
          stayed: false,
          searchTerm: "",
        });
      }

      if (newHeaders.type === "new_notification") {
        notificationListFunc();
      }

      if (newHeaders.type === "application_urgent_setup") {
        setUrgentModal(true);
        setCurrentUrgentId(newHeaders?.data?.application_id);
        setType("application");
      }

      if (newHeaders.type === "application_urgent_setup_cancel") {
        setUrgentModal(false);
        setType("application");
      }

      if (newHeaders.type === "application_urgent_setup_accepted") {
        getApplicationStatuList("", "", 1);
        setType("application");
      }

      if (newHeaders.type === "application_urgent_setup_not_accepted") {
        getApplicationStatuList("", "", 1);
        alertUrgent("Acil kurulum isteğiniz başarısız oldu.");
        setType("application");
      }

      if (newHeaders.type === "offer_urgent_collection") {
        setUrgentModal(true);
        setCurrentUrgentId(newHeaders?.data?.id);
        setType("collector");
      }

      if (newHeaders.type === "offer_urgent_collection_cancel") {
        setUrgentModal(false);
        setType("collector");
      }

      if (newHeaders.type === "offer_urgent_collection_accepted") {
        let data = {
          searchTerm: "",
          page: 1,
          stayed: "home",
        };
        getPaymentTracker(data);
        setType("collector");
      }

      if (newHeaders.type === "offer_urgent_collection_not_accepted") {
        let data = {
          searchTerm: "",
          page: 1,
          stayed: "home",
        };
        getPaymentTracker(data);
        alertUrgent("Acil tahsilat isteğiniz başarısız oldu.");
        setType("collector");
      }

      if (newHeaders.type === "new_restaurant_ticket") {
        resTechPendingFunc();
      }

      // if (newHeaders.type === "ticket_bounced") {
      //   ticketBounceListFunc();
      // }

      if (newHeaders.type === "offer_seen") {
        getTaskList();
      }

      if (newHeaders.type === "iqcell_verimor") {
        officeuserInfoFuncv2();
      }

      if (newHeaders.type === "printing_request_action") {
        setBrochureResResult(newHeaders?.data?.action);
        const restaurantId = window.location.pathname.slice(
          13,
          window.location.pathname.length
        );

        if (history.location.pathname.startsWith("/restaurants")) {
          getPrintingRequestList(1, restaurantId);
        }
      }
    };

    // onclose
    skt.onclose = function (e) {
      skt = null;
      clearInterval(endlessInterval);
      endlessInterval = null;
      if (keyws == null) {
        keyws = alertPersist(`Baglantıda sorun var. (ws'de)`);
      }

      if (accessT) {
        if (endlessInterval == null && !skt)
          endlessInterval = setInterval(() => {
            ++wsTryConnectionCount;
            if (WS_TRY_TIMER !== 10000) {
              WS_TRY_TIMER = WS_TRY_TIMER + 1000;
            }
            closeSnack(keytryws);
            keytryws = null;
            keytryws = infoPersistWs(
              `Bağlanmayı tekrar deniyor ( ${wsTryConnectionCount} )`
            );
            accessCheck();
          }, WS_TRY_TIMER);
      }
    };
    //onerror
    skt.onerror = function (e) {
      skt && skt.close();
    };
  };

  return (
    <WsContext.Provider value={{}}>
      {props.children}
      <UrgentModal
        urgentModal={urgentModal}
        setUrgentModal={setUrgentModal}
        currentUrgentId={currentUrgentId}
        type={type}
      />
    </WsContext.Provider>
  );
};

export default WsProvider;
