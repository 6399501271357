import React from "react";
import { Search } from "@mui/icons-material";
import { IconButton, InputBase, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
}));

const SearchWithIcon = ({
  setSearchTerm,
  placeTitle,
  customWidth,
  searchTerm,
}) => {
  const classes = useStyles();
  return (
    <Paper
      style={{
        width: customWidth ? customWidth : "30%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#c9d1cc",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "85%",
        }}
      >
        <InputBase
          spellCheck="false"
          style={{
            width: "100%",
            fontFamily: "segoeuiRg",
            fontSize: 12,
            marginLeft: 8,
            fontWeight: "bold",
          }}
          value={searchTerm}
          placeholder={placeTitle}
          className={classes.input}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <IconButton type="submit" aria-label="search" style={{ width: "15%" }}>
        <Search />
      </IconButton>
    </Paper>
  );
};

export default SearchWithIcon;
