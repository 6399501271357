import { http } from "./axios";

function cdr_outbound(data) {
  return http.post("/officeuser/cdr/outbound", data);
}

function cdr_inbound(data) {
  return http.post("/officeuser/cdr/inbound", data);
}

function cdr_answer(data) {
  return http.post("/officeuser/cdr/answer", data);
}

function cdr_hangup(data) {
  return http.post("/officeuser/cdr/hangup", data);
}

function cdr_missed_list() {
  return http.get("/officeuser/cdr/missed/list");
}

function cdr_missed_count() {
  return http.get("/officeuser/cdr/missed/count");
}

function cdr_summary_list(data) {
  return http.get(`/officeuser/cdr/summary/list?restofficial=${data?.rest_id}`);
}

function cdr_recording(data) {
  return http.get(`/officeuser/cdr/${data?.record_id}/recording`);
}

function cdr_inbound_list(data) {
  return http.get(`/officeuser/cdr/outbound/list`);
}

function cdr_outbound_list(data) {
  return http.get(`/officeuser/cdr/inbound/list`);
}

function all_user_iqcell_verimor(data) {
  return http.post(`/officeuser/detail/id/iqcell_bulk`, data.sendObj);
}

const callService = {
  cdr_outbound,
  cdr_inbound,
  cdr_answer,
  cdr_hangup,
  cdr_missed_list,
  cdr_missed_count,
  cdr_summary_list,
  cdr_recording,
  cdr_inbound_list,
  cdr_outbound_list,
  all_user_iqcell_verimor,
};

export default callService;
