import React, { useState } from "react";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SmsSettings from "./components/smsSettings";
import PropTypes from "prop-types";
import BannerSettings from "./components/bannerSettings";
import PopupSettings from "./components/popupSettings";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const NotificationTools = () => {
  const classes = useStyles();
  const [innerValue, setInnerValue] = useState(0);

  const handleChangeInnerTab = (event, newValue) => {
    setInnerValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={innerValue}
        onChange={handleChangeInnerTab}
        centered
        className={classes.tabs}
      >
        <Tab label="Banner" {...a11yProps(0)} />
        <Tab label="Popup" {...a11yProps(1)} />
        <Tab label="Sms" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={innerValue} index={0}>
        <BannerSettings />
      </TabPanel>
      <TabPanel value={innerValue} index={1}>
        <PopupSettings />
      </TabPanel>
      <TabPanel value={innerValue} index={2}>
        <SmsSettings />
      </TabPanel>
    </div>
  );
};

export default NotificationTools;
