import {
  AddBoxOutlined,
  CleaningServices,
  DeleteForever,
} from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { opsiyonFunc } from "./opsiyonFunc";
import menuTick from "../../../../../utils/assets/menu-tick.png";
import Select from "react-select/creatable";
import Select1 from "react-select";
import { selectDefaultStyles } from "../../../../../components/selectStyles/styleMenu";

export const optionDetailFunc = (
  row,
  forceUpdate,
  classes,
  optProductList,
  menuProductList
) => {
  if (row?.details?.length != 0) {
    return row?.details?.map((row1, ind) => {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              height: 60,
              backgroundColor: "white",
              marginTop: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
              borderRadius: 8,
              alignItems: "center",
              width: "80%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: 28,
                  height: 28,
                  borderRadius: 8,
                  backgroundColor: "#f53b62",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row?.select_all ? (
                  <img style={{ width: 16, height: 16 }} src={menuTick} />
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: 250, marginLeft: 20 }}>
                {row1?.optiondetail_type == "product" ? (
                  <Select1
                    placeholder="Ürün Seçimi"
                    styles={selectDefaultStyles}
                    value={{ label: row1?.name }}
                    fontSize={15}
                    menuColor={"#f4f4f4"}
                    hoverColor={"#f53b62"}
                    zIndex={999 - ind}
                    formatCreateLabel={(userInput) => `Oluştur - ${userInput}`}
                    options={
                      row1?.optiondetail_type == "product"
                        ? menuProductList?.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))
                        : optProductList?.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))
                    }
                    onKeyDown={(event) => {
                      if (event.key == "Enter") {
                        if (row1?.optiondetail_type != "product") {
                          row1.name = event.target.value;
                          forceUpdate();
                        }
                      }
                    }}
                    onChange={(e) => {
                      row1.name = e.label;
                      if (row1?.optiondetail_type == "product") {
                        row1.product = e.value;
                      }
                      forceUpdate();
                    }}
                  />
                ) : (
                  <Select
                    placeholder="Ürün Seçimi"
                    styles={selectDefaultStyles}
                    value={{ label: row1?.name }}
                    fontSize={15}
                    menuColor={"#f4f4f4"}
                    hoverColor={"#f53b62"}
                    zIndex={999 - ind}
                    formatCreateLabel={(userInput) => `Oluştur - ${userInput}`}
                    options={
                      row1?.optiondetail_type == "product"
                        ? menuProductList?.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))
                        : optProductList?.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))
                    }
                    onKeyDown={(event) => {
                      if (event.key == "Enter") {
                        if (row1?.optiondetail_type != "product") {
                          row1.name = event.target.value;
                          forceUpdate();
                        }
                      }
                    }}
                    onChange={(e) => {
                      row1.name = e.label;
                      if (row1?.optiondetail_type == "product") {
                        row1.product = e.value;
                      }
                      forceUpdate();
                    }}
                  />
                )}
              </div>
              <Paper
                component="form"
                style={{
                  width: 80,
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 20,
                }}
              >
                <InputBase
                  spellCheck="false"
                  value={row1?.price}
                  className={classes.input}
                  style={{
                    fontSize: 13,
                    width: "95%",
                    backgroundColor: "#f4f4f4",
                  }}
                  placeholder={"Fiyat"}
                  onChange={(e) => {
                    row1.price = e.target.value;
                    forceUpdate();
                  }}
                />
              </Paper>
            </div>

            <FormControl style={{ marginLeft: 35 }}>
              <RadioGroup
                row
                value={row1?.optiondetail_type}
                onChange={(e) => {
                  row1.optiondetail_type = e.target.value;
                  delete row1["name"];
                  delete row1["product"];
                  delete row1["optionalproduct"];
                  forceUpdate();
                }}
              >
                <FormControlLabel
                  placeholder="Alt Opsiyon"
                  value="product"
                  control={<Radio size="small" color="secondary" />}
                  label={
                    <span
                      style={{
                        fontSize: 13,
                      }}
                    >
                      Ürün
                    </span>
                  }
                />
                <FormControlLabel
                  value="optionalproduct"
                  control={<Radio size="small" color="secondary" />}
                  label={
                    <span
                      style={{
                        fontSize: 13,
                      }}
                    >
                      Opsiyonel Ürün
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>

            <div
              style={{
                width: "10%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DeleteForever
                style={{
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => {
                  row?.details.splice(ind, 1);
                  forceUpdate();
                }}
              />
              <AddBoxOutlined
                disabled={row?.num_max == 1 && row?.num_min == 1 ? false : true}
                style={{
                  width: 32,
                  height: 32,
                  cursor: "pointer",
                  color:
                    row?.num_max == 1 && row?.num_min == 1 ? "#000" : "gray",
                }}
                onClick={() => {
                  if (row?.num_max == 1 && row?.num_min == 1) {
                    row1.options.push({
                      name: "",
                      num_min: 0,
                      num_max: 0,
                      select_all: false,
                      select_limit: 0,
                      force_select_limit: false,
                      details: [],
                    });
                    forceUpdate();
                  }
                }}
              />
            </div>
          </div>

          <div>
            {opsiyonFunc(
              row1,
              forceUpdate,
              classes,
              optProductList,
              menuProductList
            )}
          </div>
        </div>
      );
    });
  }
};
