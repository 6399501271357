import React, { useContext, useState } from "react";
import Containner from "../components/containner";
import Header from "../components/header";
import { RestaurantContext } from "../contexts/restaurantContext";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { Collapse, Drawer } from "@mui/material";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import MobilRestaurantSidebar from "../components/sidebar/components/mobilRestaurantSidebar";
import CustomerAppBar from "../components/appBar";

const useStyles = makeStyles((theme) => ({
  drawerClose: {
    overflowX: "hidden",
    width: "14%",
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
}));

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 8,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 12,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});
const sidebarData = [
  {
    id: 0,
    label: "Platformlar",
    isactive: false,
    substate: true,
    substateType: "1",
    subMenu: [
      {
        sublabel: "Tabelalar",
        path: `/restaurants/tabela`,
        item_label: "tabela",
      },
      {
        sublabel: "Teklif",
        path: `/restaurants/offers`,
        item_label: "offers",
      },
      {
        sublabel: "Teknik SK",
        path: `/restaurants/secret_keys`,
        item_label: "secret_keys",
      },
      {
        sublabel: "Secret Key",
        path: `/restaurants/secret_key`,
        item_label: "secret_key",
      },
      {
        sublabel: "Menu",
        path: `/restaurants/menu`,
        item_label: "menu",
      },

      {
        sublabel: "Çalışma S",
        path: `/restaurants/working_hour`,
        item_label: "working_hour",
      },
      {
        sublabel: "Ödeme Y",
        path: `/restaurants/payment_type`,
        item_label: "payment_type",
      },
      {
        sublabel: "Kuryeler",
        path: `/restaurants/courier`,
        item_label: "courier",
      },
    ],
  },
  {
    id: 1,
    substate: true,
    substateType: "2",
    isactive: false,
    label: "Restoranlar",
    subMenu: [
      {
        sublabel: "Yazıcı / Ses",
        path: `/restaurants/printer`,
        item_label: "printer",
      },
    ],
  },

  {
    id: 2,
    isactive: false,
    label: "Yönetim Paneli",
    path: `/restaurants/management_panel`,
    item_label: "management_panel",
    //subMenu: [],
  },
  {
    id: 3,
    substate: true,
    substateType: "3",
    isactive: false,
    label: "Raporlar",
    subMenu: [
      {
        sublabel: "Sipariş / Ciro",
        path: `/restaurants/reports`,
        item_label: "reports",
      },
    ],
  },

  {
    id: 4,
    isactive: false,
    label: "Modüller",
    path: `/restaurants/module`,
    item_label: "module",
    //subMenu: [],
  },
  {
    id: 5,
    substate: true,
    substateType: "5",
    isactive: false,
    label: "Masa",
    subMenu: [
      {
        sublabel: "Masalar",
        path: `/operation/tables`,
        item_label: "tables",
      },
    ],
  },
  {
    id: 6,
    isactive: false,
    label: "CP Kurulum",
    path: `/restaurants/cp-kurulum`,
    item_label: "cp-kurulum",
  },
];

const RestaurantLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const { currentIndex, setCurrentIndex } = useContext(RestaurantContext);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [isOpenReports, setisOpenReport] = useState(false);
  const [isMobil, setMobil] = useState(false);
  const [isOpenDesk, setisOpenDesk] = useState(false);

  return (
    <div style={{ overflow: "hidden" }}>
      {isMatch ? (
        <Header image={""} title="Restoran" />
      ) : (
        <CustomerAppBar isMobil={isMobil} setMobil={setMobil} />
      )}
      <Containner>
        <div
          style={{
            height: "98%",
            width: "98%",
            borderRadius: 4,
            display: "flex",
            contain: "content",
            justifyContent: "space-between",
          }}
        >
          {isMatch ? (
            <ThemeProvider
              theme={createTheme({
                components: {
                  MuiListItemButton: {
                    defaultProps: {
                      disableTouchRipple: true,
                    },
                  },
                },
                palette: {
                  mode: "light",
                  primary: { main: "#f4f4f4" },
                  background: { paper: "#f4f4f4" },
                },
              })}
            >
              <Drawer
                variant="permanent"
                classes={{ paper: classes.drawerClose }}
                open={isMobil}
                onClose={() => setMobil(false)}
              >
                <FireNav
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                >
                  {sidebarData.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            item.substate
                              ? item.substateType == "1"
                                ? setOpen(!open)
                                : item.substateType == "2"
                                ? setOpen1(!open1)
                                : item.substateType === "3"
                                ? setisOpenReport(!isOpenReports)
                                : setisOpenDesk(!isOpenDesk)
                              : setCurrentIndex(item.item_label);
                          }}
                          key={item.label}
                          sx={{
                            "&:hover": {
                              backgroundColor: "white",
                            },
                            //py: 0, boyut kucultme
                            minHeight: 32,
                            backgroundColor: "white",
                            width: "85%",
                            borderRadius: 8,
                            color:
                              item.item_label &&
                              item.item_label === currentIndex
                                ? "#f53f62"
                                : "black",
                          }}
                        >
                          <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{
                              fontSize: 15,
                              fontWeight: "medium",
                              fontFamily: "segoeuiRg",
                            }}
                          />

                          {item.substate &&
                            (item.substateType == "1" ? (
                              open ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )
                            ) : item.substateType == "2" ? (
                              open1 ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )
                            ) : item.substateType === "3" ? (
                              isOpenReports ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )
                            ) : item.substateType === "5" ? (
                              isOpenDesk ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )
                            ) : null)}
                        </ListItemButton>
                        {item.substate &&
                          item.subMenu?.map((row, index) => {
                            return (
                              <Collapse
                                key={index}
                                in={
                                  item.substateType == "1"
                                    ? open
                                    : item.substateType == "2"
                                    ? open1
                                    : item.substateType === "3"
                                    ? isOpenReports
                                    : item.substateType === "5"
                                    ? isOpenDesk
                                    : null
                                }
                                timeout="auto"
                                unmountOnExit
                                sx={{
                                  width: "80%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  alignSelf: "flex-end",
                                }}
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  sx={{
                                    width: "90%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <ListItemButton
                                    onClick={() => {
                                      setCurrentIndex(row?.item_label);
                                    }}
                                    key={"5"}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "white",
                                      },
                                      py: 0,
                                      minHeight: 32,
                                      backgroundColor: "white",
                                      width: "85%",
                                      borderRadius: 8,
                                      color:
                                        row?.item_label == currentIndex
                                          ? "#f53f62"
                                          : "black",
                                    }}
                                  >
                                    <ListItemText
                                      primary={row.sublabel}
                                      primaryTypographyProps={{
                                        fontSize: 14,
                                        fontWeight: "medium",
                                        fontFamily: "segoeuiRg",
                                      }}
                                    />
                                  </ListItemButton>
                                </List>
                              </Collapse>
                            );
                          })}
                      </div>
                    );
                  })}
                </FireNav>
              </Drawer>
            </ThemeProvider>
          ) : (
            <MobilRestaurantSidebar
              openDrawer={isMobil}
              setOpenDrawer={setMobil}
            />
          )}
          <Box
            component="main"
            sx={{
              width: isMatch ? "85%" : "98%",
              backgroundColor: "#f4f4f4",
              borderRadius: 2,
              color: "#000",
              overflowY: "auto",
              paddingBottom: 8,
            }}
          >
            <main>{children}</main>
          </Box>
        </div>
      </Containner>
    </div>
  );
};

export default RestaurantLayout;
