export const selectDefaultStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.selectProps.menuColor,
    borderRadius: 6,
    width: state.selectProps.selectWidth,
    color: "#fff",
    border: 0,
    cursor: "pointer",
    minHeight: state.selectProps.responsiveHeight,
    height: state.selectProps.responsiveHeight,
    alignContent: "center",
    boxShadow: state.selectProps.boxShadowNotShown
      ? "2px 3px 2.5px 1px #C5C5BB"
      : "none",
  }),
  container: (styles, state) => ({
    ...styles,
    width: state.selectProps.selectWidth,
    zIndex: state.selectProps.zIndex,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#fff",
    fontFamily: "segoeuiRg",
    fontSize: 15,
    fontWeight: 600,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "white",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: "#ffffff",
    fontFamily: "segoeuiRg",
    fontSize: state.selectProps.selectFontSize,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "#5a5757",
      color: "white",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      fontSize: state.selectProps.selectFontSize,
      borderBottom: "1px solid gray",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#f53b62",
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: state.selectProps.hoverColor,
        color: "white",
      },
    };
  },
};
