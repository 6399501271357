import React, { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Avatar, Button, Divider, IconButton, Paper } from "@mui/material";
import CustomDatePickerInput from "../../../../components/datePickerInput";
import {
  DescriptionTwoTone,
  KeyboardArrowDown,
  KeyboardVoice,
  Notifications,
  Paid,
  PhonelinkOff,
  ReplyAll,
} from "@mui/icons-material";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import moment from "moment";
import LimitedUseModal from "../../../../components/modals/limitedUseModal";

import CardFlip from "react-card-flip";
import ActionModal from "../../../../components/modals/actionsModal";
import VoiceModal from "../../../../components/modals/voiceModal";
import ReminderModal from "../../../tasks/modals/reminderModal";
import { orderProductsImage } from "../../../../components/converter/orderProductsImage";
import RenewalProducts from "./renewalComponents/products";
import RenewalPlatforms from "./renewalComponents/platforms";
import RenewalOrders from "./renewalComponents/orders";
import RenewalAverageOrders from "./renewalComponents/averageOrders";
import RenewalPayed from "./renewalComponents/payed";

import Select from "react-select";
import { remaining_day } from "../../../../utils/constants/arrays";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev4";
import useDebounce from "../../../../components/debounce";
import SearchDark from "../../../../components/search/searchDark";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";
import RenewalActions from "./renewalComponents/actions";
import NewOrderTooltip from "../../../../components/tooltip";

moment.locale("tr");

const RenewalSummaryScreen = ({ dashboardType }) => {
  const [isOpen, setIsOpen] = useState(null);
  const contentRef = useRef(null);
  const {
    renewalSummaryListFunc,
    renewalSummaryListNew,
    renewalSummaryListProgress,
    renewalSummaryListCompleted,
    patchLimitedUseTask,
    summaryRestDetailFunc,
    summaryRestDetailInfo,
    getOfficeUserListRenewal,
    summaryRenewalAssingFunc,
    renewalOfficeusersListFunc,
    renewalOfficeusersList,
    appointmentTaskBackFunc,
    renewerListFunc,
    renewerList,
    renewerIncreaseRateDetailFunc,
    renewerIncreaseRateDetail,
  } = useContext(RestaurantContext);

  const [isFlipped, setIsFlipped] = useState(false);
  const [selectedOfficeUser, setSelectedOfficeUser] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [actionModalResId, setActionModalResId] = useState(null);
  const [typeSummary, setTypeSummary] = useState("active");
  const [limitedUseModal, setLimitedUseModal] = useState(false);
  const [currentLimitedUser, setCurrentLimitedUser] = useState(null);
  const [reminderModal, setReminderModal] = useState(false);
  const [reminderRow, setReminderRow] = useState(null);
  const [currentRestRow, setCurrentRestRow] = useState(null);
  const [voiceModal, setVoiceModal] = useState(false);
  const [passive, setPassive] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [selectedRemainingDay, setSelectedRemainingDay] = useState({
    value: 30,
    label: "30 Gün",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedsearchValue = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (dashboardType === "renewal_summary") {
      renewalSummaryListFunc({
        sendObj: {
          state: "new",
          remaining_day: selectedRemainingDay?.value,
          officeuser_id: selectedUser ? selectedUser?.id : "",
          restofficial_id:
            debouncedsearchValue?.length !== 0 ? debouncedsearchValue : "",
          passive: passive ? 1 : 0,
        },
      });
      renewalSummaryListFunc({
        sendObj: {
          state: "in_progress",
          officeuser_id: selectedUser ? selectedUser?.id : "",
          restofficial_id:
            debouncedsearchValue?.length !== 0 ? debouncedsearchValue : "",
          passive: passive ? 1 : 0,
        },
      });
      renewalSummaryListFunc({
        sendObj: {
          state: "closed",
          officeuser_id: selectedUser ? selectedUser?.id : "",
          restofficial_id:
            debouncedsearchValue?.length !== 0 ? debouncedsearchValue : "",
          passive: passive ? 1 : 0,
        },
      });
      renewerListFunc();
    }
  }, [
    selectedUser,
    dashboardType,
    selectedRemainingDay,
    debouncedsearchValue,
    passive,
  ]);

  useEffect(() => {
    if (dashboardType === "renewal_summary") {
      renewalOfficeusersListFunc();

      getOfficeUserListRenewal({
        task_type: "contract_renewal",
      });
    }
  }, [dashboardType]);

  const restaurantLimitedUse = () => {
    patchLimitedUseTask(currentLimitedUser?.restofficial?.id, {
      limited_use: !currentLimitedUser?.restofficial?.limited_use,
    });
    setTimeout(() => {
      renewalSummaryListFunc({
        sendObj: {
          state: "new",
          remaining_day: selectedRemainingDay?.value,
          officeuser_id: selectedUser ? selectedUser?.id : "",
          restofficial_id:
            debouncedsearchValue?.length !== 0 ? debouncedsearchValue : "",
          passive: passive ? 1 : 0,
        },
      });
      renewalSummaryListFunc({
        sendObj: {
          state: "in_progress",
          officeuser_id: selectedUser ? selectedUser?.id : "",
          restofficial_id:
            debouncedsearchValue?.length !== 0 ? debouncedsearchValue : "",
          passive: passive ? 1 : 0,
        },
      });
      renewalSummaryListFunc({
        sendObj: {
          state: "closed",
          officeuser_id: selectedUser ? selectedUser?.id : "",
          restofficial_id:
            debouncedsearchValue?.length !== 0 ? debouncedsearchValue : "",
          passive: passive ? 1 : 0,
        },
      });
    }, 1000);
  };

  function currencyFormat(num) {
    return (
      "₺ " +
      num
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80%",
            margin: 10,
            justifyContent: "space-evenly",
            alignSelf: "center",
          }}
        >
          <Select
            options={remaining_day}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#393737"}
            placeholder={"Seçiniz"}
            selectWidth={120}
            dynamiczIndex={3}
            boxShadowNotShown={false}
            value={selectedRemainingDay}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              setSelectedRemainingDay(e);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.label}</span>
              </div>
            )}
          />
          <Select
            styles={{
              ...selectDefaultStyles,
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            options={renewalOfficeusersList}
            darkMode={true}
            responsiveHeight={"auto"}
            selectFontSize={14}
            menuColor={"#393737"}
            hoverColor={"black"}
            isSearchable={false}
            selectWidth={"250px"}
            inputColor={"#f4f4f4"}
            textColor={"black"}
            placeHolderColor={"#393737"}
            inputFontSize={14}
            dropdownColor={"#393737"}
            isClearable={false}
            closeMenuOnSelect={true}
            placeholder="Kişi"
            getOptionLabel={(option) => option.full_name}
            getOptionValue={(option) => option.id}
            value={selectedUser}
            onChange={(e) => setSelectedUser(e)}
          />
          <div style={{ width: 300 }}>
            <SearchDark
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              placeholder={"Restoran Ara"}
            />
          </div>
          <div
            style={{
              boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
              borderRadius: 4,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
              backgroundColor: "#393737",
              padding: "0px 10px",
              height: 36,
            }}
          >
            <div
              style={{
                fontSize: 12,
                color: "#f4f4f4",
              }}
            >
              Pasif
            </div>
            <GreenSwitch
              onClick={() => {
                setPassive(!passive);
              }}
              checked={passive}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "33%",
              height: "80vh",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#ffb818",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "segoeuiB",
                fontSize: 13,
                padding: "5px 0px",
                color: "#fff",
                position: "relative",
              }}
            >
              YENİ ({renewalSummaryListNew?.length})
            </div>

            <div
              style={{
                overflow: "auto",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {renewalSummaryListNew?.map((row) => {
                return (
                  <Paper
                    elevation={3}
                    style={{
                      width: "90%",
                      display: "flex",
                      padding: 5,
                      margin: "10px 10px",
                      backgroundColor: "#393737",
                      boxShadow:
                        isOpen === row?.restofficial?.id
                          ? "#f30000 0px 0px 6px 3px"
                          : "rgb(91 91 91 / 75%) 0px 0px 1px 1px",
                      flexDirection: "column",
                    }}
                  >
                    <div className="accordion">
                      <div
                        className="accordion-header"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#474545",
                          color: "#f4f4f4",
                          fontFamily: "segoeuiB",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Button
                            style={{
                              width: "100%",
                              height: 20,
                              backgroundColor: "#474545",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#f4f4f4",
                            }}
                            onClick={() => {
                              if (isOpen === row?.restofficial?.id) {
                                setIsOpen(null);
                              } else {
                                setIsOpen(row?.restofficial?.id);
                              }
                              setIsFlipped(false);
                              summaryRestDetailFunc({
                                rest_id: row?.restofficial?.id,
                                sendObj: {
                                  state: "new",
                                },
                              });
                            }}
                          >
                            <KeyboardArrowDown />
                          </Button>
                          <div
                            style={{
                              borderRadius: 4,
                              width: "auto",
                              height: 15,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#497bd1",
                              fontSize: 13,
                              padding: 3,
                              marginLeft: 4,
                            }}
                          >
                            {moment(row?.offer?.expires_at).diff(
                              moment(),
                              "days"
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                            cursor: "pointer",
                            maxWidth: "70%",
                          }}
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/restaurants/${row.restofficial?.id}`,
                              "_blank"
                            );
                          }}
                        >
                          <div
                            style={{
                              width: 13,
                              height: 13,
                              backgroundColor: row?.restofficial?.online
                                ? "green"
                                : "red",
                              borderRadius: 99,
                              marginRight: 5,
                            }}
                          />
                          {row?.restofficial?.name} ({" "}
                          {moment(row?.restofficial?.online_date).fromNow()} )
                        </div>

                        <PhonelinkOff
                          style={{
                            cursor:
                              row?.restofficial?.version >= "1.1.5"
                                ? "pointer"
                                : "default",
                            color:
                              row?.restofficial?.version >= "1.1.5"
                                ? row?.restofficial?.limited_use
                                  ? "#f30000"
                                  : "green"
                                : "gray",
                          }}
                          onClick={() => {
                            if (row?.restofficial?.version >= "1.1.5") {
                              setCurrentLimitedUser(row);
                              setLimitedUseModal(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          textAlign: "-webkit-center",
                          margin: 10,
                        }}
                      >
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Satış
                        </div>
                        <Avatar
                          alt="backoffice_user"
                          src={row?.processed_by?.picture}
                          sx={{ width: 64, height: 64, cursor: "pointer" }}
                        />
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          {row?.processed_by?.name}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "40%",
                          textAlign: "-webkit-center",
                          margin: 10,
                        }}
                      >
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Üstünde
                        </div>
                        <Avatar
                          alt="backoffice_user"
                          src={row?.origin_collector?.picture}
                          sx={{ width: 64, height: 64, cursor: "pointer" }}
                        />
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          {row?.origin_collector?.name}
                        </div>
                      </div>
                    </div>

                    {isOpen === row?.restofficial?.id && (
                      <div
                        ref={contentRef}
                        className="accordion-content"
                        style={{
                          maxHeight:
                            isOpen === row?.restofficial?.id ? `auto` : "0px",
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-around",
                          backgroundColor: "#393737",
                          color: "#f4f4f4",
                        }}
                      >
                        <div
                          className="accordion-text"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              width: "100%",
                            }}
                          >
                            <RenewalProducts
                              summaryRestDetailInfo={summaryRestDetailInfo}
                            />
                            <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "gray",
                                width: "0.1px",
                                height: "auto",
                              }}
                            />
                            <RenewalPlatforms
                              summaryRestDetailInfo={summaryRestDetailInfo}
                            />
                          </div>
                          <RenewalOrders
                            summaryRestDetailInfo={summaryRestDetailInfo}
                          />
                          <RenewalAverageOrders
                            summaryRestDetailInfo={summaryRestDetailInfo}
                          />
                          <RenewalPayed
                            summaryRestDetailInfo={summaryRestDetailInfo}
                          />
                          <RenewalActions
                            setVoiceModal={setVoiceModal}
                            setCurrentRestRow={setCurrentRestRow}
                            setReminderRow={setReminderRow}
                            setReminderModal={setReminderModal}
                            setActionModal={setActionModal}
                            setActionModalResId={setActionModalResId}
                            summaryRestDetailInfo={summaryRestDetailInfo}
                            row={row}
                            appointmentTaskBackFunc={appointmentTaskBackFunc}
                            summaryRestDetailFunc={summaryRestDetailFunc}
                          />
                          <div style={{ marginTop: 30 }}>
                            <div
                              style={{
                                width: "100%",
                                height: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 0px 4.5px 1px gray",
                                fontFamily: "segoeuiB",
                                fontSize: 13,
                                fontVariant: "all-petite-caps",
                              }}
                            >
                              Aktar
                            </div>
                            <div
                              style={{
                                width: "100%",
                                height: 45,
                                backgroundColor: "#494343",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                overflow: "auto",
                              }}
                            >
                              <CardFlip
                                isFlipped={isFlipped === row?.restofficial?.id}
                                containerStyle={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                  }}
                                >
                                  {renewalOfficeusersList?.map((item) => {
                                    if (item?.full_name === "Tümü") return;
                                    return (
                                      <Avatar
                                        alt="backoffice_user"
                                        src={item?.picture}
                                        sx={{
                                          width: 32,
                                          height: 32,
                                          cursor: "pointer",
                                          marginLeft: 1,
                                        }}
                                        onClick={() => {
                                          setIsFlipped(row?.restofficial?.id);
                                          setSelectedOfficeUser(item);
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "50%",
                                    }}
                                  >
                                    <Avatar
                                      alt="backoffice_user"
                                      src={selectedOfficeUser?.picture}
                                      sx={{
                                        width: 32,
                                        height: 32,
                                        cursor: "pointer",
                                        marginLeft: 1,
                                      }}
                                    />
                                    <div style={{ marginLeft: 5 }}>
                                      {selectedOfficeUser?.full_name}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "50%",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: "#f53b62",
                                        color: "#fff",
                                        fontFamily: "segoeuiB",
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "center",
                                        height: 20,
                                      }}
                                      size="small"
                                      onClick={() => {
                                        setIsFlipped(false);
                                        setSelectedOfficeUser(null);
                                      }}
                                    >
                                      Hayır
                                    </Button>
                                    <Button
                                      style={{
                                        backgroundColor: "green",
                                        color: "#fff",
                                        fontFamily: "segoeuiB",
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "center",
                                        height: 20,
                                      }}
                                      size="small"
                                      onClick={() => {
                                        let data = {
                                          rest_id: row?.restofficial?.id,
                                          sendObj: {
                                            officeuser_id:
                                              selectedOfficeUser?.id,
                                          },
                                          remaining_day:
                                            selectedRemainingDay?.value,
                                          officeuser_id: selectedUser
                                            ? selectedUser?.id
                                            : "",
                                          restofficial_id:
                                            debouncedsearchValue?.length !== 0
                                              ? debouncedsearchValue
                                              : "",
                                          passive: passive ? 1 : 0,
                                        };
                                        summaryRenewalAssingFunc(data);
                                      }}
                                    >
                                      Aktar
                                    </Button>
                                  </div>
                                </div>
                              </CardFlip>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Paper>
                );
              })}
            </div>
          </div>
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "gray",
              width: "1px",
              height: "80vh",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "33%",
              height: "80vh",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#6aa1ff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "segoeuiB",
                fontSize: 13,
                padding: "5px 0px",
                color: "#fff",
                position: "relative",
              }}
            >
              İŞLEMDE ({renewalSummaryListProgress?.length})
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  height: 45,

                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  overflow: "auto",
                }}
              >
                {renewalOfficeusersList?.map((item) => {
                  return (
                    <div
                      style={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "gray",
                        padding: "2px 5px",
                        borderRadius: 2,
                        cursor: "pointer",
                        margin: "0px 3px",
                        height: "75%",
                        backgroundColor:
                          selectedUser?.id === item?.id ? "green" : "#494343",
                      }}
                      onClick={() => {
                        setSelectedUser(item);
                      }}
                    >
                      {item?.full_name === "Tümü" ? (
                        <ReplyAll
                          style={{ width: 32, height: 32, color: "#f4f4f4" }}
                        />
                      ) : (
                        <>
                          <Avatar
                            alt="backoffice_user"
                            src={item?.picture}
                            sx={{
                              width: 28,
                              height: 28,
                              cursor: "pointer",
                            }}
                          />
                          <div
                            style={{
                              marginLeft: 5,
                              fontFamily: "segoeuiB",
                              color: "#f4f4f4",
                            }}
                          >
                            {item.renewal_tasks}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                overflow: "auto",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {renewalSummaryListProgress?.map((row) => {
                return (
                  <Paper
                    elevation={3}
                    style={{
                      width: "90%",
                      display: "flex",
                      padding: 5,
                      margin: "10px 10px",
                      backgroundColor: "#393737",
                      boxShadow:
                        isOpen === row?.restofficial?.id
                          ? "#f30000 0px 0px 6px 3px"
                          : "rgb(91 91 91 / 75%) 0px 0px 1px 1px",
                      flexDirection: "column",
                    }}
                  >
                    <div className="accordion">
                      <div
                        className="accordion-header"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: row?.reminder
                            ? "#54735c"
                            : "#474545",
                          color: "#f4f4f4",
                          fontFamily: "segoeuiB",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Button
                            style={{
                              width: "100%",
                              height: 20,
                              backgroundColor: "#474545",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#f4f4f4",
                            }}
                            onClick={() => {
                              if (isOpen === row?.restofficial?.id) {
                                setIsOpen(null);
                              } else {
                                setIsOpen(row?.restofficial?.id);
                              }
                              setIsFlipped(false);

                              summaryRestDetailFunc({
                                rest_id: row?.restofficial?.id,
                                sendObj: {
                                  state: "in_progress",
                                },
                              });
                            }}
                          >
                            <KeyboardArrowDown />
                          </Button>
                          <div
                            style={{
                              borderRadius: 4,
                              width: "auto",
                              height: 15,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#497bd1",
                              fontSize: 13,
                              padding: 3,
                              marginLeft: 4,
                              minWidth: 25,
                            }}
                          >
                            {row?.offer_tracker
                              ? moment().diff(
                                  moment(row?.offer_tracker?.start_at),
                                  "days"
                                )
                              : "Oto"}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                            cursor: "pointer",
                            maxWidth: "70%",
                          }}
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/restaurants/${row.restofficial?.id}`,
                              "_blank"
                            );
                          }}
                        >
                          <div
                            style={{
                              width: 13,
                              height: 13,
                              backgroundColor: row?.restofficial?.online
                                ? "green"
                                : "red",
                              borderRadius: 99,
                              marginRight: 5,
                            }}
                          />
                          {row?.restofficial?.name} ({" "}
                          {moment(row?.restofficial?.online_date).fromNow()} )
                        </div>

                        <PhonelinkOff
                          style={{
                            cursor:
                              row?.restofficial?.version >= "1.1.5"
                                ? "pointer"
                                : "default",
                            color:
                              row?.restofficial?.version >= "1.1.5"
                                ? row?.restofficial?.limited_use
                                  ? "#f30000"
                                  : "green"
                                : "gray",
                          }}
                          onClick={() => {
                            if (row?.restofficial?.version >= "1.1.5") {
                              setCurrentLimitedUser(row);
                              setLimitedUseModal(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          width: "20%",
                          textAlign: "-webkit-center",
                          margin: 10,
                        }}
                      >
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Aktarılan
                        </div>
                        <Avatar
                          alt="backoffice_user"
                          src={row?.origin_collector?.picture}
                          sx={{ width: 64, height: 64, cursor: "pointer" }}
                        />
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          {row?.origin_collector?.name}
                        </div>
                        {row?.offer && (
                          <div
                            style={{
                              width: "100%",
                              height: 20,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              boxShadow: "0px 0px 4.5px 1px gray",
                              fontFamily: "segoeuiB",
                              fontSize: 11,
                              fontVariant: "all-petite-caps",
                              color: "#f4f4f4",
                              padding: "0px 5px",
                              marginTop: 10,
                            }}
                          >
                            <Paid style={{ width: 16, height: 16 }} />
                            {"  "}
                            {currencyFormat(row?.offer?.eft_amonts)}
                          </div>
                        )}
                      </div>
                      {row?.offer ? (
                        <div
                          style={{
                            width: "80%",
                            textAlign: "-webkit-center",
                            margin: 10,
                            fontSize: 13,
                            display: "flex",
                            flexDirection: "row",
                            color: "#f4f4f4",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                width: "100%",
                                height: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 0px 4.5px 1px gray",
                                fontFamily: "segoeuiB",
                                fontSize: 13,
                                fontVariant: "all-petite-caps",
                              }}
                            >
                              Yeni Teklif
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                              }}
                            >
                              {row?.offer?.products?.map((row1) => {
                                if (!row1?.renew) return;
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                      padding: 5,
                                      backgroundColor: "#494343",
                                      margin: 5,
                                      borderRadius: 4,
                                      flexDirection: "column",
                                    }}
                                  >
                                    <img
                                      src={orderProductsImage(row1.key)}
                                      alt={row1?.key}
                                      style={{
                                        width: 30,
                                        height: 30,
                                      }}
                                    />
                                    <div>{currencyFormat(row1?.amount)}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                width: "100%",
                                height: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 0px 4.5px 1px gray",
                                fontFamily: "segoeuiB",
                                fontSize: 13,
                                fontVariant: "all-petite-caps",
                                color: "#f4f4f4",
                              }}
                            >
                              Çapraz
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                              }}
                            >
                              {row?.offer?.products?.map((row1) => {
                                if (row1?.renew) return;
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                      padding: 5,
                                      backgroundColor: "#494343",
                                      margin: 5,
                                      borderRadius: 4,
                                      flexDirection: "column",
                                    }}
                                  >
                                    <img
                                      src={orderProductsImage(row1.key)}
                                      alt={row1?.key}
                                      style={{
                                        width: 30,
                                        height: 30,
                                      }}
                                    />
                                    <div>{currencyFormat(row1?.amount)}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "80%",
                            textAlign: "-webkit-center",
                            margin: 10,
                            fontSize: 20,
                            display: "flex",
                            flexDirection: "row",
                            color: "#f4f4f4",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: "segoeuiB",
                            fontVariant: "all-petite-caps",
                          }}
                        >
                          Teklif Yok
                        </div>
                      )}
                    </div>

                    {isOpen === row?.restofficial?.id && (
                      <div
                        ref={contentRef}
                        className="accordion-content"
                        style={{
                          maxHeight:
                            isOpen === row?.restofficial?.id ? `auto` : "0px",
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-around",
                          backgroundColor: "#393737",
                          color: "#f4f4f4",
                        }}
                      >
                        <div
                          className="accordion-text"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              width: "100%",
                            }}
                          >
                            <RenewalProducts
                              summaryRestDetailInfo={summaryRestDetailInfo}
                            />
                            <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "gray",
                                width: "0.1px",
                                height: "auto",
                              }}
                            />
                            <RenewalPlatforms
                              summaryRestDetailInfo={summaryRestDetailInfo}
                            />
                          </div>
                          <RenewalOrders
                            summaryRestDetailInfo={summaryRestDetailInfo}
                          />
                          <RenewalAverageOrders
                            summaryRestDetailInfo={summaryRestDetailInfo}
                          />
                          <RenewalPayed
                            summaryRestDetailInfo={summaryRestDetailInfo}
                          />

                          <RenewalActions
                            setVoiceModal={setVoiceModal}
                            setCurrentRestRow={setCurrentRestRow}
                            setReminderRow={setReminderRow}
                            setReminderModal={setReminderModal}
                            setActionModal={setActionModal}
                            setActionModalResId={setActionModalResId}
                            summaryRestDetailInfo={summaryRestDetailInfo}
                            row={row}
                            appointmentTaskBackFunc={appointmentTaskBackFunc}
                            summaryRestDetailFunc={summaryRestDetailFunc}
                          />
                        </div>
                      </div>
                    )}
                  </Paper>
                );
              })}
            </div>
          </div>
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "gray",
              width: "1px",
              height: "80vh",
            }}
          />{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "33%",
              height: "80vh",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "green",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "segoeuiB",
                fontSize: 13,
                padding: "5px 0px",
                color: "#fff",
                position: "relative",
              }}
            >
              KAPANAN ({renewalSummaryListCompleted?.length})
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#393737",
                height: "auto",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {renewerList?.officeuser_stats?.map((item) => {
                return (
                  <div
                    style={{
                      width: "47%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      background: "gray",
                      padding: "2px 5px",
                      borderRadius: 2,
                      cursor: "pointer",
                      margin: "0px 3px",
                      height: 45,
                      backgroundColor: "#494343",
                      marginTop: 10,
                    }}
                  >
                    <Avatar
                      alt="backoffice_user"
                      src={item?.picture}
                      sx={{
                        width: 28,
                        height: 28,
                        cursor: "pointer",
                      }}
                    />

                    <Paper
                      style={{
                        backgroundColor: "#403836",

                        width: 50,
                        color: "#fff",
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        height: 30,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 10,
                          fontFamily: "monospace",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: 2,
                        }}
                      >
                        T Ak.
                        <div
                          style={{
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          {item?.stats?.total_blocked +
                            item?.stats?.total_completed}
                        </div>
                      </div>
                    </Paper>
                    <Paper
                      style={{
                        backgroundColor: "#403836",
                        width: 50,
                        color: "#fff",
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        height: 30,
                        alignItems: "center",
                      }}
                    >
                      <NewOrderTooltip
                        title={
                          <div
                            style={{
                              overflow: "auto",
                              padding: 2,
                              fontSize: 12,
                            }}
                          >
                            {renewerIncreaseRateDetail?.stats?.map((row) => {
                              return (
                                <div>
                                  {Object.entries(row).map(([key, value]) => (
                                    <div style={{ marginTop: 4 }}>
                                      {key} - %{value} <br />
                                    </div>
                                  ))}
                                </div>
                              );
                            })}
                          </div>
                        }
                        placement="top"
                        open={openToolTip == `open_${item?.id}` ? true : false}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: "SgNr",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={(e) => {
                            if (openToolTip == `open_${item?.id}`) {
                              setOpenToolTip(false);
                            } else {
                              e.stopPropagation();
                              setOpenToolTip(`open_${item?.id}`);
                              renewerIncreaseRateDetailFunc({
                                user_id: item?.id,
                              });
                            }
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontFamily: "monospace",
                              textAlign: "center",
                            }}
                          >
                            Zam
                            <div
                              style={{
                                fontSize: 13,
                              }}
                            >
                              Detay
                            </div>
                          </div>
                        </div>
                      </NewOrderTooltip>
                    </Paper>
                    <Paper
                      style={{
                        backgroundColor: "#403836",

                        width: 50,
                        color: "#fff",
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        height: 30,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 10,
                          fontFamily: "monospace",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          textAlign: "center",
                          padding: 2,
                        }}
                      >
                        Bloke
                        <div
                          style={{
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          %
                          {item?.stats?.total_blocked !== 0
                            ? (
                                (item?.stats?.total_blocked /
                                  (item?.stats?.total_blocked +
                                    item?.stats?.total_completed)) *
                                100
                              ).toFixed(2)
                            : 0}
                        </div>
                      </div>
                    </Paper>
                    <Paper
                      style={{
                        backgroundColor: "#403836",
                        width: 50,
                        color: "#fff",
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        height: 30,
                        alignItems: "center",
                      }}
                    >
                      <NewOrderTooltip
                        title={
                          <div
                            style={{
                              overflow: "auto",
                              padding: 2,
                              fontSize: 12,
                            }}
                          >
                            <div>Başarısız</div>
                            {item?.stats?.blocked_time}
                          </div>
                        }
                        placement="top"
                        open={
                          openToolTip == `open_hourly_${item?.id}`
                            ? true
                            : false
                        }
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: "SgNr",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={(e) => {
                            if (openToolTip == `open_hourly_${item?.id}`) {
                              setOpenToolTip(false);
                            } else {
                              e.stopPropagation();
                              setOpenToolTip(`open_hourly_${item?.id}`);
                            }
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontFamily: "monospace",
                              textAlign: "center",
                            }}
                          >
                            Başarılı
                            <div
                              style={{
                                fontSize: 13,
                              }}
                            >
                              {item?.stats?.completed_time}
                            </div>
                          </div>
                        </div>
                      </NewOrderTooltip>
                    </Paper>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                overflow: "auto",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {renewalSummaryListCompleted?.map((row) => {
                return (
                  <Paper
                    elevation={3}
                    style={{
                      width: "90%",
                      display: "flex",
                      padding: 5,
                      margin: "10px 10px",
                      backgroundColor: "#393737",
                      boxShadow:
                        isOpen === row?.restofficial?.id
                          ? "#f30000 0px 0px 6px 3px"
                          : "rgb(91 91 91 / 75%) 0px 0px 1px 1px",
                      flexDirection: "column",
                    }}
                  >
                    <div className="accordion">
                      <div
                        className="accordion-header"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#474545",
                          color: "#f4f4f4",
                          fontFamily: "segoeuiB",
                        }}
                      >
                        <div>
                          <Button
                            style={{
                              width: "100%",
                              height: 20,
                              backgroundColor: "#474545",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#f4f4f4",
                            }}
                            onClick={() => {
                              if (isOpen === row?.restofficial?.id) {
                                setIsOpen(null);
                              } else {
                                setIsOpen(row?.restofficial?.id);
                              }
                              setIsFlipped(false);

                              summaryRestDetailFunc({
                                rest_id: row?.restofficial?.id,
                                sendObj: {
                                  state: "closed",
                                },
                              });
                            }}
                          >
                            <KeyboardArrowDown />
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                            cursor: "pointer",
                            maxWidth: "70%",
                          }}
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/restaurants/${row.restofficial?.id}`,
                              "_blank"
                            );
                          }}
                        >
                          <div
                            style={{
                              width: 13,
                              height: 13,
                              backgroundColor: row?.restofficial?.online
                                ? "green"
                                : "red",
                              borderRadius: 99,
                              marginRight: 5,
                            }}
                          />
                          {row?.restofficial?.name} ({" "}
                          {moment(row?.restofficial?.online_date).fromNow()} )
                        </div>

                        <PhonelinkOff
                          style={{
                            cursor:
                              row?.restofficial?.version >= "1.1.5"
                                ? "pointer"
                                : "default",
                            color:
                              row?.restofficial?.version >= "1.1.5"
                                ? row?.restofficial?.limited_use
                                  ? "#f30000"
                                  : "green"
                                : "gray",
                          }}
                          onClick={() => {
                            if (row?.restofficial?.version >= "1.1.5") {
                              setCurrentLimitedUser(row);
                              setLimitedUseModal(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          width: "20%",
                          textAlign: "-webkit-center",
                          margin: 10,
                        }}
                      >
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Tahsilat
                        </div>
                        <Avatar
                          alt="backoffice_user"
                          src={row?.origin_collector?.picture}
                          sx={{ width: 64, height: 64, cursor: "pointer" }}
                        />
                        <div
                          style={{
                            color: "#f4f4f4",
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          {row?.origin_collector?.name}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 20,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            boxShadow: "0px 0px 4.5px 1px gray",
                            fontFamily: "segoeuiB",
                            fontSize: 13,
                            fontVariant: "all-petite-caps",
                            color: "#f4f4f4",
                            padding: "0px 5px",
                            marginTop: 10,
                          }}
                        >
                          <Paid style={{ width: 16, height: 16 }} />
                          {"  "}
                          {row?.offer &&
                            currencyFormat(row?.offer?.paid_amount)}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "80%",
                          textAlign: "-webkit-center",
                          margin: 10,
                          fontSize: 13,
                          display: "flex",
                          flexDirection: "row",
                          color: "#f4f4f4",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              width: "100%",
                              height: 20,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0px 0px 4.5px 1px gray",
                              fontFamily: "segoeuiB",
                              fontSize: 13,
                              fontVariant: "all-petite-caps",
                            }}
                          >
                            Uzatılan Ürünler
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            {row?.offer?.products?.map((row1) => {
                              if (!row1?.renew) return;
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    padding: 5,
                                    backgroundColor: "#494343",
                                    margin: 5,
                                    borderRadius: 4,
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={orderProductsImage(row1.key)}
                                    alt={row1?.key}
                                    style={{
                                      width: 30,
                                      height: 30,
                                    }}
                                  />
                                  <div>{currencyFormat(row1?.amount)}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              width: "100%",
                              height: 20,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0px 0px 4.5px 1px gray",
                              fontFamily: "segoeuiB",
                              fontSize: 13,
                              fontVariant: "all-petite-caps",
                              color: "#f4f4f4",
                            }}
                          >
                            Çapraz
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            {row?.offer?.products?.map((row1) => {
                              if (row1?.renew) return;
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    padding: 5,
                                    backgroundColor: "#494343",
                                    margin: 5,
                                    borderRadius: 4,
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={orderProductsImage(row1.key)}
                                    alt={row1?.key}
                                    style={{
                                      width: 30,
                                      height: 30,
                                    }}
                                  />
                                  <div>{currencyFormat(row1?.amount)}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    {isOpen === row?.restofficial?.id && (
                      <div
                        ref={contentRef}
                        className="accordion-content"
                        style={{
                          maxHeight:
                            isOpen === row?.restofficial?.id ? `auto` : "0px",
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-around",
                          backgroundColor: "#393737",
                          color: "#f4f4f4",
                        }}
                      >
                        <div
                          className="accordion-text"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              width: "100%",
                            }}
                          >
                            <RenewalProducts
                              summaryRestDetailInfo={summaryRestDetailInfo}
                            />
                            <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "gray",
                                width: "0.1px",
                                height: "auto",
                              }}
                            />
                            <RenewalPlatforms
                              summaryRestDetailInfo={summaryRestDetailInfo}
                            />
                          </div>
                          <RenewalActions
                            setVoiceModal={setVoiceModal}
                            setCurrentRestRow={setCurrentRestRow}
                            setReminderRow={setReminderRow}
                            setReminderModal={setReminderModal}
                            setActionModal={setActionModal}
                            setActionModalResId={setActionModalResId}
                            summaryRestDetailInfo={summaryRestDetailInfo}
                            row={row}
                            appointmentTaskBackFunc={appointmentTaskBackFunc}
                            summaryRestDetailFunc={summaryRestDetailFunc}
                          />
                        </div>
                      </div>
                    )}
                  </Paper>
                );
              })}
            </div>
          </div>
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "gray",
              width: "1px",
              height: "80vh",
            }}
          />
        </div>
      </div>
      <LimitedUseModal
        limitedUseModal={limitedUseModal}
        setLimitedUseModal={setLimitedUseModal}
        restaurantLimitedUse={restaurantLimitedUse}
        restOfficialData={currentLimitedUser?.restofficial}
      />
      <ActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        actionModalResId={actionModalResId}
        setActionModalResId={setActionModalResId}
        actionVisiblity={false}
      />
      <VoiceModal
        voiceModal={voiceModal}
        setVoiceModal={setVoiceModal}
        currentRestRow={currentRestRow}
        type="summary"
      />
      <ReminderModal
        reminderModal={reminderModal}
        setReminderModal={setReminderModal}
        reminderType="restofficial"
        reminderRow={reminderRow}
        setReminderRow={setReminderRow}
        fetchData={{
          selectedUser,
          typeSummary,
          rest_id: isOpen,
        }}
        dashboardType={"renewal"}
      />
    </>
  );
};

export default RenewalSummaryScreen;
