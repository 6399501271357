import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";

export default function DataModal({
  openDataModal,
  setOpenDataModal,
  data,
  title,
  headers,
  rows,
  setData,
  setRows,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  function convertToHourAndMinutes(seconds) {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.round((seconds % 3600) / 60);

    const result = (hour !== 0 ? hour + " saat " : "") + minute + " dakika";

    return result;
  }

  return (
    <Modal
      open={openDataModal}
      onClose={() => {
        setOpenDataModal(false);
        setData([]);
        setRows([]);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {title}
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "450px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headers?.map((header, ind) => {
                    return (
                      <TableCell key={ind} align="center">
                        {header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, ind) => {
                  return (
                    <TableRow key={ind}>
                      {rows.map((row, i) => {
                        if (
                          row === "total_amount" ||
                          row === "total_base_amount" ||
                          row === "discount_amount"
                        ) {
                          item[row] = item[row].toLocaleString("tr-TR", {
                            style: "currency",
                            currency: "TRY",
                          });
                        }

                        let date;
                        if (
                          row === "payed_at" ||
                          row === "start_at" ||
                          row === "created_at" ||
                          row === "completed_at"
                        ) {
                          date = moment(item[row]).format("DD-MM-YYYY HH:mm");
                        }

                        let name;
                        if (item[row]?.name) {
                          if (row === "restofficial") {
                            name = item[row].user_id;
                          } else {
                            name = item[row].name;
                          }
                        }

                        let timeSpent;
                        if (row === "time_spent") {
                          timeSpent = convertToHourAndMinutes(item[row]);
                        }

                        return (
                          <TableCell
                            key={i}
                            align="center"
                            style={{
                              cursor: row === "id" ? "pointer" : "auto",
                              textDecoration:
                                row === "id" ? "underline" : "none",
                              color: row === "id" ? "blue" : "black",
                            }}
                            onClick={() => {
                              if (
                                row === "id" ||
                                row === "restofficial" ||
                                row === "restofficial_id"
                              ) {
                                window.open(
                                  `restaurants/${
                                    item.restofficial_id
                                      ? item.restofficial_id
                                      : item[row].user_id
                                  }`,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            {name
                              ? name
                              : date
                              ? date
                              : timeSpent
                              ? timeSpent
                              : item[row]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
