import {
  Avatar,
  Chip,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import restaurantService from "../../../services/restaurantService";
import sepettakipLogo from "../../../utils/assets/sepettakipLogos/sepettakip-logo.webp";
import sepetappLogo from "../../../utils/assets/sepettakipLogos/sepetapp-logo.webp";
import sepetfastLogo from "../../../utils/assets/sepettakipLogos/sepetfast-logo.webp";
import calleridLogo from "../../../utils/assets/sepettakipLogos/callerid-logo.webp";
import courierLogo from "../../../utils/assets/sepettakipLogos/courier-logo.webp";
import stoktakipLogo from "../../../utils/assets/sepettakipLogos/stoktakip-logo.webp";
import masatakipLogo from "../../../utils/assets/sepettakipLogos/masatakip-logo.webp";
import callerplusLogo from "../../../utils/assets/sepettakipLogos/caller-logo.webp";
import emptyImg from "../../../utils/assets/empty.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { platformIconImageConverter } from "../../../components/converter/platformImage";
import moment from "moment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { PieChart } from "@mui/x-charts";

const OfficeuserPrim = () => {
  let history = useHistory();
  const [notPayedTable, setNotPayedTable] = useState(null);
  const [payedTable, setPayedTable] = useState(null);
  const [primProfile, setPrimProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await restaurantService.get_prim_profit("notpayed");
      setNotPayedTable(res);
    })();

    (async () => {
      const res = await restaurantService.get_prim_profit("payed");
      setPayedTable(res);
    })();

    (async () => {
      const res = await restaurantService.get_prim_profile();
      setPrimProfile(res);
    })();
  }, []);

  const calcDateCreatedAt = (date) => {
    const diff = moment().diff(moment(date), "days");

    if (diff === 0) {
      return moment().diff(moment(date), "hours") + "saat";
    } else {
      return moment().diff(moment(date), "days") + "gün";
    }
  };

  return (
    <div style={{ padding: 30 }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: 50,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <Avatar
            alt="backoffice_user"
            src={primProfile?.picture}
            sx={{ width: 90, height: 90, cursor: "pointer" }}
          />

          <div
            style={{
              background: "gray",
              width: "100%",
              borderRadius: 8,
              textAlign: "center",
              fontSize: 24,
              fontWeight: "bold",
              color: "#fff",
              minHeight: 100,
              marginLeft: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "25%",
                height: 140,
                display: "flex",
                alignItems: "center",
              }}
            >
              <PieChart
                height={130}
                width={120}
                series={[
                  {
                    data: [
                      {
                        label: "Mevcut Kurulum",
                        value: notPayedTable?.setup_count,
                        color: "#348734",
                      },
                      {
                        label: "Hedefe Kalan",
                        value:
                          notPayedTable?.extra_prim_value_range[
                            notPayedTable?.extra_prim_value_range.length - 1
                          ].start - notPayedTable?.setup_count,
                        color: "#b54848",
                      },
                    ],
                    innerRadius: 30,
                    outerRadius: 60,
                    paddingAngle: 0,
                    cornerRadius: 0,
                    startAngle: 0,
                    endAngle: 1000,
                    cx: 50,
                    valueFormatter: (item) => item.value,
                  },
                ]}
                slotProps={{ legend: { hidden: true } }}
                skipAnimation={false}
              >
                <text
                  x={55}
                  y={68}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{ fontFamily: "math", fontWeight: 800 }}
                >
                  {
                    notPayedTable?.extra_prim_value_range[
                      notPayedTable?.extra_prim_value_range.length - 1
                    ].start
                  }
                </text>
              </PieChart>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {notPayedTable?.extra_prim_value_range?.map((row) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        height: 40,
                        alignItems: "center",
                      }}
                    >
                      <Paper
                        style={{
                          backgroundColor: "#403836",
                          width: 50,
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          height: 20,
                          alignItems: "center",
                          fontSize: 13,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "monospace",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 2,
                          }}
                        >
                          {row?.start}
                        </div>
                      </Paper>
                      <Divider
                        orientation="vertical"
                        sx={{
                          backgroundColor: "#f4f4f4",
                          width: 30,
                          height: "1px",
                        }}
                      />
                      <Chip
                        label={row?.prim}
                        size="small"
                        sx={{
                          color: "#fff",
                          width: 35,
                          backgroundColor:
                            notPayedTable?.setup_prim_rate ===
                            parseInt(row?.prim)
                              ? "#348734"
                              : "#403836",
                          height: 25,
                        }}
                      />
                      <Divider
                        orientation="vertical"
                        sx={{
                          backgroundColor: "#f4f4f4",
                          width: 30,
                          height: "1px",
                        }}
                      />
                      <Paper
                        style={{
                          backgroundColor: "#403836",
                          width: 50,
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          height: 20,
                          alignItems: "center",
                          fontSize: 13,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "monospace",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 2,
                          }}
                        >
                          {row?.end}
                        </div>
                      </Paper>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3>Açık Ödemeler</h3>

          <TableContainer style={{ maxHeight: 350, overflow: "auto" }}>
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 1,
                  lineHeight: 0,
                },
                ".MuiTableCell-root td": {
                  color: "#fff",
                },
                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 200 }}>
                    Restoran
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 150 }}>
                    Ürünler
                  </TableCell>
                  <TableCell align="center">Platformlar</TableCell>
                  <TableCell align="center">Kurulum</TableCell>
                  <TableCell align="center" style={{ minWidth: 150 }}>
                    Siparişler
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    Ort Sipariş
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 70 }}>
                    Teklif
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 70 }}>
                    Eft
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 90 }}>
                    Bek. Kazanç
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notPayedTable?.prims?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        <div
                          style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "blue",
                            fontSize: 12,
                          }}
                          onClick={() => {
                            history.push(
                              `/restaurants/${row.restofficial?.id}`
                            );
                          }}
                        >
                          {row?.restofficial?.name?.length > 30 ? (
                            <Tooltip title={row?.restofficial?.name}>
                              <div>
                                {row?.restofficial?.name?.slice(0, 30)}...
                              </div>
                            </Tooltip>
                          ) : (
                            row?.restofficial?.name
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {row.product_offers.map((product) => {
                          return (
                            <Tooltip title={product.product_name}>
                              <img
                                style={{
                                  width: 21,
                                  height: 21,
                                  marginLeft: 3,
                                }}
                                alt="product"
                                src={
                                  product.product_name === "SEPETTAKİP"
                                    ? sepettakipLogo
                                    : product.product_name === "Caller ID"
                                    ? calleridLogo
                                    : product.product_name === "SEPETFAST"
                                    ? sepetfastLogo
                                    : product.product_name === "STOKTAKİP"
                                    ? stoktakipLogo
                                    : product.product_name === "MASATAKİP"
                                    ? masatakipLogo
                                    : product.product_name === "KURYETAKİP"
                                    ? courierLogo
                                    : product.product_name === "CallerPlus"
                                    ? callerplusLogo
                                    : emptyImg
                                }
                              />
                            </Tooltip>
                          );
                        })}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {row?.platforms?.map((row1, ind) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#f4f4f4",
                                  padding: 4,
                                  borderRightColor: "lightgray",
                                  borderRightStyle:
                                    row?.platforms?.length !== ind + 1
                                      ? "solid"
                                      : "none",
                                }}
                              >
                                <img
                                  src={platformIconImageConverter(row1?.name)}
                                  width={15}
                                  alt="platform"
                                />
                                <div style={{ marginLeft: 2 }}>
                                  {row1?.count}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {calcDateCreatedAt(row?.setup_at)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.order_summary?.total_order?.toFixed(0)} /{" "}
                        {row?.order_summary?.total_order_amount?.toFixed(0)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.order_summary?.average_order?.toFixed(0)} /{" "}
                        {row?.order_summary?.average_amount?.toFixed(0)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.total_amount?.toFixed(0)} ₺
                      </TableCell>
                      <TableCell align="center">
                        {row?.eft_amount?.toFixed(0)} ₺
                      </TableCell>
                      <TableCell align="center">
                        {row?.calculated_prim?.toFixed(0)} ₺
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3>Kapanmış Ödemeler</h3>

          <TableContainer style={{ maxHeight: 350, overflow: "auto" }}>
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 1,
                  lineHeight: 0,
                },
                ".MuiTableCell-root td": {
                  color: "#fff",
                },
                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 200 }}>
                    Restoran
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 150 }}>
                    Ürünler
                  </TableCell>
                  <TableCell align="center">Platformlar</TableCell>
                  <TableCell align="center">Kurulum</TableCell>
                  <TableCell align="center" style={{ minWidth: 150 }}>
                    Siparişler
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    Ort Sipariş
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 70 }}>
                    Teklif
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 70 }}>
                    Eft
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 70 }}>
                    Kazanç
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 120 }}>
                    Primden Al. Tek.
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    Ekstra Kazanç
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    Kurulum Kazanç
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payedTable?.prims?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        <div
                          style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "blue",
                            fontSize: 12,
                          }}
                          onClick={() => {
                            history.push(
                              `/restaurants/${row.restofficial?.id}`
                            );
                          }}
                        >
                          {row?.restofficial?.name?.length > 30 ? (
                            <Tooltip title={row?.restofficial?.name}>
                              <div>
                                {row?.restofficial?.name?.slice(0, 30)}...
                              </div>
                            </Tooltip>
                          ) : (
                            row?.restofficial?.name
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {row.product_offers.map((product) => {
                          return (
                            <Tooltip title={product.product_name}>
                              <img
                                style={{
                                  width: 21,
                                  height: 21,
                                  marginLeft: 3,
                                }}
                                alt="product"
                                src={
                                  product.product_name === "SEPETTAKİP"
                                    ? sepettakipLogo
                                    : product.product_name === "Caller ID"
                                    ? calleridLogo
                                    : product.product_name === "SEPETFAST"
                                    ? sepetfastLogo
                                    : product.product_name === "STOKTAKİP"
                                    ? stoktakipLogo
                                    : product.product_name === "MASATAKİP"
                                    ? masatakipLogo
                                    : product.product_name === "KURYETAKİP"
                                    ? courierLogo
                                    : product.product_name === "CallerPlus"
                                    ? callerplusLogo
                                    : emptyImg
                                }
                              />
                            </Tooltip>
                          );
                        })}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {row?.platforms?.map((row1, ind) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#f4f4f4",
                                  padding: 4,
                                  borderRightColor: "lightgray",
                                  borderRightStyle:
                                    row?.platforms?.length !== ind + 1
                                      ? "solid"
                                      : "none",
                                }}
                              >
                                <img
                                  src={platformIconImageConverter(row1?.name)}
                                  width={15}
                                  alt="platform"
                                />
                                <div style={{ marginLeft: 2 }}>
                                  {row1?.count}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {calcDateCreatedAt(row?.setup_at)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.order_summary?.total_order?.toFixed(0)} /{" "}
                        {row?.order_summary?.total_order_amount?.toFixed(0)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.order_summary?.average_order?.toFixed(0)} /{" "}
                        {row?.order_summary?.average_amount?.toFixed(0)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.total_amount?.toFixed(0)} ₺
                      </TableCell>
                      <TableCell align="center">
                        {row?.eft_amount?.toFixed(0)} ₺
                      </TableCell>
                      <TableCell align="center">
                        {row?.calculated_prim?.toFixed(0)} ₺
                      </TableCell>
                      <TableCell align="center">
                        {row?.total_prim_received_amount !== 0
                          ? row?.total_prim_received_amount?.toFixed(0) + " ₺"
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.total_extra_prim?.toFixed(0)} ₺
                      </TableCell>
                      <TableCell align="center">
                        {row?.total_setup_prim?.toFixed(0)} ₺
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div style={{ marginBottom: 30 }}>
          <h3>Ciro Hedef Primi</h3>
          <div style={{ display: "flex", alignItems: "center" }}>
            {payedTable?.ciro_target_prim?.map((ciro, i) => {
              return (
                <div
                  style={{
                    background: "#ebebeb",
                    marginRight: 20,
                    borderRadius: 8,
                    padding: "8px 14px",
                    width: "fit-content",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "#f53b62",
                      marginBottom: 5,
                    }}
                  >
                    {i + 1}. Kademe - {ciro.prim}%
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    {ciro.selected && (
                      <CheckBoxIcon
                        style={{ color: "green", marginRight: 15 }}
                      />
                    )}
                    {ciro.start}₺ - {ciro.end}₺{" "}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <h3>Kurulum Bilgileri</h3>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                background: "#ebebeb",
                marginRight: 20,
                borderRadius: 8,
                padding: "8px 14px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Toplam Kurulum Sayısı
              <br />
              {payedTable?.setup_count}
            </div>

            <div
              style={{
                background: "#ebebeb",
                marginRight: 20,
                borderRadius: 8,
                padding: "8px 14px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Kurulum Prim Oranı
              <br />
              {payedTable?.setup_prim_rate}
            </div>

            <div
              style={{
                background: "#ebebeb",
                marginRight: 20,
                borderRadius: 8,
                padding: "8px 14px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Kurulum Prim
              <br />
              {payedTable?.total_setup_prim}₺
            </div>
          </div>
        </div>

        <div>
          <h3>Minimum Ciro Blokajı</h3>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                background: "#ebebeb",
                marginRight: 20,
                borderRadius: 8,
                padding: "8px 14px",
                width: "100px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Minimum
              <br />
              {payedTable?.bloke_prim_limit}₺
            </div>

            <div
              style={{
                background: "#ebebeb",
                marginRight: 20,
                borderRadius: 8,
                padding: "8px 14px",
                width: "100px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Anlık
              <br />
              {payedTable?.transaction_total}₺
            </div>

            <div
              style={{
                background: "#ebebeb",
                borderRadius: 8,
                padding: "8px 14px",
                width: "100px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Hakediş
              <br />
              <span style={{ color: payedTable?.prim ? "green" : "red" }}>
                {payedTable?.prim ? "Başarılı" : "Başarısız"}
              </span>
            </div>
          </div>

          <div
            style={{
              background: "#ebebeb",
              borderRadius: 8,
              padding: "8px 14px",
              width: "100px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 24,
              marginTop: 20,
              marginBottom: 30,
            }}
          >
            Toplam <br />
            {payedTable?.total_prim.toFixed(2)}₺
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeuserPrim;
