import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  createTheme,
  InputBase,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 30%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
}));

export default function ChangePasswordModal({
  changePasswordModal,
  setChangePasswordModal,
  setNewPasswordFunc,
  restOfficialData,
  resetPassword,
}) {
  const classes = useStyles();
  const [stPassword, setStPassword] = useState("");
  const [patronPassword, setPatronPassword] = useState("");

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={changePasswordModal}
      className={classes.modal}
      onClose={() => setChangePasswordModal(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
          maxHeight: 600,
          overflowY: "auto",
          backgroundColor: "#fff",
          padding: 30,
          minHeight: 130,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontFamily: "segoeuiB",
            fontSize: 28,
            marginBottom: 30,
          }}
        >
          Şifre Değiştir
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f4f4f4",
              height: "auto",
              justifyContent: "space-between",
              padding: 20,
              alignItems: "center",
            }}
          >
            <div style={{ color: "darkslategray" }}>Sepettakip Şifresi</div>
            <InputBase
              multiline
              spellCheck={false}
              style={{
                width: "100%",
                fontFamily: "segoeuiRg",
                paddingLeft: 8,
                backgroundColor: "#fff",
                marginTop: 10,
              }}
              rows={1}
              placeholder={"Sepettakip Şifresi"}
              className={classes.input}
              value={stPassword}
              onChange={(e) => {
                setStPassword(e.target.value);
              }}
            />
            <Button
              style={{
                backgroundColor: "green",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "90%",
                display: "flex",
                justifyContent: "center",
                height: 32,
                marginTop: 20,
              }}
              size="small"
              onClick={() => {
                let data = {
                  rest_id: restOfficialData?.user?.id,
                  sendObj: {
                    password: stPassword,
                    type: "electron",
                  },
                  setChangePasswordModal,
                  setStPassword,
                  setPatronPassword,
                };
                setNewPasswordFunc(data);
              }}
            >
              Onayla
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f4f4f4",
              height: "auto",
              justifyContent: "space-between",
              padding: 20,
              alignItems: "center",
            }}
          >
            <div style={{ color: "darkslategray" }}>Patron Modül Şifresi</div>
            <InputBase
              multiline
              spellCheck={false}
              style={{
                width: "100%",
                fontFamily: "segoeuiRg",
                paddingLeft: 8,
                backgroundColor: "#fff",
                marginTop: 10,
              }}
              rows={1}
              placeholder={"Patron Modül Şifresi"}
              className={classes.input}
              value={patronPassword}
              onChange={(e) => {
                setPatronPassword(e.target.value);
              }}
            />
            <Button
              style={{
                backgroundColor: "green",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "90%",
                display: "flex",
                justifyContent: "center",
                height: 32,
                marginTop: 20,
              }}
              size="small"
              onClick={() => {
                let data = {
                  rest_id: restOfficialData?.user?.id,
                  sendObj: {
                    password: patronPassword,
                    type: "portal",
                  },
                  setChangePasswordModal,
                  setStPassword,
                  setPatronPassword,
                };
                setNewPasswordFunc(data);
              }}
            >
              Onayla
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f4f4f4",
              height: "auto",
              justifyContent: "space-between",
              padding: 20,
              alignItems: "center",
            }}
          >
            <div style={{ color: "darkslategray" }}>Şifreyi Maile Gönder</div>
            <div>
              <KeyboardDoubleArrowDown />
            </div>
            <Button
              style={{
                backgroundColor: "green",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "90%",
                display: "flex",
                justifyContent: "center",
                height: 32,
                marginTop: 20,
                width: 150,
              }}
              size="small"
              onClick={() => {
                resetPassword();
              }}
            >
              Şifre Sıfırla
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
