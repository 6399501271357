import React, { useContext, useEffect, useState } from "react";

import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { orderProductsImage } from "../../../components/converter/orderProductsImage";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const ProductProfitModal = ({
  productProfitModal,
  setProductProfitModal,
  currentProfitRestId,
  setCurrentProfitRestId,
}) => {
  const classes = useStyles();
  const {
    showProductProfitFunc,
    productProfitData,
    productProfitDataFunc,
    productProfitAvailabilityList,
    productProfitAvailabilityFunc,
  } = useContext(RestaurantContext);
  const [selectedProfitProduct, setSelectedProfitProduct] = useState(null);

  useEffect(() => {
    if (productProfitModal) {
      productProfitAvailabilityFunc({ restofficial: currentProfitRestId });
    }
  }, [productProfitModal]);

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={productProfitModal}
        className={classes.modal}
        onClose={() => {
          setProductProfitModal(false);
        }}
      >
        <Card
          style={{
            width: "45%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "68%",
            overflow: "auto",
            borderRadius: 8,
            padding: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            {productProfitAvailabilityList?.some(
              (row) => row === "sepettakip"
            ) && (
              <div>
                <img
                  src={orderProductsImage("sepettakip")}
                  alt={"sepettakip"}
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    console.log("currentProfitRestId", currentProfitRestId);
                    let data = {
                      key: "sepettakip",
                      restofficial: currentProfitRestId,
                    };
                    productProfitDataFunc(data);
                    setSelectedProfitProduct("sepettakip");
                  }}
                />
              </div>
            )}
            {productProfitAvailabilityList?.some(
              (row) => row === "masatakip"
            ) && (
              <div>
                <img
                  src={orderProductsImage("masatakip")}
                  alt={"masatakip"}
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let data = {
                      key: "masatakip",
                      restofficial: currentProfitRestId,
                    };
                    productProfitDataFunc(data);
                    setSelectedProfitProduct("masatakip");
                  }}
                />
              </div>
            )}
            {productProfitAvailabilityList?.some(
              (row) => row === "callerplus"
            ) && (
              <div>
                <img
                  src={orderProductsImage("callerplus")}
                  alt={"callerplus"}
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let data = {
                      key: "callerplus",
                      restofficial: currentProfitRestId,
                    };
                    productProfitDataFunc(data);
                    setSelectedProfitProduct("callerplus");
                  }}
                />
              </div>
            )}
            {productProfitAvailabilityList?.some(
              (row) => row === "kuryetakip"
            ) && (
              <div>
                <img
                  src={orderProductsImage("kuryetakip")}
                  alt={"kuryetakip"}
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let data = {
                      key: "kuryetakip",
                      restofficial: currentProfitRestId,
                    };
                    productProfitDataFunc(data);
                    setSelectedProfitProduct("kuryetakip");
                  }}
                />
              </div>
            )}{" "}
            {productProfitAvailabilityList?.some(
              (row) => row === "restoranapp"
            ) && (
              <div>
                <img
                  src={orderProductsImage("restoranapp")}
                  alt={"restoranapp"}
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let data = {
                      key: "restoranapp",
                      restofficial: currentProfitRestId,
                    };
                    productProfitDataFunc(data);
                    setSelectedProfitProduct("restoranapp");
                  }}
                />
              </div>
            )}
            {productProfitAvailabilityList?.some(
              (row) => row === "stoktakip"
            ) && (
              <div>
                <img
                  src={orderProductsImage("stoktakip")}
                  alt={"stoktakip"}
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let data = {
                      key: "stoktakip",
                      restofficial: currentProfitRestId,
                    };
                    productProfitDataFunc(data);
                    setSelectedProfitProduct("stoktakip");
                  }}
                />
              </div>
            )}
          </div>
          {productProfitData && (
            <>
              <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ width: "50%" }}>
                  <img
                    src={productProfitData?.image}
                    alt="profit"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      fontSize: 12,
                    }}
                  >
                    {productProfitData !== null &&
                      parse(
                        productProfitData?.content?.replace(/(\r\n|\n|\r)/g, "")
                      )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "80%",
                  marginTop: 10,
                }}
              >
                <Button
                  onClick={() => {
                    let data = {
                      sendObj: {
                        key: selectedProfitProduct,
                        restofficial: currentProfitRestId,
                      },
                    };
                    showProductProfitFunc(data);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "#f53b62",
                    padding: "5px 10px 5px 10px",
                    textTransform: "capitalize",
                    fontFamily: "segoeuiRg",
                  }}
                >
                  Restorana Göster
                </Button>
              </div>
            </>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default ProductProfitModal;
