import React, { useState } from "react";
//Mui
import { Card, TablePagination } from "@mui/material";
//components
import TablePaginationActions from "../../../../../../components/pagination/mainPagination";
//Helpers
import serviceTypeConverter from "../../../../../../components/converter/serviceType";
import moment from "moment";
import orderStatusConverter from "../../../../../../components/converter/orderStatus";
function CustomerOrders({ enduserOrders, setPage, page }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const subOptionsFunc = (detail, product) => {
    return detail?.suboptions?.map((subOpt, index3) => (
      <React.Fragment key={index3}>
        <div
          style={{
            marginLeft: "10px",
            fontFamily: "segoeuiRg",
          }}
        >
          <span>{subOpt?.name}</span>
        </div>
        {subOpt?.details?.map((subdetail, index4) => (
          <div
            style={{
              marginLeft: "20px",
              fontFamily: "segoeuiRg",
              borderBottom: "1px solid #e0e0e0",
              marginTop: 2,
            }}
            key={index4}
          >
            {detail?.quantity * product.quantity !== 1
              ? detail?.quantity * product?.quantity + "-"
              : ""}
            {subdetail?.name}
            {subdetail.price === 0 ? "" : subdetail?.price.toFixed(2) + "₺"}
            {subdetail?.suboptions?.length > 0
              ? subOptionsDetailsFunc(subdetail, detail)
              : ""}
          </div>
        ))}
      </React.Fragment>
    ));
  };
  const subOptionsDetailsFunc = (subdetail, detail) => {
    return subdetail?.suboptions?.map((suboption, index5) => (
      <React.Fragment key={index5}>
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          <span>{suboption?.name}</span>
        </div>
        {suboption?.details.map((subdetail, index6) => (
          <div
            style={{
              marginLeft: "20px",
              borderBottom: "1px solid #e0e0e0",
              marginTop: 2,
            }}
            key={index6}
          >
            {detail?.quantity * subdetail?.quantity !== 1
              ? detail?.quantity * subdetail?.quantity + "-"
              : ""}
            {subdetail?.name}
            {subdetail?.price === 0 ? "" : subdetail?.price?.toFixed(2) + "₺"}
            {subdetail?.suboptions?.length !== 0
              ? subOptionsDetailsFunc(subdetail, detail)
              : ""}
          </div>
        ))}
      </React.Fragment>
    ));
  };
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 50,
      }}
    >
      {enduserOrders?.results?.map((order, ind) => (
        <div
          key={ind}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Card
            sx={{
              width: "45%",
              padding: 3,
              marginLeft: 3,
              marginBottom: 2,
              height: 300,
              borderRadius: 2,
              border: "1px solid #e0e0e0",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 7,
                fontFamily: "segoeuiB",
                color: "#f53b62",
              }}
            >
              Sipariş Bilgileri
            </div>
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 5,
                color: "#f53b62",
                fontFamily: "segoeuiB",
              }}
            >
              Sipariş Tarihi :{"  "}
              {moment(order?.created_at).format("DD-MM-YYYY - HH:mm")}
            </div>
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 5,
              }}
            >
              Servis Tipi :{"  "}
              {serviceTypeConverter(order?.service_type)}
            </div>
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 5,
              }}
            >
              Durumu :{"  "}
              {orderStatusConverter(order?.status)}
            </div>
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 5,
              }}
            >
              Ödeme Yöntemi :{"  "}
              {orderStatusConverter(order?.payment_type?.name)}
            </div>
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 5,
              }}
            >
              Sipariş No :{"  "}
              {order?.id}
            </div>
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 5,
              }}
            >
              Kurye:{"  "}
              {order?.courier == null ? "-" : order?.courier?.name}
            </div>
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 5,
              }}
            >
              Not:{"  "}
              {order?.notes === null || order?.notes === ""
                ? "-"
                : order?.notes}
            </div>
          </Card>
          <Card
            sx={{
              width: "50%",
              padding: 3,
              height: 300,
              overflowY: "auto",
              borderRadius: 2,
              border: "1px solid #e0e0e0",
            }}
          >
            <div
              style={{
                fontSize: 18,
                borderBottom: "1px solid #e0e0e0",
                marginBottom: 10,
                fontFamily: "segoeuiB",
                color: "#f53b62",
              }}
            >
              Toplam Fiyat : {order?.amount} ₺
            </div>

            {order?.products.length > 0 &&
              order?.products?.map((product, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #e0e0e0",
                      marginTop: 2,
                    }}
                  >
                    <span style={{ fontFamily: "segoeuiB" }}>
                      {product.quantity} * {product?.name}
                    </span>
                    <span>{`${product?.price?.toFixed(2)} ₺`}</span>
                  </div>
                  {product?.options.length > 0 &&
                    product?.options?.map((opt, index1) => (
                      <div
                        key={index1}
                        style={{
                          marginLeft: "10px",
                          borderBottom: "1px solid #e0e0e0",
                          marginTop: 2,
                        }}
                      >
                        <span>{opt?.name ? opt?.name : ""}</span>
                        {opt?.details?.length > 0 &&
                          opt?.details?.map((detail, index2) => (
                            <div
                              style={{
                                marginLeft: "10px",
                              }}
                              key={index2}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{detail?.name} </span>
                                <span>
                                  {detail?.price === 0
                                    ? ""
                                    : detail?.price?.toFixed(2) + "₺"}
                                </span>
                              </div>
                              {detail?.suboptions.length > 0 &&
                              detail?.suboptions
                                ? subOptionsFunc(detail, product)
                                : ""}
                            </div>
                          ))}
                      </div>
                    ))}
                  {product?.note != "" && product.note != null && (
                    <>
                      <br />
                      <span>Ürün Notu : {product?.note}</span>
                    </>
                  )}
                </div>
              ))}
          </Card>
        </div>
      ))}
      <TablePagination
        rowsPerPageOptions={[10]}
        labelRowsPerPage={"Sayfa Başına Kayıt"}
        count={enduserOrders?.count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

export default CustomerOrders;
