import { Box, Button, createTheme, Modal, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 30%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

export default function LimitedUseModal({
  limitedUseModal,
  setLimitedUseModal,
  restaurantLimitedUse,
  restOfficialData,
}) {
  const classes = useStyles();

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={limitedUseModal}
      className={classes.modal}
      onClose={() => setLimitedUseModal(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
          maxHeight: 600,
          overflowY: "auto",
          backgroundColor: "#fff",
          padding: 30,
          minHeight: 130,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontFamily: "segoeuiB",
            fontSize: 28,
          }}
        >
          Restoranı{" "}
          <span style={{ color: "red" }}>
            {restOfficialData?.limited_use
              ? "kısıtlamasını kaldırmak"
              : "kısıtlamak"}
          </span>{" "}
          istediğinize emin misiniz?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "monospace",
              width: 200,
              fontWeight: 700,
              backgroundColor: "red",
            }}
            onClick={() => {
              setLimitedUseModal(false);
            }}
          >
            Hayır
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "monospace",
              width: 200,
              fontWeight: 700,
              backgroundColor: "#0FBD20",
            }}
            onClick={() => {
              restaurantLimitedUse();
              setLimitedUseModal(false);
            }}
          >
            Evet
          </Button>
        </div>
      </div>
    </Modal>
  );
}
