import React, { useContext, useEffect, useState } from "react";

import {
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import moment from "moment";
import { makeStyles } from "@mui/styles";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const RestPrevOfferHistoryModal = ({
  restPrevOfferHistoryModal,
  setRestPrevOfferHistoryModal,
  currentRow,
}) => {
  const classes = useStyles();
  const { restPrevOfferHistoryFunc, restPrevOfferHistory } =
    useContext(RestaurantContext);

  useEffect(() => {
    if (currentRow !== null) {
      restPrevOfferHistoryFunc({ rest_id: currentRow?.restofficial?.user_id });
    }
  }, [currentRow]);

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={restPrevOfferHistoryModal}
        className={classes.modal}
        onClose={() => {
          setRestPrevOfferHistoryModal(false);
        }}
      >
        <Card
          style={{
            width: "60%",
            padding: 25,
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Ürün</TableCell>
                  <TableCell align="center">Teklif</TableCell>
                  <TableCell align="center">Tarih</TableCell>
                  <TableCell align="center">İndirim</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {restPrevOfferHistory?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {row?.product_offers?.map((row1) => {
                          return (
                            <div>
                              {row1?.product_name}({row1?.amount}₺)
                            </div>
                          );
                        })}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>{row?.total_amount}₺</div>
                          <div>{row?.payed ? "Ödendi" : "-"}</div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            {moment(row?.created_at).format("DD-MM-YYYY")}
                          </div>
                          <div>{moment(row?.created_at).fromNow()}</div>
                        </div>
                      </TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Modal>
    </>
  );
};

export default RestPrevOfferHistoryModal;
