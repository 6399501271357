import React from "react";
import { Button } from "@mui/material";
function TabItem({ actionType, itemTitle, setActionType, itemAction }) {
  return (
    <Button
      variant="outlined"
      color="error"
      style={{
        backgroundColor: actionType == itemAction ? "#f53b62" : "transparent",
        cursor: "pointer",
        color: actionType == itemAction ? "#fff" : "#f53b62",
        fontSize: 14,
        boxShadow: actionType == itemAction ? "2px 3px 2.5px 1px #C5C5BB" : "",
        minWidth: 35,
        textAlign: "center",
        textTransform: "capitalize",
        marginRight: 5,
        height: 30,
      }}
      onClick={() => {
        if (actionType == itemAction) {
          setActionType(null);
        } else {
          setActionType(itemAction);
        }
      }}
    >
      {itemTitle}
    </Button>
  );
}

export default TabItem;
