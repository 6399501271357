import React from "react";

import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const PushNotificationModal = ({
  pushNotificationModal,
  setPushNotificationModal,
  message,
  messageHeader,
  setMessage,
  setMessageHeader,
  sendPushNotificationFunc,
  id,
}) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={pushNotificationModal}
        className={classes.modal}
        onClose={() => {
          setPushNotificationModal(false);
        }}
      >
        <Card
          className={classes.paper}
          style={{
            width: "60%",
            display: "flex",

            alignItems: "center",
            padding: 25,
            flexDirection: "column",
          }}
        >
          <div>Bildirim gönderiyorsunuz. Emin misiniz?</div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                backgroundColor: "#f53b62",
                color: "#fff",
                fontFamily: "SgBd",
                width: "30%",
                display: "flex",
                justifyContent: "center",
                height: 30,
                marginTop: 30,
                textTransform: "capitalize",
              }}
              size="small"
              onClick={() => {
                setPushNotificationModal(false);
                setMessage("");
                setMessageHeader("");
              }}
            >
              Vazgeç
            </Button>
            <Button
              style={{
                backgroundColor: "#f53b62",
                color: "#fff",
                fontFamily: "SgBd",
                width: "30%",
                display: "flex",
                justifyContent: "center",
                height: 30,
                marginTop: 30,
                textTransform: "capitalize",
              }}
              size="small"
              onClick={() => {
                sendPushNotificationFunc({
                  restofficial_id: Number(id),
                  title: messageHeader,
                  body: message,
                });
                setMessage("");
                setMessageHeader("");
                setPushNotificationModal(false);
              }}
            >
              Gönder
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default PushNotificationModal;
