import { Avatar, Button, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {
  Coffee,
  ConnectedTv,
  Groups,
  Home,
  ImportContacts,
  Opacity,
  PhoneForwarded,
  PowerOff,
  School,
  TagFaces,
} from "@mui/icons-material";
import moment from "moment";
import { ManagementContext } from "../../../../contexts/managementContext";
import GridItem from "../../../../components/gridItem";

import callerIdImg from "../../../../utils/assets/caller-id.webp";
import sepettakipImg from "../../../../utils/assets/sepet-takip.webp";
import sepetfastImg from "../../../../utils/assets/sepetfast.webp";
import stoktakipImg from "../../../../utils/assets/stoktakip.webp";
import masatakipImg from "../../../../utils/assets/masatakip.webp";
import kuryeappImg from "../../../../utils/assets/kuryeapp.webp";
import emptyImg from "../../../../utils/assets/empty.png";
import sepetappImg from "../../../../utils/assets/sepettakipLogos/sepetapp-logo.webp";
import { Pie, PieChart, Tooltip } from "recharts";
import ReportModal from "./ReportModal";
import DataModal from "./DataModal";
import TaskRepeatModal from "./TaskRepeatModal";
import CompletedTasksModal from "./CompletedTasksModal";
import ProductRenewalsModal from "./ProductRenewalsModal";
import InfoModal from "./InfoModal";
import CdrRepeatsModal from "./crdRepeatsModal";
import CdrInboundModal from "./cdrInboundModal";
import CdrOutboundModal from "./cdrOutboundModal";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 12,
    height: 12,
    borderRadius: 8,
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 2.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const DetailScreen = ({
  dashboardUserInfo,
  setCallerFlipped,
  startDate,
  endDate,
  dashboardOfficeuserDetail,
  setDateRange,
  selectedUser,
  dashboardType,
}) => {
  const {
    dashboardOfficeuserDetailDeleteReasons,
    dashboardOfficeuserDetailBlockedReasons,
    dashboardOfficeuserDetailApplicationTypes,
    blockedReasons,
    deletedReasons,
    officeUserApplicationTypes,
    dashboardOfficeuserDetailPayedOffers,
    payedOffers,
    dashboardOfficeuserDetailCompletedTasks,
    dashboardOfficeuserDetailCalendar,
    dashboardOfficeuserDetailTaskRepeats,
    officeUserDetailCalendar,
    officeUserDetailTaskRepeats,
    officeUserDetailCompletedTasks,
    dashboardOfficeuserDetailFunc,
    dashboardOfficeuserProductRenewals,
    dashboardProductRenewals,
    dashboardOfficeuserInfo,
    userInfo,
    setUserInfo,
    dashboardOfficeuserDetailCalendarTech,
    dashboardOfficeuserTechActiveTasks,
    officeUserDetailActiveTasks,
    dashboardOfficeuserTechTicketBounces,
  } = useContext(ManagementContext);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openDataModal, setOpenDataModal] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [title, setTitle] = useState(null);
  const [dataTitle, setDataTitle] = useState(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [openTaskRepeatModal, setOpenTaskRepeatModal] = useState(false);
  const [openCompletedTasks, setOpenCompletedTasks] = useState(false);
  const [page, setPage] = useState(0);
  const [pageInfo, setPageInfo] = useState(0);
  const [openRenewalModal, setOpenRenewalModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoType, setInfoType] = useState(null);
  const [cdrRepeatsModal, setCdrRepeatsModal] = useState(false);
  const [cdrInboundModal, setCdrInboundModal] = useState(false);
  const [cdrOutboundModal, setCdrOutboundModal] = useState(false);

  const calculatePassiveTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond =
        row?.state_info?.state_stats.break +
        row?.state_info?.state_stats.health +
        row?.state_info?.state_stats.meeting +
        row?.state_info?.state_stats.training +
        row?.state_info?.state_stats.wc;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };
  const calculatePassiveHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond =
        row?.state_info?.state_stats.break +
        row?.state_info?.state_stats.health +
        row?.state_info?.state_stats.meeting +
        row?.state_info?.state_stats.training +
        row?.state_info?.state_stats.wc;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const userData = {
    user_id: dashboardUserInfo?.user_id,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  };

  useEffect(() => {
    if (dashboardUserInfo !== null && startDate && endDate) {
      let data = {
        user_id: dashboardUserInfo?.user_id,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      };

      dashboardOfficeuserDetailCompletedTasks({ ...data, page });
    }
  }, [page]);

  const calculateTimeSpent = (row) => {
    return moment.utc(row * 1000).format("mm");
  };

  const calculateTimeSpentHour = (row) => {
    return moment.utc(row * 1000).format("HH");
  };

  const handleShowReportModal = (type) => {
    setOpenReportModal(true);

    if (type === "bloke") {
      dashboardOfficeuserDetailBlockedReasons(userData);
      setTitle("Bloke Data Nedenleri ve Yüzdeleri");
    } else if (type === "deleted") {
      dashboardOfficeuserDetailDeleteReasons(userData);
      setTitle("Silinen Data Nedenleri ve Yüzdeleri");
    }
  };

  useEffect(() => {
    if (blockedReasons) {
      setReportData(blockedReasons);
    } else if (deletedReasons) {
      setReportData(deletedReasons);
    }
  }, [blockedReasons, deletedReasons]);

  useEffect(() => {
    if (officeUserApplicationTypes) {
      setData(officeUserApplicationTypes);
    }
  }, [officeUserApplicationTypes]);

  useEffect(() => {
    if (officeUserDetailCalendar) {
      setData(officeUserDetailCalendar);
    }
  }, [officeUserDetailCalendar]);

  useEffect(() => {
    if (officeUserDetailActiveTasks) {
      setData(officeUserDetailActiveTasks);
    }
  }, [officeUserDetailActiveTasks]);

  const handleShowDataModal = (type, product) => {
    setOpenDataModal(true);
    let selectedHeaders;
    let selectedRows;

    if (type === "datatakip") {
      dashboardOfficeuserDetailApplicationTypes(userData);
      setDataTitle("Data Takip");

      selectedHeaders = [
        "Data",
        "Toplam Sayı",
        "Kurulan",
        "Silinen",
        "Bloke",
        "Takvimde",
        "Tahsilat",
      ];
      setHeaders(selectedHeaders);

      selectedRows = [
        "application_type",
        "count",
        "setup_completed_count",
        "deleted_count",
        "restofficial_blocked_count",
        "processing_count",
        "total_amount",
      ];
      setRows(selectedRows);
    } else if (type === "products") {
      let data = {
        user_id: dashboardUserInfo?.user_id,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        productId: product.id,
      };
      dashboardOfficeuserDetailPayedOffers(data);
      setDataTitle(product.name);
      setData(payedOffers);

      selectedHeaders = [
        "ID",
        "Tarih",
        "Liste Fiyatı",
        "Satış Fiyatı",
        "Toplam İndirim",
      ];
      setHeaders(selectedHeaders);

      selectedRows = [
        "restofficial",
        "payed_at",
        "total_base_amount",
        "total_amount",
        "discount_amount",
      ];
      setRows(selectedRows);
    } else if (type === "calendar") {
      if (dashboardType === "collector") {
        dashboardOfficeuserDetailCalendar(userData);
        setDataTitle("Takvimdekiler");
        selectedHeaders = ["ID", "Görev Tipi", "Randevu Saati"];
        setHeaders(selectedHeaders);

        selectedRows = ["restofficial_id", "task_type", "start_at"];
        setRows(selectedRows);
      } else {
        dashboardOfficeuserDetailCalendarTech(userData);
        setDataTitle("Takvimdekiler");
        selectedHeaders = [
          "ID",
          "Görev Tipi",
          "Oluşturulma Saati",
          "Randevu Tarihi",
        ];
        setHeaders(selectedHeaders);

        selectedRows = [
          "restofficial_id",
          "task_type",
          "created_at",
          "start_at",
        ];
        setRows(selectedRows);
      }
    } else if (type === "active") {
      dashboardOfficeuserTechActiveTasks(userData);
      setDataTitle("Aktif Görevler");
      selectedHeaders = [
        "ID",
        "Görev Tipi",
        "Başlangıç Saati",
        "Bitiş Saati",
        "Süre Dk",
      ];
      setHeaders(selectedHeaders);

      selectedRows = [
        "restofficial_id",
        "task_type",
        "created_at",
        "completed_at",
        "time_spent",
      ];
      setRows(selectedRows);
    }
  };

  const calculateOutCallTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.out_call;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };

  const calculateOutCallHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.out_call;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const calculateSetupTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.setup;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };

  const calculateSetupHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.setup;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const calculateDictionaryTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.dictionary;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };

  const calculateDictionaryHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.dictionary;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  useEffect(() => {
    setData(payedOffers);
  }, [payedOffers]);

  const handleShowCompletedTasks = () => {
    dashboardOfficeuserDetailCompletedTasks({ ...userData, page });
    setOpenCompletedTasks(true);
  };

  const handleShowTaskRepeatModal = () => {
    setOpenTaskRepeatModal(true);
    dashboardOfficeuserDetailTaskRepeats(userData);
  };

  const handleShowRenewalModal = () => {
    setOpenRenewalModal(true);
    dashboardOfficeuserProductRenewals(userData);
  };

  useEffect(() => {
    if (openInfoModal) {
      if (infoType) {
        let data = {
          user_id: dashboardUserInfo?.user_id,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          type: infoType,
          page: pageInfo,
        };
        dashboardOfficeuserInfo(data);
      } else {
        let data = {
          user_id: dashboardUserInfo?.user_id,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          page: pageInfo,
        };
        dashboardOfficeuserTechTicketBounces(data);
      }
    }
  }, [pageInfo]);

  const handleShowInfoModal = (type) => {
    setInfoType(type);
    let data = {
      user_id: dashboardUserInfo?.user_id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      type: type,
      page: pageInfo,
    };

    let selectedHeaders;
    let selectedRows;

    if (type === "active_payed") {
      setTitle("Aktif Ödemiş");
      selectedHeaders = [
        "ID",
        "Kurulum Tarihi",
        "Tahsilat Tarihi",
        "Ürün Tipi",
        "Tutar",
      ];

      selectedRows = [
        "user_id",
        "created_at",
        "last_payed_at",
        "product_permissions",
        "total_amount",
      ];
    } else if (type === "passive_payed") {
      setTitle("Pasif Ödemiş");
      selectedHeaders = [
        "ID",
        "Kurulum Tarihi",
        "Tahsilat Tarihi",
        "Ürün Tipi",
        "Tutar",
      ];

      selectedRows = [
        "user_id",
        "created_at",
        "last_payed_at",
        "product_permissions",
        "total_amount",
      ];
    } else if (type === "active_not_payed") {
      setTitle("Aktif Ödememiş");
      selectedHeaders = [
        "ID",
        "Kurulum Tarihi",
        "Toplam Kullanılan Gün",
        "Teklif Tutarı",
        "Ürünler",
        "Ön Ödeme",
        "Kalan Ödeme",
      ];

      selectedRows = [
        "rest_id",
        "rest_created",
        "total_date",
        "total_amount",
        "products",
        "eft_amount",
        "remaining_amount",
      ];
    } else if (type === "passive_not_payed") {
      setTitle("Pasif Ödememiş");
      selectedHeaders = [
        "ID",
        "Kurulum Tarihi",
        "Toplam Kullanılan Gün",
        "Teklif Tutarı",
        "Ürünler",
        "Ön Ödeme",
        "Pasif Gün",
      ];

      selectedRows = [
        "rest_id",
        "rest_created",
        "total_date",
        "total_amount",
        "products",
        "eft_amount",
        "passive_days",
      ];
    } else if (type === "blocked") {
      setTitle("Bloke");
      selectedHeaders = [
        "ID",
        "Kurulum Tarihi",
        "Bloke Tarihi",
        "Bloke Nedeni",
      ];

      selectedRows = ["user_id", "created_at", "blocked_at", "deleted_reason"];
    }

    setHeaders(selectedHeaders);
    setRows(selectedRows);

    dashboardOfficeuserInfo(data);
    setOpenInfoModal(true);
  };

  const handleShowTicketBounces = () => {
    let data = {
      user_id: dashboardUserInfo?.user_id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      page: pageInfo,
    };
    dashboardOfficeuserTechTicketBounces(data);
    setOpenInfoModal(true);
    setTitle("Kaçan Restoranlar");

    let selectedHeaders = ["ID", "Tarih"];
    let selectedRows = ["restofficial_id", "created_at"];

    setHeaders(selectedHeaders);
    setRows(selectedRows);
  };

  const calculatePercent = (val) => {
    let sum = 0;
    val.map((item) => {
      sum = sum + item.count;
    });

    return sum;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "99%" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <Paper
            elevation={3}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: 5,
              margin: "10px 3px",
              backgroundColor: "#393737",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 5,
                padding: 10,
                background: "rgb(22,22,22)",
              }}
            >
              <div
                style={{ width: "100%", display: "flex", marginRight: "20px" }}
              >
                {selectedUser && selectedUser?.user_id !== 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginRight: 16,
                      minWidth: 150,
                    }}
                  >
                    <div style={{ width: "auto" }}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        badgeContent={
                          dashboardOfficeuserDetail?.state === "active" ? (
                            <TagFaces
                              style={{
                                color: "green",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state ===
                            "out_call" ? (
                            <PhoneForwarded
                              style={{
                                color: "green",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state === "break" ? (
                            <Coffee
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state ===
                            "training" ? (
                            <School
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state === "wc" ? (
                            <Opacity
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state === "meeting" ? (
                            <Groups
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state === "passive" ? (
                            <PowerOff
                              style={{
                                color: "red",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state === "setup" ? (
                            <ConnectedTv
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state ===
                            "dictionary" ? (
                            <ImportContacts
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : dashboardOfficeuserDetail?.state === "health" ? (
                            <Home
                              style={{
                                color: "red",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : (
                            ""
                          )
                        }
                      >
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: dashboardOfficeuserDetail?.online
                                ? "#44b700"
                                : "red",
                              color: dashboardOfficeuserDetail?.online
                                ? "#44b700"
                                : "red",
                            },
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt="backoffice_user"
                            src={dashboardOfficeuserDetail?.picture}
                            sx={{
                              width: 100,
                              height: 100,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setPage(0);
                              setCallerFlipped(false);
                              setDateRange([new Date(), new Date()]);
                            }}
                          />
                        </StyledBadge>
                      </Badge>
                    </div>
                    <div
                      style={{
                        fontFamily: "monospace",
                        fontSize: 18,
                        fontWeight: 550,
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      {dashboardOfficeuserDetail?.full_name}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        İş Baş.:{" "}
                        {dashboardOfficeuserDetail?.state_info?.active_date !==
                        null
                          ? moment(
                              dashboardOfficeuserDetail?.state_info?.active_date
                            ).format("HH:mm")
                          : "-"}
                      </div>
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Aktif:{" "}
                        {moment
                          .utc(
                            dashboardOfficeuserDetail?.state_info?.state_stats
                              ?.active * 1000
                          )
                          .format("HH")}
                        s{" "}
                        {moment
                          .utc(
                            dashboardOfficeuserDetail?.state_info?.state_stats
                              ?.active * 1000
                          )
                          .format("mm")}
                        dk
                      </div>
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Dış Ar.:{" "}
                        {calculateOutCallHourTime(dashboardOfficeuserDetail)}s{" "}
                        {calculateOutCallTime(dashboardOfficeuserDetail)}dk
                      </div>
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Kurulum:{" "}
                        {calculateSetupHourTime(dashboardOfficeuserDetail)}s{" "}
                        {calculateSetupTime(dashboardOfficeuserDetail)}dk
                      </div>
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Sözlük:{" "}
                        {calculateDictionaryHourTime(dashboardOfficeuserDetail)}
                        s {calculateDictionaryTime(dashboardOfficeuserDetail)}dk
                      </div>

                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Pasif:{" "}
                        {calculatePassiveHourTime(dashboardOfficeuserDetail)}s{" "}
                        {calculatePassiveTime(dashboardOfficeuserDetail)}
                        dk
                      </div>
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        İş Bit.:{" "}
                        {dashboardOfficeuserDetail?.state_info
                          ?.inactive_date !== null
                          ? moment(
                              dashboardOfficeuserDetail?.state_info
                                ?.inactive_date
                            ).format("HH:mm")
                          : "-"}
                      </div>
                    </div>
                  </div>
                )}

                {dashboardType === "collector" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{ flex: 1, cursor: "pointer" }}
                      onClick={handleShowCompletedTasks}
                    >
                      <div style={{ color: "#fff", fontFamily: "monospace" }}>
                        Tamamlanan Görev
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        {dashboardOfficeuserDetail?.task_stats?.map(
                          (row, i) => {
                            if (row?.time_spent === 0) return;
                            return (
                              <div
                                key={i}
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: 3,
                                  borderRadius: 8,
                                  margin: 5,
                                  fontSize: 12,
                                  fontFamily: "monospace",
                                }}
                              >
                                {row?.name}={row?.count}/
                                {calculateTimeSpentHour(row?.time_spent)}s{" "}
                                {calculateTimeSpent(row?.time_spent)}dk
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div
                      style={{ flex: 1, cursor: "pointer" }}
                      onClick={() => handleShowDataModal("calendar")}
                    >
                      <div
                        style={{
                          color: "#fff",
                          marginTop: 5,
                          fontFamily: "monospace",
                        }}
                      >
                        Takvimdekiler
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        {dashboardOfficeuserDetail?.calendar_stats?.map(
                          (row, i) => {
                            if (row?.count === 0) return;
                            return (
                              <div
                                key={i}
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: 3,
                                  borderRadius: 8,
                                  margin: 5,
                                  fontSize: 12,
                                  fontFamily: "monospace",
                                }}
                              >
                                {row?.name}={row?.count}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div
                      style={{ flex: 1, cursor: "pointer" }}
                      onClick={() => handleShowTaskRepeatModal()}
                    >
                      <div
                        style={{
                          color: "#fff",
                          marginTop: 5,
                          fontFamily: "monospace",
                        }}
                      >
                        Tekrar Görev Tipleri
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        {dashboardOfficeuserDetail?.calendar_repeat_stats?.map(
                          (row, i) => {
                            if (row?.count === 0) return;
                            return (
                              <div
                                key={i}
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: 3,
                                  borderRadius: 8,
                                  margin: 5,
                                  fontSize: 12,
                                  fontFamily: "monospace",
                                }}
                              >
                                {row?.name}={row?.count}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div style={{ flex: 1, cursor: "pointer" }}>
                      <div
                        style={{
                          color: "#fff",
                          marginTop: 5,
                          fontFamily: "monospace",
                        }}
                      >
                        Cdr Ayrıntıları
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#403836",
                            borderRadius: 4,
                            width: "30%",
                            fontWeight: 700,
                            fontSize: 14,
                            height: 25,
                            marginTop: 4,
                            textTransform: "capitalize",
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => setCdrRepeatsModal(true)}
                        >
                          Tekrar Çağrı Tipleri
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#403836",
                            borderRadius: 4,
                            width: "30%",
                            fontWeight: 700,
                            fontSize: 14,
                            height: 25,
                            marginTop: 4,
                            textTransform: "capitalize",
                            marginLeft: 20,
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => setCdrInboundModal(true)}
                        >
                          Gelen Çağrı Uzunlukları
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#403836",
                            borderRadius: 4,
                            width: "30%",
                            fontWeight: 700,
                            fontSize: 14,
                            height: 25,
                            marginTop: 4,
                            textTransform: "capitalize",
                            marginLeft: 20,
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => setCdrOutboundModal(true)}
                        >
                          Giden Çağrı Uzunlukları
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{ flex: 1, cursor: "pointer" }}
                      onClick={() => handleShowDataModal("active")}
                    >
                      <div style={{ color: "#fff", fontFamily: "monospace" }}>
                        Aktif Task
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        {dashboardOfficeuserDetail?.active_tasks?.map(
                          (row, i) => {
                            if (row?.time_spent === 0) return;
                            return (
                              <div
                                key={i}
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: 3,
                                  borderRadius: 8,
                                  margin: 5,
                                  fontSize: 12,
                                  fontFamily: "monospace",
                                }}
                              >
                                {row?.name}={row?.count}/
                                {calculateTimeSpentHour(row?.time_spent)}s{" "}
                                {calculateTimeSpent(row?.time_spent)}dk
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div
                      style={{ flex: 1, cursor: "pointer" }}
                      onClick={() => handleShowDataModal("calendar")}
                    >
                      <div style={{ color: "#fff", fontFamily: "monospace" }}>
                        Takvimdeki Task
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        {dashboardOfficeuserDetail?.calendar_stats?.map(
                          (row, i) => {
                            if (row?.time_spent === 0) return;
                            return (
                              <div
                                key={i}
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: 3,
                                  borderRadius: 8,
                                  margin: 5,
                                  fontSize: 12,
                                  fontFamily: "monospace",
                                }}
                              >
                                {row?.name}={row?.count}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>

                    <div
                      style={{ flex: 1, cursor: "pointer" }}
                      onClick={handleShowCompletedTasks}
                    >
                      <div style={{ color: "#fff", fontFamily: "monospace" }}>
                        Tamamlanan Task
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        {dashboardOfficeuserDetail?.completed_tasks?.map(
                          (row, i) => {
                            if (row?.time_spent === 0) return;
                            return (
                              <div
                                key={i}
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: 3,
                                  borderRadius: 8,
                                  margin: 5,
                                  fontSize: 12,
                                  fontFamily: "monospace",
                                }}
                              >
                                {row?.name}={row?.count}/
                                {calculateTimeSpentHour(row?.time_spent)}s{" "}
                                {calculateTimeSpent(row?.time_spent)}dk
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div
                      style={{ flex: 1, cursor: "pointer" }}
                      onClick={handleShowTicketBounces}
                    >
                      <div style={{ color: "#fff", fontFamily: "monospace" }}>
                        Kaçan Restoran Teknik
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxHeight: 80,
                          overflow: "auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#f4f4f4",
                            padding: 3,
                            borderRadius: 4,
                            margin: 5,
                            fontSize: 13,
                            fontFamily: "monospace",
                            marginLeft: 10,
                            minWidth: 30,
                            textAlign: "center",
                          }}
                        >
                          {dashboardOfficeuserDetail?.ticket_bounces}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {dashboardType === "collector" && (
              <>
                <div
                  style={{
                    width: "100%",
                    background: "rgb(22, 22, 22)",
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      padding: "16px 16px 0px",
                      display: "flex",
                      flexWrap: "wrap",
                      cursor: "pointer",
                    }}
                  >
                    {dashboardOfficeuserDetail?.product_sales?.map(
                      (product, i) => {
                        if (product.name === "Caller ID") {
                          product.img = callerIdImg;
                        } else if (product.name === "SEPETTAKİP") {
                          product.img = sepettakipImg;
                        } else if (product.name === "SEPETFAST") {
                          product.img = sepetfastImg;
                        } else if (product.name === "STOKTAKİP") {
                          product.img = stoktakipImg;
                        } else if (product.name === "MASATAKİP") {
                          product.img = masatakipImg;
                        } else if (product.name === "KURYETAKİP") {
                          product.img = kuryeappImg;
                        } else if (product.name === "FRANCHISE APP") {
                          product.img = sepetappImg;
                        } else if (product.name === "RESTORAN APP") {
                          product.img = sepetappImg;
                        } else if (product.name === "MARKET APP") {
                          product.img = sepetappImg;
                        } else {
                          product.img = emptyImg;
                        }
                        return (
                          <div
                            key={i}
                            onClick={() =>
                              handleShowDataModal("products", product)
                            }
                            style={{
                              marginRight: 16,
                              paddingRight: 16,
                              borderRight: "1px solid rgba(255,255,255,0.1)",
                              marginBottom: 16,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={product.img}
                              alt={product.img}
                              style={{
                                width: "100%",
                                maxWidth: 50,
                                height: 50,
                                borderRadius: 8,
                              }}
                            />
                            <p
                              style={{
                                fontSize: 12,
                                fontFamily: "monospace",
                                margin: "5px 0px",
                              }}
                            >
                              Tahsilat: {product.amount} ₺
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                fontFamily: "monospace",
                                margin: "5px 0px",
                              }}
                            >
                              Adet: {product.count}
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                fontFamily: "monospace",
                                margin: "5px 0px",
                              }}
                            >
                              Ort: {(product.amount / product.count).toFixed(2)}{" "}
                              ₺
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>

                <div style={{ marginTop: "6px" }}>
                  <Grid
                    container
                    columns={12}
                    spacing={2}
                    sx={{ width: "100%", marginBottom: "6px" }}
                  >
                    <GridItem
                      per={2}
                      title="Toplam Kurulum"
                      count={dashboardOfficeuserDetail?.restaurant_stats?.total}
                      boxStyle={{
                        backgroundColor: "#161616",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      darkMode={true}
                    />
                    <GridItem
                      onClick={() => handleShowInfoModal("active_payed")}
                      per={2}
                      title="Aktif Ödemiş"
                      count={
                        dashboardOfficeuserDetail?.restaurant_stats
                          ?.active_payed
                      }
                      percent={
                        (dashboardOfficeuserDetail?.restaurant_stats
                          ?.active_payed *
                          100) /
                        dashboardOfficeuserDetail?.restaurant_stats?.total
                      }
                      boxStyle={{
                        backgroundColor: "#161616",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      darkMode={true}
                    />
                    <GridItem
                      onClick={() => handleShowInfoModal("passive_payed")}
                      per={2}
                      title="Pasif Ödemiş"
                      count={
                        dashboardOfficeuserDetail?.restaurant_stats
                          ?.passive_payed
                      }
                      percent={
                        (dashboardOfficeuserDetail?.restaurant_stats
                          ?.passive_payed *
                          100) /
                        dashboardOfficeuserDetail?.restaurant_stats?.total
                      }
                      boxStyle={{
                        backgroundColor: "#161616",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      darkMode={true}
                    />
                    <GridItem
                      onClick={() => handleShowInfoModal("active_not_payed")}
                      per={2}
                      title="Aktif Ödememiş"
                      count={
                        dashboardOfficeuserDetail?.restaurant_stats
                          ?.active_not_payed
                      }
                      percent={
                        (dashboardOfficeuserDetail?.restaurant_stats
                          ?.active_not_payed *
                          100) /
                        dashboardOfficeuserDetail?.restaurant_stats?.total
                      }
                      boxStyle={{
                        backgroundColor: "#161616",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      darkMode={true}
                    />
                    <GridItem
                      onClick={() => handleShowInfoModal("passive_not_payed")}
                      per={2}
                      title="Pasif Ödememiş"
                      count={
                        dashboardOfficeuserDetail?.restaurant_stats
                          ?.passive_not_payed
                      }
                      percent={
                        (dashboardOfficeuserDetail?.restaurant_stats
                          ?.passive_not_payed *
                          100) /
                        dashboardOfficeuserDetail?.restaurant_stats?.total
                      }
                      boxStyle={{
                        backgroundColor: "#161616",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      darkMode={true}
                    />
                    <GridItem
                      onClick={() => handleShowInfoModal("blocked")}
                      per={2}
                      title="Bloke"
                      count={
                        dashboardOfficeuserDetail?.restaurant_stats?.blocked
                      }
                      percent={
                        (dashboardOfficeuserDetail?.restaurant_stats?.blocked *
                          100) /
                        dashboardOfficeuserDetail?.restaurant_stats?.total
                      }
                      boxStyle={{
                        backgroundColor: "#161616",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      darkMode={true}
                    />
                  </Grid>
                </div>

                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    backgroundColor: "rgb(22, 22, 22)",
                  }}
                >
                  <Grid
                    container
                    columns={8}
                    spacing={2}
                    sx={{ padding: "5px 20px" }}
                  >
                    <Grid
                      item
                      xs={2}
                      onClick={() => handleShowDataModal("datatakip")}
                    >
                      <div>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: 14,
                            fontFamily: "monospace",
                            color: "#fff",
                            textDecoration: "underline",
                          }}
                        >
                          Data Takip
                        </h3>
                        {dashboardOfficeuserDetail?.application_type_stats && (
                          <>
                            <PieChart width={250} height={120}>
                              <Pie
                                data={dashboardOfficeuserDetail?.application_type_stats?.map(
                                  (row, index) => {
                                    if (row.count === 0) return null;
                                    return {
                                      name: row?.name,
                                      value: row?.count,
                                      fill: COLORS[index % COLORS.length],
                                    };
                                  }
                                )}
                                dataKey="value"
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                outerRadius={40}
                                fill="#8884d8"
                              />
                              <Tooltip />
                            </PieChart>
                            <div
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {dashboardOfficeuserDetail?.application_type_stats?.map(
                                (item) => {
                                  return (
                                    <div>
                                      {item.name} - {item.count}
                                      {item?.count !== 0 &&
                                        "- " +
                                          (
                                            (item.count /
                                              calculatePercent(
                                                dashboardOfficeuserDetail?.application_type_stats
                                              )) *
                                            100
                                          ).toFixed(2) +
                                          " %"}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>

                    <Grid item xs={2} onClick={handleShowRenewalModal}>
                      <div>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: 14,
                            fontFamily: "monospace",
                            color: "#fff",
                            textDecoration: "underline",
                          }}
                        >
                          Sözleşme Uzatma
                        </h3>
                        {dashboardOfficeuserDetail?.product_renewal_stats && (
                          <>
                            <PieChart width={250} height={120}>
                              <Pie
                                data={dashboardOfficeuserDetail?.product_renewal_stats?.map(
                                  (row, index) => {
                                    if (row.count === 0) return null;
                                    const newName =
                                      row.name === "payed"
                                        ? "Ödenmiş"
                                        : row.name === "blocked"
                                        ? "Bloke"
                                        : row.name === "processing"
                                        ? "İşlemde"
                                        : "";
                                    return {
                                      name: newName,
                                      value: row?.count,
                                      fill: COLORS[index % COLORS.length],
                                    };
                                  }
                                )}
                                dataKey="value"
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                outerRadius={40}
                                fill="#8884d8"
                              />
                              <Tooltip />
                            </PieChart>
                            <div
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {dashboardOfficeuserDetail?.product_renewal_stats?.map(
                                (item) => {
                                  return (
                                    <div>
                                      {item.name === "processing"
                                        ? "İşlemde"
                                        : item.name === "payed"
                                        ? "Ödenmiş"
                                        : "Bloke"}{" "}
                                      - {item.count}
                                      {item?.count !== 0 &&
                                        " -" +
                                          (
                                            (item.count /
                                              calculatePercent(
                                                dashboardOfficeuserDetail?.product_renewal_stats
                                              )) *
                                            100
                                          ).toFixed(2) +
                                          " %"}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      onClick={() => handleShowReportModal("bloke")}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: 14,
                            fontFamily: "monospace",
                            color: "#fff",
                            textDecoration: "underline",
                          }}
                        >
                          Bloke Data Nedenleri ve Yüzdeleri
                        </h3>
                        {dashboardOfficeuserDetail?.blocked_restaurant_stats && (
                          <>
                            <PieChart width={250} height={120}>
                              <Pie
                                data={dashboardOfficeuserDetail?.blocked_restaurant_stats?.map(
                                  (row, index) => {
                                    if (row.count === 0) return null;
                                    return {
                                      name: row?.name,
                                      value: row?.count,
                                      fill: COLORS[index % COLORS.length],
                                    };
                                  }
                                )}
                                dataKey="value"
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                outerRadius={40}
                                fill="#8884d8"
                              />
                              <Tooltip />
                            </PieChart>
                            <div
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {dashboardOfficeuserDetail?.blocked_restaurant_stats?.map(
                                (item) => {
                                  return (
                                    <div>
                                      {item.name}- {item.count}
                                      {item.count !== 0 &&
                                        "- " +
                                          (
                                            (item.count /
                                              calculatePercent(
                                                dashboardOfficeuserDetail?.blocked_restaurant_stats
                                              )) *
                                            100
                                          ).toFixed(2) +
                                          " %"}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      onClick={() => handleShowReportModal("deleted")}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: 14,
                            fontFamily: "monospace",
                            color: "#fff",
                            textDecoration: "underline",
                          }}
                        >
                          Silinen Data Nedenleri ve Yüzdeleri
                        </h3>
                        {dashboardOfficeuserDetail?.deleted_application_type_stats && (
                          <>
                            <PieChart width={250} height={120}>
                              <Pie
                                data={dashboardOfficeuserDetail?.deleted_application_type_stats?.map(
                                  (row, index) => {
                                    if (row.count === 0) return null;
                                    return {
                                      name: row?.name,
                                      value: row?.count,
                                      fill: COLORS[index % COLORS.length],
                                    };
                                  }
                                )}
                                dataKey="value"
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                outerRadius={40}
                                fill="#8884d8"
                              />
                              <Tooltip />
                            </PieChart>
                            <div
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {dashboardOfficeuserDetail?.deleted_application_type_stats?.map(
                                (item) => {
                                  return (
                                    <div>
                                      {item.name}- {item.count}
                                      {item?.count !== 0 &&
                                        " -" +
                                          (
                                            (item.count /
                                              calculatePercent(
                                                dashboardOfficeuserDetail?.deleted_application_type_stats
                                              )) *
                                            100
                                          ).toFixed(2) +
                                          " %"}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      // onClick={() => handleShowReportModal("deleted")}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: 14,
                            fontFamily: "monospace",
                            color: "#fff",
                            textDecoration: "underline",
                          }}
                        >
                          CDR Raporlar
                        </h3>
                        {dashboardOfficeuserDetail?.cdr_stats && (
                          <>
                            <PieChart width={250} height={120}>
                              <Pie
                                data={dashboardOfficeuserDetail?.cdr_stats?.map(
                                  (row, index) => {
                                    if (row.count === 0) return null;
                                    return {
                                      name: row?.name,
                                      value: row?.count,
                                      fill: COLORS[index % COLORS.length],
                                    };
                                  }
                                )}
                                dataKey="value"
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                outerRadius={40}
                                fill="#8884d8"
                              />
                              <Tooltip />
                            </PieChart>
                            <div
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {dashboardOfficeuserDetail?.cdr_stats?.map(
                                (item) => {
                                  return (
                                    <div>
                                      {item.name}- {item.count}
                                      {item?.count !== 0 &&
                                        " -" +
                                          (
                                            (item.count /
                                              calculatePercent(
                                                dashboardOfficeuserDetail?.cdr_stats
                                              )) *
                                            100
                                          ).toFixed(2) +
                                          " %"}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            )}
          </Paper>
        </div>
      </div>

      <DataModal
        openDataModal={openDataModal}
        setOpenDataModal={setOpenDataModal}
        title={dataTitle}
        data={data}
        headers={headers}
        rows={rows}
        setData={setData}
        setRows={setRows}
      />

      <ReportModal
        openReportModal={openReportModal}
        setOpenReportModal={setOpenReportModal}
        title={title}
        data={reportData}
      />

      <CompletedTasksModal
        openCompletedTasks={openCompletedTasks}
        setOpenCompletedTasks={setOpenCompletedTasks}
        officeUserDetailCompletedTasks={officeUserDetailCompletedTasks}
        page={page}
        setPage={setPage}
      />

      <TaskRepeatModal
        openTaskRepeatModal={openTaskRepeatModal}
        setOpenTaskRepeatModal={setOpenTaskRepeatModal}
        officeUserDetailTaskRepeats={officeUserDetailTaskRepeats}
        startDate={moment(startDate).format("YYYY-MM-DD")}
        endDate={moment(endDate).format("YYYY-MM-DD")}
        userId={dashboardUserInfo?.user_id}
      />

      <ProductRenewalsModal
        openRenewalModal={openRenewalModal}
        setOpenRenewalModal={setOpenRenewalModal}
        dashboardProductRenewals={dashboardProductRenewals}
        startDate={moment(startDate).format("YYYY-MM-DD")}
        endDate={moment(endDate).format("YYYY-MM-DD")}
        dashboardOfficeuserDetail={dashboardOfficeuserDetail}
      />

      <InfoModal
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        headers={headers}
        rows={rows}
        title={title}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        setRows={setRows}
      />
      <CdrRepeatsModal
        cdrRepeatsModal={cdrRepeatsModal}
        setCdrRepeatsModal={setCdrRepeatsModal}
        startDate={moment(startDate).format("YYYY-MM-DD")}
        endDate={moment(endDate).format("YYYY-MM-DD")}
        userId={dashboardUserInfo?.user_id}
      />
      <CdrInboundModal
        cdrInboundModal={cdrInboundModal}
        setCdrInboundModal={setCdrInboundModal}
        startDate={moment(startDate).format("YYYY-MM-DD")}
        endDate={moment(endDate).format("YYYY-MM-DD")}
        userId={dashboardUserInfo?.user_id}
      />
      <CdrOutboundModal
        cdrOutboundModal={cdrOutboundModal}
        setCdrOutboundModal={setCdrOutboundModal}
        startDate={moment(startDate).format("YYYY-MM-DD")}
        endDate={moment(endDate).format("YYYY-MM-DD")}
        userId={dashboardUserInfo?.user_id}
      />
    </div>
  );
};

export default DetailScreen;
