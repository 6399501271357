import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Badge,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev2";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@mui/styles";
import sepettakipLogo from "../../../../utils/assets/sepettakipLogos/sepettakip-logo.webp";
import sepetappLogo from "../../../../utils/assets/sepettakipLogos/sepetapp-logo.webp";
import sepetfastLogo from "../../../../utils/assets/sepettakipLogos/sepetfast-logo.webp";
import calleridLogo from "../../../../utils/assets/sepettakipLogos/callerid-logo.webp";
import courierLogo from "../../../../utils/assets/sepettakipLogos/courier-logo.webp";
import stoktakipLogo from "../../../../utils/assets/sepettakipLogos/stoktakip-logo.webp";
import masatakipLogo from "../../../../utils/assets/sepettakipLogos/masatakip-logo.webp";
import { platformIconImageConverter } from "../../../../components/converter/platformImage";
import {
  CheckCircleOutline,
  Close,
  DescriptionTwoTone,
  KeyboardVoice,
  Notifications,
  PhonelinkOff,
  ReplyAll,
} from "@mui/icons-material";
import DailyOrderModal from "../../../tasks/modals/dailyOrder";
import ActionModal from "../../../../components/modals/actionsModal";
import { styled } from "@mui/material/styles";
import ReminderModal from "../../../tasks/modals/reminderModal.js";
import LimitedUseModal from "../../../../components/modals/limitedUseModal/index.js";
import VoiceModal from "../../../../components/modals/voiceModal/index.js";
import { GreenSwitch } from "../../../../components/switch/greenSwitch.js";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 12,
    height: 12,
    borderRadius: 8,
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const SummaryScreen = ({ dashboardType, backofficeCollectorListWithAll }) => {
  let history = useHistory();
  const classes = useStyles();
  const {
    appointmentQcdListFunc,
    appointmentQcdList,
    createNewTaskFunc,
    patchLimitedUseTask,
    appointmentTaskBackFunc,
    patchRestofficialReminder,
  } = useContext(RestaurantContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [actionModalResId, setActionModalResId] = useState(null);
  const [dailyOrderModal, setDailyOrderModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [typeSummary, setTypeSummary] = useState("active");
  const [onlyYs, setOnlyYs] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [reminderRow, setReminderRow] = useState(null);
  const [limitedUseModal, setLimitedUseModal] = useState(false);
  const [currentLimitedUser, setCurrentLimitedUser] = useState(null);
  const [voiceModal, setVoiceModal] = useState(false);
  const [currentRestRow, setCurrentRestRow] = useState(null);

  useEffect(() => {
    if (dashboardType === "summary") {
      appointmentQcdListFunc({
        user_id:
          selectedUser !== null
            ? selectedUser?.user_id !== 0
              ? selectedUser?.user_id
              : ""
            : "",
        type: typeSummary,
      });
    }
  }, [typeSummary, selectedUser, dashboardType]);

  const calcDate = (date) => {
    const diff = moment(date).diff(moment(), "days");

    if (diff === 0) {
      return moment(date).diff(moment(), "hours") + "saat";
    } else {
      return moment(date).diff(moment(), "days") + "gün";
    }
  };

  const calcDateCreatedAt = (date) => {
    const diff = moment().diff(moment(date), "days");

    if (diff === 0) {
      return moment().diff(moment(date), "hours") + "saat";
    } else {
      return moment().diff(moment(date), "days") + "gün";
    }
  };

  const restaurantLimitedUse = () => {
    patchLimitedUseTask(currentLimitedUser?.restofficial?.id, {
      limited_use: !currentLimitedUser?.restofficial?.limited_use,
    });
    setTimeout(() => {
      appointmentQcdListFunc({
        user_id:
          selectedUser !== null
            ? selectedUser?.user_id !== 0
              ? selectedUser?.user_id
              : ""
            : "",
        type: typeSummary,
      });
    }, 1000);
  };

  return (
    <div
      style={{
        height: "100%",
        minHeight: 500,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-around",
          margin: "5px 0px",
        }}
      >
        <div
          style={{
            color: "#fff",
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 1,
            padding: "0px 3px",
            height: 25,
            marginTop: 5,
            fontFamily: "segoeuiB",
          }}
        >
          {typeSummary === "active"
            ? "Aktif : "
            : typeSummary === "passive"
            ? "Pasif : "
            : "Tek P : "}
          {appointmentQcdList?.length}
        </div>
        <Select
          styles={{
            ...selectDefaultStyles,
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          options={backofficeCollectorListWithAll}
          darkMode={true}
          responsiveHeight={"auto"}
          selectFontSize={14}
          menuColor={"#f4f4f4"}
          hoverColor={"#f53b62"}
          isSearchable={false}
          selectWidth={"250px"}
          inputColor={"#f4f4f4"}
          textColor={"black"}
          placeHolderColor={"#f53b62"}
          inputFontSize={14}
          dropdownColor={"#f53b62"}
          isClearable={false}
          closeMenuOnSelect={true}
          placeholder="Kişi"
          getOptionLabel={(option) => option.full_name}
          getOptionValue={(option) => option.user_id}
          value={selectedUser}
          onChange={(e) => setSelectedUser(e)}
        />

        <Button
          style={{
            backgroundColor: "#f53b62",
            color: "#fff",
            fontFamily: "segoeuiB",
            width: 200,
            display: "flex",
            justifyContent: "center",
            height: 30,
            margin: 2,
          }}
          size="small"
          onClick={() => {
            setTypeSummary("active");
          }}
        >
          Aktif
        </Button>
        <Button
          style={{
            backgroundColor: "#f53b62",
            color: "#fff",
            fontFamily: "segoeuiB",
            width: 200,
            display: "flex",
            justifyContent: "center",
            height: 30,
            margin: 2,
          }}
          size="small"
          onClick={() => {
            setTypeSummary("passive");
          }}
        >
          Pasif
        </Button>
        <Button
          style={{
            backgroundColor: "#f53b62",
            color: "#fff",
            fontFamily: "segoeuiB",
            width: 200,
            display: "flex",
            justifyContent: "center",
            height: 30,
            margin: 2,
          }}
          size="small"
          onClick={() => {
            setTypeSummary("one_platform");
          }}
        >
          Tek Platform
        </Button>
        <div
          style={{
            boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
            borderRadius: 4,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "whitesmoke",
            padding: "0px 10px",
          }}
        >
          <div
            style={{
              fontSize: 12,
            }}
          >
            Ys Durumu
          </div>
          <GreenSwitch
            onClick={() => {
              setOnlyYs(!onlyYs);
            }}
            checked={onlyYs}
          />
        </div>
      </div>
      <TableContainer>
        <Table
          sx={{
            ".MuiTableCell-root": {
              height: 1,
              lineHeight: 0,
            },
            ".MuiTableCell-root td": {
              color: "#fff",
            },
            overflow: "auto",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ minWidth: 200, width: "auto" }}
              >
                Restoran
              </TableCell>
              <TableCell
                align="center"
                style={{ minWidth: 100, width: "auto" }}
              >
                Officeuser
              </TableCell>
              <TableCell
                align="center"
                style={{ minWidth: 150, width: "auto" }}
              >
                Ürünler
              </TableCell>
              <TableCell
                align="center"
                style={{ minWidth: 150, width: "auto" }}
              >
                Platformlar
              </TableCell>
              <TableCell
                align="center"
                style={{ minWidth: 100, width: "auto" }}
              >
                Kurulum
              </TableCell>
              <TableCell align="center" style={{ minWidth: 80, width: "auto" }}>
                Siparişler
              </TableCell>
              <TableCell align="center" style={{ minWidth: 80, width: "auto" }}>
                Ort Sipariş
              </TableCell>
              <TableCell align="center" style={{ minWidth: 80, width: "auto" }}>
                Teklif
              </TableCell>
              <TableCell align="center" style={{ minWidth: 80, width: "auto" }}>
                Ön Ödeme
              </TableCell>
              <TableCell align="center" style={{ minWidth: 80, width: "auto" }}>
                Task
              </TableCell>
              <TableCell align="center" style={{ minWidth: 10, width: "auto" }}>
                Ys
              </TableCell>
              <TableCell align="center" style={{ minWidth: 10, width: "auto" }}>
                Hatırlatıcı
              </TableCell>
              <TableCell align="center" style={{ minWidth: 10, width: "auto" }}>
                Not
              </TableCell>
              <TableCell align="center" style={{ minWidth: 10, width: "auto" }}>
                Kısıtla
              </TableCell>
              <TableCell align="center" style={{ minWidth: 10, width: "auto" }}>
                Ses Kaydı
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              overflow: "auto",
            }}
          >
            {appointmentQcdList
              ?.sort((a, b) => {
                return moment(a.offer?.starts_at).diff(
                  moment(b.offer?.starts_at)
                );
              })
              .map((row, ind) => {
                if (onlyYs) {
                  if (
                    !row?.platforms?.some((row1) => {
                      return row1.name == "Yemeksepeti";
                    })
                  ) {
                    return;
                  }
                }
                return (
                  <TableRow key={ind}>
                    <TableCell style={{ width: "auto" }}>
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: row?.restofficial?.online
                              ? "#44b700"
                              : "red",
                            color: row?.restofficial?.online
                              ? "#44b700"
                              : "red",
                          },
                        }}
                        variant="dot"
                      ></StyledBadge>
                      <div
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: 12,
                        }}
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/restaurants/${row.restofficial?.id}`,
                            "_blank"
                          );
                        }}
                      >
                        {row?.restofficial?.name?.length > 30 ? (
                          <Tooltip title={row?.restofficial?.name}>
                            <div>
                              {row?.restofficial?.name?.slice(0, 30)}...
                            </div>
                          </Tooltip>
                        ) : (
                          row?.restofficial?.name
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row?.officeuser?.name}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: "flex" }}>
                        {row?.products?.map((row1) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <img
                                src={
                                  row1 === "sepettakip"
                                    ? sepettakipLogo
                                    : row1 === "callerid"
                                    ? calleridLogo
                                    : row1 === "sepetfast"
                                    ? sepetfastLogo
                                    : row1 === "stoktakip"
                                    ? stoktakipLogo
                                    : row1 === "masatakip"
                                    ? masatakipLogo
                                    : row1 === "kuryetakip"
                                    ? courierLogo
                                    : sepetappLogo
                                }
                                alt={row1?.name}
                                style={{
                                  width: 21,
                                  height: 21,
                                  marginLeft: 3,
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {row?.platforms?.map((row1, ind) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#f4f4f4",
                                padding: 4,
                                borderRightColor: "lightgray",
                                borderRightStyle:
                                  row?.platforms?.length !== ind + 1
                                    ? "solid"
                                    : "none",
                              }}
                            >
                              <img
                                src={platformIconImageConverter(row1?.name)}
                                width={15}
                                alt="platform"
                              />
                              <div style={{ marginLeft: 2 }}>{row1?.count}</div>
                            </div>
                          );
                        })}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row?.offer?.product?.map((row1) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <img
                                src={
                                  row1?.key === "sepettakip"
                                    ? sepettakipLogo
                                    : row1?.key === "callerid"
                                    ? calleridLogo
                                    : row1?.key === "sepetfast"
                                    ? sepetfastLogo
                                    : row1?.key === "stoktakip"
                                    ? stoktakipLogo
                                    : row1?.key === "masatakip"
                                    ? masatakipLogo
                                    : row1?.key === "kuryetakip"
                                    ? courierLogo
                                    : sepetappLogo
                                }
                                alt={row1?.key}
                                style={{
                                  width: 21,
                                  height: 21,
                                  marginLeft: 3,
                                }}
                              />
                            </div>
                          );
                        })}
                        <div style={{ marginLeft: 2 }}>
                          {calcDateCreatedAt(row?.offer?.starts_at)}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row?.order_summary?.total_order?.toFixed(0)} /{" "}
                      {row?.order_summary?.total_amount?.toFixed(0)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDailyOrderModal(true);
                        setCurrentRow(row);
                      }}
                    >
                      {row?.order_summary?.average_order?.toFixed(0)} /{" "}
                      {row?.order_summary?.average_amount?.toFixed(0)}
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer?.total_amount?.toFixed(0)} ₺
                    </TableCell>
                    <TableCell align="center">
                      {row?.offer?.down_payment?.toFixed(0)} ₺
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {row?.task?.in_calendar ? (
                          <>
                            {calcDate(row?.task?.start_at)}
                            <ReplyAll
                              onClick={() => {
                                appointmentTaskBackFunc({
                                  id: row?.task?.id,
                                });
                                setTimeout(() => {
                                  appointmentQcdListFunc({
                                    user_id:
                                      selectedUser !== null
                                        ? selectedUser?.user_id !== 0
                                          ? selectedUser?.user_id
                                          : ""
                                        : "",
                                    type: typeSummary,
                                  });
                                }, 1000);
                              }}
                              style={{
                                marginLeft: 3,
                                width: 18,
                                height: 18,
                                cursor: "pointer",
                              }}
                            />
                          </>
                        ) : row?.task?.in_queue ? (
                          <Button
                            style={{
                              backgroundColor: "darkslategrey",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "auto",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                          >
                            Kuyrukta
                          </Button>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "auto",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              let data = {
                                sendingObj: {
                                  task_type: 14,
                                  restofficial: row.restofficial?.id,
                                  officeuser_id: row?.officeuser?.id,
                                },
                              };
                              createNewTaskFunc(data);
                              setTimeout(() => {
                                appointmentQcdListFunc({
                                  user_id:
                                    selectedUser !== null
                                      ? selectedUser?.user_id !== 0
                                        ? selectedUser?.user_id
                                        : ""
                                      : "",
                                  type: typeSummary,
                                });
                              }, 1000);
                            }}
                          >
                            Task Oluştur
                          </Button>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row?.yt_ticket ? (
                        <CheckCircleOutline style={{ color: "green" }} />
                      ) : (
                        <Close style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Notifications
                          style={{
                            cursor: "pointer",
                            color:
                              row?.reminders?.length !== 0
                                ? row?.reminders[0]?.sender_role ===
                                  "officeuser"
                                  ? "green"
                                  : "red"
                                : "gray",
                          }}
                          onClick={() => {
                            if (
                              row?.reminders?.length !== 0 &&
                              row?.reminders[0]?.sender_role === "officeuser" &&
                              !row?.reminders[0]?.is_read
                            ) {
                              let data = {
                                id: row?.reminders[0]?.id,
                                sendObj: {
                                  is_read: true,
                                },
                                fetchData: {
                                  selectedUser,
                                  typeSummary,
                                },
                                reminderType: "restofficial",
                                dashboardType,
                              };
                              patchRestofficialReminder(data);
                            }

                            setReminderRow(row);
                            setReminderModal(true);
                          }}
                        />
                        {row?.reminders?.filter(
                          (item) => item?.sender_role === "officeuser"
                        )?.length !== 0
                          ? row?.reminders?.filter(
                              (item) => item?.sender_role === "officeuser"
                            )?.length
                          : ""}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <DescriptionTwoTone
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActionModal(true);
                          setActionModalResId({
                            user_id: row?.restofficial?.id,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PhonelinkOff
                        disabled
                        style={{
                          cursor:
                            row?.restofficial?.version >= "1.1.5"
                              ? "pointer"
                              : "default",
                          color:
                            row?.restofficial?.version >= "1.1.5"
                              ? row?.restofficial?.limited_use
                                ? "#f30000"
                                : "green"
                              : "gray",
                        }}
                        onClick={() => {
                          if (row?.restofficial?.version >= "1.1.5") {
                            setCurrentLimitedUser(row);
                            setLimitedUseModal(true);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <KeyboardVoice
                        style={{
                          cursor: "pointer",
                          color: "green",
                        }}
                        onClick={() => {
                          setVoiceModal(true);
                          setCurrentRestRow(row);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <ActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        actionModalResId={actionModalResId}
        setActionModalResId={setActionModalResId}
        actionVisiblity={false}
      />

      <DailyOrderModal
        dailyOrderModal={dailyOrderModal}
        setDailyOrderModal={setDailyOrderModal}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />

      <ReminderModal
        reminderModal={reminderModal}
        setReminderModal={setReminderModal}
        reminderType="restofficial"
        reminderRow={reminderRow}
        setReminderRow={setReminderRow}
        fetchData={{
          selectedUser,
          typeSummary,
        }}
        dashboardType={dashboardType}
      />

      <LimitedUseModal
        limitedUseModal={limitedUseModal}
        setLimitedUseModal={setLimitedUseModal}
        restaurantLimitedUse={restaurantLimitedUse}
        restOfficialData={currentLimitedUser?.restofficial}
      />

      <VoiceModal
        voiceModal={voiceModal}
        setVoiceModal={setVoiceModal}
        currentRestRow={currentRestRow}
        type="summary"
      />
    </div>
  );
};

export default SummaryScreen;
