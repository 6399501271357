import { Box, Button, InputBase } from "@mui/material";
import { useEffect, useState } from "react";
import managementService from "../../../../services/managementService";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useToast } from "../../../../components/snackbar";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Notes() {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { alert, success } = useToast();

  const getNotes = async () => {
    await managementService.get_note_templates().then((res) => {
      setNotes(res);
    });
  };

  const sortNotes = async () => {
    let data = {
      sorted: notes.map((item) => item.id),
    };
    await managementService.sort_note_templates(data);
  };

  useEffect(() => {
    if (notes.length > 0) {
      sortNotes();
    }
  }, [notes]);

  useEffect(() => {
    getNotes();
  }, []);

  const getSelectedNoteId = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Box>
      <Box>
        {notes.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#fff",
              marginBottom: 1,
              padding: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              setNote(item.text);
              setIsEditing(true);
              getSelectedNoteId(item.id);
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box style={{ color: "red", fontWeight: 600, marginRight: 10 }}>
                {index + 1} -{" "}
              </Box>
              <Box>{item.text}</Box>
            </Box>

            <Box>
              <ArrowUpwardIcon
                style={{ color: "#f53b62" }}
                onClick={() => {
                  if (index > 0) {
                    const updatedItems = [...notes];
                    const itemToMove = updatedItems.splice(index, 1)[0];
                    updatedItems.splice(index - 1, 0, itemToMove);
                    setNotes(updatedItems);
                  }
                }}
              />

              <ArrowDownwardIcon
                style={{ color: "#f53b62" }}
                onClick={() => {
                  if (index < notes.length - 1) {
                    const updatedItems = [...notes];
                    const itemToMove = updatedItems.splice(index, 1)[0];
                    updatedItems.splice(index + 1, 0, itemToMove);
                    setNotes(updatedItems);
                  }
                }}
              />

              <DeleteIcon
                style={{ color: "#f53b62" }}
                onClick={() => {
                  managementService
                    .delete_note_template(item.id)
                    .then((res) => {
                      success("Not başarıyla silindi.");
                      getNotes();
                    });
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box>
        <span
          style={{
            fontSize: 20,
            marginBottom: 10,
            display: "block",
            fontWeight: "600",
          }}
        >
          Not
        </span>
        <Box sx={{ display: "flex" }}>
          <InputBase
            style={{
              background: "#fff",
              padding: 6,
              fontSize: 16,
              fontWeight: "400",
              fontFamily: "segoeuiRg",
              width: "100%",
              marginRight: 20,
            }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          {isEditing ? (
            <>
              <Button
                variant="contained"
                size="large"
                style={{
                  fontFamily: "segoeuiB",
                  width: 160,
                  fontWeight: 700,
                  backgroundColor: "red",
                  marginRight: 20,
                }}
                onClick={() => {
                  setNote("");
                  setIsEditing(false);
                }}
              >
                Vazgeç
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{
                  fontFamily: "segoeuiB",
                  width: 160,
                  fontWeight: 700,
                  backgroundColor: "#0FBD20",
                }}
                onClick={async () => {
                  setIsEditing(false);
                  setNote("");
                  let data = {
                    tempId: selectedIndex,
                    obj: {
                      text: note,
                    },
                  };
                  await managementService
                    .edit_note_template(data)
                    .then((res) => {
                      getNotes();
                    });
                }}
              >
                Düzenle
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              size="large"
              style={{
                fontFamily: "segoeuiB",
                width: 160,
                fontWeight: 700,
                backgroundColor: "#0FBD20",
              }}
              onClick={async () => {
                if (note.length < 5)
                  return alert("Notunuz en az 5 karakter olmalıdır.");
                await managementService
                  .create_note_template({ text: note })
                  .then((res) => {
                    setNote("");
                    getNotes();
                    success("Not başarıyla eklendi.");
                  });
              }}
            >
              Kaydet
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
