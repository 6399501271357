import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, FormControlLabel, InputBase, Modal, Radio } from "@mui/material";
import { AddCircleOutline, Delete } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 30%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const TextChoicesModalv2 = ({
  textChoicesModalv2,
  setTextChoicesModalv2,
  textChoicesModalInfo,
  ratingSettingListObj,
  setRatingSettingListObj,
}) => {
  const classes = useStyles();
  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={textChoicesModalv2}
      className={classes.modal}
      componentsProps={{
        backdrop: { style: { top: 60 } },
        root: { style: { top: 60 } },
      }}
      onClose={() => {
        setTextChoicesModalv2(false);
      }}
    >
      <Card
        style={{
          width: "30%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <AddCircleOutline
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRatingSettingListObj((prev) =>
                prev.map((item) => ({
                  ...item,
                  features: item.features.map((feature) => ({
                    ...feature,
                    text_choices: Array.isArray(feature.text_choices)
                      ? [...feature.text_choices, { choice: "", sentiment: "" }]
                      : [{ choice: "", sentiment: "" }],
                  })),
                }))
              );
            }}
          />
        </div>
        {textChoicesModalInfo !== null &&
          ratingSettingListObj[textChoicesModalInfo?.currentInd]?.features[
            textChoicesModalInfo?.currentIndF
          ]?.text_choices?.map((row1, ind2) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InputBase
                  spellCheck={false}
                  style={{
                    width: "40%",
                    fontFamily: "segoeuiRg",
                    fontSize: 12,
                    backgroundColor: "#f4f4f4",
                    borderRadius: 4,
                    height: 30,
                  }}
                  rows={1}
                  placeholder={"Not ekle"}
                  className={classes.input}
                  value={row1?.choice}
                  onChange={(e) => {
                    const ratingSettingListObjObj = Object.assign(
                      [],
                      JSON.parse(
                        JSON.stringify({
                          ...ratingSettingListObj,
                        })
                      )
                    );

                    ratingSettingListObjObj[
                      textChoicesModalInfo?.currentInd
                    ].features[textChoicesModalInfo?.currentIndF].text_choices[
                      ind2
                    ].choice = e.target.value;
                    setRatingSettingListObj(ratingSettingListObjObj);
                  }}
                />
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#f4f4f4",
                    marginLeft: 30,
                  }}
                >
                  <FormControlLabel
                    value={
                      ratingSettingListObj[textChoicesModalInfo?.currentInd]
                        ?.features[textChoicesModalInfo?.currentIndF]
                        ?.text_choices[ind2]?.sentiment === "positive"
                        ? true
                        : false
                    }
                    control={
                      <Radio
                        checked={
                          ratingSettingListObj[textChoicesModalInfo?.currentInd]
                            ?.features[textChoicesModalInfo?.currentIndF]
                            ?.text_choices[ind2]?.sentiment === "positive"
                            ? true
                            : false
                        }
                        size="small"
                        sx={{
                          color: "#fff",
                          "&.Mui-checked": {
                            color: "#6f1111",
                          },
                        }}
                        onClick={() => {
                          const ratingSettingListObjObj = Object.assign(
                            [],
                            JSON.parse(
                              JSON.stringify({
                                ...ratingSettingListObj,
                              })
                            )
                          );
                          if (
                            ratingSettingListObjObj[
                              textChoicesModalInfo?.currentInd
                            ].features[textChoicesModalInfo?.currentIndF]
                              .text_choices[ind2].sentiment === "positive"
                          ) {
                            ratingSettingListObjObj[
                              textChoicesModalInfo?.currentInd
                            ].features[
                              textChoicesModalInfo?.currentIndF
                            ].text_choices[ind2].sentiment = "";
                          } else {
                            ratingSettingListObjObj[
                              textChoicesModalInfo?.currentInd
                            ].features[
                              textChoicesModalInfo?.currentIndF
                            ].text_choices[ind2].sentiment = "positive";
                          }
                          setRatingSettingListObj(ratingSettingListObjObj);
                        }}
                      />
                    }
                    label="Pozitif"
                    labelPlacement="start"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 12,
                      },
                    }}
                  />
                  <FormControlLabel
                    value={
                      ratingSettingListObj[textChoicesModalInfo?.currentInd]
                        ?.features[textChoicesModalInfo?.currentIndF]
                        ?.text_choices[ind2]?.sentiment === "negative"
                        ? true
                        : false
                    }
                    control={
                      <Radio
                        checked={
                          ratingSettingListObj[textChoicesModalInfo?.currentInd]
                            ?.features[textChoicesModalInfo?.currentIndF]
                            ?.text_choices[ind2]?.sentiment === "negative"
                            ? true
                            : false
                        }
                        size="small"
                        sx={{
                          color: "#fff",
                          "&.Mui-checked": {
                            color: "#6f1111",
                          },
                        }}
                        onClick={() => {
                          const ratingSettingListObjObj = Object.assign(
                            [],
                            JSON.parse(
                              JSON.stringify({
                                ...ratingSettingListObj,
                              })
                            )
                          );
                          if (
                            ratingSettingListObjObj[
                              textChoicesModalInfo?.currentInd
                            ].features[textChoicesModalInfo?.currentIndF]
                              .text_choices[ind2].sentiment === "negative"
                          ) {
                            ratingSettingListObjObj[
                              textChoicesModalInfo?.currentInd
                            ].features[
                              textChoicesModalInfo?.currentIndF
                            ].text_choices[ind2].sentiment = "";
                          } else {
                            ratingSettingListObjObj[
                              textChoicesModalInfo?.currentInd
                            ].features[
                              textChoicesModalInfo?.currentIndF
                            ].text_choices[ind2].sentiment = "negative";
                          }
                          setRatingSettingListObj(ratingSettingListObjObj);
                        }}
                      />
                    }
                    label="Negatif"
                    labelPlacement="start"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 12,
                      },
                    }}
                  />
                </div>
                <Delete
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const ratingSettingListObjObj = Object.assign(
                      [],
                      JSON.parse(
                        JSON.stringify({
                          ...ratingSettingListObj,
                        })
                      )
                    );
                    let index = ratingSettingListObjObj[
                      textChoicesModalInfo?.currentInd
                    ].features[
                      textChoicesModalInfo?.currentIndF
                    ].text_choices?.findIndex((item) => item.id === row1.id);

                    ratingSettingListObjObj[
                      textChoicesModalInfo?.currentInd
                    ].features[
                      textChoicesModalInfo?.currentIndF
                    ].text_choices.splice(index, 1);

                    setRatingSettingListObj(ratingSettingListObjObj);
                  }}
                />
              </div>
            );
          })}
      </Card>
    </Modal>
  );
};

export default TextChoicesModalv2;
