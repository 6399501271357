import { InputBase } from "@mui/material";
import React from "react";

const InputField = ({
  fieldValue,
  fieldName,
  formData,
  setFormData,
  label,
  disabled,
}) => {
  const handleChange = (name) => (event) => {
    let valueData = event.target ? event.target.value : event;
    let newDataRest = { ...formData };

    if (name) {
      newDataRest = { ...formData, [name]: valueData };
    }

    setFormData(newDataRest);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",

        backgroundColor: "#fff",
        height: 60,
        borderRadius: 6,
      }}
    >
      <span
        style={{
          color: "#585858",
          fontFamily: "segoeuiRg",
          fontSize: 13,
          fontWeight: 700,
          marginLeft: 8,
          marginBottom: 4,
        }}
      >
        {label}
      </span>
      <InputBase
        variant="filled"
        style={{
          width: "100%",
          backgroundColor: "#fff",
          color: "black",
          paddingLeft: 12,
          fontSize: 18,
          fontWeight: "400",
          fontFamily: "segoeuiRg",
          height: "55px",
          borderRadius: 6,
        }}
        value={fieldValue}
        disabled={disabled}
        onChange={handleChange(fieldName)}
      />
    </div>
  );
};

export default InputField;
