import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useToast } from "../../../components/snackbar";
import FiberNew from "@mui/icons-material/FiberNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneIcon from "@mui/icons-material/Done";
import LinkIcon from "@mui/icons-material/Link";
import { DevelopmentContext } from "../../../contexts/developmentContext";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ChangeColorModal from "./ChangeColorModal";
import emptyImg from "../../../utils/assets/empty.png";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import TaskInfoModal from "./TaskInfoModal";
import SearchWithIcon from "../../../components/searchWithIcon";
import useDebounce from "../../../components/debounce";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import AddSubTaskModal from "./AddSubTaskModal";
import SubTaskModal from "./SubTaskModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { GreenSwitch } from "../../../components/switch/greenSwitch";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function ProcessingDetail({
  showProcessingDetail,
  setShowProcessingDetail,
  taskStatuses,
  developmentUserList,
  taskGeneralStatus,
  setTaskDetail,
  selectedProcessingTask,
  setSelectedProcessingTask,
  ownRequest,
}) {
  const userInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));
  const { alert } = useToast();
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState(null);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [note, setNote] = useState("");
  const [showNotePeople, setShowNotePeople] = useState(false);
  const {
    addNoteToMainTask,
    getTaskDetail,
    taskDetail,
    getSubTaskDetail,
    subTaskDetail,
    deleteNote,
  } = useContext(DevelopmentContext);
  const [openColorModal, setOpenColorModal] = useState(false);
  const [openAddSubTask, setOpenAddSubTask] = useState(false);
  const [openTaskInfo, setOpenTaskInfo] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedsearchValue = useDebounce(searchTerm, 1000);
  const [taskNotes, setTaskNotes] = useState([]);
  const [showAllNotes, setShowAllNotes] = useState(false);
  const [openSubTask, setOpenSubTask] = useState(false);
  const [checkedNoteType, setCheckedNoteType] = useState(false);
  const [ownSubRequest, setOwnSubRequest] = useState(true);

  useEffect(() => {
    if (debouncedsearchValue.length === 0) {
      const noteType = checkedNoteType ? "all" : "officeuser";
      const requestType = ownSubRequest ? "all" : "own";
      getTaskDetail(selectedProcessingTask?.id, noteType, requestType);
    } else {
      const filteredNotes = taskNotes.filter((note) =>
        note.note.toLowerCase().includes(debouncedsearchValue.toLowerCase())
      );
      setTaskNotes(filteredNotes);
    }
  }, [debouncedsearchValue, checkedNoteType, ownSubRequest]);

  useEffect(() => {
    if (taskDetail) {
      const taskDetailCopy = { ...taskDetail };
      taskDetailCopy.notes.unshift({
        note: taskDetail.description.replace(/\n/g, "<br />"),
        created_by: {
          picture: taskDetail.request_by.picture,
        },
        created_at: taskDetail.created_at,
      });

      if (taskDetail?.created_by) {
        taskDetailCopy.notes.unshift({
          note: taskDetail.note.replace(/\n/g, "<br />"),
          created_by: {
            picture: taskDetail?.created_by?.picture,
          },
          created_at: taskDetail.created_at,
        });
      } else {
        taskDetailCopy.notes.unshift({
          note: taskDetail.note,
          created_by: {
            picture: "/error-icon.png",
          },
          created_at: taskDetail.created_at,
        });
      }

      setTaskNotes(taskDetailCopy.notes);
    }
  }, [taskDetail]);

  const handleChange = (e, index) => {
    setCheckedUsers((prev) => {
      let newArr = [...prev];
      newArr[index] = e.target.checked;
      return newArr;
    });
  };

  useEffect(() => {
    if (taskDetail) {
      taskDetail.users.map((user) => {
        if (user.officeuser.user_id === userInfo.user_id) {
          setSelectedUser(user);
        }
      });
    }
  }, [taskDetail]);

  const handleSaveNote = () => {
    const users = developmentUserList.filter(
      (user, index) => checkedUsers[index]
    );
    const usersArr = users.map((user) => user.user_id);

    if (note === "") {
      alert("Not alanı boş bırakılamaz");
      return;
    }

    const data = {
      users: usersArr,
      note: note,
    };
    const own = ownRequest ? "own" : "all";
    const noteType = checkedNoteType ? "all" : "officeuser";
    addNoteToMainTask(taskDetail.id, data, own, noteType);
    setNote("");
    setCheckedUsers([]);
  };

  if (!taskDetail) return;

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={showProcessingDetail}
      className={classes.modal}
      onClose={() => {
        setShowProcessingDetail(false);
        setNote("");
        setCheckedUsers([]);
        setSelectedUser(null);
        setSelectedProcessingTask(null);
        setTaskDetail(null);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "80%",
          maxHeight: "90%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            margin: "10px 0px",
            padding: 15,
            border: "1px solid rgba(191,191,191,0.4)",
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Task Renk Değiştir">
                <span
                  style={{
                    display: "block",
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: taskDetail.color,
                    borderRadius: 9999,
                    color: "#fff",
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenColorModal(true);
                  }}
                ></span>
              </Tooltip>

              <div style={{ marginLeft: 5 }}>
                <span
                  style={{
                    fontFamily: "segoeuiRg",
                    fontWeight: "bold",
                    borderRadius: 9999,
                    padding: "5px 10px",
                    backgroundColor: taskDetail.color,
                    color: "#fff",
                  }}
                >
                  Task No:{taskDetail.id}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  marginRight: 20,
                  paddingRight: 20,
                  borderRight: "1px solid rgba(191,191,191,0.5)",
                }}
              >
                <div style={{ display: "flex" }}>
                  {taskDetail?.file?.map((file, i) => {
                    return (
                      <div key={i} style={{ marginRight: 5 }}>
                        <Tooltip title="Dosya Görüntüle">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {i + 1}-
                            <FolderIcon
                              style={{
                                color: "red",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() => {
                                window.open(file.url, "_blank");
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div style={{ display: "flex" }}>
                {taskDetail?.links?.map((link, i) => {
                  return (
                    <div key={i} style={{ marginLeft: 10 }}>
                      <Tooltip title="Linki Görüntüle">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {i + 1}-
                          <LinkIcon
                            style={{
                              color: "red",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              window.open(link, "_blank");
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            </div>

            <InfoIcon
              style={{
                color: taskDetail.color,
                cursor: "pointer",
                fontSize: 32,
              }}
              onClick={() => {
                setOpenTaskInfo(true);
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            padding: "15px",
            border: "1px solid rgba(191,191,191,0.4)",
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            position: "relative",
            margin: "10px 0px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <div style={{ width: "50%" }}>
                <SearchWithIcon
                  setSearchTerm={setSearchTerm}
                  placeTitle={"Not Ara"}
                  searchTerm={searchTerm}
                  customWidth={"100%"}
                />
              </div>

              <div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedNoteType}
                        onChange={(e) => {
                          setCheckedNoteType(e.target.checked);
                        }}
                      />
                    }
                    label="Sistem Notları"
                  />
                </FormGroup>
              </div>
            </div>

            {taskNotes.length === 0 ? (
              <div style={{ color: "red", fontWeight: "bold", fontSize: 14 }}>
                Not bulunamadı
              </div>
            ) : (
              <div
                style={{
                  height: showAllNotes
                    ? "auto"
                    : taskNotes.length > 5
                    ? "335px"
                    : "auto",
                  overflow: "auto",
                  paddingRight: 5,
                  transition: "max-height 0.5s",
                }}
              >
                {taskNotes.map((note, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        borderRadius: 12,
                        padding: 5,
                        margin: "5px 0px",
                        background: taskDetail.color,
                        fontSize: 13,
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title={note.created_by.full_name}>
                          <img
                            src={note.created_by.picture}
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 9999,
                            }}
                          />
                        </Tooltip>
                        <div
                          style={{
                            fontSize: 11,
                            margin: "0px 0px 0px 10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          ({moment(note.created_at).format("DD-MM-YYYY HH:mm")})
                        </div>
                        <div
                          style={{ marginLeft: 10, marginRight: 10 }}
                          dangerouslySetInnerHTML={{
                            __html: note?.note?.replace(/\n/g, "<br />"),
                          }}
                        ></div>
                      </div>

                      <Tooltip title="Notu Sil">
                        <DeleteIcon
                          style={{ color: "#fff", cursor: "pointer" }}
                          onClick={() => {
                            if (note.id) {
                              deleteNote(taskDetail.id, note.id);
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            )}

            {taskNotes.length > 6 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {showAllNotes ? (
                  <KeyboardDoubleArrowUpIcon
                    style={{ color: taskDetail.color, cursor: "pointer" }}
                    onClick={() => {
                      setShowAllNotes(!showAllNotes);
                    }}
                  />
                ) : (
                  <KeyboardDoubleArrowDownIcon
                    style={{ color: taskDetail.color, cursor: "pointer" }}
                    onClick={() => {
                      setShowAllNotes(!showAllNotes);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            padding: 10,
            border: "1px solid rgba(191,191,191,0.4)",
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                marginRight: 20,
                width: showNotePeople ? "75%" : "100%",
              }}
            >
              <TextField
                placeholder="Not"
                multiline
                rows={2}
                style={{ width: "100%", height: "100%" }}
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>

            {showNotePeople && (
              <div
                style={{
                  maxHeight: 80,
                  overflow: "auto",
                  width: showNotePeople ? "auto" : 0,
                }}
              >
                {developmentUserList.map((user, index) => {
                  return (
                    <div key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedUsers[index] || false}
                            onChange={(e) => handleChange(e, index)}
                          />
                        }
                        label={user.full_name}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100px",
                marginLeft: 20,
              }}
            >
              <Button
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: "#f53b62",
                  marginBottom: 10,
                  color: "#fff",
                }}
                onClick={() => {
                  setCheckedUsers([]);
                  setShowNotePeople(!showNotePeople);
                }}
              >
                Kişi Seç
              </Button>
              <Button
                variant="outline"
                size="small"
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  backgroundColor: "green",
                  color: "#fff",
                }}
                onClick={handleSaveNote}
              >
                Gönder
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: 15,
            border: "1px solid rgba(191,191,191,0.4)",
            borderRadius: 8,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            margin: "10px 0px",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <div style={{ width: "100%" }}>
            <div>
              <div
                style={{
                  fontFamily: "segoeuiRg",
                  fontWeight: "bold",
                  marginBottom: 20,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 20,
                }}
              >
                <span>Alt Tasklar</span>
                <AddCircleOutlineIcon
                  style={{
                    color: taskDetail.color,
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenAddSubTask(true);
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontFamily: "segoeuiRg", fontSize: 12 }}>
                    Tümü
                  </div>
                  <GreenSwitch
                    onClick={() => {
                      setOwnSubRequest(!ownSubRequest);
                    }}
                    checked={ownSubRequest}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontFamily: "segoeuiRg", fontSize: 12 }}>
                    Kendi
                  </div>
                  <GreenSwitch
                    onClick={() => {
                      setOwnSubRequest(!ownSubRequest);
                    }}
                    checked={!ownSubRequest}
                  />
                </div>
              </div>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#ffb818",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "segoeuiB",
                      fontSize: 13,
                      padding: "5px 0px",
                      color: "#fff",
                    }}
                  >
                    ONAY
                  </div>
                  <div
                    style={{
                      maxHeight: "350px",
                      overflow: "auto",
                      padding: "5px",
                      marginTop: 10,
                    }}
                  >
                    {taskDetail?.subtasks_new_seen?.map((row) => {
                      return (
                        <div
                          key={row.id}
                          style={{
                            padding: 10,
                            marginBottom: 15,
                            boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.7)",
                            borderRadius: 12,
                            cursor: "pointer",
                            background: row.color,
                          }}
                          onClick={() => {
                            getSubTaskDetail(row.id);
                            setOpenSubTask(true);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 15,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  background: "#f53b62",
                                  padding: "1px 5px",
                                  color: "white",
                                  marginRight: 10,
                                  boxShadow: "0px 0px 3px 1px #f53b62",
                                }}
                              >
                                {row.priority}
                              </div>
                            </div>

                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#fff",
                                padding: "3px 5px",
                                borderRadius: "8px",
                              }}
                            >
                              {row.title}
                            </div>
                          </div>

                          <div
                            style={{
                              backgroundColor: "#efefef",
                              borderRadius: 4,
                              padding: 10,
                              boxShadow:
                                "1px 2px 2px 2px rgba(191,191,191,0.4)",
                              fontFamily: "segoeuiRg",
                              fontSize: 14,
                              marginTop: 5,
                            }}
                          >
                            {row?.note}{" "}
                          </div>

                          <Grid container spacing={2} style={{ marginTop: 15 }}>
                            {row.users.map((user, i) => {
                              return (
                                <Grid item xs={4} key={i}>
                                  <div
                                    style={{
                                      background: "#fff",
                                      borderRadius: 8,
                                      boxShadow:
                                        "1px 2px 2px 2px rgba(191,191,191,0.4)",
                                      padding: 10,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Tooltip title={user.officeuser.full_name}>
                                      <img
                                        src={
                                          user.officeuser.picture
                                            ? user.officeuser.picture
                                            : emptyImg
                                        }
                                        style={{
                                          width: 35,
                                          height: 35,
                                          borderRadius: 9999,
                                        }}
                                      />
                                    </Tooltip>

                                    <div
                                      style={{
                                        fontFamily: "segoeuiRg",
                                        fontSize: 12,
                                        textAlign: "center",
                                        marginLeft: 5,
                                      }}
                                    >
                                      {user.status === "new" ? (
                                        <Tooltip title="Yeni Task">
                                          <FiberNew
                                            style={{ color: "#f53b62" }}
                                          />
                                        </Tooltip>
                                      ) : user.status === "seen" ? (
                                        <Tooltip title="Görüldü">
                                          <VisibilityIcon
                                            style={{ color: "#f53b62" }}
                                          />
                                        </Tooltip>
                                      ) : user.status === "processing" ? (
                                        <Tooltip
                                          title={
                                            user.task_duration +
                                            " " +
                                            user.task_duration_type
                                          }
                                        >
                                          <TimelapseIcon
                                            style={{ color: "#f53b62" }}
                                          />
                                        </Tooltip>
                                      ) : user.status === "completed" ? (
                                        <Tooltip title="Tamamlandı">
                                          <DoneIcon
                                            style={{ color: "#f53b62" }}
                                          />
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>

                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              color: "gray",
                              fontSize: 13,
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: 10,
                            }}
                          >
                            {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#6aa1ff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "segoeuiB",
                      fontSize: 13,
                      padding: "5px 0px",
                      color: "#fff",
                      position: "relative",
                    }}
                  >
                    SÜREÇ
                  </div>
                  <div
                    style={{
                      maxHeight: "350px",
                      overflow: "auto",
                      padding: "5px",
                      marginTop: 10,
                    }}
                  >
                    {taskDetail?.subtasks_processing?.map((row) => {
                      return (
                        <div
                          key={row.id}
                          style={{
                            padding: 10,
                            marginBottom: 15,
                            boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.7)",
                            borderRadius: 12,
                            cursor: "pointer",
                            background: row.color,
                          }}
                          onClick={() => {
                            getSubTaskDetail(row.id);
                            setOpenSubTask(true);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 15,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  background: "#f53b62",
                                  padding: "1px 5px",
                                  color: "white",
                                  marginRight: 10,
                                  boxShadow: "0px 0px 3px 1px #f53b62",
                                }}
                              >
                                {row.priority}
                              </div>
                            </div>

                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#fff",
                                padding: "3px 5px",
                                borderRadius: "8px",
                              }}
                            >
                              {row.title}
                            </div>
                          </div>

                          <div
                            style={{
                              backgroundColor: "#efefef",
                              borderRadius: 4,
                              padding: 10,
                              boxShadow:
                                "1px 2px 2px 2px rgba(191,191,191,0.4)",
                              fontFamily: "segoeuiRg",
                              fontSize: 14,
                              marginTop: 5,
                            }}
                          >
                            {row?.note}{" "}
                          </div>

                          <Grid container spacing={2} style={{ marginTop: 15 }}>
                            {row.users.map((user, i) => {
                              return (
                                <Grid item xs={4} key={i}>
                                  <div
                                    style={{
                                      background: "#fff",
                                      borderRadius: 8,
                                      boxShadow:
                                        "1px 2px 2px 2px rgba(191,191,191,0.4)",
                                      padding: 10,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Tooltip title={user.officeuser.full_name}>
                                      <img
                                        src={
                                          user.officeuser.picture
                                            ? user.officeuser.picture
                                            : emptyImg
                                        }
                                        style={{
                                          width: 35,
                                          height: 35,
                                          borderRadius: 9999,
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>

                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              color: "gray",
                              fontSize: 13,
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: 10,
                            }}
                          >
                            {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={4}>
                <div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#3ac100",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "segoeuiB",
                      fontSize: 13,
                      padding: "5px 0px",
                      color: "#fff",
                      position: "relative",
                    }}
                  >
                    TAMAMLANAN
                  </div>
                  <div
                    style={{
                      maxHeight: "350px",
                      overflow: "auto",
                      padding: "5px",
                      marginTop: 10,
                    }}
                  >
                    {taskDetail?.subtasks_completed?.map((row) => {
                      return (
                        <div
                          key={row.id}
                          style={{
                            padding: 10,
                            marginBottom: 15,
                            boxShadow: "1px 2px 2px 2px rgba(191,191,191,0.7)",
                            borderRadius: 12,
                            cursor: "pointer",
                            background: row.color,
                          }}
                          onClick={() => {
                            getSubTaskDetail(row.id);
                            setOpenSubTask(true);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 15,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  background: "#f53b62",
                                  padding: "1px 5px",
                                  color: "white",
                                  marginRight: 10,
                                  boxShadow: "0px 0px 3px 1px #f53b62",
                                }}
                              >
                                {row.priority}
                              </div>
                            </div>

                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#fff",
                                padding: "3px 5px",
                                borderRadius: "8px",
                              }}
                            >
                              {row.title}
                            </div>
                          </div>

                          <div
                            style={{
                              backgroundColor: "#efefef",
                              borderRadius: 4,
                              padding: 10,
                              boxShadow:
                                "1px 2px 2px 2px rgba(191,191,191,0.4)",
                              fontFamily: "segoeuiRg",
                              fontSize: 14,
                              marginTop: 5,
                            }}
                          >
                            {row?.note}{" "}
                          </div>

                          <Grid container spacing={2} style={{ marginTop: 15 }}>
                            {row.users.map((user, i) => {
                              return (
                                <Grid item xs={4} key={i}>
                                  <div
                                    style={{
                                      background: "#fff",
                                      borderRadius: 8,
                                      boxShadow:
                                        "1px 2px 2px 2px rgba(191,191,191,0.4)",
                                      padding: 10,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Tooltip title={user.officeuser.full_name}>
                                      <img
                                        src={
                                          user.officeuser.picture
                                            ? user.officeuser.picture
                                            : emptyImg
                                        }
                                        style={{
                                          width: 35,
                                          height: 35,
                                          borderRadius: 9999,
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>

                          <div
                            style={{
                              fontFamily: "segoeuiRg",
                              color: "gray",
                              fontSize: 13,
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: 10,
                            }}
                          >
                            {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        ></div>

        <ChangeColorModal
          openColorModal={openColorModal}
          setOpenColorModal={setOpenColorModal}
          taskDetail={taskDetail}
          type={"task"}
        />

        <AddSubTaskModal
          openAddSubTask={openAddSubTask}
          setOpenAddSubTask={setOpenAddSubTask}
          taskDetail={taskDetail}
          developmentUserList={developmentUserList}
        />

        <TaskInfoModal
          openTaskInfo={openTaskInfo}
          setOpenTaskInfo={setOpenTaskInfo}
          taskDetail={taskDetail}
          taskGeneralStatus={taskGeneralStatus}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          taskStatuses={taskStatuses}
          setShowProcessingDetail={setShowProcessingDetail}
          developmentUserList={developmentUserList}
        />

        <SubTaskModal
          openSubTask={openSubTask}
          setOpenSubTask={setOpenSubTask}
          taskDetail={taskDetail}
          subTaskDetail={subTaskDetail}
          developmentUserList={developmentUserList}
          taskGeneralStatus={taskGeneralStatus}
          taskStatuses={taskStatuses}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </Card>
    </Modal>
  );
}
