import {
  Backdrop,
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import TechCompleteModal from "../../../components/modals/techCompleteModal";
import { AccessTime, Home, Reply } from "@mui/icons-material";
import moment from "moment";
import SearchWithIcon from "../../../components/searchWithIcon";

export default function TechnicalService() {
  let history = useHistory();
  const { techSupportListFunc, techSupportList, techSupportDetail } =
    useContext(RestaurantContext);
  const [techCompleteModal, setTechCompleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [stayed, setStayed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    techSupportListFunc({
      stayed,
      searchTerm,
    });
  }, [stayed, searchTerm]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          marginTop: 30,
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%" }}>
          <SearchWithIcon
            setSearchTerm={setSearchTerm}
            placeTitle={"Restoran Ara"}
            searchTerm={searchTerm}
          />
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "black",
              color: "#fff",
              fontFamily: "segoeuiB",
              width: 100,
              height: 40,
            }}
            size="small"
            onClick={() => {
              if (stayed) {
                setStayed(false);
              } else {
                setStayed(true);
              }
            }}
          >
            {stayed ? <Home /> : <AccessTime />}
          </Button>{" "}
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Gönderen Kişi</TableCell>
                <TableCell align="center">Gönderen Tipi</TableCell>
                <TableCell align="center">Restoran Adı</TableCell>
                <TableCell align="center">Restoran Yetkilisi</TableCell>
                <TableCell align="center">Telefon</TableCell>
                {stayed && (
                  <>
                    <TableCell align="center">Bekleme zamanı</TableCell>
                    <TableCell align="center">Bekleme notu</TableCell>
                  </>
                )}
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {techSupportList?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">
                      {row?.created_by === null
                        ? "-"
                        : row?.created_by?.full_name}
                    </TableCell>
                    <TableCell align="center">
                      {row?.created_by === null
                        ? "Restoran"
                        : row?.created_by?.role === "call_center"
                        ? "CallCenter"
                        : row?.created_by?.role === "collector"
                        ? "Tahsilat"
                        : row?.created_by?.role === "setup"
                        ? "Kurulum"
                        : row?.created_by?.role === "setup"
                        ? "Kurulum"
                        : row?.created_by?.role}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push(
                            `/restaurants/${row?.restofficial?.user_id}`
                          );
                        }}
                      >
                        {row?.restofficial?.name}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row?.restofficial?.full_name}
                    </TableCell>
                    <TableCell align="center">
                      {row?.restofficial?.phone}
                    </TableCell>
                    {stayed && (
                      <>
                        <TableCell align="center">
                          {moment(row?.stay_time).format("DD-MM-YYYY HH:mm")}
                        </TableCell>
                        <TableCell align="center">{row?.stay_note}</TableCell>
                      </>
                    )}
                    <TableCell align="center">
                      {stayed ? (
                        <Reply
                          onClick={() => {
                            let data = {
                              support_id: row?.id,
                              stayed: false,
                              searchTerm,
                              sendObj: {
                                stay_time: moment()
                                  .subtract(1, "seconds")
                                  .toISOString(true),
                                stay_note: "Geri alındı.",
                              },
                            };
                            techSupportDetail(data);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Button
                          style={{
                            backgroundColor:
                              row?.status === "new" ? "green" : "tomato",
                            color: "#fff",
                            fontFamily: "segoeuiB",
                            width: 100,
                            height: 40,
                          }}
                          size="small"
                          onClick={() => {
                            let data;
                            if (row?.status === "new") {
                              data = {
                                support_id: row?.id,
                                stayed,
                                searchTerm,
                                sendObj: {
                                  status: "processing",
                                },
                              };
                            } else if (row?.status === "processing") {
                              setTechCompleteModal(true);
                              setCurrentRow(row);
                            }
                            techSupportDetail(data);
                          }}
                        >
                          {row?.status === "new" ? "İşleme Al" : "Kapat"}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TechCompleteModal
        techCompleteModal={techCompleteModal}
        setTechCompleteModal={setTechCompleteModal}
        techSupportDetail={techSupportDetail}
        currentRow={currentRow}
        stayed={stayed}
        searchTerm={searchTerm}
      />
    </Container>
  );
}
