import { Box } from "@mui/material";
import InputField from "../../../../../../components/input";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ProductSummaryDesc from "./productSummaryDesc";

export default function ProductSummary({
  name,
  summaries,
  productData,
  setProductData,
  handleAddDescription,
}) {
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        background: "#efefef",
        padding: 1,
        position: "relative",
        marginBottom: 2,
      }}
    >
      <h3 style={{ textAlign: "center" }}>Ürün Özet</h3>
      <Box sx={{ marginRight: 2, marginBottom: 2 }}>
        <InputField
          label="Ürün Özet Başlık"
          fieldName="header_summary"
          formData={productData}
          setFormData={setProductData}
          fieldValue={name}
        />
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {productData?.summaries?.map((summary, i) => (
          <ProductSummaryDesc
            key={i}
            summary={summary}
            productData={productData}
            setProductData={setProductData}
            featureIndex={i}
            summaries={summaries}
          />
        ))}
      </Box>

      <Box
        sx={{
          background: "#fff",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: 1,
          width: "fit-content",
          marginLeft: "auto",
        }}
        onClick={handleAddDescription}
      >
        <span
          style={{
            marginRight: 10,
            display: "block",
            color: "#585858",
            fontFamily: "segoeuiRg",
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          Ekle
        </span>
        <AddBoxIcon style={{ color: "red" }} />
      </Box>
    </Box>
  );
}
