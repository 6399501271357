import { useSnackbar } from "notistack";

export function useToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const alert = (text, displayTime) =>
    enqueueSnackbar(text, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: displayTime ? displayTime : 2500,
    });

  const alertOrderStatus = (text) =>
    enqueueSnackbar(text, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 4000,
    });

  const alertUrgent = (text) =>
    enqueueSnackbar(text, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 10000,
    });

  const alertPersist = (text) =>
    enqueueSnackbar(text, {
      variant: "error",
      preventDuplicate: true,
      persist: true,
    });
  const warn = (text) =>
    enqueueSnackbar(text, {
      variant: "warning",
      preventDuplicate: true,
      autoHideDuration: 1500,
    });
  const info = (text) =>
    enqueueSnackbar(text, {
      variant: "info",
      preventDuplicate: true,
      autoHideDuration: 1500,
    });
  const infoPersist = (text) =>
    enqueueSnackbar(text, {
      variant: "info",
      preventDuplicate: true,
      persist: true,
    });
  const infoPersistWs = (text) =>
    enqueueSnackbar(text, {
      variant: "info",
      preventDuplicate: true,
      persist: true,
      autoHideDuration: 4500,
    });
  const success = (text) =>
    enqueueSnackbar(text, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 1500,
    });
  const successV2 = (text, displayTime) =>
    enqueueSnackbar(text, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: displayTime ? displayTime : 2500,
    });

  const closeSnack = (key) => closeSnackbar(key);

  return {
    alert,
    warn,
    info,
    success,
    closeSnack,
    alertPersist,
    infoPersist,
    infoPersistWs,
    alertOrderStatus,
    alertUrgent,
    successV2,
  };
}
