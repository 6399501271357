import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/tr";
import {
  Switch,
  Button,
  useMediaQuery,
  createTheme,
  Tooltip,
  TextField,
  Box,
  InputBase,
  Paper,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import { RestaurantInfo } from "./components/RestaurantInfo";
import SpeedDialActions from "./components/speedDialAction";
import { RestaurantContext } from "../../../contexts/restaurantContext";

import Trendyol from "../../../utils/assets/trendyol_logo.png";
import Yemeksepeti from "../../../utils/assets/yemeksepeti_logo.png";
import Getir from "../../../utils/assets/getir_logo.png";
import Gofody from "../../../utils/assets/gofody_logo.png";
import Migros from "../../../utils/assets/migrosa.png";
import OfficeUserList from "../../../components/modals/officeUserList";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SearchIcon from "@mui/icons-material/Search";
import restaurantService from "../../../services/restaurantService";
import OnlineStatusModal from "../../../components/modals/onlineStatusModal";
import CustomerOrders from "../../../components/modals/customerOrders";
import ProductExpiredModal from "./components/productExpiredModal";
import { useToast } from "../../../components/snackbar";
import { ManagementContext } from "../../../contexts/managementContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RedoIcon from "@mui/icons-material/Redo";
import ChangeCollectorModal from "../../../components/modals/changeCollector";
import destekImg from "../../../utils/assets/sup_logo_dark.svg";
import TechSupport from "./components/techSupportModal";

const CustomerSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "red",
  },
}));

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#f53b62",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiFormControl-root MuiFormControl-fullWidth css-wb57ya-MuiFormControl-root-MuiTextField-root":
      {
        display: "flex",
        height: 55,
        backgroundColor: "#fafafa",
      },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#f53b62",
      },
    },
  },
})(TextField);

const Kunye = () => {
  const {
    setRestaurantID,
    cities,
    town,
    neigh,
    foodCategory,
    formData,
    setFormData,
    postResetPassword,
    postRestaurantBlock,
    getOfficeuserDetail,
    restOfficialData,
    patchOfficeuserDetail,
    setDealarSidebarPage,
    updatePlatformsWorkstating,
    complatedApplicationStatu,
    getTrackerNotes,
    trackerNotes,
    postTrackerNotes,
    patchRestaurantTransfer,
    getApplicationTransferList,
    restofficialPlatforms,
    getRestofficialPlatforms,
    restaurantEnduserAppListFunc,
    restaurantEnduserAppList,
    sepetAppDemo,
    courierTrackerDemoStatusListFunc,
    courierTrackerStatusList,
    courierTrackerDemoChangeFunc,
    courierTrackerDemoDeleteFunc,
    testOrderFunc,
    getOfficeUserListWithRoleCollector,
    backofficeCollectorList,
    sepetAppDemoDetailFunc,
    sepetAppDemoDetail,
    sepetAppDemoDelete,
    setNewPasswordFunc,
  } = useContext(RestaurantContext);

  let { id } = useParams();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [openModal, setOpenModal] = useState(false);
  const [openOnlineStatusModal, setOpenOnlineStatusModal] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [electronStatus, setElectronStatus] = useState([]);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [openCustomerStatusModal, setOpenCustomerStatusModal] = useState(false);
  const [error, setError] = useState(false);
  const [checkDemo, setCheckDemo] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [courierDemoPhone, setCourierDemoPhone] = useState("");
  const [sepetappDemoPhone, setSepetAppDemoPhone] = useState("");
  const [callerPlusDemoPhone, setCallerPlusDemoPhone] = useState("");
  const { alert } = useToast();
  const [showChangeCollectorModal, setShowChangeCollectorModal] =
    useState(false);
  const { smsSettingsList, getSmsSettings } = useContext(ManagementContext);
  const [techSupport, setTechSupport] = useState(false);

  const handleUserList = () => {
    getApplicationTransferList();
    setOpenModal(true);
  };

  useEffect(() => {
    setRestaurantID(id);
    setDealarSidebarPage(0);
    getOfficeuserDetail(id);
    sepetAppDemoDetailFunc({ rest_id: id });
    courierTrackerDemoStatusListFunc({ user_id: id });
  }, [id]);

  useEffect(() => {
    getSmsSettings();

    getOfficeUserListWithRoleCollector({
      role: "collector",
      task_types: "",
    });
  }, []);

  const updateData = () => {
    const selectedCity = cities.find((item) => item.id === formData.city);
    const selectedTown = town.find((item) => item.id === formData.town);
    const selectNeighborhod = neigh.find(
      (item) => item.id === formData.neighborhood
    );
    const selectFoodCategory = foodCategory.find(
      (item) => item.id === formData.category
    );
    let newData = { ...restOfficialData, ...formData };

    newData.user.username = formData.email;
    newData.city = selectedCity;
    newData.town = selectedTown;
    newData.neighborhood = selectNeighborhod;
    newData.category = selectFoodCategory;
    patchOfficeuserDetail(id, newData);
  };

  const complatedSetup = () => {
    complatedApplicationStatu(id, "setup_completed");
  };

  const onchangeStateWorking = (platformID) => {
    let newPlatforms = formData.platforms;
    const idx = newPlatforms.findIndex((itm) => itm.id == platformID);
    if (newPlatforms[idx].state_working === "open") {
      newPlatforms[idx].state_working = "closed";
    } else {
      newPlatforms[idx].state_working = "open";
    }
    updatePlatformsWorkstating(restOfficialData?.user?.id, {
      secret_key_id: newPlatforms[idx]?.id,
      state_working: newPlatforms[idx]?.state_working,
    });
  };

  const resetPassword = () => {
    postResetPassword(restOfficialData?.user?.id);
  };

  const restaurantBlock = () => {
    postRestaurantBlock(id, restOfficialData?.user?.id, {
      blocked: !restOfficialData?.blocked,
    });
  };

  const restaurantLimitedUse = () => {
    patchOfficeuserDetail(id, {
      limited_use: !restOfficialData?.limited_use,
    });
  };

  const handleCheckCustomer = async () => {
    let data = {
      restofficial_id: restOfficialData?.user?.id,
      search: customerName,
    };
    if (customerName.length > 2) {
      const res = await restaurantService.search_by_customer(data);
      if (res.length === 0) {
        setError(true);
      } else {
        setOpenCustomerStatusModal(true);
        setCustomerName("");
        setCustomerOrders(res);
        setError(false);
      }
    }
  };

  const handleCheckStatus = async () => {
    setOpenOnlineStatusModal(true);
    const res = await restaurantService.restaurant_online_history(
      restOfficialData?.user?.id
    );
    setElectronStatus(res);

    getRestofficialPlatforms(restOfficialData?.user?.id);
  };

  const handleShowProductModal = () => {
    if (restOfficialData?.plan_expired_date?.length > 0) {
      setShowProductModal(true);
    } else {
      alert("Aktif bir teklif yok");
    }
  };

  const handleChangeCollector = () => {
    getApplicationTransferList();
    setShowChangeCollectorModal(true);
  };

  const handleOpenTechSupport = () => {
    setTechSupport(true);
  };

  useEffect(() => {
    if (restOfficialData?.user?.id) {
      restaurantEnduserAppListFunc(restOfficialData?.user?.id);
    }
  }, []);

  useEffect(() => {
    setCheckDemo(restaurantEnduserAppList?.length > 0 ? true : false);
  }, [restaurantEnduserAppList]);

  useEffect(() => {
    if (courierTrackerStatusList !== null) {
      if (courierTrackerStatusList?.phone !== null) {
        setCourierDemoPhone(courierTrackerStatusList?.phone);
      }
    }
  }, [courierTrackerStatusList]);

  return (
    <div style={styles.container}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: matches ? "row" : "column-reverse",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: matches ? "70%" : "98%" }}>
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: matches ? "row" : "column-reverse",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <h2>Tabelalar </h2>

                <h2
                  style={{
                    marginLeft: 10,
                    textTransform: "capitalize",
                    color:
                      restOfficialData?.payment_status == "ödendi"
                        ? "green"
                        : "gray",
                  }}
                >
                  {" "}
                  {"-"} {restOfficialData?.payment_status}
                </h2>

                <div
                  style={{
                    marginLeft: 10,
                    textTransform: "capitalize",
                    color: "gray",
                  }}
                >
                  {restOfficialData?.offers.map((offer, i) => {
                    if (offer.active) {
                      if (offer.extended_free_days_start) {
                        return (
                          <div key={i}>
                            {"- (" +
                              moment(new Date(offer.extended_free_days_start))
                                .add(offer.extended_free_days, "days")
                                .format("DD/MM/YYYY HH:mm:ss") +
                              ")"}
                          </div>
                        );
                      } else {
                        return (
                          <div key={i}>
                            {"- (" +
                              moment(new Date(offer.created_at))
                                .add(offer.trial_days, "days")
                                .format("DD/MM/YYYY HH:mm:ss") +
                              ")"}
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>
              <OfficeUserList
                openModal={openModal}
                setOpenModal={setOpenModal}
                selectedItem={restOfficialData?.user?.id}
                patchAction={patchRestaurantTransfer}
              />
              <OnlineStatusModal
                openOnlineStatusModal={openOnlineStatusModal}
                setOpenOnlineStatusModal={setOpenOnlineStatusModal}
                electronStatus={electronStatus}
                restofficialPlatforms={restofficialPlatforms}
              />
              <CustomerOrders
                openCustomerStatusModal={openCustomerStatusModal}
                setOpenCustomerStatusModal={setOpenCustomerStatusModal}
                orders={customerOrders}
              />
              <ChangeCollectorModal
                showChangeCollectorModal={showChangeCollectorModal}
                setShowChangeCollectorModal={setShowChangeCollectorModal}
                userId={restOfficialData?.user?.id}
                backofficeCollectorList={backofficeCollectorList}
                fromUser={restOfficialData?.origin_collector}
              />
              <SpeedDialActions
                handleUserList={handleUserList}
                resetPassword={resetPassword}
                restaurantBlock={restaurantBlock}
                restOfficialData={restOfficialData}
                getTrackerNotes={getTrackerNotes}
                trackerNotes={trackerNotes}
                postTrackerNotes={postTrackerNotes}
                smsSettingsList={smsSettingsList}
                testOrderFunc={testOrderFunc}
                restaurantLimitedUse={restaurantLimitedUse}
                setNewPasswordFunc={setNewPasswordFunc}
              />{" "}
              <img
                src={destekImg}
                style={{ width: 45, height: 45, cursor: "pointer" }}
                onClick={handleOpenTechSupport}
              />
            </div>
            {formData.platforms?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  width: "94%",
                  paddingBottom: "20px",
                  overflowX: "auto",
                  flexDirection: "row",
                }}
              >
                {formData.platforms?.map((item, i) => {
                  let logo = "";
                  if (item.platform.name == "Trendyol") {
                    logo = Trendyol;
                  } else if (item.platform.name == "Getir") {
                    logo = Getir;
                  } else if (item.platform.name == "Gofody") {
                    logo = Gofody;
                  } else if (item.platform.name == "Yemeksepeti") {
                    logo = Yemeksepeti;
                  } else if (item.platform.name == "Migros") {
                    logo = Migros;
                  }
                  return (
                    <div
                      key={i}
                      style={{
                        width: matches ? "320px" : "90%",
                        //height: "220px",
                        backgroundColor: "#fff",
                        borderRadius: "15px",
                        marginTop: "24px",
                        marginLeft: "10px",
                        padding: "10px 6px",
                      }}
                    >
                      <div style={styles.item}>
                        <img
                          style={{ width: 80, heigth: 50 }}
                          src={logo}
                          alt="logo"
                        />
                        {item?.restaurant_name?.length > 14 ? (
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontSize: 14,
                                  fontFamily: "segoeuiRg",
                                  padding: 1,
                                  bgcolor: "#082657",
                                  "& .MuiTooltip-arrow": {
                                    color: "#082657",
                                  },
                                },
                              },
                            }}
                            title={item?.restaurant_name}
                            arrow
                            placement="top"
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: 100,
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "segoeuiRg",
                                  cursor: "pointer",
                                  ...styles.boxText,
                                  marginLeft: 6,
                                  fontWeight: 600,
                                }}
                              >
                                {item?.restaurant_name}
                              </span>
                            </div>
                          </Tooltip>
                        ) : (
                          <span
                            style={{
                              fontFamily: "segoeuiRg",
                              cursor: "pointer",
                              ...styles.boxText,
                              marginLeft: 6,
                              fontWeight: 600,
                            }}
                          >
                            {item?.restaurant_name}
                          </span>
                        )}

                        <CustomerSwitch
                          id={item.id}
                          name=""
                          checked={item.state_working === "open" ? true : false}
                          onChange={() => {
                            onchangeStateWorking(item.id);
                          }}
                          //inputProps={{ "aria-label": "controlled" }}

                          size="large"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  ...styles.emptyContent,
                  width: matches ? "70%" : "90%",
                }}
              >
                Kayıtlı Tabela bulunamadı
              </div>
            )}
          </div>
          <h2>
            Restoran Bilgileri{" "}
            <span style={{ fontSize: 16, color: "gray" }}>
              (version : {restOfficialData?.version})
            </span>
          </h2>

          <RestaurantInfo formData={formData} setFormData={setFormData} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: matches ? "40px" : "0px",
              marginBottom: matches ? "10px" : "30px",
              paddingBottom: matches ? "10px" : "20px",
              flexDirection: matches ? "row" : "column",
              justifyContent:
                restOfficialData?.status == "setup"
                  ? "space-between"
                  : "center",
            }}
          >
            {restOfficialData?.status == "setup" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: matches ? "0px" : "12px",
                }}
              >
                {" "}
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    fontFamily: "segoeuiB",
                    fontWeight: 700,
                    backgroundColor: "#0FBD20",
                  }}
                  onClick={() => {
                    complatedSetup();
                  }}
                >
                  Kurulumu tamamla
                </Button>
              </div>
            )}
            <div
              style={{
                alignItems: "center",
                justifyContent: matches ? "flex-end" : "center",
              }}
            >
              {" "}
              <Button
                variant="contained"
                size="large"
                style={{
                  fontFamily: "segoeuiB",
                  width: matches ? 180 : "100%",
                  fontWeight: 700,
                }}
                onClick={() => {
                  updateData();
                }}
              >
                Güncelle
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            width: matches ? "26%" : "98%",
            height: "auto",
            flexDirection: "column",
          }}
        >
          {restOfficialData?.company?.available_credit && (
            <Paper
              elevation={2}
              style={{
                padding: "2px 10px",
                textAlign: "center",
                width: "100%",
                fontFamily: "cursive",
                marginBottom: 2,
              }}
            >
              Sepetfast Kontor: {restOfficialData?.company?.available_credit}
            </Paper>
          )}

          <Paper
            elevation={2}
            style={{
              padding: "2px 10px",
              textAlign: "center",
              width: "100%",
              fontFamily: "cursive",
              marginBottom: 2,
            }}
          >
            Kurulum: {restOfficialData?.processed_by?.full_name}
          </Paper>

          <Paper
            elevation={2}
            style={{
              padding: "2px 10px",
              textAlign: "center",
              width: "100%",
              fontFamily: "cursive",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Tahsilat: {restOfficialData?.origin_collector?.full_name}
            {JSON.parse(localStorage.getItem("st-officeuser-info")).user_id ===
              111 && (
              <RedoIcon
                onClick={handleChangeCollector}
                style={{
                  color: "rgb(245, 63, 98)",
                  cursor: "pointer",
                  marginLeft: 5,
                }}
              />
            )}
          </Paper>

          <Paper
            style={{
              marginTop: 10,
              boxShadow: "rgba(71, 10, 22, 0.07) 5px 9px 8px 0px",
              background: "#fff",
              width: "100%",
              marginBottom: 10,
              textAlign: "center",
              padding: "2px 10px",
            }}
          >
            <h4
              style={{
                color: "rgb(245, 63, 98)",
                fontFamily: "segoeuiRg",
                cursor: "pointer",
              }}
              onClick={handleShowProductModal}
            >
              Ürünlerin Sözleşme Başlangıç-Bitiş Tarihleri
            </h4>
            <div
              style={{
                width: "96%",
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "segoeuiRg",
                  fontSize: 18,
                }}
              >
                {moment(new Date(restOfficialData?.created_at)).format(
                  "DD/MM/YYYY"
                )}{" "}
              </span>
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                Alınan Ön Ödeme: {restOfficialData?.offers[0]?.eft_amount}
                <br />
                Kalan Bakiye:{" "}
                {restOfficialData?.offers?.length !== 0
                  ? restOfficialData?.offers[0]?.payed
                    ? 0
                    : restOfficialData?.offers[0]?.total_amount -
                      restOfficialData?.offers[0]?.eft_amount
                  : "Teklif Yok"}
              </span>
            </div>
            <ProductExpiredModal
              showProductModal={showProductModal}
              setShowProductModal={setShowProductModal}
              dates={restOfficialData?.plan_expired_date}
            />
          </Paper>

          <div
            style={{
              ...styles.rightItemContent,
              backgroundColor:
                moment()
                  .subtract(48, "hours")
                  .diff(restOfficialData?.online_date) > 0
                  ? "#ffa091"
                  : "#fff",
            }}
          >
            <div
              style={{
                width: "96%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 8,
                marginBottom: 8,
                minHeight: 40,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                onClick={handleCheckStatus}
              >
                <AccessTimeIcon />
              </Box>

              <div
                style={{
                  width: "25%",
                  height: "80%",
                  backgroundColor: "black",
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 999,
                      backgroundColor: restOfficialData?.online
                        ? "green"
                        : "red",
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 13,
                      color: "white",
                    }}
                  >
                    {restOfficialData?.online ? "Açık" : "Kapalı"}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "96%",
                flexDrection: "row",
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  fontFamily: "segoeuiRg",
                  marginTop: 10,
                }}
              >
                Son Aktiflik
              </span>
              {" :  "}
              <span style={styles.rightItemTxt}>
                {restOfficialData?.online_date !== null
                  ? moment(new Date(restOfficialData?.online_date)).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                  : "Giriş yapılmadı"}{" "}
              </span>{" "}
            </div>
            <div
              style={{
                width: "96%",
                flexDrection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <span style={{ fontWeight: 700, fontFamily: "segoeuiRg" }}>
                Pasif Gün
              </span>
              {" :  "}
              <span style={styles.rightItemTxt}>
                {restOfficialData?.online_date != null
                  ? moment(restOfficialData?.online_date).fromNow()
                  : "-"}
              </span>{" "}
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "relative",
              }}
            >
              <InputBase
                variant="filled"
                type="text"
                placeholder="Müşteri İsim"
                style={{
                  width: 200,
                  color: "black",
                  fontSize: 15,
                  fontWeight: "400",
                  fontFamily: "segoeuiRg",
                  height: 40,
                  backgroundColor: "#f4f4f4",
                  paddingLeft: 10,
                }}
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                }}
              />
              <SearchIcon
                style={{ cursor: "pointer", position: "absolute", right: 10 }}
                onClick={handleCheckCustomer}
              />
              {error && (
                <span
                  style={{
                    color: "red",
                    fontSize: 11,
                    position: "absolute",
                    bottom: -15,
                    fontFamily: "segoeuiRg",
                    fontWeight: 500,
                  }}
                >
                  Müşteri bulunamadı
                </span>
              )}
            </Box>
          </div>

          <div
            style={{
              width: "100%",
              height: "270px",
              borderRadius: 8,
              display: "flex",
              flexDirection: "column",
              marginBottom: 16,
              padding: "6px 10px",
              boxShadow: "5px 9px 8px 0 rgba(71, 10, 22, 0.07)",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontFamily: "segoeuiRg",
                    marginTop: 10,
                  }}
                >
                  SepetApp Demo
                </span>
                <div style={{ marginTop: 10, width: "95%" }}>
                  <PhoneInput
                    countryCodeEditable={false}
                    country="tr"
                    onlyCountries={["tr"]}
                    localization={{ tr: "Türkiye" }}
                    value={sepetappDemoPhone}
                    containerStyle={{ height: 35 }}
                    inputStyle={{
                      height: 35,
                      width: "100%",
                      fontSize: 15,
                      fontWeight: "400",
                    }}
                    onChange={(phone) => {
                      setSepetAppDemoPhone(phone);
                    }}
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <CustomerSwitch
                    checked={sepetAppDemoDetail?.sepetapp_demo}
                    onChange={() => {
                      if (sepetAppDemoDetail?.sepetapp_demo) {
                        let data = {
                          restofficial: restOfficialData?.user?.id,
                        };
                        sepetAppDemoDelete(data);
                      } else {
                        let data = {
                          restofficial: restOfficialData?.user?.id,
                          phone:
                            sepetappDemoPhone?.length !== 12
                              ? null
                              : sepetappDemoPhone,
                        };
                        sepetAppDemo(data);
                      }
                    }}
                    size="large"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontFamily: "segoeuiRg",
                    marginTop: 10,
                  }}
                >
                  KuryeTakip Demo
                </span>
                <div style={{ marginTop: 10, width: "95%" }}>
                  <PhoneInput
                    countryCodeEditable={false}
                    country="tr"
                    onlyCountries={["tr"]}
                    localization={{ tr: "Türkiye" }}
                    value={courierDemoPhone}
                    containerStyle={{ height: 35 }}
                    inputStyle={{
                      height: 35,
                      width: "100%",
                      fontSize: 15,
                      fontWeight: "400",
                    }}
                    onChange={(phone) => {
                      setCourierDemoPhone(phone);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CustomerSwitch
                    checked={courierTrackerStatusList?.demo}
                    disabled={
                      courierTrackerStatusList?.demo
                        ? false
                        : courierDemoPhone?.length !== 12
                        ? true
                        : false
                    }
                    onChange={() => {
                      if (courierTrackerStatusList?.demo) {
                        courierTrackerDemoDeleteFunc({ user_id: id });
                      } else {
                        let data = {
                          user_id: id,
                          sendObj: {
                            phone: courierDemoPhone,
                          },
                        };
                        courierTrackerDemoChangeFunc(data);
                      }
                    }}
                    size="large"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "80%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        fontFamily: "segoeuiRg",
                      }}
                    >
                      Kurye Girişi
                    </div>
                    <div
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: 99,
                        backgroundColor:
                          courierTrackerStatusList?.courier_loggedin
                            ? "green"
                            : "red",
                        marginTop: 4,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontFamily: "segoeuiRg",
                    marginTop: 10,
                  }}
                >
                  CallerPlus Demo
                </span>
                <div style={{ marginTop: 10, width: "95%" }}>
                  <PhoneInput
                    countryCodeEditable={false}
                    country="tr"
                    onlyCountries={["tr"]}
                    localization={{ tr: "Türkiye" }}
                    value={callerPlusDemoPhone}
                    containerStyle={{ height: 35 }}
                    inputStyle={{
                      height: 35,
                      width: "100%",
                      fontSize: 15,
                      fontWeight: "400",
                    }}
                    onChange={(phone) => {
                      setCallerPlusDemoPhone(phone);
                    }}
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <CustomerSwitch
                    checked={sepetAppDemoDetail?.callerplus_demo}
                    onChange={() => {
                      if (sepetAppDemoDetail?.callerplus_demo) {
                        let data = {
                          restofficial: restOfficialData?.user?.id,
                        };
                        sepetAppDemoDelete(data);
                      } else {
                        let data = {
                          restofficial: restOfficialData?.user?.id,
                          phone:
                            callerPlusDemoPhone?.length !== 12
                              ? null
                              : callerPlusDemoPhone,
                          app: "callerplus",
                        };
                        sepetAppDemo(data);
                      }
                    }}
                    size="large"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* {restOfficialData?.payment_history?.length > 0 && (
            <div
              style={{
                ...styles.rightItemContent,
              }}
            >
              {restOfficialData?.payment_history != "Teklif Bulunamadı" ? (
                restOfficialData?.payment_history?.length > 0 && (
                  <div
                    style={{
                      width: "96%",
                      flexDrection: "row",
                      marginBottom: 10,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 700,
                        fontFamily: "segoeuiRg",
                        marginTop: 10,
                      }}
                    >
                      {restOfficialData?.payment_history.length > 0 &&
                        dataTypeConverter(
                          restOfficialData?.payment_history[0]?.warning
                        )}
                    </span>
                    <span style={styles.rightItemTxt}>
                      {" "}
                      {moment(
                        new Date(
                          restOfficialData?.payment_history[0]?.expired_date
                        )
                      ).format("DD-MM-YYYY ")}{" "}
                    </span>{" "}
                  </div>
                )
              ) : (
                <div style={{ fontFamily: "segoeuiB", color: "red" }}>
                  {restOfficialData?.payment_history}
                </div>
              )}

              {restOfficialData?.payment_history != "Teklif Bulunamadı"
                ? restOfficialData?.payment_history?.length > 1 && (
                    <div
                      style={{
                        width: "96%",
                        flexDrection: "row",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 700,
                          fontFamily: "segoeuiRg",
                          marginTop: 10,
                        }}
                      >
                        {restOfficialData?.payment_history &&
                          dataTypeConverter(
                            restOfficialData?.payment_history[1]?.warning
                          )}
                      </span>
                      <span style={styles.rightItemTxt}>
                        {" "}
                        {moment(
                          new Date(
                            restOfficialData?.payment_history[1]?.expired_date
                          )
                        ).format("DD-MM-YYYY ")}{" "}
                      </span>{" "}
                    </div>
                  )
                : ""}
            </div>
          )} */}
        </div>
      </div>

      {techSupport && (
        <TechSupport
          techSupport={techSupport}
          setTechSupport={setTechSupport}
          id={id}
        />
      )}
    </div>
  );
};

const styles = {
  container: { margin: 32 },
  content: {
    height: "98%",
    width: "98%",
    borderRadius: 4,
    display: "flex",
    contain: "content",
    justifyContent: "space-between",
    paddingBottom: "20px",
  },
  boxStyle: {
    width: "85%",
    backgroundColor: "#f4f4f4",
    borderRadius: 2,
    color: "#000",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
  item: {
    backgroundColor: "#fff",
    height: "32px",
    width: "96%",
    marginTop: 12,
    marginBottom: 24,
    paddingLeft: 12,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  switch: {
    color: "#f53f62",
    width: "60px",
    height: "26px",
  },
  boxText: {
    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
  },
  userName: {
    width: 300,
    fontWeight: 900,
  },
  authorities: {
    width: "65%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 31,
    backgroundColor: "#ffffff",
    marginTop: 16,
  },
  official: {
    display: "flex",
    width: "100%",
    height: 63,
    borderRadius: 31,
    backgroundColor: "#ffffff",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  officialLabel: {
    //width: 74,
    width: "20%",
    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: "normal",
    //lineHeight: 24,
    textAlign: "left",
    paddingLeft: 10,
  },
  officialInput: {
    display: "justify",
    height: "52px",
    borderStyle: "none",
    borderWidth: 0,
    borderColor: "#f53f62",
    width: "30%",
    paddingLeft: 6,
    height: 27,
    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 18,
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: 24,
    textAlign: "left",
  },

  rightItemContent: {
    width: "100%",
    height: "170px",
    backgroundColor: "#fff",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
    padding: "6px 10px",
    //boxShadow: "4px 4px 8px",
    boxShadow: "5px 9px 8px 0 rgba(71, 10, 22, 0.07)",
  },
  rightItemTitle: {
    width: "90%",
    color: "#f53f62",
    fontFamily: "segoeuiRg",
    fontSize: 16,
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    textTransform: "capitalize",
  },
  rightItemTxt: {
    width: "90%",

    color: "#293c51",
    fontFamily: "segoeuiRg",
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    textAlign: "center",
  },
  updateOfferTitle: {
    //width: 156,
    // height: 27,
    color: "#f53f62",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: "normal",
    //lineHeight: 24,
    textAlign: "center",
  },
  updateOfferTxt: {
    // width: 216,
    //height: 27,
    color: "#f53f62",
    fontFamily: "segoeuiRg",
    fontSize: 20,
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "normal",
    // lineHeight: 24,
    textAlign: "center",
  },
  emptyContent: {
    display: "flex",
    minHeight: "90px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    marginTop: "24px",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    fontFamily: "segoeuiRg",
  },
};
export default Kunye;
