import { Delete } from "@mui/icons-material";
import { Paper } from "@mui/material";
import React from "react";
import { CustomerInput } from "../../../../../components/CustomerInput";

const Ysv1 = ({
  secretKeyPlatformInfo,
  scrollRefs,
  deleteRestofficialPlatforms,
  id,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {secretKeyPlatformInfo?.map((row, index) => {
        if (row?.yemeksepetiv2) return;
        return (
          <Paper
            key={index}
            ref={scrollRefs.current[index]}
            elevation={2}
            style={{ width: "50%", height: "auto", padding: 10, marginTop: 20 }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "#293c51",
                  fontFamily: "segoeuiRg",
                  fontSize: 18,
                  fontWeight: 700,
                  marginBottom: 5,
                }}
              >
                {row?.restaurant_name}
              </div>
              <Delete
                variant="Filled"
                size="large"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  deleteRestofficialPlatforms(id, row.id, row?.platform?.id)
                }
              />
            </div>
            <div style={{ marginTop: 5 }}>
              <CustomerInput
                fieldHeader="Kategori ID"
                fieldName="platform_user_id"
                formData={""}
                setFormData={""}
                fieldValue={row?.platform_user_id}
                disabledStatus={true}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <CustomerInput
                fieldHeader="Kullanıcı Adı"
                fieldName="secret_key"
                formData={""}
                setFormData={""}
                fieldValue={row?.secret_key}
                disabledStatus={true}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <CustomerInput
                fieldHeader="Şifre"
                fieldName="token"
                formData={""}
                setFormData={""}
                fieldValue={row?.token}
                disabledStatus={true}
              />
            </div>
          </Paper>
        );
      })}
    </div>
  );
};

export default Ysv1;
