import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  InputBase,
  Paper,
} from "@mui/material";

import { ManagementContext } from "../../../../contexts/managementContext";
import SendSettingsModal from "../modals/sendSettings";
import {
  AddPhotoAlternate,
  DescriptionTwoTone,
  ImageSearch,
  Save,
} from "@mui/icons-material";
import { forwardList } from "../../../../utils/constants/arrays";
import { offerCount } from "../../../../utils/constants/index";
import ContentNoteModal from "../modals/contentNote";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";

import Select from "react-select";
import { EditorState, convertToRaw } from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import CommunicationModal from "../modals/communications";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { makeStyles } from "@mui/styles";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
  cart: {
    "&.active": {
      backgroundColor: "#f53f62",
    },
  },
});

const BannerSettings = () => {
  const classes = useStyles();
  const {
    createBannerTemplateFunc,
    bannerTemplateListFunc,
    bannerTemplateList,
    platformAndProductsFunc,
    platformAndProducts,
    buttonActionsListFunc,
    buttonActionsList,
    deleteBannerTemplateFunc,
    bannerListFunc,
    bannerList,
    bannerChangeFunc,
  } = useContext(ManagementContext);
  const [bannerHeader, setBannerHeader] = useState("");
  const [bannerContent, setBannerContent] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [bannerType, setBannerType] = useState(null);
  const [selectedAppProduct, setSelectedAppProduct] = useState(null);
  const [selectedAppProductName, setSelectedAppProductName] = useState(null);
  const [selectedForwardPage, setSelectedForwardPage] = useState(null);
  const [sendSettingsModal, setSendSettingsModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [image1, setImage1] = useState(null);
  const [sendingBannerImage, setSendingBannerImage] = useState(null);
  const imageRef1 = useRef();
  const [image2, setImage2] = useState(null);
  const [sendingBannerImage2, setSendingBannerImage2] = useState(null);
  const imageRef2 = useRef();
  const [selectedButtonActions, setSelectedButtonActions] = useState(null);
  const [contentNoteModal, setContentNoteModal] = useState(false);
  const [selectedShowTime, setSelectedShowTime] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [blacklistOnClick, setBlackListOnClick] = useState(false);
  const [communicationModal, setCommunicationModal] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    bannerTemplateListFunc();
    platformAndProductsFunc();
    buttonActionsListFunc();
  }, []);

  useEffect(() => {
    bannerListFunc({ page: page + 1 });
  }, [page]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);

    setBannerContent(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  const onImageChange1 = (e) => {
    let valueData = e.target ? e.target.files[0] : e;

    setSendingBannerImage(valueData);

    setImage1(URL.createObjectURL(e.target.files[0]));
  };

  const onImageChange2 = (e) => {
    let valueData = e.target ? e.target.files[0] : e;

    setSendingBannerImage2(valueData);

    setImage2(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "50%",
            height: "auto",
            display: "flex",
            alignItems: "center",
            minHeight: 700,
          }}
        >
          <Paper
            elevation={1}
            style={{
              backgroundColor: "#fff",
              width: "90%",
              height: 700,
              display: "flex",
              flexDirection: "column",
              padding: 20,
              overflow: "auto",
            }}
          >
            <div>
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  backgroundColor: "#f4f4f4",
                  borderRadius: 4,
                }}
                rows={1}
                placeholder={"Banner İsmi"}
                value={bannerHeader}
                onChange={(e) => {
                  setBannerHeader(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="file"
                multiple
                accept="image/*"
                style={{ display: "none" }}
                onChange={onImageChange1}
                ref={imageRef1}
              />
              {image1 != null ? (
                <img
                  src={image1}
                  style={{
                    marginTop: 15,
                    cursor: "pointer",
                    width: "100%",
                    height: 200,
                  }}
                  onClick={() => imageRef1.current.click()}
                />
              ) : (
                <div
                  style={{
                    marginTop: 15,
                    width: 120,
                    height: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddPhotoAlternate
                    style={{
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                    }}
                    onClick={() => imageRef1.current.click()}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="file"
                multiple
                accept="image/*"
                style={{ display: "none" }}
                onChange={onImageChange2}
                ref={imageRef2}
              />
              {image2 != null ? (
                <img
                  src={image2}
                  style={{
                    marginTop: 15,
                    cursor: "pointer",
                    width: "100%",
                    height: 200,
                  }}
                  onClick={() => imageRef2.current.click()}
                />
              ) : (
                <div
                  style={{
                    marginTop: 15,
                    width: 120,
                    height: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddPhotoAlternate
                    style={{
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                    }}
                    onClick={() => imageRef2.current.click()}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: 20 }}>
              <div style={{ backgroundColor: "#f4f4f4", minHeight: 200 }}>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "fontFamily",
                      "fontSize",
                      "colorPicker",
                      "emoji",
                      "list",
                      "inline",
                      "link",
                    ],
                    inline: {
                      options: [
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "monospace",
                      ],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                    link: {
                      options: ["link"],
                    },
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Paper
                elevation={1}
                style={{
                  padding: 5,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    margin: 5,
                    fontFamily: "segoeuiRg",
                    color: "gray",
                    fontSize: 14,
                  }}
                >
                  Ek Banner Ayarları
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 50,
                      boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                      borderRadius: 4,
                      backgroundColor: "whitesmoke",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      textAlign: "center",
                      padding: "20px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Select
                        responsiveHeight={30}
                        selectFontSize={16}
                        options={offerCount}
                        styles={selectDefaultStyles}
                        isSearchable={false}
                        zIndex={90}
                        hoverColor={"#f53b62"}
                        menuColor={"#f53b62"}
                        selectWidth={150}
                        placeholder={"Sıralama"}
                        value={selectedPriority}
                        getOptionValue={(option) => option.value}
                        onChange={(e) => {
                          setSelectedPriority(e);
                        }}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {e.label}
                          </div>
                        )}
                      />
                      <Select
                        responsiveHeight={30}
                        selectFontSize={16}
                        options={offerCount}
                        styles={selectDefaultStyles}
                        isSearchable={false}
                        zIndex={90}
                        hoverColor={"#f53b62"}
                        menuColor={"#f53b62"}
                        selectWidth={150}
                        placeholder={"Gösterim (sn)"}
                        value={selectedShowTime}
                        getOptionValue={(option) => option.value}
                        onChange={(e) => {
                          setSelectedShowTime(e);
                        }}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {e.label}
                          </div>
                        )}
                      />
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Tıklandığında kapansın
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setBlackListOnClick(!blacklistOnClick);
                            }}
                            checked={blacklistOnClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Paper
                elevation={1}
                style={{
                  padding: 5,
                  width: "25%",
                }}
              >
                <div
                  style={{
                    margin: 5,
                    fontFamily: "segoeuiRg",
                    color: "gray",
                    fontSize: 14,
                  }}
                >
                  Banner türü
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 100,
                      boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                      borderRadius: 4,
                      backgroundColor: "whitesmoke",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      textAlign: "center",
                      padding: "20px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Başvuru
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setBannerType("application");
                            }}
                            checked={
                              bannerType === "application" ? true : false
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Yönlendirme
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setBannerType("forward");
                            }}
                            checked={bannerType === "forward" ? true : false}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Referans
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setBannerType("ref");
                            }}
                            checked={bannerType === "ref" ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Tıklanabilir
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setBannerType("clickable");
                            }}
                            checked={bannerType === "clickable" ? true : false}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Tıklanamaz
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setBannerType("non_clickable");
                            }}
                            checked={
                              bannerType === "non_clickable" ? true : false
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "segoeuiRg",
                            fontSize: 14,
                          }}
                        >
                          Duyuru
                        </div>
                        <div>
                          <GreenSwitch
                            onClick={() => {
                              setBannerType("announcement");
                            }}
                            checked={
                              bannerType === "announcement" ? true : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>

              {bannerType === "application" ? (
                <Paper elevation={1} style={{ padding: 5, width: "75%" }}>
                  <div
                    style={{
                      margin: 5,
                      fontFamily: "segoeuiRg",
                      color: "gray",
                      fontSize: 14,
                    }}
                  >
                    Banner ürün veya platform
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 100,
                        boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                        borderRadius: 4,
                        backgroundColor: "whitesmoke",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {platformAndProducts?.package_products?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedAppProduct?.id === row?.id) {
                                      setSelectedAppProduct(null);
                                      setSelectedAppProductName(null);
                                    } else {
                                      setSelectedAppProductName(row?.name);
                                      setSelectedAppProduct({
                                        id: row?.id,
                                        type: "product",
                                      });
                                    }
                                  }}
                                  checked={
                                    selectedAppProductName === row?.name
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {platformAndProducts?.platforms?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedAppProduct?.id === row?.id) {
                                      setSelectedAppProduct(null);
                                      setSelectedAppProductName(null);
                                    } else {
                                      setSelectedAppProductName(row?.name);
                                      setSelectedAppProduct({
                                        id: row?.id,
                                        type: "platform",
                                      });
                                    }
                                  }}
                                  checked={
                                    selectedAppProductName === row?.name
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Paper>
              ) : bannerType === "forward" ? (
                <Paper elevation={1} style={{ padding: 5, width: "75%" }}>
                  <div
                    style={{
                      margin: 5,
                      fontFamily: "segoeuiRg",
                      color: "gray",
                      fontSize: 14,
                    }}
                  >
                    Yönlendirelecek sayfa
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 100,
                        boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                        borderRadius: 4,
                        backgroundColor: "whitesmoke",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {forwardList?.map((row) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.name}
                              </div>
                              <div>
                                <GreenSwitch
                                  onClick={() => {
                                    if (selectedForwardPage === row?.id) {
                                      setSelectedForwardPage(null);
                                    } else {
                                      setSelectedForwardPage(row?.id);
                                    }
                                  }}
                                  checked={
                                    selectedForwardPage === row?.id
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Paper>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Paper elevation={1} style={{ padding: 5, width: "100%" }}>
                <div
                  style={{
                    margin: 5,
                    fontFamily: "segoeuiRg",
                    color: "gray",
                    fontSize: 14,
                  }}
                >
                  Button seçimi
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 100,
                      boxShadow: "5 9 8 0 rgba(71, 10, 22, 0.07)",
                      borderRadius: 4,
                      backgroundColor: "whitesmoke",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      textAlign: "center",
                      padding: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      {buttonActionsList?.map((row) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 14,
                              }}
                            >
                              {row?.name}
                            </div>
                            <div>
                              <GreenSwitch
                                onClick={() => {
                                  const selectedButtonActionsObj =
                                    Object.assign(
                                      [],
                                      JSON.parse(
                                        JSON.stringify({
                                          ...selectedButtonActions,
                                        })
                                      )
                                    );
                                  let buttonActionsId =
                                    selectedButtonActionsObj.findIndex(
                                      (item) => item === row?.id
                                    );
                                  if (buttonActionsId == -1) {
                                    selectedButtonActionsObj.push(row?.id);
                                  } else {
                                    selectedButtonActionsObj.splice(
                                      buttonActionsId,
                                      1
                                    );
                                  }
                                  setSelectedButtonActions(
                                    selectedButtonActionsObj
                                  );
                                }}
                                checked={selectedButtonActions?.some((item) => {
                                  return item == row.id;
                                })}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: 10,
              }}
            >
              <Button
                style={{
                  backgroundColor: "green",
                  color: "#fff",
                  fontFamily: "segoeuiB",
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  height: 40,
                }}
                size="small"
                onClick={() => {
                  let data = {
                    sendObj: {
                      name: bannerHeader,
                      content: bannerContent,
                      banner_type: bannerType,
                      package_products:
                        selectedAppProduct?.type === "product"
                          ? [selectedAppProduct?.id]
                          : [],
                      banner_actions: selectedButtonActions
                        ?.map((row) => row)
                        .join(","),
                      platforms:
                        selectedAppProduct?.type === "platform"
                          ? [selectedAppProduct?.id]
                          : [],
                      custom_application:
                        selectedAppProduct === null ? bannerHeader : "",
                      forward_to:
                        selectedForwardPage !== null ? selectedForwardPage : "",
                      image: sendingBannerImage,
                      image2: sendingBannerImage2,
                      priority: selectedPriority,
                      show_time: selectedShowTime,
                      blacklist_on_click: blacklistOnClick,
                    },
                    setBannerHeader,
                    setBannerType,
                    setBannerContent,
                    setSelectedAppProduct,
                    setSelectedButtonActions,
                    setSelectedForwardPage,
                    setImage1,
                    setImage2,
                    setSendingBannerImage,
                    setEditorState,
                    setBlackListOnClick,
                    setSelectedPriority,
                    setSelectedShowTime,
                  };
                  createBannerTemplateFunc(data);
                }}
              >
                Oluştur
              </Button>
            </div>
          </Paper>
        </div>
        <div
          style={{
            width: "50%",
            height: "auto",
            display: "flex",
            alignItems: "center",
            minHeight: 700,
          }}
        >
          <Paper
            elevation={1}
            style={{
              backgroundColor: "#fff",
              width: "95%",
              height: 700,
              display: "flex",
              padding: 20,
              flexDirection: "column",
            }}
          >
            <div style={{ height: "50%", display: "flex", overflow: "auto" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Banner İsmi</TableCell>
                      <TableCell align="center">Görsel</TableCell>
                      <TableCell align="center">Sıralama</TableCell>
                      <TableCell align="center">Gösterim</TableCell>
                      <TableCell align="center">Buton ismi</TableCell>
                      <TableCell align="center">İçerik</TableCell>
                      <TableCell align="center">Banner Tipi</TableCell>
                      <TableCell align="center">Etki Alanı</TableCell>
                      <TableCell align="center">Aksiyon</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bannerTemplateList?.map((row, ind) => {
                      return (
                        <TableRow key={ind}>
                          <TableCell align="center">{row?.name}</TableCell>
                          <TableCell align="center">
                            {row?.image != null ? (
                              <ImageSearch
                                onClick={() =>
                                  window.open(`${row?.image}`, "_blank")
                                }
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <span>-</span>
                            )}
                          </TableCell>
                          <TableCell align="center">{row?.priority}</TableCell>
                          <TableCell align="center">{row?.show_time}</TableCell>
                          <TableCell align="center">
                            {row?.banner_actions?.map((row) => {
                              return (
                                <>
                                  {row?.name}
                                  <br />
                                </>
                              );
                            })}
                          </TableCell>
                          <TableCell align="center">
                            <DescriptionTwoTone
                              style={{
                                cursor: "pointer",
                                width: 24,
                                height: 24,
                              }}
                              onClick={() => {
                                setCurrentRow(row);
                                // setContentNoteModal(true);
                                setCommunicationModal(true);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row?.banner_type === "forward"
                              ? "Yönlendir"
                              : row?.banner_type === "application"
                              ? "Başvuru"
                              : row?.banner_type === "normal"
                              ? "Normal"
                              : row?.banner_type === "non_clickable"
                              ? "Tıklanamaz"
                              : row?.banner_type === "announcement"
                              ? "Duyuru"
                              : "Referans"}
                          </TableCell>
                          <TableCell align="center">
                            {row?.banner_type === "forward"
                              ? row?.forward_to
                              : row?.banner_type === "application"
                              ? row?.package_products?.length !== 0
                                ? row?.package_products[0]?.name
                                : row?.platforms[0]?.name
                              : "-"}
                          </TableCell>
                          <TableCell align="center" style={{ display: "flex" }}>
                            <Button
                              style={{
                                backgroundColor: "green",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                display: "flex",
                                justifyContent: "center",
                                height: 30,
                              }}
                              size="small"
                              onClick={() => {
                                setSendSettingsModal(true);
                                setCurrentRow(row);
                              }}
                            >
                              <Save />
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "red",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                display: "flex",
                                justifyContent: "center",
                                height: 30,
                              }}
                              size="small"
                              onClick={() => {
                                deleteBannerTemplateFunc({
                                  temp_id: row?.id,
                                });
                              }}
                            >
                              <Save />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                height: "50%",
                display: "flex",
                overflow: "auto",
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Banner İsmi</TableCell>
                      <TableCell align="center">Görsel</TableCell>
                      <TableCell align="center">Sıra</TableCell>
                      <TableCell align="center">Süre</TableCell>
                      <TableCell align="center">Banner Tipi</TableCell>
                      <TableCell align="center">Aksiyon</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bannerList?.results?.map((row, ind) => {
                      return (
                        <TableRow key={ind}>
                          <TableCell align="center">{row?.name}</TableCell>
                          <TableCell align="center">
                            {row?.image != null ? (
                              <ImageSearch
                                onClick={() =>
                                  window.open(`${row?.image}`, "_blank")
                                }
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <span>-</span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {row?.restofficial_count}
                          </TableCell>
                          <TableCell align="center">
                            {row?.active_time}
                          </TableCell>
                          <TableCell align="center">
                            {row?.banner_type === "forward"
                              ? "Yönlendir"
                              : row?.banner_type === "application"
                              ? "Başvuru"
                              : row?.banner_type === "normal"
                              ? "Normal"
                              : row?.banner_type === "non_clickable"
                              ? "Tıklanamaz"
                              : row?.banner_type === "announcement"
                              ? "Duyuru"
                              : "Referans"}
                          </TableCell>

                          <TableCell align="center" style={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: row?.is_active
                                    ? "green"
                                    : "gray",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 30,
                                }}
                                size="small"
                                onClick={() => {
                                  bannerChangeFunc({
                                    banner_id: row?.id,
                                    sendObj: {
                                      is_active: !row?.is_active,
                                    },
                                    page: page + 1,
                                  });
                                }}
                              >
                                {row?.is_active ? "Canlıda" : "Yolla"}
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      {bannerList?.results && (
                        <TablePagination
                          rowsPerPageOptions={[5]}
                          labelRowsPerPage={"Sayfa Başına Kayıt"}
                          count={bannerList?.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          classes={{ root: classes.root }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </div>
      </div>
      <SendSettingsModal
        sendSettingsModal={sendSettingsModal}
        setSendSettingsModal={setSendSettingsModal}
        platformAndProducts={platformAndProducts}
        currentRow={currentRow}
        type="banner"
      />
      <ContentNoteModal
        contentNoteModal={contentNoteModal}
        setContentNoteModal={setContentNoteModal}
        currentRow={currentRow}
      />
      <CommunicationModal
        communicationModal={communicationModal}
        setCommunicationModal={setCommunicationModal}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        setEditorState={setEditorState}
      />
    </>
  );
};

export default BannerSettings;
