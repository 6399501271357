import { Registerer, SessionState, UserAgent } from "sip.js";

import inbound from "./inbound";
import callService from "../../../services/callService";

const uri = UserAgent.makeURI(
  `sip:${
    JSON.parse(localStorage.getItem("st-officeuser-info"))?.iqcell_username
  }@gofody.tt360.live:8443`
);

export let userAgent = "";
export let X_UUID_INVITATION;

const callAuth = (
  enqueueSnackbar,
  closeSnackbar,
  cdrHangupFunc,
  cdrAnswerFunc,
  callkey,
  cdrMissedCountFunc,
  history
) => {
  const audioElement = document.getElementById("remoteAudio");

  function preferG711a(sdp) {
    const sdpLines = sdp.split("\r\n");
    let mLineIndex = -1;

    // m=audio satırını bul
    for (let i = 0; i < sdpLines.length; i++) {
      if (sdpLines[i].indexOf("m=audio") === 0) {
        mLineIndex = i;
        break;
      }
    }

    if (mLineIndex === -1) {
      return sdp;
    }

    // G.711a codec'i (PCMA) için payload type'ını bul
    let pcmaPayload;
    for (let i = 0; i < sdpLines.length; i++) {
      if (
        sdpLines[i].indexOf("a=rtpmap") === 0 &&
        sdpLines[i].indexOf("PCMA/8000") !== -1
      ) {
        pcmaPayload = sdpLines[i].split(" ")[0].split(":")[1];
        break;
      }
    }

    if (!pcmaPayload) {
      return sdp;
    }

    // G.711a codec'ini m=audio satırının başına taşı
    const mLineElements = sdpLines[mLineIndex].split(" ");
    const newMLine = [
      mLineElements[0],
      mLineElements[1],
      mLineElements[2],
      pcmaPayload,
    ];
    for (let i = 3; i < mLineElements.length; i++) {
      if (mLineElements[i] !== pcmaPayload) {
        newMLine.push(mLineElements[i]);
      }
    }
    sdpLines[mLineIndex] = newMLine.join(" ");

    return sdpLines.join("\r\n");
  }
  function playRingbackTone() {
    const audio = new Audio(
      "https://static.sepettakip.com/ringtone-santral.mp3"
    );
    audio.loop = true;
    audio.play().catch((error) => {
      console.error("Failed to play ringback tone:", error);
    });
    return audio;
  }

  function playCallEndedRingTone() {
    const audio = new Audio(
      "https://static.sepettakip.com/call_end_ringtone_santral.mp3"
    );
    audio.play().catch((error) => {
      console.error("Failed to play ringback tone:", error);
    });
    return audio;
  }

  const userAgentOptions = {
    authorizationPassword: JSON.parse(
      localStorage.getItem("st-officeuser-info")
    )?.iqcell_password,
    authorizationUsername: JSON.parse(
      localStorage.getItem("st-officeuser-info")
    )?.iqcell_username,
    transportOptions: { server: "wss://gofody.tt360.live:8443" },
    uri,
    logLevel: "error",
    modifySDP: (sdp) => {
      return preferG711a(sdp);
    },
    delegate: {
      onConnect: () => {
        console.log("Bağlantı kuruldu");
      },
      onInvite: (invitation) => {
        const ringbackTone = playRingbackTone();

        X_UUID_INVITATION =
          invitation.incomingInviteRequest.message.headers.Sessionuuid[0].raw;
        let data = {
          x_uuid:
            invitation.incomingInviteRequest.message.headers.Sessionuuid[0].raw,
          caller: invitation.incomingInviteRequest.message.from._displayName,
        };
        callService
          .cdr_inbound(data)
          .then((response) => {
            let callData = {
              phoneNumber:
                invitation.incomingInviteRequest.message.from._displayName,
              restofficial_id: response?.restofficial_id,
              restofficial_name: response?.restofficial_name,
            };
            inbound({
              enqueueSnackbar,
              closeSnackbar,
              callData,
              invitation,
              type: "ringing",
              cdrAnswerFunc,
              X_UUID_INVITATION,
              history,
              callkey,
            });

            // Handle incoming session state changes.
            invitation.stateChange.addListener((newState) => {
              switch (newState) {
                case SessionState.Establishing:
                  break;
                case SessionState.Established:
                  ringbackTone.pause();
                  const sessionDescriptionHandler =
                    invitation._sessionDescriptionHandler;
                  inbound({
                    enqueueSnackbar,
                    closeSnackbar,
                    callData,
                    invitation,
                    type: "accept",
                    cdrAnswerFunc,
                    X_UUID_INVITATION,
                    history,
                    callkey,
                  });
                  const remoteStream = new MediaStream();
                  sessionDescriptionHandler._peerConnection
                    .getReceivers()
                    .forEach((receiver) => {
                      if (receiver.track) {
                        remoteStream.addTrack(receiver.track);
                      }
                    });

                  audioElement.srcObject = remoteStream;
                  audioElement.play();

                  break;
                case SessionState.Terminated:
                  ringbackTone.pause();
                  playCallEndedRingTone();
                  let data = {
                    x_uuid: X_UUID_INVITATION,
                    who_hanged_up: "agent",
                  };
                  cdrHangupFunc(data);
                  cdrMissedCountFunc();
                  closeSnackbar(callkey);

                  break;
                default:
                  break;
              }
            });
          })
          .catch((err) => console.log("err", err));
      },
      onReject: (response) => {
        console.log("auth_reject:", response);
      },
    },
  };

  userAgent = new UserAgent(userAgentOptions);
  const registerer = new Registerer(userAgent);

  userAgent.start().then(() => {
    registerer.register({});
  });
};
export default callAuth;
