export const forwardList = [
  { id: "order", name: "Sepettakip" },
  { id: "sepetApp", name: "SepetApp" },
  { id: "callerplus", name: "CallerPlus" },
  { id: "kuryetakip", name: "KuryeTakip" },
  { id: "stoktakip", name: "StokTakip" },
  { id: "masatakip", name: "MasaTakip" },
  { id: "ürünler", name: "Ürünler" },
  { id: "toptancı", name: "Toptancı" },
];

export const remaining_day = [
  {
    value: 0,
    label: "0 Gün",
  },
  {
    value: 1,
    label: "1 Gün",
  },
  {
    value: 2,
    label: "2 Gün",
  },
  {
    value: 3,
    label: "3 Gün",
  },
  {
    value: 4,
    label: "4 Gün",
  },
  {
    value: 5,
    label: "5 Gün",
  },
  {
    value: 6,
    label: "6 Gün",
  },
  {
    value: 7,
    label: "7 Gün",
  },
  {
    value: 8,
    label: "8 Gün",
  },
  {
    value: 9,
    label: "9 Gün",
  },
  {
    value: 10,
    label: "10 Gün",
  },
  {
    value: 11,
    label: "11 Gün",
  },
  {
    value: 12,
    label: "12 Gün",
  },
  {
    value: 13,
    label: "13 Gün",
  },
  {
    value: 14,
    label: "14 Gün",
  },
  {
    value: 15,
    label: "15 Gün",
  },
  {
    value: 16,
    label: "16 Gün",
  },
  {
    value: 17,
    label: "17 Gün",
  },
  {
    value: 18,
    label: "18 Gün",
  },
  {
    value: 19,
    label: "19 Gün",
  },
  {
    value: 20,
    label: "20 Gün",
  },
  {
    value: 21,
    label: "21 Gün",
  },
  {
    value: 22,
    label: "22 Gün",
  },
  {
    value: 23,
    label: "23 Gün",
  },
  {
    value: 24,
    label: "24 Gün",
  },
  {
    value: 25,
    label: "25 Gün",
  },
  {
    value: 26,
    label: "26 Gün",
  },
  {
    value: 27,
    label: "27 Gün",
  },
  {
    value: 28,
    label: "28 Gün",
  },
  {
    value: 29,
    label: "29 Gün",
  },
  {
    value: 30,
    label: "30 Gün",
  },
];
