import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";

export default function ProductsModal({ openModal, setOpenModal, products }) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "40%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Ürünler
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "780px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Ürün</TableCell>
                  <TableCell align="center">Tahsilat</TableCell>
                  <TableCell align="center">Ortalama Tahsilat</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">{row?.name}</TableCell>
                      <TableCell align="center">
                        {row?.total_payed_count} ₺
                      </TableCell>
                      <TableCell align="center">{row?.avg_amount} ₺</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
