export const selectDefaultStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.selectProps.menuColor,
    borderRadius: 6,
    width: state.selectProps.selectWidth,
    color: state.selectProps.selectedColor
      ? state.selectProps.selectedColor
      : "#000",
    border: 0,
    cursor: "pointer",
    minHeight: state.selectProps.responsiveHeight,
    height: state.selectProps.responsiveHeight,
    alignContent: "center",
    boxShadow: "2px 3px 2.5px 1px #C5C5BB",
  }),
  container: (styles, state) => ({
    ...styles,
    width: state.selectProps.selectWidth,
    zIndex: state.selectProps.zIndex,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: state.selectProps.placeHolderColor
      ? state.selectProps.placeHolderColor
      : "#000",
    fontFamily: state.selectProps.darkMode ? "monospace" : "segoeuiRg",
    fontSize: state.selectProps.darkMode ? 16 : 18,
    fontWeight: 500,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#000",
    fontSize: 18,
    fontWeight: 500,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "#000",
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: "#000",
    fontFamily: state.selectProps.darkMode ? "monospace" : "segoeuiRg",
    fontSize: state.selectProps.darkMode ? 16 : 18,
    fontWeight: 500,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "baige",
      fontFamily: state.selectProps.darkMode ? "monospace" : "segoeuiRg",
      color: "black",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      fontSize: state.selectProps.selectFontSize,
      borderBottom: "1px solid gray",
      ":active": {
        ...styles[":active"],
        backgroundColor: "red",
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: state.selectProps.hoverColor,
        color: "#F4F4F4",
      },
    };
  },
};
