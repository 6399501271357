import { Box, Button, Modal, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { platformImageConverterBig } from "../../converter/platformImage";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { useParams } from "react-router-dom";

export default function ProductImportModal({
  openProductImportModal,
  setOpenProductImportModal,
  restOfficialData,
  platformList,
}) {
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [platformRes, setPlatformRes] = useState([]);
  const [selectedPlatformRes, setSelectedPlatformRes] = useState(null);
  const { productImport } = useContext(RestaurantContext);
  const { id } = useParams();

  useEffect(() => {
    if (selectedPlatform != null) {
      let res_test = [];

      restOfficialData?.platforms?.length !== 0 &&
        restOfficialData?.platforms
          ?.filter((info) => info.platform?.id === selectedPlatform?.id)
          ?.map((row, ind) => {
            res_test.push(row);
          });
      setPlatformRes(res_test);
    }
  }, [selectedPlatform]);

  const handleSaveProductImport = () => {
    const data = {
      secret_key: selectedPlatformRes?.id,
    };
    productImport(data, id);
    setOpenProductImportModal(false);
    setPlatformRes(null);
    setSelectedPlatform(null);
    setSelectedPlatformRes(null);
  };

  return (
    <Modal
      open={openProductImportModal}
      onClose={() => {
        setOpenProductImportModal(false);
        setPlatformRes(null);
        setSelectedPlatform(null);
        setSelectedPlatformRes(null);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, maxHeight: 550, overflow: "auto" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Menü Aktarma İşlemi
        </h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {platformList?.map((row, index) => {
            return (
              <div
                key={index}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedPlatform(row);
                }}
              >
                {row?.name === "Trendyol" && (
                  <img
                    alt="Trendyol"
                    style={{ width: 120, height: 70, marginRight: 20 }}
                    src={platformImageConverterBig(row?.name)}
                  />
                )}
                {row?.name === "Gofody" && (
                  <img
                    alt="Gofody"
                    style={{ width: 120, height: 70, marginRight: 20 }}
                    src={platformImageConverterBig(row?.name)}
                  />
                )}
                {row?.name === "Getir" && (
                  <img
                    alt="Getir"
                    style={{ width: 120, height: 70, marginRight: 20 }}
                    src={platformImageConverterBig(row?.name)}
                  />
                )}
                {row?.name === "Migros" && (
                  <img
                    alt="Migros"
                    style={{ width: 120, height: 70 }}
                    src={platformImageConverterBig(row?.name)}
                  />
                )}
              </div>
            );
          })}
        </div>

        {platformRes?.length > 0 && (
          <div
            style={{
              height: 65,
              display: "flex",
              minHeight: 65,
              alignItems: "center",
              overflowX: "auto",
              justifyContent: "center",
            }}
          >
            {platformRes?.map((row, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor:
                      selectedPlatformRes?.id == row?.id ? "green" : "#f53b62",
                    padding: "1px 6px",
                    fontSize: 13,
                    color: "white",
                    borderRadius: 4,
                    fontFamily: "segoeuiRg",
                    width: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    height: "80%",
                    flexDirection: "column",
                    marginLeft: 5,
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    setSelectedPlatformRes(row);
                  }}
                >
                  <span
                    style={{
                      fontFamily: "segoeuiRg",
                      cursor: "pointer",
                    }}
                  >
                    Tabela
                  </span>
                  {(row?.restaurant_name).length > 16 ? (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: 14,
                            fontFamily: "segoeuiRg",
                            padding: 1,
                            bgcolor: "#082657",
                            "& .MuiTooltip-arrow": {
                              color: "#082657",
                            },
                          },
                        },
                      }}
                      title={row?.restaurant_name}
                      arrow
                      placement="top"
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 100,
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "segoeuiRg",
                            cursor: "pointer",
                          }}
                        >
                          {row?.restaurant_name}
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <>{row?.restaurant_name}</>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              fontFamily: "segoeuiB",
              fontWeight: 700,
              backgroundColor: "#0FBD20",
              width: 200,
            }}
            onClick={handleSaveProductImport}
          >
            Kaydet
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#efefef",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    width: "70%",
  },
};
