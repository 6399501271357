import React from "react";
import { Card, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EftInfoCard from "./components/eftInfoCard";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    // padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
    height: "auto",
  },
}));

const EftInfoModal = ({
  eftInfoModal,
  setEftInfoModal,
  currentRow,
  setCurrentRow,
}) => {
  const classes = useStyles();
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={eftInfoModal}
      className={classes.modal}
      onClose={() => {
        setEftInfoModal(false);
        setCurrentRow(null);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          padding: 30,
        }}
      >
        <Paper
          elevation={2}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#f4f4f4",
          }}
        >
          <EftInfoCard
            title="Restoran İsmi"
            eftInfo={currentRow?.offer?.restofficial?.name}
          />
          <EftInfoCard
            title="Restoran Yetkili"
            eftInfo={
              currentRow?.offer?.restofficial?.first_name +
              " " +
              currentRow?.offer?.restofficial?.last_name
            }
          />
          <EftInfoCard
            title="Restoran Mobil"
            eftInfo={currentRow?.offer?.restofficial?.phone}
          />
          <EftInfoCard
            title="Restoran Sabit"
            eftInfo={currentRow?.offer?.restofficial?.constant_phone}
          />
        </Paper>
        <Paper
          elevation={2}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: 10,
            backgroundColor: "#f4f4f4",
          }}
        >
          <EftInfoCard
            title="Şirket Türü"
            eftInfo={currentRow?.offer?.restofficial?.company_type}
          />
           <EftInfoCard
            title="Vergi Dairesi"
            eftInfo={currentRow?.offer?.restofficial?.tax_authority}
          />
          <EftInfoCard
            title="Vergi Numarası"
            eftInfo={currentRow?.offer?.restofficial?.tax_no}
          />
          <EftInfoCard
            title="T.C. Kimlik No"
            eftInfo={currentRow?.offer?.restofficial?.identity_number}
          />
        </Paper>
        <Paper
          elevation={2}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: 10,
            backgroundColor: "#f4f4f4",
          }}
        >
          <EftInfoCard
            title="Ticari Ünvan"
            eftInfo={currentRow?.offer?.restofficial?.company_title}
          />
          <EftInfoCard
            title="Şirket İl"
            eftInfo={currentRow?.offer?.restofficial?.billing_city?.name}
          />
          <EftInfoCard
            title="Şirket İlçe"
            eftInfo={currentRow?.offer?.restofficial?.billing_town?.name}
          />
        </Paper>
        <Paper
          elevation={2}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: 10,
            backgroundColor: "#f4f4f4",
          }}
        >
          <EftInfoCard
            title="Şirket Mahallesi"
            eftInfo={
              currentRow?.offer?.restofficial?.billing_neighborhood?.name
            }
          />
          <EftInfoCard
            title="Şirket Email"
            eftInfo={currentRow?.offer?.restofficial?.billing_email}
          />
          <EftInfoCard
            title="Şirket Cep No"
            eftInfo={currentRow?.offer?.restofficial?.billing_phone}
          />
        </Paper>
        <Paper
          elevation={2}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: 10,
            backgroundColor: "#f4f4f4",
          }}
        >
          <EftInfoCard
            title="Şirket Adresi"
            eftInfo={currentRow?.offer?.restofficial?.billing_address}
          />
        </Paper>
      </Card>
    </Modal>
  );
};

export default EftInfoModal;
