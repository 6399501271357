import React from "react";
import { orderProductsImage } from "../../../../../components/converter/orderProductsImage";
import moment from "moment";

const RenewalProducts = ({ summaryRestDetailInfo }) => {
  return (
    <div style={{ width: "45%" }}>
      <div
        style={{
          width: "100%",
          height: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 4.5px 1px gray",
          fontFamily: "segoeuiB",
          fontSize: 13,
          fontVariant: "all-petite-caps",
        }}
      >
        Ürünler
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {summaryRestDetailInfo?.products !== null &&
        summaryRestDetailInfo?.products?.length !== 0 ? (
          summaryRestDetailInfo?.products?.map((row1) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: 5,
                  backgroundColor: "#494343",
                  margin: 5,
                  borderRadius: 4,
                  flexDirection: "column",
                }}
              >
                <img
                  src={orderProductsImage(row1.key)}
                  alt={row1?.key}
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </div>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              padding: 5,
              backgroundColor: "#494343",
              margin: 5,
              borderRadius: 4,
              flexDirection: "column",
              width: "100%",
              fontFamily: "segoeuiB",
              fontSize: 13,
            }}
          >
            Ürün Bulunmamaktadır.
          </div>
        )}
      </div>
    </div>
  );
};

export default RenewalProducts;
