import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import InputField from "../../../../../../components/input";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import managementService from "../../../../../../services/managementService";
import { Save } from "@mui/icons-material";
import { useToast } from "../../../../../../components/snackbar";

export default function Question({
  question,
  index,
  questions,
  setQuestions,
  packageId,
}) {
  const { success, error } = useToast();
  const [questionsData, setQuestionsData] = useState({
    question: "",
    answer: "",
  });

  useEffect(() => {
    setQuestionsData(question);
  }, [question]);

  useEffect(() => {
    questions[index] = questionsData;
  }, [questionsData]);

  const handleDeleteQuestion = async () => {
    if (question.id) {
      await managementService
        .delete_question(packageId, question.id)
        .then(() => {
          success("Soru-cevap başarıyla silindi");
        });
    }
    questions.splice(index, 1);
    setQuestions([...questions]);
  };

  const handleEditQuestion = async () => {
    if (question.id) {
      await managementService
        .edit_question(packageId, question.id, questionsData)
        .then(() => {
          success("Soru-cevap başarıyla güncellendi");
        });
    }

    setQuestions([...questions]);
  };

  return (
    <Box sx={{ display: "flex", marginBottom: 2, alignItems: "center" }}>
      <Box sx={{ marginRight: 2, width: "40%" }}>
        <InputField
          label="Soru"
          fieldName="question"
          formData={questionsData}
          setFormData={setQuestionsData}
          fieldValue={questionsData?.question}
        />
      </Box>
      <Box sx={{ marginRight: 2, width: "40%" }}>
        <InputField
          label="Cevap"
          fieldName="answer"
          formData={questionsData}
          setFormData={setQuestionsData}
          fieldValue={questionsData?.answer}
        />
      </Box>

      <Save
        style={{ color: "red", cursor: "pointer" }}
        onClick={handleEditQuestion}
      />

      <DeleteForeverIcon
        style={{ color: "red", cursor: "pointer", marginLeft: 20 }}
        onClick={handleDeleteQuestion}
      />
    </Box>
  );
}
