import { Box } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputField from "../../../../../../components/input";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../../../components/selectStyles/stylev3";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function ModuleDetail({
  module,
  index,
  modules,
  handleDeleteModuleDetail,
  productTypes,
  optionalProducts,
}) {
  const [moduleData, setModuleData] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const [disabledText, setDisabledText] = useState(false);

  useEffect(() => {
    setModuleData({
      name: module?.name,
      description: module?.description,
      included: module?.included,
      optional_product: {
        id: module?.optional_product?.id,
        name: module?.optional_product?.name,
      },
      text: module?.text,
      id: module?.id,
    });
  }, [module]);

  useEffect(() => {
    if (moduleData != null) {
      modules[index] = {
        name: moduleData?.name,
        description: moduleData?.description,
        included: moduleData?.included,
        optional_product: moduleData?.optional_product,
        text: moduleData?.text,
        id: moduleData?.id,
      };
    }
  }, [moduleData, index]);

  const handleCheckStatus = (key) => {
    if (key === "optional") {
      setShowProduct(true);
      setModuleData({
        ...moduleData,
        included: "optional",
        optional_product: {
          id: moduleData?.optional_product?.id,
          name: moduleData?.optional_product?.name,
        },
        text: "",
      });
    } else if (key === "yes") {
      setShowProduct(false);
      setModuleData({
        ...moduleData,
        included: "yes",
        text: "",
        optional_product: {},
      });
    } else if (key === "no") {
      setShowProduct(false);
      setModuleData({
        ...moduleData,
        included: "no",
        text: "",
        optional_product: {},
      });
    } else if (key === "text") {
      setShowProduct(false);
      setModuleData({
        ...moduleData,
        included: "text",
        optional_product: {},
      });
    }
  };

  useEffect(() => {
    if (moduleData != null && moduleData?.included === "optional") {
      setShowProduct(true);
    }

    if (moduleData != null && moduleData?.included === "text") {
      setDisabledText(false);
    } else {
      setDisabledText(true);
    }
  }, [moduleData]);

  return (
    <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          background: "#f7f7f7",
        }}
      >
        <Box sx={{ marginRight: 2 }}>
          <InputField
            label="Modul"
            fieldName="name"
            formData={moduleData}
            setFormData={setModuleData}
            fieldValue={moduleData?.name}
          />
        </Box>

        <Box sx={{ marginRight: 2 }}>
          <InputField
            label="Modul Açıklama"
            fieldName="description"
            formData={moduleData}
            setFormData={setModuleData}
            fieldValue={moduleData?.description}
          />
        </Box>

        <FormControl>
          <RadioGroup row value={moduleData != null && moduleData?.included}>
            {productTypes?.included_types?.map((productType, i) => {
              return (
                <FormControlLabel
                  key={i}
                  value={productType.key}
                  control={<Radio />}
                  label={productType.value}
                  onClick={() => handleCheckStatus(productType.key)}
                />
              );
            })}
          </RadioGroup>
        </FormControl>

        {showProduct && (
          <Box sx={{ marginRight: 2 }}>
            <span
              style={{
                color: "#585858",
                fontFamily: "segoeuiRg",
                fontSize: 14,
                fontWeight: 700,
                marginBottom: 4,
              }}
            >
              Ürün Seçiniz
            </span>

            <Select
              styles={{ ...selectDefaultStyles, height: "555px" }}
              options={optionalProducts}
              value={moduleData?.optional_product}
              isSearchable={false}
              hoverColor={"black"}
              selectWidth={140}
              menuColor={"#fff"}
              color={"#000"}
              placeholder={"Ürünler"}
              onChange={(e) => {
                setModuleData({ ...moduleData, optional_product: e });
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
            />
          </Box>
        )}

        {!disabledText && (
          <Box sx={{ marginRight: 2, marginTop: 2 }}>
            <InputField
              label="Yazı"
              fieldName="text"
              formData={moduleData}
              setFormData={setModuleData}
              fieldValue={moduleData?.text}
              disabled={disabledText}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <DeleteForeverIcon
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => handleDeleteModuleDetail(modules, index)}
        />
      </Box>
    </Box>
  );
}
