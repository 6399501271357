import React from "react";
import Lottie from "lottie-react";
import * as animationData from "../../utils/assets/loading.json";

const LottieMain = () => {
  return (
    <Lottie
      animationData={animationData}
      loop
      autoPlay
      style={{ width: 200, height: 300 }}
    />
  );
};

export default LottieMain;
