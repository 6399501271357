import React, { useState, useRef, useCallback } from "react";
import { LoadScript, GoogleMap, Marker, Circle } from "@react-google-maps/api";
function ServiceAreaMap(props) {
  // Store Polygon path in state
  const [path, setPath] = useState([{ ...props.cent, radius: 500 }]);
  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      let rank = polygonRef.current.getRadius();
      props?.onChangeRank(rank / 1000);
      setPath({ ...path, radius: rank * 10 });
    }
  }, [setPath]);
  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
    },
    [onEdit]
  );
  // Clean up refs
  const onUnmount = useCallback(() => {
    polygonRef.current = null;
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <LoadScript
        googleMapsApiKey="AIzaSyBT6XeFQU7AUBj8nGY2ITK1UMsLUPdJe9Q"
        language="tr"
        region="tr"
      >
        <GoogleMap
          on
          zoom={12}
          version="weekly"
          mapContainerStyle={{ height: "100%", width: "100%" }}
          center={props?.cent}
        >
          <Circle
            center={props?.cent}
            radius={props?.rank * 1000} ///1000 metre
            options={{
              fillColor: "#f1b62a",
              strokeColor: "#f1b62a",
              fillOpacity: 0.1,
              content: "labelText",
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
          <Marker position={props?.cent} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default ServiceAreaMap;
