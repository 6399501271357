import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import SearchWithIcon from "../../../../components/searchWithIcon";
import useDebounce from "../../../../components/debounce";
import { useToast } from "../../../../components/snackbar";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function ProductPoolModal({
  openProductPoolModal,
  setOpenProductPoolModal,
  currentHeader,
  currentProductType,
  setProductPhotoModal,
  uploadType,
}) {
  const { id } = useParams();
  const classes = useStyles();
  const { alert } = useToast();
  const {
    getProductHeaderPictureTemplate,
    productHeaderPictureTemplate,
    setProductHeaderPictureTemplate,
    getProductPictureTemplate,
    productPictureTemplate,
    setProductPictureTemplate,
    saveProductPictureTemplate,
  } = useContext(RestaurantContext);
  const [searchHeader, setSearchHeader] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const debouncedsearchProduct = useDebounce(searchProduct, 1000);
  const debouncedsearchValue = useDebounce(searchHeader, 1000);

  useEffect(() => {
    if (productHeaderPictureTemplate) {
      const filteredHeader = productHeaderPictureTemplate.filter((item) =>
        item.name.toLowerCase().includes(debouncedsearchValue.toLowerCase())
      );
      setProductHeaderPictureTemplate(filteredHeader);
    }

    if (debouncedsearchValue.length === 0) {
      getProductHeaderPictureTemplate();
    }
  }, [debouncedsearchValue]);

  useEffect(() => {
    if (productPictureTemplate) {
      const filteredProducts = productPictureTemplate.filter((item) =>
        item.name.toLowerCase().includes(debouncedsearchProduct.toLowerCase())
      );
      setProductPictureTemplate(filteredProducts);
    }

    if (debouncedsearchProduct.length === 0 && selectedHeader) {
      getProductPictureTemplate(selectedHeader.id);
    }
  }, [debouncedsearchProduct]);

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openProductPoolModal}
        className={classes.modal}
        onClose={() => {
          setOpenProductPoolModal(false);
          setSelectedHeader(null);
          setSelectedProduct(null);
        }}
      >
        <Card
          className={classes.paper}
          style={{
            width: "90%",
            display: "flex",
            height: "80vh",
            alignItems: "center",
            padding: 25,
            flexDirection: "column",
          }}
        >
          <div style={{ width: "100%" }}>
            <SearchWithIcon
              setSearchTerm={setSearchHeader}
              placeTitle={"Başlık Ara"}
              searchTerm={searchHeader}
            />
            <div style={{ display: "flex", overflow: "auto" }}>
              {productHeaderPictureTemplate?.map((item, index) => {
                return (
                  <Paper
                    key={index}
                    elevation={2}
                    style={{
                      minWidth: 150,
                      maxWidth: 150,
                      height: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      padding: 5,
                      margin: 15,
                      fontSize: 12,
                      color: selectedHeader?.id === item.id ? "white" : "black",
                      fontFamily: "segoeuiRg",
                      background:
                        selectedHeader?.id === item.id ? "#f53b62" : "",
                    }}
                    onClick={() => {
                      setSelectedHeader(item);
                      getProductPictureTemplate(item.id);
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{item?.name}</span>
                  </Paper>
                );
              })}
            </div>

            <div style={{ marginTop: 20 }}>
              <SearchWithIcon
                setSearchTerm={setSearchProduct}
                placeTitle={"Ürün Ara"}
                searchTerm={searchProduct}
              />
            </div>

            {productPictureTemplate?.length > 0 && (
              <div>
                <div style={{ display: "flex", overflow: "auto" }}>
                  {productPictureTemplate?.map((item, i) => {
                    return (
                      <Paper
                        key={i}
                        elevation={2}
                        style={{
                          minWidth: 200,
                          maxWidth: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          padding: 5,
                          margin: 15,
                          fontSize: 12,
                          color:
                            selectedProduct?.id === item.id ? "white" : "black",
                          fontFamily: "segoeuiRg",
                          background:
                            selectedProduct?.id === item.id ? "#f53b62" : "",
                        }}
                        onClick={() => {
                          setSelectedProduct(item);
                        }}
                      >
                        <img
                          style={{
                            height: 75,
                            width: 75,
                            borderRadius: 10,
                            border: "1px solid darkgray",
                          }}
                          src={item?.picture}
                          alt={item?.name}
                        />
                        <span style={{ fontWeight: "bold", marginLeft: 10 }}>
                          {item.name}
                        </span>
                      </Paper>
                    );
                  })}
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  fontFamily: "segoeuiB",
                  fontWeight: 700,
                  backgroundColor: "#0FBD20",
                  width: 200,
                }}
                onClick={() => {
                  if (!selectedProduct || !selectedHeader)
                    return alert("Lütfen bir başlık ve ürün seçiniz.");
                  const data = {
                    product: currentHeader.id,
                    product_picture_template: selectedProduct.id,
                  };
                  saveProductPictureTemplate(data, id);
                  setOpenProductPoolModal(false);
                  setProductPhotoModal(false);
                }}
              >
                Onayla
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
}
