import { Box, Modal, createTheme, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import restaurantService from "../../../services/restaurantService";

export default function OnlineStatusModal({
  setOpenOnlineStatusModal,
  openOnlineStatusModal,
  electronStatus,
  restofficialPlatforms,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [restaurantStatus, setRestaurantStatus] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [selectedRest, setSelectedRest] = useState(null);
  const [diffText, setDiffText] = useState("Kapalı");

  useEffect(() => {
    let status = [];
    if (restaurantStatus.length === 0) return;
    for (let i = 0; i < restaurantStatus.length; i++) {
      let first = restaurantStatus[i];
      let sec = restaurantStatus[i - 1];

      if (i > 0) {
        if (first.state_working === "open") {
          let eachStatus =
            moment(first.created_at).format("HH:mm") +
            " -> " +
            moment(sec.created_at).format("HH:mm") +
            " Açık";
          status = [...status, eachStatus];
        } else {
          let eachStatus =
            moment(first.created_at).format("HH:mm") +
            " -> " +
            moment(sec.created_at).format("HH:mm") +
            " Kapalı";
          status = [...status, eachStatus];
        }

        if (restaurantStatus.length - 1 === i) {
          let eachStatus;
          if (restaurantStatus[i].state_working === "open") {
            eachStatus = `${moment(restaurantStatus[i].created_at).format(
              "HH:mm"
            )} öncesi Kapalı`;
          } else {
            eachStatus = `${moment(restaurantStatus[i].created_at).format(
              "HH:mm"
            )} öncesi Açık`;
          }

          status = [...status, eachStatus];
        }
      } else {
        if (first.state_working === "open") {
          let eachStatus = `${moment(first.created_at).format(
            "HH:mm"
          )} -> Şuan Açık`;
          status = [...status, eachStatus];
        } else {
          let eachStatus = `${moment(first.created_at).format(
            "HH:mm"
          )} -> Şuan Kapalı`;
          status = [...status, eachStatus];
        }
      }

      setFilteredStatus(status);
    }
  }, [restaurantStatus]);

  const handleSelectRestaurant = async (item) => {
    setSelectedRest(item);
    const res = await restaurantService.get_restaurant_online_history(item.id);
    setRestaurantStatus(res);
  };

  useEffect(() => {
    if (electronStatus) {
      const currentDate = moment();

      const diff = currentDate.diff(
        moment(electronStatus[0]?.created_at),
        "hours"
      );
      if (diff > 18) {
        setDiffText("Pasif");
      } else {
        setDiffText("Kapalı");
      }
    }
  }, [electronStatus]);

  return (
    <Modal
      open={openOnlineStatusModal}
      onClose={() => setOpenOnlineStatusModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "70%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Online/Offline Durumu
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "500px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <Box>
            <h3 style={{ textAlign: "center" }}>
              Electron Online/Offline Durumu
            </h3>
            <Box
              sx={{
                border: "1px solid #f4f4f4",
                background: "#f4f4f4",
              }}
            >
              {electronStatus != null &&
                electronStatus.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        borderBottom: "1px solid #fff",
                        padding: 10,
                        fontSize: 15,
                        fontFamily: "segoeuiRg",
                      }}
                    >
                      {i === 0 && (
                        <>
                          <span
                            style={{
                              color: electronStatus[i]?.online
                                ? "green"
                                : "red",
                            }}
                          >
                            Şuanki Durumu :{" "}
                            {electronStatus[i]?.online ? "Açık" : diffText}{" "}
                          </span>
                          <br />
                          <br />
                        </>
                      )}

                      {electronStatus?.length === i + 1 ? (
                        <div style={{ display: "flex" }}>
                          <div style={{ width: 180, fontFamily: "segoeuiB" }}>
                            ?
                          </div>
                          <span
                            style={{
                              width: 50,
                              textAlign: "center",
                              color: !electronStatus[i]?.online
                                ? "green"
                                : "red",
                              fontFamily: "segoeuiB",
                            }}
                          >
                            {!electronStatus[i]?.online ? "Açık" : "Kapalı"}
                          </span>
                          <div style={{ width: 200, textAlign: "end" }}>
                            <span style={{ fontFamily: "segoeuiB" }}>
                              {moment(electronStatus[i]?.created_at).format(
                                "HH:mm:ss"
                              )}
                            </span>
                            <span
                              style={{
                                fontSize: 12,
                                color: "gray",
                                fontFamily: "segoeuiB",
                              }}
                            >
                              (
                              {moment(electronStatus[i]?.created_at).format(
                                "DD-MM-YYYY"
                              )}
                              )
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <div style={{ width: 180 }}>
                            <span style={{ fontFamily: "segoeuiB" }}>
                              {" "}
                              {moment(electronStatus[i + 1]?.created_at).format(
                                "HH:mm:ss"
                              )}
                            </span>
                            <span
                              style={{
                                fontSize: 12,
                                color: "gray",
                                fontFamily: "segoeuiB",
                              }}
                            >
                              (
                              {moment(electronStatus[i + 1]?.created_at).format(
                                "DD-MM-YYYY"
                              )}
                              )
                            </span>
                          </div>
                          <span
                            style={{
                              width: 50,
                              textAlign: "center",
                              color: electronStatus[i + 1]?.online
                                ? "green"
                                : "red",
                              fontFamily: "segoeuiB",
                            }}
                          >
                            {electronStatus[i + 1]?.online ? "Açık" : "Kapalı"}
                          </span>
                          <div style={{ width: 200, textAlign: "end" }}>
                            <span style={{ fontFamily: "segoeuiB" }}>
                              {moment(electronStatus[i]?.created_at).format(
                                "HH:mm:ss"
                              )}
                            </span>
                            <span
                              style={{
                                fontSize: 12,
                                color: "gray",
                                fontFamily: "segoeuiB",
                              }}
                            >
                              (
                              {moment(electronStatus[i]?.created_at).format(
                                "DD-MM-YYYY"
                              )}
                              )
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </Box>
          </Box>
          <Box>
            <h3 style={{ textAlign: "center" }}>
              Restoranların Online/Offline Durumu
            </h3>

            {selectedRest && (
              <Box
                sx={{
                  background: "#f53b62",
                  color: "#fff",
                  fontFamily: "segoeuiRg",
                  fontWeight: 500,
                  padding: "5px",
                  borderRadius: "5px",
                  display: "inline-block",
                  marginBottom: 1,
                }}
              >
                <span>
                  {selectedRest.restaurant_name} - {selectedRest.platform.name}
                </span>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {filteredStatus.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderBottom: "1px solid #fff",
                      padding: 5,
                      fontSize: 12,
                      fontFamily: "segoeuiRg",
                      marginRight: 10,
                      marginBottom: 10,
                      background: "#f4f4f4",
                      width: 90,
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </Box>
            <h3>Restoran Listesi</h3>
            <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: 2 }}>
              {restofficialPlatforms.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderBottom: "1px solid #fff",
                      padding: 10,
                      fontSize: 15,
                      fontFamily: "segoeuiRg",
                      background: "#f4f4f4",
                      marginRight: 5,
                      marginBottom: 5,
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    onClick={() => handleSelectRestaurant(item)}
                  >
                    {item.restaurant_name} - {item.platform.name}
                  </div>
                );
              })}
            </Box>
          </Box>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
