import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Modal,
  Box,
  Card,
  CardContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { DeleteForever, DescriptionTwoTone } from "@mui/icons-material";
import { withStyles, makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { useToast } from "../../../components/snackbar";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import Colors from "../../../utils/colors";
import { isBlank } from "../../../components/regExr";
import { GreenSwitch } from "../../../components/switch/greenSwitch";

const headColumns = [
  {
    id: "courierInfo",
    label: "Kurye Bilgileri",
    minWidth: 170,
    align: "center",
  },
  { id: "statu", label: "Durum", minWidth: 120, align: "center" },
  { id: "name_edit", label: "İsim Düzenle", minWidth: 120, align: "center" },
  { id: "delete", label: "Kaldır", minWidth: 120, align: "center" },
];

const useStyles = makeStyles({
  root: {
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
  },
});

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#f53b62",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#f53b62",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#f53b62",
      },
    },
  },
})(TextField);

const Courier = () => {
  const { info } = useToast();
  const classes = useStyles();
  const {
    patchRestaurantCourierData,
    getRestaurantCourierData,
    postRestaurantCourierData,
    restaurantCourier,
    courierLoading,
    setCurrentIndex,
    currentIndex,
  } = useContext(RestaurantContext);
  const [courierName, setCourierName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentCourier, setCurrentCourier] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  let { id } = useParams();

  useEffect(() => {
    id && getRestaurantCourierData(id, page + 1);
    setCurrentIndex("courier");
  }, [id, page]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  };

  const updateCourierStatu = (row) => {
    let newData = { ...row };
    newData.is_active = !row.is_active;
    patchRestaurantCourierData(id, newData, 1);
  };
  const deleteCourier = (row) => {
    let newData = { ...row };
    newData.is_deleted = !row.is_deleted;

    patchRestaurantCourierData(id, newData, 1);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Card style={styles.card}>
        <CardContent style={styles.cardContent}>
          <div style={styles.textContent}>
            Eklemek istediğiniz kuryenin adını ve soyadını giriniz.
          </div>
          <div style={styles.inputContent}>
            <InputField
              variant="filled"
              required
              onKeyDown={keyPress}
              fullWidth
              spellCheck={false}
              name="courier-name"
              value={courierName}
              label="Kurye ad-soyad"
              type={"text"}
              id="courierName"
              style={{ backgroundColor: "#f1f1f1" }}
              inputProps={{
                style: {
                  fontFamily: "segoeuiRg",
                  color: "#0a0a0a",
                },
              }}
              InputLabelProps={{
                style: {
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  color: "#0a0a0a",
                },
              }}
              onChange={(e) => setCourierName(e.target.value)}
            />
            <LoadingButton
              loading={courierLoading}
              loadingIndicator=""
              style={{
                ...styles.btnContent,
                backgroundColor: courierLoading ? "gray" : "#f53f62",
              }}
              type="submit"
              onClick={async () => {
                if (isBlank(courierName)) {
                  info("Kurye ad soyad giriniz.");
                } else {
                  await postRestaurantCourierData(id, 1, {
                    name: courierName,
                    phone: "00000000000",
                  });
                  setCourierName("");
                }
              }}
            >
              {courierLoading ? "Ekleniyor" : "Ekle"}
            </LoadingButton>
          </div>
        </CardContent>
      </Card>

      <Paper
        sx={{
          width: "98%",
          overflow: "hidden",
          marginBottom: 10,
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headColumns?.map((column, idx) => (
                  <TableCell
                    key={idx}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: Colors.tableHeadColor,
                      height: 32,
                      borderRightWidth: 0.2,
                      borderRightColor: "#fff",
                      border: "0.2px solid #fff",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurantCourier?.results?.map((row, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "#fff", color: "#000" }}
                    >
                      {row?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <GreenSwitch
                        onClick={() => {
                          updateCourierStatu(row);
                        }}
                        checked={row?.is_active}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <IconButton
                        onClick={() => {
                          setOpenModal(true);
                          setCurrentCourier(row);
                        }}
                      >
                        <DescriptionTwoTone
                          style={{ color: "#f53b62", cursor: "pointer" }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <IconButton
                        onClick={() => {
                          deleteCourier(row);
                        }}
                      >
                        <DeleteForever
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {restaurantCourier?.results && (
          <TablePagination
            rowsPerPageOptions={[10]}
            labelRowsPerPage={"Sayfa Başına Kayıt"}
            count={restaurantCourier?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            component="div"
            style={{
              backgroundColor: "black",
            }}
            classes={{ root: classes.root }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <Modal
        open={openModal}
        onClose={() => handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...styles.modal, width: 400 }}>
          <h2 id="child-modal-title">Kurye İsmini Düzenle</h2>
          <div style={{ ...styles.inputContent, width: "100%" }}>
            <InputField
              variant="filled"
              required
              onKeyDown={keyPress}
              fullWidth
              spellCheck={false}
              name="courier-name"
              value={currentCourier.name}
              label="Kurye ad-soyad"
              type={"text"}
              id="courierName"
              style={{
                backgroundColor: "#d1d1d1",
                height: "52px",
                borderRadius: 6,
              }}
              inputProps={{
                style: {
                  fontFamily: "segoeuiRg",
                  color: "#0a0a0a",
                },
              }}
              InputLabelProps={{
                style: {
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  color: "#0a0a0a",
                },
              }}
              onChange={(e) => {
                let editcourierName = { ...currentCourier };
                editcourierName.name = e.target.value;
                setCurrentCourier(editcourierName);
              }}
            />
            <LoadingButton
              loading={courierLoading}
              loadingIndicator=""
              style={{
                ...styles.btnContent,
                backgroundColor: courierLoading ? "gray" : "#f53f62",
              }}
              type="submit"
              onClick={async () => {
                if (isBlank(currentCourier)) {
                  info("Kurye ad soyad giriniz.");
                } else {
                  await patchRestaurantCourierData(id, currentCourier, 1);
                  setCourierName("");
                  handleClose();
                }
              }}
            >
              Kaydet
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};
const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#f1f1f1",
    border: "2px solid #f1f1f1",
    boxShadow: 24,
    borderRadius: 6,
    pt: 2,
    px: 4,
    pb: 3,
  },
  card: {
    width: "90%",
    height: "auto",
    marginTop: 15,
    overflow: "auto",
    marginLeft: "4%",
  },
  cardContent: {
    width: "95%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  textContent: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    color: "#0a0a0a",
    fontFamily: "segoeuiRg",
    fontWeight: 700,
  },
  inputContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    color: "#0a0a0a",
  },
  btnContent: {
    width: "30%",
    color: "#ffffff",
    fontFamily: "segoeuiRg",
    textTransform: "capitalize",
    fontSize: 18,
    marginLeft: 15,
    padding: "7px 20px 7px 20px",
    backgroundColor: "#f53f62",
  },
};

export default Courier;
