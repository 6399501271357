import {
  Box,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import CardFlip from "react-card-flip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function ProductRenewalsModal({
  openRenewalModal,
  setOpenRenewalModal,
  dashboardProductRenewals,
  startDate,
  endDate,
  dashboardOfficeuserDetail,
}) {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [newProductRenewalsWithTotal, setNewProductRenewalsWithTotal] =
    useState([]);
  const {
    dashboardOfficeuserProductRenewalsRestaurants,
    dashboardProductRenewalsDetail,
  } = useContext(ManagementContext);
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    const newProductRenewalsWithTotalArr = [...dashboardProductRenewals];

    newProductRenewalsWithTotalArr.push({
      product_name: "Toplam",
      count: dashboardProductRenewals.reduce(
        (acc, item) => acc + item.count,
        0
      ),
      payed_count: dashboardProductRenewals.reduce(
        (acc, item) => acc + item.payed_count,
        0
      ),
      payed_amount: dashboardProductRenewals.reduce(
        (acc, item) => acc + item.payed_amount,
        0
      ),
      blocked_count: dashboardProductRenewals.reduce(
        (acc, item) => acc + item.blocked_count,
        0
      ),
      processing_count: dashboardProductRenewals.reduce(
        (acc, item) => acc + item.processing_count,
        0
      ),
    });

    setNewProductRenewalsWithTotal(newProductRenewalsWithTotalArr);
  }, [dashboardProductRenewals]);

  const handleShowDetail = (item, type) => {
    let data;
    if (item.product_name === "Toplam") {
      data = {
        user_id: dashboardOfficeuserDetail.user_id || 0,
        startDate: startDate,
        endDate: endDate,
        product_id: "",
        processType: type,
      };
    } else {
      data = {
        user_id: dashboardOfficeuserDetail.user_id || 0,
        startDate: startDate,
        endDate: endDate,
        product_id: item.product_id,
        processType: type,
      };
    }
    dashboardOfficeuserProductRenewalsRestaurants(data);
    setIsFlipped(true);
  };

  return (
    <Modal
      open={openRenewalModal}
      onClose={() => {
        setOpenRenewalModal(false);
      }}
      aria-labelledby="parent-datatitle"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styles.modal, width: matches ? "50%" : "90%" }}>
        <h2
          id="child-modal-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Sözleşme Uzatma
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "450px",
            overflowY: "auto",
            backgroundColor: "#fff",
          }}
        >
          <CardFlip
            isFlipped={isFlipped}
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Ürün</TableCell>
                    <TableCell align="center">Geçen Yıl</TableCell>
                    <TableCell align="center">Adet</TableCell>
                    <TableCell align="center">Uzatılan</TableCell>
                    <TableCell align="center">Uzatma TL</TableCell>
                    <TableCell align="center">Bloke</TableCell>
                    <TableCell align="center">İşlemde</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newProductRenewalsWithTotal &&
                    newProductRenewalsWithTotal?.map((item, ind) => {
                      return (
                        <TableRow key={ind}>
                          <TableCell align="center">
                            {item.product_name}
                          </TableCell>
                          <TableCell align="center">geçen yıl</TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleShowDetail(item, "")}
                          >
                            {item.count}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleShowDetail(item, "payed")}
                          >
                            {item.payed_count}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleShowDetail(item, "")}
                          >
                            {item.payed_amount} ₺
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleShowDetail(item, "blocked")}
                          >
                            {item.blocked_count}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleShowDetail(item, "processing")}
                          >
                            {item.processing_count}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <div>
              <ArrowBackIcon
                onClick={() => setIsFlipped(false)}
                style={{ cursor: "pointer" }}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">ID</TableCell>
                      <TableCell align="center">İsim</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardProductRenewalsDetail &&
                      dashboardProductRenewalsDetail?.map((item, ind) => {
                        return (
                          <TableRow key={ind}>
                            <TableCell
                              align="center"
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              onClick={() => {
                                window.open(
                                  `restaurants/${item.user_id}`,
                                  "_blank"
                                );
                              }}
                            >
                              {item.user_id}
                            </TableCell>
                            <TableCell align="center">{item.name}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CardFlip>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
  },
};
