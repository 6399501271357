import React, { useContext, useEffect } from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
//MUI
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControlLabel,
} from "@mui/material";
import { GreenSwitch } from "../../../../../components/switch/greenSwitch";

function PaymentTypes({ resDetailInfo, id }) {
  const { getRestaurantPaymentTypeList, paymentTypeList, resChangeDetailFunc } =
    useContext(RestaurantContext);
  useEffect(() => {
    getRestaurantPaymentTypeList();
  }, []);
  return (
    <Card style={{ overflow: "auto", marginTop: 4 }}>
      <CardContent>
        <TableContainer>
          <Table style={{ border: 0, width: "98%" }}>
            <TableBody>
              {paymentTypeList &&
                paymentTypeList.map((payment) => (
                  <TableRow key={payment?.id}>
                    <TableCell
                      style={{
                        borderTop: 0,
                        borderRight: 0,
                        borderLeft: 0,
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "SgSemi",
                          fontSize: 14,
                        }}
                      >
                        {payment.name}
                      </span>
                    </TableCell>

                    <TableCell
                      style={{
                        borderTop: 0,
                        borderRight: 0,
                        borderLeft: 0,
                      }}
                      align="right"
                    >
                      <FormControlLabel
                        control={
                          <GreenSwitch
                            checked={resDetailInfo?.payment_types?.some(
                              (paymentType) => {
                                return paymentType === payment.id;
                              }
                            )}
                            onChange={(e) => {
                              const paymentListObj = Object.assign(
                                [],
                                JSON.parse(
                                  JSON.stringify({
                                    ...resDetailInfo?.payment_types,
                                  })
                                )
                              );
                              let paymentTypeId = paymentListObj.findIndex(
                                (paymentTypeListId) =>
                                  paymentTypeListId === payment?.id
                              );
                              if (paymentTypeId == -1) {
                                paymentListObj.push(payment?.id);
                              } else {
                                paymentListObj.splice(paymentTypeId, 1);
                              }
                              let data = {
                                payment_types: paymentListObj,
                                restofficial_id: id,
                              };
                              resChangeDetailFunc(data);
                            }}
                            /* disabled={buttonDisable} */
                          />
                        }
                        label={
                          resDetailInfo?.payment_types?.some((option) => {
                            return option == payment.id;
                          }) ? (
                            <span
                              style={{
                                fontFamily: "SgSemi",
                                fontSize: 14,
                              }}
                            >
                              Aktif
                            </span>
                          ) : (
                            <span
                              style={{
                                fontFamily: "SgSemi",
                                fontSize: 14,
                              }}
                            >
                              Pasif
                            </span>
                          )
                        }
                        labelPlacement="start"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default PaymentTypes;
