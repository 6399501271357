import React from "react";

import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const ProductDeleteModal = ({
  productDeleteModal,
  setProductDeleteModal,
  currentRow,
  setCurrentRow,
  menuDetailChangeFunc,
  deleteType,
  setDeleteType,
  menuHeaderDetailChangeFunc,
  id,
}) => {
  const classes = useStyles();
  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={productDeleteModal}
        className={classes.modal}
        onClose={() => {
          setProductDeleteModal(false);
          setCurrentRow(null);
        }}
      >
        <Card
          className={classes.paper}
          style={{
            width: "60%",
            display: "flex",

            alignItems: "center",
            padding: 25,
            flexDirection: "column",
          }}
        >
          <div>
            Ürünü siliyorsunuz. Hem App'ten hem masadan silinecektir bla bla{" "}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                backgroundColor: "#f53b62",
                color: "#fff",
                fontFamily: "SgBd",
                width: "30%",
                display: "flex",
                justifyContent: "center",
                height: 30,
                marginTop: 30,
                textTransform: "capitalize",
              }}
              size="small"
              onClick={() => {
                setProductDeleteModal(false);
                setCurrentRow(null);
                setDeleteType(null);
              }}
            >
              Vazgeç
            </Button>
            <Button
              style={{
                backgroundColor: "#f53b62",
                color: "#fff",
                fontFamily: "SgBd",
                width: "30%",
                display: "flex",
                justifyContent: "center",
                height: 30,
                marginTop: 30,
                textTransform: "capitalize",
              }}
              size="small"
              onClick={() => {
                if (deleteType == "header") {
                  menuHeaderDetailChangeFunc(currentRow);
                } else {
                  menuDetailChangeFunc(currentRow);
                }
                setProductDeleteModal(false);
                setCurrentRow(null);
                setDeleteType(null);
              }}
            >
              Sil
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default ProductDeleteModal;
