import { useContext, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import moment from "moment";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import { ManagementContext } from "../../../contexts/managementContext";
import useWindowDimensions from "../../../components/windowSize";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

const formatter = new Intl.NumberFormat("tr-TR", {
  style: "currency",
  currency: "TRY",
  minimumFractionDigits: 2,
});

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
  cart: {
    "&.active": {
      backgroundColor: "#f53f62",
    },
  },
});

const ManagerPayment = () => {
  const classes = useStyles();
  const [workerSelect, setWorkerSelect] = useState(null);
  const [status, setStatus] = useState(null);
  const { width } = useWindowDimensions();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { getApplicationTransferList, backofficeList } =
    useContext(RestaurantContext);

  const { getPaymentTracker, paymentTracker } = useContext(ManagementContext);

  const handleChangeWorker = (e) => {
    setPage(0);
    setWorkerSelect(e);
  };

  useEffect(() => {
    backofficeList.unshift({
      user_id: "",
      full_name: "Tümü",
    });
  }, [backofficeList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let data = {
      userId: workerSelect === null ? "" : workerSelect.user_id,
      page: page + 1,
      status: status === null ? "" : status,
    };
    getPaymentTracker(data);
  }, [workerSelect, page, status]);

  const handleSelectStatus = (card) => {
    if (status === card.key) {
      setStatus("");
    } else {
      setStatus(card.key);
    }
    setPage(0);
  };

  useEffect(() => {
    getApplicationTransferList();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "90%" }}>
        <Box>
          <h3
            style={{
              fontFamily: "segoeuiB",
              fontSize: 24,
              textAlign: "center",
            }}
          >
            FİNANS / YÖNETİCİ ÖDEME TAKİP
          </h3>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
              }}
            >
              Kişiler
            </span>
            <Select
              options={backofficeList}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              isSearchable={false}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={width > 1200 ? 230 : 140}
              placeholder={"Tümü"}
              value={workerSelect}
              getOptionValue={(option) => option.user_id}
              onChange={(e) => {
                handleChangeWorker(e);
              }}
              getOptionLabel={(option) => option.full_name}
            />
          </div>
        </Box>
        <Grid
          container
          columns={10}
          spacing={2}
          sx={{ marginTop: 2, marginBottom: 4 }}
        >
          {paymentTracker?.reports.map((card, i) => (
            <Grid item xs={1} key={i}>
              <Paper
                className={`${classes.cart} ${
                  status === card.key ? "active" : ""
                }`}
                elevation={3}
                sx={{ padding: 2, cursor: "pointer" }}
                onClick={() => handleSelectStatus(card)}
              >
                <h3
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    fontFamily: "segoeuiL",
                    color: status === card.key ? "#fff" : "darkslategray",
                    fontSize: 18,
                  }}
                >
                  {card.name}
                </h3>
                <span
                  style={{
                    display: "block",
                    textAlign: "right",
                    fontSize: 16,
                    marginLeft: 5,
                    fontFamily: "segoeuiL",
                    color: status === card.key ? "#fff" : "darkslategray",
                  }}
                >
                  {card.value}
                </span>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Kurulum Tarih</TableCell>
                <TableCell align="center">Restoran Adı</TableCell>
                <TableCell align="center">Durum</TableCell>
                <TableCell align="center">Teklif/Ay</TableCell>
                <TableCell align="center">GF Üyelik</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentTracker?.results?.map((res, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">
                      {moment(new Date(res?.created_at)).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </TableCell>
                    <TableCell align="center">{res?.name}</TableCell>
                    <TableCell align="center">{res?.status}</TableCell>
                    <TableCell align="center">
                      {formatter.format(res.offer?.total_amount) +
                        " / " +
                        res.offer?.months +
                        "ay"}
                      {res.offer?.free_days
                        ? "+" + res.offer?.free_days + "gün"
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {res?.is_gf ? "Var" : "Yok"}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                {paymentTracker?.results && (
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    labelRowsPerPage={"Sayfa Başına Kayıt"}
                    count={paymentTracker?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    classes={{ root: classes.root }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ManagerPayment;
