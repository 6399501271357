import React, { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../contexts/restaurantContext";
import managementService from "../../services/managementService";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

export default function PrimTable() {
  const { getPrimTable, primTable } = useContext(RestaurantContext);
  const [productsList, setProductsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedRenew, setSelectedRenew] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await managementService.get_product_list();
      setProductsList(res);
    })();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      getPrimTable(selectedProduct.id, false);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      getPrimTable(selectedProduct.id, selectedRenew);
    }
  }, [selectedRenew]);

  return (
    <div>
      <div style={{ padding: "0px 30px", marginTop: 30 }}>
        <h2
          style={{
            textAlign: "center",
            margin: 0,
            marginBottom: 20,
          }}
        >
          Ürünler
        </h2>

        <div style={{ display: "flex", overflow: "auto", paddingBottom: 10 }}>
          {productsList?.map((product) => {
            return (
              <Tooltip title={product.name} key={product.id}>
                <Paper
                  elevation={2}
                  style={{
                    cursor: "pointer",
                    color:
                      selectedProduct?.id === product.id ? "white" : "black",
                    fontFamily: "segoeuiRg",
                    background:
                      selectedProduct?.id === product.id ? "#f53b62" : "",
                    padding: 5,
                    marginRight: 20,
                    width: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    minWidth: 100,
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setSelectedProduct(product);
                    setSelectedRenew(false);
                  }}
                >
                  {product.name}
                </Paper>
              </Tooltip>
            );
          })}
        </div>

        {selectedProduct && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Button
              style={{
                backgroundColor: !selectedRenew ? "#f53b62" : "gray",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: 200,
                display: "flex",
                justifyContent: "center",
                height: 40,
                marginRight: 10,
              }}
              size="small"
              onClick={() => {
                setSelectedRenew(false);
              }}
            >
              Normal Primler
            </Button>
            <Button
              style={{
                backgroundColor: selectedRenew ? "#f53b62" : "gray",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: 200,
                display: "flex",
                justifyContent: "center",
                height: 40,
                marginLeft: 10,
              }}
              size="small"
              onClick={() => {
                setSelectedRenew(true);
              }}
            >
              Sözleşme Uzatma Primler
            </Button>
          </div>
        )}

        {selectedProduct && !selectedRenew ? (
          <div style={{ width: "70%", margin: "auto" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">İndirim</TableCell>
                    <TableCell align="center">Prim</TableCell>
                    <TableCell align="center">Ürün Fiyat</TableCell>
                    <TableCell align="center">Kazanç</TableCell>
                    <TableCell align="center">Kayıp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {primTable?.map((prim, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">%{prim.discount}</TableCell>
                        <TableCell align="center">%{prim.prim}</TableCell>
                        <TableCell align="center">
                          {prim.sales_price} ₺
                        </TableCell>
                        <TableCell align="center">{prim.profit} ₺</TableCell>
                        <TableCell align="center">{prim.loss} ₺</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div style={{ width: "70%", margin: "auto" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Min Artış</TableCell>
                    <TableCell align="center">Max Artış</TableCell>
                    <TableCell align="center">Prim</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {primTable?.map((prim, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell align="center">
                          %{prim.min_increase}
                        </TableCell>
                        <TableCell align="center">
                          %{prim.max_increase}
                        </TableCell>
                        <TableCell align="center">%{prim.prim}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
}
