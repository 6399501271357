import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Card, Modal } from "@mui/material";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import OTPInput from "react-otp-input";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 100%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const LockedModal = ({ lockedModal, setLockedModal, currentRowForUnlock }) => {
  const classes = useStyles();
  const { taskUnlockFunc } = useContext(RestaurantContext);
  const [passw, setPassw] = useState("");

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={lockedModal}
      className={classes.modal}
    >
      <Card
        style={{
          width: "30%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <OTPInput
            value={passw}
            inputType="password"
            onChange={(e) => {
              setPassw(e);
              if (e.length === 4) {
                //   checkPassword(e);
                let data = {
                  task_id: currentRowForUnlock?.id,
                  setPassw,
                  setLockedModal,
                  sendObj: {
                    unlock_code: e,
                  },
                };
                taskUnlockFunc(data);
              }
            }}
            numInputs={4}
            shouldAutoFocus
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  width: 35,
                  height: 35,
                  textAlign: "center",
                  fontSize: 18,
                }}
              />
            )}
          />
        </div>
      </Card>
    </Modal>
  );
};

export default LockedModal;
