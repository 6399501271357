import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import ProductsModal from "./ProductsModal";

const showInfo = (row) => {
  return (
    <Box>
      <p>Kişi: {row?.full_name}</p>
      <p>Atanan Data: {row?.application?.total_count}</p>
      <p>Dokunulmayan Data: {row?.application?.not_touched_count}</p>
      <p>İşlemde: {row?.application?.processing_count}</p>
      <p>Sayaçta: {row?.application?.stayed_count}</p>
      <p>Silinen: {row?.application?.deleted_count}</p>
      <p>Toplam Dokunulan: {row?.application?.touched_count}</p>
      <p>
        Yönlendirilen: {row?.application?.transfered_count} / %
        {row?.application?.transfered_percent}
      </p>
      <p>
        Çapraz Satış: {row?.multi_sale?.total_count} / %
        {row?.multi_sale?.percent}
      </p>
      <p>
        Bekleyen Teklif Adet: {row?.payment?.total_count} / Ort Gün:{" "}
        {row?.payment?.average_remaining_days?.toFixed(2)} / Alınacak bakiye:{" "}
        {row?.payment?.total_amount} ₺
      </p>
      <p>
        Ön Ödeme Adet: {row?.remaining_payment?.total_count} / Ort Gün:{" "}
        {row?.remaining_payment?.average_remaining_days?.toFixed(2)} / kalan
        bakiye: {row?.remaining_payment?.total_amount} ₺
      </p>
      <p>Bloke: {row?.restoff?.blocked}</p>
      <p>
        Pasif Ödemiş: {row?.restoff?.passive_payed} / %
        {row?.restoff?.passive_payed_percent}
      </p>
      <p>
        Aktif Ödemiş: {row?.restoff?.active_payed} / %
        {row?.restoff?.active_payed_percent}
      </p>
      <p>
        Pasif Ödememiş: {row?.restoff?.passive_not_payed} / %
        {row?.restoff?.passive_not_payed_percent}
      </p>
      <p>Toplam Tahsilat: {row?.restoff?.total_amount} ₺</p>
    </Box>
  );
};

export default function KpiOutbound({ kpiOutbound }) {
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);

  const handleShowProducts = (row) => {
    setOpenModal(true);
    setProducts(row.products);
  };

  return (
    <Box>
      <h3 style={{ textAlign: "center" }}>KPI OUTBOUND RAPORLARI</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Kişi
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Atanan Data
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Dokunulmayan Data
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                İşlemde
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Sayaçta
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Silinen
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Toplam Dokunulan
              </TableCell>
              <TableCell align="center" style={{ minWidth: 300 }}>
                Yönlendirilen / % / Başarılı / Başarısız / Bekliyor
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ürünler
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Çapraz Satış
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Bekleyen Teklif Adet / Ort Gün / Alınacak bakiye
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Ön Ödeme Adet / Ort Gün / kalan bakiye
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Bloke %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Pasif Ödemiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Aktif Ödemiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Pasif Ödememiş %
              </TableCell>
              <TableCell align="center" style={{ minWidth: 200 }}>
                Toplam Tahsilat
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kpiOutbound?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <Tooltip title={showInfo(row)}>
                    <TableCell
                      align="center"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {row?.full_name} <InfoIcon style={{ color: "#293c51" }} />
                    </TableCell>
                  </Tooltip>
                  <TableCell align="center">
                    {row?.application?.total_count}
                  </TableCell>
                  <TableCell align="center">
                    {row?.application?.not_touched_count}
                  </TableCell>
                  <TableCell align="center">
                    {row?.application?.processing_count}
                  </TableCell>
                  <TableCell align="center">
                    {row?.application?.stayed_count}
                  </TableCell>
                  <TableCell align="center">
                    {row?.application?.deleted_count}
                  </TableCell>
                  <TableCell align="center">
                    {row?.application?.touched_count}
                  </TableCell>
                  <TableCell align="center">
                    {row?.application?.transfered_count} /{" "}
                    {row?.application?.transfered_percent}% /{" "}
                    {row?.application?.transfer_setup_count} /{" "}
                    {row?.application?.transfer_deleted_count} /{" "}
                    {row?.application?.transfer_awaiting_count}
                  </TableCell>
                  <TableCell align="center">
                    <InfoIcon
                      style={{ cursor: "pointer", color: "#f53b62" }}
                      onClick={() => handleShowProducts(row)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row?.multi_sale?.total_restofficial_count} /{" "}
                    {row?.multi_sale?.total_count} / {row?.multi_sale?.percent}%
                  </TableCell>
                  <TableCell align="center">
                    {row?.payment?.total_count} /{" "}
                    {row?.payment?.average_remaining_days?.toFixed(2)} /{" "}
                    {row?.payment?.total_amount} ₺
                  </TableCell>
                  <TableCell align="center">
                    {row?.remaining_payment?.total_count} /{" "}
                    {row?.remaining_payment?.average_remaining_days?.toFixed(2)}{" "}
                    / {row?.remaining_payment?.total_amount} ₺
                  </TableCell>
                  <TableCell align="center">{row?.restoff?.blocked}</TableCell>
                  <TableCell align="center">
                    {row?.restoff?.passive_payed} /{" "}
                    {row?.restoff?.passive_payed_percent}%
                  </TableCell>
                  <TableCell align="center">
                    {row?.restoff?.active_payed} /{" "}
                    {row?.restoff?.active_payed_percent}%
                  </TableCell>
                  <TableCell align="center">
                    {row?.restoff?.passive_not_payed} /{" "}
                    {row?.restoff?.passive_not_payed_percent}%
                  </TableCell>
                  <TableCell align="center">
                    {row?.restoff?.total_amount} ₺
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ProductsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        products={products}
      />
    </Box>
  );
}
