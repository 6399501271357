import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import {
  Drawer,
  ListItemText,
  ListItemButton,
  List,
  Collapse,
  Tooltip,
} from "@mui/material";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import {
  AddBusiness,
  AttachMoney,
  ExpandLess,
  ExpandMore,
  Paid,
  Report,
  Restaurant,
  Save,
  Task,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  drawerClose: {
    overflowX: "hidden",
    width: "3%",
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
}));

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    marginTop: 8,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 12,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const SidebarIcon = ({ sidebarData = [] }) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState({});
  const [open, setOpen] = useState(false);
  const [isFinanceUser, setIsFinanceUser] = useState(false);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));
    if (
      userInfo.department === "Operasyon Yonetimi" &&
      userInfo.sub_department === "Finans"
    ) {
      setIsFinanceUser(true);
    } else {
      setIsFinanceUser(false);
    }

    sidebarData.map((item, i) => {
      if (item.path === history.location.pathname) {
        setCurrentPage(item);
      }
      item.subMenu?.map((subItem) => {
        if (subItem.path === history.location.pathname) {
          setOpen(true);
          setCurrentPage(subItem);
        }
      });
    });
  }, []);

  const { id } = useParams();
  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiListItemButton: {
            defaultProps: {
              disableTouchRipple: true,
            },
          },
        },
        palette: {
          mode: "light",
          primary: { main: "#000" },
          background: { paper: "#000" },
        },
      })}
    >
      <Drawer
        variant="permanent"
        classes={{ paper: classes.drawerClose }}
        open={false}
      >
        <FireNav
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {sidebarData.map((item, idx) => (
            <div key={idx} style={{ width: "85%" }}>
              <ListItemButton
                disabled={item.path === "/finans" && !isFinanceUser}
                onClick={() => {
                  const res_id =
                    id != null ? `${item.path}/${id}` : `${item.path}`;
                  setCurrentPage(item);
                  history.push(res_id);
                  if (item.subMenu) {
                    setOpen(!open);
                  } else {
                    setOpen(false);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#393737",
                    color: "#fff",
                  },
                  minHeight: 30,
                  color: currentPage.id == idx ? "#fff" : "#fff",
                  backgroundColor: currentPage.id == idx ? "#393737" : "#000",
                }}
              >
                <Tooltip
                  placement="right"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  title={item.label}
                >
                  {idx === 0 ? (
                    <Task />
                  ) : idx === 1 ? (
                    <Restaurant />
                  ) : idx === 2 ? (
                    <Paid />
                  ) : idx === 3 ? (
                    <Report />
                  ) : (
                    <AddBusiness />
                  )}
                </Tooltip>
              </ListItemButton>
            </div>
          ))}
        </FireNav>
      </Drawer>
    </ThemeProvider>
  );
};

export default SidebarIcon;
