import React from "react";
import { orderProductsImage } from "../../../../../components/converter/orderProductsImage";
import moment from "moment";

const RenewalPayed = ({ summaryRestDetailInfo }) => {
  function currencyFormat(num) {
    return (
      "₺ " +
      num
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 4.5px 1px gray",
          fontFamily: "segoeuiB",
          fontSize: 13,
          fontVariant: "all-petite-caps",
        }}
      >
        Bir yıl önce ödenen
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {summaryRestDetailInfo?.current_offer?.products?.length !== 0 ? (
          summaryRestDetailInfo?.current_offer?.products?.map((row1) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 5,
                  backgroundColor: "#494343",
                  margin: 5,
                  borderRadius: 4,
                  flexDirection: "column",
                }}
              >
                <img
                  src={orderProductsImage(row1.key)}
                  alt={row1?.key}
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
                <div style={{ fontFamily: "segoeuiRg", fontSize: 13 }}>
                  {moment(row1?.created_at).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontFamily: "segoeuiRg", fontSize: 13 }}>
                  - {moment().diff(moment(row1?.created_at), "days")} g -
                </div>
                <div
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 13,
                  }}
                >
                  {currencyFormat(row1?.amount)}
                </div>
              </div>
            );
          })
        ) : (
          <div>Siparişi Bulunmamaktadır. </div>
        )}
      </div>
    </div>
  );
};

export default RenewalPayed;
