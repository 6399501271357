import { Button } from "@mui/material";
import React from "react";
import { Call, Close } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import makeCall from "./func/makeCall";
import { callkey } from "../../contexts/callContext";

const makeCallComp = ({
  enqueueSnackbar,
  closeSnackbar,
  inputRef,
  cdrOutboundFunc,
  cdrAnswerFunc,
  cdrHangupFunc,
  sendCallData,
  cdrMissedCountFunc,
  disabledCallIcon,
  setDisabledCallIcon,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setDisabledCallIcon(true);
      setTimeout(() => {
        setDisabledCallIcon(false);
      }, 5000);

      sendCallData.callee = JSON.parse(
        localStorage.getItem("st-officeuser-info")
      )?.iqcell_verimor
        ? sendCallData.callee.slice(1)
        : sendCallData.callee;

      makeCall({
        enqueueSnackbar,
        closeSnackbar,
        sendCallData,
        callkey,
        cdrOutboundFunc,
        cdrAnswerFunc,
        cdrHangupFunc,
        cdrMissedCountFunc,
      });
    }
  };

  callkey = enqueueSnackbar(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <PhoneInput
        countryCodeEditable={false}
        country="tr"
        onlyCountries={["tr"]}
        localization={{ tr: "Türkiye" }}
        value={sendCallData.callee}
        containerStyle={{ height: 45 }}
        inputStyle={{
          height: 45,
          width: "100%",
          fontSize: 15,
          fontWeight: "400",
        }}
        inputProps={{
          ref: inputRef,
        }}
        onKeyDown={handleKeyPress}
        onChange={(phone) => {
          sendCallData.callee = phone;
        }}
      />
      <Button
        style={{
          color: "white",
          backgroundColor: disabledCallIcon ? "gray" : "#f53b62",
          textTransform: "capitalize",
          fontFamily: "SgBd",
          fontSize: 14,
          marginLeft: 15,
        }}
        disabled={disabledCallIcon}
        onClick={() => {
          setDisabledCallIcon(true);
          setTimeout(() => {
            setDisabledCallIcon(false);
          }, 5000);

          sendCallData.callee = JSON.parse(
            localStorage.getItem("st-officeuser-info")
          )?.iqcell_verimor
            ? sendCallData.callee.slice(1)
            : sendCallData.callee;

          makeCall({
            enqueueSnackbar,
            closeSnackbar,
            sendCallData,
            callkey,
            cdrOutboundFunc,
            cdrAnswerFunc,
            cdrHangupFunc,
            cdrMissedCountFunc,
          });
        }}
      >
        <Call />
      </Button>
      <Button
        style={{
          color: "white",
          backgroundColor: "#f53b62",
          textTransform: "capitalize",
          fontFamily: "SgBd",
          fontSize: 14,
          marginLeft: 15,
        }}
        onClick={() => {
          sendCallData.callee = "";
          closeSnackbar(callkey);
        }}
      >
        <Close />
      </Button>
    </div>,
    {
      preventDuplicate: true,
      persist: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      sx: {
        "& .SnackbarContent-root": {
          backgroundColor: "darkgray",
        },
      },
    }
  );
};

export default makeCallComp;
