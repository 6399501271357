import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Card, Modal } from "@mui/material";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 100%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const UserActivity = ({
  userActiveModal,
  isMouseInactive,
  setuserActiveModal,
  setStatusModal,
  setisMouseInactive,
}) => {
  const { userGoToBreak } = useContext(RestaurantContext);
  const classes = useStyles();
  const [secondLeft, setSecondLeft] = useState(15);

  useEffect(() => {
    if (userActiveModal) {
      let timer = setInterval(() => {
        if (secondLeft === 0) {
          const date = new Date();

          let data;
          if (date.getHours() >= 19) {
            data = {
              sendObj: {
                state: "passive",
              },
            };
          } else {
            data = {
              sendObj: {
                state: "break",
              },
            };
          }

          userGoToBreak(data);
          setuserActiveModal(false);
          setStatusModal(true);
          setSecondLeft(15);
          clearInterval(timer);
          return;
        } else {
          setSecondLeft((prev) => prev - 1);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isMouseInactive, secondLeft, userActiveModal]);

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      onClose={() => {
        setSecondLeft(15);
        setuserActiveModal(false);
        setisMouseInactive(false);
      }}
      open={userActiveModal}
      className={classes.modal}
      componentsProps={{
        backdrop: { style: { top: 60 } },
        root: { style: { top: 60 } },
      }}
    >
      <Card
        style={{
          width: "30%",
          display: "flex",
          padding: 25,
          flexDirection: "column",
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 28,
            fontWeight: 600,
            fontFamily: "monospace",
            textAlign: "center",
          }}
        >
          <span>{secondLeft} saniye içerisinde molaya alınacaksınız.</span>
        </div>
      </Card>
    </Modal>
  );
};

export default UserActivity;
