import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import managementService from "../../../services/managementService";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import { useToast } from "../../../components/snackbar";
import Select from "react-select";
import { offerCount } from "../../../utils/constants";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";

export default function ApproveOffer() {
  const [offers, setOffers] = useState([]);
  const { success } = useToast();
  const [selectedDiscountList, setSelectedDiscountList] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await managementService.get_approved_offers();
      const selectedDiscountList = res.map((item) => {
        return offerCount.find((e) => e.value === item.discount_manager);
      });
      setSelectedDiscountList(selectedDiscountList);
      setOffers(res);
    })();
  }, []);

  const handleSelectChange = async (index, selectedDiscount, row) => {
    const updatedDiscountList = [...selectedDiscountList];
    updatedDiscountList[index] = selectedDiscount;
    setSelectedDiscountList(updatedDiscountList);

    const newOffers = [...offers];
    newOffers[index].discount_manager = selectedDiscount.value;
    setOffers(newOffers);

    let data = {
      id: row.id,
      discount_manager: selectedDiscount.value,
    };

    await managementService.approve_offer(data).then((res) => {
      success("İndirim oranı güncellendi");
    });

    const res = await managementService.get_approved_offers();
    setOffers(res);
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "90%" }}>
        <h3
          style={{
            fontFamily: "segoeuiB",
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Teklif Onay
        </h3>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Restoran Id</TableCell>
                  <TableCell align="center">Restoran Adı</TableCell>
                  <TableCell align="center">Personel</TableCell>
                  <TableCell align="center">Ürün Adı</TableCell>
                  <TableCell align="center">Adet</TableCell>
                  <TableCell align="center">Asıl Fiyat</TableCell>
                  <TableCell align="center">
                    Operasyon Yüzdelik İndirim
                  </TableCell>
                  <TableCell align="center">
                    Operasyon Yön. Yüzdelik İndirim
                  </TableCell>
                  <TableCell align="center">Özel Fiyat</TableCell>
                  <TableCell align="center">Onayla</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offers?.map((row, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {row.basket_offer.restaurant_id}
                      </TableCell>
                      <TableCell align="center">
                        {row.basket_offer.restaurant_name}
                      </TableCell>
                      <TableCell align="center">
                        {row.officeuser.name}
                      </TableCell>
                      <TableCell align="center">{row.product.name}</TableCell>
                      <TableCell align="center">{row.count}</TableCell>
                      <TableCell align="center">{row.base_price}</TableCell>
                      <TableCell align="center">
                        {row.operation_price} / {row.discount_operation}%
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                          styles={{ ...selectDefaultStyles, height: "555px" }}
                          responsiveHeight={40}
                          selectFontSize={16}
                          options={offerCount}
                          boxShadow={"#f53b62"}
                          isSearchable={false}
                          hoverColor={"#f53b62"}
                          menuColor={"#f53b62"}
                          selectWidth={"100"}
                          placeHolderFontSize={16}
                          placeholder={"Seçiniz"}
                          value={selectedDiscountList[ind]}
                          getOptionValue={(option) => option.value}
                          isOptionDisabled={(option) =>
                            option.value <= row.discount_operation
                          }
                          onChange={(e) => {
                            handleSelectChange(ind, e, row);
                          }}
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {e.label} %
                            </div>
                          )}
                        />
                      </TableCell>
                      <TableCell align="center">{row.special_price}</TableCell>
                      <TableCell align="center">
                        <DoneIcon
                          style={{
                            color: "green",
                            cursor: "pointer",
                            marginRight: 20,
                          }}
                          onClick={async () => {
                            let data = {
                              check: "yes",
                              id: row.id,
                            };
                            await managementService
                              .approve_offer(data)
                              .then((res) => {
                                success("Teklif onaylandı");
                              });

                            const res =
                              await managementService.get_approved_offers();
                            setOffers(res);
                          }}
                        />
                        <CancelIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={async () => {
                            let data = {
                              check: "no",
                              id: row.id,
                            };
                            await managementService
                              .approve_offer(data)
                              .then((res) => {
                                success("Teklif reddedildi");
                              });

                            const res =
                              await managementService.get_approved_offers();
                            setOffers(res);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
}
