import React from "react";
import restaurantService from "../../../../../../services/restaurantService";
import AsyncSelect from "react-select/async";
function EnduserSearch({ setUserList, userList, id }) {
  const userListOptionsHandler = (searchQuery) => {
    if (searchQuery.length > 2) {
      return new Promise((resolve) => {
        restaurantService
          .restaurant_enduser_search(searchQuery, id)
          .then((response) => {
            const value = response?.map((user) => ({
              value: user?.user_id,
              label: user?.full_name,
            }));
            resolve(value);
          });
      });
    }
  };
  return (
    <div
      style={{
        width: "50%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: "#f4f4f4",
        border: "groove",
        borderColor: "#f53b62",
        marginRight: 15,
      }}
    >
      <span
        style={{
          fontSize: 14,
          width: 150,
          textAlign: "center",
        }}
      >
        Kullanıcı Ara
      </span>
      <div
        style={{
          width: "80%",
          padding: 5,
        }}
      >
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#f53b62 !important" : "grey",
              boxShadow: "0 0 0 1px #f53b62",
            }),
          }}
          value={{
            label: (
              <span style={{ fontSize: 14 }}>{userList?.full_name || ""}</span>
            ),
            value: userList?.user_id || -1,
          }}
          onChange={(e) => {
            setUserList(() => ({
              full_name: e?.label,
              user_id: e?.value,
            }));
          }}
          loadOptions={userListOptionsHandler}
          isClearable={userList?.full_name != null ? true : false}
        />
      </div>
    </div>
  );
}

export default EnduserSearch;
